import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class EmployeeApiInvoker extends Component {


	static getEmployee(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee/${employeeId}`, callback, callerror);
	}
	
	static postEmployee(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee`, body, callback, callerror);
	}

	static patchEmployee(body, callback, callerror) {
		baseInvoker.patch(`${urlInvoke}/customer`, body, callback, callerror);
	}

	static getAddress(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/address`, callback, callerror);
	}

	static getEmployeeAddress(personId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/persons/${personId}/address`, callback, callerror);
	}

	static deleteEmployeeAddress(personId, addressId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/persons/${personId}/address/${addressId}`, callback, callerror);
	}

	static postEmployeeNewAddress(personId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/address`, body, callback, callerror);
	}

	static postEmplyeeAddress(personId, addressId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/address/${addressId}`, body, callback, callerror);
	}

	static getEmployeeTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/phoneTypes`, callback, callerror);
	}

	static postEmployeePhone(personId, phoneId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/phone/${phoneId}`, body, callback, callerror);
	}

	static postEmployeeNewPhone(personId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/phone`, body, callback, callerror);
	}

	static getEmployeePhone(personId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/persons/${personId}/phone`, callback, callerror);
	}

	static deleteEmployeePhone(personId, phoneId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/persons/${personId}/phone/${phoneId}`, callback, callerror);
	}

	static getIdTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/idTypes`, callback, callerror);
	}

	static getEmployees(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees?employee-category=PROFESSIONAL`, callback, callerror);
	}

	static getEmployeesType(type, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees?employee-category=${type}`, callback, callerror);
	}

	static getEmployeesByPracticetype(practiceTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-by-practicetype/${practiceTypeId}`, callback, callerror);
	}

	static postEmployeeHojaRuta(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee/hojaRuta`, body, callback, callerror);
	}

	static getEmployeeByZoneAndSchedule(body,  callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employees-zone-type`, body, callback, callerror);
	}

	static postAppointmentScheduleItems(appointmentId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/appointment/items-request/${appointmentId}`, body, callback, callerror);
	}


}