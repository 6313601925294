export const EMPLOYEE = 'EMPLOYEE';
export const CUSTOMERS = 'CUSTOMERS';
export const PROVINCIES = 'PROVINCIES';


export function getEmployee(employee) {
    return {type: EMPLOYEE, employee}
}

export function getCustomers(customers) {
    return {type: CUSTOMERS, customers}
}

export function getProvincies(provincies) {
    return {type: PROVINCIES, provincies}
}

