import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../CustomButtons/Button';
import CustomSweetAlert from '../../CustomSweetAlert';
import Snackbar from '../../Snackbar/Snackbar';
import Table from '../../Table/Table';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import { useQuantityRequests as setQuantityRequests } from '../../../hooks/useQuantityRequests';

const ItemsRequest = ({ appointment, practiceTypes, onChangeItemsRequest, packages, t }) => {
  const [appointmentId, setAppointmentId] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeItemsRequest, setChangeItemsRequest] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState({});
  const [showConfirmAcceptItem, setShowConfirmAcceptItem] = useState(false); 
  const [showConfirmRejectItem, setShowConfirmRejectItem] = useState(false); 
  const [confirmAlert, setConfirmAlert] = useState({
    message: '',
    type: 'danger',
    open: false,
  }); 

  const onAcceptItemAction = (id) => {
    setSelectedItemId(id);
    setShowConfirmAcceptItem(true);
  }

  const onRejectItemAction = (id) => {
    setSelectedItemId(id);
    setShowConfirmRejectItem(true);
  }

  const openConfirmAlert = (type, message) => {
    setConfirmAlert({
      type,
      message,
      open: true,
    });

    setTimeout(() => {
      setConfirmAlert({ open: false });
    }, 5000);
  }

  const onAcceptItem = () => {
    AppointmentApiInvoker.postAcceptItemRequest(selectedItemId,
      (res) => {
        setShowConfirmAcceptItem(false);
        getItemRequest(appointmentId);
        setChangeItemsRequest(true);
        onChangeItemsRequest(true);
        openConfirmAlert('success', t('confirm.success.acceptItem'));
      }, (err) => {
        console.error('** Error postAcceptItemRequest: ', err);
        setShowConfirmAcceptItem(false);
        openConfirmAlert('danger', t('confirm.error'));
      });
  }

  const onRejectItem = () => {
    AppointmentApiInvoker.postRejectItemRequest(selectedItemId,
      (res) => {
        getItemRequest(appointmentId);
      }, (err) => {
        console.error('** Error postRejectItemRequest:', err);
      });
  }

  const renderActionItemRequestComponent = (a) => {
    return (
      <div className="appointment-action">
        <Tooltip title={t('appointment.table.item.action.accept')}>
          <span>
            <Button
              simple
              justIcon
              color="success"
              onClick={() => onAcceptItemAction(a.appointmentItemRequestId)}
            >
              <CheckIcon />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={t('appointment.table.item.action.reject')}>
          <span>
            <Button
              simple
              justIcon
              color="rose"
              disabled
            >
              <ClearIcon style={{ color: 'grey' }} />
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  }
  const isMeModulePractice = (partnership) => {
    let isMe = false;
    if (partnership) {
      if (localStorage.getItem('company_partnership_id') === null) {
        isMe = true;
      } else {
        if (partnership.companyId === parseInt(localStorage.getItem('company_partnership_id'))) {
          isMe = true;
        }
      }
    } else {
      isMe = true;
    }
    return isMe;
  }

  const getLenderName = (t, partnership) => {
    const isMe = isMeModulePractice(partnership);
    const isNamePartnership = !!(partnership && partnership.name);
    const lenderName = isMe
      ? (isNamePartnership ? partnership.name : '') : t('tools.new.other');
    return lenderName;
  }

  const getItemRequestName = (r) => {
    let practiceTypeId = null;
    let practiceItem = {};
    let packageId = null;
    let packageItem = {};
    
    if (r.itemRequestDetails) {
      practiceTypeId = r.practiceTypeId;
      practiceItem = practiceTypes.find(e => e.practiceTypeId === practiceTypeId);
    }
    if (r.itemRequestPackageDetails) {
      packageId = r.packageId;
      packageItem = packages.find(e => e.packageId === packageId);
    }
    
    return practiceTypeId ? practiceItem.name : packageId ? packageItem.name : '';
  }

  const formatDataTableApp = (itemRequest, appointment) => {
    let companyPartnershipId = -1;
    if (localStorage.getItem('company_partnership_id') != null) {
      companyPartnershipId = parseInt(localStorage.getItem('company_partnership_id'));
    } 
    if (companyPartnershipId == null || companyPartnershipId === -1) {
      companyPartnershipId = parseInt(localStorage.getItem('itlg_default_company_id'));
    }

    const appointmentEndDate = moment(appointment.endDate, 'DD/MM/YYYY').format('YYYY/MM/DD');

    const items = itemRequest.map((r) => {
      const itemStartDate = moment(r.startDate);
      
      const [quantity] = setQuantityRequests(r, itemStartDate, null, appointmentEndDate);
      const type = r.itemRequestDetails ? t('Practice') : t('Package');
      const name = getItemRequestName(r);

      let edit = false;
      if (r.companyIdPartnership && r.companyIdPartnership.companyId === companyPartnershipId) {
        edit = true;
      }
      const lender = r.companyIdPartnership ? getLenderName(t, r.companyIdPartnership) : '';
      
      return {
        order: r.order,
        type,
        name,
        startDate: moment(r.startDate).format('DD/MM/YYYY'),
        quantity,
        lender,
        status: t(`status.appointmentItemRequest.${r.appointmentItemRequestStatus}`),
        actions: edit && r.appointmentItemRequestStatus === 'CREATED' ? renderActionItemRequestComponent(r) : '',
      }
    }).sort((a, b) => a.order - b.order);

    return items;
  }
  
  const getItemRequest = () => {
    setLoading(true);
    AppointmentApiInvoker.getAppointmentItems(appointment.appointmentId, (res) => {
      const data = formatDataTableApp(res, appointment);
      setItems(data);
      setLoading(false);
    }, (err) => {
      console.error('** Error getAppointmentItems:', err);
      setLoading(false);
    })
  }

  useEffect(() => {
    onChangeItemsRequest(false);
    setAppointmentId(appointment.appointmentId);
    getItemRequest();
    return () => {
      setItems([]);
      setAppointmentId(null);
      setChangeItemsRequest(false);
      onChangeItemsRequest(false);
    }
  }, [])

  return (
    <>
      <Table
        filterable
        loading={loading}
        tableHeaderColor="primary"
        sortable
        tableHead={[
          { Header: t('appointment.table.orden'), accessor: 'order' },
          { Header: t('appointment.new.schedule.practice.lender'), id: 'lender', accessor: 'lender' },
          { Header: t('appointment.table.type'), accessor: 'type' },
          { Header: t('appointment.table.name'), accessor: 'name' },
          { Header: t('appointment.table.status'), accessor: 'status' },
          { Header: t('appointment.table.startDate'), accessor: 'startDate' },
          { Header: t('appointment.table.quantity'), accessor: 'quantity' },
          { Header: t('appointment.table.actions'), accessor: 'actions' },
        ]}
        tableData={items}
        colorsColls={['primary']}
        className="-striped -highlight filtrable sticky"
        defaultPageSize={items.length <= 25 ? 25 : items.length}
        showPaginationTop={false}
        showPaginationBottom={false}
      />

      {showConfirmAcceptItem &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => onAcceptItem()}
          onCancel={() => setShowConfirmAcceptItem(false)}
          title={t('appointment.item.accept.title')}
          confirmBtnCssClass="success"
          cancelBtnCssClass="danger"
          cancelBtnText={t('appointment.created.cancel.cancel')}
          confirmBtnText={t('appointment.created.cancel.confirm')}
          showCancel={true}
          message={<p>{t('appointment.item.accept.message')}</p>}
        />
      }

      {showConfirmRejectItem &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => onRejectItem()}
          onCancel={() => setShowConfirmRejectItem(false)}
          confirmBtnCssClass="success"
          cancelBtnCssClass="danger"
          title={t('appointment.created.cancel.title')}
          cancelBtnText={t('appointment.created.cancel.cancel')}
          confirmBtnText={t('appointment.created.cancel.confirm')}
          showCancel={true}
          message={<p>{t('appointment.created.cancel.message')}</p>}
        />
      }

      <Snackbar
        color={confirmAlert.type}
        message={confirmAlert.message}
        open={confirmAlert.open}
        place="tr"
      />
    </>
  )
}

ItemsRequest.defaultProps = {
  onChangeItemsRequest: () => {},
  packages: [],
  practiceTypes: [],
}

ItemsRequest.propTypes = {
  appointment: PropTypes.object.isRequired,
  onChangeItemsRequest: PropTypes.func,
  packages: PropTypes.array,
  practiceTypes: PropTypes.array,
}

export default withTranslation()(ItemsRequest);
