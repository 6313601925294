import { DOCUMENT_TYPES } from '../actions/newCustomer';

const newCustomer = (state = {}, action, root) => {
    switch(action.type) {
        case DOCUMENT_TYPES:
            const documentTypes = action.documentTypes
            return {...state, documentTypes}
        default:
            return state;
    }
}

export default newCustomer;
