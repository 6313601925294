import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  secondaryCardHeader,
  roseCardHeader,
  redCardHeader,
  greenCardHeader,
  yellowCardHeader,
  violetCardHeader,
  grayColor
} from "./material-dashboard-pro-react";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$secondaryCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$redCardHeader,&$greenCardHeader,&$yellowCardHeader,&$yellowCardHeader,&$violetCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  warningCardHeader,
  secondaryCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  redCardHeader,
  greenCardHeader,
  yellowCardHeader,
  violetCardHeader
};

export default cardIconStyle;
