import clsx from 'clsx';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Grid, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import BookingDetail from '../../../Bookings/BookingDetail';
import Button from '../../../CustomButtons/Button';
import CustomDialog from '../../../CustomDialog';
import EventWithIcon from '../../../EventWithIcon/EventWithIcon';
import Table from '../../../Table/Table';
import { useStylesGralSteps } from '../../useStylesReassignAppStepper';
import useCalendarStep from './useCalendarStep';

const localizer = momentLocalizer(moment);

const CalendarStep = ({
  handleChange,
  showSnackbarNotification,
  values,
  t,
}) => {
  const classes = useStylesGralSteps();

  const {
    handleOnSelectedEvent,
    handleUndoChanges,
    updateAppointment,
    eventColors,
    openDetail,
    setOpenDetail,
    updateErrors,
    status,
  } = useCalendarStep(() => {}, handleChange, () => {}, showSnackbarNotification, values, t);

  const {
    bookings, bookingsError, currentEvent, events, hasChangedSchedules, loadingevents, schedulesNotAssigned, schedulesAssigned, 
  } = values;

  return (
    <Grid
      container
      className={classes.formStepper}
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <h4 className={classes.stepTitle}>{t('title.calendar')}</h4>
        <React.Fragment>
          <Calendar
            showMultiDayTimes={true}
            selectable
            localizer={localizer}
            events={events}
            defaultView="month"
            step={30}
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={event => handleOnSelectedEvent(event)}
            eventPropGetter={event => eventColors(event)}
            messages={{
              next: t('common.next'),
              previous: t('common.prev'),
              today: t('common.today'),
              month: t('common.month'),
              week: t('common.week'),
              day: t('common.day'),
              date: t('common.date'),
              time: t('common.hour'),
              noEventsInRange: t('appointment.notPractices'),
            }}
            components={{
              event: EventWithIcon,
            }}
          />
        </React.Fragment>

        <CustomDialog
          title={t('appointment.calendar.scheduleDialogTitle')}
          maxWidth={'sm'}
          open={openDetail}
          onClose={() => setOpenDetail(false)}
        >
          <BookingDetail
            event={currentEvent}
            onCancel={() => setOpenDetail(false)}
            onUpdate={(appointment) => updateAppointment(appointment)}
            disabled={false}
            errors={updateErrors}
            status={status}
          />
        </CustomDialog>
      </Grid>
      <Grid item xs={12} className={classes.actionButton}>
        <Button
          onClick={() => handleUndoChanges([...bookings, ...bookingsError])}
          className={classes.button}
          disabled={!hasChangedSchedules}
        >
          {t('label.undo')}
        </Button>
      </Grid>

      <Grid item xs={12}>
           <h4 className={clsx(classes.stepTitle, classes.spacingTop7, classes.titleWidthIconEnd)}>
            <span>{t('reassignAppointments.table.schedules_assigned')}</span>
          </h4>
          <Table
            loading={loadingevents}
            tableHeaderColor="primary"
            sortable={true}
            filterable
            tableHead={[
              { Header: t('reassignAppointments.notasigned.table.scheduleId'), accessor: 'scheduleId' },
              { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
              { Header: t('reassignAppointments.notasigned.table.scheduleDateTime'), accessor: 'scheduleDateTime' },
              { Header: t('reassignAppointments.notasigned.table.practiceTypeName'), accessor: 'practiceTypeName' },
              { Header: t('reassignAppointments.notasigned.table.customer'), accessor: 'customer' },
              { Header: t('reassignAppointments.notasigned.table.location'), accessor: 'location' },
              { Header: t('reassignAppointments.notasigned.table.geographicZone'), accessor: 'geographicZone' },
            ]}
            tableData={schedulesAssigned}
            defaultPageSize={schedulesAssigned ? schedulesAssigned.length > 5 ? 20 : 5 : 0}
            colorsColls={['primary']}
          />
        </Grid>

      {schedulesNotAssigned.length ?
        <Grid item xs={12}>
           <h4 className={clsx(classes.stepTitle, classes.spacingTop7, classes.titleWidthIconEnd)}>
            <span>{t('reassignAppointments.table.schedules_not_assigned')}</span>
            <Tooltip placement="right" title={t('tooltipInfo.schedulesNotAssigned')}>
              <InfoOutlined />
            </Tooltip>
          </h4>
          <Table
            loading={loadingevents}
            tableHeaderColor="primary"
            sortable={true}
            filterable
            tableHead={[
              { Header: t('reassignAppointments.notasigned.table.scheduleId'), accessor: 'scheduleId' },
              { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
              { Header: t('reassignAppointments.notasigned.table.scheduleDateTime'), accessor: 'scheduleDateTime' },
              { Header: t('reassignAppointments.notasigned.table.practiceTypeName'), accessor: 'practiceTypeName' },
              { Header: t('reassignAppointments.notasigned.table.customer'), accessor: 'customer' },
              { Header: t('reassignAppointments.notasigned.table.location'), accessor: 'location' },
              { Header: t('reassignAppointments.notasigned.table.geographicZone'), accessor: 'geographicZone' },
            ]}
            tableData={schedulesNotAssigned}
            defaultPageSize={schedulesNotAssigned.length > 5 ? 20 : 5}
            colorsColls={['primary']}
          />
        </Grid>
      : <div />
    }
    </Grid>
  )
}

export default withTranslation()(CalendarStep);
