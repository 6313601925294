
import Authority from '../util/Authority';
import routes from '../routes';

function requireAuth (nextState, replace, callback) {
    let flattenRoutes = getFlattenRoutes(routes);
    let flattenRoutesFilteredAuthorized = _.filter(flattenRoutes, (route) => {
        return route.authority || route.parentAuthority;
    });
    
    let actualRoute = _.find(flattenRoutesFilteredAuthorized, route => {
        if (!route.path.includes(':')) {
        return nextState.location.pathname === route.path;
        } else {
        let routeWithoutId = route.path.substring(0, route.path.lastIndexOf("/"));
        let broswerPathWithoutId = nextState.location.pathname.substring(0, nextState.location.pathname.lastIndexOf("/"));
        
        return broswerPathWithoutId === routeWithoutId;
        }
    }) || {};
    
    if (!Authority.hasPermission(actualRoute.authority || actualRoute.parentAuthority)) replace('/')
    return callback()
}

function getFlattenRoutes (routesToFlat, parentAuthority) {
    return _.flattenDepth(_.map(routesToFlat, route => {
      if(route.views) {
        let clonedViews = _.cloneDeep(route.views);
  
        return getFlattenRoutes(clonedViews, route.authority);
      } else {
        return (parentAuthority) ? _.assign({}, route, { parentAuthority: parentAuthority }) : route;
      }
    }), 1);
}

export default requireAuth;