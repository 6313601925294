import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../../CustomDialog';
import SelectInput from '../../../SelectInput';
import SchedulerService from '../../../../containers/SchedulerService';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import { withTranslation } from 'react-i18next';

class ScheduleDetailModal extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            open: false,
            types: [{
                id: 1,
                value: t('Practice'),
            }, {
                id: 2,
                value: t('Package'),
            }],
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            appointmentAction: '',
        }
    }

    componentWillReceiveProps(next) {
        this.setState({ open: next.open });

        if (!next.current && next.action === 'create') {
            this.setState({
                action: next.action,
                appointmentAction: '',
            });
        }

        if (next.current && next.action !== 'create') {
            if (next.actionAppointment === 'create' || next.actionAppointment === 'assign_agenda' || next.actionAppointment === 'edit') {
                if (!next.current.item) {
                    next.current.item = {
                        ...next.current,
                        schedulePlan: next.current.schedulePlan,
                    }
                }
                this.setState({
                    current: next.current,
                    type: next.current.module ? 2 : 1,
                    component: this.state.component ? this.state.component : next.current.module ? 'module' : 'plan',
                    appointmentAction: next.actionAppointment,
                });
            } else if ((next.current.module && this.state.component === 'module') || (next.current.plan && this.state.component === 'plan') || !(this.state.component)) {
                if (next.current && next.action === 'edit') {
                    if (!next.current.item) {
                        next.current.item = {
                            ...next.current,
                            schedulePlan: next.current.schedulePlan,
                        }
                    }
                    this.setState({
                        current: next.current,
                        type: next.current.module ? 2 : 1,
                        component: this.state.component ? this.state.component : next.current.module ? 'module' : 'plan',
                        appointmentAction: next.actionAppointment,
                    });
                }
            }
        }
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        });
        this.props.onCloseModal();
    }

    selectedType(value) {
        this.setState({
            type: value,
            current: null,
            component: value === 2 ? 'module' : 'plan',
        });
    }

    createModule(value) {
        this.closeModal();
        this.props.onCreatedModule(value);
    }

    createPlan(value) {
        this.closeModal();
        this.props.onCreatedPlan(value);
    }

    updatePlan(value) {
        this.closeModal();
        this.props.onUpdatePlan(value);
    }

    render() {
        const { t, appointmentId, dates, customer, items, onGetModuleTypes, onGetPracticeTypes } = this.props;
        const { action, appointmentAction, component, current, open, type, types } = this.state;
        let size = 'sm';
        let colspan = 12;
        if (type) {
            size = 'md';
            colspan = 6;
        }
        return (
            <CustomDialog
                maxWidth={size}
                title={t('appointment.new.schedule.modal.title')}
                open={open}
                onClose={() => this.closeModal()}
            >
                <GridContainer className={component === 'module' ? 'ps-child modules-form' : 'ps-child plan-form'}>
                    <GridItem xs={colspan} className="componentType">
                        {component == null &&
                            <SelectInput
                                disabled={appointmentAction === 'assign_agenda' || appointmentAction === 'copy' || (appointmentAction === 'edit' && action !== 'create')}
                                label={t('appointment.new.schedule.modal.type')}
                                elements={types}
                                onSelectedValue={(value) => this.selectedType(value)}
                                value={type}
                            />
                        }
                    </GridItem>
                    {component === 'module' &&
                        <SchedulerService.ModulesService
                            onGetModuleTypes={onGetModuleTypes}
                            items={items}
                            onUpdatePlan={(value) => this.updatePlan(value)}
                            customer={customer}
                            dates={dates}
                            action={action}
                            appointmentAction={appointmentAction}
                            appointmentId={appointmentId}
                            onSavedModules={(value) => this.createModule(value)}
                            current={current}
                        />
                    }
                    {component === 'plan' &&
                        <SchedulerService.PlanService
                            onGetPracticeTypes={onGetPracticeTypes}
                            items={items}
                            appointmentAction={appointmentAction}
                            customer={customer}
                            action={action}
                            appointmentId={appointmentId}
                            dates={dates}
                            onUpdatePlan={(value) => this.updatePlan(value)}
                            onSavedPlan={(value) => this.createPlan(value)}
                            current={current}
                        />
                    }
                </GridContainer>
            </CustomDialog>
        )
    }
}

ScheduleDetailModal.defaultProps = {
    onGetModuleTypes: () => [],
    onGetPracticeTypes: () => [],
}

ScheduleDetailModal.propTypes = {
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onCreatedModule: PropTypes.func,
    onCreatedPlan: PropTypes.func,
    onGetModuleTypes: PropTypes.func,
    onGetPracticeTypes: PropTypes.func,
    current: PropTypes.object,
    action: PropTypes.string,
}

export default withTranslation()(ScheduleDetailModal);
