import { makeStyles } from '@material-ui/core';
import { grayColor, warningColor, whiteColor } from './material-dashboard-pro-react';

const useStylesTabsNav = makeStyles(() => ({
  tabsNav: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tabNav: {
    borderRadius: 4,
    color: grayColor[6],
    fontSize: 12,
    fontWeight: 500,
    height: 'auto',
    letterSpacing: 'initial',
    lineHeight: 3.5,
    margin: '0 5px',
    maxWidth: '100%',
    minHeight: 48,
    minWidth: 100,
    opacity: 1,
    padding: '25px 15px 20px',
    position: 'relative',
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: 'all .3s',
    '&.Mui-selected': {
      color: whiteColor,
      backgroundColor: `${warningColor[0]} !important`,
    },
  },
}));

export default useStylesTabsNav;
