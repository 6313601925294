import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import EditIcon from '@material-ui/icons/Edit';
import ValidationInput from '../ValidationInput';
import Snackbar from '../Snackbar/Snackbar';
import { withTranslation } from 'react-i18next';
import ApiInvoker from '../../api/ApiInvoker';
import ButtonSpinner from '../ButtonSpinner';
import './EditToolType.css';

class EditTollType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTypes: [],
            documents: [],
            name: '',
            detail: '',
            toolTypeCode: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            personId: '',
            openAddress: false,
            openPhone: false,
            openFinanciers: false,
            deleteAlert: false,
            address: null,
            phone: null,
            save: false,
            refresh: true,
            busy: false,
        }
    }

    componentWillMount() {
        this.findToolType();        
    }

    componentWillReceiveProps(next) {
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false })
            if (color === 'success') {
                browserHistory.push(`/tool-types/`);
            }
        }, 5000);
    }

    saveToolType() {
        this.setState({ save: true, busy: true });
        if (this.state.toolTypeCode && this.state.toolTypeCode.trim() && this.state.name && this.state.name.trim()) {
            ApiInvoker.postToolType({
                name: this.state.name,
                toolTypeCode: this.state.toolTypeCode,
                detail: this.state.detail,
                toolTypeId: this.state.toolTypeId,
                metadata: {},
            }, data => {
                this.props.onEditToolType(data)
                this.openAlert('success', this.props.t('tooltypes.edit.success'));
            }, null)
        } else {
            this.openAlert('warning', this.props.t('tooltypes.edit.warning'));
            this.setState({ busy: false });
        }
    }

    handleValue(value, state) {
        this.setState({
            [state]: value,
            refresh: false,
        });
    }

    findToolType() {
        ApiInvoker.getToolType(this.props.routeParams.id, tooltype => {
            this.setState({
                name: tooltype.name,
                toolTypeCode: tooltype.toolTypeCode,
                detail: tooltype.detail,
                toolTypeId: tooltype.toolTypeId,
            })
        }, null);
    }

    back() {        
        browserHistory.goBack();
    }

    render() {
        const { t } = this.props;

        return (
            <GridContainer className="edit-tooltype">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <EditIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('tooltypes.edit.toolType.title')}</h3>
                        </CardHeader>
                        <CardBody className="edit-tooltype-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('tooltypes.edit.toolTypeCode')} onChangeValue={(value) => this.handleValue(value, 'toolTypeCode')} value={this.state.toolTypeCode} invalid={!(this.state.toolTypeCode && this.state.toolTypeCode.trim()) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('tooltypes.edit.name')} onChangeValue={(value) => this.handleValue(value, 'name')} value={this.state.name} invalid={!(this.state.name && this.state.name.trim()) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('tooltypes.edit.detail')}  onChangeValue={(value) => this.handleValue(value, 'detail')} value={this.state.detail} />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className="edit-tooltype-submit padding-horizontal">
                            <Button onClick={() => this.back()}>
                                {t('supplycatalog.new.cancel')}
                            </Button>

                            <ButtonSpinner
                                onClick={() => this.saveToolType()}
                                disabled={this.state.busy}
                                label={t('tooltypes.edit.toolType.save')}
                                labelLoading={t('tooltypes.edit.toolType.saving')}
                                loading={this.state.busy}
                                typeButton="submit"
                                color="info"
                            />
                        </CardFooter>
                    </Card>
                </GridItem>
                
                <Snackbar
                    place="tr"
                    color={this.state.alertColor}
                    message={this.state.alertMessage}
                    open={this.state.alertOpen}
                />
            </GridContainer>
        )
    }
}

EditTollType.propTypes = {
    onEditToolType: PropTypes.func.isRequired,
}

export default withTranslation()(EditTollType);
