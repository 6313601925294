import BaseInvoker from './BaseInvoker'
import { Component } from 'react'

const baseInvoker = new BaseInvoker()

export default class DiabeticApiInvoker extends Component {
  static getAllDiabetics(callback, callerror) {
    baseInvoker.get('/api/diabetics/viewAll', callback, callerror)
  }
  static newDiabeticProgram(body, callback, callerror) {
    baseInvoker.post('/api/diabetics', body, callback, callerror)
  }
  static getAllIMCOptions(callback, callerror) {
    baseInvoker.get('/api/imc/viewAll', callback, callerror)
  }
  static getAllSkinOptions(callback, callerror) {
    baseInvoker.get('/api/skin/viewAll', callback, callerror)
  }
  static getAllDepressionValuesOptions(callback, callerror) {
    baseInvoker.get('/api/depressionValues/viewAll', callback, callerror)
  }
  static getAllColesterolOptions(callback, callerror) {
    baseInvoker.get('/api/laboratoryResults/Colesterol', callback, callerror)
  }
  static getAllGlucemiaOptions(callback, callerror) {
    baseInvoker.get('/api/laboratoryResults/Glucemia', callback, callerror)
  }
  static getAllHemoglobinaGlicolisadaOptions(callback, callerror) {
    baseInvoker.get(
      '/api/laboratoryResults/Hemoglobina Glicolisada',
      callback,
      callerror
    )
  }
  static getAllTriglicéridosOptions(callback, callerror) {
    baseInvoker.get('/api/laboratoryResults/Triglicéridos', callback, callerror)
  }
  static getAllTUreaOptions(callback, callerror) {
    baseInvoker.get('/api/laboratoryResults/Urea', callback, callerror)
  }
  static getAllTCreatininaOptions(callback, callerror) {
    baseInvoker.get('/api/laboratoryResults/Creatinina', callback, callerror)
  }
  static getDiabeticById(id, callback, callerror) {
    baseInvoker.get(`/api/diabetics/${id}`, callback, callerror)
  }
  static updateDiabeticProgram(body, callback, callerror) {
    baseInvoker.put(`/api/diabetics/update`, body, callback, callerror)
  }
  static filterListData(params, callback, callerror) {
    baseInvoker.get(`/api/diabeticsFilter?${params}`, callback, callerror)
  }
}
