import '../HospitalizationStyles.css'

import BusinessApiInvoker from '../../../api/BusinessApiInvoker'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import Card from '../../Card/Card.jsx'
import CardBody from '../../Card/CardBody.jsx'
import CardHeader from '../../Card/CardHeader.jsx'
import CardIcon from '../../Card/CardIcon.jsx'
import CustomInput from '../../CustomInput/CustomInput.jsx'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const FormFinancial = (props) => {
  const { setDataFinanceForm, setIsValidatedFinanceForm, personId } = props

  const [axa, setAxa] = useState(null)
  const [axaID, setAxaID] = useState(null)
  const [isValidated, setIsValidated] = useState(false)

  let data = {
    axa: axa,
    axaID: axaID,
  }

  let company = localStorage.getItem('itlg_default_company_name')

  const isValidateData = (personId) => {
    BusinessApiInvoker.getCustomersFinanciers(personId, (data) => {
      setAxa(data[0].financier.businessName)
      setAxaID(data[0].affiliateId)
      validateForm()
    })
  }

  const validateForm = () => {
    if (axaID || personId) {
      setIsValidated(true)
    } else {
      setIsValidated(false)
    }
  }

  useEffect(() => {
    validateForm()
    if (personId) {
      isValidateData(personId)
    }
    setDataFinanceForm(data)
    setIsValidatedFinanceForm(isValidated)
  }, [axa, axaID, personId, isValidated])

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <BusinessCenterIcon />
        </CardIcon>
        <h4>Datos financieros</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <CustomInput
              value={axa ? axa : ''}
              labelText={`Financiador`}
              id="axa"
              formControlProps={{
                fullWidth: true,
                disabled: true,
              }}
              inputProps={{
                type: 'text',
              }}
              onChange={(e) => setAxa(e.target.value)}
            />
          </GridItem>
          <GridItem md={12}>
            <CustomInput
              value={axaID ? axaID : ''}
              labelText={`Nº de beneficiario`}
              id="axa_id"
              formControlProps={{
                fullWidth: true,
                disabled: true,
              }}
              inputProps={{
                type: 'text',
              }}
              disabled={axa === null}
              onChange={(e) => setAxaID(e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormFinancial
