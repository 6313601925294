import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import ChipFilters from '../../ChipFilters';
import ComponentDisabledWithTooltip from '../../ComponentDisabledWithTooltip';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import ApiInvoker from '../../../api/ApiInvoker';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import BusinessApiInvoker from '../../../api/BusinessApiInvoker';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import RecordsApiInvoker from '../../../api/RecordsApiInvoker';
import '../Dashboard.css';

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            professionals: [],
            employees: [],
            employeesType: [],
            oemployeesType: [],
            financiers: [],
            ofinaciers: [],
            dateFrom: moment().startOf('month').format(),
            dateTo: moment().endOf('month').subtract(1, 'day').format(),
            professional: [],
            patologies: [],
            opatologies: [],
            provincies: [],
            oprovincies: [],
            errorStartDate: false,
            errorEndDate: false,
        }
    }

    formatEmployees = (employees) => employees.map(e => {
        return {
            id: e.personId,
            value: `${e.firstName} ${e.lastName}`,
        }
    });

    formatEmployeesType = (employeesType) => employeesType.map(e => {
        return {
            id: e.employeeTypeId,
            value: e.name,
        }
    });

    formatFinaciers = (financiers) => financiers.map(e => {
        return {
            id: e.financierId,
            value: e.fantasyName,
        };
    });

    formatPatologies = (patologies) => patologies.map(e => {
        return {
            id: e.id,
            value: e.name,
        }
    });

    formatProvincies = (provincies) => provincies.map(e => {
        return {
            id: e.provinceId,
            value: e.name,
        }
    });

    componentDidMount() {
        if (this.props.employee && this.props.employee.length) {
            this.setState({
                employees: this.props.employees,
                professionals: this.formatEmployees(this.props.employees),
            });
        } else {
            this.getEmployees();
        }

        if (this.props.employeesType && this.props.employeesType.length) {
            this.setState({
                employeesType: this.props.employeesType,
                oemployeesType: this.formatEmployeesType(this.props.employeesType),
            });
        } else {
            this.getEmployeesType();
        }

        if (this.props.financiers && this.props.financiers.length) {
            this.setState({
                financiers: this.props.financiers,
                ofinanciers: this.formatFinaciers(this.props.financiers),
            });
        } else {
            this.getFinanciers();
        }

        if (this.props.patologies && this.props.patologies.length) {
            this.setState({
                patologies: this.props.patologies,
                opatologies: this.formatPatologies(this.props.patologies),
            });
        } else {
            this.getPatologies();
        }

        if (this.props.provincies && this.props.provincies.length) {
            this.setState({
                provincies: this.props.provincies,
                oprovincies: this.formatProvincies(this.props.provincies),
            });
        } else {
            this.getProvincies();
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees && next.employees.length) {
            this.setState({
                employees: next.employees,
                professionals: this.formatEmployees(next.employees),
            });
        }
        if (next.employeesType && next.employeesType.length) {
            this.setState({
                employeesType: next.employeesType,
                oemployeesType: this.formatEmployeesType(next.employees),
            });
        }
        if (next.financiers && next.financiers.length) {
            this.setState({
                financiers: next.financiers,
                ofinanciers: this.formatFinaciers(next.financiers),
            });
        }
        if (next.patologies && next.patologies.length) {
            this.setState({
                patologies: next.patologies,
                opatologies: this.formatPatologies(next.patologies),
            });
        }
        if (next.provincies && next.provincies.length) {
            this.setState({
                provincies: next.provincies,
                oprovincies: this.formatProvincies(next.provincies),
            });
        }
    }

    getPatologies() {
        RecordsApiInvoker.getAllPatologies(pato => {
            if (pato && pato.length) {
                this.props.onGetPatologies(pato);
            }
        }, err => console.error('** error getAllPatologies', err));
    }

    getEmployeesType() {
        AppointmentApiInvoker.getPracticeTypes(practiceType => {
            if (practiceType && practiceType.length) {
                this.props.onGetEmployeesType(practiceType);
            }
        }, err => console.error('** error getPracticeTypes', err));
    }

    getEmployees() {
        PersonApiInvoker.getEmployees(employees => {
            if (employees && employees.length) {
                this.props.onGetEmployees(employees);
            }
        }, err => console.error('** error getEmployees', err));
    }

    getFinanciers() {
        BusinessApiInvoker.getFinanciers(finan => {
            if (finan && finan.length) {
                this.props.onGetFinanciers(finan);
            }
        }, err => console.error('** error getFinanciers', err));
    }

    getProvincies() {
        ApiInvoker.getAllProvinces(prov => {
            if (prov && prov.length) {
                this.props.onGetProvincies(prov);
            }
        }, err => console.error('** error getAllProvinces', err));
    }

    findProfessional = (personId) => this.state.professionals.find(p =>  p.personId === personId);

    handleValue = (value, state) => {
        if (state === 'dateFrom') {
            this.setState({ errorStartDate: false });
        }
        if (state === 'dateTo') {
            this.setState({ errorEndDate: false });
        }
        this.setState({ [state]: value });
    }

    sendFilters() {
        let errorStartDate = false;
        if (!this.state.dateFrom) {
            errorStartDate = true;
        }
        let errorEndDate = false;
        if (!this.state.dateTo) {
            errorEndDate = true;
        }

        if (errorStartDate || errorEndDate) {
            this.setState({ errorStartDate: errorStartDate, errorEndDate: errorEndDate });
        } else {
            this.props.onChange({
                start_date: this.state.dateFrom,
                end_date: this.state.dateTo,
            });
        }
    }

    onChangeChips = (index, chips) => this.props.onChangeChips({ chips });

    cleanFilters() {
        this.setState({
            professional: [],
            dateFrom: '',
            dateTo: '',
        }, () => {
            this.props.onClearFilters();
        });
    }

    render() {
        const { t, chips, loading } = this.props;
        const {
            dateFrom, dateTo, errorEndDate, errorStartDate, oemployeesType, professionals, ofinanciers, opatologies, oprovincies
        } = this.state;
        const disabledButton = !(dateFrom && dateTo);

        return (
            <GridContainer className="filters" justify="center">
                <GridItem xs={12} sm={3} md={2} className="date-nopadding">
                    <DateInput
                        text={t('dashboardcustomer.filters.from')}
                        value={dateFrom}
                        onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
                        error={errorStartDate}
                        isRequired={true}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={2} className="date-nopadding">
                    <DateInput
                        text={t('dashboardcustomer.filters.to')}
                        minDate
                        min={dateFrom}
                        value={dateTo}
                        onChangeValue={(value) => this.handleValue(value, 'dateTo')}
                        error={errorEndDate}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={6}>
                    <ChipFilters
                        chips={chips}
                        onChangeChips={this.onChangeChips}
                        employeesType={oemployeesType}
                        employees={professionals}
                        financiers={ofinanciers}
                        patologies={opatologies}
                        provincies={oprovincies}
                    />
                </GridItem>
                <GridItem className="filters-actions" xs={12} sm={2} md={2}>
                    <ComponentDisabledWithTooltip
                        tooltipText={t('dashboardCustomer.buttonTooltip')}
                        disabled={disabledButton}
                        loading={loading}
                        component={
                            <ButtonSpinner
                                block
                                label={t('dashboard.filters.search')}
                                labelLoading={t('dashboard.filters.searching')}
                                loading={loading}
                                disabled={disabledButton || loading}
                                onClick={() => this.sendFilters()}
                                color="success"
                            />
                        }
                    />
                    <Button
                        block
                        onClick={() => this.cleanFilters()}
                        color="danger"
                    >
                        {t('dashboard.filters.clean')}
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }
}

Filters.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onGetFinanciers: PropTypes.func.isRequired,
    onGetProvincies: PropTypes.func.isRequired,
    onGetPatologies: PropTypes.func.isRequired,
    onGetEmployeesType: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    employeesType: PropTypes.array,
    loading: PropTypes.bool,
}

export default withTranslation()(Filters);
