export const SUPPLYCATALOG_DETAIL = 'SUPPLYCATALOG_DETAIL'
export const ADD_SUPPLYCATALOG = 'ADD_CSUPPLYCATALOG';
export const EDIT_SUPPLYCATALOG = 'EDIT_CSUPPLYCATALOG';
export const UNMOUNT_SUPPLYCATALOG= 'UNMOUNT_SUPPLYCATALOG';


export function getSupplyCatalog(supplycatalog) {
    return {type: SUPPLYCATALOG_DETAIL, supplycatalog}
}

export function addSupplyCatalog(supplycatalog) {
    return {type: ADD_SUPPLYCATALOG, supplycatalog};
}

export function editSupplyCatalog(supplycatalog) {
    return {type: EDIT_SUPPLYCATALOG, supplycatalog};
}

export function unmountCatalogSupply() {
    return {type: UNMOUNT_SUPPLYCATALOG};
}