import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ButtonSpinner from '../../ButtonSpinner';
import ChipFilters from './ChipFilters';
import ComponentDisabledWithTooltip from '../../ComponentDisabledWithTooltip';
import Button from '../../CustomButtons/Button';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import ApiInvoker from '../../../api/ApiInvoker';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import BusinessApiInvoker from '../../../api/BusinessApiInvoker';
import EmployeeApiInvoker from '../../../api/EmployeeApiInvoker';
import '../Dashboard.css';

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: '',
      dateTo: '',
      employees: [],
      oemployees: [],
      financiers: [],
      ofinaciers: [],
      practiceTypes: [],
      opracticeTypes: [],
      professional: [],
      professionals: [],
      provincies: [],
      oprovincies: [],
    }
  }

  componentDidMount() {
    if (this.props.financiers && this.props.financiers.length) {
      this.setState({
        financiers: this.props.financiers,
        ofinanciers: this.formatFinaciers(this.props.financiers),
      });
    } else {
      this.getFinanciers();
    }

    if (this.props.provincies && this.props.provincies.length) {
      this.setState({
        provincies: this.props.provincies,
        oprovincies: this.formatProvincies(this.props.provincies),
      });
    } else {
      this.getProvincies();
    }

    if (this.props.employees && this.props.employees.length) {
      this.setState({
        employees: this.props.employees,
        oemployees: this.formatEmployees(this.props.employees),
      });
    } else {
      this.getEmployees();
    }
    this.getPracticeTypes();
  }

  componentWillReceiveProps(next) {
    if (next.financiers && next.financiers.length) {
      this.setState({
        financiers: next.financiers,
        ofinanciers: this.formatFinaciers(next.financiers),
      });
    }
    if (next.provincies && next.provincies.length) {
      this.setState({
        provincies: next.provincies,
        oprovincies: this.formatProvincies(next.provincies),
      });
    }
    if (next.employees && next.employees.length) {
      this.setState({
        employees: next.employees,
        oemployees: this.formatEmployees(next.employees),
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      practiceTypes: [],
      opracticeTypes: [],
    });
  }

  formatPracticeTypes(practiceTypes) {
    if (practiceTypes) {
      const result = practiceTypes.map(e => ({
        id: e.practiceTypeId,
        value: e.name,
      }));
      return result;
    }
  }

  formatFinaciers(financiers) {
    const result = financiers.map(e => ({
      id: e.financierId,
      value: e.fantasyName,
    }));
    return result;
  }

  formatProvincies = (provincies) => {
    const result = provincies.map(e => ({
      id: e.provinceId,
      value: e.name,
    }));
    return result;
  }

  formatEmployees(employees) {
    const result = employees.map(e => ({
      id: e.personId,
      value: `${e.firstName} ${e.lastName}`
    }));
    return result;
  }

  getPracticeTypes() {
    AppointmentApiInvoker.getPracticeTypesAll(practices => {
      if (practices && practices.length) {
        this.setState({ practiceTypes: practices, opracticeTypes: this.formatPracticeTypes(practices)});
      }
    }, err => console.error('** error getPracticeTypes', err));
  }

  getFinanciers() {
    BusinessApiInvoker.getFinanciers(finan => {
      if (finan && finan.length) {
          this.props.onGetFinanciers(finan);
      }
    }, err => {
      console.error('** error getFinanciers', err);
    });
  }


  getProvincies() {
    ApiInvoker.getAllProvinces(prov => {
      if (prov && prov.length) {
        this.props.onGetProvincies(prov);
      }
    }, err => {
      console.error('** error getAllProvinces', err);
    });
  }

  getEmployees() {
    EmployeeApiInvoker.getEmployees(employeeList => {
      if (employeeList && employeeList.length) {
        this.props.onGetEmployees(employeeList);
      }
    }, err => {
      console.error('** error getEmployees', err);
    });
  }

  findProfessional = (personId) => this.state.professionals.find(p => p.personId === personId)

  handleValue = (value, state) => {
    if (state === 'dateFrom') this.setState({ errorStartDate: false });
    if (state === 'dateTo') this.setState({ errorEndDate: false });
    this.setState({ [state]: value });
  }

  sendFilters() {
    let errorStartDate = false;
    if (this.state.dateFrom === '') {
      errorStartDate = true;
    }
    let errorEndDate = false;
    if (this.state.dateTo === '') {
      errorEndDate = true;
    }

    if (errorStartDate || errorEndDate) {
      this.setState({ errorStartDate, errorEndDate });
    } else {
      this.props.onChange({
        start_date: this.state.dateFrom,
        end_date: this.state.dateTo,
      });
    }
  }

  onChangeChips = (index, chips) => {
    this.props.onChangeChips({ chips });
  }

  cleanFilters() {
    this.setState({
      professional: [],
      dateFrom: '',
      dateTo: '',
    }, () => {
      this.props.onClearFilters();
    });
  }

  render() {
    const { t, chips, loading } = this.props;
    const {
      dateFrom, dateTo, errorEndDate, errorStartDate, oemployees, ofinanciers, opracticeTypes, oprovincies
    } = this.state;
    
    const disabledButton = !(dateFrom && dateTo);

    return (
      <GridContainer className="filters" justify="center">
        <GridItem xs={12} sm={3} md={2} className="date-nopadding">
          <DateInput
            text={t('dashboardprofesional.filters.from')}
            value={dateFrom}
            onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
            error={errorStartDate}
          />
        </GridItem>
        <GridItem xs={12} sm={3} md={2} className="date-nopadding">
          <DateInput
            text={t('dashboardprofesional.filters.to')}
            minDate min={dateFrom}
            value={dateTo}
            onChangeValue={(value) => this.handleValue(value, 'dateTo')}
            error={errorEndDate}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={6}>
          <ChipFilters
            chips={chips}
            financiers={ofinanciers}
            employees={oemployees}
            practiceTypes={opracticeTypes}
            provincies={oprovincies}
            onChangeChips={this.onChangeChips}  
          />
        </GridItem>
        <GridItem className="filters-actions" xs={12} sm={2}>
          <ComponentDisabledWithTooltip
            tooltipText={t('dashboard.buttonTooltip')}
            disabled={disabledButton}
            loading={loading}
            component={
              <ButtonSpinner
                block
                label={t('dashboard.filters.search')}
                labelLoading={t('dashboard.filters.searching')}
                loading={loading}
                disabled={disabledButton || loading}
                onClick={() => this.sendFilters()}
                color="success"
              />
            }
          />
          <Button
            block
            onClick={() => this.cleanFilters()}
            color="danger"
          >
            {t('dashboard.filters.clean')}
          </Button>
      </GridItem>
    </GridContainer>
    )
  }
}

Filters.defaultProps = {
  loading: false,
}

Filters.propTypes = {
  onGetFinanciers: PropTypes.func.isRequired,
  onGetProvincies: PropTypes.func.isRequired,
  onGetEmployees: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func,
  onChange: PropTypes.func,
  employees: PropTypes.array,
  loading: PropTypes.bool,
}

export default withTranslation()(Filters);
