import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import Table from '../Table/Table';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button';
import { CardContent } from '@material-ui/core';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomInput from '../CustomInput/CustomInput';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { browserHistory } from 'react-router';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import { FolderShared } from '@material-ui/icons';
import HeartWithPulse from '../../icons/HeartWithPulse';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { saveAs } from "file-saver";

import './MedicalRecordSignVitals.css';

class MedicalRecordSignVitals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schedulesVitalSigns: this.props && this.props.scheduleVitalSigns,
            VitalSignsOne: [],
            vitalSigns: [],
            scheduleInformation: {
                scheduleMedicines: [],
                vitalSignList: [],
                note: "",
                scheduleId: "",
                employeeName: "",
                practiceName: "",
                type: "",
                startDateTime: "",
                endDateTime: "",
                scheduleStatus: "",
                scheduledStartDateTime: "",
                scheduledEndDateTime: ""

            },
            resources: [],
            scheduleSelected: this.props && this.props.scheduleSelected
        }
    }

    saveFile = (url, name) => {
        window.open(url, '_blank');
    };

    componentDidMount() {
        this.getScheduleInformation(this.props.params.scheduleId)
        this.getResources(this.props.params.scheduleId)

    }

    getScheduleInformation = async (scheduleId) => {
        AppointmentApiInvoker.getScheduleInformation(scheduleId, data => {
            this.setState({ scheduleInformation: data });
        }, error => {
            console.error(error);
        });
    }

    getResources = async (scheduleId) => {
        AppointmentApiInvoker.getScheduleResource(scheduleId, data => {
            this.setState({ resources: data });
        }, error => {
            console.error(error);
        });
    }

    onBack = () => {
        browserHistory.goBack();
    }

    toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    render() {
        const { t } = this.props
        var glucose = "-";
        if (this.state.scheduleInformation && this.state.scheduleInformation.capillaryGlucoseDetail) {
            glucose =
                this.state.scheduleInformation.capillaryGlucoseDetail.capillaryGlucoseValue + " " +
                (this.state.scheduleInformation.capillaryGlucoseUnitType ? this.state.scheduleInformation.capillaryGlucoseUnitType.name + " " : "") +
                t(`common.glucose.unit.${this.state.scheduleInformation.capillaryGlucoseDetail.capillaryGlucoseClassificationType}`)

        }
        if (this.state.scheduleInformation) {
            var scheduledStartDateTime = new moment(this.state.scheduleInformation.scheduledStartDateTime).format("DD/MM/YYYY HH:mm");
            var startDateTime = new moment(this.state.scheduleInformation.startDateTime).format("DD/MM/YYYY HH:mm");
            var scheduledEndDateTime = new moment(this.state.scheduleInformation.scheduledEndDateTime).format("DD/MM/YYYY HH:mm");
            var endDateTime = new moment(this.state.scheduleInformation.endDateTime).format("DD/MM/YYYY HH:mm");
        }
        var mThis = this;
        var hasData = false;
        if (
                this.state.scheduleInformation.patientWeight || 
                this.state.scheduleInformation.capillaryGlucoseDetail ||
                this.state.scheduleInformation.note 
            ) {
            hasData = true;
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="info">
                                <CalendarToday />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('medical.record.schedule.title')}</h3>
                        </CardHeader>
                        <CardBody className="calendarBody" calendar>
                            <GridContainer className='container'>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.scheduleId')}
                                        value={this.state.scheduleInformation.scheduleId}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.status')}
                                        value={t(`schedule.booking.state.${this.state.scheduleInformation.scheduleStatus}`)}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.start_date_programed')}
                                        value={scheduledStartDateTime}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.start_date_real')}
                                        value={startDateTime}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.end_date_programed')}
                                        value={scheduledEndDateTime}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.end_date_real')}
                                        value={endDateTime}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.type')}
                                        value={this.state.scheduleInformation.type == 'Practica' ? t('label.practice') : t('label.module"')}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.practice')}
                                        value={this.state.scheduleInformation.practiceName}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomInput
                                        id="appointment_id"
                                        disabled={true}
                                        labelText={t('medical.record.detail.employee')}
                                        value={this.state.scheduleInformation.employeeName}
                                        formControlProps={{
                                            disabled: true,
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="danger">
                                <HeartWithPulse />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('medical.record.signvitals.title')}</h3>
                        </CardHeader>
                        <CardBody className="calendarBody" calendar>
                            {this.state.scheduleInformation && this.state.scheduleInformation.vitalSignList 
                            && this.state.scheduleInformation.vitalSignList.length > 0 ?
                                this.state.scheduleInformation.vitalSignList.map(function (item, i) {
                                    var date = new moment(item.timeRegistry, "DD/MM/YYYY HH:mm:SS").format("DD/MM/YYYY");
                                    var time = new moment(item.timeRegistry, "DD/MM/YYYY HH:mm:SS").format("HH:mm");
                                    
                                    return (
                                        <GridContainer className='container'>

                                            <GridItem xs={12}>
                                            <p className='header'>{t('medical.record.detail.medicines', {
                                                    date: date,
                                                    time: time
                                                })}</p>
                                            </GridItem>

                                            <GridItem xs={6}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.temperature')}
                                                    value={item.temperature ? item.temperature + " " + "° C" : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.pulse')}
                                                    value={item.pulse ? item.pulse + " por minuto" : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.oximeter')}
                                                    value={item.breathing ? (item.breathing + " %") : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.presure')}
                                                    value={ (item.pressure && item.pressure.min && item.pressure.max) ? item.pressure.max + "/" + item.pressure.min + " mmHg" : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>

                                        </GridContainer>
                                    )
                                })
                            
                            : <div className='no-data'>
                                <p style={{ width: '100%', textAlign: 'center' }}>{t('common.no.data')}</p>
                            </div>
                            
                            }
                        </CardBody>
                    </Card>
                </GridItem>

                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="danger">
                                <LocalHospitalIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('medical.record.medicine.title')}</h3>
                        </CardHeader>
                        <CardBody className="calendarBody" calendar>
                            {this.state.scheduleInformation && this.state.scheduleInformation.scheduleMedicines &&
                                this.state.scheduleInformation.scheduleMedicines.length > 0 ?
                                this.state.scheduleInformation.scheduleMedicines.map(function (item, i) {
                                    var date = new moment(item.medicineDateTime).format("DD/MM/YYYY");
                                    var time = new moment(item.medicineDateTime).format("HH:mm");
                                    var expire = "-";
                                    if (item.medicineExpireDate != null) {
                                        expire = mThis.toTitleCase(new moment(item.medicineExpireDate).format("MMMM YYYY"));
                                    }
                                    return (
                                        <GridContainer className='container'>
                                            <GridItem xs={12}>
                                                <p className='header'>{t('medical.record.detail.medicines', {
                                                    date: date,
                                                    time: time
                                                })}</p>
                                            </GridItem>

                                            <GridItem xs={3}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.medicine')}
                                                    value={item.medicineType ? item.medicineType.name : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={3}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.presentation')}
                                                    value={item.medicinePresentationType ? item.medicinePresentationType.name : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true

                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={2}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.dosis')}
                                                    value={(item.medicineDose ? item.medicineDose : "-") + " " + (item.medicineUnitType ? item.medicineUnitType.name : "-")}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={2}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.quantity')}
                                                    value={item.medicineQuantity}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={2}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.via')}
                                                    value={item.medicineViaType ? item.medicineViaType.name : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={3}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.lote')}
                                                    value={item.medicineLotNumber ? item.medicineLotNumber : "-"}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={3}>
                                                <CustomInput
                                                    id="appointment_id"
                                                    disabled={true}
                                                    labelText={t('medical.record.detail.medicines.expire')}
                                                    value={expire}
                                                    formControlProps={{
                                                        disabled: true,
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>

                                        </GridContainer>
                                    );
                                })
                                : <div className='no-data'>
                                    <p style={{ width: '100%', textAlign: 'center' }}>{t('common.no.data')}</p>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="info">
                                <NoteAddIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('medical.record.other.title')}</h3>
                        </CardHeader>
                        <CardBody className="calendarBody" calendar>
                            {hasData ?
                                <GridContainer className='container'>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            id="appointment_id"
                                            disabled={true}
                                            labelText={t('medical.record.detail.weight')}
                                            value={this.state.scheduleInformation.patientWeight ? (this.state.scheduleInformation.patientWeight + " " + t(`common.weight.unit.${this.state.scheduleInformation.weightUnit}`)) : "-"}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true

                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <CustomInput
                                            id="appointment_id"
                                            disabled={true}
                                            labelText={t('medical.record.detail.glucose')}
                                            value={glucose}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            id="appointment_id"
                                            disabled={true}
                                            labelText={t('medical.record.detail.note')}
                                            value={this.state.scheduleInformation.note ? this.state.scheduleInformation.note : "-"}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>

                                </GridContainer>
                            : <div className='no-data'>
                            <p style={{ width: '100%', textAlign: 'center' }}>{t('common.no.data')}</p>
                        </div>

                            }
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="danger">
                                <AttachmentIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('medical.record.images.title')}</h3>
                        </CardHeader>
                        <CardBody className="calendarBody" calendar>

                            {this.state.resources != null && this.state.resources.length > 0 ?
                                this.state.resources.map(function (item, i) {
                                    var date = new moment(item.scheduleResourceUploadDate).format("DD/MM/YYYY");
                                    var time = new moment(item.scheduleResourceUploadDate).format("HH:mm");
                                    return (
                                        <GridContainer className='container'>
                                            <GridItem xs={12} className='no-padding'>
                                                <p className='header'>{t('medical.record.detail.resource', {
                                                    date: date,
                                                    time: time
                                                })}</p>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <img src={item.fullURL} style={{ width: 100, height: 100 }} />
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className='file'>{t('medical.record.detail.resource.file')}</p>
                                                <a className='link' onClick={() => mThis.saveFile(item.fullURL, item.scheduleResourceName)}>{item.scheduleResourceName}</a>
                                            </GridItem>

                                        </GridContainer>
                                    );
                                })
                                : <div className='no-data'>
                                    <p style={{ width: '100%', textAlign: 'center' }}>{t('common.no.data')}</p>
                                </div>
                            }
    
                            
                        </CardBody>
                    </Card>
                </GridItem>

                <GridItem className="actions-buttons text-center">
                    <Button onClick={() => this.onBack()}>
                        {t("appointment.new.back")}
                    </Button>
                </GridItem>

            </GridContainer>
        )
    }
}

export default withTranslation()(MedicalRecordSignVitals)