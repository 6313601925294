import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'

import { Check } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import styles from '../../assets/components/customCheckboxRadioSwitch'

const useStyles = makeStyles(styles)

const CheckInput = ({
  checked,
  label,
  labelPlacement,
  onChangeValue,
  disabled,
  inputProps,
}) => {
  const classes = useStyles()

  return (
    <div className="check-input" style={{ paddingTop: 8 }}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            tabIndex={-1}
            inputProps={inputProps}
            onClick={(e) => onChangeValue(e.target.value)}
            checked={checked}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
              disabled: classes.disabledCheckboxAndRadio,
            }}
          />
        }
        classes={{ label: classes.label, root: 'check-label' }}
        label={label}
        labelPlacement={labelPlacement}
      />
    </div>
  )
}

CheckInput.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  onChangeValue: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CheckInput
