export const EMPLOYEES_DETAIL = 'EMPLOYEES_DETAIL'
export const EMPLOYEE_DETAIL = 'EMPLOYEE_DETAIL'
export const EMPLOYEE_GEOGRAPHICDETAIL = 'EMPLOYEE_GEOGRAPHICDETAIL'
export const EMPLOYEES_ADDRESS = 'EMPLOYEES_ADDRESS'
export const EMPLOYEES_PERSON_ADDRESS = 'EMPLOYEES_PERSON_ADDRESS'
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const UNMOUNT_EMPLOYEE = 'EDIT_EMPLOYEE';

export function getEmployees(employees) {
    return {type: EMPLOYEES_DETAIL, employees}
}
export function getEmployee(employee) {
    return {type: EMPLOYEE_DETAIL, employee}
}
export function getEmployeeGeographics(geographicOfEmployee) {
    return {type: EMPLOYEE_GEOGRAPHICDETAIL, geographicOfEmployee}
}

export function getAddress(address) {
    return {type: EMPLOYEES_ADDRESS, address}
}

export function getPersonAddress(personAddress) {
    return {type: EMPLOYEES_PERSON_ADDRESS, personAddress}
}

export function addEmployee(Employee) {
    return {type: ADD_EMPLOYEE, Employee};
}

export function editEmployee(Employee) {
    return {type: EDIT_EMPLOYEE, Employee};
}
export function unmountEmployee() {
    return {type: UNMOUNT_EMPLOYEE, };
}


