import { DOCUMENT_TYPESP } from '../actions/newEmployee';

const newEmployee = (state = {}, action, root) => {
    switch(action.type) {
        case DOCUMENT_TYPESP:
            const documentTypes = action.documentTypes
            return {...state, documentTypes}
        default:
            return state;
    }
}

export default newEmployee;
