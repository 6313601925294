import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import Table from '../../../../Table/Table';
import GridItem from '../../../../Grid/GridItem';
import Button from '../../../../CustomButtons/Button';

class VitalSignsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schedulesVitalSigns: this.props && this.props.scheduleVitalSigns,
            VitalSignsOne: [],
            scheduleSelected: this.props && this.props.scheduleSelected
        }
    }

    componentDidMount(){
        this.SpecificVitalSigns(this.state.schedulesVitalSigns)
    }
    
    SpecificVitalSigns = async (schedules) => {
        const { t } = this.props
        if(schedules && schedules.length) {
            schedules.filter(schedFt => schedFt.scheduleId === this.state.scheduleSelected).forEach(schedVS => {
                if(schedVS && schedVS.metadata && schedVS.metadata.vitalSignsList) {
                    let result = schedVS.metadata.vitalSignsList.map(vs => {
                        return {
                            date: vs.timeRegistry ? moment(vs.timeRegistry, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY'): moment(schedVS.scheduleDateTime).format('DD/MM/YYYY'),
                            hour: vs.timeRegistry ? moment(vs.timeRegistry, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss'): moment(schedVS.scheduleDateTime).format('HH:mm:ss'),
                            temperature: vs.temperature ? vs.temperature : t('integralDashboardClinical.modal.undefined'),
                            breathing: vs.breathing ? vs.breathing : t('integralDashboardClinical.modal.undefined'),
                            pulse: vs.pulse ? vs.pulse : t('integralDashboardClinical.modal.undefined'),
                            pressure: vs.pressure.min || vs.pressure.max ? `${vs.pressure.max ? vs.pressure.max : '-'}/${vs.pressure.min ? vs.pressure.min : '-'}` : t('integralDashboardClinical.modal.undefined')
                        }
                    })
                    this.setState({ VitalSignsOne: result})
                } else if(schedVS && schedVS.metadata && schedVS.vital_signs) {
                    this.setState({ 
                        VitalSignsOne: [{
                            date: moment(schedVS.scheduleDateTime).format('DD/MM/YYYY'),
                            hour: moment(schedVS.scheduleDateTime).format('HH:mm:ss'),
                            temperature: schedVS.metadata.vital_signs.temperature ? schedVS.metadata.vital_signs.temperature : t('integralDashboardClinical.modal.undefined'),
                            breathing: schedVS.metadata.vital_signs.breathing ? schedVS.metadata.vital_signs.breathing : t('integralDashboardClinical.modal.undefined'),
                            pulse: schedVS.metadata.vital_signs.pulse ? schedVS.metadata.vital_signs.pulse : t('integralDashboardClinical.modal.undefined'),
                            pressure: schedVS.metadata.vital_signs.pressure.min || schedVS.metadata.vital_signs.pressure.max ? `${schedVS.metadata.vital_signs.pressure.max ? schedVS.metadata.vital_signs.pressure.max : '-'}/${schedVS.metadata.vital_signs.pressure.min ? schedVS.metadata.vital_signs.pressure.min : '-'}` : t('integralDashboardClinical.modal.undefined')
                        }]
                    })
                } else {
                    this.setState({ 
                        VitalSignsOne: [{
                            date: moment(schedVS.scheduleDateTime).format('DD/MM/YYYY'),
                            hour: moment(schedVS.scheduleDateTime).format('HH:mm:ss'),
                            temperature: t('integralDashboardClinical.modal.undefined'),
                            breathing: t('integralDashboardClinical.modal.undefined'),
                            pulse: t('integralDashboardClinical.modal.undefined'),
                            pressure: t('integralDashboardClinical.modal.undefined')
                        }]
                    })
                }
            }) 
        }
    }
    
    render() {
        const { t }  = this.props
        return (
            <React.Fragment>
                 <Table
                    loading={this.state.loading}
                    tableHeaderColor="primary"
                    filtrable
                    sortable
                    tableHead={[
                        { Header: t('integralDashboardClinical.modal.table.date'), accessor: 'date'},
                        { Header: t('integralDashboardClinical.modal.table.hour'), accessor: 'hour' },
                        { Header: `${t('integralDashboardClinical.modal.table.temperature')} (°C)`, accessor: 'temperature' },
                        { Header: `${t('integralDashboardClinical.modal.table.breathing')} (%)`, accessor: 'breathing' },
                        { Header: `${t('integralDashboardClinical.modal.table.pulse')} (ppm)`, accessor: 'pulse' },
                        { Header: `${t('integralDashboardClinical.modal.table.pressure')} (mmHg)`, accessor: 'pressure'}
                    ]}
                    defaultPageSize={this.state.VitalSignsOne.length > 5 ? 20 : 5}
                    tableData={this.state.VitalSignsOne}
                    colorsColls={["primary"]}
                    showPaginationBottom={true}
                />
                
            </React.Fragment>
        );
    }
}

export default withTranslation()(VitalSignsModal)