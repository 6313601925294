import './Login.css'

import {
  Checkbox,
  CircularProgress,
  FilledInput,
  FormControl,
  FormControlLabel,
  IconButton,
  withStyles,
} from '@material-ui/core'
import { Link, browserHistory } from 'react-router'
import React, { Component } from 'react'

import BusinessIcon from '@material-ui/icons/Business'
import Button from '../CustomButtons/Button'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardFooter from '../Card/CardFooter'
import { Check } from '@material-ui/icons'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import LetterAvatar from '../Avatar/LetterAvatar'
import Person from '@material-ui/icons/Person'
import PropTypes from 'prop-types'
import SelectInput from '../SelectInput'
import Snackbar from '../Snackbar/Snackbar'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import VpnKey from '@material-ui/icons/VpnKey'
import hmmLogo from '../../assets/img/login/logo-hmm.png'
import isEmpty from 'lodash/isEmpty'
import pwdBy from '../../assets/img/glin-white.png'
import styles from '../../assets/components/customCheckboxRadioSwitch'
import { withTranslation } from 'react-i18next'
import ReCAPTCHA from "react-google-recaptcha";
import Chat from '../Chat/index.js';
import ChatBubble from '@material-ui/icons/ChatBubble';
var CryptoJS = require("crypto-js");

class Login extends Component {
  profileTimeOut

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      disableInteractions: false,
      alertColor: 'success',
      alertMessage: '',
      alertOpen: false,
      checking: false,
      rememberMe: false,
      showPassword: false,
      realms: [],
      disabled_realm: false,
      realm: '',
      profile: {},
      KeyCaptcha: null,
      isSubmitLogin: false,
      isChatVisible: false
    }
  }

  componentWillMount() {

    // code to run on component mount
    const fetchData = async () => {
      console.log("get keys");
      const response = await fetch('/getkeys/');
      let data = await response.json();
      console.log("after data");
      console.log(data);
      this.setState({KeyCaptcha:data.key});
    }

    console.log("fetch");
    fetchData()
    clearTimeout(this.profileTimeOut)
  }

  handleLogin = async () => {
    this.setState({ isSubmitLogin: true })
    let realm = this.state.realms.filter((r) => r['id'] == this.state.realm)
    if (realm.length == 0) {
      return
    }
    let password = await this.encryptToLoginPassword();
    const { t } = this.props
    this.setState({
      disableInteractions: true,
      loading: true,
    })
    realm = realm[0]['code']
    fetch(`/api/auth/token`, {
      method: 'POST',
      body: JSON.stringify({
        username: document.getElementById('username').value,
        password: password,
        realm: realm,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          fetch(`/api/profile`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => response.json())
            .then((profile) => {
              if (profile) {
                this.setState({ profile }, () => {
                  this.profileTimeOut = setTimeout(() => {
                    this.setState({
                      disableInteractions: false,
                      loading: false,
                    })
                    //this.props.onGetCompanies(profile.companies);
                    if (profile.companies && profile.companies.length > 0) {
                      localStorage.setItem(
                        'company',
                        JSON.stringify(profile.companies[0])
                      )
                      localStorage.setItem(
                        'itlg_default_company_id',
                        profile.companies[0].company_id
                      )
                      localStorage.setItem(
                        'itlg_default_company_name',
                        profile.companies[0].name
                      )
                    }
                    if (profile.company_partnership_id != null) {
                      localStorage.setItem(
                        'company_partnership_id',
                        profile.company_partnership_id == null ||
                          profile.company_partnership_id == 'null'
                          ? null
                          : profile.company_partnership_id
                      )
                    } else {
                      localStorage.removeItem('company_partnership_id')
                    }
                    localStorage.setItem('user_id', profile.userId)
                    localStorage.setItem(
                      'userName',
                      `${profile.firstName} ${profile.lastName}`
                    )
                    localStorage.setItem('email', profile.email)
                    localStorage.setItem('APP_VERSION', profile.APP_VERSION)
                    localStorage.setItem('RELEASE_DATE', profile.RELEASE_DATE)
                    browserHistory.push('/home')
                  }, 3000)
                })
              }
            })
        } else {
          this.openAlert('danger', t('login.bad_credentials'))
          this.setState({
            disableInteractions: false,
            loading: false,
          })
        }
      })
      .catch((e) => {
        this.openAlert('danger', t('login.bad_credentials'))
        this.setState({
          disableInteractions: false,
          loading: false,
        })
      })
  }

  handleRealms = () => {
    const { t } = this.props
    this.setState({
      disableInteractions: true,
      loading: true,
    })
    fetch(`/api/realms`, {
      method: 'POST',
      body: JSON.stringify({
        username: document.getElementById('username').value,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          disableInteractions: false,
          loading: false,
        })

        if (!data.error) {
          var realms = []
          data.forEach((element, idx) => {
            realms.push({
              id: idx + 1,
              value: element['realmName'],
              code: element['realm'],
            })
          })
          if (realms.length == 0) {
            this.setState({ realms: realms, realm: -1, disabled_realm: true })
          } else if (realms.length == 1) {
            this.setState({
              realms: realms,
              realm: realms[0]['id'],
              disabled_realm: true,
            })
          } else {
            this.setState({ realms: realms, realm: realms[0]['id'] })
          }
        } else {
          this.openAlert('danger', t('login.bad_credentials'))
          this.setState({
            disableInteractions: false,
            loading: false,
          })
        }
      })
      .catch((e) => {
        this.setState({
          disableInteractions: false,
          loading: false,
        })
      })
  }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    })

    setTimeout(() => {
      this.setState({ alertOpen: false })
    }, 5000)
  }

  handleRememberMe = (event) => {
    this.setState({ rememberMe: event.target.checked })
  }

  handleValue = (value) => {
    this.setState({ realm: value })
  }

  validateLogin = () => {
    const { t, classes } = this.props
    fetch(`/recaptcha/`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'g-recaptcha-token': this.state.token
      })
    })
      .then(response => {
        return response.json();
      }
      )
      .then(data => {
        console.log(data)
        if (data.success) {
          this.handleLogin();
        } else {
          
          this.openAlert('danger', t('login.is.robot'));
        }
      })
      .catch((error) => {
        this.openAlert('danger', t('login.is.robot'));
      });
  }


  fetchKeys = async () => {
    const response = await fetch('/getkeys/');
    const data = await response.json();
    console.log(data.privateKey);
    this.setState({passphrase: data.privateKey});
    /*
    setKeyCaptcha(data.captchaKey);
    setPassphrase(data.privateKey);
    */
  }

  encryptPassword = async () => {
    try {
        console.log(document.getElementById('password').value);
        console.log(this.state.passphrase);
        let p = CryptoJS.AES.encrypt(document.getElementById('password').value, this.state.passphrase).toString();
        console.log(p);
        return p;

    } catch (error) {
        console.error('** Error getEncryptedPass:', error);
        return null;
    }
  }

  encryptToLoginPassword = async () => {
    if (!this.state.passphrase) {
        await this.fetchKeys();
    }
    return await this.encryptPassword();
  }

  onVerify = (token) => {
    this.setState({token: token});
  }
  
  startChat = () => {
    this.setState((prev) => ({ ...prev, isChatVisible: !this.state.isChatVisible }));
  }
  render() {
    const { t, classes } = this.props
    console.log(this.state.KeyCaptcha);
    if (this.state.KeyCaptcha == null) {return <div/>}
    return (
      <div>
        <div>
          <div className="wrapper">
            <div className="fullpage">
              <div className="login">
              <Button
                            id="button-signin"
                            onClick={this.startChat}
                            class="chat-floating">
                                <ChatBubble />
                        </Button>
                        <Chat login={true} isVisible={this.state.isChatVisible} /> 
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8}>
                    <div className="hmm-logo">
                      <img src={hmmLogo} alt="HMM Logo" />
                    </div>
                    <form
                      id="login-form"
                      onSubmit={this.handleLogin}
                      onKeyPress={({ key }) =>
                        key === 'Enter' && this.handleLogin()
                      }
                    >
                      <Card login className="login-card">
                        <CardBody>
                          {this.state.loading &&
                            !isEmpty(this.state.profile) ? (
                            <div className="loading-profile">
                              <LetterAvatar
                                user={
                                  this.state.profile.firstName +
                                  ' ' +
                                  this.state.profile.lastName
                                }
                              />
                              <span className="loading-profile-welcome">
                                {t('login.welcome')}
                              </span>
                              <span className="loading-profile-username">
                                {`${this.state.profile.firstName} ${this.state.profile.lastName}`}
                              </span>
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : (
                            <React.Fragment>
                              <FormControl variant="filled">
                                <FilledInput
                                  disabled={this.state.disableInteractions}
                                  id="username"
                                  onBlur={() => this.handleRealms()}
                                  inputProps={{
                                    disabled: this.state.disableInteractions,
                                    placeholder: t('login.user'),
                                  }}
                                  type="text"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <Person />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>

                              <FormControl>
                                <SelectInput
                                  className="company"
                                  placeholder={t('login.realm')}
                                  disabled={this.state.disabled_realm}
                                  elements={this.state.realms}
                                  value={this.state.realm}
                                  onSelectedValue={(value) =>
                                    this.handleValue(value)
                                  }
                                  invalid={
                                    !this.state.realm &&
                                    this.state.isSubmitLogin
                                  }
                                  error={
                                    !this.state.realm &&
                                    this.state.isSubmitLogin
                                  }
                                  isAdornedStart={true}
                                  iconAdornedStart={BusinessIcon}
                                />
                              </FormControl>

                              <FormControl variant="filled">
                                <FilledInput
                                  disabled={this.state.disableInteractions}
                                  id="password"
                                  inputProps={{
                                    disabled: this.state.disableInteractions,
                                    placeholder: t('login.password'),
                                  }}
                                  type={
                                    !this.state.showPassword
                                      ? 'password'
                                      : 'text'
                                  }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <VpnKey />
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          this.setState({
                                            showPassword:
                                              !this.state.showPassword,
                                          })
                                        }
                                        edge="end"
                                      >
                                        {this.state.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </React.Fragment>
                          )}
                        </CardBody>
                        {!this.state.loading ? (
                          <CardFooter className="login-sign-in">
                            <Button
                              onClick={this.validateLogin}
                              className="signIn"
                              variant="contained"
                              disabled={this.state.disableInteractions}
                            >
                              {t('login.signin')}
                            </Button>
                            <ReCAPTCHA sitekey={this.state.KeyCaptcha} onChange={this.onVerify} />

                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    className="login-remember-me"
                                    checked={this.state.rememberMe}
                                    onChange={this.handleRememberMe}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: 'check-label',
                                }}
                                label={t('login.remember_me')}
                              />
                            </div>
                            <div
                              className="login-recover"
                              style={
                                this.state.loading
                                  ? {
                                    pointerEvents: 'none',
                                  }
                                  : {}
                              }
                            >
                              <Link className="login-recover-link" to="/">
                                {t('login.forgot')}
                              </Link>
                            </div>
                          </CardFooter>
                        ) : null}
                      </Card>
                      <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                      />
                    </form>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="login-footer powered-by">
                <a
                  href="https://glin-technologies.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Glin"
                >
                  <img src={pwdBy} alt="Glin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  onUserLogged: PropTypes.func.isRequired,
  companies: PropTypes.array,
  onGetCompanies: PropTypes.func,
}

export default withStyles(styles)(withTranslation()(Login))
