import './ConfirmUser.css'

import React, { Component } from 'react'

import { FileCopyOutlined } from '@material-ui/icons'
import { InfoOutlined } from '@material-ui/icons'
import PersonApiInvoker from '../../api/PersonApiInvoker'
import RadioInput from '../RadioInput'
import { RemoveRedEye } from '@material-ui/icons'
import Snackbar from '../Snackbar/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'
import { VisibilityOff } from '@material-ui/icons'
import apple from '../../assets/img/apple.png'
import { browserHistory } from 'react-router'
import google from '../../assets/img/google.png'
import hmmLogo from '../../assets/img/login/logo-hmm.png'
import { t } from 'i18next'
import { withTranslation } from 'react-i18next'

class NewUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      name: '',
      lastName: '',
      selectedRole: 'PROF',
      openDialog: false,
      employeeTypes: [],
      selectedEmployeeTypes: [],
      save: false,
      alertColor: 'info',
      alertMessage: '',
      alertOpen: false,
      loading: true,
      openCancelWarning: false,
      hidden: false,
    }
  }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    })

    setTimeout(() => {
      this.setState({ alertOpen: false })
    }, 5000)
  }

  componentWillMount() {
    const { t } = this.props
    this.setState({ hash: this.props.params.hash })

    PersonApiInvoker.createUser(
      this.props.params.hash,
      (data) => {
        this.setState({
          response: data,
        })
      },
      (e) => {
        this.setState({ alertMessage: t('invitation.expired') })
      }
    )
  }

  eye = () => {
    this.setState({
      hidden: !this.state.hidden,
    })
  }

  copy = async () => {
    if (this.state.response) {
      var text = this.state.response.password
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
      } else {
        return document.execCommand('copy', true, text)
      }
    }
  }

  render() {
    const { t } = this.props
    var password = ''
    if (this.state.response) {
      if (this.state.hidden) {
        password = '*************'
      } else {
        password = this.state.response.password
      }
    }
    return (
      <div className="container">
        <div className="hmm-logo">
          <img src={hmmLogo} alt="HMM Logo" />
        </div>
        <hr className="linea" />
        <p className="label">{t('user.confirm.hello')}</p>
        <p className="label">{t('user.confirm.subtitle1')}</p>
        <p className="labelAccount">{t('user.confirm.subtitle2')}</p>
        <p className="labelEmail">{t('user.confirm.subtitle3')}</p>
        <p className="labelPassword">
          {t('user.confirm.subtitle4')}

          <span className="icons">
            <span class="password">{password}</span>
            <a href="#" className="icons" onClick={this.eye}>
              {this.state.hidden ? (
                <VisibilityOff style={{ color: 'gray', marginTop: 0 }} />
              ) : (
                <RemoveRedEye style={{ color: 'gray', marginTop: 0 }} />
              )}
            </a>
            <a href="#" className="icons" onClick={this.copy}>
              <FileCopyOutlined style={{ color: 'gray', marginTop: 0 }} />
            </a>
          </span>
        </p>

        {this.state.alertMessage && (
          <p className="labelError">{this.state.alertMessage} </p>
        )}

        <div className="footer">
          <span className="footer-text">
            <span className="labelDownload">{t('user.confirm.stores')} </span>
            <a href="https://apps.apple.com/ar/app/home-medical-management/id1522565212">
              <img src={apple} className="stores" alt="Apple" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.itlg.hmm&hl=es_AR&gl=US">
              <img src={google} className="stores" alt="Google" />
            </a>
          </span>
        </div>
      </div>
    )
  }
}

export default withTranslation()(NewUser)
