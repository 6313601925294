import { TOOLTYPES_DETAIL,   ADD_TOOLTYPE, EDIT_TOOLTYPE } from "../actions/tooltypes";

const tooltypes = (state = {}, action, root) => {

    switch(action.type) {
        case TOOLTYPES_DETAIL:
            let tooltypes = action.tooltypes
            return {...state, tooltypes}
        case ADD_TOOLTYPE: 
                const result = addToolType(state.tooltypes, action.tooltype)
                return {...state, customers: result}
        case EDIT_TOOLTYPE: 
                const updated = updateToolType(state.tooltypes, action.tooltype)
                return {...state, customers: updated}
        default:
            return state
    }
}


const addToolType = (tooltypes, tooltype) => {
    if (tooltypes && tooltypes.length) {
        tooltypes.push(tooltype);
        return tooltypes;
    }
    return;
}

const updateToolType = (tooltypes, tooltype) => {
    if (tooltypes && tooltypes.length) {
        tooltypes.map((c, i) => {
            if (c.toolTypeId === tooltype.perstoolTypeIdonId) {
                tooltypes[i] = tooltypes
                return;
            }
        })
    }
    return tooltypes;
}

export default tooltypes;
