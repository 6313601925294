import { connect } from 'react-redux';
import { fetchBookings } from '../actions/bookings';
import { deselectAllTools, fetchAppointmentTools, fetchAppointmentToolsEdit, checkAppointmentTools, saveAppointmentTools, deleteAppointmentTools, checkAppointmentToolsEdit,undoTools} from '../actions/appointments';
import AppointmentTools from '../components/AppointmentTools';
import AppointmentToolsModal from '../components/AppointmentTools/AppointmentToolsModal';

const mapStateToProps = (state) => {
    return {
        bookings: state.bookings.bookings,
        newSchedule: state.newSchedule,
        appointmentsTools: state.appointments.appointmentsTools,
        appointmentsToolsEdit: state.appointments && state.appointments.selectedAppointment && state.appointments.selectedAppointment.toolsList,
        appointmentId: state.newSchedule.schedule && state.newSchedule.schedule.appointmentId,
        checkedToolsList: state.appointments.checkedToolsList,
        savedTools: state.appointments.savedTools,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetBookings: (bookings) => {
            dispatch(fetchBookings(bookings))
        },
        onGetAppointmentsTools: (appointmentTools) => {
            dispatch(fetchAppointmentTools(appointmentTools))
        },
        onAppointmentToolsSave: (appointmentId, checkedToolsList) => {
            const savedTools = {appointmentId: appointmentId || "",
                                 checkedToolsList: checkedToolsList}
            dispatch(saveAppointmentTools(savedTools))
        },
        onCheckAppointmentTools: (checkedTools) => {
            dispatch(checkAppointmentTools(checkedTools))
        },  
        onCheckAppointmentToolsEdit: (checkedTools) => {
            dispatch(checkAppointmentToolsEdit(checkedTools))
        },
        onDeleteAppointmentTool: (tool) => {
            dispatch(deleteAppointmentTools(tool))
        },
        onClearSelectedTools: () => {
            dispatch(deselectAllTools())
        },
        onUndoSelectedTools: (checklist, tools) => {
            dispatch(undoTools(checklist, tools))
        }

    }
}


const Modal = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentToolsModal)

const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentTools)

export default { 
    Modal,
    Form,
}