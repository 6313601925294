import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Navbar from '../Navbar';
import AdminNavbar from '../Navbars/AdminNavbar';
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from "../Table/Table";
import ScheduleDetail from '../ScheduleDetail';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRange from '@material-ui/icons/DateRange';
import CalendarToday from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import _ from 'lodash';
import './Schedules.css'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ChartistGraph from "react-chartist";
import * as Chartist from 'chartist';
import ApiInvoker from "../../api/ApiInvoker";
import AppointmentApiInvoker from "../../api/AppointmentApiInvoker";
import config from '../../config/config.js'
import Authority from '../../util/Authority';

class Schedules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schedules: [],
            vitalSigns: {
                pulse: {},
                pressure: {},
                breath: {},
                temperature: {},
            },
            status: [],
            types: [],
            customer: '',
            scheduleDetail: {},
            openSchedule: false,
            dateFrom: '',
            dateTo: new moment(),
            selectedStatus: '',
            selectedTypes: '',
            loading: false,
            activeTab: 'visits-history'
        }
    }

    handleSwitchTab(event, newValue) {
        this.setState({
            activeTab: newValue
        })
    }

    componentWillMount() {
        this.getSchedules()

        if (this.props.status) {
            this.setState({
                status: this.props.status
            })
        } else {
            this.getStatus()
        }
    }

    componentWillReceiveProps(next) {
        if (next.schedules) {
            this.formatSchedules(next.schedules)
            if (next.schedules && next.schedules.length) {
                this.setState({
                    customer: `${next.schedules[0].appointmentDetail.appointment.customer.firstName} ${next.schedules[0].appointmentDetail.appointment.customer.lastName}`
                })
            }
        }

        if (next.status) {
            this.setState({
                status: next.status
            })
        }
    }

    getSchedules() {
        this.setState({
            loading: true,
        })
        AppointmentApiInvoker.getCustomerSchedules(this.props.routeParams.id, data => {
            this.setState({
                loading: false,
            })
            data = _.orderBy(data, 'scheduleDateTime', 'desc')
            this.props.onGetSchedules(data)
        }, e => {
            this.setState({
                loading: false,
            })
        }, null)
    }

    getStatus() {
        AppointmentApiInvoker.getScheduleStatuses(data => {
            this.props.onGetStatus(data)
        }, null)
    }

    formatSchedules(schedules) {

        const _data = schedules.map(this.scheduleDataFormater.bind(this));
        const data = _.sortBy(_data, 'dateMoment');
        const vitalSigns = this.vitalSignsFormater(data);
        this.setState({
            schedules: data,
            vitalSigns
        })
    }

    scheduleDetail(id) {
        this.props.schedules.map(s => {
            if (s.scheduleId === id) {
                ApiInvoker.getSupplyCatalogHistory(id, (result) => {
                    this.setState({
                        scheduleDetail: s,
                        openSchedule: true,
                        supplyList: result
                    })
                }, null);
            }
        })
    }


    handleProfessional(e) {
        const professional = e.target.value
        const _data = this.props.schedules.filter(s => {
            const currentProfessional = `${s.employee.firstName} ${s.employee.lastName}`
            return currentProfessional.toLowerCase().indexOf(professional.toLowerCase()) !== -1;
        }).map(this.scheduleDataFormater.bind(this))
        const data = _.sortBy(_data, 'dateMoment');
        const vitalSigns = this.vitalSignsFormater(data);
        this.setState({
            schedules: data,
            vitalSigns,
        })
    }

    handleStatus(e) {
        const status = e.target.value

        const _data = this.props.schedules.filter(s => {
            return s.scheduleStatus.scheduleStatusId === status
        }).map(this.scheduleDataFormater.bind(this))
        const data = _.sortBy(_data, 'dateMoment');
        const vitalSigns = this.vitalSignsFormater(data);
        this.setState({
            schedules: data,
            selectedStatus: status,
            vitalSigns,
        })
    }

    handleTypes(e) {
        const type = e.target.value
        const _data = this.props.schedules.filter(s => {
            return s.appointment.appointmentType.appointmentTypeId === type
        }).map(this.scheduleDataFormater.bind(this))
        const data = _.sortBy(_data, 'dateMoment');
        const vitalSigns = this.vitalSignsFormater(data);
        this.setState({
            schedules: data,
            selectedTypes: type,
            vitalSigns
        })
    }


    scheduleDataFormater(s) {
        return {
            date: moment(s.scheduleDateTime).format('DD/MM/YYYY HH:mm'),
            dateMoment: moment(s.scheduleDateTime),
            employeeName: `${s.employee.firstName} ${s.employee.lastName}`,
            appAddress: s.appointmentDetail.address ? s.appointmentDetail.address.address : '',
            status: s.scheduleStatus.name,
            note: _.get(s, 'metadata.note', ''),
            actions: <Button simple color="rose" onClick={() => this.scheduleDetail(s.scheduleId)}>Detalle</Button>,
            vitalSigns: _.get(s, 'metadata.vital_signs', this.state.vitalSigns),
        }
    }
    vitalSignsFormater(data) {
        const labels = data.map(d => {
            return d.dateMoment.format('DD/MM');
        })
        return {
            pulse: {
                labels,
                options: {
                    lineSmooth: Chartist.Interpolation.cardinal({
                        tension: 10
                    }),
                    axisY: {
                        showGrid: true,
                        offset: 40
                    },
                    axisX: {
                        showGrid: false
                    },
                    low: 40,
                    showPoint: true,
                    height: "230px"
                },
                series: [
                    data.map(d => parseInt(d.vitalSigns ? d.vitalSigns.pulse : 0), 10)
                ]
            },
            temperature: {
                labels,
                options: {
                    lineSmooth: Chartist.Interpolation.cardinal({
                        tension: 10
                    }),
                    axisY: {
                        showGrid: true,
                        offset: 40
                    },
                    axisX: {
                        showGrid: false
                    },
                    low: 26,
                    showPoint: true,
                    height: "230px"
                },
                series: [
                    data.map(d => parseInt(d.vitalSigns ? d.vitalSigns.temperature : '0'), 10)
                ]
            },
            breath: {
                labels,
                options: {
                    lineSmooth: Chartist.Interpolation.cardinal({
                        tension: 10
                    }),
                    axisY: {
                        showGrid: true,
                        offset: 40
                    },
                    axisX: {
                        showGrid: false
                    },
                    low: 55,
                    showPoint: true,
                    height: "230px"
                },
                series: [
                    data.map(d => parseInt(d.vitalSigns ? d.vitalSigns.breathing : '0'), 10)
                ]
            },
            pressure: {
                labels,
                options: {
                    lineSmooth: Chartist.Interpolation.cardinal({
                        tension: 10
                    }),
                    axisY: {
                        showGrid: true,
                        offset: 40
                    },
                    axisX: {
                        showGrid: false
                    },
                    low: 50,
                    showPoint: true,
                    height: "230px"
                },
                series: [
                    data.map(d => parseInt(d.vitalSigns && d.vitalSigns.pressure && d.vitalSigns.pressure.max ? d.vitalSigns.pressure.max : '0'), 10),
                    data.map(d => parseInt(d.vitalSigns && d.vitalSigns.pressure && d.vitalSigns.pressure.min ? d.vitalSigns.pressure.min : '0'), 10),
                ],
            },
        }
    }

    handleCloseSchedule() {
        this.setState({
            openSchedule: false,
        })
    }

    render() {
        return (
            <div className="schedules">
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <DateRange />
                                </CardIcon>
                                <Tabs
                                    value={this.activeTab || ''}
                                    onChange={this.handleSwitchTab.bind(this)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab icon={<PersonPinIcon />} label="Historial de visitas" value={'visits-history'} />
                                    <Tab icon={<FavoriteIcon />} label="Historial de signos vitales" value={'vital-signs-history'} />
                                </Tabs>
                                <div className="schedules-filters schedules-filters-row">
                                    <CustomInput
                                        labelText="Profesional"
                                        id="professional"
                                        formControlProps={{
                                            fullWidth: false,
                                        }}
                                        onChange={(e) => this.handleProfessional(e)}
                                    />
                                    <FormControl className="schedules-dropdown">
                                        <InputLabel
                                            htmlFor="status-select">
                                            Estado
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                            }}
                                            value={this.state.selectedStatus}
                                            onChange={(event) => this.handleStatus(event)}
                                            inputProps={{
                                                name: "statusSelect",
                                                id: "status-select"
                                            }}
                                        >
                                            {
                                                this.state.status.map(s => {
                                                    return (
                                                        <MenuItem value={s.scheduleStatusId}>
                                                            {s.name}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </CardHeader>
                            {
                                this.state.activeTab === 'visits-history' ?
                                    <CardBody className="appointments-content-body not-scroll-table">
                                        <Table
                                            loading={this.state.loading}
                                            tableHeaderColor="primary"
                                            tableHead={[
                                                { Header: "Fecha/HoraXX", accessor: 'date' },
                                                { Header: "Profesional", accessor: 'employeeName' },
                                                { Header: "Estado", accessor: 'status' },
                                                { Header: "Acciones", accessor: 'actions', sortable: false }
                                            ]}
                                            colorsColls={["primary"]}
                                            tableData={this.state.schedules}
                                        />
                                    </CardBody>
                                        :   
                                    <CardBody> 
                                        <Card className="chart-container">
                                            <CardHeader className="chart-header"> Presión Arterial </CardHeader>
                                            <CardBody>
                                                <ChartistGraph
                                                    data={this.state.vitalSigns.pressure}
                                                    type="Line"
                                                    options={this.state.vitalSigns.pressure.options}
                                                />
                                                <h4 className="chart-legend">
                                                    <span className="chart-legend-blue">Presión Max</span>
                                                    <span className="chart-legend-red">Presión Min</span>
                                                </h4>
                                            </CardBody>
                                        </Card>
                                        <Card className="chart-container">
                                            <CardHeader className="chart-header"> Pulso </CardHeader>
                                            <CardBody>
                                                <ChartistGraph
                                                    data={this.state.vitalSigns.pulse}
                                                    type="Line"
                                                    className="chartist-graph-lines-green"
                                                    options={this.state.vitalSigns.pulse.options}
                                                />
                                                <h4 className="chart-legend"><span className="chart-legend-green">Pulso</span></h4>
                                            </CardBody>
                                        </Card>
                                        <Card className="chart-container">
                                            <CardHeader className="chart-header"> Temperatura </CardHeader>
                                            <CardBody>
                                                <ChartistGraph
                                                    data={this.state.vitalSigns.temperature}
                                                    type="Line"
                                                    options={this.state.vitalSigns.temperature.options}
                                                    className="chartist-graph-lines-black"
                                                />
                                                <h4 className="chart-legend"><span className="chart-legend-black">Temperatura</span></h4>
                                            </CardBody>
                                        </Card>
                                        <Card className="chart-container">
                                            <CardHeader className="chart-header"> Respiración </CardHeader>
                                            <CardBody>
                                                <ChartistGraph
                                                    data={this.state.vitalSigns.breath}
                                                    type="Line"
                                                    options={this.state.vitalSigns.breath.options}
                                                    className="chartist-graph-lines-purple"
                                                />
                                                <h4 className="chart-legend"><span className="chart-legend-purple">Respiración</span></h4>
                                            </CardBody>
                                        </Card>                                                                            
                                    </CardBody>
                                }
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <ScheduleDetail
                        schedule={this.state.scheduleDetail}
                        open={this.state.openSchedule}
                        onClose={this.handleCloseSchedule.bind(this)}
                        customer={this.state.customer}
                    />
            </div>
        )
    }
}

Schedules.propTypes = {
    onGetSchedules: PropTypes.func.isRequired,
    onGetStatus: PropTypes.func.isRequired,
    onGetTypes: PropTypes.func.isRequired,
    schedules: PropTypes.array,
    status: PropTypes.array,
    types: PropTypes.array,
}

export default Schedules;