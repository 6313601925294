import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function MedicalDoctor(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M14.813 14.312L12 19l-2.813-4.688C6.268 14.893 3 16.291 3 18.5V21h18v-2.5C21 16.291 17.732 14.893 14.813 14.312zM12 11c2.209 0 4-1.791 4-4H8C8 9.209 9.791 11 12 11zM15.5 1h-7C8.5 1 8 3 8 5h8C16 3 15.5 1 15.5 1z" />
    </SvgIcon>
  );
}
