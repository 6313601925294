import { isArray } from "lodash";

class Authority {
    static hasPermission = (authority) => {
        let result = true;
        let companyStr = localStorage.getItem('company');
        if (companyStr) {
            var company = JSON.parse(companyStr);
            var auth = company.authorities;
            if (!isArray(company.authorities)) {
                auth = company.authorities.split(",");
            }

            if (company && company.authorities && authority && isArray(auth)) {
                var splitAuthority = authority.split(",")
                for (let item of splitAuthority) {
                    try{
                        result = item ? company.authorities.split(",").includes(item) : true;
                    } catch (Exception) {
                        return false;
                    }
    
                    if (result) {
                        break;
                    }
                 }
            }
        }
        return result;
    }
}

export default Authority;

