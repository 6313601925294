import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';
import ConfirmAlert from '../../ConfirmAlert';
import Button from '../../CustomButtons/Button';
import Table from '../../Table/Table';
import PersonApiInvoker from '../../../api/PersonApiInvoker';

class EmployeeGeographic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employeeGeographic: [],
            personId: '',
            loading: false,
            deleteAlert: false,
            success: false,
            error: false,
            selectedGeographic: '',
        }
    }

    componentWillReceiveProps(next) {        
        if(next.personId !== this.props.personId){
            PersonApiInvoker.getEmployeeGeographics(next.personId, (data) => {
                this.props.onGetEmployeeGeographics(data);
            });
        } 
    }

    formatGeographic(a) {
        return {
            companyId: a.companyId,
            locationName: a.locationName,
            provinceName: a.provinceName,
            location: a.location && a.location.name,
            name: a.detail,
            actions: (this.props.componenMode === 'view') ? '' : <div>
                <Button simple justIcon color="danger" onClick={() => this.deleteEmployeeGeographic(a.geographicZoneId)}><Delete /></Button>
            </div>,
        }            
    }

    deleteEmployeeGeographic(geographicId) {
        this.setState({
            deleteAlert: true,
            selectedGeographic: geographicId,
        });
    }

    deleteItemGeographic() {
        PersonApiInvoker.deleteEmployeeGeographic(this.props.personId, this.state.selectedGeographic, data => {
            let employeeGeographic = this.state.employeeGeographic;
            employeeGeographic.filter(e => e.geographicZoneId !== this.state.selectedGeographic);

            this.props.onDeleteItemGeographic(this.state.selectedGeographic);
            this.setState({
                success: true,
                employeeGeographic,
            });
        }, () => {
            this.setState({ error: true });
        })
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
        });
    }

    onSuccess = () => {
        this.setState({
            deleteAlert: false,
            success: false,
        });
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: t('employee.new.province'), accessor: 'provinceName' },
            { Header: t('employee.new.location'), accessor: 'locationName' },
            { Header: t('employee.new.GeographicZone'), accessor: 'name' },
        ];
        componenMode !== 'view' && tableHead.push({ Header: t('employee.new.actions'), accessor: 'actions', sortable: false, width: 150 });
        return tableHead;
    }

    render() {
        const { t, currentEmployeeGeographics } = this.props;
        const { loading, deleteAlert, error, success } = this.state;
        const employeesGeographic = currentEmployeeGeographics && currentEmployeeGeographics.map(this.formatGeographic.bind(this));
        return (
            <>
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={false}
                    tableHead={this.renderTableHead()}
                    tableData={employeesGeographic}
                    colorsColls={['primary']}
                />

                <ConfirmAlert
                    text={t('employee.new.modal.geographic')}
                    contextText={'female'}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItemGeographic()}
                    onSuccess={this.onSuccess}
                    show={deleteAlert}
                    success={success}
                    error={error}
                    showCancelSuccess={false}
                />
            </>
        )
    }
}

EmployeeGeographic.defaultProps = {
    employeesGeographic: [],
}

EmployeeGeographic.propTypes = {
    employees: PropTypes.array,
    employeesType: PropTypes.array,
    employeesGeographic: PropTypes.array,
    checkType: PropTypes.array,
    checkGeographic: PropTypes.array,
    employee: PropTypes.object,
    personId: PropTypes.string,
    onEmployeeGeographics: PropTypes.func,
    onDeleteItemGeographic: PropTypes.func,
    onGetEmployeeGeographics: PropTypes.func,
}

export default withTranslation()(EmployeeGeographic);
