import { connect } from 'react-redux';
import {getDocumentTypes, deleteItem, deleteItemGeographic} from '../actions/newEmployee';
import NewEmployee from '../components/NewEmployee/new-employee';
import EditEmployee from '../components/EditEmployee/EditEmployee';
import { addEmployee, editEmployee, getEmployee, getEmployeeGeographics, unmountEmployee } from '../actions/employees';

const mapStateToProps = (state) => {
    return {
       documentTypes: state.newEmployee.documentTypes,
       employee: state.employee,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetDocumentTypes: (documentTypes) => {
            dispatch(getDocumentTypes(documentTypes))
        },
        onAddEmployee: (Employee) => {
            dispatch(addEmployee(Employee))
        },
        onGetEmployee: (Employee) => {
            dispatch(getEmployee(Employee))
        },
        onEditEmployee: (Employee) => {
            dispatch(editEmployee(Employee))
        },
        onDeleteItem: (itemId) => {
            dispatch(deleteItem(itemId))
        },
        onUnmountEmployee: () => {
            dispatch(unmountEmployee())
        }
       

    }
}

const NewEmployeeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewEmployee);

const EditEmployeeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditEmployee);

export default {NewEmployeeService, EditEmployeeService};