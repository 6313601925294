export const TOOLTYPES_DETAIL = 'TOOLTYPES_DETAIL'
export const ADD_TOOLTYPE = 'ADD_TOOLTYPE';
export const EDIT_TOOLTYPE = 'EDIT_TOOLTYPE';
export const UNMOUNT_TOOLTYPE = 'UNMOUNT_TOOLTYPE';



export function getToolTypes(tooltypes) {
    return {type: TOOLTYPES_DETAIL, tooltypes}
}

export function addToolType(tooltype) {
    return {type: ADD_TOOLTYPE, tooltype};
}

export function editToolType(tooltype) {
    return {type: EDIT_TOOLTYPE, tooltype};
}

export function unmountToolType() {
    return {type: UNMOUNT_TOOLTYPE};
}