import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function DeleteDocument(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M7 11H9V13H7zM11 11H17V13H11zM7 15H9V17H7zM21.414 20l2.581 2.581-1.414 1.414L20 21.414 17.414 24 16 22.586 18.586 20l-2.591-2.591 1.414-1.414L20 18.586 22.586 16 24 17.414 21.414 20z" />
      <g>
        <path d="M11 15L11 17 13.576 17 15.576 15z" />
        <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h9.758l1-1l-1-1H5V8h14v6.758l1,1l1-1V5C21,3.895,20.105,3,19,3z" />
      </g>
    </SvgIcon>
  );
}