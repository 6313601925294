import '../../assets/plugins/react-datetime.css'
import './CustomInput.css'

import React, { useEffect, useState } from 'react'

import Datetime from 'react-datetime'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import customInputStyle from '../../assets/components/customInputStyle'
import withStyles from '@material-ui/core/styles/withStyles'

// nodejs library to set properties for components

// nodejs library that concatenates classes

// @material-ui/core components

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    placeholder,
    time,
    error,
    white,
    inputRootCustomClasses,
    multiline,
    success,
    helpText,
    onChange,
    onBlur,
    type,
    value,
    dateValue,
    defaultDateValue,
    closeOnSelect,
    dateShrink,
    min,
    max,
    pattern,
    step,
    minDate,
    maxDate,
    placeHolder,
  } = props

  const [shrink, setShrink] = useState(false)

  useEffect(() => {
    setShrink(dateShrink)
  }, [dateShrink])

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  })
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    )
  } else {
    formControlClasses = classes.formControl
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  })

  var yesterday = Datetime.moment().subtract(1, 'day')
  var valid = function (current) {
    if (!min) {
      return current.isAfter(yesterday)
    } else {
      if (max) {
        return current.isSameOrAfter(min) && current.isSameOrBefore(max)
      }
      return current.isSameOrAfter(min)
    }
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={
            classes.labelRoot +
            ' ' +
            labelClasses +
            ' ' +
            (shrink ? 'MuiInputLabel-shrink' : '')
          }
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      {type === 'date' ? (
        minDate || maxDate ? (
          <Datetime
            dateFormat="DD/MM/YYYY"
            timeFormat={time ? 'HH:mm' : false}
            isValidDate={valid}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            value={dateValue}
            defaultValue={defaultDateValue}
            closeOnSelect={closeOnSelect}
            className={props.className}
            inputProps={{
              placeholder,
              id,
              ...inputProps,
            }}
          />
        ) : (
          <Datetime
            dateFormat="DD/MM/YYYY"
            timeFormat={time ? 'HH:mm' : false}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            value={dateValue}
            defaultValue={defaultDateValue}
            closeOnSelect={closeOnSelect}
            className={props.className}
            inputProps={{
              placeholder,
              id,
              ...inputProps,
            }}
          />
        )
      ) : (
        <Input
          classes={{
            input: inputClasses,
            root: marginTop,
            underline: underlineClasses,
          }}
          id={id}
          onChange={onChange}
          value={value}
          multiline={multiline}
          min={min}
          max={max}
          step={step}
          placeholder={placeHolder}
          pattern={pattern}
          type={type || 'text'}
          inputProps={{
            ...inputProps,
          }}
          error={error}
        />
      )}
      {helpText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  time: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  dateValue: PropTypes.any,
  defaultDateValue: PropTypes.any,
  closeOnSelect: PropTypes.bool,
  dateShrink: PropTypes.bool,
  minDate: PropTypes.bool,
  maxDate: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  className: PropTypes.any,
}

export default withStyles(customInputStyle)(CustomInput)
