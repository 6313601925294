import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ChipFilters from '../ChipFilters';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import ComponentDisabledWithTooltip from '../../ComponentDisabledWithTooltip';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import DateInput from '../../DateInput';
import '../Dashboard.css';

class Filters extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            professionals: [],
            employees: [],
            financiers: [],
            ofinaciers: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            employeeTypes:[],
            oemployeeTypes: [],
            provincies: [],
            oprovincies: [],
        }
    }

    componentDidMount() {
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        this.handleValue(firstDay, 'dateFrom');
        this.handleValue(date, 'dateTo');
    }

    componentWillReceiveProps(next) {
    }

    handleValue = (value, state) => {
        if (state === 'dateFrom') {
            this.setState({errorStartDate: false});
        }
        if (state === 'dateTo') {
            this.setState({errorEndDate: false});
        }
        this.setState({ [state]: value });
    }

    sendFilters() {
        let errorStartDate = false;
        if (this.state.dateFrom === '') {
            errorStartDate = true;
        }

        let errorEndDate = false;
        if (this.state.dateTo === '') {
            errorEndDate = true;
        }

        if (errorStartDate || errorEndDate) {
            this.setState({errorStartDate: errorStartDate, errorEndDate: errorEndDate});
        } else {
            this.props.onChange({
                start_date: this.state.dateFrom,
                end_date: this.state.dateTo,
            });
        }
    }

    onChangeChips = (index, chips) => {
        this.props.onChangeChips({ chips });
    }

    cleanFilters() {
        this.setState({
            professional: [],
            dateFrom: '',
            dateTo: '',
        }, () => {
            this.props.onClearFilters();
        });
    }

    render() {
        const { t, chips, loading } = this.props;
        const { dateFrom, dateTo, errorEndDate, errorStartDate, } = this.state;
        const disabledButton = !(dateFrom && dateTo);

        return (
            <GridContainer className="filters" justify="center">
                <GridItem xs={12} sm={3} md={2} className="date-nopadding">
                    <DateInput
                        text={t('dashboardprofesional.filters.from')}
                        value={dateFrom}
                        onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
                        error={errorStartDate}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={2} className="date-nopadding">
                    <DateInput
                        text={t('dashboardprofesional.filters.to')}
                        minDate min={dateFrom}
                        value={dateTo}
                        onChangeValue={(value) => this.handleValue(value, 'dateTo')}
                        error={errorEndDate}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={6}>
                    <ChipFilters chips={chips} onChangeChips={this.onChangeChips} />
                </GridItem>
                <GridItem className="filters-actions" xs={12} sm={2}>
                    <ComponentDisabledWithTooltip
                        tooltipText={t('dashboard.buttonTooltip')}
                        disabled={disabledButton}
                        loading={loading}
                        component={
                            <ButtonSpinner
                                block
                                label={t('dashboard.filters.search')}
                                labelLoading={t('dashboard.filters.searching')}
                                loading={loading}
                                disabled={disabledButton || loading}
                                onClick={() => this.sendFilters()}
                                color="success"
                            />
                        }
                    />
                    <Button block onClick={() => this.cleanFilters()} color="danger">
                        {t('dashboard.filters.clean')}
                    </Button>
                </GridItem>
        </GridContainer>
        )
    }
}

Filters.defaultProps = {
    loading: false,
}

Filters.propTypes = {
    onGetFinanciers: PropTypes.func.isRequired,
    onGetProvincies: PropTypes.func.isRequired,
    onGetEmployeeTypes: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    loading: PropTypes.bool.isRequired,
}

export default withTranslation()(Filters);
