import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Cancel from '@material-ui/icons/Cancel';
import { ChipSet, Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import CustomInputChip from './CustomInputChip';

class ChipFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            professionals: [],
            employees: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            elements: [],
        }
    }

    addFilter = (s) => {
        const c = this.props.chips;
        c.push(s);
        this.props.onChangeChips(c, this.props.chips);
    }

    removeChip = (keyremove) => {
        this.props.chips.map((item, key) => {
            if (item.code === keyremove) {
                this.props.chips.splice(key,1);
            }
        });
    }

    onChipRemove = (s) => {
        this.props.chips.map((item, key) => {
            const label = `${item.title} '${item.name}'`;
            if (label === s) {
                if (this.props.chips[key].code === 'city') {
                    this.removeChip('geographicZone');
                }
                if (this.props.chips[key].code === 'province') {
                    this.removeChip('city');
                    this.removeChip('geographicZone');
                }
                if (this.props.chips[key].code === 'disabledPatient') {
                    this.removeChip('activeDisabilityCertificate');
                }
                this.props.chips.splice(key, 1);
                this.props.onChangeChips(this.props);
            }
        });

        this.setState({refresh: true})
    }

    render() {
        const { chips, employees, employeesType, financiers, patologies, provincies, t } = this.props;
        return (
            <ChipSet>
                <CustomInputChip 
                    selecteditems={chips} 
                    id="add_filter"
                    label={t('common.addFilter')}
                    employees={employees}
                    employeesType={employeesType}
                    financiers={financiers} 
                    patologies={patologies} 
                    onApply={this.addFilter} 
                    provincies={provincies} 
                />
                {this.props.chips.map((item, key) => {
                    let label = `${item.title} '${item.name}'`;

                    return (
                        <Chip
                            className="chipEllipsis"
                            size="medium"
                            filter={true}
                            selecteditems={chips}
                            trailingIcon={<Cancel/>}
                            shouldRemoveOnTrailingIconClick={false}
                            handleTrailingIconInteraction={this.onChipRemove}
                            id={label}
                            label={label}
                        />
                    );
                })}
            </ChipSet>
        )
    }
}

ChipFilters.propTypes = {
    onChange: PropTypes.func,
    employees: PropTypes.array,
    employeesType: PropTypes.array,
}

export default withTranslation()(ChipFilters);
