import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { Check, Edit, Delete } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import ConfirmAlert from '../ConfirmAlert';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import styles from '../../assets/components/customCheckboxRadioSwitch';

class Address extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personAddress: [],
            isAddress: false,
            personId: '',
            loading: true,
            deleteAlert: false,
            success: false,
            error: false,
            selectedAddres: '',
        }
    }

    componentWillReceiveProps(next) {      
        if (next.personId && 
            next.refresh) {
            PersonApiInvoker.getPersonsAddress(next.personId, false, (data) => {
                const personAddress = this.formatAddress(data);
                const isAddress = personAddress.length > 0;
                this.setState({
                    personAddress,
                    isAddress,
                    loading: false,
                    personId:next.personId,
                    refresh: next.refresh,
                }, () => {
                    if (this.props.onFilledAddress) {
                        this.props.onFilledAddress({
                            isAddress: isAddress,
                            isLoading: this.state.loading,
                            refresh: false
                        });
                    }
                });
            });
        }
    }

    formatAddress(address) {
        const { classes } = this.props;
        return address.map(a => {
            return {
                addressId: a.addressId,
                addressType: a.addressType.name,
                street: a.street,
                number: a.number,
                location: (a.location && a.location.name) && a.location.name,
                province: a.provinceName,
                default: <Checkbox
                    tabIndex={-1}
                    checked={a.addressDefault}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                    }}
                />,
                actions: this.props.componenMode !== 'view' &&
                    <>
                        <Button simple justIcon color="success" onClick={() => this.editAddress(a)}><Edit /></Button>
                        <Button simple justIcon color="danger" onClick={() => this.deleteAddress(a.addressId)}><Delete /></Button>
                    </>
            }
        });
    }

    editAddress(address) {
        this.props.onEditAddress(address);
    }

    deleteAddress(addressId) {
        this.setState({
            deleteAlert: true,
            selectedAddres: addressId,
        });
    }

    deleteItem() {
        const { onFilledAddress, personId } = this.props;
        const { loading, personAddress, selectedAddres } = this.state;
        PersonApiInvoker.deletePersonsAddress(personId, selectedAddres, data => {
            const addresses = personAddress;
            const pAddress = addresses.filter(addr => addr.addressId !== selectedAddres);
            const isAddress = pAddress.length > 0;
            this.setState({
                success: true,
                personAddress,
                isAddress,
            }, () => {
                if (onFilledAddress) {
                    onFilledAddress({
                        isAddress,
                        isLoading: loading,
                        refresh: false,
                    });
                }
            });
        }, err => {
            console.error('** error deletePersonsAddress', err);
            if(err.message){
                this.openAlert('danger', err.message);
                this.closeAlert();
            } else {
                this.setState({ error: true, success: false });
            }
        });
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
        });
    }
    
    openAlert = (color, message) => {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: t('address.type'), accessor: 'addressType' },
            { Header: t('address.street'), accessor: 'street' },
            { Header: t('address.number'), accessor: 'number' },
            { Header: t('address.location'), accessor: 'location' },
            { Header: t('address.province'), accessor: 'province' },
            { Header: t('address.mainAddress'), accessor: 'default' },
        ];
        componenMode !== 'view' && tableHead.push({ Header: t('common.actions'), accessor: 'actions', sortable: false, width: 150 });
        return tableHead;
    }

    render() {
        const { alertColor, alertMessage, alertOpen, deleteAlert, error, loading, personAddress, success } = this.state;
        const { t } = this.props;

        return (
            <div className="address-list">
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={true}
                    tableHead={this.renderTableHead()}
                    tableData={personAddress}
                    colorsColls={["primary"]}
                />

                <Snackbar
                    place="tr"
                    color={alertColor}
                    message={alertMessage}
                    open={alertOpen}
                />

                <ConfirmAlert
                    text={t('address.title')}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    show={deleteAlert}
                    success={success}
                    onSuccess={() => this.closeAlert()}
                    error={error}
                />
            </div>
        )
    }
}

Address.defaultProps = {
    onFilledAddress: () => {},
}

Address.propTypes = {
    personId: PropTypes.any,
    onEditAddress: PropTypes.func,
    componenMode: PropTypes.string.isRequired,
    onFilledAddress: PropTypes.func,
}

export default withStyles(styles)(withTranslation()(Address));
