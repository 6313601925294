import React from 'react';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import waves from '../../assets/img/waves.svg';
import './FullLoading.css';

const FullLoading = () => {
    return (
        <GridContainer className="full-loading">
            <GridItem xs={6} className="full-loading-container white"></GridItem>
            <GridItem xs={6} className="full-loading-container green"></GridItem>
            <div className="full-loading-animation">
                <img src={waves} alt="loading" className="heart" />
            </div>
        </GridContainer>
    )
}

export default FullLoading;
