import React from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Checkbox, Grid } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Table from '../../../Table/Table';
import useProfessionalsStep from './useProfessionalsStep';

const ProfessionalsStep = ({
  handleChange,
  handleIsValidStep,
  isConfirmStep,
  showSnackbarNotification,
  setRefSchedules,
  refStepsValues,
  values,
  t,
}) => {
  const {
    checkAllProfessionals,
    classes,
    classesChecks,
    loadingSchedule,
    loadingTable,
    onSelectAllProfessionals,
    professionalsToReassign,
    schedulesSelectedData,
  } = useProfessionalsStep(handleChange, showSnackbarNotification, refStepsValues, values);
  
  return (
    <Grid container className={clsx(classes.formStepper, {[classes.readOnlyForm]: isConfirmStep})}>
      <Grid item xs={12}>
        <h4 className={classes.stepTitle}>{t('title.suggestedProfessionals')}</h4>
        <Table
          loading={loadingTable}
          tableHeaderColor="primary"
          sortable={true}
          filterable
          tableHead={[
            { Header: <div className="tooltypes-actions">
              <Checkbox
                tabIndex={-1}
                id="allProfessionals"
                onClick={(e) => onSelectAllProfessionals(e)}
                checked={checkAllProfessionals}
                checkedIcon={<Check className={classesChecks.checkedIcon} />}
                icon={<Check className={classesChecks.uncheckedIcon} />}
                classes={{
                    checked: classesChecks.checked,
                    root: classesChecks.checkRoot
                }}
                disabled={professionalsToReassign.length === 0}
              /></div>, sortable: false, filterable: false, accessor: 'selector', width: 60 },
            { Header: t('reassignAppointments.table.profesional.firstName'), accessor: 'firstName' },
            { Header: t('reassignAppointments.table.profesional.lastName'), accessor: 'lastName' },
            { Header: t('reassignAppointments.table.profesional.employeeTypes'), accessor: 'employeeTypes' },
            { Header: t('reassignAppointments.table.profesional.geographicZone'), accessor: 'geographicZone' },
          ]}
          tableData={professionalsToReassign}
          defaultPageSize={professionalsToReassign && professionalsToReassign.length > 5 ? 20 : 5}
          colorsColls={['primary']}
        />
      </Grid>

      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.appointmentsToModify')}</h4>
        <Table
          loading={loadingSchedule}
          tableHeaderColor="primary"
          sortable
          setRef={setRefSchedules}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.scheduleId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.professional'), accessor: 'professional' },
            { Header: t('reassignAppointments.table.scheduleDateTime'), accessor: 'scheduleDate' },
            { Header: t('label.hour'), accessor: 'scheduleTime' },
          ]}
          tableData={schedulesSelectedData}
          defaultPageSize={schedulesSelectedData && schedulesSelectedData.length > 5 ? schedulesSelectedData.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>
    </Grid>
  )
}

export default withTranslation()(ProfessionalsStep);
