export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const SELECT_COMPANY = 'SELECT_COMPANY';

export function userLogin(user) {
    return {type: USER_LOGIN, user}
}

export function userLogout() {
    return { type: USER_LOGOUT }
}

export function fetchCompanies(companies) {
    return {type: FETCH_COMPANIES, companies}
}

export function selectCompany(companyId) {
    return { type: SELECT_COMPANY, selectedCompany: companyId }
}