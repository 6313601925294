import '../../assets/plugins/react-charts.css'
import './DashboardPB.css'
// Comment
import React, { useEffect, useState } from 'react'

const DashboardManagementControl = () => {
  const [companyId, setCompanyId] = useState(1)
  const [dashboardLink, setDashboardLink] = useState('')

  useEffect(() => {
    const company = parseInt(localStorage.getItem('itlg_default_company_id'))
    setCompanyId(company)
    getUrlPB()
  }, [])

  const getUrlPB = () => {
    let link = ''
    switch (companyId) {
      case 1:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiY2Q4YWEzMTAtOTJjNi00OTY4LWE5MmMtNWY2NmVkZjI0YWU0IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d'
        break
      case 4:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiNTY5ZDdmMWYtYjA2Yy00NGUyLTk5ODItNmM4YzgxYWY5OTA3IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9'
        break
      case 5:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiYmM0NWRjMDItMTY2OC00YmQ0LTljOTktMjkwMzgyMWY3MDlmIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9'
        break
      case 7:
        link =
          'https://app.powerbi.com/reportEmbed?reportId=b0cc8547-7f84-47b7-89c5-f344652389b5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22'
        break
      case 8:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiY2Q4YWEzMTAtOTJjNi00OTY4LWE5MmMtNWY2NmVkZjI0YWU0IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d'
        break
      case 10:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiN2EzMGFmY2EtNGUzMS00ZjFlLThkYzEtNmFhMWY0NWM4MzMzIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9'
        break
      case 11:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiOWI1ZTI0YWMtNDlmYy00MjgyLTllMGMtMDIxNWFhZDcyN2ZmIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9'
        break
      case 12:
        link =
          'https://app.powerbi.com/reportEmbed?reportId=279ed19b-4d10-4477-b0a1-d3c77f03b9fe&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22'
        break
      case 13:
        link =
          'https://app.powerbi.com/view?r=eyJrIjoiODUxNjU0MDQtYWM2Yy00Y2I1LThjMzgtNGM3YTkyY2RmYzU0IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d'
        break
      default:
        link = ''
        break
    }
    return setDashboardLink(link)
  }

  return (
    <div className="div">
      <iframe
        width="100%"
        height={window.innerHeight}
        src={dashboardLink}
        frameborder="0"
        allowFullScreen="true"
        title="PB"
      />
    </div>
  )
}

export default DashboardManagementControl
