import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class DashboardApiInvoker extends Component {

	static postEmployeeDashboard(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee-dashboard`, body, callback, callerror);
	}

	static postEmployeeDashboardPuntuality(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee-dashboard/puntuality`, body, callback, callerror);
	}

	static postEmployeeDashboardDuration(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee-dashboard/duration`, body, callback, callerror);
	}

	static postEmployeeDashboardSchedule(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee-dashboard/schedule`, body, callback, callerror);
	}

	static postEmployeeDashboardQuantity(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee-dashboard/quantity`, body, callback, callerror);
	}

	static getEmployeesDashboard(filter, callback, callerror) {
		const url = `${urlInvoke}/employee-dashboard/scheduler-filter?date-from=${filter.start_date}&date-to=${filter.end_date}`;
		baseInvoker.get(url, callback, callerror);
	}

	static getDashboardEconomicSupply(filter, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/dashboard-economic-supply?date-from=${filter.start_date}&date-to=${filter.end_date}`, callback, callerror);
	}
	static getEmployeesDashboardEconomicProfessional(filter, callback, callerror) {
		const url = `${urlInvoke}/employee-dashboard-economic/scheduler-filter?date-from=${filter.start_date}&date-to=${filter.end_date}`;
		baseInvoker.get(url, callback, callerror);
	}

	static postEmployeeDashboardScheduleRejected(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee-dashboard/schedule-rejected`, body, callback, callerror);
	}

	static getAppointments(params, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/appointments/filter?betweenStartDate=${params.start_date}&betweenEndDate=${params.end_date}`, callback, callerror);
	}

	static getDashboardEconomicTools(filter, callback, callerror) {
		const url = `${urlInvoke}/dashboard-economic-tools?date-from=${filter.start_date}&date-to=${filter.end_date}`;
		baseInvoker.get(url, callback, callerror);
	}

}
