import { TOOLS_DETAIL, TOOLTYPES_DETAIL, ADD_TOOL, EDIT_TOOL, UNMOUNT_TOOL } from "../actions/tools";

const tools = (state = {}, action, root) => {
    switch(action.type) {
        case UNMOUNT_TOOL:
            return {}
        case TOOLTYPES_DETAIL:
            let tooltypes = action.tooltypes
            return {...state, tooltypes}
        case TOOLS_DETAIL:
            let tools = action.tools
            return {...state, tools}
        case ADD_TOOL: 
                const result = addTool(state.tools, action.tool)
                return {...state, tools: result}
        case EDIT_TOOL: 
                const updated = updateTool(state.tools, action.tool)
                return {...state, tools: updated}
        default:
            return state
    }
}


const addTool = (tools, tool) => {
    if (tools && tools.length) {
        tools.push(tool);
        return tools;
    }
    return;
}

const updateTool = (tools, tool) => {
    if (tools && tools.length) {
        tools.map((c, i) => {
            if (c.toolId === tool.toolId) {
                tools[i] = tool
                return;
            }
        })
    }
    return tools;
}

export default tools;
