import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import "@material/react-chips/dist/chips.css";
import {ChipSet, Chip} from '@material/react-chips';
import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';

class ChipFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tools: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            elements: []
        }
    }

    addFilter = (s) => {
        var c = this.props.chips;
        c.push(JSON.parse(JSON.stringify(s)));
        this.props.onChangeChips(c,this.props.chips);
    }

    removeChip = (keyremove) => {
        this.props.chips.map((item, key) => {
            if (item.code === keyremove) {
                this.props.chips.splice(key,1);
            }
        });
    }

    onChipRemove = (s) => {
        this.props.chips.map((item, key) => {
            var label = item.title + "  '" + item.name + "'   ";
            if (label === s) {
                this.props.chips.splice(key, 1);
                this.props.onChangeChips(s, this.props.chips);
            }
        });
    }

    render() {
        const { t } = this.props;
        return (
            <ChipSet>
                <CustomInputChip selecteditems={this.props.chips} id='add_filter' label={t('common.addFilter')}  financiers={this.props.financiers} employeeTypes={this.props.employeeTypes} onApply={this.addFilter} provincies={this.props.provincies} />
                {this.props.chips.map((item, key) => {
                    var label = item.title + "  '" + item.name + "'   ";
                    return (
                        <Chip className="ChipsInFilter" filter={true} selecteditems={this.props.chips} trailingIcon={<Cancel />} shouldRemoveOnTrailingIconClick={false} handleTrailingIconInteraction={this.onChipRemove} id={label} label={label} />
                    );
                })}
            </ChipSet>
        )
    }
}

ChipFilters.propTypes = {
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    t: PropTypes.func
}

export default withTranslation()(ChipFilters);