import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function DateRange(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm4-7h-3V2h-2v2H8V2H6v2H3v18h18V4zm-2 16H5V9h14v11z"/>
    </SvgIcon>
  );
}
