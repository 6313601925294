import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';
import CardIcon from '../../Card/CardIcon';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import HistoriaClinicaApi from '../../../api/HistoriaClinicaApi';
import { IntegralFiltersService } from '../../../containers/IntegralDashboardCustomerService';
import { useIntegralVitalSigns } from '../../../hooks/useVitalSigns';
import { Stethoscope } from '../../../icons';
import IntegralVitalSignsCharts from './IntegralVitalSignsCharts';
import '../IntegralTechnicalFile.css';

const IntegralVitalSigns = (props) => {
  // Intial states
  const [ age, setAge ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ schedules, setSchedules ] = useState([]);
  const [ chips, setChips ] = useState([]);
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ filters, setFilters ] = useState({ specialties: [], profesionals: [] });

  // Applies frontend filter
  const applySoftFilter = (filters) => {
    setChips(filters.chips);
    // Creates an array of profesional ids
    const profesionals = filters.chips.reduce((prev, curr) => {
      if (curr.code === 'employees') {
        return prev.concat(curr.value);
      } else {
        return prev;
      }
    }, [])
    // Creates an array of specialties ids
    const specialties = filters.chips.reduce((prev, curr) => {
      if (curr.code === 'employeesType') {
        return prev.concat(curr.value);
      } else {
        return prev;
      }
    }, []);
    setFilters({ specialties, profesionals });
  }

  // Calls the backend to get data
  const refreshData = (filters) => {
    if (!filters.start_date.length || !filters.end_date.length) {
      return;
    }
    const start_date = moment(filters.start_date).format('YYYY-MM-DD');
    const end_date = moment(filters.end_date).format('YYYY-MM-DD');
    setStartDate(start_date);
    setEndDate(end_date);
    setIsLoading(true);
    HistoriaClinicaApi.getIntegralClinicalHistoryIntegral(props.customer.personId, start_date, end_date, (data) => {
      setIsLoading(false);
      setSchedules(data);
    }, err => {
      console.error(err.message);
      return setIsLoading(false);
    })
  }

  // Clear button
  const clearInformation = () => { 
    setSchedules([]);
    setFilters({ specialties: [], profesionals: [] });
    setStartDate();
    setEndDate();
  }  

  // Computes the charts information when schedules or filters changes.
  const [pulse, oxigen, arterialTension, temperature] = useIntegralVitalSigns(schedules, {
    dateFrom: startDate,
    dateTo: endDate,
    profesionals: filters.profesionals,
    specialties: filters.specialties,
  });

  useEffect(() => {
    const cacluatedAge = moment().diff(moment(props.customer.birthdate), 'years')
    setAge(cacluatedAge)
  }, [
    props.customer.birthdate
  ]);

  const { t } = props;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Stethoscope />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.nameAndLastName')}
                  value={`${props.customer.firstName} ${props.customer.lastName}`}
                  inputProps={{ shrink: !!(props.customer.firstName) }}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.docType')} value={props.customer.idType && props.customer.idType.name}
                  inputProps={{ shrink: !!(props.customer.idType && props.customer.idType.name), }}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.docNumber')}
                  value={props.customer.id}
                  inputProps={{ shrink: !!(props.customer.id) }}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6}>
                <CustomInput
                classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.age')} 
                  value={age}
                  inputProps={{ shrink: !!(age), }}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6}>
                <CustomInput
                  classes={{
                    input: "input-technical-file"
                  }}
                  labelText={t('technicalfile.nationality')}
                  value={props.customer.nationality && props.customer.nationality.name}
                  inputProps={{ shrink: !!(props.customer.nationality && props.customer.nationality.name) }}
                  formControlProps={{
                    disabled: true,
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="info">
              <Stethoscope />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <IntegralFiltersService
              chips={chips}
              onChangeChips={applySoftFilter}
              onChange={refreshData}
              onClearFilters={clearInformation}
            />
            {isLoading ?
              <div className="progressContent">
                <CircularProgress />
              </div>
              : <IntegralVitalSignsCharts 
                pulse={pulse}
                oxigen={oxigen}
                arterialTension={arterialTension}
                temperature={temperature}
              />
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

IntegralVitalSigns.propTypes = {
  patientId: PropTypes.string.isRequired
}

export default withTranslation()(IntegralVitalSigns);
