import { connect } from 'react-redux'
import { getCompanyFinanciers } from '../actions/financiers'
import CreateFinancier from '../components/CreateFinancier'

const mapStateToProps = state => {
  return {
    companyFinanciers: state.companyFinanciers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetCompanyFinanciers: companyFinanciers => {
      dispatch(getCompanyFinanciers(companyFinanciers))
    },
  }
}

const FinanciersService = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFinancier)

export default FinanciersService
