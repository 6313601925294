import {
  APPOINTMENTS,
  APPOINTMENT_TOOLS,
  DELETE_APPOINTMENT,
  SUBMIT_APPOINTMENT,
  SELECT_APPOINTMENT,
  SELECT_APPOINTMENT_OBJECT,
  SELECT_APPOINTMENT_LIST,
  SELECT_APPOINTMENT_SCHEDULES,
  SAVE_TOOLS,
  DELETE_APPOINTMENTTOOLS,
  CHECK_APPOINTMENT_TOOLS,
  CHECK_APPOINTMENT_TOOLSEDIT,
  SAVE_APPOINTMENT_SCHEDULE,
  DESELECT_ALL_TOOLS,
  CLEAN_APPOINTMENTS,
  UNDO_APPOINTMENTTOOLS
} from '../actions/appointments'
import _ from 'lodash'

const initialState = {};

const appointments = (state = initialState, action) => {
  switch (action.type) {
    case APPOINTMENTS:
      //const orderedAppointments = orderAppointments(action.appointments)
      return Object.assign([], state, action.appointments)
    case CLEAN_APPOINTMENTS:
      return initialState;
    case SELECT_APPOINTMENT:
      const selectedAppointment = selectAppointment(action.appointmentId, state)
      return { ...state, selectedAppointment: selectedAppointment[0] }
    case SELECT_APPOINTMENT_LIST:
        return { ...state, selectedAppointment: null }
    case SELECT_APPOINTMENT_OBJECT:
        return { ...state, selectedAppointment: action.appointment }
    case SELECT_APPOINTMENT_SCHEDULES:
        return { ...state,  
                    selectedSchedules: action.schedules}
    case SAVE_APPOINTMENT_SCHEDULE:
      let _bookingId = action.schedule && action.schedule.id
      let schedulesInState = state && state.selectedSchedules
      let nextSchedules = schedulesInState.map( schedule => {
        const _scheduleId = schedule.scheduleId
        return {
          ...schedule,
          scheduleStatus: {
            ...schedule.scheduleStatus, scheduleStatusId: _bookingId === _scheduleId ? 3 : schedule.scheduleStatus.scheduleStatusId
          }
        }
      })
      return {...state, selectedSchedules: nextSchedules}

    case DELETE_APPOINTMENT:
      const appointments = deleteAppointment(action.appointmentId, state)
      return Object.assign([], state, appointments)
    case DESELECT_ALL_TOOLS:
      const slAppointment = state.selectedAppointment || {};
      return Object.assign([], state, { selectedAppointment: { ...slAppointment, toolsList: [] } , checkedToolsList: [], appointmentsTools: [], checkAppointmentsTools: [], checkAppointmentsToolsEdit: []  })
    case SUBMIT_APPOINTMENT:
      const appointment = action.appointment
      return { ...state, appointment }
    case APPOINTMENT_TOOLS:
      const appointmentsTools = action.appointmentsTools
      return { ...state, appointmentsTools}
    case SAVE_TOOLS:
      const savedTools = action.savedTools
      return { ...state, savedTools }
    case DELETE_APPOINTMENTTOOLS:
        const tool = action.tool
        const selAppointment = state.selectedAppointment || {};
        let toolsList = selAppointment.toolsList
        let toolsNewList = toolsList.filter(id => id.toolId !== tool.toolId)        
        return { ...state, selectedAppointment: { ...selAppointment, toolsList: toolsNewList } }
    case CHECK_APPOINTMENT_TOOLS:
      const checkAppointmentsTools = action.checkAppointmentsTools
      const currArray = state.checkedToolsList || []
      let nextArray = []
      if(currArray.indexOf(checkAppointmentsTools.toolId) < 0) {
        nextArray = [
          ...currArray,
          checkAppointmentsTools.toolId
        ]
      } else {
        nextArray = [
          ...currArray,
        ]
        nextArray = nextArray.filter(id => id !== checkAppointmentsTools.toolId)
      }
      return { ...state, checkedToolsList: nextArray }
    case CHECK_APPOINTMENT_TOOLSEDIT:
      const checkAppointmentsToolsEdit = action.checkAppointmentsToolsEdit
      const currArrayEdit = state.checkedToolsList || []
      let nextArrayEdit = []
      if(currArrayEdit.indexOf(checkAppointmentsToolsEdit.toolId) < 0) {
        nextArrayEdit = [
          ...currArrayEdit,
          checkAppointmentsToolsEdit.toolId
        ]
      } else {
        nextArrayEdit = [
          ...currArrayEdit,
        ]
        nextArrayEdit = nextArrayEdit.filter(id => id !== checkAppointmentsToolsEdit.toolId)
      }
      return { ...state, checkedToolsList: nextArrayEdit }
    case UNDO_APPOINTMENTTOOLS:{
        const selAppointment = state.selectedAppointment || {};
        return { ...state, checkedToolsList: action.checklist} 
      }
    default:
      return state
  }
}

const deleteAppointment = (id, appointments) => {
  appointments.map((a, key) => {
    if (a.appointmentId === id) {
      delete appointments[key]
    }
  })
  return appointments
}

const selectAppointment = (appointmentId, appointments) => {
  return appointments.filter((a, key) => {
    if (a.appointmentId === appointmentId) {
      return appointments[key]
    }
  })
}

const orderAppointments = appointments => {
  var result = _.orderBy(appointments, 'startDate', 'desc')
  return result
}

export default appointments
