import { connect } from 'react-redux';
import CustomerForm from '../components/NewSchedule/CustomerForm';
import DiagnosticForm from '../components/NewSchedule/DiagnosticForm';
import DiagnosticFormReadOnly from '../components/NewSchedule/DiagnosticFormReadOnly';
import ProviderForm from '../components/NewSchedule/ProviderForm';
import ScheduleDetail from '../components/NewSchedule/ScheduleDetail';
import { getCustomers } from '../actions/customers';
import { fetchModules, fetchPracticeTypes } from '../actions/newSchedule';
import Modules from '../components/NewSchedule/ScheduleDetail/ScheduleDetailModal/Modules';
import Plan from '../components/NewSchedule/ScheduleDetail/ScheduleDetailModal/Plan';
import AuthorizerForm from '../components/NewSchedule/AuthorizerForm';
import ResponsableForm from '../components/NewSchedule/ResponsableForm/ResponsableForm';
import { fetchPatologies, fetchComplexities, fetchBarthels } from '../actions/records'
import DisabilityForm from '../components/NewSchedule/DisabilityForm';

const mapStateToProps = (state) => {
    return {
        customers: state.customers.customers,        
        modules: state.newSchedule.modules,
        practiceTypes: state.newSchedule.practiceTypes,
        complexities: state.records.complexities,
        barthels: state.records.barthels,
        patologies: state.records.patologies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetComplexities: (complexities) => {
            dispatch(fetchComplexities(complexities))
        },

        onGetPatologies: (patologies) => {
            dispatch(fetchPatologies(patologies))
        },

        onGetBarthels: (barthels) => {
            dispatch(fetchBarthels(barthels))
        },
        onGetCustomers: (customers) => {
            dispatch(getCustomers(customers))
        },
        onFetchModules: (modules) => {
            dispatch(fetchModules(modules))
        },
        onFetchPracticeTypes: (practiceTypes) => {
            dispatch(fetchPracticeTypes(practiceTypes))
        }
    }
}

const CustomerFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerForm);

const DisabilityFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DisabilityForm);

const DiagnosticFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiagnosticForm);

const DiagnosticFormReadOnlyService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiagnosticFormReadOnly);

const ProviderFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProviderForm);

const ScheduleDetailService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleDetail);

const ModulesService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Modules)

const PlanService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Plan)

const AuthorizerFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthorizerForm)

const ResponsableFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsableForm)

export default {
    CustomerFormService,
    DisabilityFormService,
    DiagnosticFormService,
    DiagnosticFormReadOnlyService,
    ProviderFormService,
    ScheduleDetailService,
    ModulesService,
    PlanService,
    ResponsableFormService,
    AuthorizerFormService,
};