
export const STATUS = 'STATUS'
export const PROFESSIONALS = 'PROFESSIONALS'
export const CUSTOMERS = 'CUSTOMERS'
export const NEW_TOOLS = 'NEW_TOOLS'
export const ADRESS = 'ADRESS'
export const MODULES = 'MODULES'
export const RECURRENCES = 'RECURRENCES'
export const APPOINTMENT_TYPES = 'APPOINTMENT_TYPES'
export const PERSON_ADDRESS = 'PERSON_ADDRESS'
export const APPOINTMENT = 'APPOINTMENT'

export function getStatus(status) {
    return {type: STATUS, status}
}

export function getProfessionals(professionals) {
    return {type: PROFESSIONALS, professionals}
}

export function setAppointment(appointment) {
    return {type: APPOINTMENT, appointment}
}

export function getCustomers(customers) {
    return {type: CUSTOMERS, customers}
}

export function getTools(tools) {
    return {type: NEW_TOOLS, tools}
}

export function getAdress(adress) {
    return {type: ADRESS, adress}
}

export function getModules(modules) {
    return {type: MODULES, modules}
}

export function getRecurrences(recurrences) {
    return {type: RECURRENCES, recurrences}
}

export function getAppointmentTypes(appointmentTypes) {
    return {type: APPOINTMENT_TYPES, appointmentTypes}
}

export function getPersonAddress(personAddress) {
    return {type: PERSON_ADDRESS, personAddress}
}