import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from "../Table/Table";
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Assignment from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../config/config.js';
import { withTranslation } from 'react-i18next';
import './Modules.css';
import AppointmentApiInvoker from "../../api/AppointmentApiInvoker";

class Modules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modules: [],
            loading: false,
        }
    }

    componentWillMount() {

        if (this.props.modules) {
            this.formatModules(this.props.modules)
        } else this.getModules();
    }

    componenDidMount() {
        if (this.props.modules) {
            this.formatModules(this.props.modules)
        }
    }

    componentWillReceiveProps(next) {
        if (next.modules) {
            this.formatModules(next.modules)
        }
    }

    getModules() {
        this.setState({
            loading: true,
        })
        
        AppointmentApiInvoker.getPackagesActive(data => {
            if (data && data.length) {
                this.props.onGetModules(data)
            }
            this.setState({
                loading: false,
            })
        }, e => {
            this.setState({
                loading: false,
            })
        })
    }

    formatModules(modules) {
        let data = modules.map(m => {
            return {
                id: m.packageId,
                name: m.name,
                actions: <div className="modules-actions">
                    <Tooltip title="Ver"><div><Button simple justIcon color="info" authority="package_view" onClick={console.log('view')}><Assignment /></Button></div></Tooltip>
                    <Tooltip title="Editar"><div><Button simple justIcon color="success" authority="package_edit" onClick={console.log('edit')}><Edit /></Button></div></Tooltip>
                    <Tooltip title="Eliminar"><div><Button simple justIcon color="danger" authority="package_del" onClick={console.log('delete')}><Delete /></Button></div></Tooltip>
                </div>
            }
        })

        this.setState({
            modules: data
        })
    }

    handleName(e) {
        const name = e.target.value
        const data = this.props.modules.filter(module => {
            return module.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        })

        return this.formatModules(data);
    }

    moduleHistory(id) {
        browserHistory.push(`/module/${id}`);
    }

    render() {
        const { t } = this.props
        return (
            <div className="modules">
                <GridContainer >
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="info" icon>
                                <CardIcon color="info">
                                    <Assignment />
                                </CardIcon>
                                <Button className="modules-button" round justIcon color="success" authority="package_new" onClick={console.log('Add')}>
                                    <Add className="modules-button-icon" />
                                </Button>
                                {/* <div className="modules-filters modules-filters-row">
                                    <CustomInput
                                        labelText="Módulo"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: false,
                                        }}
                                        onChange={(e) => this.handleName(e)}
                                    />
                                </div> */}
                            </CardHeader>
                            <CardBody className="modules-content-body filtrable-table">
                                <Table
                                    filterable
                                    striped
                                    loading={this.state.loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        { Header: t("modules.table.id"), accessor: 'id' },
                                        { Header: t("modules.table.module"), accessor: 'name' },
                                        { Header: t("modules.table.actions"), accessor: 'actions', sortable: false, filterable: false }
                                    ]}
                                    tableData={this.state.modules}
                                    colorsColls={["primary"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

Modules.propTypes = {
    onGetModules: PropTypes.func.isRequired,
    modules: PropTypes.array
}

export default withTranslation()(Modules);
