import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Element, Events, scroller } from 'react-scroll';
import QuantityChart from './QuantityChart/index.js';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import HotelIcon from '@material-ui/icons/Hotel';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Snackbar from '../Snackbar/Snackbar';
import { FiltersService } from '../../containers/DashboardCustomerService';
import DashboardApiInvoker from '../../api/DashboardApiInvoker';
import ActiveCustomers from './ActiveCustomers/ActiveCustomers';
import '../../assets/plugins/react-charts.css';
import './Dashboard.css';

const dateFormat = 'DD/MM/YYYY';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appointments: [],
            originalquery: [],
            professionals: [],
            schedules: [],
            dateFrom: '',
            dateTo: '',
            employeeId: '',
            graphics: false,
            clear: false,
            financiers: [],
            chips: [],
            alertOpen: false,
            alertColor: 'warning',
            alertMessage: '',
        }
    }

    componentWillMount() {
        if (this.props.employees && this.props.employees.length > 0) {
            this.setState({ professionals: this.props.employees });
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.setState({
                employees: next.employees,
                professionals: next.employees,
            })
        }
        if (next.employeesType) {
            this.setState({
                employeesType: next.employeesType,
                oemployeesType: next.employeesType,
            });
        }
        if (next.financiers) {
            this.setState({
                financiers: next.financiers,
                ofinanciers: next.financiers,
            });
        }
    }

    componentDidMount() {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-charts', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }   

    exportToExcel = () => {
        const { t } = this.props
        let r = [];
        const fields = {
            'orderId': t('dashboardcustomer.table.orderid'),
            'customer': t('dashboardcustomer.table.customer'),
            'financier': t('dashboardcustomer.table.financier'),
            'appointmentId': t('dashboardcustomer.table.appointmentId'),
            'startEndDate': t('dashboardcustomer.table.startDate_endDate'),
            'mainDiagnostic': t('dashboardcustomer.table.mainDiagnostic'),
            'pathologyId': t('dashboardcustomer.table.pathologyId'),
            'complexity': t('dashboardcustomer.table.complexity'),
            'disabledPatient': t('appointment.disabledPatient.title'),
            'activeDisabilityCertificate': t('appointment.activeDisabilityCertificate'),
            'province': t('dashboardcustomer.table.province'),
            'city': t('dashboardcustomer.table.city'),
            'geographicZone': t('dashboardcustomer.table.geographicZone'),
        };
        r.push(fields);
        
        this.state.appointments.forEach(a => {
            const appointment = {
                orderId: a.orderId,
                customer: a.customer,
                financier: a.financier,
                appointmentId: a.appointmentId,
                startEndDate: a.startEndDate,
                mainDiagnostic: a.mainDiagnostic,
                pathologyId: a.pathologyId,
                complexity: a.complexity,
                disabledPatient: a.disabledPatient === '1' ? t('form.option.yes') : t('form.option.no'),
                activeDisabilityCertificate: a.activeDisabilityCertificate === '1' ? t('form.option.yes') : t('form.option.no'),
                province: a.province,
                city: a.city,
                geographicZone: a.geographicZone,
            }
            r = r.concat(appointment);
        });

        const fileName = `dashboard-customers-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(r, {skipHeader: 1});
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data2, fileName + fileExtension);
        return; 
    }

    base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
    }
    
    format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

    openAlert(color, message) {
        this.setState({
            alertOpen: true,
            alertMessage: message,
            alertColor: color,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    setFilters(filters) {
        if (filters.start_date.length > 0 && filters.end_date.length > 0) {
            this.setState({
                start_date: moment(filters.start_date).format('DD/MM/YYYY'),
                end_date: moment(filters.end_date).format('DD/MM/YYYY'),
                loadingEmployeesTable: true,
            })
            DashboardApiInvoker.getAppointments({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
            }, data => {
                this.formatTableData(data)
            }, err => {
                const message = err.message ?? err;
                console.error(message);
                this.setState({loadingEmployeesTable: false})
                this.openAlert('danger', message + '');
            });
        }
    }

    softFilter = (filters) => {
        let appointments = [...this.state.originalquery];
        this.state.chips.map(e => {
            return appointments = appointments.filter((item) => {
                if (e.code === 'employeesInvolved') {
                    return item[e.code] && item[e.code].map(app => app[e.subproperty]).indexOf(e.value) > - 1;
                } else {
                    return item[e.code] === e.value || item[e.code] === e.name;
                }
            });
        })
        this.setState({ appointments });
    }

    formatTableData(data) {
        const { t } = this.props;
        if (data) {
            let index=1;
            let result = data.map(d => {
                let complexi = (d.metadata &&  d.metadata.diagnostic && d.metadata.diagnostic.complexity ? d.metadata.diagnostic.complexity.description : '');
                complexi = (complexi && complexi !== '') ? t(complexi) : '';

                return {
                    orderId: index++,
                    customer: d.customer,
                    customerId: d.customerId,
                    financier: d.financier,
                    appointmentId: d.id,
                    startEndDate: `${d.startDate && moment(d.startDate).format(dateFormat)} - ${d.endDate && moment(d.endDate).format(dateFormat)}`,
                    mainDiagnostic: (d.metadata && d.metadata.diagnostic && d.metadata.diagnostic.principalDiagnostic ? d.metadata.diagnostic.principalDiagnostic.description : ''),
                    pathologyId: (d.metadata &&  d.metadata.diagnostic && d.metadata.diagnostic.hospitalizationDiagnostic ? d.metadata.diagnostic.hospitalizationDiagnostic.description : ''),
                    complexity: complexi,
                    province: d.province,
                    city: d.location,
                    geographicZone: d.geographicZone,
                    employee: d.employee,
                    employeesInvolved: d.employeesInvolved,
                    appointmentStatus: d.appointmentStatus,
                    disabledPatient: d.metadata && d.metadata.disability && d.metadata.disability.disabledPatient && d.metadata.disability.disabledPatient === true ? '1' : '2',
                    activeDisabilityCertificate: d.metadata && d.metadata.disability && d.metadata.disability.activeDisabilityCertificate && d.metadata.disability.activeDisabilityCertificate === true ? '1' : '2',
                }
            });
            this.setState({
                appointments: result,
                originalquery: result,
                loadingEmployeesTable: false,
            });

            this.softFilter();
        }
    }

    clickOnRow(employeeId) {
        if (employeeId) {
            this.setState({
                clear: false,
            }, () => {
                this.getSchedules(this.state.employeeId);
            });
        }
    }

    getSchedules(employeeId) {
        DashboardApiInvoker.postEmployeeDashboardSchedule({
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            employeeId: employeeId,
        }, schedules => {
            this.setState({
                schedules: [],
                selectedRow: true,
                graphics: false,
            })
            this.formatSchedules(schedules);
        }, err => {
            console.error('** Error postEmployeeDashboardSchedule: ', err);
        })
    }

    formatSchedules(schedules) {
        if (schedules && schedules.length) {
            const { t } = this.props
            const result = schedules.map(s => {
                return {
                    customer: `${s.customer.firstName} ${s.customer.lastName}`,
                    practice: s.practiceTypeName,
                    duration: s.scheduleActualDuration,
                    punctuality: s.puntuality,
                    verificated: s.patientIdentityChecks ? t('verify.location.y') : t('verify.location.n')
                }
            });
            this.setState({ schedules: result });
        }
    }

    clearInformation() {
        this.setState({
            employees: [],
            employeeId: '',
            dateFrom: '',
            dateTo: '',
            clear: true,
            chips: [],
            appointments:[],
            originalquery: [],
        })
    } 

    showGraphicsInfo(rowInfo) {
        if (rowInfo && rowInfo.original && rowInfo.original.employeeId) {
            this.setState({
                graphics: true,
                employeeId: rowInfo.original.employeeId,
            }, () => {
                this.setState({ graphics: false });
            });
        }
    }

    sortDate = (a, b) => {
        let a1 = a.split(' - ');
        a1 = a1[0];
        a1 = moment(a, dateFormat).valueOf();
        let b1 = b.split(' - ');
        b1 = b1[0];
        b1 = moment(b, dateFormat).valueOf();

        if(a1<b1)
         return -1;
        else if(a1>b1)
            return 1;
        else
            return 0;                  
    }
    
    render() {
        const { t } = this.props;
        const { alertColor, alertMessage, alertOpen, appointments, chips, loadingEmployeesTable } = this.state;
        
        return (
            <div className="dashboard dashboard-customer">
                <GridContainer>
                    <GridItem className="table-container" xs={12}>
                        <ActiveCustomers appointments={appointments} />
                        <Card className="dashboard-table">
                            <CardHeader icon>
                                <CardIcon color="rose">
                                    <HotelIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('dashboardcustomer.table.title')}</h3>
                                <FiltersService
                                    chips={chips}
                                    loading={loadingEmployeesTable}
                                    onChangeChips={(filters) => this.softFilter(filters)}
                                    onChange={(filters) => this.setFilters(filters)}
                                    onClearFilters={() => this.clearInformation()}
                                />
                            </CardHeader>
                            <CardBody className="dashboard-content-body">
                                <Snackbar
                                    place="tr"
                                    color={alertColor}
                                    message={alertMessage}
                                    open={alertOpen}
                                />
                                <Table
                                    loading={loadingEmployeesTable}
                                    tableHeaderColor="primary"
                                    sortable={true}
                                    tableHead={[
                                        { Header: t('dashboardcustomer.table.orderid'), accessor: 'orderId' },
                                        { Header: t('dashboardcustomer.table.customer'), accessor: 'customer' },
                                        { Header: t('dashboardcustomer.table.financier'), accessor: 'financier' },
                                        { Header: t('dashboardcustomer.table.appointmentId'), accessor: 'appointmentId' },
                                        { Header: t('dashboardcustomer.table.startDate_endDate'), accessor: 'startEndDate', sortMethod: (a, b) => this.sortDate(a, b) },
                                        { Header: t('dashboardcustomer.table.mainDiagnostic'), accessor: 'mainDiagnostic' },
                                        { Header: t('dashboardcustomer.table.pathologyId'), accessor: 'pathologyId' },
                                        { Header: t('dashboardcustomer.table.complexity'), accessor: 'complexity' },
                                        { Header: t('dashboardcustomer.table.province'), accessor: 'province' },
                                        { Header: t('dashboardcustomer.table.city'), accessor: 'city' },
                                        { Header: t('dashboardcustomer.table.geographicZone'), accessor: 'geographicZone' },
                                    ]}
                                    tableData={appointments}
                                    defaultPageSize={appointments.length > 5 ? 20 : 5}
                                    colorsColls={['primary']}
                                    onRowClick={(e, t, rowInfo) => this.showGraphicsInfo(rowInfo)}
                                />
                            </CardBody>
                            <CardFooter className="footer-buttons buttons-right">
                                <Button
                                    className="button-export"
                                    onClick={() => this.exportToExcel()}
                                    color="success"
                                    disabled={appointments.length < 1}
                                >
                                    {t('common.exportExcel')}
                                </Button>
                            </CardFooter>
                        </Card>
                        <Element name="scroll-to-charts">
                            <GridContainer className="dashboard-charts">
                                <GridItem xs={6} sm={6}>
                                    <QuantityChart
                                        icon={true}
                                        title={t('dashboardcustomer.chart.financier')}
                                        data={appointments}
                                        column="financier"
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <QuantityChart
                                        icon={true}
                                        title={t('dashboardcustomer.chart.mainDiagnostic')}
                                        data={appointments}
                                        column="mainDiagnostic"
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <QuantityChart
                                        icon={true}
                                        title={t('dashboardcustomer.chart.pathologyId')}
                                        data={appointments}
                                        column="pathologyId"
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <QuantityChart
                                        icon={true}
                                        title={t('dashboardcustomer.chart.city')}
                                        data={appointments}
                                        column="city"
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <QuantityChart
                                        icon={true}
                                        title={t('dashboardcustomer.chart.geographicZone')}
                                        data={appointments}
                                        column="geographicZone"
                                    />
                                </GridItem>
                            </GridContainer>
                        </Element>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

Dashboard.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onGetSupplies: PropTypes.func.isRequired,
    onGetTools: PropTypes.func.isRequired,
    appointments: PropTypes.array,
    supplies: PropTypes.array,
    tools: PropTypes.array,
    t: PropTypes.func,
}

export default withTranslation()(Dashboard);
