import { Add, Assignment, Edit } from '@material-ui/icons'
import { Grid, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'

import Button from '../CustomButtons/Button'
import ButtonSpinner from '../ButtonSpinner'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'
import CardIcon from '../Card/CardIcon'
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip'
import DateInput from '../DateInput'
import DiabeticApiInvoker from '../../api/DiabeticApiIvoker'
import Table from '../Table/Table'
import ValidationInput from '../ValidationInput'
import { browserHistory } from 'react-router'
import { useEffect } from 'react'
import { useMemo } from 'react'

const DiabeticProgram = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterable, setFilterable] = useState(true)
  const [customerName, setCustomerName] = useState(null)
  const [affiliateId, setAffiliateId] = useState(null)
  const [localDateStart, setLocalDateStart] = useState(null)
  const [doctor, setDoctor] = useState(null)

  const addProgram = () => browserHistory.push('/diabetic-program/form')

  let company = localStorage.getItem('itlg_default_company_name')

  const filterSearchClear = () => {
    setCustomerName('')
    setDoctor('')
    setAffiliateId('')
    setLocalDateStart(null)
    setFilterable(false)
    getAllDiabetic()
  }

  const filterSearch = () => {
    setLoading(true)
    let createdUserNameQuery
    let customerNameQuery
    let affiliateIdQuery
    let localDateStartQuery

    if (customerName) {
      customerNameQuery = `customerName=${customerName}`
    }
    if (doctor) {
      createdUserNameQuery = `&&createdUserName=${doctor}`
    }
    if (affiliateId) {
      affiliateIdQuery = `&&affiliateId=${affiliateId}`
    }
    if (localDateStart) {
      localDateStartQuery = `&&localDateStart=${localDateStart}`
    }
    let params = `${customerNameQuery ? customerNameQuery : ''}${
      createdUserNameQuery ? createdUserNameQuery : ''
    }${affiliateIdQuery ? affiliateIdQuery : ''}${
      localDateStartQuery ? localDateStartQuery : ''
    }`

    DiabeticApiInvoker.filterListData(params, (response) => {
      setData(formatDataToTable(response))
    })
  }

  const formatDataToTable = (response) => {
    let data = []
    response.map((diabetic) => {
      let dataDiabetic = {
        name: diabetic.diabetics.patientId.firstName,
        last_name: diabetic.diabetics.patientId.lastName,
        axa_id: diabetic.diabetics.affiliateId,
        date: diabetic.diabetics.dateCreated,
        time: diabetic.diabetics.timeCreated,
        doctor: diabetic.diabetics.createdUserName,
        actions: renderActionComponent(
          diabetic.diabetics.diabeticsId,
          diabetic.editable
        ),
      }
      data.push(dataDiabetic)
    })
    setLoading(false)
    return data
  }

  const renderActionComponent = (id, editable) => {
    return (
      <div className="appointments-actions">
        <Tooltip title={'Ver'}>
          <span>
            <Button
              simple
              justIcon
              color="info"
              authority="diabetic_view"
              onClick={() => goToView(id)}
            >
              <Assignment />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={'Editar'}>
          <div>
            <Button
              simple
              justIcon
              disabled={!editable}
              color="success"
              authority="diabetic_edit"
              onClick={() => goToEdit(id)}
            >
              <Edit />
            </Button>
          </div>
        </Tooltip>
      </div>
    )
  }

  const goToEdit = (id) => {
    browserHistory.push(`/diabetic-program/edit/${id}`)
  }

  const goToView = (id) => {
    browserHistory.push(`/diabetic-program/view/${id}`)
  }

  const getAllDiabetic = () => {
    setLoading(true)
    DiabeticApiInvoker.getAllDiabetics((response) => {
      setData(formatDataToTable(response))
      setFilterable(true)
    })
  }

  const clearFilterData = useMemo(() => {}, [
    customerName,
    doctor,
    affiliateId,
    localDateStart,
  ])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    clearFilterData
    getAllDiabetic()
    window.scrollTo(0, 0)
  }, [filterable])

  return (
    <Grid container className="appointments appointments-list">
      <Grid item xs={12}>
        <Card>
          <CardHeader
            icon
            className="filters-header"
            style={{ paddingBottom: 20 }}
          >
            <div className="header-internment-table">
              <div className="header-icon">
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </div>
              <div className="header-buttons">
                <Tooltip title="Nueva consulta">
                  <span>
                    <Button
                      className="button-new-int"
                      round
                      color="primary"
                      authority="diabetic_new"
                      onClick={addProgram}
                    >
                      <Add className="appointments-button-icon" />{' '}
                      {'Nueva consulta'}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {
              <Grid container className="appointments-filters">
                <Grid
                  container
                  item
                  xs={12}
                  sm={9}
                  md={10}
                  className="appointments-filters-row"
                >
                  <Grid container item xs={12}>
                    <Grid container item xs={12} sm={6}>
                      <ValidationInput
                        text={'Paciente'}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        value={customerName}
                        onChangeValue={(e) => setCustomerName(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValidationInput
                        text={`Nº de beneficiario`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        value={affiliateId}
                        onChangeValue={(e) => setAffiliateId(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateInput
                        text={'Fecha de consulta'}
                        value={localDateStart}
                        onChangeValue={(e) => setLocalDateStart(e)}
                      />
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <ValidationInput
                        text={'Médico'}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        value={doctor}
                        onChangeValue={(e) => setDoctor(e)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  className="filters-actions-appointments"
                >
                  <Grid
                    container
                    item
                    justify="flex-end"
                    className="filters-row"
                  >
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={'Buscar'}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            onClick={filterSearch}
                            label={'Buscar'}
                            labelLoading={'Buscar'}
                            typeButton="submit"
                            color="primary"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button block onClick={filterSearchClear} color="danger">
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </CardHeader>

          <CardBody className="appointments-content-body not-scroll-table filtrable-table">
            <Table
              filterable={filterable}
              striped
              loading={loading}
              tableHeaderColor="primary"
              sortable
              tableHead={[
                {
                  Header: 'Nombre',
                  accessor: 'name',
                  width: 130,
                },
                {
                  Header: 'Apellido',
                  accessor: 'last_name',
                },
                {
                  Header: `Nº de beneficiario`,
                  accessor: 'axa_id',
                },
                {
                  Header: 'Fecha',
                  accessor: 'date',
                },
                { Header: 'Hora', accessor: 'time' },
                { Header: 'Médico', accessor: 'doctor' },
                {
                  Header: 'Acciones',
                  accessor: 'actions',
                  width: 200,
                  sortable: false,
                  filterable: false,
                },
              ]}
              tableData={data}
              colorsColls={['primary']}
              className="-striped -highlight filtrable sticky"
              defaultPageSize={data.length <= 25 ? 25 : data.length}
              showPaginationTop={false}
              showPaginationBottom={true}
            />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DiabeticProgram
