export const MODULES = 'MODULES'
export const ADD_MODULE = 'ADD_MODULE';
export const EDIT_MODULE = 'EDIT_MODULE';
export const UNMOUNT_MODULE = "UNMOUNT_MODULE";

export function getModules(modules) {
    return {type: MODULES, modules}
}

export function addModule(module) {
    return {type: ADD_MODULE, module};
}

export function editModule(module) {
    return {type: EDIT_MODULE, module};
}

export function unmountModule() {
    return {type: UNMOUNT_MODULE, };
}
