import { EMPLOYEES, EMPLOYEES_TYPE, FINANCIERS, PATOLOGIES, PROVINCIES, SUPPLIES, TOOLS } from "../actions/dashboardcustomer";
import _ from 'lodash';

const dashboardcustomer = (state = {}, action) => {
    switch(action.type) {
        case EMPLOYEES:
            const employees = action.employees
            return {...state, employees};
        case EMPLOYEES_TYPE:
            const employeesType = action.employeesType
            return {...state, employeesType};
        case FINANCIERS:
            const financiers = action.financiers
            return {...state, financiers};
        case PATOLOGIES:
            const patologies = action.patologies
            return {...state, patologies};
        case PROVINCIES:
            const provincies = action.provincies
            return {...state, provincies};
        case SUPPLIES:
            const supplies = groupSupplies(action.supplies)
            return {...state, supplies}
        case TOOLS:
            const tools = groupTools(action.tools)
            return {...state, tools}
        default:
            return state;
    }
}

const groupSupplies = (supplies) => {
    var result = _.chain(supplies)
    .groupBy('supplyCatalog.supplyCatalogId')
    .map((group) => ({ key: group[0].supplyCatalog.name, val : _.sumBy(group, 'quantity') }))
    .orderBy('val', 'desc')
    .value();

    return result;
}

const groupTools = (tools) => {
    var result = _.chain(tools)
    .groupBy('toolType.toolTypeId')
    .map((group) => ({ key: group[0].toolType.name, val : group.length }))
    .orderBy('val', 'desc')
    .value();
    return result;
}


export default dashboardcustomer;