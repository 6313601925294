import { CLEAN_CUSTOMER,CUSTOMERS_DETAIL, CUSTOMERS_ADDRESS, CUSTOMERS_PERSON_ADDRESS, ADD_CUSTOMER, EDIT_CUSTOMER } from "../actions/customers";


const customers = (state = {}, action, root) => {
    switch(action.type) {
        case CLEAN_CUSTOMER:
            return {};
        case CUSTOMERS_DETAIL:
            let customers = action.customers
            return {...state, customers}
        case CUSTOMERS_ADDRESS:
            let address = action.address
            return {...state, address}
        case CUSTOMERS_PERSON_ADDRESS:
                let personAddress = action.personAddress
                return {...state, personAddress}
        case ADD_CUSTOMER: 
                const result = addCustomer(state.customers, action.customer)
                return {...state, customers: result}
        case EDIT_CUSTOMER: 
                const updated = updateCustomer(state.customers, action.customer)
                return {...state, customers: updated}
        default:
            return state
    }
}


const addCustomer = (customers, customer) => {
    if (customers && customers.length) {
        customers.push(customer);
        return customers;
    }
    return;
}

const updateCustomer = (customers, customer) => {
    if (customers && customers.length) {
        customers.map((c, i) => {
            if (c.personId === customer.personId) {
                customers[i] = customer
                return;
            }
        })
    }
    return customers;
}

export default customers;
