export const APPOINTMENTS = 'APPOINTMENTS'
export const CLEAN_APPOINTMENTS = 'CLEAN_APPOINTMENTS'
export const APPOINTMENT_TOOLS = 'APPOINTMENT_TOOLS'
export const CHECK_APPOINTMENT_TOOLS = 'CHECK_APPOINTMENT_TOOLS'
export const CHECK_APPOINTMENT_TOOLSEDIT = 'CHECK_APPOINTMENT_TOOLSEDIT'
export const UNDO_APPOINTMENTTOOLS = "UNDO_APPOINTMENTTOOLS";
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENTS'
export const SUBMIT_APPOINTMENT = 'SUBMIT_APPOINTMENT'
export const SELECT_APPOINTMENT = 'SELECT_APPOINTMENT'
export const SELECT_APPOINTMENT_OBJECT = 'SELECT_APPOINTMENT_OBJECT'
export const SELECT_APPOINTMENT_LIST = 'SELECT_APPOINTMENT_LIST'
export const SELECT_APPOINTMENT_SCHEDULES = 'SELECT_APPOINTMENT_SCHEDULES'
export const SAVE_APPOINTMENT_SCHEDULE = 'SAVE_APPOINTMENT_SCHEDULE'

export const SAVE_TOOLS = 'SAVE_TOOLS'
export const DELETE_APPOINTMENTTOOLS = 'DELETE_APPOINTMENTTOOLS'
export const DESELECT_ALL_TOOLS = 'DESELECT_ALL_TOOLS'

export function getAppointments(appointments) {
  return { type: APPOINTMENTS, appointments }
}
export function getSchedulesAppointment(schedules) {
  return { type: SELECT_APPOINTMENT_SCHEDULES, schedules }
}
export function SaveSchedulesAppointment(schedule) {
  return { type: SAVE_APPOINTMENT_SCHEDULE, schedule }
}
export function cleanAppointments() {
  return { type: CLEAN_APPOINTMENTS }
}

export function deselectAllTools() {
  return { type: DESELECT_ALL_TOOLS }
}

export function selectAppointment(appointmentId) {
  return { type: SELECT_APPOINTMENT, appointmentId }
}
export function selectAppointmentList(appointmentId) {
  return { type: SELECT_APPOINTMENT_LIST, appointmentId }
}
export function selectAppointmentObject(appointment) {
  return { type: SELECT_APPOINTMENT_OBJECT, appointment }
}

export function deleteAppointment(appointmentId) {
  return { type: DELETE_APPOINTMENT, appointmentId }
}
export function submitAppointment(appointment) {
  return { type: SUBMIT_APPOINTMENT, appointment }
}
export function fetchAppointmentTools(appointmentsTools) {
  return { type: APPOINTMENT_TOOLS, appointmentsTools }
}
export function checkAppointmentTools(checkAppointmentsTools) {
  return { type: CHECK_APPOINTMENT_TOOLS, checkAppointmentsTools }
}
export function checkAppointmentToolsEdit(checkAppointmentsToolsEdit) {
  return { type: CHECK_APPOINTMENT_TOOLSEDIT, checkAppointmentsToolsEdit }
}
export function saveAppointmentTools(savedTools) {
  return { type: SAVE_TOOLS, savedTools }
}
export function deleteAppointmentTools(tool) {
  return { type: DELETE_APPOINTMENTTOOLS, tool }
}
export function undoTools(checklist, tools) {
  return {type: UNDO_APPOINTMENTTOOLS, checklist, tools}
}
