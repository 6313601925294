export const EMPLOYEES = 'EMPLOYEES';
export const EMPLOYEES_TYPE = 'EMPLOYEES_TYPE';


export function igetEmployees(employees) {
    return {type: EMPLOYEES, employees}
}

export function igetEmployeesType(employeesType) {
    return {type: EMPLOYEES_TYPE, employeesType}
}
