import './Navbar.css'

import React, { Component } from 'react'

import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'
import Sidebar from '../Sidebar/Sidebar'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import image from '../../assets/img/sidebar-2.jpg'
import routes from '../../routes'

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      routes: [],
      user: {},
    }
  }

  componentWillMount() {
    const cookies = new Cookies()
    if (!cookies.get('sessionActive')) {
      browserHistory.push('/login')
    }

    this.setState({
      user: localStorage.getItem('username'),
    })
  }

  componentDidMount() {
    this.setState({
      routes: routes,
    })
  }

  render() {
    return (
      <Sidebar
        routes={this.state.routes}
        logoText={'HMM'}
        color={'white'}
        bgColor={'white'}
        user={this.state.user}
        image={image}
        miniActive={this.props.miniActive}
        handleDrawerToggle={this.props.handleDrawerToggle}
        open={this.props.open}
        companies={this.props.companies}
      />
    )
  }
}

Navbar.propTypes = {
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    companies: state.user.companies,
  }
}

export default connect(mapStateToProps, null)(Navbar)
