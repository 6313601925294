import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Location(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M21.99,11.793c-0.237,0.266-0.433,0.475-0.551,0.596l-1.758,1.827c-0.346,1.197-0.968,2.276-1.786,3.173C17.636,16.585,16.89,16,16,16h-1v-2c0-1.105-0.895-2-2-2h-3c-0.552,0-1-0.448-1-1v0c0-0.552,0.448-1,1-1h0c0.552,0,1-0.448,1-1V8c0-0.552,0.448-1,1-1h1c0.589,0,1.114-0.26,1.48-0.665C14.188,5.554,14,4.762,14,4c0-0.603,0.091-1.184,0.258-1.733C13.531,2.098,12.777,2,12,2C8.739,2,5.846,3.577,4.019,6H4v0.026c-1.571,2.098-2.352,4.819-1.847,7.732c0.703,4.055,3.934,7.337,7.984,8.073C16.466,22.98,22,18.124,22,12C22,11.93,21.991,11.862,21.99,11.793z M4,12c0-0.616,0.077-1.214,0.21-1.79L6,12l3,3v1c0,1.105,0.895,2,2,2v1.931C7.06,19.436,4,16.072,4,12z" />
      <path d="M20,0c-2.209,0-4,1.791-4,4c0,2.062,2.083,4.793,3.243,6.154c0.399,0.468,1.116,0.468,1.515,0C21.917,8.793,24,6.062,24,4C24,1.791,22.209,0,20,0z M20,5.429c-0.789,0-1.429-0.64-1.429-1.429c0-0.789,0.64-1.429,1.429-1.429S21.429,3.211,21.429,4C21.429,4.789,20.789,5.429,20,5.429z" />
    </SvgIcon>
  );
}
