import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Checkbox, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import ButtonSpinner from '../../ButtonSpinner';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table'
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import './NewEmployeeGeographic.css';
import styles from '../../../assets/components/customCheckboxRadioSwitch';

class NewEmployeeGeographic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openEmployeeGeographic: true,
            default: false,
            open: false,
            alertOpen: false,
            save: false,
            loading: false,
            busy: false,
        }
    }

    componentDidMount() {
        this.setState({ loading: true,
        });
        this.getGeographic();
    };

    componentWillUnmount(){
        this.props.onUncheckAllGeographic();
    }
  
    getGeographic(){
        PersonApiInvoker.getGeographicsCompany(data => {
            this.props.onGetGeographics(data)
            this.setState({ loading: false });
        }, e => {
            this.setState({ loading: false });
        });
    }

    closeModal(){
        this.setState({
            open: false,
            type: '',
            openEmployeeType: false,
            component: null,
            current: null,
        });
    }

    saveEmployeeGeographic() {
        if(this.props.checkGeographic.length > 0){
            this.setState({
                loading: true,
                busy: true,
            })
            PersonApiInvoker.postEmployeeGeographic(this.props.personId, this.props.checkGeographic, data => {
                this.openAlert('success', this.props.t('employee.new.modal.success'))
                this.setState({
                    loading: false,
                    busy: false,
                    save: true,
                    open: false,
                })
                this.props.onPutEmployeeGeographic(this.props.checkGeographic)
                this.props.onUncheckAllGeographic()
                this.props.onGeographicSubmited()   
            }, e => {
                this.setState({
                    loading: false,
                    busy: false,
                });
            })
        } else {
            this.props.onGeographicSubmited();
            this.setState({
                loading: false,
                busy: false,
            });
        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        }, () => {
            setTimeout(() => {
                this.setState({ alertOpen: false });
            }, 5000);
        });
    }

    render() {
        const { t, classes } = this.props;
        let allGeographics = this.props.geographics || [];
        let selected = this.props.currentEmployeeGeographics.map(p => {
            return p.geographicZoneId || p
        })
        let geographicsToShow = allGeographics.filter(a => selected.indexOf(a.geographicZoneId || a) < 0 ).map(a => {
            return {
                ...a,
                input: <Checkbox
                        tabIndex={-1}
                        onChange={() => this.props.onCheckEmployeeGeographic(a)}
                        checked={this.props.checkGeographic.indexOf(a.geographicZoneId) !== -1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                }
        })

        return (
            <GridContainer className="check-input">
                <GridItem xs={12}>
                <Table
                    loading={this.state.loading}
                    tableHeaderColor="primary"
                    defaultPageSize={geographicsToShow.length > 6 ? 20 : 5}
                    tableHead={[
                        { Header: '#', accessor: 'geographicZoneId', sortable: false},
                        { Header: ' ', accessor: 'input', sortable: false},
                        { Header: t('employee.new.province'), accessor: 'provinceName', filterable: true, sortable: false},
                        { Header: t('employee.new.location'), accessor: 'locationName', filterable: true, sortable: false},
                        { Header: t('employee.new.GeographicZone'), accessor: 'detail', filterable: true, sortable: false},
                    ]}
                    sortable={false}
                    tableData={geographicsToShow}
                    colorsColls={['primary']}
                    showPaginationBottom={true}
                />
                <GridItem xs={12} className="buttonFooterModal no-padding">
                    <ButtonSpinner
                        className="buttonPlusFooter"
                        onClick={() => this.saveEmployeeGeographic()}
                        disabled={this.state.busy}
                        label={t('employee.new.accept')}
                        labelLoading={t('common.saving')}
                        loading={this.state.busy}
                        typeButton="submit"
                        color="success"
                    />
                </GridItem> 
                </GridItem>
          </GridContainer>    
        )
    };
}

NewEmployeeGeographic.defaultProps = {
    employeesGeographic: [],
    checkGeographic: [],
    currentEmployeeGeographics: [],
    onCheckEmployeeGeographic: () => {},
    onUncheckAllGeographic: () => {},
    onPutEmployeeGeographic: () => {},
}

NewEmployeeGeographic.propTypes = {
    onUncheckAllGeographic: PropTypes.func,
    onPutEmployeeGeographic: PropTypes.func,
    personId: PropTypes.number,
    onGetGeographics: PropTypes.func,
    onCheckEmployeeGeographic: PropTypes.func,
    employeesGeographic: PropTypes.array,
    checkGeographic: PropTypes.array
}

export default  withStyles(styles)(withTranslation()(NewEmployeeGeographic));
