import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import { Button, MenuItem, MenuList, Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Card';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from '../../../../../../Grid/GridContainer';
import GridItem from '../../../../../../Grid/GridItem';
import SelectInput from '../../../../../../SelectInput';

class CustomInputChip extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props

        this.state = {
            show_items: false,
            items: [],
            locations: [],
            filters: [
                {id: 'financiado', title: t('dashboardprofesional.filter.employee'), code: 'employees', type: 'select', multiple: true},
                {id: 'financiado', title: t('dashboardprofesional.filter.employeesType'), code: 'employeesType', type: 'select', multiple: true},
            ] 
        }
    }

    onChipClick = () => {
       this.setState({show_items: !this.state.show_items})
    }

    handleEvent = (e) => {
        const v = this.state.filters[e.target.id];
        let source = null;
        if (v.code === 'employeesType') {
            source = this.props.employeesType;
        } else if (v.code === 'employees') {
            source = this.props.employees;
        }
        this.setState({items: source, show_items: !this.state.show_items, selected_type: v})
    }

    findValue = (value) => {
        let r = null;
        this.state.items.map(d => {
            if (value === d.id) {
                r = d.value;
            }
        });
        return r;
    }

    handleValue(value, state) {
        const item = this.state.selected_type;
        item.value = value;
        if (item.code === 'province') {
            this.getLocations(value);
        } else if (item.code === 'location') {
            this.getZones(value);
        }
        item.name = this.findValue(value);
        this.setState({selected_type: item});
    }

    handleCloseDialog = () => {
        this.setState({selected_type: null});
    }

    handleApply = () => {
        if (!this.state.selected_type.value) {
            return;
        }
        const s = this.state.selected_type;
        this.props.onApply(s);
        this.setState({selected_type: null})
    }

    renderDialogSelect = () => {
        const { t } = this.props;
        const { items, selected_type } = this.state;
        
        return (
            <Dialog
                open={true}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <GridContainer className="filters" justify="center">
                        <GridItem xs={12} >
                            <SelectInput
                            className="customInputChipSelect" 
                            elements={items} 
                            onSelectedValue={(value) => this.handleValue(value, 'item')}
                            label={selected_type.title}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog} color="primary">
                        {t('dashboardprofesional.filters.close')}
                    </Button>
                    <Button onClick={this.handleApply} color="primary">
                        {t('dashboardprofesional.filters.apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    has = (keyvalue) => {
        let p = false;
        this.state.filters.map((item, key) => {
            if (item.code === keyvalue && item.value) {
                p = true;
            }
        });
        return p;
    }

    hasCity = () => {
        return false;
    }

    findItemValue = (code) => {
        let ret = false;
        this.props.selecteditems.map((item, key) => {
            if (item.code === code) {
                ret = true;
            }
        });
        return ret;
    }

    renderMenuList = (filters) => {
        return filters.map((item, key) => {
            let findc = this.findItemValue(item.code);
            if (item.multiple) {
                findc = false;
            }
            if (findc || (item.code === 'location' && !this.has('province')) || (item.code === 'geographicZone' && !this.has('location'))) {
                return (<div/>);
            }
            return (
                <MenuItem id={key} onClick={this.handleEvent}>{item.title}</MenuItem>
            )}
        )
    }

    render() {
        const { id, label } = this.props;
        const { filters, selected_type, show_items } = this.state;

        if (selected_type && selected_type.type === 'select') {
            return this.renderDialogSelect();
        }
        return (
            <div>
                <Chip id={id} label={label} handleInteraction={this.onChipClick} />
                {show_items ? 
                    <div style={{position: 'absolute'}}>
                        <Paper>
                            <MenuList>
                                {this.renderMenuList(filters)}
                            </MenuList>
                        </Paper>
                    </div>
                : <div/>
                }
            </div>
        )
    }
}

CustomInputChip.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onGetEmployeesType: PropTypes.func,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    employeesType: PropTypes.array,
}

export default withTranslation()(CustomInputChip);
