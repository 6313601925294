import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
} from 'react-google-maps';
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import ButtonSpinner from '../ButtonSpinner';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import CardHeader from '../Card/CardHeader';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import { Itinerary } from '../../icons';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PinBlue from '../../assets/img/pin_blue.png';
import PinGreen from '../../assets/img/pin_green.png';
import CustomDialog from '../CustomDialog';
import { browserHistory } from 'react-router';

import './Routes.css';
import CustomInput from '../CustomInput/CustomInput';

const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={8}
            center={{ lat: props.location.lat, lng: props.location.lng }}
            defaultOptions={{ scrollwheel: false }}
        >
            {props.directions && (
                <DirectionsRenderer
                    directions={props.directions}
                    options={{ suppressMarkers: true }}
                />
            )}
            {props.waypoints &&
                props.waypoints.map((d, i) => {
                    return (
                        <Marker key={i}
                            className="routes-marker"
                            defaultLabel={{ text: (i + 1).toString(), fontSize: '15px', color: '#3C4858' }}
                            defaultIcon={
                                d.status.scheduleStatusId === 2
                                    ? {
                                        url: PinGreen,
                                        scaledSize: { width: 40, height: 40 },
                                        size: { width: 40, height: 40 },
                                        labelOrigin: new window.google.maps.Point(20, 15),
                                    }
                                    : {
                                        url: PinBlue,
                                        scaledSize: { width: 40, height: 40 },
                                        size: { width: 40, height: 40 },
                                        labelOrigin: new window.google.maps.Point(20, 15),
                                    }
                            }
                            position={d.location}
                            onClick={() => props.showWaypointDetail(d)}
                        />
                    )
                })}
        </GoogleMap>
    ))
)

class Routes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routes: [],
            currentLocation: {},
            markers: [],
            directions: [],
            customers: [],
            employees: [],
            schedules: [],
            waypoints: [],
            selectedCustomer: '',
            selectedEmployee: '',
            alertOpen: false,
            alertColor: 'info',
            alertMessage: '',
            alert: null,
            loading: false,
            openDetail: false,
            waypoint: {

            }
        }
    }

    componentWillMount() {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;
                this.setState({
                    currentLocation: {
                        lat: coords.latitude,
                        lng: coords.longitude,
                    },
                })
            })
        }

        if (this.props.customers) {
            this.setState({ customers: this.props.customers });
        } else {
            this.getCustomers();
        }

        if (this.props.employees) {
            this.setState({ employees: this.props.employees });
        } else {
            this.getEmployees();
        }
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            this.setState({ customers: next.customers });
        }

        if (next.employees) {
            this.setState({ employees: next.employees });
        }
    }

    showWaypointDetail(waypoint) {
        this.setState({
            waypoint: waypoint,
            openDetail: true
        });
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    getCustomers() {
        PersonApiInvoker.getCustomers(false, data => {
            if (data && data.length) {
                this.props.onFetchCustomers(data);
            }
        }, null);
    }

    getEmployees() {
        PersonApiInvoker.getEmployees(data => {
            if (data && data.length) {
                this.props.onFetchEmployees(data);
            }
        }, null);
    }

    getSchedules(employeeId, selectedDate) {
        this.setState({ loading: true });
        PersonApiInvoker.postEmployeeHojaRuta({
            personId: employeeId,
            date: moment(selectedDate).format('YYYY-MM-DD'),
        }, data => {
            this.paintSchedules(data);
            this.setState({ loading: false });

        }, err => {
            console.error('** error postEmployeeHojaRuta', err);
            this.setState({ loading: false });
        });
    }

    setDirection(schedules) {
        const DirectionsService = new window.google.maps.DirectionsService();
        if (schedules.length > 0) {
            DirectionsService.route(
                {
                    origin: schedules[0].location,
                    destination: schedules[schedules.length - 1].location,
                    waypoints: schedules,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({ directions: result });
                    } else {
                        console.error(`** error fetching directions ${result}`)
                    }
                }
            )
        } else {
            this.openAlert(
                'danger',
                this.props.t('routes.messageNoShifts', {
                    date: moment(this.state.selectedDate).format('DD/MM/YYYY')
                })
            );
        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    paintSchedules(schedules) {
        let daySchedules = []
        let waypoints = []

        schedules.map(s => {
            daySchedules.push({
                location: new window.google.maps.LatLng(
                    s.address.latitude,
                    s.address.longitude
                ),
                stopover: true,
            })
            waypoints.push({
                location: new window.google.maps.LatLng(
                    s.address.latitude,
                    s.address.longitude
                ),
                description: s.appointmentDescription,
                status: s.scheduleStatus,
                scheduleDateTime: moment(s.scheduleDateTime).format('HH:mm'),
                customer: `${s.customer.firstName} ${s.customer.lastName}`,
                appointmentId: s.appointmentId,
                practiceName: s.practiceName,
                scheduleId: s.scheduleId,
                address: s.addressFull,


            });
        });

        daySchedules = _.orderBy(daySchedules, 'startDate', 'asc')
        this.setState({ waypoints });
        this.setDirection(daySchedules);
    }

    findCustomerAddress = () => {
        let address;
        this.state.customers.map(c => {
            if (this.state.selectedCustomer === c.personId) {
                address = c.address
                return address;
            }
        })
        return address;
    }

    handleValueDate = (value) => {
        const date = value && moment(value).format('YYYY-MM-DD');
        this.setState({ selectedDate: date });
    }

    handleFilter() {
        this.getSchedules(this.state.selectedEmployee, this.state.selectedDate);
    }

    closeModal = () => {
        this.setState({
            openDetail: false
        })
    }

    render() {
        const { t } = this.props;
        const {
            alert, alertColor, alertMessage, alertOpen, currentLocation, employees, directions, loading, selectedDate, selectedEmployee, waypoints
        } = this.state;
        const disabledButton = !(selectedDate && selectedEmployee);
        return (
            <GridContainer className="routes">
                {alert}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Itinerary />
                            </CardIcon>
                            <GridContainer className="routes-filters">
                                <GridItem xs={12} sm={6}>
                                    <FormControl className="routes-dropdown">
                                        <SelectInput
                                            onSelectedValue={(value) => this.setState({ selectedEmployee: value })}
                                            onGetOptionLabel={(value) => `${value.firstName} ${value.lastName}`}
                                            valueAccessor="personId"
                                            elements={employees}
                                            value={selectedEmployee}
                                            label={`${t('routes.input.professionals')} *`}
                                        >
                                        </SelectInput>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={4}>
                                    <DateInput
                                        className="date-filter"
                                        text={`${t('routes.input.date')} *`}
                                        value={selectedDate}
                                        onChangeValue={(value) => this.handleValueDate(value)}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={2}>
                                    <ComponentDisabledWithTooltip
                                        tooltipText={t('routes.buttonTooltip')}
                                        disabled={disabledButton}
                                        loading={loading}
                                        component={
                                            <ButtonSpinner
                                                block
                                                disabled={disabledButton || loading}
                                                color="success"
                                                onClick={() => this.handleFilter()}
                                                label={t('routes.input.filter')}
                                                labelLoading={t('routes.input.filtering')}
                                                loading={loading}
                                                typeButton="submit"
                                            />
                                        }
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            <RegularMap
                                location={currentLocation}
                                directions={directions}
                                waypoints={waypoints}
                                showWaypointDetail={this.showWaypointDetail.bind(this)}
                                googleMapURL="/googlemap"
                                loadingElement={<div style={{ height: '100%' }} />}
                                containerElement={
                                    <div className="regular-map" />
                                }
                                mapElement={<div style={{ height: '100%' }} />}
                            />
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>

                <CustomDialog
                    maxWidth="sm"
                    title={t('route.detail.title')}
                    open={this.state.openDetail}
                    onClose={() => this.closeModal()}
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.scheduleId')}
                                value={this.state.waypoint.scheduleId}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.customerName')}
                                value={this.state.waypoint.customer}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.address')}
                                value={this.state.waypoint.address}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.time')}
                                value={this.state.waypoint.scheduleDateTime}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.practiceName')}
                                value={this.state.waypoint.practiceName}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.scheduleStatus')}
                                value={
                                    t(`schedule.booking.state.${this.state.waypoint.status}`)}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <p className="label-link">
                                {t('route.detail.appointmentId')}
                            </p>
                            <p className="value-link">
                                <span onClick={() => {
                                    browserHistory.push({
                                    state: { mode: 'view', action: "finished_system" },
                                    pathname: `/solicitudes/${this.state.waypoint.appointmentId}`,
                                    });
                                }}>{this.state.waypoint.appointmentId}</span>
                            </p>

                            
                        </GridItem>
                    </GridContainer>
                </CustomDialog>





            </GridContainer>
        )
    }
}

Routes.propTypes = {
    onFetchCustomers: PropTypes.func.isRequired,
    onFetchEmployees: PropTypes.func.isRequired,
    customers: PropTypes.array,
    employees: PropTypes.array,
}

export default withTranslation()(Routes);
