import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Checkbox, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import ButtonSpinner from '../../ButtonSpinner';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import styles from '../../../assets/components/customCheckboxRadioSwitch';
import './NewEmployeeType.css';

class NewEmployeeType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openEmployeeType: true,
            default: false,
            open: false,
            alertOpen: false,
            save: false,
            loading: false,
            checkedTypesList: [],
            busy: false,
            checkAllEmployeeTypes: false,
        }
    }

    componentDidMount() {
        this.getTypes();
    }

    componentWillUnmount() {
        this.props.onUncheckAll();
    }
  
    getTypes() {
        this.setState({ loading: true });
        PersonApiInvoker.getEmployeesType('PROFESSIONAL', data => {
            this.props.onGetEmployeeTypes(data);
            this.setState({ loading: false });
        }, () => {
            this.setState({ loading: false });
        });
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            openEmployeeType: false,
            component: null,
            current: null,
        });
    }

    saveEmployeeType() {
        if(this.props.checkType.length > 0){
            this.setState({
                loading: true,
                busy: true,
            });
            
            PersonApiInvoker.postEmployeeType(this.props.personId, this.props.checkType, data => {
                this.setState({
                    save: true,
                    busy: false,
                    loading: false,
                    open: false,
                });
                this.openAlert('success', this.props.t('employee.new.modal.success'));
                this.props.onPutEmployeeType(this.props.checkType);
                this.props.onUncheckAll();
                this.props.onTypeSubmited();
            }, () => {
                this.setState({ loading: false, busy: false });
            });
        } else {
            this.props.onTypeSubmited();
            this.setState({ loading: false, busy: false });
        }
    }

    openAlert(color, message, extraState) {
        this.setState(_.assign({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        }, extraState));

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    handleSelectedAllTypes(event) {
        this.setState({ checkAllEmployeeTypes: event.target.checked });
        if (event.target.checked) {
            this.props.onCheckAllTypes(this.props.employeesType);
        } else {
            this.props.onUncheckAll();
        }
    }

    renderData = (selected, data) => {
        const { classes } = this.props;
        const selectedEmployee = selected.map((p) => p.employeeTypeId || p);

        return data.filter((d) => selectedEmployee.indexOf(d.employeeTypeId) < 0 ).map((a) => {
            const practiceTypeList = a.practiceType && a.practiceType.length ? a.practiceType.map((p) => p.name).join(', ') : '';
            const practiceTypeGroupList = a.practiceTypeGroup && a.practiceTypeGroup ? a.practiceTypeGroup.map((p) => p.name).join(', ') : '';
            return {
                ...a,
                input: (
                        <Checkbox
                            tabIndex={-1}
                            onChange={() => {
                                this.props.onCheckEmployeeType(a);
                                this.setState({ checkAllEmployeeTypes: false });
                            }}
                            checked={this.props.checkType.indexOf(a.employeeTypeId) !== -1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    ),
                employeeType: a.employeeTypeName,
                practiceTypeGroup: practiceTypeGroupList,
                praticeType: practiceTypeList,
            }
        });
    }

    render() {
        const { t, employee, employeesType } = this.props;
        const { loading } = this.state;
        const dataTable = this.renderData(employee.employeeTypes, employeesType);

        return (
            <GridContainer className="check-input NewEmployeeTypeModal">
                <GridItem xs={12} className="filtrable-table">
                    <Table
                        loading={loading}
                        tableHeaderColor="primary"
                        defaultPageSize={dataTable.length <= 10 ? 10 : dataTable.length}
                        tableHead={[
                            { Header:
                                <div className="check-actions">
                                    <Checkbox
                                        tabIndex={-1}
                                        id="allTypes"
                                        checked={this.state.checkAllEmployeeTypes}
                                        onClick={(e) => this.handleSelectedAllTypes(e)}
                                    />
                              </div>, accessor: 'input', sortable: false, width: 100 },
                            { Header: t('employees.practiceTypeGroup'), accessor: 'practiceTypeGroup', filterable: true, sortable: false },
                            { Header: t('employees.praticeType'), accessor: 'praticeType', filterable: true, sortable: false},
                        ]}
                        sortable={false}
                        tableData={dataTable}
                        colorsColls={['primary']}
                        showPaginationBottom={false}
                    />
                </GridItem>
                <GridItem xs={12} className="buttonFooterModal">
                    <ButtonSpinner
                        className="buttonPlusFooter"
                        onClick={() => this.saveEmployeeType()}
                        disabled={this.state.busy}
                        label={t('employee.new.accept')}
                        labelLoading={t('common.saving')}
                        loading={this.state.busy}
                        typeButton="submit"
                        color="success"
                    />
                </GridItem>
            </GridContainer>    
        )
    };
}

NewEmployeeType.defaultProps = {
    employeesType: [],
    checkType: [],
    onCheckEmployeeType: () => {},
    onUncheckAll: () => {},
    onCheckAllTypes: () => {},
    onPutEmployeeType: () => {},
}

NewEmployeeType.propTypes = {
    onUncheckAll: PropTypes.func,
    onCheckAllTypes: PropTypes.func,
    onPutEmployeeType: PropTypes.func,
    personId: PropTypes.number,
    onGetEmployeeTypes: PropTypes.func,
    onCheckEmployeeType: PropTypes.func,
    employeesType: PropTypes.array,
    checkType: PropTypes.array,
}

export default withStyles(styles)(withTranslation()(NewEmployeeType));
