import Button from '../CustomButtons/Button'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  buttonSpinner: {
    margin: '5px 0',
    '& svg': {
      margin: 0,
    },
    '& span.labelCont': {
      display: 'inline-block',
      padding: '0 8px',
    },
  },
  circularProgress: {
    color: 'white',
    marginLeft: 0,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
}))

const ButtonSpinner = ({
  block,
  className,
  disabled,
  fullWidth,
  typeButton,
  onClick,
  color,
  loading,
  label,
  labelLoading,
  size,
}) => {
  const classes = useStyles()

  return (
    <Button
      className={clsx(classes.buttonSpinner, className)}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      type={typeButton}
      onClick={onClick}
      block={block}
      size={size}
    >
      {loading ? (
        <>
          <CircularProgress className={classes.circularProgress} size={18} />
          <span className="labelCont">{labelLoading}</span>
        </>
      ) : (
        label
      )}
    </Button>
  )
}

ButtonSpinner.defaultProps = {
  block: false,
  className: '',
  color: 'primary',
  disabled: false,
  fullWidth: false,
  labelLoading: '',
  loading: false,
  onClick: null,
  typeButton: 'text',
}

ButtonSpinner.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelLoading: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  typeButton: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
}

export default ButtonSpinner
