import { connect } from 'react-redux';
import { getEmployeeTypes, getFinanciers, getProvincies } from "../actions/dashboardprofesional";
import DashboardEconomicProfessional from '../components/DashboardEconomicProfessional/DashboardEconomicProfessional';
import Filters from '../components/DashboardEconomicProfessional/Filters';

const mapStateToProps = (state) => {
    return {
        financiers: state.dashboardprofesional.financiers,
        provincies: state.dashboardprofesional.provincies,
        employeeTypes: state.dashboardprofesional.employeeTypes,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetFinanciers: (financiers) => {
            dispatch(getFinanciers(financiers))
        },
        onGetEmployeeTypes: (employeeTypes) => {
            dispatch(getEmployeeTypes(employeeTypes))
        },
        onGetProvincies: (provincies) => {
            dispatch(getProvincies(provincies))
        },
    }
}

const DashboardEconomicProfesionalService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardEconomicProfessional);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters)

export {DashboardEconomicProfesionalService, FiltersService};