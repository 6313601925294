export const TOOLS_DETAIL = 'TOOLS_DETAIL'
export const ADD_TOOL = 'ADD_TOOL';
export const EDIT_TOOL = 'EDIT_TOOL';
export const TOOLTYPES_DETAIL = "TOOLTYPES_DETAIL";
export const UNMOUNT_TOOL = "UNMOUNT_TOOL";

export function getTools(tools) {
    return {type: TOOLS_DETAIL, tools}
}

export function getToolTypes(tooltypes) {
    return {type: TOOLTYPES_DETAIL, tooltypes}
}

export function addTool(tool) {
    return {type: ADD_TOOL, tool};
}

export function editTool(tool) {
    return {type: EDIT_TOOL, tool};
}

export function unmountTool() {
    return {type: UNMOUNT_TOOL};
}