import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { makeStyles } from '@material-ui/core';
import { dangerColor, grayColor, successColor, whiteColor } from '../../assets/components/material-dashboard-pro-react';

const pxToRem = (px) => `${px / 16}rem`;

const useStyles = makeStyles(() =>({
  customSweetAlert: {
    display: 'inline-block',
    marginTop: '-100px !important',
    '& h2': {
      fontSize: pxToRem(21),
      fontWeight: 300,
      color: grayColor[2],
    },
    '& .lead': {
      color: grayColor[2],
      fontSize: pxToRem(14),
      fontWeight: 300,
      padding: '15px 0 5px',
      '& .schedule-errors-list, .confirmBodyTextSecondLine': {
        margin: 0,
        padding: 0,
        '& .schedule-errors-item, li': {
          display: 'flex',
          justifyContent: 'space-evenly',
          '& + li': {
            marginTop: 12,
          },
        },
      },
    },
    '& .customAlertButtons': {
      marginTop: 32,
    },
    '&>p:last-child': {
      marginBottom: 0,
      '& span': {
        display: 'inline-block',
      },
    },
    '& .btn': {
      backgroundColor: grayColor[0],
      border: 'none',
      borderRadius: 3,
      boxShadow: '0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)',
      color: whiteColor,
      cursor: 'pointer',
      fontSize: pxToRem(12),
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.42857143,
      margin: `${pxToRem(8)} 1px`,
      minHeight: 40,
      minWidth: 120,
      padding: '12px 30px',
      position: 'relative',
      textAlign: 'center',
      textTransform: 'uppercase',
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      touchAction: 'manipulation',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      willChange: 'boxShadow, transform',
      '&.danger': {
        backgroundColor: dangerColor[0],
        boxShadow: '0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)',
      },
      '&.success': {
        backgroundColor: successColor[0],
        boxShadow: '0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
      },
    },
  },
}));

const CustomSweetAlert = ({ cancelBtnCssClass, cancelBtnText, closeOnClickOutside, confirmBtnCssClass, confirmBtnText, message, onCancel, onConfirm, showCancel, showConfirm, title, type, className }) => {
  const classes = useStyles();

  return (
    <SweetAlert
      cancelBtnCssClass={cancelBtnCssClass}
      cancelBtnText={cancelBtnText}
      closeOnClickOutside={closeOnClickOutside}
      confirmBtnCssClass={confirmBtnCssClass}
      confirmBtnText={confirmBtnText}
      customClass={`${classes.customSweetAlert} ${className}`}
      onCancel={onCancel}
      onConfirm={onConfirm}
      showCancel={showCancel}
      showConfirm={showConfirm}
      title={title}
      type={type}
    >
      {message}
    </SweetAlert>
  )
}

CustomSweetAlert.defaultProps = {
  className: '',
  cancelBtnText: 'cancel',
  cancelBtnCssClass: '',
  closeOnClickOutside: undefined,
  confirmBtnCssClass: '',
  confirmBtnText: 'ok',
  message: '',
  onCancel: undefined,
  onConfirm: undefined,
  showCancel: true,
  showConfirm: true,
  title: '',
  type: 'default',
}

CustomSweetAlert.propTypes = {
  className: PropTypes.string,
  cancelBtnCssClass: PropTypes.string,
  cancelBtnText: PropTypes.string,
  closeOnClickOutside: PropTypes.func,
  confirmBtnCssClass: PropTypes.string,
  confirmBtnText: PropTypes.string,
  message: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf('default'|'info'|'success'|'warning'|'danger'|'error'|'input'|'custom'|'controlled'),
}

export default CustomSweetAlert;
