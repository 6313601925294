export const EMPLOYEES = 'EMPLOYEES';
export const EMPLOYEESTYPE = 'EMPLOYEESTYPE';

export const SUPPLIES = 'SUPPLIES';
export const TOOLS = 'TOOLS';

export function getEmployees(employees) {
    return {type: EMPLOYEES, employees}
}
export function getEmployeesType(employeesType) {
    return {type: EMPLOYEESTYPE, employeesType}
}
export function getSupplies(supplies) {
    return {type: SUPPLIES, supplies}
}

export function getTools(tools) {
    return {type: TOOLS, tools}
}