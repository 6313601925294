import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function QrCode(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M 4 3 C 3.448 3 3 3.448 3 4 L 3 8 C 3 8.552 3.448 9 4 9 L 8 9 C 8.552 9 9 8.552 9 8 L 9 4 C 9 3.448 8.552 3 8 3 L 4 3 z M 11 3 L 11 5 L 13 5 L 13 3 L 11 3 z M 16 3 C 15.448 3 15 3.448 15 4 L 15 8 C 15 8.552 15.448 9 16 9 L 20 9 C 20.552 9 21 8.552 21 8 L 21 4 C 21 3.448 20.552 3 20 3 L 16 3 z M 5 5 L 7 5 L 7 7 L 5 7 L 5 5 z M 17 5 L 19 5 L 19 7 L 17 7 L 17 5 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 3 11 L 3 13 L 5 13 L 5 11 L 3 11 z M 7 11 L 7 13 L 9 13 L 9 11 L 7 11 z M 11 11 L 11 13 L 13 13 L 13 11 L 11 11 z M 13 13 L 13 15 L 15 15 L 15 13 L 13 13 z M 15 13 L 17 13 L 17 11 L 15 11 L 15 13 z M 17 13 L 17 15 L 19 15 L 19 13 L 17 13 z M 19 13 L 21 13 L 21 11 L 19 11 L 19 13 z M 19 15 L 19 17 L 21 17 L 21 15 L 19 15 z M 19 17 L 17 17 L 17 19 L 19 19 L 19 17 z M 19 19 L 19 21 L 21 21 L 21 19 L 19 19 z M 17 19 L 15 19 L 15 21 L 17 21 L 17 19 z M 15 19 L 15 17 L 13 17 L 13 19 L 15 19 z M 13 19 L 11 19 L 11 21 L 13 21 L 13 19 z M 13 17 L 13 15 L 11 15 L 11 17 L 13 17 z M 15 17 L 17 17 L 17 15 L 15 15 L 15 17 z M 4 15 C 3.448 15 3 15.448 3 16 L 3 20 C 3 20.552 3.448 21 4 21 L 8 21 C 8.552 21 9 20.552 9 20 L 9 16 C 9 15.448 8.552 15 8 15 L 4 15 z M 5 17 L 7 17 L 7 19 L 5 19 L 5 17 z" />
    </SvgIcon>
  );
}
