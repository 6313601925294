import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ChipSet, Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';

class ChipFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      professionals: [],
      employees: [],
      dateFrom: '',
      dateTo: '',
      professional: [],
      elements: [],
    }
  }

  addFilter = (s) => {
    const c = this.props.chips;
    c.push(JSON.parse(JSON.stringify(s)));
    this.props.onChangeChips(c, this.props.chips);
  }

  onChipRemove = (s) => {
    const { chips, onChangeChips } = this.props;
    chips.map((item, key) => {
      const label = `${item.title} '${item.name}' `;
      if (label === s) {
        chips.splice(key, 1);
        onChangeChips(item, this.props);
      }
    });

    this.setState({ refresh: true });
  }

  render() {     
    const {
      chips, employees, financiers, practiceTypes, provincies, t,
    } = this.props;
    return (
      <ChipSet>
        <CustomInputChip
          selecteditems={chips}
          id="add_filter"
          label={t('common.addFilter')}
          financiers={financiers}
          practiceTypes={practiceTypes}
          provincies={provincies}
          employees={employees}
          onApply={this.addFilter}
        />
        {chips.map((item, key) => {
          const label = `${item.title} '${item.name}' `;
          return (
            <Chip
              filter={true}
              selecteditems={chips}
              trailingIcon={<Cancel/>}
              shouldRemoveOnTrailingIconClick={false}
              handleTrailingIconInteraction={this.onChipRemove}
              id={label}
              label={label}
            />
          );
        })}
      </ChipSet>
    )
  }
}

ChipFilters.propTypes = {
  onGetEmployees: PropTypes.func,
  onClearFilters: PropTypes.func,
  onChange: PropTypes.func,
  employees: PropTypes.array,
}

export default withTranslation()(ChipFilters);