import { SCHEDULES, SCHEDULES_STATUS, SCHEDULES_TYPES, SAVE_NEW_SCHEDULE } from "../actions/schedules";

const schedules = (state = {}, action) => {
    switch(action.type) {
        case SCHEDULES:
            let schedules = action.schedules
            return {...state, schedules}
        case SCHEDULES_STATUS:
            let status = action.status
            return {...state, status}
        case SCHEDULES_TYPES:
            let types = action.types
            return {...state, types}
        case SAVE_NEW_SCHEDULE:
            let result = addSchedule(state.schedules, action.schedule)
            return {...state, schedules: result}
        default:
            return state
    }
}

const addSchedule = (schedules, schedule) => {
    if (schedules && schedule && schedules.length) {
        schedules.push(schedule)
        return schedules
    }
    return
}

export default schedules;