import './i18n'

import React, { Suspense } from 'react'
import { Redirect, Route, Router, browserHistory } from 'react-router'

import AlertsService from './containers/AlertsService'
import AppointmentsComponents from './containers/AppointmentsService'
import AppointmentsToolsService from './containers/AppointmentsToolsService'
import BookingsService from './containers/BookingsService'
import CustomerComponents from './containers/NewCustomerService'
import CustomersService from './containers/CustomersService'
import { DashboardCustomerService } from './containers/DashboardCustomerService'
import { DashboardEconomicProfesionalService } from './containers/DashboardEconomicProfesionalService'
import { DashboardEconomicSupplyService } from './containers/DashboardEconomicSupplyService'
import { DashboardEconomicToolService } from './containers/DashboardEconomicToolService'
import DashboardManagementControlService from './containers/DashboardPBManagementControlService'
import DashboardManagementService from './containers/DashboardPBManagementService'
import DashboardOperativeService from './containers/DashboardPBOperativeService'
import DashboardPBFinancierService from './containers/DashboardPBFinancierService'
import { DashboardProfesionalService } from './containers/DashboardProfesionalService'
import { DashboardService } from './containers/DashboardService'
import DiabeticProgram from './components/DiabeticProgram/DiabeticProgram'
import DiabeticProgramForm from './components/DiabeticProgram/DiabeticProgramForm'
import DiabeticProgramFormView from './components/DiabeticProgram/DiabeticProgramFormView'
import EditPracticeTypes from './components/EditPracticeTypes'
import EmployeeComponents from './containers/NewEmployeeService'
import EmployeesService from './containers/EmployeesService'
import HospitalizationForm from './components/HospitalizationForm/HospitalizationForm'
import HospitalizationFormView from './components/HospitalizationForm/HospitalizationFormView'
import HospitalizationList from './components/HospitalizationForm/HospitalizationList'
import IntegralTechnicalFileService from './containers/IntegralTechnicalFileService'
import MedicalRecordService from './containers/MedicalRecordService'
import MedicalRecordSignVitalsService from './containers/MedicalRecordSignVitalsService'
import ModulesService from './containers/ModulesService'
import Navigator from './components/Navigator'
import NewScheduleService from './containers/NewScheduleService'
import PracticeTypes from './components/PracticeTypes'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ReassignAppointmentsStepperService } from './containers/ReassignAppointmentsStepperService'
import RoutesService from './containers/RoutesService'
import ScheduleDetail from './components/MedicalRecord/ScheduleDetail'
import SchedulesService from './containers/SchedulesService'
import SupplyCatalogComponent from './containers/NewSupplyCatalogService'
import SupplyCatalogService from './containers/SupplyCatalogService'
import TechnicalFileService from './containers/TechnicalFileService'
import ToolComponent from './containers/NewToolService'
import ToolTypesComponent from './containers/NewToolTypeService'
import ToolTypesService from './containers/ToolTypesService'
import ToolsService from './containers/ToolsService'
import UserComponent from './containers/NewUserService'
import UserService from './containers/UserService'
import UsersService from './containers/UsersService'
import Wellcome from './components/Wellcome'
import _ from 'lodash'
import { hot } from 'react-hot-loader'
import requireAuth from './handlers/authorizing-handler'

const Root = ({ store }) => (
  <Suspense fallback={null}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="/login" component={UserService.UserLogged} />
        <Route
          path="/confirm/:hash"
          component={UserComponent.ConfirmUser}
          onEnter={false}
        />

        <Route component={Navigator}>
          <Redirect exact from="/" to="/home" onEnter={requireAuth} />
          <Route path="/" component={UserService.UserLogged} />
          <Route path="/home" component={Wellcome} />
          <Route
            path="/dashboard"
            component={DashboardService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-professionals"
            component={DashboardProfesionalService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-economic-professionals"
            component={DashboardEconomicProfesionalService}
            onEnter={requireAuth}
          />

          <Route
            path="/dashboard-paciente"
            component={DashboardCustomerService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-economic-supplies"
            component={DashboardEconomicSupplyService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-economic-tools"
            component={DashboardEconomicToolService}
            onEnter={requireAuth}
          />
          <Route
            path="/solicitudes"
            exact
            component={AppointmentsComponents.AppointmentsService}
            onEnter={requireAuth}
          />

          <Route
            path="/solicitudes/:id"
            exact
            component={AppointmentsComponents.AppointmentDetailService}
            onEnter={requireAuth}
          />
          <Route
            path="/appointments/:id/edit/agenda"
            exact
            component={AppointmentsComponents.AppointmentDetailService}
            onEnter={requireAuth}
          />
          <Route
            path="/appointments/:id/renew/agenda"
            exact
            component={AppointmentsComponents.AppointmentDetailService}
            onEnter={requireAuth}
          />

          <Route
            path="/appointments/:id/cancel"
            exact
            component={AppointmentsComponents.AppointmentCancelService}
            onEnter={requireAuth}
          />
          <Route
            path="/reasign-appointments"
            exact
            component={ReassignAppointmentsStepperService}
            onEnter={requireAuth}
          />

          <Route
            path="/nueva-solicitud"
            component={NewScheduleService}
            onEnter={requireAuth}
          />
          <Route
            path="/herramientas-turno"
            component={AppointmentsToolsService.Form}
            onEnter={requireAuth}
          />
          <Route
            path="/hojas-ruta"
            component={RoutesService}
            onEnter={requireAuth}
          />
          <Route
            path="/alertas"
            component={AlertsService}
            onEnter={requireAuth}
          />
          <Route
            path="/modulos"
            component={ModulesService}
            onEnter={requireAuth}
          />
          <Route
            path="/patient"
            component={CustomersService}
            onEnter={requireAuth}
          />
          <Route
            path="/ver-profesional/:id"
            component={EmployeeComponents.EditEmployeeService}
            onEnter={requireAuth}
          />
          <Route
            path="/catalogo-insumos"
            component={SupplyCatalogService}
            onEnter={requireAuth}
          />
          <Route
            path="/profesionales"
            component={EmployeesService.EmployeesService}
            onEnter={requireAuth}
          />
          <Route path="/tools" component={ToolsService} onEnter={requireAuth} />
          <Route path="/users" component={UsersService} onEnter={requireAuth} />
          <Route
            path="/new-user"
            component={UserComponent.NewUser}
            onEnter={requireAuth}
          />

          <Route
            path="/nuevo-profesional"
            component={EmployeeComponents.NewEmployeeService}
            onEnter={requireAuth}
          />
          <Route
            path="/editar-profesional/:id"
            component={EmployeeComponents.EditEmployeeService}
            onEnter={requireAuth}
          />
          <Route
            path="/nuevo-paciente"
            component={CustomerComponents.NewCustomerService}
            onEnter={requireAuth}
          />
          <Route
            path="/nuevo-insumo"
            component={SupplyCatalogComponent.NewSupplyCatalogService}
            onEnter={requireAuth}
          />
          <Route
            path="/nuevo-producto"
            component={ToolComponent.NewToolService}
            onEnter={requireAuth}
          />
          <Route
            path="/editar-paciente/:id"
            component={CustomerComponents.EditCustomerService}
            onEnter={requireAuth}
          />
          <Route
            path="/ver-paciente/:id"
            component={CustomerComponents.EditCustomerService}
            onEnter={requireAuth}
          />
          <Route
            path="/tool-types"
            component={ToolTypesService}
            onEnter={requireAuth}
          />
          <Route
            path="/nuevo-tooltype"
            component={ToolTypesComponent.NewToolTypeService}
            onEnter={requireAuth}
          />
          <Route
            path="/editar-tooltype/:id"
            component={ToolTypesComponent.EditToolTypeService}
            onEnter={requireAuth}
          />
          <Route
            path="/editar-insumo/:id"
            component={SupplyCatalogComponent.EditSupplyCatalogService}
            onEnter={requireAuth}
          />
          <Route
            path="/editar-producto/:id"
            component={ToolComponent.EditToolService}
            onEnter={requireAuth}
          />
          <Route
            path="/turnos"
            component={BookingsService}
            onEnter={requireAuth}
          />
          <Route
            path="/historial/:id"
            component={SchedulesService}
            onEnter={requireAuth}
          />
          <Route
            path="/patient/:patientId/medical-record"
            component={MedicalRecordService}
            onEnter={requireAuth}
          />
          <Route
            path="/patient/:patientId/medical-record/:appointmentId/view"
            component={TechnicalFileService}
            onEnter={requireAuth}
          />
          <Route
            path="/patient/:patientId/medical-record/:appointmentId/edit"
            component={TechnicalFileService}
            onEnter={requireAuth}
          />
          <Route
            path="/patient/:patientId/medical-record/full"
            component={IntegralTechnicalFileService}
            onEnter={requireAuth}
          />
          <Route
            path="/patient/:patientId/medical-record/:appointmentId/detail/schedule/:scheduleId"
            component={ScheduleDetail}
            onEnter={requireAuth}
          />
          <Route
            path="/diabetic-program"
            component={DiabeticProgram}
            onEnter={requireAuth}
          />
          <Route
            path="/diabetic-program/form"
            component={DiabeticProgramForm}
            onEnter={requireAuth}
          />
          <Route
            path="/diabetic-program/edit/:id"
            component={DiabeticProgramForm}
            onEnter={requireAuth}
          />
          <Route
            path="/diabetic-program/view/:id"
            component={DiabeticProgramFormView}
            onEnter={requireAuth}
          />
          <Route
            path="/admission"
            component={HospitalizationList}
            onEnter={requireAuth}
          />
          <Route
            path="/admission/new-form"
            component={HospitalizationForm}
            onEnter={requireAuth}
          />
          <Route
            path="/admission/edit-form/:id"
            component={HospitalizationForm}
            onEnter={requireAuth}
          />
          <Route
            path="/admission/view/:id"
            component={HospitalizationFormView}
            onEnter={requireAuth}
          />

          <Route
            path="/practice-types"
            component={PracticeTypes}
            onEnter={requireAuth}
          />
          <Route
            path="/practice-types/:id/edit"
            component={EditPracticeTypes}
            onEnter={requireAuth}
          />
          <Route
            path="/patient/:patientId/medical-record/sign-vitals/:scheduleId"
            component={MedicalRecordSignVitalsService}
            onEnter={requireAuth}
          />

          <Route
            path="/dashboard-hospitalizations"
            component={DashboardOperativeService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-diabetic"
            component={DashboardManagementService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-management-control-pb"
            component={DashboardManagementControlService}
            onEnter={requireAuth}
          />
          <Route
            path="/dashboard-financier-pb"
            component={DashboardPBFinancierService}
            onEnter={requireAuth}
          />
        </Route>
      </Router>
    </Provider>
  </Suspense>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default hot(module)(Root)
