import { connect } from 'react-redux';
import Schedules from '../components/Schedules';
import { getSchedules, getStatus, getScheduleTypes } from '../actions/schedules';

const mapStateToProps = (state) => {
    return {
        schedules: state.schedules.schedules,
        status: state.schedules.status,
        types: state.schedules.types,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetSchedules: (schedules) => {
            dispatch(getSchedules(schedules))
        },
        onGetStatus: (status) => {
            dispatch(getStatus(status))
        },
        onGetTypes: (types) => {
            dispatch(getScheduleTypes(types))
        }
    }
}

const SchedulesService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedules);

export default SchedulesService;