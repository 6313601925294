import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function HeartWithPulse(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M16.5,3C13.605,3,12,5.09,12,5.09S10.395,3,7.5,3C4.462,3,2,5.462,2,8.5C2,9.003,2.075,9.505,2.203,10h3.464l2.579-1.934l2,3L11.667,10h1.611C13.624,9.405,14.262,9,15,9c1.105,0,2,0.895,2,2c0,1.105-0.895,2-2,2c-0.738,0-1.376-0.405-1.723-1h-0.944l-2.579,1.934l-2-3L6.333,12h-3.31c1.514,2.764,4.282,5.08,5.257,5.99C9.858,19.46,12,21.35,12,21.35s2.142-1.89,3.719-3.36C17.088,16.713,22,12.671,22,8.5C22,5.462,19.538,3,16.5,3z"/>
    </SvgIcon>
  );
}
