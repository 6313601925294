import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './Chat.css'

class Chat extends Component {
    constructor(props) {
        super(props)

        this.state = {
        
        }
    }

    render() {
        const { t, isVisible, login } = this.props;
        let url = "https://iot.webee.io/app/669057af1d5644001182e71b/3f4eae1f-ba34-4cd9-ac62-2bac0eadb656?h=0&d=0&c=1";
        if (login) {
            url = "https://iot.webee.io/app/669057af1d5644001182e71b/3f4eae1f-ba34-4cd9-ac62-2bac0eadb656?h=0&d=0&c=1";
        }
        return (
            <div style={{zIndex: 9999, position: 'fixed', display: isVisible ? 'block' : 'none',  border: '2px solid rgba(0, 0, 0, 0.05)', top: 15, right: 5, height: '85%', width: '25%' }} id="chat-container">
                <iframe width='100%' height='100%'
                    src={url} frameBorder='0' id="chat-iframe"></iframe>
            </div>
            )
    }
}


export default withTranslation()(Chat);
