import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

export default (customer) => {
	const {vfs} = vfsFonts.pdfMake;
	pdfMake.vfs = vfs;
    
    const qrContent = `{"personId":${customer.personId}}`;

    const documentDefinition = {
		pageSize: 'A4',
        pageOrientation: 'portrait',
        info: {
            title: `Paciente ${customer.firstName} ${customer.lastName}`,
            author: 'HMM',
            subject: 'Datos del paciente a verificar',
        },
		content: [
            {
                text: 'Ficha de Paciente',
                style: 'header'
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: -30, x2: 510, y2: -30, lineWidth: 1 }, //Bottom line
                ]
            },
            {
                qr: qrContent,
                fit: 250,
                eccLevel: 'L',
                style: 'qr'
            },
            {
                text: [
                    {text: 'Nombre: ', bold: true},
                    customer.firstName,
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Apellido: ', bold: true},
                    customer.lastName,
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Sexo: ', bold: true},
                     function (customer) {
                        let genderTypesMap = {
                            UNDEFINED: 'No definido',
                            MALE: 'Masculino',
                            FEMALE: 'Femenino'
                        };
                
                        return genderTypesMap[customer.sex];
                    } (customer),
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Nacionalidad: ', bold: true},
                    customer.nationality.name,
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Tipo de Documento: ', bold: true},
                    customer.idType.name,
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Número de Documento: ', bold: true},
                    customer.id,
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Fecha de Nacimiento: ', bold: true},
                    moment(customer.birthdate).format('DD/MM/YYYY'),
                ], style: 'text'
            },
            {
                text: [
                    {text: 'Correo Electrónico: ', bold: true},
                    customer.email,
                ], style: 'text'
            },
        ],
        styles: {
            header: {
                fontSize: 25,
                alignment: 'center',
                margin: [0, 20, 0, 50]
            },
            text: {
                fontSize: 14,
                marginTop: 15,
            },
            qr: {
                alignment: 'center',
                margin: [0, 50, 0, 50]
            },
            
        }
    };

    //pdfMake.createPdf(documentDefinition).download(`ficha-paciente-${customer.firstName}${customer.lastName}.pdf`);
    pdfMake.createPdf(documentDefinition).open({}, window);
}