import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Button from '../../../../CustomButtons/Button'
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import SelectInput from '../../../../SelectInput';
import DateInput from '../../../../DateInput';
import ValidationInput from '../../../../ValidationInput'
import ButtonSpinner from '../../../../ButtonSpinner';
import CustomSweetAlert from '../../../../CustomSweetAlert';
import SchedulePlan from '../../../../SchedulePlan';
import TimePickerGCalendar from '../../../../TimePickerGCalendar';
import ModalSuggestedProfessionals from './ModalSuggestedProfessionals';
import AppointmentApiInvoker from '../../../../../api/AppointmentApiInvoker';
import '../../../NewSchedule.css';

class Plan extends Component {
	constructor(props) {
		super(props)
		const { t } = props;

		this.state = {
			startTime: '06:00',
			endTime: '23:30',
			startDate: '',
			plan: '',
			plans: [],
			recurrence: {},
			savePlan: false,
			id: 0,
			companyPracticesType: [],
			estimatedDuration: '',
			professionals: [],
			originalDayLength: 0,
			openSuggestedProfessionals: false,
			busy: false,
			invalidPlan: false,
			datesErrorMSG: null,
			items_radio: [],
			selected_radio_service: 1,
			third_parties: [],
			lender: null,
			selector: [
				{ id: 1, value: t('appointment.new.schedule.lender.me'), component: true, },
				{ id: 2, value: t('appointment.new.schedule.lender.third'), component: false, }
			]
		}
	}

	componentDidMount() {
		this.getPartnership(null);
		const { appointmentAction, current, practiceTypes } = this.props;

		if (practiceTypes) {
			this.setState({ plans: this.formatPracticeTypes(practiceTypes) });
		} else {
			this.getPracticeTypes();
		}
		this.getCompanyPracticeTypes();

		if (appointmentAction === 'assign_agenda' || appointmentAction === 'edit' || appointmentAction === 'create' || appointmentAction === 'copy') {
			if (current && current.practiceTypeId) {
				let schedulep = current.item.schedulePlan;
				let originalDayLength = 0;
				if (schedulep) {
					if (schedulep.recurrenceType === 'WEEK' || schedulep.recurrenceType === 'INTRADAY') {
						if (Array.isArray(schedulep.weekDays)) {
							schedulep.weekRecurrence = schedulep.weekDays;
						} else {
							if (schedulep.weekDays) {
								let split = schedulep.weekDays.split(',');
								schedulep.weekRecurrence = split;
								if (split) {
									originalDayLength = split.length
								}
							} else {
								schedulep.weekRecurrence = [];
							}
						}
					}
					if (schedulep.monthDay) {
						schedulep.monthRecurrence = schedulep.monthDay
					}
				}

				this.setState({
					plan: current.practiceTypeId,
					startDate: current.item.startDate,
					startTime: current.item.startTime,
					endDate: current.item.endDate,
					lender: this.isJSON(current.item.companyIdPartnership) ? current.item.companyIdPartnership.companyId : current.item.companyIdPartnership,
					endTime: current.item.endTime,
					recurrence: schedulep,
					schedulePlan: schedulep,
					originalDayLength: originalDayLength,
					order: current.order,
					oldStartDate: current.item.startDate,
					itemRequestDetails: current.item.itemRequestDetails,
				}, () => {
					this.setPartnershipDefault();
					this.getPartnership(current.practiceTypeId);

				});
			}
		} else if (current && current.plan) {
			if (!current.specialty) {
				this.setState({
					id: current.id,
					plan: current.plan.id,
					startDate: current.startDate,
					endDate: current.endDate,
					startTime: current.startTime,
					endTime: current.endTime,
					recurrence: current.recurrence,
					order: current.order,
					itemRequestDetails: current.itemRequestDetails
				})
			} else {
				this.setState({
					id: current.plan.id,
					plan: current.plan.plan.id,
					startDate: current.plan.startDate,
					endDate: current.plan.endDate,
					startTime: current.plan.startTime,
					endTime: current.plan.endTime,
					recurrence: current.plan.recurrence,
					itemRequestDetails: current.itemRequestDetails,
				});
			}
		}
		this.fillSuggested();
	}

	isJSON(str) {
		if (str && str.companyId) 
			return true;
		return false;
	}
	
	componentWillReceiveProps(next) {
		if (next.practiceTypes) {
			this.setState({ plans: this.formatPracticeTypes(next.practiceTypes) })
		}
		if (next.professionals) {
			this.setState({ professionals: this.formatProfessionals(next.professionals) })
		}
		this.fillSuggested();
	}

	handleValue(value, state) {
		let endDate = this.state.endDate;
		let changedStartDate = this.state.changedStartDate;
		if (state === 'recurrence') {
			endDate = value.endDate;
		}
		if (state === 'startDate') {
			if (this.state.oldStartDate !== moment(value).format('YYYY-MM-DD')) {
				changedStartDate = true;
			} else {
				changedStartDate = false;
			}
		}
		if (state == 'plan') {
			this.getPartnership(value);
		}
		this.setState({
			[state]: value,
			endDate: endDate,
			changedStartDate: changedStartDate
		}, () => {
			this.fillSuggested();
		});
	}

	fillSuggested = () => {
		const { itemRequestDetails, recurrence, plan } = this.state;

		if (plan) {
			const items = itemRequestDetails ? itemRequestDetails : [];
			const result = [];
			const topv = recurrence && recurrence.recurrenceType === 'INTRADAY' && recurrence.quantityIntraday
				? recurrence.quantityIntraday : 1;
			for (let idx = 0; idx < topv; idx++) {
				if (idx >= items.length) {
					const row = {}
					row['packageDetailId'] = null;
					row['practiceTypeId'] = this.state.plan;
					row['suggestedEmployeeId'] = null;
					row['filerByZone'] = false;
					row['order'] = idx + 1;
					result.push(row);
				} else {
					result.push(items[idx]);
				}
			}
			this.setState({ itemRequestDetails: result });
		}
	}

	mapPlan(id) {
		let result = this.state.plans.find(p =>  p.id === id);
		return result;
	}

	isAnInvalidPlan = () => {
		const hasInvalidRecurrenceType = this.state.recurrence &&
			(!this.state.recurrence.recurrenceType ||
				!(this.state.recurrence.recurrenceType === 'DAY' || this.state.recurrence.recurrenceType === 'WEEK' || this.state.recurrence.recurrenceType === 'MONTH' || this.state.recurrence.recurrenceType === 'INTRADAY'));

		const hasInvalidWeek = (this.state.recurrence && this.state.recurrence.recurrenceType === 'WEEK' && this.state.recurrence.weekRecurrence && !this.state.recurrence.weekRecurrence.length);

		const hasInvalidMonth = (this.state.recurrence && this.state.recurrence.recurrenceType === 'MONTH' && (!this.state.recurrence.monthRecurrence || parseInt(this.state.recurrence.monthRecurrence) < 1 || parseInt(this.state.recurrence.monthRecurrence) > 31));

		const hastInvalidFinish = this.state.recurrence.finish ?
			(!this.state.recurrence.finish || (this.state.recurrence.finish && this.state.recurrence.finish === '2' && this.state.recurrence.finishOcurrences < 1)) : false;

		const hasInvalidFinishEnddate = this.state.recurrence.finish === '3' && (!this.state.recurrence.endDate && !this.state.endDate);

		//const hasInvalidIntraday = this.state.recurrence.recurrenceType === 'INTRADAY' && (this.state.recurrence.every < 1 || this.state.recurrence.every > 4); 
	

		if (this.state.recurrence.recurrenceType === 'INTRADAY') {
			const plan = this.mapPlan(this.state.plan);
			if (this.state.recurrence.quantityIntraday < 1 || (plan ? plan.estimatedDuration : 0) * this.state.recurrence.quantityIntraday > 1440) {
				return true;
			}
			if (!this.state.recurrence.weekRecurrence || this.state.recurrence.weekRecurrence === '') {
				return true;
			}
		}

		return (
			(this.state.recurrence && this.state.recurrence.every < 1) ||
			!this.state.startTime ||
			!this.state.endTime ||
			!this.state.startDate ||
			!this.state.plan ||
			!this.state.lender ||
			isEmpty(this.state.recurrence) ||
			hasInvalidRecurrenceType ||
			hasInvalidWeek ||
			hasInvalidMonth ||
			hastInvalidFinish ||
			hasInvalidFinishEnddate
		)
	}

	requestedPlan = () => {
		const result = [];

		if (Array.isArray(this.state.itemRequestDetails)) {
			return this.state.itemRequestDetails;
		}

		if (this.state.itemRequestDetails) {
			let va = this.state.itemRequestDetails;
			if (!va.practiceTypeId || va.practiceTypeId === '')
				va.practiceTypeId = this.state.plan;
			result.push(va);
		}
		return result;
	}

	formatRecurrence = (rec) => {
		const ret = rec;
		if (rec.recurrenceType === 'WEEK' && ret.weekRecurrence) {
			ret.weekDays = ret.weekRecurrence.toString();
			ret.weekRecurrence = null;
		}
		return ret;
	}

	checkDates = () => {
		const { t } = this.props;

		let appointmentStartDate = this.props.dates.startDate;
		let appointmentEndDate = this.props.dates.endDate;
		let itemStartDate = this.state.startDate;

		appointmentStartDate = moment(appointmentStartDate);
		appointmentEndDate = moment(appointmentEndDate);
		itemStartDate = moment(this.state.startDate);

		if (itemStartDate < appointmentStartDate || itemStartDate > appointmentEndDate) {
			this.setState({
				datesErrorMSG: t('newschedule.practice.validate.start.date.out.range')
			})
			return false;
		}
		if (this.state.endDate) {
			const itemEndDate = moment.utc(this.state.endDate);

			if (itemEndDate < itemStartDate) {
				this.setState({
					datesErrorMSG: t('newschedule.practice.validate.end.date.before.start.date')
				})
				return false;
			}
			if (itemEndDate < appointmentStartDate || itemEndDate > appointmentEndDate) {
				this.setState({
					datesErrorMSG: t('newschedule.practice.validate.end.date.out.range')
				})
				return false;
			}
		}
		return true;
	}

	savePlan(force) {
		this.setState({ busy: true });

		if (!force && (this.props.appointmentAction === 'assign_agenda')) {
			if (this.state.changedStartDate &&
				((this.state.recurrence.endType && this.state.recurrence.endType !== 'OCURRENCES') ||
					(this.state.recurrence.finishOcurrences != null && this.state.recurrence.finishOcurrences == ''))) {
				this.setState({ showAlertChangeDate: true, busy: false });
				return;
			}
		}

		if (this.props.appointmentAction === 'assign_agenda') {
			if (this.isAnInvalidPlan()) {
				return this.setState({ savePlan: true });
			}

			let error = false;

			// Validamos antes que todos los reg esten okey, porque sino el close se rompe.
			this.props.items.map(() => {
				if (this.state.recurrence.weekRecurrence) {
					if (this.state.recurrence.weekRecurrence.length !== this.state.originalDayLength) {
						error = true;
					}
				}
			});

			if (error) return;

			const items = this.props.items.map((item) => {
				if (item.practiceTypeId === this.props.current.item.practiceTypeId && item.order === this.props.current.order) {
					item.startTime = this.state.startTime;
					item.endTime = this.state.endTime;
					item.startDate = this.state.startDate;

					if (this.state.itemRequestDetails) {
						if (Array.isArray(this.state.itemRequestDetails)) {
							item.itemRequestDetails = this.state.itemRequestDetails;
						} else {
							item.itemRequestDetails = [this.state.itemRequestDetails];
						}
					} else {
						item.itemRequestDetails = [];
					}
					item.schedulePlan = this.state.schedulePlan;

					if (this.state.recurrence.weekRecurrence) {
						if (this.state.recurrence.weekRecurrence.length !== this.state.originalDayLength) {
							error = true;
						}
						item.schedulePlan.weekDays = this.state.recurrence.weekRecurrence.toString();
					}
					item.schedulePlan.processingScheduleType = 'SCHEDULE';
					if (item.schedulePlan.recurrenceType === 'INTRADAY') {
						item.schedulePlan.recurrenceType = 'WEEK';
						item.schedulePlan.processingScheduleType = 'INTRADAY';
						item.schedulePlan.every = 1;
					}
					if (this.state.recurrence.monthRecurrence) {
						item.schedulePlan.monthDay = this.state.recurrence.monthRecurrence;
					}
					return item;
				} else {
					item.schedulePlan.processingScheduleType = 'SCHEDULE';
					if (item.schedulePlan.recurrenceType === 'INTRADAY') {
						item.schedulePlan.recurrenceType = 'WEEK';
						item.schedulePlan.processingScheduleType = 'INTRADAY';
						item.schedulePlan.every = 1;
					}
					return item;
				}
			});
			if (!error) {
				this.props.onUpdatePlan(items);
			}

		} else if (this.props.appointmentAction === 'edit' || this.props.appointmentAction === 'copy') {

			//mlgmlgmlg
			if (!this.checkDates()) {
				this.setState({ busy: false })
				return;
			}
			if (this.isAnInvalidPlan()) {
				return this.setState({ savePlan: true, busy: true });
			}

			const items = this.props.items.filter((item) => {
				return item.appointmentItemRequestId === this.props.current.appointmentItemRequestId
			}).map((item) => {
				let itemRequestDetails = Array.isArray(this.state.itemRequestDetails) ? this.state.itemRequestDetails.map((i) => {
					i.practiceTypeId = this.state.plan;
					return i;
				}) : [this.state.itemRequestDetails];
				let weekRecurrence = '';
				if (this.state.recurrence.weekRecurrence) {
					weekRecurrence = this.state.recurrence.weekRecurrence.toString();
				}

				let endType = this.state.recurrence.endType;
				if (this.state.recurrence.finish) {
					endType = (this.state.recurrence.finish === '2' ? 'OCURRENCES' : this.state.recurrence.finish === '1' ? 'DATE' : null);
				}
				let endDate = null;
				if (this.state.recurrence.finish === '3') {
					endDate = this.state.recurrence.endDate;
					endType = 'DATE';
				}

				let estimatedDuration = 1;
				if (this.state.plan) {
					const plan = this.mapPlan(this.state.plan);
					estimatedDuration = plan.estimatedDuration;
				}

				let recurrenceType = this.state.recurrence.recurrenceType;
				let processingScheduleType = 'SCHEDULE';
				const processingStartTimes = null;
				let processingDurationTime = null;
				let every = this.state.recurrence.every;

				if (this.state.recurrence.recurrenceType === 'INTRADAY') {
					recurrenceType = 'WEEK';
					processingScheduleType = 'INTRADAY';
					processingDurationTime = estimatedDuration * this.state.recurrence.quantityIntraday;
					every = 1;
				}
				let lender = null;
				if (this.state.lender) {
					lender = { 'companyId': this.state.lender };
				}
				
				const newItem = {
					appointmentItemRequestId: item.appointmentItemRequestId,
					practiceTypeId: this.state.plan,
					startTime: this.state.startTime,
					endDate: endDate,
					endTime: this.state.endTime,
					startDate: this.state.startDate,
					order: this.state.order,
					companyIdPartnership: lender,
					itemRequestDetails,
					schedulePlan: {
						...this.state.schedulePlan,
						endType: endType,
						recurrenceType: recurrenceType,
						processingScheduleType: processingScheduleType,
						processingStartTimes: processingStartTimes,
						processingDurationTime: processingDurationTime,
						every: every,
						ocurrences: this.state.recurrence.ocurrences ? this.state.recurrence.ocurrences : this.state.recurrence.finishOcurrences,
						finishOcurrences: this.state.recurrence.finishOcurrences,
						weekDays: weekRecurrence,
						monthDay: this.state.recurrence.monthRecurrence,
					},
				}
				return newItem;
			});
			this.props.onUpdatePlan(items);

		} else if (!this.isAnInvalidPlan()) {
			let recurrence = this.state.recurrence;
			let estimatedDuration = 1;
			if (this.state.plan) {
				const planObj = this.mapPlan(this.state.plan);
				estimatedDuration = planObj.estimatedDuration;
			}
			if (this.state.recurrence.finish) {
				let endType = (this.state.recurrence.finish === '2' ? 'OCURRENCES' : this.state.recurrence.finish === '1' ? 'DATE' : null);
				if (recurrence.finish === '3') {
					endType = 'DATE';
				}
				recurrence.endType = endType;
			} else if (!this.state.recurrence.finish) {
				if (this.state.recurrence.ocurrences !== '') {
					recurrence.finish = '2';
				} else {
					recurrence.finish = '1';
				}
			}

			recurrence.processingScheduleType = 'SCHEDULE';
			if (recurrence.recurrenceType === 'INTRADAY') {
				recurrence.processingDurationTime = estimatedDuration * this.state.recurrence.quantityIntraday;
				recurrence.every = 1;
				recurrence.processingScheduleType = 'INTRADAY';
			}
			this.setState({
				savePlan: false,
				recurrence: recurrence,
			}, () => {
				let endDate = null;
				if (recurrence.finish === '3') {
					endDate = recurrence.endDate;
				}
				const plan = {
					id: this.state.id,
					plan: this.getPlan(this.state.plan),
					itemRequestDetails: this.requestedPlan(),
					startDate: this.state.startDate,
					endDate: endDate,
					companyIdPartnership: this.state.lender,
					startTime: this.state.startTime,
					endTime: this.state.endTime,
					recurrence: this.state.recurrence,
				}
				this.props.onSavedPlan(plan);
			})
		} else {
			this.setState({ savePlan: true, busy: true });
		}
	}

	getPracticeTypes() {
		this.props.onGetPracticeTypes();
	}

	formatPracticeTypes = (practiceTypes) => {
		const result = practiceTypes.map(pt => {
			return {
				id: pt.practiceTypeId,
				value: pt.name,
				estimatedDuration: pt.estimatedDuration,
			}
		})
		return result;
	}

	setPartnership = (data) => {
		const partnership = data.map(p => ({
			id: p.companyIdPartnership.companyId,
			value: p.companyIdPartnership.name,
		}));

		const id = parseInt(localStorage.getItem('itlg_default_company_id'));
		const value = localStorage.getItem('itlg_default_company_name');
		const partnershipArray = [{ id, value }, ...partnership];

		return partnershipArray;
	}

	getPartnership = (practiceTypeId) => {
		if (practiceTypeId == null) {
			return;
		}
		this.setState({third_parties: []});
		AppointmentApiInvoker.getCompanyPartnership(practiceTypeId, data => {
			const third_parties = this.setPartnership(data);
			const lender = !this.state.lender && third_parties.length ? third_parties[0].id : this.state.lender;
			this.setState({ third_parties, lender });
		}, (e) => {
			this.setState({ third_parties: [] });
		});
	}

	setPartnershipDefault = () => {
		const lender = !this.state.lender && this.state.third_parties.length ? this.state.third_parties[0].id : this.state.lender;
		this.setState({ lender });
	}

	getCompanyPracticeTypes = () => {
		AppointmentApiInvoker.getPracticeTypes(data => {
			this.setState({ companyPracticesType: this.formatPracticeTypes(data) });
		}, (e) => {
			this.setState({ companyPracticesType: [] });
		});
	}

	formatProfessionals = (professionals) => professionals.map(pt => ({
		id: pt.personId,
		value: `${pt.firstName} ${pt.lastName}`,
	}));

	getPlan = (id) => this.state.plans.find((p) => p.id === id);

	asignProfessionals = () => this.setState({ openSuggestedProfessionals: true });

	setInvalidPlan = (value) => this.setState({ invalidPlan: value });

	showButtonAssignProf = () => {
		const { appointmentAction } = this.props;
		const { lender, plan, recurrence } = this.state;
		const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
		let isMe = false;
		let companyPartnerShip = parseInt(localStorage.getItem('company_partnership_id'));
		if (companyPartnerShip == null) {
		companyPartnerShip = companyId;
		}
		if (lender === companyPartnerShip) {
		isMe = true;
		}
		return plan && recurrence.recurrenceType && ((lender === companyId) || (isMe && appointmentAction === 'assign_agenda'));
	}

	render() {
		//mlg3
		const { t, appointmentAction, customer, dates, items } = this.props;
		const { busy, companyPracticesType, datesErrorMSG, plan, plans, savePlan, invalidPlan, itemRequestDetails, lender, openSuggestedProfessionals, startDate, startTime, endTime, endDate, showAlertChangeDate, recurrence, third_parties } = this.state;
		const selectedPlan = plans.filter(f => (f.id === plan))[0];
		const selectedDuration = plans.filter(f => (f.id === plan))[0];
		const showAssignProf = this.showButtonAssignProf();
		
		return (
			<GridContainer className="form-practice-container">
				<GridItem xs={12} sm={6}>
					<GridItem xs={12}>
						<SelectInput
							id="plan"
							className="no-padding"
							disabled={appointmentAction === 'assign_agenda'}
							label={t("appointment.new.schedule.practice.practice")}
							elements={companyPracticesType}
							value={plan}
							onSelectedValue={(value) => this.handleValue(value, 'plan')}
							invalid={!plan && savePlan}
							errorText={!plan && savePlan ? t('error.required') : ''}
						/>
					</GridItem>

					<GridItem xs={12} className="no-padding">
						{(plan) &&
							<ValidationInput
								text={t('estimated_label')}
								value={`${moment.utc(moment.duration(parseInt(selectedDuration && selectedDuration.estimatedDuration), 'minutes').asMilliseconds()).format('HH:mm')}${t('estimated_minutes')}`}
								disabled
							/>
						}
					</GridItem>
					{appointmentAction !== 'assign_agenda' &&
						<GridItem xs={12}>
							<h4>{t('appointment.new.schedule.practice.lender')}</h4>
							<SelectInput
								id="lender"
								className="no-padding"
								label={t('appointment.new.schedule.practice.lender')}
								elements={third_parties}
								disabled={selectedPlan == undefined ? true : false}
								value={lender}
								onSelectedValue={(value) => this.handleValue(value, 'lender')}
							/>
						</GridItem>
					}
				</GridItem>

				<GridItem xs={12} sm={6}>
					<GridItem xs={12} className="base-font availability-label">
							<h4 className="padding-horizontal">{t('appointment.new.schedule.practice.availability')}</h4>
						</GridItem>
						<GridItem xs={12} className="date-input-grid date-size">
							<DateInput text={t('appointment.new.schedule.practice.start_date')} minDate min={dates ? dates.startDate : null} max={dates ? dates.endDate : null} value={startDate} onChangeValue={(value) => this.handleValue(value, 'startDate')} invalid={!startDate && savePlan} errorText={!startDate && savePlan ? t('error.required') : ''} />
						</GridItem>
						<GridContainer>
							<GridItem xs={12} md={6}>
								<TimePickerGCalendar name="practice_start_time" text={t('appointment.new.schedule.practice.start_time')} value={startTime} onSelectedValue={(value) => this.handleValue(value, 'startTime')} defaultValue="00:00" invalid={!startTime} errorText={!startTime ? t('error.required') : ''} />
							</GridItem>

							<GridItem xs={12} md={6}>
								<TimePickerGCalendar name="practice_end_time" text={t('appointment.new.schedule.practice.end_time')} value={endTime} onSelectedValue={(value) => this.handleValue(value, 'endTime')} defaultValue="23:30" invalid={!endTime} errorText={!endTime ? t('error.required') : ''} />
							</GridItem>
						</GridContainer>
					</GridItem>

					<GridItem xs={12} className="no-padding plan-content">
						<SchedulePlan
							className="schedule-plan-component"
							appointmentAction={appointmentAction}
							practiceDuration={selectedDuration && selectedDuration.estimatedDuration}
							current={recurrence}
							endDate={endDate}
							dates={dates}
							indexArrayDays={0}
							insideModule={false}
							onCreatedRecurrence={(value) => this.handleValue(value, 'recurrence')}
							savePlan={savePlan}
							invalidPlan={(value) => this.setInvalidPlan(value)}
						/>
					</GridItem>

					<GridItem className="schedule-form-submit plan" xs={12}>
						{showAssignProf &&
							<Button color="success" simple onClick={() => this.asignProfessionals()}>
								{t('suggested.assignProfessionals')}
							</Button>
						}
						<ButtonSpinner
							onClick={() => this.savePlan(false)}
							disabled={busy || this.isAnInvalidPlan() || invalidPlan}
							label={t('appointment.new.schedule.practice.save')}
							labelLoading={t('appointment.new.schedule.practice.saving')}
							loading={busy}
							typeButton="submit"
							color="success"
						/>
					</GridItem>
	
				<ModalSuggestedProfessionals
					open={openSuggestedProfessionals}
					appointmentAction={appointmentAction}
					items={items}
					customer={customer}
					lender={this.state.lender}
					recurrence={recurrence ? recurrence : []}
					onCloseModal={() => this.setState({ openSuggestedProfessionals: false })}
					onConfirmModal={(value) => this.setState({ itemRequestDetails: value, openSuggestedProfessionals: false })}
					practiceType={plan && selectedPlan}
					itemRequestDetails={itemRequestDetails}
					onChangeValue={(value, state, i) => this.handleValue(value, state, i)}
				/>

				{datesErrorMSG ?
					<CustomSweetAlert
						type="warning"
						title={t('appointment.edit.dates.error.title')}
						onConfirm={() => this.setState({ datesErrorMSG: null })}
						confirmBtnCssClass="btn success"
						confirmBtnText={t('common.accept')}
						showCancel={false}
						message={
							<div className="schedule-errors-list">
								<span className="schedule-errors-item">
									{datesErrorMSG}
								</span>
							</div>
						}
					/> : <div />
				}

				{showAlertChangeDate ?
					<CustomSweetAlert
						type="warning"
						title={t('appointment.edit.changequantityschedule.title')}
						onConfirm={() => this.savePlan(true)}
						confirmBtnCssClass="btn success"
						confirmBtnText={t('common.accept')}
						onCancel={() => this.setState({ showAlertChangeDate: false })}
						cancelBtnCssClass="btn danger"
						cancelBtnText={t('cancel')}
						showCancel={true}
						message={
							<div className="schedule-errors-list">
								<span className="schedule-errors-item">
									{t('appointment.edit.changequantityschedule.message')}
								</span>
								<span className="schedule-errors-item">
									{t('appointment.edit.changequantityschedule.question')}
								</span>
							</div>
						}
					/>
					: <div />
				}
			</GridContainer>
		)
	}
}

Plan.defaultProps = {
	dates: {},
	dayShifts: 0,
}

Plan.propTypes = {
	onSavedPlan: PropTypes.func,
	onFetchPracticeTypes: PropTypes.func,
	practiceTypes: PropTypes.array,
	dates: PropTypes.object,
	dayShifts: PropTypes.number,
}

export default withTranslation()(Plan);
