import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import RadioInput from '../../RadioInput';
import './AuthorizerForm.css';

const AuthorizerForm = ({ disabled, onFilledAuthorizer, t, value }) => {
  const inChargeOptions = [
    {
      id: '1',
      value: t('form.option.yes'),
      component: true,
    },
    {
      id: '2',
      value: t('form.option.no'),
      component: false,
    },
  ];

  let hasFamily = false;
  if(value && value.metadata && value.metadata.family) {
    const family = value.metadata.family;
    if (family.name || family.telephone || family.observations) hasFamily = true;
  }

  const [hasInCharge, setHasInCharge] = useState(hasFamily ? '1' : '2');

  const [auditor, setAuditor] = useState({
    name: '',
    licenseNumber: '',
    telephone: '',
  });

  const [requestor, setRequestor] = useState({
    name: '',
    licenseNumber: '',
    telephone: '',
    homeMedicalCenter: '',
  });

  const [family, setFamily] = useState({
    name: '',
    telephone: '',
    observations: '',
  });

  useEffect(() => {
    if (hasInCharge === '2') {
      setFamily({
        name: '',
        telephone: '',
        observations: '',
      });
      onFilledAuthorizer({
        requestor,
        family: {
          name: '',
          telephone: '',
          observations: '',
        },
        auditor,
      });
    }
  }, [hasInCharge]);

  useEffect(() => {
    if (value && value.metadata && value.metadata.family) {
      const family = value.metadata.family;
      if (family.name || family.telephone || family.observations) {
        setHasInCharge('1');
      } else {
        setHasInCharge('2');
      }
    }
  }, []);

  useEffect(() => {
    if (value && value.metadata && value.metadata.requestor) {
      const { requestor, auditor, family } = value.metadata || {};
      setRequestor({
        name: requestor.name,
        licenseNumber: requestor.licenseNumber,
        telephone: requestor.telephone,
        homeMedicalCenter: requestor.homeMedicalCenter,
      });
      setAuditor({
        name: auditor.name,
        licenseNumber: auditor.licenseNumber,
        telephone: auditor.telephone,
      });
      setFamily({
        name: family.name,
        telephone: family.telephone,
        observations: family.observations,
      });
      setHasInCharge(inChargeOptions[family.name !== '' ? 0 : 1].id);
    }
  }, [value]);

  const handleRequestorChange = (field, value) => {
    onFilledAuthorizer({
      requestor: {
        ...requestor,
        [field]: value,
      },
      family,
      auditor,
    });
  };

  const handleFamilyChange = (field, value) => {
    onFilledAuthorizer({
      family: {
        ...family,
        [field]: value,
      },
      requestor,
      auditor,
    });
  };

  const handleAuditorChange = (field, value) => {
    onFilledAuthorizer({
      auditor: {
        ...auditor,
        [field]: value,
      },
      requestor,
      family,
    });
  };

  return (
    <div className="authorizer-form">
        <GridContainer className="authorizer">
          <GridItem className="authorizer-medical-name" sm={4}>
            <CustomInput
              labelText={t('appointment.new.authorizer.doctor_name')}
              onChange={e => handleRequestorChange('name', e.target.value)}
              formControlProps={{
                fullWidth: true,
                disabled: disabled
              }}
              value={
                requestor && requestor.name
                  ? requestor.name
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              labelText={t('appointment.new.authorizer.medical_registration_number')}
              formControlProps={{
                fullWidth: true,
                disabled: disabled
              }}
              onChange={e => handleRequestorChange('licenseNumber', e.target.value)}
              value={
                requestor && requestor.licenseNumber
                  ? requestor.licenseNumber
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              labelText={t('appointment.new.authorizer.medical_phone_number')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleRequestorChange('telephone', e.target.value)}
              value={
                requestor && requestor.telephone
                  ? requestor.telephone
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={4}>
            <CustomInput
              labelText={t('appointment.new.authorizer.medical_center')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleRequestorChange('homeMedicalCenter', e.target.value)}
              value={
                requestor && requestor.homeMedicalCenter
                  ? requestor.homeMedicalCenter
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer className="authorizer">
          <GridItem className="authorizer-medical-name" sm={4}>
            <CustomInput
              labelText={t('appointment.new.authorizer.doctor_auditor_name')}
              onChange={e => handleAuditorChange('name', e.target.value)}
              formControlProps={{
                fullWidth: true,
              }}
              value={
                auditor && auditor.name
                  ? auditor.name
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              labelText={t('appointment.new.authorizer.medical_registration_number')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleAuditorChange('licenseNumber', e.target.value)}
              value={
                auditor && auditor.licenseNumber
                  ? auditor.licenseNumber
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              labelText={t('appointment.new.authorizer.medical_phone_number')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleAuditorChange('telephone', e.target.value)}
              value={
                auditor && auditor.telephone
                  ? auditor.telephone
                  : ''
              }
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem sm={4}/>
        </GridContainer>
        <GridContainer className="authorizer margin-top" justify="flex-start">
          <GridItem xs={12} sm={8} className="radio-input-inline">
            <p className="radio-legend">{t('technicalfile.hasFamily')}</p>
            <RadioInput
              value={hasInCharge}
              onChangeValue={value => setHasInCharge(value)}
              elements={inChargeOptions}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
        </GridContainer>

        {hasInCharge === '1' && (
          <GridContainer className="authorizer">
            <GridItem className="authorizer-contact-family-name" sm={6}>
              <CustomInput
                labelText={t('appointment.new.authorizer.contactFamilyName')}
                value={family && family.name ? family.name : ''}
                onChange={e => handleFamilyChange('name', e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: disabled || false,
                }}
              />
            </GridItem>
            <GridItem className="authorizer-contact-family-phone" sm={3}>
              <CustomInput
                labelText={t('appointment.new.authorizer.contactFamilyPhoneNumber')}
                onChange={e => handleFamilyChange('telephone', e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: disabled || false,
                }}
                value={family && family.telephone ? family.telephone : ''}
              />
            </GridItem>
            <GridItem className="authorizer-contact-family-phone" sm={3}>
              <CustomInput
                labelText={t('appointment.new.authorizer.observations')}
                onChange={e => handleFamilyChange('observations', e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: disabled || false,
                }}
                value={family && family.observations ? family.observations : ''}
              />
            </GridItem>
          </GridContainer>
        )}
    </div>
  )
}

AuthorizerForm.propTypes = {
  onFilledAuthorizer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    metadata: {
      requestor: {
        name: PropTypes.string,
        licenseNumber: PropTypes.number,
        telephone: PropTypes.number,
        homeMedicalCenter: PropTypes.string
      },
      auditor: {
        name: PropTypes.string,
        licenseNumber: PropTypes.number,
        telephone: PropTypes.number,
      },
      family: {
        name: PropTypes.string,
        telephone: PropTypes.number,
      },
    },
  }),
}

export default withTranslation()(AuthorizerForm);
