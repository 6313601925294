import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import GridItem from '../Grid/GridItem';
import customCheckboxRadioSwitch from '../../assets/components/customCheckboxRadioSwitch';
import './RadioInput.css';

const useStyles = makeStyles(customCheckboxRadioSwitch);

const RadioInput = ({ children, className, components, elements, inputProps, onChangeValue, value, title }) => {
  const classes = useStyles();

  const change = (e) => onChangeValue(e.target.value);

  return (
    <GridItem xs={12} className="radio-input">
      <FormControl component="fieldset">
        {title && (<FormLabel className="radio-input-title" component="legend" disabled={inputProps && inputProps.disabled}>{title}</FormLabel>)}
        <RadioGroup
          aria-label="radio"
          name="radio-group"
          value={value}
          className={className}
          onChange={(e) => change(e)}
        >
          {elements &&
            elements.map(e => {
              return (
                <div className="radio-input-group" key={`radio-option-${e.id}`}>
                  <FormControlLabel
                    value={e.id}
                    label={e.label || e.value}
                    classes={{ label: classes.label }}                                
                    control={
                      <Radio
                        disabled={(inputProps && inputProps.disabled) || (e.disabled && e.disabled)}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                          disabled: classes.disabledCheckboxAndRadio,
                        }}
                      />
                    }
                  />
                  {e.component && components ? components(e.id, inputProps && inputProps.disabled) : e.component && children}
                </div>
              )
            })
          }
        </RadioGroup>
      </FormControl>
    </GridItem>
  )
}

RadioInput.defaultProps = {
  children: null,
  components: () => {},
  inputProps: {},
  onChangeValue: () => {},
  value: '',
  title: '',
}

RadioInput.propTypes = {
  children: PropTypes.any,
  components: PropTypes.func,
  elements: PropTypes.array.isRequired,
  inputProps: PropTypes.object,
  onChangeValue: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
}

export default RadioInput;
