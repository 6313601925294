import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import ComponentDisabledWithTooltip from '../../ComponentDisabledWithTooltip';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import DateInput from '../../DateInput';
import SelectInput from '../../SelectInput';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import '../Dashboard.css';

class Filters extends Component {
    constructor(props) {
        super(props);
        this.sendFilters = this.sendFilters.bind(this);
        this.state = {
            professionals: [],
            employees: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            employeeTypesFilter: [],
            oemployeesType: [],
        }
    }

    componentDidMount() {
        if (this.props.employee && this.props.employee.length) {
            this.setState({
                employees: this.props.employees,
                professionals: this.formatEmployees(this.props.employees),
            });
        } else {
            this.getEmployees();
        }

        if (this.props.employeesType && this.props.employeesType.length) {
            this.setState({
                employeeType: this.props.employeesType,
                oemployeeType: this.formatEmployeeTypes(this.props.oemployeesType),
            });
        } else {
            this.getEmployeeTypes();
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees && next.employees.length) {
            this.setState({
                employees: next.employees,
                professionals: this.formatEmployees(next.employees),
            });
        }

        if (next.employeesType && next.employeesType.length) {
            this.setState({
                employeesType: next.employeesType,
                oemployeesType: this.formatEmployeeTypes(next.employeesType),
            });
        }
    }

    getEmployeeTypes() {
        const type = 'PROFESSIONAL';
        PersonApiInvoker.getAllEmployeesType(type, employees => {
            if (employees && employees.length) {
                this.props.onGetEmployeesType(employees);
            }
        }, e => {
            console.error(e);
        });
    }


    formatEmployeeTypes(employeeTypes) {
        let result = []
        if(employeeTypes){
            result = employeeTypes.map(e => {
                return {
                    id: e.employeeTypeId,
                    value: e.employeeTypeName,
                }
            });
        }
        return result;
    }

    formatEmployees(employees) {
        const result = employees.map(e => {
            return {
                id: e.personId,
                value: `${e.firstName} ${e.lastName}`
            }
        });
        return result;
    }

    getEmployees() {
        PersonApiInvoker.getEmployees(employees => {
            if (employees && employees.length) {
                this.props.onGetEmployees(employees);
            }
        }, e => {
            console.error(e);
        });
    }

    findProfessional = (personId) => this.state.professionals.find(p => p.personId === personId);

    handleValue = (value, state) => {
        this.setState({ [state]: value });
    }

    sendFilters() {
        if((this.state.professional && this.state.professional.length) || this.state.dateFrom || this.state.dateTo){
            this.props.onChange({
                professional: this.state.professional,
                employeeTypesFilter: this.state.employeeTypesFilter,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
            });
        }
    }

    cleanFilters() {
        this.setState({
            professional: [],
            dateFrom: '',
            dateTo: '',
            employeeTypesFilter: [],
        }, () => {
            this.props.onClearFilters();
        });
    }

    render() {
        const { t, loading } = this.props;
        const { dateFrom, dateTo, employeeTypesFilter, oemployeesType, professional, professionals } = this.state;
        const disabledButton = !(dateFrom && dateTo && professional.length);

        return (
            <GridContainer className="filters" justify="center">
                <GridItem xs={12} sm={3} md={2} className="date-filter date-nopadding">
                    <DateInput
                        text={t('dashboard.filters.from')}
                        value={dateFrom}
                        onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={2} className="date-filter date-nopadding">
                    <DateInput
                        text={t('dashboard.filters.to')}
                        minDate
                        min={dateFrom}
                        value={dateTo}
                        onChangeValue={(value) => this.handleValue(value, 'dateTo')}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}></GridItem>
                <GridItem className="filters-actions" xs={12} sm={2}>
                    <ComponentDisabledWithTooltip
                        tooltipText={t('dashboardProfessional.buttonTooltip')}
                        disabled={disabledButton}
                        loading={loading}
                        component={
                            <ButtonSpinner
                                block
                                label={t('dashboard.filters.search')}
                                labelLoading={t('dashboard.filters.searching')}
                                loading={loading}
                                disabled={disabledButton || loading}
                                onClick={() => this.sendFilters()}
                                color="success"
                            />
                        }
                    />
                    <Button
                        block
                        onClick={() => this.cleanFilters()}
                        color="danger"
                    >{t('dashboard.filters.clean')}</Button>
                </GridItem>
                <GridItem className="professionals-filter" xs={6}>
                    <SelectInput 
                        label={`${t('dashboard.filters.professionals')} *`}
                        chips
                        multi
                        elements={professionals}
                        value={professional}
                        onSelectedValue={(value) => this.handleValue(value, 'professional')}
                    />
                </GridItem>
                <GridItem className="professionals-filter" xs={6}>
                    <SelectInput
                        label={t('dashboard.filters.employeeTypesFilter')}
                        chips multi
                        elements={oemployeesType}
                        value={employeeTypesFilter}
                        onSelectedValue={(value) => this.handleValue(value, 'employeeTypesFilter')}
                    />
                </GridItem>
        </GridContainer>
        )
    }
}

Filters.defaultProps = {
    loading: false,
}

Filters.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onGetEmployeesType: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    loading: PropTypes.bool.isRequired,
}

export default withTranslation()(Filters);
