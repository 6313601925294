import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import useStateRef from 'react-usestateref';
import { Checkbox } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import CustomDialog from '../CustomDialog';
import Table from '../Table/Table';

const EmployeeTypesDialogSelector = ({ t, data, loading, open, onAccept, onCancel, selectedData }) => {

  const [checkAll, setCheckAll] = useState(false);
  const [employeeTypes, setEmployeeTypes] = useState();
  const [selectedEmployeeTypes, setSelectedEmployeeTypes, refSelectedEmployeeTypes] = useStateRef(selectedData);

  const mapEmployeeTypes = (selectedEmpTypes) => data.map((a) => {
    const praticeType = a.practiceType && a.practiceType.length ? a.practiceType.map((p) => p.name).join(', ') : '';
    const practiceTypeGroup = a.practiceTypeGroup && a.practiceTypeGroup.length ? a.practiceTypeGroup.map((p) => p.name).join(', ') : '';
    let isChecked = false;
    if (selectedEmpTypes.filter(s => s === a.employeeTypeId).length > 0) {
      isChecked = true;
    }
    const checkbox = <Checkbox
      tabIndex={-1}
      onChange={() => handleSelectedData(a.employeeTypeId)}
      checked={isChecked}
      checkedIcon={<Check className="checked-icon" />}
      icon={<Check className="unchecked-icon" />}
    />;

    return {
      ...a,
      checkbox,
      employeeTypeName: a.employeeTypeName,
      practiceTypeGroup,
      praticeType,
    }
  });

  const formatData = (dataSelected) => {
    setSelectedEmployeeTypes(dataSelected);
    const result = mapEmployeeTypes(dataSelected);
    return setEmployeeTypes(result);
  }

  const handleSelectedData = (id) => {
    setCheckAll(false);
    const typesSelected = refSelectedEmployeeTypes.current;
    if (typesSelected.includes(id)) {
      const index = typesSelected.indexOf(id);
      if (index !== -1) {
        typesSelected.splice(index, 1);
      }
    } else {
      typesSelected.push(id);
    }
    return formatData(typesSelected);
  }

  const handleSelectedAllData = (event) => {
    setCheckAll(event.target.checked);
    let selected = [];
    if (event.target.checked) {
      selected = data.map(p => p.employeeTypeId);
    }
    return formatData(selected);
  }

  useEffect(() => {
    formatData(selectedData);
    setCheckAll(data.length === selectedData.length ? true : false);
    return () => {
     setEmployeeTypes([]);
      setSelectedEmployeeTypes([]);
      setCheckAll(false);
    }
  }, []);

  const actions =
    <Button color="success" onClick={() => onAccept(selectedEmployeeTypes)}>
      {t('appointment.new.accept')}
    </Button>

  return (
    <CustomDialog
      fullWidth
      title={t('employee.specialities.practices')}
      open={open}
      onClose={() => onCancel()}
      actions={actions}
    >
      <Table
        loading={loading}
        tableHeaderColor="primary"
        defaultPageSize={data.length <= 10 ? 10 : data.length}
        tableHead={[
          {
            Header: 
              <div className="check-actions">
                <Checkbox
                  tabIndex={-1}
                  id="allTypes"
                  checked={checkAll}
                  onClick={(e) => handleSelectedAllData(e)}
                />
              </div>,
            accessor: 'checkbox', sortable: false, filterable: false, width: 60,
          },
          { Header: t('employees.practiceTypeGroup'), accessor: 'employeeTypeName', filterable: true, sortable: false },
          { Header: t('employees.praticeType'), accessor: 'praticeType', filterable: true, sortable: false },
        ]}
        sortable={false}
        tableData={employeeTypes}
        colorsColls={['primary']}
        showPaginationBottom={false}
      />
    </CustomDialog>
  )
}

export default withTranslation()(EmployeeTypesDialogSelector);
