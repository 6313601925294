import { Component } from 'react'
import { browserHistory } from 'react-router';

export default class BaseInvoker extends Component {

	get(url, callback, callerror) {
		this.invoke(url, 'GET', null, callback, callerror, true);
	}

	getWithoutSecurityHeaders(url, callback, callerror) {
		this.invoke(url, 'GET', null, callback, callerror, false);
	}

	post(url, body, callback, callerror) {
		this.invoke(url, 'POST', body, callback, callerror, true);
	}

	put(url, body, callback, callerror) {
		this.invoke(url, 'PUT', body, callback, callerror, true);
	}

	delete(url, callback, callerror) {
		this.invoke(url, 'DELETE', null, callback, callerror, true);
	}
	delete(url, body, callback, callerror) {
		this.invoke(url, 'DELETE', body, callback, callerror, true);
	}

	patch(url, body, callback, callerror) {
		this.invoke(url, 'PATCH', body, callback, callerror, true);
	}

	option(url, body, callback, callerror) {
		this.invoke(url, 'OPTION', body, callback, callerror, true);
	}

	invoke(url, type, body, callback, callerror, secureHeaders) {

		let params = {
			method: type
		}
		if (body) {
			params.body = JSON.stringify(body);
		}

		params.headers = {'Content-Type': 'application/json'};
		fetch(url, params)
			.then((response) => {
				if(!localStorage.getItem('user_id')) {					
					return;
				}
				if(response.redirected)
				{
					browserHistory.push('/');
					return;
				}
				if (response.ok) {
					return response.text().then(function (text) {
						return text ? JSON.parse(text) : {}
					})
				} else {
					// BAD REQUEST
					if (response.status === 404 || response.status === 400 || response.status === 500) {
						response.text().then((text) => {
							try {
								const t = JSON.parse(text);
								if (callerror && t && t.hasOwnProperty('message')) {
									callerror(new Error(t.message));
								} else if (callerror && t && t.hasOwnProperty('messages')) {
									callerror(new Error(t.messages.toString()));
								}
							} catch (error) {
								if (callerror) {
									const err =  { message: text, status: response.status, statusText: response.statusText }
									callerror(err);
								}
							}
						})
					// NOT AUTHORIZED
					} else if (response.status === 401) {
						this.redirectLogin();						
						return null;
					} else if (response.status === 504) {
						callerror(new Error('TIME_OUT_EXCEPTION'));
					}
				}
			})
			.then(data => {	
				if(!data) return;	
				
				if(data && !data.message){
					if (callback) {
						callback(data);
					}
				} else {
					if (callerror) {
						callerror(data);
					}	
				}				
			})
			.catch(e => {	
				console.error('** Error', e);					

				if (callerror) {
					callerror(e);
				}
			})
	}

	redirectLogin() {
		localStorage.removeItem('user_id');
		browserHistory.push('/login');
	}

}
