import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import * as _ from 'lodash';
import moment from 'moment';
import { debounce, Grid, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Copy from '@material-ui/icons/FileCopy';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import Button from '../CustomButtons/Button';
import CustomDialog from '../CustomDialog/index';
import CustomInput from '../CustomInput/CustomInput';
import CustomSweetAlert from '../CustomSweetAlert';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';
import Table from '../Table/Table';
import {
  EventAcceptedTentatively, Assignment as AssignmentIcon, HealthCalendar, DeleteDocument, EventDeclined, ViewList as ViewListIcon,
} from '../../icons';
import ApiInvoker from '../../api/ApiInvoker';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import ItemsRequest from './ItemsRequest';
import './Appointments.css';

const dateFormat = 'DD/MM/YYYY';
const dateFormatServer = 'YYYY-MM-DD';

class Appointments extends Component {
  constructor(props) {
    super(props);

    const { t } = props;
    const proximityList = [
      { id: -1, value: '' },
      { id: 1, value: t('appointment.table.filters.red') },
      { id: 2, value: t('appointment.table.filters.yellow') },
      { id: 3, value: t('appointment.table.filters.green') },
    ];
    const scheduleStatusList = [
      { id: -1, value: '' },
      { id: 'PENDING_APPROVAL', value: t('scheduleDetail.state.PENDING_APPROVAL') },
      { id: 'REJECTED', value: t('scheduleDetail.state.REJECTED') },
      { id: 'APPROVED', value: t('scheduleDetail.state.APPROVED') },
      { id: 'IN_PROGRESS', value: t('scheduleDetail.state.IN_PROGRESS') },
      { id: 'DONE', value: t('scheduleDetail.state.DONE') },
      { id: 'NOT_ATTENDED', value: t('scheduleDetail.state.NOT_ATTENDED') },
      { id: 'WITHOUT_ATTENTION', value: t('scheduleDetail.state.WITHOUT_ATTENTION') },
      { id: 'CANCELLED', value: t('scheduleDetail.state.CANCELLED') },
      { id: 'FINISHED_SYSTEM', value: t('scheduleDetail.state.FINISHED_SYSTEM') }
    ];
    const appointmentStatusList = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'CANCELLED', value: t('appointment.table.status.CANCELLED') },
      { id: 'ENDED', value: t('appointment.table.status.ENDED') },
      { id: 'OVERDUE', value: t('appointment.table.status.OVERDUE') }
    ];
    const filterColors = [
      { id: 1, value: t('appointment.table.filters.red') },
      { id: 2, value: t('appointment.table.filters.yellow') },
      { id: 3, value: t('appointment.table.filters.green') },
      { id: 4, value: t('appointment.table.filters.rejected') },
    ];

    this.state = {
      appointments: [],
      itemsRequest: [],
      showItemRequest: false,
      loading: false,
      loadingSearch: false,
      pageSize: 25,
      scheduleStatusList,
      appointmentStatusList,
      proximityList,
      filterModules: '',
      filterProfessionals: '',
      filterStatus: '',
      id: '',
      appointmentId: '',
      filterTools: '',
      filterColors,
      dateFrom: '',
      filter: {},
      dateTo: '',
      currentFilterColor: [],
      company_partnership_id: null,
      color: 'true',
      checkedInput: false,
      currentUserId: localStorage.getItem('user_id'),
      dialogCopyApointment: false,
      busy: false,
      chips: [],
      page: 0,
      originalquery: [],
      showFilters: false,
      chipsFilters: [],
      chips_original: [],
      filterShow: {},
      customersList: [],
      selectedAppointment: {},
      changeRequests: false,
    }
    this.myRefStartDate = React.createRef();
    this.myRefEndDate = React.createRef();
  }

  componentWillMount() {
    const company_partnership_id = localStorage.getItem('company_partnership_id');
    this.setState({ company_partnership_id });
    this.getAppointments();
    this.getPracticeTypes();
    this.getModuleTypes();
    this.getProvincies();
  }

  getPracticeTypes() {
    AppointmentApiInvoker.getGenericPracticeTypes(data => {
      this.setState({ practiceTypes: data });
    }, null);
  }

  getModuleTypes() {
    AppointmentApiInvoker.getPackagesAll(data => {
      if (data && data.length) {
        this.setState({ package: data });
      }
    }, null);
  }

  componentWillReceiveProps(next) {
    if (next.appointments) {
      this.reduceAppointments(next.appointments);
      if (this.state.page > 0) {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.onGetAppointments([]);
  }

  getAcceptRejectItems = (appointmentId) => {
    const selectedAppointment = this.state.appointments.find((a) => a.appointmentId === appointmentId);
    this.setState({
      appointmentId,
      selectedAppointment,
      showItemRequest: true,
      showConfirmAcceptItemRequest: false,
      showConfirmRejectItemRequest: false,
    });
  }

  getAppointments = () => {
    const { appointments, filter, page, pageSize } = this.state;
    this.setState({ loading: true });
    const self = this;
    let params = Object.keys(filter).map((k) => {
      let value = self.state.filter[k]
      if (value !== '') {
        if (k === 'startDate' || k === 'endDate') {
          const endDate = moment(value);
          value = endDate.format('yyyy-MM-DD');
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
      } else {
        return null;
      }
    }).join('&')
    if (params !== '') {
      params = `${params}&`;
    }
    params = `${params}page=${page}&pageSize=${pageSize}`;

    AppointmentApiInvoker.getAppointmentsFilter(params, data => {
      this.setState({ loading: false, loadingSearch: false });
      if (data) {
        const apposList = appointments.length > 0 ? [...appointments, ...data] : data;
        apposList.sort((a, b) => (a.appointmentId < b.appointmentId && 1) || -1)
        this.props.onGetAppointments(apposList);
      } else {
        this.props.onGetAppointments([]);
      }
    }, err => {
      console.error('** error getAppointmentsFilter', err);
      this.setState({ loading: false, loadingSearch: false });
    });
  }

  formatProvincies = (provincies) => provincies.map(e => ({
    id: e.provinceId,
    value: e.name,
  }));

  getProvincies = () => {
    ApiInvoker.getAllProvinces(prov => {
      const provinces = this.formatProvincies(prov);
      this.setState({ provinces });
    }, err => console.error('** error getAllProvinces', err));
  }

  endDateFormater(endDate, hasSchedulesRejected, hasSchedulesPending, appointmentStatus) {
    const { t } = this.props;
    const now = moment();
    const endDateF = moment(endDate);
    let daysLeft = -1;
    if (appointmentStatus === 'SCHEDULES_ASSIGNED' || appointmentStatus === 'CREATED') {
      daysLeft = Math.ceil(endDateF.diff(now, 'days', true));
    }
    let rejected = '';
    let schedulesPending = '';

    if (hasSchedulesRejected) {
      rejected = <Tooltip className="toolTipEdit" title={t('appointment.table.rejected')}>
        <span><EventDeclined className="svgdanger svgIcon" /></span>
      </Tooltip>;
    }
    if (hasSchedulesPending) {
      schedulesPending = <Tooltip className="toolTipEdit" title={t('appointment.table.pending')}>
        <span><EventAcceptedTentatively className="svgyellow svgIcon" /></span>
      </Tooltip>;
    }
    if (daysLeft >= 11 && daysLeft <= 15) {
      return (
        <>
          <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')} `}>
            <span><AssignmentIcon className="svgsuccess svgIcon" fontSize="large" /></span>
          </Tooltip>
          {rejected}
          {schedulesPending}
        </>
      );
    } else if (daysLeft >= 6 && daysLeft <= 10) {
      return (
        <>
          <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')} `}>
            <span><AssignmentIcon className="svgyellow svgIcon" /></span>
          </Tooltip>
          {rejected}
          {schedulesPending}
        </>
      );
    } else if (daysLeft <= 5 && daysLeft > 0) {
      return (
        <>
          <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${(daysLeft === 1) ? t('appointment.table.day') : t('appointment.table.days')} `}>
            <span><AssignmentIcon className="svgdanger svgIcon" /></span>
          </Tooltip>
          {rejected}
          {schedulesPending}
        </>
      );
    } else if (daysLeft === 0) {
      return (
        <>
          <Tooltip className="toolTipEdit" title={t('appointment.table.remaining.today')}>
            <span><AssignmentIcon className="svgdanger svgIcon" /></span>
          </Tooltip>
          {rejected}
          {schedulesPending}
        </>
      );
    } else if (appointmentStatus === 'SCHEDULES_ASSIGNED' && daysLeft < 0) {
      return (
        <>
          <Tooltip className="toolTipEdit" title={t('appointment.table.remaining.end')}>
            <span><AssignmentIcon className="svgdefault svgIcon" /></span>
          </Tooltip>
          {rejected}
          {schedulesPending}
        </>
      );
    } else return <>{rejected}{schedulesPending}</>;
  }

  appDataFormater = (a) => {
    const { t } = this.props;
    const appointmentDateState = this.endDateFormater(a.endDate, a.hasSchedulesRejected, a.hasSchedulesPending, a.appointmentStatus);
    return {
      employee: {
        personId: a.responsableEmployee && a.responsableEmployee.personId,
      },
      appointmentId: a.appointmentId,
      affiliateId: a.affiliateId,
      financierName: a.companyFinancier,
      appointmentDateState,
      createdDateTime: a.createdDateTime,
      date: moment(a.createdDateTime).format(dateFormat),
      name: `${a.customerFirstName} ${a.customerLastName}`,
      customerId: a.customerId,
      startDate: moment(a.startDate).format(dateFormat),
      endDate: moment(a.endDate).format(dateFormat),
      appStatus: t(`appointment.table.status.${a.appointmentStatus}`) || t('appointment.table.status.DEFAULT'),
      hasSchedulesRejected: a.hasSchedulesRejected,
      hasItemsPending: a.hasItemsPending,
      hasItemsConfirmed: a.hasItemsConfirmed,
      locationName: a.locationName,
      provinceName: a.provinceName,
      actions: this.renderActionComponent(a),
      compareEndDate: moment(a.endDate),
      createdDateCompare: moment(a.createdDateTime).format(dateFormatServer),
    }
  }

  reduceAppointments = (appointments) => {
    if (appointments.length) {
      const data = appointments.map((a) => this.appDataFormater(a));
      const oldAppointments = this.state.appointments;
      oldAppointments.push(...data);
      const uniques = oldAppointments.filter((v, i, a) => a.findIndex(t => (t.appointmentId === v.appointmentId)) === i);
      this.setState({ appointments: uniques, originalquery: uniques });
    }
  }

  openCancelAppointment = (appointmentId) => {
    this.setState({ selectedCancelAppointment: appointmentId },
      () => {
        this.setState({ showConfirmCancelAppointment: true });
      }
    );
  }

  cancelAppointmentAction = () => {
    const body = {
      "reason": "Cancell Created appointment",
      "appointmentDetailIds": [],
    }
    AppointmentApiInvoker.cancelAppointment(this.state.selectedCancelAppointment, body, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    }, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    });
  }

  renderActionComponent = (a) => {
    const { onSelectAppointmentList, t } = this.props;
    return (
      <div className="appointments-actions">
        <Tooltip title={t('appointment.viewAppointment')}>
          <span>
            <Button
              simple
              justIcon
              color="info"
              authority="appointment_view,appointment_view_related"
              onClick={() => {
                onSelectAppointmentList(a.appointmentId);
                browserHistory.push({
                  state: { mode: 'view' },
                  pathname: `/solicitudes/${a.appointmentId}`
                });
              }}
            >
              <Assignment />
            </Button>
          </span>
        </Tooltip>
        {(a.appointmentStatus === 'SCHEDULES_ASSIGNED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') &&
          <Tooltip title={t('appointment.editCalendar')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="appointment_edit_agenda"
                onClick={() => {
                  onSelectAppointmentList(a.appointmentId);
                  browserHistory.push({
                    state: { mode: 'edit', authority: 'appointment_edit_agenda' },
                    pathname: `/appointments/${a.appointmentId}/edit/agenda`
                  });
                }}
              >
                <HealthCalendar />
              </Button>
            </span>
          </Tooltip>
        }

        {(a.appointmentStatus === 'CREATED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') &&
          a.hasItemsPending === true &&
          <Tooltip title={t('appointment.actions.acceptReject')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                onClick={() => this.getAcceptRejectItems(a.appointmentId)}
              >
                <span className="icon-with-overlay">
                  <ViewListIcon className="principal-icon" />
                  <DoneIcon className="overlay-icon" />
                </span>
              </Button>
            </span>
          </Tooltip>
        }

        {this.state.company_partnership_id == null && a.appointmentStatus !== 'CREATED' && a.appointmentStatus !== 'CANCELLED' && a.appointmentStatus !== 'ENDED'
          && a.appointmentStatus !== 'OVERDUE' &&
          <Tooltip title={t('appointment.actions.edit')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="appointment_edit"
                onClick={() => {
                  onSelectAppointmentList(a.appointmentId);
                  browserHistory.push({
                    state: { mode: 'edit' },
                    pathname: `/solicitudes/${a.appointmentId}`
                  });
                }}
              >
                <Edit />
              </Button>
            </span>
          </Tooltip>
        }
        {this.state.company_partnership_id == null && a.appointmentStatus === 'CREATED' && (
          <>
            <Tooltip title={t('appointment.actions.edit')}>
              <span>
                <Button
                  simple
                  justIcon
                  color="success"
                  authority="appointment_edit"
                  onClick={() => {
                    onSelectAppointmentList(a.appointmentId);
                    browserHistory.push({
                      state: { mode: 'edit' },
                      pathname: `/appointments/${a.appointmentId}/edit/agenda`,
                    });
                  }}
                >
                  <Edit />
                </Button>
              </span>
            </Tooltip>
            {this.state.company_partnership_id == null &&
              <Tooltip title={t('appointment.actions.cancel')}>
                <span>
                  <Button
                    simple
                    justIcon
                    color="danger"
                    authority="appointment_cancel"
                    onClick={() => this.openCancelAppointment(a.appointmentId)}
                  >
                    <DeleteDocument className="svg-cancel-agenda" />
                  </Button>
                </span>
              </Tooltip>
            }
          </>
        )}
        {(a.appointmentStatus === 'CREATED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') && 
          a.hasItemsConfirmed == true && 
           <Tooltip title={t('appointment.actions.assignAgenda')}>
           <span>
             <Button
               simple
               justIcon
               color="primary"
               authority="appointment_assign"
               onClick={() => {
                 this.props.onSelectAppointmentList(a.appointmentId)
                 browserHistory.push({
                   state: { mode: 'assign_agenda' },
                   pathname: `/appointments/${a.appointmentId}/edit/agenda`,
                 })
               }}
             >
               <HealthCalendar className="svg-assign-agenda" />
             </Button>
           </span>
         </Tooltip>
        }
        {a.appointmentStatus !== 'CANCELLED' && a.appointmentStatus !== 'CREATED' &&
          a.appointmentStatus !== 'OVERDUE' && a.appointmentStatus !== 'ENDED' && this.state.company_partnership_id == null &&
          <Tooltip title={t('appointment.actions.cancel')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="appointment_cancel"
                onClick={() => {
                  browserHistory.push({
                    pathname: `/appointments/${a.appointmentId}/cancel`,
                  });
                }}
              >
                <DeleteDocument className="svg-cancel-agenda" />
              </Button>
            </span>
          </Tooltip>
        }
        {(a.appointmentStatus === 'SCHEDULES_ASSIGNED' || a.appointmentStatus === 'OVERDUE') &&
        this.state.company_partnership_id == null &&
          <Tooltip title={t('schedule.finished.title')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="end_schedule_by_system"
                onClick={() => {
                  browserHistory.push({
                    state: { mode: 'view', action: "finished_system" },
                    pathname: `/solicitudes/${a.appointmentId}`,
                  });
                }}
              >
                <EventAvailableIcon className="svg-cancel-agenda" />
              </Button>
            </span>
          </Tooltip>
        }
      </div>
    )
  }

  formatCustomers = (customers) => customers.map(e => ({
    id: e.personId,
    value: `${e.firstName} ${e.lastName}`,
  }));

  getCustomerstAPI = (text) => PersonApiInvoker.getCustomersOptions(text, data => {
    const customersList = this.formatCustomers(data);
    this.setState({ customersList });
  }, err => console.error('** error getCustomersOptions', err));

  getCustomersAPIDebounced = (value) => debounce(this.getCustomerstAPI(value), 500);

  handleCustomerOptions(event) {
    const value = (event && event.target && event.target.value);
    const isValue = (event && event.target) && (value && value.length >= 3);
    if (!!(isValue)) {
      this.setState({ customersList: [] });
      this.getCustomersAPIDebounced(value);
    }
    const isInvalidValueAndCustomers = event && event.target && event.target.value && event.target.value.length < 3 && this.state.customersList.length > 0;
    if (isInvalidValueAndCustomers) {
      this.setState({ customersList: [] });
    }
  }

  handleValue(value, state) {
    const { chipsFilters, filter, filterShow } = this.state;
    const chips = chipsFilters;
    filterShow[state] = value;
    filter[state] = value;
    this.setState({
      filter,
      chipsFilters: chips,
      filterShow,
    }, () => { });

    if (state === 'customerId' && !value) {
      this.setState({ customersList: [] });
    }
  }

  addAppointment = () => browserHistory.push('/nueva-solicitud');

  sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if (a1 < b1)
      return -1;
    else if (a1 > b1)
      return 1;
    else
      return 0;
  }

  toggleCopy = () => this.setState({
    dialogCopyApointment: !this.state.dialogCopyApointment,
    busy: false,
  });

  onChangeItemsRequest = (changeRequests) => this.setState({ changeRequests });

  toggleItemRequest = () => {
    this.setState({ showItemRequest: false });
    if (this.state.changeRequests) {
      this.setState({
        loading: true,
        loadingSearch: true,
        appointments: [],
      }, () => {
        this.getAppointments();
      });
    }
  }

  checkAppointment = () => {
    const { appointment_copy, dialogCopyApointment } = this.state;
    this.setState({ busy: true });
    AppointmentApiInvoker.getAppointment(
      appointment_copy,
      () => {
        //mlg
        this.props.onSelectAppointmentList(-1);
        this.props.onSelectAppointmentObject(null);
        browserHistory.push({
          state: { mode: 'copy' },
          pathname: `/appointments/${appointment_copy}/renew/agenda`,
        });
      }, (err) => {
        this.setState({
          dialogCopyApointment: !dialogCopyApointment,
          alertWarningCopy: true,
          busy: false,
        });
      }
    );
  }

  findFilters = () => {
    this.props.onCleanAppointments();
    this.setState({
      filter: this.state.filterShow,
      loading: true,
      loadingSearch: true,
      page: 0,
      appointments: [],
    }, () => {
      this.getAppointments();
    });
    this.setState({ showFilters: false });
  }

  showMore = () => this.setState({
    page: this.state.page + 1,
    loading: true,
  }, () => {
    this.getAppointments();
  });

  cleanSearch = () => {
    this.props.onCleanAppointments();
    this.setState({
      filter: {},
      page: 0,
      id: '',
      customerId: '',
      filterShow: [],
      chipsFilters: [],
      chips_original: [],
      loading: false,
      loadingSearch: false,
      customersList: [],
      appointments: [],
    }, () => {
      this.getAppointments();
      this.myRefStartDate.current.handleBlur('');
      this.myRefEndDate.current.handleBlur('');
    });
  }

  perfomSuccessReload = () => {
    this.setState({
      filter: this.state.filterShow,
      loading: true,
      page: 0,
      appointments: [],
      showConfirmationCancelAppointment: false,
    }, () => {
      this.getAppointments();
    });
  }

  render() {
    const { t } = this.props;
    const {
      alertWarningCopy, appointments, appointmentStatusList,
      busy, company_partnership_id, customersList, dialogCopyApointment, filter, filterShow,
      loading, loadingSearch, practiceTypes, provinces, proximityList,
      selectedAppointment, scheduleStatusList, selectedCancelAppointment, showConfirmCancelAppointment, showConfirmationCancelAppointment, showItemRequest,
    } = this.state;

    return (
      <Grid container className="appointments appointments-list">
        <Grid item xs={12}>
          <Card>
            <CardHeader icon className="filters-header" style={{ paddingBottom: 20 }}>
              <CardIcon color="warning">
                <Assignment />
              </CardIcon>
              <Grid container justify="flex-end">

                <Grid item xs={12} className="buttons-header appointment-header">
                  {company_partnership_id == null &&
                    <Tooltip title={t('appointment.new.newappointment')}>
                      <span>
                        <Button
                          className="add-button"
                          round
                          color="success"
                          authority="hospitalization_new"
                          onClick={() => this.addAppointment()}
                        >
                          <Add className="appointments-button-icon" /> {t('appointment.create')}
                        </Button>
                      </span>
                    </Tooltip>
                  }
                  {company_partnership_id == null &&
                    <Tooltip title={t('appointment.copy')}>
                      <span>
                        <Button
                          className="copy-button"
                          round
                          color="warning"
                          authority="appointment_new"
                          onClick={() => this.toggleCopy()}
                        >
                          <Copy className="appointments-button-icon" />
                        </Button>
                      </span>
                    </Tooltip>
                  }
                </Grid>
              </Grid>

              <Grid container className="appointments-filters">
                <Grid container item xs={12} sm={9} md={10} className="appointments-filters-row">
                  <Grid container item xs={12} className="filters-row">
                    <Grid container item xs={12} sm={3}>
                      <Grid item xs={6}>
                        <CustomInput
                          className="filter-full no-padding"
                          labelText={t('appointment.find.appointmentId')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={filter.appointmentId !== undefined ? filter.appointmentId : ''}
                          onChange={(value) => this.handleValue(value.target.value, 'appointmentId')}
                          errorText={t('error.required')}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <CustomInput
                          className="filter-full no-padding"
                          labelText={t('affiliate.number')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={filter.affiliateId !== undefined ? filter.affiliateId : ''}
                          onChange={(value) => this.handleValue(value.target.value, 'affiliateId')}
                          errorText={t('error.required')}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="no-padding"
                        label={t('appointment.find.customer')}
                        elements={customersList}
                        value={filter.customerId}
                        onSelectedValue={(value) => this.handleValue(value, 'customerId')}
                        onInputChange={(ev) => this.handleCustomerOptions(ev)}
                        freeSolo={true}
                        isWaitLetters={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t('appointment.find.identification')}
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={filter.id !== undefined ? filter.id : ''}
                        onChange={(value) => this.handleValue(value.target.value, 'id')}
                        errorText={t('error.required')}
                      />
                    </Grid>

                    <Grid container item xs={12} sm={3}>
                      <Grid container item xs={6} className="date-container startDate">
                        <DateInput
                          ref={this.myRefStartDate}
                          text={t('appointment.find.startDate')}
                          value={filterShow.startDate}
                          onChangeValue={(value) => this.handleValue(value, 'startDate')}
                        />
                      </Grid>
                      <Grid container item xs={6} className="date-container endDate">
                        <DateInput
                          ref={this.myRefEndDate}
                          text={t('appointment.find.endDate')}
                          value={filterShow.endDate}
                          onChangeValue={(value) => this.handleValue(value, 'endDate')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} className="filters-row">
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.appointmentStatus')}
                        elements={appointmentStatusList}
                        value={filterShow.appointmentStatus}
                        onSelectedValue={(value) => this.handleValue(value, 'appointmentStatus')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.scheduleStatus')}
                        elements={scheduleStatusList}
                        value={filterShow.scheduleStatus}
                        onSelectedValue={(value) => this.handleValue(value, 'scheduleStatus')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.proximity')}
                        elements={proximityList}
                        value={filterShow.proximity}
                        onSelectedValue={(value) => this.handleValue(value, 'proximity')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={`${t('appointment.find.province')}`}
                        elements={provinces}
                        value={filterShow.province}
                        onSelectedValue={(value) => this.handleValue(value, 'province')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={3} md={2} className="filters-actions-appointments">
                  <Grid container item justify="flex-end" className="filters-row">
                    <Grid item xs={12} className="info-search">
                      <Tooltip aria-label="info" title={t('tooltipInfo.searchAppointments')} placement="left-start">
                        <InfoOutlined />
                      </Tooltip>
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={t('reassignAppointments.buttonTooltip')}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            onClick={() => this.findFilters()}
                            label={t('appointment.find.find')}
                            labelLoading={t('appointment.find.find')}
                            loading={loadingSearch}
                            disabled={loading}
                            typeButton="submit"
                            color="success"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button block onClick={() => this.cleanSearch()} color="danger">
                        {t('appointment.find.clean')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardHeader>

            <CardBody className="appointments-content-body not-scroll-table filtrable-table">
              <Table
                filterable
                loading={loading}
                tableHeaderColor="primary"
                sortable
                tableHead={[
                  { Header: t('appointment.table.notification'), accessor: 'appointmentDateState', sortable: false, width: 130, filterable: false },
                  { Header: t('appointment.table.appointment_id'), accessor: 'appointmentId' },
                  { Header: t('appointment.table.financier'), accessor: 'financierName' },
                  { Header: t('affiliate.number'), accessor: 'affiliateId' },
                  { Header: t('appointment.table.init_date'), accessor: 'date', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('appointment.table.customer.id'), accessor: 'customerId' },
                  { Header: t('appointment.table.customer.name'), accessor: 'name' },
                  { Header: t('appointment.table.start_date'), accessor: 'startDate', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('appointment.table.end_date'), accessor: 'endDate', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('appointment.table.status'), accessor: 'appStatus' },
                  { Header: t('appointment.table.actions'), accessor: 'actions', width: 200, sortable: false, filterable: false },
                ]}
                tableData={appointments}
                colorsColls={['primary']}
                className="-striped -highlight filtrable sticky"
                defaultPageSize={appointments.length <= 25 ? 25 : appointments.length}
                showPaginationTop={false}
                showPaginationBottom={false}
              />
            </CardBody>
          </Card>
        </Grid>

        {appointments.length > 0 &&
          <Grid item className="bottom" xs={12}>
            <ButtonSpinner
              onClick={() => this.showMore()}
              label={t('appointment.showmore')}
              labelLoading={t('appointment.showmore')}
              loading={false}
              typeButton="submit"
              block
              disabled={loading}
              className="button-gray"
            />
          </Grid>
        }

        <CustomDialog
          title={t('appointment.renew.title')}
          maxWidth="sm"
          open={dialogCopyApointment}
          onClose={() => this.toggleCopy()}
        >
          <div>
            <p className="appointmentRenewTitle">
              {t('appointment.renew.question')}
            </p>
            <div className="booking-errors-actions">
              <CustomInput
                className="filter-number"
                id="appointment_copy"
                type="number"
                formControlProps={{ fullWidth: false }}
                inputProps={{ autocomplete: "no" }}
                onChange={(e) => this.setState({ appointment_copy: e.target.value })}
              />
            </div>
          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.checkAppointment()}
              disabled={busy}
              label={t('appointment.renew.acept')}
              labelLoading={t('common.saving')}
              loading={busy}
              typeButton="submit"
              color="success"
            />
          </div>
        </CustomDialog>

        <CustomDialog
          maxWidth="md"
          open={showItemRequest}
          onClose={() => this.toggleItemRequest()}
          title={t('appointment.actions.acceptReject')}
        >
          <ItemsRequest
            appointment={selectedAppointment}
            practiceTypes={practiceTypes}
            packages={this.state.package}
            onChangeItemsRequest={(a) => this.onChangeItemsRequest(a)}
          />
        </CustomDialog>

        {alertWarningCopy &&
          <CustomSweetAlert
            type="warning"
            onConfirm={() => this.setState({ dialogCopyApointment: true, alertWarningCopy: false })}
            confirmBtnCssClass="success"
            confirmBtnText={t('appointment.new.accept')}
            showCancel={false}
            message={<p>{t('appointment.renew.renewWarning')}</p>}
          />
        }

        {showConfirmCancelAppointment &&
          <CustomSweetAlert
            type="warning"
            onConfirm={this.cancelAppointmentAction}
            onCancel={() => this.setState({ showConfirmCancelAppointment: false })}
            confirmBtnCssClass="success"
            cancelBtnCssClass="danger"
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.created.cancel.confirm')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {selectedCancelAppointment}</p>}
          />
        }

        {showConfirmationCancelAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.perfomSuccessReload}
            confirmBtnCssClass="success"
            title={t('appointment.created.cancel.confirmation.title')}
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            showCancel={false}
            message={<p>{selectedCancelAppointment} {t('appointment.created.cancel.confirmation.message')}</p>}
          />
        }
      </Grid>
    )
  }
}

Appointments.defaultProps = {
  appointments: [],
}

Appointments.propTypes = {
  onGetAppointments: PropTypes.func.isRequired,
  onDeleteAppointment: PropTypes.func.isRequired,
  onSelectAppointment: PropTypes.func.isRequired,
  onSelectAppointmentList: PropTypes.func.isRequired,
  onSelectAppointmentObject: PropTypes.func.isRequired,
  appointments: PropTypes.any,
}

export default withTranslation()(Appointments);
