import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../CustomButtons/Button';

const defaultProps = {
  observedValues: [],
  onSaveChanges: () => {},
  onUndoChanges: () => {},
  onChangeDetected: () => {},
  undoText: 'Undo Changes',
  applyText: 'Save Changes',
}

const styles = {
  'buttonsContainer': {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'center',
    'alignItems': 'center',
  }
}

const SaveChanges = (props = defaultProps) => {
  const [ initialState, setInitialState ] = useState([])
  const [ changed, setChange ] = useState(false);

  const onSaveChanges = () => {
    props.onSaveChanges();
  }

  const onUndoChanges = () => {
    setChange(false);
    props.onUndoChanges(initialState);
  }

  useEffect(() => {
    if(!initialState.length) {
      return setInitialState(props.observedValues)
    } else {
      const changed = props.observedValues.reduce((prev, curr, i) => {
        return !lodash.isEqual(initialState[i], curr) || prev;
      }, false)
      if (changed) {
        props.onChangeDetected();
        return setChange(true)
      } else {
        return setChange(false)
      }
    }
  }, props.observedValues)

  useEffect(() => {
    if(props.setNewInitialState){
      setInitialState(props.observedValues);
      setChange(false);
    }
  }, [props.setNewInitialState])

  return (
    <div className={props.classes.buttonsContainer}>
      <Button color="danger" disabled={!changed || props.disabled} onClick={onUndoChanges}> {props.undoText} </Button>
      <Button color="success" disabled={!changed || props.disabled} onClick={onSaveChanges}> {props.applyText} </Button>
    </div>
  )
}

export default withStyles(styles)(SaveChanges);
