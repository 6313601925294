import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Syringe(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path fill="none" stroke="#FFFFFF" d="M5.006 19.006L2.682 21.33M18.734 5.278L15.638 8.374M15.724 2.69L21.311 8.276"/><path d="M9.308 15.707L10.722 14.293 13.921 17.493 15.493 15.921 12.337 12.766 13.751 11.352 16.907 14.507 19.368 12.046 11.955 4.632 4.031 12.545 3.995 20 11.414 20 12.507 18.907z" />
    </SvgIcon>
  );
}
