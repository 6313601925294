export const EMPLOYEES = 'EMPLOYEES';
export const FINANCIERS = 'FINANCIERS';
export const PATOLOGIES = 'PATOLOGIES';
export const PROVINCIES = 'PROVINCIES';
export const EMPLOYEES_TYPE = 'EMPLOYEES_TYPE';

export const SUPPLIES = 'SUPPLIES';
export const TOOLS = 'TOOLS';

export function getEmployees(employees) {
    return {type: EMPLOYEES, employees}
}

export function getEmployeesType(employeesType) {
    return {type: EMPLOYEES_TYPE, employeesType}
}

export function getFinanciers(financiers) {
    return {type: FINANCIERS, financiers}
}
export function getPatologies(patologies) {
    return {type: PATOLOGIES, patologies}
}
export function getProvincies(provincies) {
    return {type: PROVINCIES, provincies}
}

export function getSupplies(supplies) {
    return {type: SUPPLIES, supplies}
}

export function getTools(tools) {
    return {type: TOOLS, tools}
}