import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from "../Table/Table";
import Button from '../CustomButtons/Button';
import PersonApiInvoker from "../../api/PersonApiInvoker";
import { MedicalDoctor } from '../../icons';
import CustomInput from '../CustomInput/CustomInput';
import SelectInput from '../SelectInput';
import ButtonSpinner from '../ButtonSpinner';

import './Employees.css';

class Employees extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            loading: false,
            filter: { zoneGeographic: [], employeeType: [] },
            employeeTypes: [],
            geographics: [],
            page: 0,
            pageSize: 25
        }
    }
    componentDidMount() {
        this.getEmployees();
        this.getEmployeeType();
        this.getGeographics();
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.reduceEmployee(next.employees);
            
        }
    }

    reduceEmployee = (employees) => {
        if (employees.length) {
          const data = this.formatEmployees(employees);
          const oldAppointments = this.state.employees;
          oldAppointments.push(...data);
          const uniques = oldAppointments.filter((v, i, a) => a.findIndex(t => (t.personId === v.personId)) === i)
          this.setState({ employees: uniques, originalquery: uniques }, () => {
              if (this.state.employees.length > 2 && this.state.page > 0) {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                  });
    
              }
          });
        }
      }

    getEmployeeType() {
        PersonApiInvoker.getEmployeesType('PROFESSIONAL', data => {
            this.setState({
                employeeTypes: this.formatEmployeeTypes(data)
            })
        }, e => {
            console.error('** error', e);
            this.setState({ loading: false });
        });
    }

    getGeographics() {
        PersonApiInvoker.getGeographicsCompany(data => {
            this.setState({
                geographics: this.formatGeographics(data)
            })
        }, e => {
            console.error('** error', e);
            this.setState({ loading: false });
        });
    }

    getEmployees() {
        this.setState({ loading: true });
        var self = this;
        var params = Object.keys(this.state.filter).map(function (k) {
            let value = self.state.filter[k]
            if (value != '') {
                return encodeURIComponent(k) + '=' + encodeURIComponent(value.toString())
            } else {
                return null
            }
        }).join('&')
        if (params !== '') {
            params = params + "&";
        }
        params = params + "page=" + this.state.page + "&pageSize=" + this.state.pageSize;
        PersonApiInvoker.getEmployeesWithGeoZoneFilter(params, data => {
            this.setState({ loading: false });
            if (data && data.length) {
                this.props.onGetEmployees(data);               
            }
        }, e => {
            console.error('** error', e);
            this.setState({ loading: false });
        });
    }

    formatEmployeeTypes = (employee) => employee.map(e => {
        return {
            id: e.employeeTypeId,
            value: e.employeeTypeName,
        }
    });

    formatGeographics = (geo) => geo.map(e => {
        return {
            id: e.geographicZoneId,
            value: e.detail,
        }
    });

    formatEmployees(employees) {
        const data = employees.map(c => {
            return {
                personId: c.personId,
                lastName: c.lastName,
                firstName: c.firstName,
                identification: c.id,
                email: c.email,
                zoneGeographics: c.zoneGeographics.map((zone) => zone.detail).join(', '),
                employeeType: this.toStringTypes(c.employeeTypes),
                actions: <div className="employees-actions">
                    <Tooltip title={this.props.t('employees.table.view')}><div><Button simple justIcon color="info" onClick={() => browserHistory.push({
                        state: { mode: 'view' },
                        pathname: `/ver-profesional/${c.personId}`
                    })}><AssignmentIcon /></Button></div></Tooltip>
                    <Tooltip title={this.props.t('employees.table.edit')}>
                        <div>
                            <Button simple justIcon color="success"
                                onClick={() => browserHistory.push(`/editar-profesional/${c.personId}`)}>
                                <Edit />
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            }
        });
        return data;
    }

    toStringTypes = (employeeType) => {
        let toString = '';
        for (let idx = 0; idx < employeeType.length; idx++) {
            if (idx > 0) {
                toString = toString + ', ';
            }
            toString = toString + employeeType[idx].name;
        }
        return toString;
    }

    handleValue(value, field) {
        const { filter, filterShow } = this.state;
        filter[field] = value;
        this.setState({
            filter: filter,
        });
    }

    cleanFilters = () => {
        this.props.onCleanEmployees();
        this.setState({
            filter: { zoneGeographic: [], employeeType: [], page: 0 },
            employees: []
        }, () => {
            this.getEmployees();
        })
    }

    find = () => {
        this.props.onCleanEmployees();

        this.setState({
            employees: [],
            page: 0,
            loading: true,
        }, () => {
            this.getEmployees();
        })
    }

    showMore = () => {
        this.setState({
          page: this.state.page + 1,
          loading: true,
        }, () => {
          this.getEmployees();
        })
      }

    render() {
        const { t } = this.props;
        const { loading, employees } = this.state;
        return (
            <GridContainer className="employees">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="info">
                                <MedicalDoctor />
                            </CardIcon>
                            <GridContainer className="appointments-filters">
                                <GridItem xs={12} sm={9} md={12} className="no-padding">
                                    <GridContainer>
                                        <GridItem xs={2}>

                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.id')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={this.state.filter.id !== undefined ? this.state.filter.id : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'id')}
                                                errorText={t('error.required')}
                                            />


                                        </GridItem>
                                        <GridItem xs={3}>

                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.first.name')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={this.state.filter.firstName !== undefined ? this.state.filter.firstName : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'firstName')}
                                                errorText={t('error.required')}
                                            />

                                        </GridItem>

                                        <GridItem xs={2}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.last.name')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={this.state.filter.lastName !== undefined ? this.state.filter.lastName : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'lastName')}
                                                errorText={t('error.required')}
                                            />

                                        </GridItem>

                                        <GridItem xs={3}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('employee.find.email')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={this.state.filter.email !== undefined ? this.state.filter.email : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'email')}
                                                errorText={t('error.required')}
                                            />

                                        </GridItem>

                                        <GridItem xs={5}>
                                            <SelectInput
                                                className="no-padding"
                                                label={t('employee.find.employeetype')}
                                                multi
                                                elements={this.state.employeeTypes}
                                                value={this.state.filter.employeeType}
                                                onSelectedValue={(value) => this.handleValue(value, 'employeeType')}
                                            />

                                        </GridItem>
                                        <GridItem xs={5}>
                                            <SelectInput
                                                className="no-padding"
                                                label={t('employee.find.zone.geographics')}
                                                multi
                                                elements={this.state.geographics}
                                                value={this.state.filter.zoneGeographic}
                                                onSelectedValue={(value) => this.handleValue(value, 'zoneGeographic')}
                                            />
                                        </GridItem>
                                        <GridItem xs={2}>
                                            <GridContainer className="appointments-filters" style={{ marginTop: -50 }}>
                                                <GridItem className="filters-actions" xs={12}>
                                                    <Button block onClick={() => this.find()} color="success">
                                                        {t('employee.find.find')}
                                                    </Button>
                                                </GridItem>
                                                <GridItem className="filters-actions" xs={12}>
                                                    <Button block onClick={() => this.cleanFilters()} color="danger">
                                                        {t('employee.find.clean')}
                                                    </Button>
                                                </GridItem>


                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>

                                </GridItem>

                            </GridContainer>
                        </CardHeader>
                        <CardBody className="appointments-content-body not-scroll-table filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t("employees.table.first_name"), accessor: 'firstName' },
                                    { Header: t("employees.table.last_name"), accessor: 'lastName' },
                                    { Header: t("employees.table.identification"), accessor: 'identification' },
                                    { Header: t("employees.table.email"), accessor: 'email' },
                                    { Header: t("employees.table.specialties"), accessor: 'employeeType' },
                                    { Header: t("employees.table.geozones"), accessor: 'zoneGeographics' },
                                    { Header: t("employees.table.actions"), accessor: 'actions', sortable: false, filterable: false }
                                ]}
                                tableData={employees}
                                defaultPageSize={employees.length <= 25 ? 25 : employees.length}
                                showPaginationTop={false}
                                showPaginationBottom={false}
                
                                colorsColls={["primary"]}
                            />
                            {employees.length &&
                                <GridItem className="bottom" xs={12} sm={12} md={12}>
                                    <ButtonSpinner
                                        onClick={() => this.showMore()}
                                        label={t('employee.showmore')}
                                        labelLoading={t('employee.showmore')}
                                        loading={false}
                                        typeButton="submit"
                                        block
                                        disabled={loading}
                                        className="button-gray"
                                    />
                                </GridItem>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

Employees.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onCleanEmployees: PropTypes.func.isRequired,
    employees: PropTypes.array,
}

export default withTranslation()(Employees);
