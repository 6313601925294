import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { withTranslation } from 'react-i18next';
import BeforeUnloadComponent from 'react-beforeunload-component';
import _, { isArray } from 'lodash';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Assignment from '@material-ui/icons/Assignment';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ButtonSpinner from '../../ButtonSpinner';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import Button from '../../CustomButtons/Button';
import CustomInput from '../../CustomInput/CustomInput';
import CustomSweetAlert from '../../CustomSweetAlert';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';
import ValidationInput from '../../ValidationInput';
import FullLoading from '../../FullLoading';
import CustomDialog from '../../CustomDialog';
import Snackbar from '../../Snackbar/Snackbar';
import SchedulerService from '../../../containers/SchedulerService';
import BookingDetail from '../../Bookings/BookingDetail';
import EventWithIcon from '../../EventWithIcon/EventWithIcon';
import ConfirmAlert from '../../ConfirmAlert';
import { HealthBook, DeleteDocument } from '../../../icons';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import AppointmentToolsService from '../../../containers/AppointmentsToolsService';
import SaveChanges from '../SaveChanges/SaveChanges';
import './AppointmentDetail.css';

const localizer = momentLocalizer(moment);

class AppointmentDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      metadata: {
        medicalData: {},
        diagnostic: {},
        disability: {},
      },
      cancellations: [],
      cancellationReason: '',
      confirmMetadataEdit: false,
      saveChangesDisabled: false,
      bookings: [],
      practiceModulesCopy: [],
      loading: false,
      openDetail: false,
      openConfirmCancel: false,
      openConfirmChanges: false,
      currentEvent: {},
      events: [],
      event_original: [],
      items: [],
      itemsCopy: [],
      updateErrors: {},
      status: '',
      alertErrorOpen: false,
      alertErrorMessage: '',
      alertOpen: false,
      alertStatus: '',
      appointmentBookingId: 0,
      showConfirmUndo: false,
      showConfirmSave: false,
      bookingErrors: [],
      alertMessageOpen: false,
      alertMessageColor: 'danger',
      alertMessageMsg: 'danger',
      rejecteds: [],
      calendarViewMode: 'month',
      scheduleStatus: '',
      serviceProvider: null,
      confirmOpenNewAppointment: false,
      busy: false,
      invalidStartDate: false,
      showErrorStartDayBefore: false,
      showAgendaPracticeDisplay: false,
      myPractices: []
    }
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    this.confirmUndo();
  }

  onUnload = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }

  componentDidMount() {
    if (this.props.location.state.mode === 'edit') {
      window.addEventListener('beforeunload', this.onUnload, false);
    }
    if (this.props.location.state.mode !== 'copy') {
      this.fetchAppointment(true);
    }
    this.getAppointment();
    this.getCancellations();
  }

  componentWillUnmount() {
    if (this.props.location.state.mode === 'edit') {
      window.removeEventListener('beforeunload', this.onUnload, false);
    }
    window.removeEventListener('unload', this.componentCleanup);
  }

  componentWillMount() {
    window.addEventListener('unload', this.componentCleanup);
  }

  getCancellations = () => {
    AppointmentApiInvoker.getAppointmentCancellations(this.props.params.id, cancellations => {
      const cancelledTotal = cancellations.find(c => c.cancelType === 'Total') || {}
      this.setState({
        cancellationReason: cancelledTotal.reason || '',
        cancellations
      });
    }, err => {
      console.error('** error getAppointmentCancellations: ', err);
    })
  }

  normaizePractices = (array) => {
    const items = this.state.practiceModulesCopy;
    array.forEach((b, i) => {
      items.push(b);
    });
    this.setState({ practiceModulesCopy: items });
  }

  setAppointmentCopy(app) {
    const today = moment().format('YYYY-MM-DD');
    const endDateServ = moment(app.endDate);
    let startDate = moment(endDateServ).add(1, 'days');
    const realOriginStartDate = app.startDate;
    const realOriginEndDate = app.startDate;

    const diffStart = moment().diff(startDate, 'days');
    if (diffStart > 0) startDate = today;
    const diffEnd = moment(app.endDate).diff(app.startDate, 'days');
    const endDate = moment(startDate).add(diffEnd, 'days');

    app.startDate = startDate;
    app.endDate = moment(endDate).format('YYYY-MM-DD');
    app.toolsList = [];

    this.setState({
      customerId: app.customer.personId,
      provider: {},
      affiliateId: app.financier.financierId,
      addressId: app.address.addressId,
      creationEmployeeId: app.creationEmployee ? app.creationEmployee.personId : null,
      description: app.description,
      startDate: '',
      endDate: '',
      metadata: app.metadata,
      tools: [],
    });
    app.appointmentStatus = 'CREATED';
    this.fetchItems(app, realOriginStartDate, realOriginEndDate);
    this.props.onSelectAppointmentObject(app);
    this.setState({
      loading: false,
      customer: { zone: app.address.geographicZone.geographicZoneId }
    });
  }

  getAppointment = () => {
    const { location, params } = this.props;
    AppointmentApiInvoker.getAppointment(params.id, app => {
      if (location.state.mode === 'copy') {
        this.setAppointmentCopy(app);
      } else {
        if (app.appointmentStatus === 'SCHEDULES_ASSIGNED') {
          this.fetchItemsDetails();
        }
        this.props.onSelectAppointmentObject(app);
        this.setState({ metadata: app.metadata, loading: false, customer: { zone: app.address.geographicZone.geographicZoneId } });
      }
    }, err => {
      console.error('** error getAppointment: ', err);
    });
  }

  componentWillReceiveProps(next) {
    if (next.selectedAppointment && next.selectedAppointment.metadata) {
      this.setState({ metadata: next.selectedAppointment.metadata });
    }
  }

  fetchAppointment = async (loading) => {
    const { location, params } = this.props;
    const dontFetchCanceled = location.state.mode === 'edit' || location.state.mode === 'view';
    this.setState({ loading });
    AppointmentApiInvoker.getScheduleAppointment(
      params.id,
      dontFetchCanceled,
      events => {
        this.formatSchedules(events);
        this.setState({ loading: false });
        this.props.onGetSchedulesAppointment(events);
      }, err => {
        console.error('** error getScheduleAppointment: ', err);
      }
    )
  }

  fetchItems = async (app, realOriginStartDate, realOriginEndDate) => {
    const { location, params } = this.props;
    AppointmentApiInvoker.getAppointmentItems(
      params.id,
      items => {
        if (location.state.mode === 'copy') {
          const itn = [];
          items.forEach((b, i) => {
            const diffStart = moment(b.startDate).diff(realOriginStartDate, 'days');
            let startDate = moment(app.startDate).add(diffStart, 'days');
            let originaStartDatePractice = b.startDate;
            b.startDate = startDate;
            if (b.endDate != null && b.endDate != '') {
              let endDate = b.endDate.replace('"', '');
              const diffStartEnd = moment(endDate).diff(originaStartDatePractice, 'days');
              b.endDate = moment(b.startDate).add(diffStartEnd, 'days');
            }
            b.order = i;
            itn.push(b);
          })
          this.setState({ items: itn, itemsCopy: itn });
        } else {
          this.setState({ items });
        }
      }, err => {
        console.error('** error getAppointmentItems: ', err);
      }
    )
  }

  fetchItemsDetails = async () => {
    AppointmentApiInvoker.getAppointmentItemsDetails(
      this.props.params.id,
      items => {
        const resultItems = this.formatPracticeModule(items);
        this.setState({ items: resultItems });
      }, err => {
        console.error('** error getAppointmentItemsDetails: ', err);
      }
    )
  }

  getLenderName = (partnership) => {
    let isHeader = false;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
  
    if (companyPartnerShip == null) {
      isHeader = true;
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }
    return (isHeader ? partnership.name : partnership.companyId == companyPartnerShip ? partnership.name  : this.props.t('common.other'));
  }

  formatPracticeModule = (items) => {
    const { t } = this.props;
    const result = items.map(b => {
      const lender = this.getLenderName(b.companyIdPartnership);
      const status = t(`status.appointmentItemRequest.${b.itemRequestStatus}`);

      return {
        order: b.order,
        practiceTypeLabel: b.packageLabel ? b.packageLabel : b.practiceTypeLabel,
        startDate: `${moment(b.startDate).format('DD/MM/YYYY')}`,
        quantity: b.quantity,
        practiceModule: (b.packageLabel ? t('Package') : t('Practice')),
        serviceProvider: b.serviceProvider,
        status,
        lender,
      }
    });
    return result;
  }

  UndoChanges = () => this.setState({ showConfirmUndo: true });

  setProvider = (serviceProvider) => this.setState({ 'serviceProvider': serviceProvider });

  getServiceProviderOnEdit = stateItems => {
    let sprov = this.state.cancellations && this.state.cancellations.length > 0 ? this.state.cancellations[0].serviceProvider : {};
    sprov = this.state.serviceProvider ? this.state.serviceProvider : sprov;
    const itemsExist = stateItems && stateItems.length && true;
    return itemsExist ? stateItems[0].serviceProvider && stateItems[0].serviceProvider : sprov; //TODO: agregar condicion para solicitudes canceladas --> this.state.cancellations;
  }

  getProvider = () => {
    const {
      financier,
      customer,
      startDate,
      endDate,
    } = this.props.selectedAppointment;
    return {
      customerId: {
        customer: customer.personId
      },
      financier,
      provider: this.getServiceProviderOnEdit(this.state.items),
      dateStart: startDate,
      dateEnd: endDate
    }
  }

  getCustomer = () => {
    const {
      customer: { personId },
      address: { addressId },
    } = this.props.selectedAppointment;

    return {
      customer: personId,
      address: addressId,
    }
  }

  handleOnSelectedEvent = (event) => {
    this.props.selectedSchedules.map(sch => {
      if (sch.scheduleId === event.id) {
        this.setState({ scheduleStatus: sch.scheduleStatus });
      }
    });

    this.setState({
      openDetail: true,
      updateErrors: null,
      currentEvent: event,
    });
  }

  eventColors = (event, start, end, isSelected) => {
    let backgroundColor = 'event-';
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + 'default');
    return {
      className: `${backgroundColor} ${(event.hasRejected) && this.state.calendarViewMode != 'agenda' ? 'event-rejected' : ''}`
    }
  }

  formatSchedules = (data) => {
    let schedules = [...data];
    schedules = _.orderBy(schedules, ['scheduleDateTime'], ['asc']);

    const result = schedules.map(b => {
      let isMePM = false;
      let companyPartnerShip = localStorage.getItem('company_partnership_id');
      if (companyPartnerShip == null) {
        companyPartnerShip = localStorage.getItem('itlg_default_company_id');
      }
      isMePM = b.companyIdPartnership.companyId == companyPartnerShip;
      
      return {
        id: b.scheduleId,
        title: b.appointmentDetail.practiceType.name,
        allDay: false,
        time_display: `${moment(b.scheduleDateTime).format('HH:mm')} - ${moment(b.scheduleEndDateTime).format('HH:mm')}`,
        time_start: moment(b.scheduleDateTime).format('HH:mm'),
        start: new Date(b.scheduleDateTime),
        end: new Date(b.scheduleEndDateTime),
        view: this.state.calendarViewMode,
        hasRejected: (b.scheduleStatus === 'REJECTED') ? true : false,
        color: isMePM ? this.getColor(b) : 'grey',
        employeeId: b.employee.personId,
        type: 'schedule',
        status: !b.status ? 'OK' : b.status,
      }
    });

    const oresult = result.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);

    this.setState({
      event_original: oresult,
      events: result,
    });
  }

  onBack = () => browserHistory.goBack();

  updateAppointment = (appointment) => {
    let result = this.state.events;
    this.setState({
      updateErrors: null,
      status: null,
    });

    if (appointment && appointment.status) {
      if (appointment.status !== 'ERROR') {
        if (appointment.appointmentBookingId) {
          this.setState({ appointmentBookingId: appointment.appointmentBookingId })
        }
        this.state.events.forEach((b, i) => {
          if (b.id === this.state.currentEvent.id) {
            let item = appointment.bookings[0]
            result[i] = {
              id: item.scheduleBookingId,
              title: item.label,
              allDay: false,
              time_display: `${moment(item.scheduleDateTime).format('HH:mm')} - ${moment(item.scheduleEndDateTime).format('HH:mm')}`,
              time_start: moment(item.scheduleDateTime).format('HH:mm'),
              start: new Date(item.scheduleDateTime),
              end: new Date(item.scheduleEndDateTime),
              view: this.state.calendarViewMode,
              color: this.getColor(item.group),
              employeeId: item.employeeId,
              status: item.status,
              errorDetail:
                item.status === 'ERROR' || item.status === 'WARNING' ? item.errorDetail : '',
              type:
                item.booking ||
                  item.scheduleBookingId
                  ? 'booking'
                  : 'schedule',
            }
          }
        });

        this.setState({
          events: _.orderBy(result, ['start'], ['asc']),
          openDetail: false,
          appointment: appointment.status,
        });
      } else {
        this.setState({
          updateErrors: appointment.errors,
          status: appointment.status,
        });
      }
    } else {
      // Handling Unexpected errors
      this.setState({
        updateErrors: [{
          detail: appointment.message,
          statusError: 'ERROR',
        }],
        status: 'ERROR',
      });
    }
  }

  cancelSave = () => this.setState({ showConfirmSave: false, busy: false });

  openAlert = (color, message) => {
    this.setState({
      alertMessageColor: color,
      alertMessageMsg: message,
      alertMessageOpen: true,
    });

    setTimeout(() => {
      this.setState({ alertMessageOpen: false });
    }, 5000);
  }

  confirmUndo = () => {
    SchedulerInvoker.deleteBookingsByArray(
      [this.state.appointmentBookingId],
      data => {
        const oresult = this.state.event_original.reduce((newArray, element) => {
          newArray.push(element);
          return newArray;
        }, []);

        this.setState({
          events: oresult,
          showConfirmUndo: false,
          appointmentBookingId: null,
        });
        this.openAlert('success', this.props.t('common.undoMessageConfirm'));

      }, err => {
        this.setState({ showConfirmUndo: false });
        const message = err.message ?? err
        this.openAlert('danger', message + '');
      }
    )
  }

  //mlgmlgmlg
  assignAgenda() {
    AppointmentApiInvoker.getAppointmentItems(this.props.selectedAppointment.appointmentId,
      (res) => {
        let hasPrevius = false;
        res.forEach((e) => {
          const sDate = moment(e.startDate);
          const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          if (sDate < now) {
            hasPrevius = true;
          }
        });
        if (hasPrevius) {
          this.setState({ loading: false, busy: false, showErrorStartDayBefore: true })
        } else {
          this.confirmedAssignAgenda()
        }
      }, (err) => {
        console.error(err);
      });
  }

  // MLGMLGMLG
  confirmedAssignAgenda = () => {
    
    this.setState({
      showErrorStartDayBefore: false
    });

    this.setState({
      showAgendaPracticeDisplay: true
    })
    
  }

  confirmedAssignAgendaReal = () => {
    this.setState({ loading: true, busy: true })
    SchedulerInvoker.assignAgenda(
      this.props.selectedAppointment.appointmentId,
      (res) => {
        if (res.errors && res.errors.length) {
          this.setState({ alertOpen: true, bookingErrors: res.errors, alertStatus: res.status, loading: false, bookings: res });
        } else {
          this.afterSave(res);
        }
      }, (e) => {
        this.handleSaveError(e);
      }
    );
  }

  saveMetadata(skipAssignAgenda) {
    //MLG
    if (this.props.location.state.mode === 'assign_agenda') {
      this.assignAgenda();
      return;
    }
    const { metadata } = this.state;
    const meta = {
      // DO NOT DELETE
      sensibility: metadata.sensibility,
      'medicalData': metadata.medicalData,
      'diagnostic': {
        'principalDiagnostic': {
          'id': _.get(metadata, 'diagnostic.principalDiagnostic.recordId'),
          'description': _.get(metadata, 'diagnostic.principalDiagnostic.name'),
          'category': _.get(metadata, 'diagnostic.principalDiagnostic.parentId'),
          'subcategory': _.get(metadata, 'diagnostic.principalDiagnostic.recordCategoryId'),
        },
        'hospitalizationDiagnostic': {
          'id': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.recordId'),
          'description': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.name'),
          'category': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.parentId'),
          'subcategory': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.recordCategoryId'),
        },
        'complexity': {
          'id': _.get(metadata, 'diagnostic.complexity.id'),
          'description': _.get(metadata, 'diagnostic.complexity.name', _.get(metadata, 'diagnostic.complexity.description')),
        },
        'barthelIndex': {
          'id': _.get(metadata, 'diagnostic.barthelIndex.id'),
          'description': _.get(metadata, 'diagnostic.barthelIndex.name', _.get(metadata, 'diagnostic.barthelIndex.description')),
        },
        'observations': _.get(metadata, 'diagnostic.observations', _.get(metadata, 'observations.description')),
      },
      'disability': {
        'disabledPatient': _.get(metadata, 'disability.disabledPatient') || false,
        'activeDisabilityCertificate': _.get(metadata, 'disability.activeDisabilityCertificate') || false,
        'disabilityCertificateCode': _.get(metadata, 'disability.disabilityCertificateCode') || null,
      },
    }

    AppointmentApiInvoker.updateAppointmentMetadata(
      this.props.selectedAppointment.appointmentId,
      meta,
      (res) => {
        setTimeout(() => {
          this.setState({
            confirmMetadataEdit: false,
            saveChangesDisabled: true,
            busy: false,
          }, () => {
            if (skipAssignAgenda) {
              return;
            }
            this.setState({ busy: false });
            this.assignAgenda();
          })
        }, 1000);
      }, (e) => {
        this.handleSaveError(e);
        this.setState({ busy: false });
      });
  }

  afterSave(bookings) {
    const { t, selectedAppointment } = this.props;
    this.fetchAppointment(false);
    this.openAlert('success', t('appointment.message.success'));
    this.setState({ showConfirmSave: false, loading: false });

    browserHistory.push({
      state: {
        appointmentId: selectedAppointment.appointmentId,
        bookings: bookings,
        fromAppointmentAssignAgenda: true,
        dates: {
          startDate: selectedAppointment.dateStart,
          endDate: selectedAppointment.dateEnd
        }
      },
      pathname: '/turnos',
    });
  }

  validateFields = () => {
    const { addressId, affiliateId, customerId, financierId, metadata, provider } = this.state;
    let diagnostic;
    if (metadata.diagnostic && metadata.diagnostic.hospitalizationDiagnostic && metadata.diagnostic.hospitalizationDiagnostic.recordId) {
      diagnostic = true;
    }
    let secondary;
    if (metadata.diagnostic && metadata.diagnostic.hospitalizationDiagnostic && metadata.diagnostic.hospitalizationDiagnostic.recordId) {
      secondary = true;
    }

    if (
      !customerId ||
      !addressId ||
      !provider ||
      !provider.dateStart ||
      !provider.dateEnd ||
      !financierId ||
      !affiliateId ||
      !diagnostic ||
      !secondary
    ) {
      return false;
    }
    return true;
  }

  formatItems() {
    return this.state.itemsCopy.map((s, i) => {

      if (s.practiceTypeId) {
        const item = [];
        s.itemRequestDetails.forEach((c, idx) => {
          const sa = {
            packageDetailId: null,
            filerByZone: c.filerByZone,
            practiceTypeId: c.practiceTypeId,
            suggestedEmployeeId: c.suggestedEmployeeId,
          }
          item.push(sa);
        });

        const schedulePlan = s.schedulePlan;
        if (schedulePlan.recurrenceType === 'INTRADAY') {
          schedulePlan.processingScheduleType = 'INTRADAY';
          schedulePlan.processingStartTimes = null;
          schedulePlan.every = 1;
          schedulePlan.recurrenceType = 'WEEK';
        }
        schedulePlan.weekDays =
          Array.isArray(schedulePlan.weekDays) ? schedulePlan.weekDays.join(',') : schedulePlan.weekDays

        return {
          order: i + 1,
          startDate: s.startDate,
          endTime: s.endTime,
          startTime: s.startTime,
          endDate: s.endDate,
          practiceTypeId: s.practiceTypeId,
          itemRequestDetails: item,
          packageId: null,
          schedulePlan: schedulePlan,
          serviceProvider: { serviceProviderId: 1 }
        }

      } else {

        let itemRequestPackageDetails = [];
        if (s.itemRequestPackageDetails) {
          itemRequestPackageDetails = [];
          s.itemRequestPackageDetails.forEach(m => {
            const row = {}
            row['practiceTypeId'] = m['practiceTypeId'];
            row['recurrentType'] = m['recurrenceType'];
            row['every'] = m['every'];
            row['endType'] = m['endType'];
            row['processingScheduleType'] = m['processingScheduleType'];
            row['processingDurationTime'] = m['processingDurationTime'];
            row['weekDays'] =
              m.weekRecurrence ? (Array.isArray(m.weekRecurrence) ? m.weekRecurrence.join(',') : m.weekRecurrence) :
                Array.isArray(m.weekDays) ? m.weekDays.join(',') : m.weekDays;


            //row['weekDays'] = Array.isArray(m.schedulePlan['weekDays']) ? m.schedulePlan['weekDays'].join(',') : m.schedulePlan['weekDays']
            row['monthDay'] = m.monthRecurrence ? m.monthRecurrence : null;
            row['ocurrences'] = m['ocurrences'];

            let startTime = '06:00';
            if (m['startTime']) {
              startTime = moment(m['startTime'], 'HH:mm:SS').format('HH:mm');
            }
            let endTime = '23:00';
            if (m['endTime']) {
              startTime = moment(m['endTime'], 'HH:mm:SS').format('HH:mm');
            }
            row['startTime'] = startTime;
            row['endTime'] = endTime;
            itemRequestPackageDetails.push(row);
          });
        }

        return {
          order: i + 1,
          startDate: s.startDate,
          endDate: s.endDate,
          endTime: s.endTime,
          startTime: s.startTime,
          packageId: s.packageId,
          packageQuantity: s.packageQuantity ? s.packageQuantity : null,
          itemRequestDetails: s.itemRequestDetails,
          itemRequestPackageDetails: itemRequestPackageDetails,
          serviceProvider: { serviceProviderId: 1 }
        }
      }
    });
  }

  validateDateStart = () => {
    let notDateNull = true;
    this.state.itemsCopy.forEach((b, i) => {
      if (b.startDate === '') {
        notDateNull = false;
      }
    });
    return notDateNull;
  }

  validateMinStartDate = (date) => {
    const dif = moment().diff(date, 'days');
    return dif > 0;
  }

  showDialogSaveNewAppointment = () => {
    const isValidateMinDate = this.validateMinStartDate(this.state.provider.dateStart);
    if (isValidateMinDate) {
      this.setState({
        invalidStartDate: isValidateMinDate,
        checkInvalid: true,
      });
      return;
    }
    this.setState({ confirmOpenNewAppointment: true });
  }

  saveNewSchedule() {
    const { t } = this.props;
    const { addressId, affiliateId, financierId, customerId, diagnostic, metadata, provider } = this.state;

    if (!this.validateFields()) {
      this.setState({
        checkInvalid: true,
        confirmOpenNewAppointment: false,
      })
      this.openAlert('warning', t('appointment.new.requiredFields'));
      return;
    }

    if (!this.validateDateStart()) {
      this.setState({
        checkInvalid: true,
        confirmOpenNewAppointment: false,
      })
      this.openAlert('warning', t('appointment.new.haspracticewithoutdate'));
      return;
    }

    this.setState({
      loading: false,
      confirmOpenNewAppointment: false
    });

    const body = {
      affiliateId,
      financierId,
      addressId,
      customerId,
      creationEmployeeId: localStorage.getItem('user_id'),
      description: diagnostic,
      items: this.formatItems(),
      startDate: provider.dateStart,
      endDate: provider.dateEnd,
      metadata: {
        // DO NOT DELETE
        sensibility: 1,
        medicalData: {
          requestor: metadata.medicalData.requestor,
          auditor: metadata.medicalData.auditor,
          family: metadata.medicalData.family
        },
        diagnostic: {
          principalDiagnostic: {
            id: this.state.metadata.diagnostic.principalDiagnostic.recordId,
            description: this.state.metadata.diagnostic.principalDiagnostic.name,
            category: this.state.metadata.diagnostic.principalDiagnostic.parent && this.state.metadata.diagnostic.principalDiagnostic.parent.parent && this.state.metadata.diagnostic.principalDiagnostic.parent.parent.recordId,
            subcategory: this.state.metadata.diagnostic.principalDiagnostic.parent && this.state.metadata.diagnostic.principalDiagnostic.parent.recordId
          },
          hospitalizationDiagnostic: {
            id: this.state.metadata.diagnostic.hospitalizationDiagnostic.recordId,
            description: this.state.metadata.diagnostic.hospitalizationDiagnostic.name,
            category: this.state.metadata.diagnostic.hospitalizationDiagnostic.parent && this.state.metadata.diagnostic.hospitalizationDiagnostic.parent.parent && this.state.metadata.diagnostic.hospitalizationDiagnostic.parent.parent.recordId,
            subcategory: this.state.metadata.diagnostic.hospitalizationDiagnostic.parent && this.state.metadata.diagnostic.hospitalizationDiagnostic.parent.recordId
          },
          complexity: {
            id: (this.state.metadata.diagnostic.complexity ? this.state.metadata.diagnostic.complexity.id : null),
            description: (this.state.metadata.diagnostic.complexity ? (this.state.metadata.diagnostic.complexity.name ? this.state.metadata.diagnostic.complexity.name : this.state.metadata.diagnostic.complexity.description) : null)
          },
          barthelIndex: {
            id: (this.state.metadata.diagnostic.barthelIndex ? this.state.metadata.diagnostic.barthelIndex.id : null),
            description: (
              this.state.metadata.diagnostic.barthelIndex ? (this.state.metadata.diagnostic.barthelIndex.name ? this.state.metadata.diagnostic.barthelIndex.name : this.state.metadata.diagnostic.barthelIndex.description) : null)
          },
          observations: (this.state.metadata.diagnostic ? this.state.metadata.diagnostic.observations : ''),
        },
        disability: {
          disabledPatient: this.state.metadata.disability ? metadata.disability.disabledPatient || false : null,
          activeDisabilityCertificate: this.state.metadata.disability ? metadata.disability.activeDisabilityCertificate || false : null,
          disabilityCertificateCode: this.state.metadata.disability ? metadata.disability.disabilityCertificateCode || null : null,
        },
      }
    };
    SchedulerInvoker.postAppointment(body, appointement => {
      if (appointement.status === 'OK') {
        this.setState({
          loading: false,
          appointmentId: appointement.appointmentId,
        }, () => {
          this.performSaveTools(appointement.appointmentId);
        })
      } else {
        this.setState({
          alertOpen: true,
          confirmOpen: false,
          checkInvalid: false,
          bookingErrors: appointement.errors,
          alertStatus: appointement.status,
          bookingsResult: appointement,
          loading: false,
        })
      }
    }, error => {
      this.setState({ loading: false });
      const message = error.message ?? error;
      this.openAlert('danger', message + '');
    });
  }

  performSaveTools(appointmentId) {
    this.setState({
      loading: false,
      appointmentId,
      performAppointmentToolsSave: true,
    });
  }

  saveAppointmentTools = () => browserHistory.push('/solicitudes');

  handleAppointmentToolsSaveError = () => browserHistory.push('/solicitudes');

  handleSaveError(err) {
    this.setState({
      loading: false
    })
    if (err.message) {
      if (err.message == 'TIME_OUT_EXCEPTION') {
        this.setState({ showTimeOutException: true });
        return;
      }
    }
    const message = err.message ?? err;
    this.openAlert('danger', message + '');
    this.setState({ showConfirmSave: false });
  }

  confirmSave() {
    const { t, location } = this.props;
    if (location.state.mode === 'edit') {
      SchedulerInvoker.postSaveBookings(
        this.state.appointmentBookingId,
        data => {
          if (data.message) {
            this.openAlert('success', data.message);
          } else {
            //browserHistory.push('/solicitudes')
          }
          this.openAlert('success', t('appointment.message.success'));
          this.fetchAppointment(false);
          this.props.onSaveBookings();
          this.setState({ showConfirmSave: false });
        }, err => {
          const message = err.message ?? err
          this.openAlert('danger', message + '');
          this.setState({ showConfirmSave: false, busy: false });
        }
      )
    } else {
      if (location.state.mode === 'assign_agenda') {
        this.saveMetadata();
      } else {
        this.assignAgenda();
      }
    }
  }

  cancelUndo = () => {
    this.setState({ showConfirmUndo: false });
  }

  discardEdit = () => {
    this.setState({ showConfirmUndo: true });
  }

  handleCancelButton = () => {
    if (this.state.appointmentBookingId) {
      this.setState({ openConfirmCancel: true });
    } else {
      browserHistory.push('/solicitudes');
    }
  }

  deleteBookings = () => {
    this.setState({ openConfirmCancel: false });
    SchedulerInvoker.deleteBookings(this.state.appointmentBookingId);
    browserHistory.push('/solicitudes');
  }

  saveBookings = () => this.setState({ showConfirmSave: true, busy: false });

  getColor = (status) => {
    let colors = {
      0: 'E157CA',
      1: 'F06292',
      2: '5AB4FF',
      3: '79009B',
      4: 'D81B60',
      5: '0A85FF',
      6: 'BF00B0',
      7: '880E4F',
      8: '1F3FB9',
    };
    return colors[status.group % 9];
  }

  setAuthorizerMetadata(value) {
    const currMeta = this.state.metadata;
    const { family, requestor, auditor } = value;
    const newMeta = {
      ...currMeta,
      medicalData: {
        family, requestor, auditor,
      }
    };
    this.setState({ metadata: newMeta });
  }

  setDisabilityMetadata(value) {
    const currMeta = this.state.metadata;
    const { activeDisabilityCertificate, disabledPatient, disabilityCertificateCode } = value;
    const newMeta = {
      ...currMeta,
      disability: {
        disabledPatient,
        activeDisabilityCertificate,
        disabilityCertificateCode,
      }
    };
    this.setState({ metadata: newMeta });
  }

  setDiagnostic(value, field) {
    const currMeta = this.state.metadata;
    const newMeta = {
      ...currMeta,
      diagnostic: {
        ...currMeta.diagnostic,
        [field]: value,
      }
    };
    this.setState({ metadata: newMeta });
  }

  onChangeView = (event) => {
    this.state.events.forEach((b, i) => {
      b.view = event;
    });

    this.setState({ calendarViewMode: event });
  }

  confirmSaveMetadata = () => this.setState({ confirmMetadataEdit: true, busy: true });

  undoChangesMetadata(initialState) {
    const [observations, barthelIndex, complexity, principalDiagnostic, hospitalizationDiagnostic, medicalData, disability] = initialState;
    this.setState({
      metadata: {
        sensibility: this.state.metadata.sensibility,
        medicalData,
        diagnostic: {
          observations,
          barthelIndex,
          complexity,
          principalDiagnostic: {
            recordId: principalDiagnostic,
            id: principalDiagnostic,
          },
          hospitalizationDiagnostic: {
            recordId: hospitalizationDiagnostic,
            id: hospitalizationDiagnostic,
          },
        },
        disability,
      }
    });
  }

  saveNewPractices(appointementScheduleItem, cb = () => { }) {
    if (this.props.location.state.mode !== 'copy') {

      if (appointementScheduleItem.schedulePlan && appointementScheduleItem.schedulePlan.recurrenceType === 'INTRADAY') {
        appointementScheduleItem.schedulePlan.recurrenceType = 'WEEK';
        appointementScheduleItem.schedulePlan.weekDays = isArray(appointementScheduleItem.schedulePlan.weekDays) ?
          appointementScheduleItem.schedulePlan.weekDays.join(',') : appointementScheduleItem.schedulePlan.weekDays
      } else {
        if (appointementScheduleItem.schedulePlan) {
          appointementScheduleItem.schedulePlan.weekDays = isArray(appointementScheduleItem.schedulePlan.weekDays) ?
            appointementScheduleItem.schedulePlan.weekDays.join(',') : appointementScheduleItem.schedulePlan.weekDays
        }
      }
      if (appointementScheduleItem.companyIdPartnership.companId == undefined) {
        appointementScheduleItem.companyIdPartnership = {"companyId":  appointementScheduleItem.companyIdPartnership};
      }
      appointementScheduleItem.appointmentItemRequestStatus = "CREATED";
      //mlgmlgmlg
      const data = [appointementScheduleItem];
      AppointmentApiInvoker.postAppointmentScheduleItems(this.props.selectedAppointment.appointmentId, data, (res) => {
        const newItems = _.cloneDeep(this.state.items).concat(res)
        this.setState({ items: newItems });
        return cb(res);
      }, (err) => {
        console.error('** error postAppointmentScheduleItems:', err);
      });
    } else {
      const itemsCopyNew = this.state.itemsCopy;
      itemsCopyNew.push(appointementScheduleItem);
      const nitems = [];
      this.state.itemsCopy.forEach((b, i) => {
        b.order = i;
        nitems.push(b);
      });
      this.setState({ itemsCopy: nitems });
    }
  }

  reemplacePractices = (itemCopy) => {
    const itemsCopyNew = [];
    this.state.itemsCopy.forEach((b, i) => {
      if (b.order === itemCopy.order) {
        itemCopy.order = i
        itemsCopyNew.push(itemCopy);
      } else {
        b.order = i;
        itemsCopyNew.push(b);
      }
    })
    this.setState({ itemsCopy: itemsCopyNew });
  }

  deletePractices(practiceToDelete, appointmentItemRequestIdToDelete) {
    if (this.props.location.state.mode === 'copy') {
      const itemsCopyNew = [];
      let index = 0;
      this.state.itemsCopy.forEach((b, i) => {
        if (b.order !== appointmentItemRequestIdToDelete) {
          b.order = index;
          itemsCopyNew.push(b);
          index++;
        }
      })
      this.setState({ itemsCopy: itemsCopyNew });
      return;
    }

    if (!appointmentItemRequestIdToDelete) {
      const appScheduleItem = this.state.items.find(i => {
        if (i.practiceTypeId) {
          return i.practiceTypeId === practiceToDelete.practiceTypeId;
        } else if (i.packageId) {
          return i.packageId === practiceToDelete.packageId;
        } else {
          return false;
        }
      })
      if (appScheduleItem) {
        AppointmentApiInvoker.deleteAppointmentScheduleItems(appScheduleItem.appointmentItemRequestId, (res) => {
        }, (res) => {
          console.log(res);
        });
      }
    } else {
      AppointmentApiInvoker.deleteAppointmentScheduleItems(appointmentItemRequestIdToDelete, (res) => {
      }, (res) => {
        console.log(res);
      })
    }
  }

  setProviderF(value) {
    this.setState({
      provider: value,
    }, () => { });
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: (value.financierId) ? value.financierId : '',
      affiliateId: (value.affiliateId) ? value.affiliateId : '',
    });
  }

  setMetadata = (value) => this.setState({ metadata: value });

  alertErrorStartDate = (t) => (
    <CustomSweetAlert
      type="danger"
      onConfirm={() => this.setState({ invalidStartDate: false })}
      confirmBtnCssClass="danger"
      confirmBtnText={t('common.cancel')}
      showCancel={false}
      title={t('appointment.new.appointmentReview')}
      message={<p>{t('appointment.invalidDate')}</p>}
    />
  );

  cancellationsTableData = (data, t) => data.map(cancelation => {
    return {
      'order': cancelation.order,
      'type': t(cancelation.type),
      'speciality': cancelation.speciality,
      'count': cancelation.count,
      'startDate': moment(cancelation.startDate).format('DD/MM/YYYY'),
      'cancelDate': moment(cancelation.cancelDate).format('DD/MM/YYYY'),
      'reason': cancelation.cancelType === 'Total' ? '' : cancelation.reason,
    }
  });


  render() {
    const {
      t, appointmentsTools, classes, location, onGetAppointmentsTools, onCheckAppointmentToolsEdit,
      params, selectedAppointment, selectedSchedules, onDeleteAppointmentTool,
    } = this.props;

    const {
      alertErrorMessage, alertOpen, alertStatus, alertErrorOpen, alertMessageColor, alertMessageMsg, alertMessageOpen,
      appointmentBookingId, appointmentId, bookings, bookingErrors, busy,
      cancellations, cancellationReason, checkInvalid, confirmMetadataEdit, confirmOpenNewAppointment, currentEvent, customer,
      events, event_original, loading, invalidStartDate, items, itemsCopy, metadata,
      openConfirmChanges, openConfirmCancel, openDetail, performAppointmentToolsSave, provider,
      saveChangesDisabled, scheduleStatus, showConfirmSave, showConfirmUndo, status, updateErrors,
    } = this.state;

    const eventChange = (JSON.stringify(events) !== JSON.stringify(event_original));
    const viewMode = location.state.mode;
    const authorityAgenda = (location.state && location.state.authority) && location.state.authority === 'appointment_edit_agenda';
    const mThis = this;

    if (loading || (!appointmentId && !selectedAppointment)) {
      return (<FullLoading />)
    }
    let myPractices = this.state.myPractices;

    const cancellationsCanceled = cancellations.filter(c => c.canceled);
    const cancellationsData = this.cancellationsTableData(cancellationsCanceled, t);
    const cancellationsAppoStatus = ['CANCELLED', 'SCHEDULES_ASSIGNED', 'OVERDUE', 'ENDED'];
    const isCancellationsAppoStatusOk = cancellationsAppoStatus.find(f => f === selectedAppointment.appointmentStatus) !== undefined;
    const viewPracticesModulesTable = viewMode === 'view' || viewMode === 'assign_agenda' || viewMode === 'copy' || viewMode === 'edit' || selectedAppointment.appointmentStatus === 'CANCELLED';
    return (
      <BeforeUnloadComponent
        blockRoute={true}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          if (!appointmentBookingId) {
            handleModalLeave();
            return;
          }
          return (
            <CustomSweetAlert
              type="warning"
              onConfirm={() => mThis.confirmUndo()}
              onCancel={() => handleModalCancel()}
              confirmBtnCssClass="success"
              cancelBtnCssClass="danger"
              cancelBtnText={t('common.cancel')}
              confirmBtnText={t('common.confirm')}
              showCancel
              title={t('reload.site.title')}
              message={<p>{t('reasign.reload.site.changes.lost')}</p>}
            />
          )
        }}
      >
        <GridContainer className={`appointment-detail appointment-${viewMode}`}>
          <Snackbar
            place="tr"
            color={alertMessageColor}
            message={alertMessageMsg}
            open={alertMessageOpen}
          />
          <GridItem className="appointment-data" xs={12}>
            {showConfirmUndo &&
              <CustomSweetAlert
                type="warning"
                title={t('common.undoMessage')}
                onConfirm={() => this.confirmUndo()}
                onCancel={() => this.cancelUndo()}
                confirmBtnCssClass="success"
                cancelBtnCssClass="danger"
                cancelBtnText={t('common.cancel')}
                confirmBtnText={t('common.confirm')}
                showCancel
              />
            }

            {invalidStartDate && this.alertErrorStartDate(t)}

            {showConfirmSave &&
              <CustomSweetAlert
                type="warning"
                title={t('common.saveChangesMessage')}
                onConfirm={() => this.confirmSave()}
                onCancel={() => this.cancelSave()}
                showCancel={false}
                showConfirm={false}
                message={
                  <div className="customAlertButtons">
                    <Button
                      color="danger"
                      onClick={() => {
                        this.cancelSave()
                        this.setState({ busy: false })
                      }}
                    >
                      {t('common.cancel')}
                    </Button>
                    <ButtonSpinner
                      onClick={() => {
                        this.confirmSave()
                      }}
                      disabled={busy}
                      label={t('confirm')}
                      labelLoading={t('common.saving')}
                      loading={busy}
                      authority="hospitalization_new"
                      typeButton="submit"
                      color="success"
                    />
                  </div>
                }
              />
            }
            {viewMode !== 'copy' ?
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Assignment />
                  </CardIcon>
                  <h3 className="card-icon-title">{t('appointment.title')}</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer className="appointment-detail-schedule">
                    <GridItem className="fullWidth-input validation-input" xs={2} sm={4}>
                      <CustomInput
                        id="appointment_id"
                        disabled={true}
                        labelText={t('appointment.input.appointment_id')}
                        value={
                          selectedAppointment
                            ? selectedAppointment.appointmentId
                            : null
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input validation-input" xs={2} sm={4}>
                      <CustomInput
                        id="appointment_status"
                        labelText={t('appointment.input.status')}
                        value={
                          selectedAppointment
                            ? t(`appointment.table.status.${selectedAppointment.appointmentStatus}`)
                            : null
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input" xs={2}>
                      <ValidationInput
                        id="init_date"
                        disabled={true}
                        text={t('appointment.input.init_date')}
                        value={
                          selectedAppointment
                            ? moment(selectedAppointment.createdDateTime).format('DD/MM/YYYY HH:mm:ss')
                            : null
                        }
                        formControlProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer className="appointment-detail-schedule">
                    <GridItem className="fullWidth-input validation-input" xs={12} sm={4}>
                      <ValidationInput
                        id="appointment_id"
                        classes={{
                          input: "fullWidth-input"
                        }}
                        fullWidth
                        text={t('appointment.input.created_user')}
                        disabled={true}
                        className="fullWidth-input"
                        value={
                          selectedAppointment && selectedAppointment.creationEmployee
                            ? `${selectedAppointment.creationEmployee.firstName} ${selectedAppointment.creationEmployee.lastName}`
                            : '-'
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input validation-input" xs={2} sm={4}>
                      <CustomInput
                        id="clientId"
                        labelText={t('appointment.input.clientId')}
                        value={
                          selectedAppointment && selectedAppointment.applicationClient ?
                            t(`${selectedAppointment.applicationClient}`) : '-'
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input" xs={2}>
                      <CustomInput
                        id="foreignId"
                        labelText={t('appointment.input.foreignId')}
                        value={
                          selectedAppointment && selectedAppointment.foreignId ?
                            selectedAppointment.foreignId : '-'
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              : ''
            }
          </GridItem>

          <GridItem className="schedule-form" xs={12} sm={12} md={6}>
            <SchedulerService.CustomerFormService
              onFilledCustomer={(value) => { this.setState({ customerId: value.customer, addressId: value.address, zoneId: value.zone }) }}
              value={selectedAppointment ? this.getCustomer() : null}
              disabled={viewMode !== 'copy'}
            />
          </GridItem>
          <SchedulerService.ProviderFormService
            onFilledProvider={value => this.setProviderF(value)}
            onFilledFinancier={value => this.setFinancier(value)}
            value={selectedAppointment ? this.getProvider() : null}
            disabled={viewMode !== 'copy'}
          />
          <GridItem xs={12}>
            <Card className="authorizer-diagnostic-forms">
              <CardHeader icon className={classes.cardHeader}>
                <CardIcon color="primary">
                  <HealthBook />
                </CardIcon>
                <h3 className="card-icon-title">{t('appointment.clinicalData')}</h3>
              </CardHeader>
              <CardBody>
                <SchedulerService.AuthorizerFormService
                  disabled={(viewMode !== 'edit' && viewMode !== 'copy') || (viewMode === 'edit' && authorityAgenda)}
                  value={{
                    metadata: metadata && metadata.medicalData,
                  }}
                  onFilledAuthorizer={(value) => this.setAuthorizerMetadata(value)}
                />
                <SchedulerService.DisabilityFormService
                  disabled={(viewMode !== 'edit' && viewMode !== 'copy') || (viewMode === 'edit' && authorityAgenda)}
                  value={{
                    metadata: metadata && metadata.disability,
                  }}
                  onFilledDisability={(value) => this.setDisabilityMetadata(value)}
                />
                <SchedulerService.DiagnosticFormService
                  disabled={(viewMode !== 'edit' && viewMode !== 'copy') || (viewMode === 'edit' && authorityAgenda)}
                  observation={metadata.diagnostic && metadata.diagnostic.observations}
                  barthel={metadata.diagnostic && metadata.diagnostic.barthelIndex}
                  accessor={'id'}
                  complexity={metadata.diagnostic && metadata.diagnostic.complexity}
                  patology={metadata.diagnostic && metadata.diagnostic.principalDiagnostic}
                  hospitalPatology={metadata.diagnostic && metadata.diagnostic.hospitalizationDiagnostic}
                  onFilledPatology={(value) => this.setDiagnostic(value, 'principalDiagnostic')}
                  onFilledHospitalPatology={(value) => this.setDiagnostic(value, 'hospitalizationDiagnostic')}
                  onFilledBarthel={(value) => this.setDiagnostic(value, 'barthelIndex')}
                  onFilledComplexity={(value) => this.setDiagnostic(value, 'complexity')}
                  onFilledObservation={(value) => this.setDiagnostic(value, 'observations')}
                />
                {(viewMode === 'edit' && !authorityAgenda) &&
                  <SaveChanges
                    setNewInitialState={saveChangesDisabled}
                    disabled={saveChangesDisabled || !_.get(this.state, 'metadata.diagnostic.principalDiagnostic.id', _.get(this.state, 'metadata.diagnostic.principalDiagnostic.recordId')) || !_.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.id', _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.recordId'))}
                    undoText={t('label.undo')}
                    applyText={t('saveChanges.apply')}
                    observedValues={[
                      _.get(this.state, 'metadata.diagnostic.observations'),
                      _.get(this.state, 'metadata.diagnostic.barthelIndex'),
                      _.get(this.state, 'metadata.diagnostic.complexity'),
                      _.get(this.state, 'metadata.diagnostic.principalDiagnostic.id',
                        _.get(this.state, 'metadata.diagnostic.principalDiagnostic.recordId')),
                      _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.id',
                        _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.recordId')),
                      _.get(this.state, 'metadata.medicalData'),
                      _.get(this.state, 'metadata.disability')
                    ]}
                    onChangeDetected={() => {
                      this.setState({
                        saveChangesDisabled: false
                      })
                    }}
                    onSaveChanges={() => {
                      this.confirmSaveMetadata();
                    }}
                    onUndoChanges={(initialState) => {
                      this.undoChangesMetadata(initialState);
                    }}
                  />
                }
              </CardBody>
            </Card>
          </GridItem>

          {viewPracticesModulesTable &&
            <SchedulerService.ScheduleDetailService
              onFilledSchedule={value => {
                this.setState({ schedule: value });
              }}
              onDeletePracticeModule={(deleted, appointmentItemRequestId) => {
                this.deletePractices(deleted, appointmentItemRequestId);
              }}
              onNewPracticeModuleAdded={(value, valueRaw) => {
                this.saveNewPractices(value, valueRaw);
              }}
              onUpdatePlanCopy={(value) => {
                this.reemplacePractices(value[0]);
              }}
              disabled={!((viewMode === 'edit' || viewMode === 'copy') && selectedAppointment.appointmentStatus === 'CREATED')}
              hasschedules={events != null && events.length > 0}
              customer={customer}
              dates={{
                startDate: (viewMode === 'copy' && provider ? provider.dateStart : selectedAppointment.startDate),
                endDate: (viewMode === 'copy' && provider ? provider.dateEnd : selectedAppointment.endDate),
              }}
              action={location.state.mode}
              appointmentId={params.id}
              checkInvalid={checkInvalid}
              items={viewMode !== 'copy' ? items : itemsCopy}
              value={events}
              viewMode={viewMode}
              onCalculatePractices={(value) => {
                this.setState({myPractices: value})}}
              appointmentStatus={selectedAppointment.appointmentStatus}
              setProvider={this.setProvider}
              provider={selectedAppointment ? this.getProvider() : null}
            />
          }

          {events.length > 0 && viewMode !== 'copy' && (
            <GridItem xs={12}>
              <Card>
                <CardHeader icon>
                  <CardIcon color="rose">
                    <CalendarToday />
                  </CardIcon>
                  <h3 className="card-icon-title">{t('appointment.agenda')}</h3>
                  {(selectedSchedules && selectedSchedules.filter(schedule => schedule.scheduleStatus === 'REJECTED').length > 0) ?
                    <div className="alert-rejected-card">
                      <ErrorOutlineIcon /><span className="alertRejectedText"> {t('appointment.new.schedule.rejected')}</span>
                    </div> : ''}
                </CardHeader>
                <CardBody className="calendarBody" calendar>
                  <React.Fragment>
                    <Calendar
                      showMultiDayTimes={true}
                      selectable
                      localizer={localizer}
                      events={events}
                      defaultView="month"
                      step={30}
                      scrollToTime={new Date(1970, 1, 1, 6)}
                      defaultDate={new Date()}
                      onView={event => this.onChangeView(event)}
                      onSelectEvent={event => this.handleOnSelectedEvent(event)}
                      eventPropGetter={event => this.eventColors(event)}
                      messages={{
                        next: t('common.next'),
                        previous: t('common.prev'),
                        today: t('common.today'),
                        month: t('common.month'),
                        week: t('common.week'),
                        day: t('common.day'),
                        event: t('Practice'),
                        date: t('common.date'),
                        time: t('common.hour'),
                        noEventsInRange: t('appointment.notPractices'),
                      }}
                      components={{
                        event: EventWithIcon,
                      }}
                    />
                    {location.state.mode === 'edit' ?
                      <div className="booking-errors-actions">
                        <Button
                          color="danger"
                          disabled={eventChange === false}
                          onClick={() => this.UndoChanges()}
                        >
                          {t('appointment.button.undo')}
                        </Button>
                        <Button
                          color="success"
                          onClick={() => this.saveBookings()}
                          disabled={alertStatus === 'ERROR' || eventChange == false}
                        >
                          {t('appointment.button.apply')}
                        </Button>
                      </div>
                      : <div />
                    }

                    <CustomDialog
                      title={t('appointment.scheduleDetail')}
                      maxWidth="md"
                      open={openDetail}
                      onClose={() => this.setState({ openDetail: false })}
                    >
                      <BookingDetail
                        readOnly={(scheduleStatus !== 'APPROVED'
                          && scheduleStatus !== 'PENDING_APPROVAL'
                          && scheduleStatus !== 'REJECTED')
                        }
                        event={currentEvent}
                        action={location.state.action}
                        appointmentStatus={selectedAppointment.appointmentStatus}
                        onCancel={() => this.setState({ openDetail: false })}
                        updateAppointment={appointment => this.updateAppointment(appointment)}
                        disabled={location.state.mode === 'view'}
                        errors={updateErrors}
                        status={status}
                      />
                    </CustomDialog>
                  </React.Fragment>
                </CardBody>
              </Card>
            </GridItem>
          )}

          {cancellationsCanceled.length > 0 && isCancellationsAppoStatusOk ?
            <GridItem xs={12} className="cancellations">
              <Card>
                <CardHeader icon>
                  <CardIcon color="danger">
                    <DeleteDocument />
                  </CardIcon>
                  <h3 className="card-icon-title">{t('appointment.cancellations.title')}</h3>
                </CardHeader>
                <CardBody>
                  <Table
                    filtrable={false}
                    loading={loading}
                    tableHeaderColor="primary"
                    defaultPageSize={5}
                    tableHead={[
                      { Header: '#', accessor: 'order' },
                      { Header: t('cancellations.typePn'), accessor: 'type' },
                      { Header: t('cancellations.name'), accessor: 'speciality' },
                      { Header: t('cancellations.quantity'), accessor: 'count' },
                      { Header: t('cancellations.date_init'), accessor: 'startDate' },
                      { Header: t('cancellations.date_cancel'), accessor: 'cancelDate' },
                      { Header: t('cancellations.reason'), accessor: 'reason' },
                    ]}
                    sortable={false}
                    tableData={cancellationsData}
                    colorsColls={['primary']}
                    showPaginationBottom={true}
                  />
                  <GridContainer className="cancellation-container">
                    {
                      <GridItem xs={4}>
                        <CustomInput
                          labelText={t('appointment.cancellations.type')}
                          value={selectedAppointment.appointmentStatus === 'CANCELLED' ? t('appointment.cancellations.type.total') : t('appointment.cancellations.type.partial')}
                          formControlProps={{ disabled: true, fullWidth: true }}
                        />
                      </GridItem>
                    }
                    {selectedAppointment.appointmentStatus === 'CANCELLED' &&
                      <GridItem xs={8}>
                        <CustomInput
                          labelText={t('appointment.cancellations.reason')}
                          value={cancellationReason || t('appointment.cancellations.undefined')}
                          formControlProps={{ disabled: true, fullWidth: true, }}
                        />
                      </GridItem>
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            : <div />}

          <AppointmentToolsService.Form
            assignAgenda={viewMode === 'assign_agenda'}
            viewMode={authorityAgenda ? 'view' : viewMode}
            appointmentIdToSave={appointmentId}
            appointmentId={appointmentId || params.id}
            componentAction={(authorityAgenda || viewMode === 'view' || viewMode === 'assign_agenda') ? 'view' : 'edit'}
            onDeleteAppointmentTool={onDeleteAppointmentTool}
            onGetAppointmentsTools={onGetAppointmentsTools}
            appointmentsTools={appointmentsTools}
            onBack={this.onBack}
            eventChangesPending={eventChange}
            selectedAppointment={selectedAppointment}
            onCheckAppointmentToolsEdit={onCheckAppointmentToolsEdit}
            afterAppointmentToolsSave={this.saveAppointmentTools}
            onAppointmentToolsSaveError={this.handleAppointmentToolsSaveError}
            performSave={performAppointmentToolsSave}
          />

          {viewMode === 'assign_agenda' && <GridItem xs={12} className="schedule-grid-submit justify-space-between">
            <Button onClick={() => browserHistory.push('/solicitudes')}>
              {t('appointment.new.cancel')}
            </Button>
            <Button color="info" onClick={() => this.confirmSave()}>
              {t('appointment.new.request-appointment')}
            </Button>
          </GridItem>
          }
          {viewMode === 'copy' &&
            <GridItem xs={12} className="schedule-grid-submit justify-space-between">
              <Button onClick={() => browserHistory.push('/solicitudes')}>
                {t('appointment.new.cancel')}
              </Button>
              <Button color="info" onClick={() => this.showDialogSaveNewAppointment()}>
                {t('appointment.new.save')}
              </Button>
            </GridItem>
          }

          {viewMode === 'edit' &&
            <ConfirmAlert
              className="saveChangesConfirm"
              confirmBodyText={t('saveChanges.confirm')}
              onConfirm={() => this.saveMetadata(true)}
              onClose={() => {
                this.setState({ confirmMetadataEdit: false, busy: false })
              }}
              showCancel={false}
              showConfirm={false}
              customButtons={
                <>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.setState({ confirmMetadataEdit: false, busy: false })
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <ButtonSpinner
                    onClick={() => {
                      this.setState({ busy: true })
                      this.saveMetadata(true)
                    }}
                    disabled={busy}
                    label={t('confirm')}
                    labelLoading={t('common.saving')}
                    loading={busy}
                    typeButton="submit"
                    color="success"
                  />
                </>
              }
              showConfirmWithText={confirmMetadataEdit}
            />
          }
          {this.state.showAgendaPracticeDisplay &&
            <CustomSweetAlert
              type="warning"
              className="alertAssignListPM"
              title={t('appointment.new.dialog.asign.schedule')}
              onConfirm={() => this.confirmedAssignAgendaReal()}
              confirmBtnCssClass="success"
              confirmBtnText={t('common.accept')}
              message={
                <>
                  <div className="date-before-message">
                    <p>{t('asign.schedules.practice.question.title')}</p>
                    <ul className="errors-list-alert">
                      {myPractices.map(e => 
                        <li>{e}</li>
                      )}
                    </ul>
                  </div>
                </>
              }
              showCancel={false}
            />

          }
          {confirmOpenNewAppointment &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.new.dialog.confimation.title')}
              onConfirm={() => this.saveNewSchedule()}
              onCancel={() => this.setState({ confirmOpenNewAppointment: false })}
              confirmBtnCssClass="success"
              cancelBtnCssClass="danger"
              confirmBtnText={t('appointment.new.dialog.confimation.button_confirm')}
              cancelBtnText={t('appointment.new.dialog.confimation.button_cancel')}
              message={t('appointment.new.dialog.confimation.description')}
              showCancel
            />
          }
          {this.state.showTimeOutException &&
            <CustomSweetAlert
              type="danger"
              title={t('appointment.agenda.review.scheduls.quantity.title')}
              onConfirm={() => this.setState({ showTimeOutException: false })}
              confirmBtnCssClass="danger"
              confirmBtnText={t('appointment.agenda.review.scheduls.quantity.cancel')}
              message={t('appointment.agenda.review.scheduls.quantity.message')}
              showCancel={false}
            />
          }
          {this.state.showErrorStartDayBefore &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.agenda.dialog.error_has_praticesbefore.title')}
              onConfirm={() => this.confirmedAssignAgenda()}
              onCancel={() => this.setState({ showErrorStartDayBefore: false })}
              confirmBtnCssClass="success"
              cancelBtnCssClass="danger"
              confirmBtnText={t('appointment.agenda.dialog.confimation.button_confirm')}
              cancelBtnText={t('appointment.new.dialog.confimation.button_cancel')}
              message={
                <>
                  <div className="date-before-message">
                    <p>{t('appointment.agenda.dialog.error_has_praticesbefore.message.options')}</p>
                    <ul className="errors-list-alert">
                      <li>{t('appointment.agenda.dialog.error_has_praticesbefore.message.option1')}</li>
                      <li>{t('appointment.agenda.dialog.error_has_praticesbefore.message.option2')}</li>
                    </ul>
                  </div>
                </>
              }
              showCancel
            />
          }
          {location.state.mode !== 'view' && (
            <React.Fragment>
              {openConfirmCancel &&
                <CustomSweetAlert
                  type="warning"
                  title={t('appointment.button.undo')}
                  onCancel={() => this.setState({ openConfirmCancel: false })}
                  cancelBtnCssClass="danger"
                  cancelBtnText={t('common.cancel')}
                  onConfirm={this.deleteBookings()}
                  confirmBtnCssClass="success"
                  confirmBtnText={t('common.accept')}
                />
              }

              {openConfirmChanges &&
                <CustomSweetAlert
                  type="warning"
                  className="alertModifyChanges"
                  title={t('common.modifyChanges')}
                  onCancel={() => this.setState({ openConfirmChanges: false })}
                  showCancel={false}
                  showConfirm={false}
                  message={
                    <>
                      <p>{t('appointment.messageConfirm')}</p>
                      <div className="booking-errors-actions">
                        <Button
                          color="danger"
                          onClick={() => this.setState({ openConfirmChanges: false })}
                        >
                          {t('common.cancel')}
                        </Button>
                        <ButtonSpinner
                          className="buttonSpinner"
                          loading={busy}
                          color="success"
                          onClick={() => this.saveBookings()}
                          disabled={alertStatus === 'ERROR' || busy}
                          label={t('common.save')}
                          labelLoading={t('common.saving')}
                        />
                      </div>
                    </>
                  }
                />
              }

              {alertErrorOpen &&
                <CustomSweetAlert
                  type="error"
                  className="alertErrorMessage"
                  title={t('appointment.new.appointmentReview')}
                  showConfirm={false}
                  showCancel
                  onCancel={() => this.setState({ alertErrorOpen: false })}
                  cancelBtnCssClass="danger"
                  cancelBtnText={t('common.cancel')}
                  message={<p>{alertErrorMessage}</p>}
                />
              }

              {alertOpen && alertStatus === 'ERROR' ?
                <CustomSweetAlert
                  type="error"
                  className="alertListMessages"
                  title={t('appointment.new.appointmentReview')}
                  style={{ display: "block", marginTop: "-100px" }}
                  onConfirm={() => this.setState({ alertOpen: false, showAgendaPracticeDisplay: false })}
                  confirmBtnCssClass="danger"
                  confirmBtnText={t('common.cancel')}
                  showCancel={false}
                  message={
                    bookingErrors.length > 0 &&
                    <ul className="errors-list-alert">
                      {bookingErrors.map((e) => <li>{e.detail}</li>)}
                    </ul>
                  }
                />
                : alertOpen && alertStatus === 'WARNING' ?
                  <CustomSweetAlert
                    type="warning"
                    title={t('appointment.new.appointmentReview')}
                    style={{ display: "block", marginTop: "-100px" }}
                    onConfirm={() => this.afterSave(bookings)}
                    confirmBtnCssClass="success"
                    confirmBtnText={t('common.continue')}
                    showCancel={false}
                    message={
                      bookingErrors.length > 0 &&
                      <ul className="errors-list-alert">
                        {bookingErrors.map((e) =>
                          <li>{e.detail}</li>)}
                      </ul>
                    }
                  />
                  : <div />
              }
            </React.Fragment>
          )}
        </GridContainer>
      </BeforeUnloadComponent>
    );
  }
}

AppointmentDetail.defaultProps = {
  selectedSchedules: [],
  onSaveSchedulesAppointment: () => { },
}

AppointmentDetail.propTypes = {
  selectedAppointment: PropTypes.object,
  providers: PropTypes.array,
  appointmentTools: PropTypes.array,
  selectedSchedules: PropTypes.array,
  onSelectAppointmentObject: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onGetAppointmentsTools: PropTypes.func,
  onDeleteAppointmentTool: PropTypes.func,
  onGetSchedulesAppointment: PropTypes.func,
  onSaveSchedulesAppointment: PropTypes.func
}

const styles = {
  cardHeader: {
    zIndex: '0 !important',
  }
};

export default withStyles(styles)(withTranslation()(AppointmentDetail));
