import './HospitalizationStyles.css'

import CustomInput from '../CustomInput/CustomInput'
import { Delete } from '@material-ui/icons'
import GridItem from '../Grid/GridItem'
import React from 'react'
import RegularButton from '../CustomButtons/Button.jsx'

const MedicationsForm = (props) => {
  const { removeMedication, withoutRemove, dataM, onlyView } = props

  return (
    <>
      <GridItem md={4}>
        <CustomInput
          labelText="Medicación:"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            disabled: true,
          }}
          value={dataM.medicineTypeId ? dataM.medicineTypeId.name : ''}
        />
      </GridItem>
      <GridItem md={4}>
        <CustomInput
          labelText="Presentación:"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            disabled: true,
          }}
          value={
            dataM.medicinePresentationType
              ? dataM.medicinePresentationType.name
              : ''
          }
        />
      </GridItem>
      <GridItem md={4}>
        <CustomInput
          labelText="Unidad:"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            disabled: true,
          }}
          value={dataM.medicineUnitType ? dataM.medicineUnitType.name : ''}
        />
      </GridItem>
      <GridItem md={4}>
        <CustomInput
          labelText="Dosis:"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            disabled: true,
          }}
          value={dataM.medicineDose}
          disabled={true}
        />
      </GridItem>
      <GridItem md={4}>
        <CustomInput
          labelText="Vía:"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            disabled: true,
          }}
          value={dataM.medicineViaTypeId ? dataM.medicineViaTypeId.name : ''}
        />
      </GridItem>
      <GridItem md={4}>
        <div className="delete-medication-button">
          {!onlyView && (
            <RegularButton
              onClick={() => removeMedication(dataM)}
              size="sm"
              color="danger"
            >
              {' '}
              <Delete />
            </RegularButton>
          )}
        </div>
      </GridItem>
    </>
  )
}

export default MedicationsForm
