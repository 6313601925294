import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ChipSet, Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  chipSet: {
    position: 'relative',
  }
}));

const ChipFilters = ({
  chips,
  onChangeChips,
  customers,
  filterData,
  professionals,
  appointments,
  t,
}) => {
  const classes = useStyles();

  const addFilter = (s) => {
    const chipsData = chips;
    chipsData.push(s);
    return onChangeChips(chipsData);
  }

  const onChipRemove = (s) => {
    const chipsData = chips;
    return chipsData.forEach((item, key) => {
      let label = `${item.title} '${item.name}'`;
      if (item.multiple) {
        label = `${item.title} ${item.name} `;
      }
      if (label === s) {        
        chipsData.splice(key, 1);
        onChangeChips(chipsData);
      }
    });
  }

  return (
    <ChipSet className={classes.chipSet}>
      <CustomInputChip
        selecteditems={chips}
        id='add_filter'
        label={t('common.addFilter')}
        customers={customers}
        filterData={filterData}
        professionals={professionals}
        chipsData={chips}
        appointments={appointments}
        onApply={addFilter}
      />
      {chips.map((item, key) => {
        let label = `${item.title} '${item.name}'`;
        if (item.multiple) {
          label = `${item.title} ${item.name} `;
        }
        return (
          <Chip
            key={item.id}
            className="ChipsInFilter"
            filter="true"
            selecteditems={chips}
            trailingIcon={<Cancel />}
            shouldRemoveOnTrailingIconClick={false}
            handleTrailingIconInteraction={() => onChipRemove(label)}
            id={item.id}
            label={label}
          />
        );
      })}
    </ChipSet>
  )
}

ChipFilters.defaultProps = {
  onClearFilters: () => {},
  onChange: () => {},
  chips: [],
};

ChipFilters.propTypes = {
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    chips: PropTypes.array,
}

export default withTranslation()(ChipFilters);
