import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import Snackbar from '../Snackbar/Snackbar';
import Table from "../Table/Table.jsx";
import Button from '../CustomButtons/Button';
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardIcon from "../Card/CardIcon.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardFooter from '../Card/CardFooter';
import DashboardApiInvoker from "../../api/DashboardApiInvoker";
import { FiltersService } from '../../containers/DashboardEconomicToolService';
import DashboardEconomicIcon from '../../icons/DashboardEconomicIcon';
import '../../assets/plugins/react-charts.css';
import './Dashboard.css';


class DashboardEconomicTool extends Component {
    constructor(props) {
        super(props);

        this.state = {
            originalquery: [],
            tools: [],
            dateFrom: '',
            dateTo: '',
            clear: false,
            financiers: [],
            chips: [],
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
        }
    }

    componentWillReceiveProps(next) {
        if (next.financiers) {
            this.setState({
                financiers: next.financiers,
                ofinanciers: next.financiers
            })
        }
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        })
        setTimeout(() => {
            this.setState({ alertOpen: false })
            if (color === 'success') {
                this.cleanFields();
            }
        }, 5000)
    }

    exportToExcel = () => {
        const { t } = this.props;
        const fields = {
            'toolType': t('dashboardeconomictool.toolType'),
            'serialNumber': t('dashboardeconomictool.serialNumber'),
            'condition': t('dashboardeconomictool.condition'),
            'appointmentId': t('dashboardeconomictool.appointmentId'),
            'appointmentStatus': t('dashboardeconomictool.appointmentStatus'),
            'appointmentStartDate': t('dashboardeconomictool.appointmentStartDate'),
            'appointmentEndDate': t('dashboardeconomictool.appointmentEndDate'),
            'appointmentDays': t('dashboardeconomictool.appointmentDays'),
            'customer': t('dashboardeconomictool.customer'),
            'province': t('dashboardeconomictool.province'),
            'location': t('dashboardeconomictool.location'),
            'geographicZone': t('dashboardeconomictool.geographicZone'),
            'financierName': t('dashboardeconomictool.financier')
        };

        let exportData = [];
        exportData.push(fields);
        exportData = exportData.concat(this.state.tools);

        const fileName = `dashboard-economic-tools-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(exportData, { skipHeader: 1 });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data2, fileName + fileExtension);
        return;
    }

    getArrayOf = (filters, type) => {
        let ret = [];
        ret = filters.chips.filter(f =>f.code === type).name;
        return ret;
    }

    containsAny = (source, target) => {
        let result = source.filter(function (item) { return target.indexOf(item) > -1 });
        return (result.length > 0);
    }

    softFilter = (filters) => {
        let result = this.state.originalquery;
        let arra = this.getArrayOf(filters, 'toolTypes')
        let self = this;
        filters.chips.map(e => {
            result = result.filter(function (item) {
                if (e.code === 'toolTypes') {
                    return self.containsAny(item[e.code].split(','), arra);
                } else {
                    return item[e.code] === e.name;
                }
            });
        })
        this.setState({ tools: result });
    }

    setFilters(filters) {
        if (filters.start_date && filters.end_date) {
            this.setState({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
                loadingTableData: true
            })

            DashboardApiInvoker.getDashboardEconomicTools({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
            }, data => {
                this.formatTableData(data)
            }, err => {
                this.openAlert('danger', err.message + '');
                this.setState({ loadingTableData: false });
                console.error(err)
            })
        }
    }

    formatTableData(data) {
        const { t } = this.props;
        if (data) {
            let result = data.map(d => {
                return {
                    toolType: d.toolType,
                    serialNumber: d.serialNumber,
                    condition: d.condition ? `${t(`dashboardeconomictool.condition.${d.condition}`)}` : `${t(`dashboardeconomictool.undefined`)}`,
                    appointmentId: d.appointmentId,
                    appointmentStatus: `${t(`dashboardeconomictool.status.${d.appointmentStatus}`)}`,
                    appointmentStartDate: d.appointmentStartDate,
                    appointmentEndDate: d.appointmentEndDate,
                    customer: d.customer,
                    appointmentDays: d.prestationDays,
                    province: d.province,
                    location: d.location,
                    geographicZone: d.geograficZone,
                    financierName: d.financier,
                }
            })
            this.setState({
                tools: result,
                originalquery: result,
                loadingTableData: false,
            })
            this.softFilter(this.state);
        }
    }

    clearInformation() {
        this.setState({
            dateFrom: '',
            dateTo: '',
            clear: true,
            chips: [],
            tools: [],
            originalquery: []
        });
    }

    render() {
        const { t } = this.props;
        const {
            chips, loadingTableData, tools, alertColor, alertMessage, alertOpen,
        } = this.state;
        
        return (
            <div className="dashboard dashboard-economic-tools">
                <GridContainer>
                    <GridItem className="dashboard-data-container" xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <DashboardEconomicIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('dashboardeconomictool.title')}</h3>
                                <FiltersService
                                    loading={loadingTableData}
                                    chips={chips}
                                    onChangeChips={(filters) => this.softFilter(filters)}
                                    onChange={(filters) => this.setFilters(filters)}
                                    onClearFilters={() => this.clearInformation()}
                                />
                            </CardHeader>
                            <CardBody>
                                <Table
                                    loading={loadingTableData}
                                    tableHeaderColor="primary"
                                    sortable
                                    tableHead={[
                                        { Header: t('dashboardeconomictool.toolType'), accessor: 'toolType' },
                                        { Header: t('dashboardeconomictool.serialNumber'), accessor: 'serialNumber' },
                                        { Header: t('dashboardeconomictool.condition'), accessor: 'condition' },                                       
                                        { Header: t('dashboardeconomictool.appointmentId'), accessor: 'appointmentId' },
                                        { Header: t('dashboardeconomictool.appointmentStatus'), accessor: 'appointmentStatus' },
                                        { Header: t('dashboardeconomictool.appointmentStartDate'), accessor: 'appointmentStartDate' },
                                        { Header: t('dashboardeconomictool.appointmentEndDate'), accessor: 'appointmentEndDate' },
                                        { Header: t('dashboardeconomictool.appointmentDays'), accessor: 'appointmentDays' },
                                        { Header: t('dashboardeconomictool.customer'), accessor: 'customer' },
                                        { Header: t('dashboardeconomictool.province'), accessor: 'province' },
                                        { Header: t('dashboardeconomictool.location'), accessor: 'location' },
                                        // { Header: t('dashboardeconomictool.geographicZone'), accessor: 'geographicZone' },
                                        { Header: t('dashboardeconomictool.financier'), accessor: 'financierName' }
                                    ]}
                                    tableData={tools}
                                    defaultPageSize={tools.length > 5 ? 20 : 5}
                                    colorsColls={['primary']}
                                />
                            </CardBody>
                            <CardFooter className="footer-buttons buttons-right">
                                <Button className="button-export" onClick={() => this.exportToExcel()} color="success" disabled={tools.length < 1}>
                                    {t('common.exportExcel')}
                                </Button>
                            </CardFooter>
                        </Card>
                        <Snackbar
                            place="tr"
                            color={alertColor}
                            message={alertMessage}
                            open={alertOpen}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

DashboardEconomicTool.propTypes = {
    tools: PropTypes.array,
    t: PropTypes.func,
}

export default withTranslation()(DashboardEconomicTool);
