import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _, { isArray } from 'lodash';
import moment from 'moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import ViewList from '@material-ui/icons/ViewList';
import Button from '../../CustomButtons/Button';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import CardFooter from '../../Card/CardFooter';
import GridItem from '../../Grid/GridItem';
import Snackbar from '../../Snackbar/Snackbar';
import Table from '../../Table/Table';
import ConfirmAlert from '../../ConfirmAlert';
import ScheduleDetailModal from './ScheduleDetailModal';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import EmployeeApiInvoker from '../../../api/EmployeeApiInvoker';
import { useQuantityRequests } from '../../../hooks/useQuantityRequests';
import ScheduleItemActions from './ScheduleItemActions/ScheduleItemActions';
import '../NewSchedule.css';

class ScheduleDetail extends Component {
  constructor(props) {
    super(props)
    const { t } = this.props;

    this.state = {
      open: false,
      loading: false,
      dataTable: [],
      appointmentSchedules: [],
      modules: [],
      plans: [],
      deleteAlert: false,
      deleteAlertText: t('Package'),
      error: false,
      remove: '',
      current: null,
      id: 1,
      action: '',
      alertColor: 'warning',
      alertMessage: '',
      alertOpen: false,
    }
  }

  componentWillReceiveProps(next) {
    const { modules, practiceTypes, viewMode } = this.props;
    if (next.practiceTypes || next.modules) {
      this.formatDataTable(next.practiceTypes || practiceTypes, next.modules || modules)
    }
    if (next.value && next.value.length && !this.state.setAction) {
      this.formatValue(next.value);
    }
    if (next.dates) {
      this.setState({ dates: next.dates })
      this.formatDataTable(practiceTypes, modules);
    }

    if (next.items) {
      if (viewMode === 'copy') {
        this.setState({ appointmentSchedules: next.items, loading: false },
          () => {
            this.formatDataTable(practiceTypes, modules);
          });
      }
    }
  }

  getPartnership = () => {
		this.setState({third_parties: []});
		AppointmentApiInvoker.getCompanyPartnershipAll(data => {
			this.setState({ third_parties: data });
		}, (e) => {
			this.setState({ third_parties: [] });
		});
	}

  componentDidMount() {
    const { appointmentId, appointmentStatus, modules, practiceTypes, setProvider, viewMode } = this.props;
    if (appointmentStatus === 'SCHEDULES_ASSIGNED') return;
    
    this.getPartnership();

    if (appointmentId) {
      this.getPracticeTypes();
      this.getModuleTypes();
      this.setState({ loading: true });
      if (viewMode !== 'copy') {
        AppointmentApiInvoker.getAppointmentItems(appointmentId,
          (res) => {
            setProvider(res ? res[0].serviceProvider : {});
            this.setState({ appointmentSchedules: res, loading: false }, () => {
              this.formatDataTable(practiceTypes, modules, true);
            })
          }, (err) => {
            console.error('** error getAppointmentItems', err);
          })
      }
    }
  }

  getLenderName = (isHeader, data, companyPartnerShip) => {
    const { t } = this.props;
    if (typeof Number(data.companyIdPartnership)) {
      return data.companyIdPartnership;
    }
    return (
      isHeader
        ? data.companyIdPartnership.name : data.companyIdPartnership.companyId == companyPartnerShip
          ? data.companyIdPartnership.name  : t('common.other')
    )
  }

  formatedPlan = (r, foundPlan, startDate, index, disabled) => {
    const { action, viewMode, t } = this.props;
    let isMePM = false;
    let isHeader = false;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    
    if (companyPartnerShip == null) {
      isHeader = true;
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }
    if (r.companyIdPartnership.companyId == companyPartnerShip) {
      isMePM = true;
    }

    let lenderName = null;
    if (viewMode != undefined) {
      lenderName = (isHeader ? r.companyIdPartnership.name : r.companyIdPartnership.companyId == companyPartnerShip ? r.companyIdPartnership.name  : t('common.other'));
    } else {
      const found = this.state.third_parties.filter(c => c.companyIdPartnership.companyId == r.companyIdPartnership);
      if (found.length > 0) {
        lenderName = found[0].companyIdPartnership.name;
      } else {
        lenderName =localStorage.getItem('itlg_default_company_name');
      }
    }
    const iStatus = r.appointmentItemRequestStatus ? r.appointmentItemRequestStatus : 'CREATED';
    const iStatusName = t(`status.appointmentItemRequest.${iStatus}`);

    return {
      'practiceTypeId': r.practiceTypeId,
      'startTime': r.startTime,
      'endTime': r.endTime,
      'specialty': foundPlan.name,
      'appointmentItemRequestStatus': r.appointmentItemRequestStatus,
      'name': t('Practice'),
      'start_date': (startDate && startDate !== '' ? startDate.format('DD/MM/YYYY') : ''),
      'startDate': (r.startDate && r.startDate !== '' ? r.startDate : ''),
      'endDate': (r.endDate && r.endDate !== '' ? r.endDate : ''),
      'component': 'plan',
      'quantity': r.quantityBefore,
      'companyIdPartnership': r.companyIdPartnership,
      'itemRequestDetails': r.itemRequestDetails,
      'status': iStatusName,
      'lender': lenderName,
      'actions': (action !== 'view')
        && (isMePM || action === 'create' || (isMePM && action === 'assign_agenda') || isHeader)
        && (((isHeader && r.appointmentItemRequestStatus === 'CREATED') || (isMePM && r.appointmentItemRequestStatus === 'APROVEED')) || r.appointmentItemRequestStatus === undefined)
        && <ScheduleItemActions
          hideRemove={(action === 'assign_agenda')}
          disabled={disabled}
          onEdit={() => {
            this.setState({ open: true, current: this.getCurrentEdit(r, 'plan', r.appointmentItemRequestId), action: 'edit' })
          }}
          onDelete={() => {
            const orderItem = (viewMode == null || viewMode === 'copy') ? r.order : r.appointmentItemRequestId;
            this.selectToBeRemoved(index + 1, 'practice', orderItem);
          }}
        />,
      'plan': foundPlan,
      'recurrence': foundPlan.recurrence,
      'id': index + 1,
      'idserver': foundPlan.order,
      'order': foundPlan.order ? foundPlan.order : index + 1,
    }
  }

  formatedModule = (r, foundModule, startDate, disabled, index) => {
    const { action, viewMode, t } = this.props;

    let isMePM = false;
    let isHeader = false;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    if (companyPartnerShip == null) {
      isHeader = true;
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }
    if (r.companyIdPartnership.companyId == companyPartnerShip) {
      isMePM = true;
    }
    let lenderName = null;
    if (viewMode != undefined) {
      lenderName = (isHeader ? r.companyIdPartnership.name : r.companyIdPartnership.companyId == companyPartnerShip ? r.companyIdPartnership.name  : t('common.other'));
    } else {
      const found = this.state.third_parties.filter(c => c.companyIdPartnership.companyId == r.companyIdPartnership);
      if (found.length > 0) {
        lenderName = found[0].companyIdPartnership.name;
      } else {
        lenderName =localStorage.getItem('itlg_default_company_name');
      }
    }

    const iStatus = r.appointmentItemRequestStatus ? r.appointmentItemRequestStatus : 'CREATED';
    const iStatusName = t(`status.appointmentItemRequest.${iStatus}`);

    return {
      'startTime': r.startTime,
      'endTime': r.endTime,
      'packageId': r.packageId,
      'name': t('Package'),
      'specialty': foundModule.name,
      'start_date': (startDate && startDate !== '' ? startDate.format('DD/MM/YYYY') : ''),
      'startDate': (r.startDate && r.startDate !== '' ? r.startDate : ''),
      'module': foundModule.module,
      'itemRequestDetails': r.itemRequestDetails,
      'packageQuantity': r.packageQuantity,
      'quantity': r.packageQuantity ? r.packageQuantity : 1,
      'companyIdPartnership': r.companyIdPartnership,
      'appointmentItemRequestStatus': r.appointmentItemRequestStatus,
      'status': iStatusName,
      'lender': lenderName,
      'actions': (action !== 'view')
      && (isMePM || action === 'create' || (isMePM && action === 'assign_agenda') || isHeader)
      && (((isHeader && r.appointmentItemRequestStatus === 'CREATED') || (isMePM && r.appointmentItemRequestStatus === 'APROVEED')) || r.appointmentItemRequestStatus === undefined)
      && <ScheduleItemActions
          hideRemove={(action === 'assign_agenda')}
          disabled={disabled}
          onEdit={() => {
            this.setState({ open: true, current: this.getCurrentEdit(r, 'module', r.appointmentItemRequestId), action: 'edit' })
          }}
          onDelete={() => {
            const orderItem = viewMode === 'copy' ? r.order : r.appointmentItemRequestId;
            this.selectToBeRemoved(index + 1, 'module', orderItem);
          }}
        />,
      'id': index + 1,
      'idserver': foundModule.order,
      'order': foundModule.order ? foundModule.order : index + 1,
    }
  };

  formatDataTable = (plansProps = [], modulesProps = [], calculate) => {
    const { appointmentSchedules, dates } = this.state;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    if (companyPartnerShip == null) {
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }

    if (appointmentSchedules && appointmentSchedules.length) {
      const plans = [];
      const modules = [];
      let dataTable = [];
      appointmentSchedules.forEach((r, index) => {
        let startDate = '';
        if (r.startDate !== '') {
          startDate = moment(r.startDate);
        }

        // Practice
        let disabled = true;
        if (dates.startDate && dates.startDate != '' && dates.endDate && dates.endDate != '') {
          disabled = false;
        }

        if (r.practiceTypeId) {
          const foundPlan = (plansProps.find((p) => p.practiceTypeId === r.practiceTypeId));
          if (!foundPlan) return;

          if (foundPlan.recurrence && foundPlan.recurrence.endType === 'OCURRENCES') {
            foundPlan.recurrence.finishOcurrences = foundPlan.recurrence.ocurrences;
          }
          const recurrence = r.schedulePlan;
          if (!recurrence.hasOwnProperty('finishOcurrences')) {
            recurrence.finishOcurrences = (recurrence.ocurrences ? recurrence.ocurrences : null);
          }
          if (r.endDate && r.endDate != null) {
            recurrence.finish = '3';
          }
          if (recurrence.endType === 'DATE' && r.endDate == null) {
            recurrence.finish = '1';
          }
          if (recurrence.endType === 'OCURRENCES') {
            recurrence.finish = '2';
          }

          // Ajustamos el recurrence de las INTRADAYS
          if (recurrence.processingScheduleType && recurrence.processingScheduleType === 'INTRADAY') {
            recurrence.recurrenceType = 'INTRADAY';
            recurrence.quantityIntraday = recurrence.processingDurationTime / foundPlan.estimatedDuration;
          }
          const [quantity] = useQuantityRequests(foundPlan, startDate, r.endDate, this.props.dates.endDate); 
          foundPlan.recurrence = recurrence;
          foundPlan.startTime = r.startTime;
          foundPlan.endTime = r.endTime;
          foundPlan.id = foundPlan.practiceTypeId;
          foundPlan.companyIdPartnership = r.companyIdPartnership;
          foundPlan.appointmentItemRequestStatus = r.appointmentItemRequestStatus;
          foundPlan.order = r.order;
          r.quantityBefore = quantity;
          
          const formatedPlan = this.formatedPlan(r, foundPlan, startDate, index, disabled);
          plans.push(formatedPlan);
          dataTable.push(formatedPlan);

        } else {
          // Module
          const foundModule = (modulesProps.find((p) => p.packageId === r.packageId));
          if (!foundModule) return;

          foundModule.startTime = r.startTime;
          foundModule.endTime = r.endTime;
          foundModule.id = foundModule.packageId;
          foundModule.order = r.order;
          foundModule.companyIdPartnership = r.companyIdPartnership;
          foundModule.appointmentItemRequestStatus = r.appointmentItemRequestStatus;
          const formatedModule = this.formatedModule(r, foundModule, startDate, disabled, index);
          formatedModule.itemRequestPackageDetails = r.itemRequestPackageDetails;

          modules.push(formatedModule);
          dataTable.push(formatedModule);
        }
      });

      dataTable = dataTable.sort((a, b) => a.order - b.order);
      let myPractices = [];
      if (calculate) {
        dataTable.forEach(e => {
          if (e.companyIdPartnership.companyId == companyPartnerShip && e.appointmentItemRequestStatus == 'APROVEED') {
            myPractices.push(e.specialty);
          }
        });
        this.props.onCalculatePractices(myPractices);
      }
      return this.setState({ plans, dataTable, modules, id: appointmentSchedules.length, plansProps, modulesProps });
    }
  }

  getCurrentEdit = (v, type, appointmentItemRequestId) => {
    v.module = (type === 'module');
    v.appointmentItemRequestId = appointmentItemRequestId;
    return v;
  }

  findItem = (value, type) => {
    if (type === 'module') {
      return this.state.appointmentSchedules.find((r) => {
        return r.packageId === value.packageId
      });
    } else {
      return this.state.appointmentSchedules.find((r) => {
        return r.practiceTypeId === value.practiceTypeId && r.order === value.order;
      });
    }
  }

  formatValue(value) {
    value.map(v => {
      if (!v.actions) {
        v.actions = (<ScheduleItemActions
          onEdit={() => {
            this.setState({ open: true, current: v, action: 'edit' })
          }}
          onDelete={() => {
            this.selectToBeRemoved(v.id, v.plan ? 'practice' : 'module')
          }}
        />)
      }
      if (v.plan) {
        this.setState(prev => ({
          setAction: true,
          plans: (_.cloneDeep(prev.plans) || []).concat([v]),
          dataTable: (_.cloneDeep(prev.dataTable) || []).concat([v]),
          id: prev.id + 1
        }))
      } else {
        this.setState(prev => ({
          setAction: true,
          modules: (_.cloneDeep(prev.modules) || []).concat([v]),
          dataTable: (_.cloneDeep(prev.dataTable) || []).concat([v]),
          id: prev.id + 1
        }))
      }
    })
  }

  __getMaxOrder() {
    let maxorder = 0;
    for (let idx = 0; idx < this.state.appointmentSchedules.length; idx++) {
      if (this.state.appointmentSchedules[idx].order > maxorder) {
        maxorder = this.state.appointmentSchedules[idx].order;
      }
    }
    return maxorder + 1;
  }

  addModule(module) {
    if (this.props.viewMode === 'copy') {
      const m = this.formatAppointmentScheduleItem({ module: module });
      this.props.onNewPracticeModuleAdded(m, null)
      return;
    }

    const isChange = this.state.current && !this.state.current.module;
    if (!this.state.current || !this.state.current.module) {
      module.id = isChange ? this.state.current.order : this.state.dataTable.length + 1;
      const newModule = this.generateModuleItem(module);
      if (!isChange) {
        newModule.id = this.__getMaxOrder();
      }
      newModule.packageQuantity = this.state.packageQuantity;
      let newDataTable = _.cloneDeep(this.state.dataTable);
      let modules = _.cloneDeep(this.state.modules);
      let newAppointementScheduleItems = [];

      let appointmentScheduleItem = this.formatAppointmentScheduleItem(newModule);
      if (isChange) {
        newDataTable = this.replaceDataTable(module.id, newModule);
        modules.push(newModule);
        newAppointementScheduleItems = this.state.appointmentSchedules.map((app) => {
          if (app.order === appointmentScheduleItem.order) {
            return appointmentScheduleItem;
          } else {
            return app;
          }
        })
      } else {
        newDataTable.push(newModule);
        modules.push(newModule);
        newAppointementScheduleItems = (_.cloneDeep(this.state.appointmentSchedules) || []).concat([appointmentScheduleItem]);
      }
      this.setState({
        current: null,
        modules,
        dataTable: newDataTable,
        id: newDataTable.length + 1,
        appointmentSchedules: newAppointementScheduleItems,
      }, () => {

        this.props.onNewPracticeModuleAdded(appointmentScheduleItem, this.linkToItemSchedule(this.state.appointmentSchedules.length - 1))
        this.props.onFilledSchedule(newDataTable)
      }
      )
      setTimeout(() => {
        this.closeAlert();
      }, 100);
    } else {
      this.editModule(module);
    }
  }

  editModule(module) {
    const editModule = this.generateModuleItem(module);
    const modules = _.cloneDeep(this.state.modules);
    const appointmentScheduleItem = this.formatAppointmentScheduleItem(editModule);

    const newAppointementScheduleItems = this.state.appointmentSchedules.map((app) => {
      if (app.order === appointmentScheduleItem.order) {
        return appointmentScheduleItem
      } else {
        return app;
      }
    })

    const modulesUpdated = modules.map((m, i) => {
      if (m.id === module.id) {
        this.editDataTable(editModule);
        return editModule;
      } else {
        return m;
      }
    });

    this.setState({
      modules: modulesUpdated,
      dataTable: this.replaceDataTable(editModule.id, editModule),
      appointmentSchedules: newAppointementScheduleItems,
    });
  }

  generateModuleItem(module) {
    const startDate = moment(module.startDate)
    const moduleDetails = this.props.modules.find(m => m.packageId === module.module.id);
    const quantity = _.get(moduleDetails, 'packageDetail.0.schedulePlan.ocurrences', 1);
    return {
      module: module,
      id: module.id,
      name: this.props.t('Package'),
      itemRequestDetails: module.itemRequestDetails,
      itemRequestPackageDetails: module.itemRequestPackageDetails,
      specialty: module.module.name,
      start_date: startDate.format('DD/MM/YYYY'),
      companyIdPartnership: module.companyIdPartnership,
      quantity,
      actions: (<ScheduleItemActions
        onEdit={() => {
          this.setState({ open: true, current: module, action: 'edit' })
        }}
        onDelete={() => {
          this.selectToBeRemoved(module.id, 'module')
        }}
      />),
    }
  }

  //mlgmlg2
  updatePlan(items) {
    if (this.props.viewMode != 'copy') {
      this.setState({ loading: true });
      const sprov = this.state.appointmentSchedules && this.state.appointmentSchedules.length > 0 ? this.state.appointmentSchedules[0].serviceProvider : {};
      if (sprov) {
        items.map(item => item.serviceProvider = sprov);
      }
      EmployeeApiInvoker.postAppointmentScheduleItems(this.props.appointmentId, items, data => {
        this.getPracticeTypes();
        this.getModuleTypes();
        AppointmentApiInvoker.getAppointmentItems(this.props.appointmentId,
          (res) => {
            this.setState({ appointmentSchedules: res }, () => {

              this.formatDataTable(this.state.plansProps, this.state.modulesProps);
              this.setState({ loading: false });
            })
          }, (err) => {
            console.error(err);
            this.setState({ loading: false });
          }
        )
      }, error => {
        this.setState({ loading: false });
      })

    } else {
      this.props.onUpdatePlanCopy(JSON.parse(JSON.stringify(items)));
    }
  }

  formatSchedulePlan(schedulePlan) {
    return {
      endType: schedulePlan.recurrence.finish === '2' ? 'OCURRENCES' : schedulePlan.recurrence.finish === '1' ? 'DATE' : schedulePlan.recurrence.finish === '3' ? 'DATE' : null,
      every: schedulePlan.recurrence.every,
      monthDay: schedulePlan.recurrence.monthRecurrence || null,
      ocurrences: schedulePlan.recurrence.finishOcurrences || null,
      recurrenceType: schedulePlan.recurrence.recurrenceType || null,
      quantityIntraday: schedulePlan.recurrence.quantityIntraday || null,
      processingDurationTime: schedulePlan.recurrence.processingDurationTime,
      processingScheduleType: schedulePlan.recurrence.processingScheduleType,
      weekDays: schedulePlan.recurrence.weekRecurrence
        ? isArray(schedulePlan.recurrence.weekRecurrence) ? schedulePlan.recurrence.weekRecurrence : schedulePlan.recurrence.weekRecurrence.join(',')
        : null,
    }
  }

  formatAppointmentScheduleItem(s) {
    const { provider, items } = this.props;

    return {
      order: s.id,
      serviceProvider: {
        serviceProviderId: (provider && provider.provider.serviceProviderId ? provider.provider.serviceProviderId : (items && items.length > 0 ? items[0].serviceProvider.serviceProviderId : null))
      },
      startDate: s.plan ? s.plan.startDate : s.module.startDate,
      endDate: s.plan ? s.plan.endDate : s.module.endDate,
      endTime: s.plan ? s.plan.endTime : s.module.endTime,
      startTime: s.plan ? s.plan.startTime : s.module.startTime,
      packageId: s.module ? s.module.module.packageId : null,
      packageQuantity: s.module ? s.module.packageQuantity : null,
      practiceTypeId: s.plan ? s.plan.plan.id : null,
      companyIdPartnership: s.plan ? s.plan.companyIdPartnership : s.module.companyIdPartnership,
      itemRequestDetails: s.plan ? s.plan.itemRequestDetails : s.module.itemRequestDetails,
      itemRequestPackageDetails: s.module && s.module.itemRequestPackageDetails ? s.module.itemRequestPackageDetails : null,
      schedulePlan: s.plan ? this.formatSchedulePlan(s.plan) : null,
    }
  }

  addPlan(plan) {
    if (this.props.viewMode === 'copy') {
      this.props.onNewPracticeModuleAdded(this.formatAppointmentScheduleItem({ plan: plan }), null)
      return;
    }
    let isChange = this.state.current && !this.state.current.plan;
    if (!this.state.current || !this.state.current.plan) {
      plan.id = isChange ? this.state.current.order : this.state.dataTable.length + 1;
      const newPlan = this.generatePlanItem(plan);

      if (!isChange) {
        newPlan.id = this.__getMaxOrder();
      }
      let newPlans = _.cloneDeep(this.state.plans);
      let newDataTable = _.cloneDeep(this.state.dataTable);
      let appointmentScheduleItem = this.formatAppointmentScheduleItem(newPlan)
      let newAppointementScheduleItems = [];

      if (isChange) {
        newDataTable = this.replaceDataTable(plan.id, newPlan);
        newPlans.push(newPlan);

        newAppointementScheduleItems = this.state.appointmentSchedules.map((app) => {
          if (app.order === appointmentScheduleItem.order) {
            return appointmentScheduleItem
          } else {
            return app;
          }
        })
      } else {
        newDataTable.push(newPlan);
        newPlans.push(newPlan);
        newAppointementScheduleItems = (_.cloneDeep(this.state.appointmentSchedules) || []).concat([appointmentScheduleItem]);
      }

      this.setState({
        current: null,
        plans: newPlans,
        dataTable: newDataTable,
        id: newDataTable.length + 1,
        appointmentSchedules: newAppointementScheduleItems,
      },
        () => {
          this.props.onNewPracticeModuleAdded(appointmentScheduleItem, this.linkToItemSchedule(this.state.appointmentSchedules.length - 1))
          this.props.onFilledSchedule(newDataTable)
        }
      )
    } else {
      this.editPlan(plan);
    }
  }

  //mlgmlg3
  linkToItemSchedule(index) {
    return (newItemSchedule) => {
      this.getPracticeTypes();
      this.getModuleTypes();
      AppointmentApiInvoker.getAppointmentItems(this.props.appointmentId,
        (res) => {
          this.setState({ appointmentSchedules: res }, () => {

            this.formatDataTable(this.state.plansProps, this.state.modulesProps);
            this.setState({ loading: false });
          })
        }, (err) => {
          console.error(err);
          this.setState({ loading: false });
        }
      )
    }
  }
   
  editPlan(plan) {
    const plansUpdated = this.state.plans.map((p, i) => {
      if (p.id === plan.id) {
        const newPlan = this.generatePlanItem(plan);
        this.editDataTable(newPlan);
        return newPlan;
      } else {
        return p;
      }
    });

    this.setState({ plans: plansUpdated });
  }

  generatePlanItem(plan) {
    const startDate = moment(plan.startDate);
    const [quantity] = useQuantityRequests(plan, startDate, null, this.props.dates.endDate);
    return {
      plan: plan,
      id: plan.id,
      name: this.props.t('Practice'),
      specialty: plan.plan.value,
      start_date: startDate.format('DD/MM/YYYY'),
      quantity,
      actions: (
        <ScheduleItemActions
          onEdit={() => {
            this.setState({ open: true, current: plan, action: 'edit' });
          }}
          onDelete={() => {
            this.selectToBeRemoved(plan.id, 'practice');
          }}
        />
      ),
    }
  }

  editDataTable(row) {
    let current = this.state.dataTable;
    this.state.dataTable.map((dt, i) => {
      if (row.id === dt.id) {
        current[i] = row;
        this.setState(
          {
            dataTable: current,
            current: null,
          }, () => {
            this.props.onFilledSchedule(this.state.dataTable);
          }
        );
      }
    });
  }

  /**
   * Delete item related functions
   */
  // 1. Set id of item to be removed and opens delete confirmation
  selectToBeRemoved(id, type, appointmentItemRequestId) {
    this.setState({
      remove: id,
      appointmentItemRequestIdToDelete: appointmentItemRequestId,
      deleteAlertText: type === 'module' ? this.props.t('appointment.new.schedule.module') : this.props.t('appointment.new.schedule.practice'),
    }, () => {
      this.setState({ deleteAlert: true });
    })
  }

  // 2. Lookup of item to be removed
  removeItem() {
    const { t } = this.props;
    if (this.props.action === 'edit' && this.state.dataTable.length === 1) {
      this.setState({
        alertMessage: t('practicesModules.isNotSave'),
        alertOpen: true,
      })
      return setTimeout(() => {
        this.setState({ alertOpen: false });
      }, 2000);
    }
    const itemToRemove = this.state.dataTable.find(dt => {
      return dt.id === this.state.remove;
    });
    if (!itemToRemove) {
      return;
    }
    if (itemToRemove.plan) {
      this.remove('plans');
    } else {
      this.remove('modules');
    }
    //mlgmlg
    this.props.onDeletePracticeModule(itemToRemove, this.state.appointmentItemRequestIdToDelete);
  }

  // 3. Actual remove of the item from the state
  remove(type) {
    const current = this.state[type];
    this.state[type].map((c, i) => {
      if (c.id === this.state.remove) {
        current.splice(i, 1)
        this.setState({
          [type]: current,
          remove: '',
        })
      }
    })
    this.removeDataTable(this.state.remove);
  }

  // 4. Updates the table
  removeDataTable(id) {
    const current = this.state.dataTable.filter((dt, i) => dt.id !== id);
    const appointmentSchedulesFiltered = this.state.appointmentSchedules.filter((appS, i) => {
      if (this.props.viewMode == 'edit') {
        return (appS.appointmentItemRequestId !== this.state.appointmentItemRequestIdToDelete);
      } else {
        let order = appS.order;
        return (order != id);

      }
    });
    this.setState({
      dataTable: current,
      appointmentSchedules: appointmentSchedulesFiltered,
      id: current.length,
    }, () => {
      this.props.onFilledSchedule(this.state.dataTable);
      setTimeout(() => {
        this.closeAlert();
      }, 100);
    })
  }

  replaceDataTable(id, item) {
    const currentDataTable = this.state.dataTable;
    const newDataTable = currentDataTable.filter(a => a.id !== id).concat([item]);
    return newDataTable;
  }

  closeAlert() {
    this.setState({
      error: false,
      deleteAlert: false,
      current: null,
      isModule: false,
    });
  }

  getPracticeTypes() {
    AppointmentApiInvoker.getGenericPracticeTypes(practiceTypes => {
      this.props.onFetchPracticeTypes(practiceTypes)
    }, null);
  }

  getModuleTypes() {
    AppointmentApiInvoker.getPackagesAll(data => {
      if (data && data.length) {
        this.props.onFetchModules(data);
      }
    }, null);
  }

  renderTableHead = (appointmentStatus) => {
    const { t } = this.props;
    const headerDefault = [
      { Header: t('appointment.new.schedule.order'), accessor: 'idserver' },
      { Header: t('appointment.new.schedule.lender'), accessor: 'lender' },
      { Header: t('appointment.new.schedule.type'), accessor: 'name' },
      { Header: t('appointment.new.schedule.name'), accessor: 'specialty' },
      { Header: t('appointment.new.schedule.status'), accessor: 'status' },
      { Header: t('appointment.new.schedule.start_date'), accessor: 'start_date' },
      { Header: t('appointment.new.schedule.quantity'), accessor: 'quantity' },
    ];
    if (this.props.viewMode !== 'view') {
      headerDefault.push({ Header: t('appointment.new.schedule.actions'), accessor: 'actions', });
    }

    switch (appointmentStatus) {
      case 'SCHEDULES_ASSIGNED':
        return [
          { Header: t('appointment.new.schedule.order'), accessor: 'order' },
          { Header: t('appointment.new.schedule.lender'), accessor: 'lender' },
          { Header: t('appointment.new.schedule.type'), accessor: 'practiceModule' },
          { Header: t('appointment.new.schedule.name'), accessor: 'practiceTypeLabel' },
          { Header: t('appointment.new.schedule.status'), accessor: 'status' },
          { Header: t('appointment.new.schedule.start_date'), accessor: 'startDate' },
          { Header: t('appointment.new.schedule.quantity'), accessor: 'quantity' },
        ];

      default:
        return headerDefault;
    }
  }

  render() {
    const { t, appointmentId, appointmentStatus, items, disabled, checkInvalid, dates, action, customer, customerAddress } = this.props;
    const { alertColor, alertMessage, alertOpen, appointmentSchedules, loading, dataTable, deleteAlert, error, current, open } = this.state;
    const isLoading = appointmentStatus === 'SCHEDULES_ASSIGNED' ? items && items.length === 0 : loading;
    const data = appointmentStatus === 'SCHEDULES_ASSIGNED' ? items : dataTable;
    const tableHead = this.renderTableHead(appointmentStatus);

    return (
      <GridItem className="table schedule-detail full-width">
        <Card>
          <CardHeader icon>
            <CardIcon color="rose">
              <ViewList />
            </CardIcon>
            <h3 className="card-icon-title">{t('appointment.new.schedule.title')}</h3>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              pageSizeOptions={[5, 10, 25, 50, 100]}
              defaultPageSize={5}
              sortable={true}
              loading={isLoading}
              tableHead={tableHead}
              tableData={data}
              colorsColls={['primary']}
            />
          </CardBody>
          {!disabled && !loading &&
            <CardFooter xs={12} className={`${checkInvalid && !(dataTable && dataTable.length) ? 'schedule-form-submit invalid-text' : 'schedule-form-submit'}`}>
              {checkInvalid && !(dataTable && dataTable.length) && (
                <FormHelperText className="helper-error">
                  {t('error.required')}
                </FormHelperText>
              )}
              <Tooltip
                title={
                  !dates.startDate || !dates.endDate
                    ? t('appointment.new.warningDates')
                    : ''
                }
              >
                <Button
                  onClick={() => this.setState({ open: true, action: 'create', current: null })}
                  color="success"
                  disabled={!dates || !dates.startDate || !dates.endDate}
                >
                  <Add />
                </Button>
              </Tooltip>
            </CardFooter>
          }
        </Card>

        <ScheduleDetailModal
          onGetModuleTypes={this.getModuleTypes.bind(this)}
          onGetPracticeTypes={this.getPracticeTypes.bind(this)}
          current={current}
          open={open}
          action={this.state.action}
          actionAppointment={action}
          items={appointmentSchedules}
          customer={customer}
          appointmentId={appointmentId}
          dates={dates}
          onCloseModal={() => this.setState({ open: false, current: null })}
          onCreatedModule={value => this.addModule(value)}
          onCreatedPlan={value => this.addPlan(value)}
          onUpdatePlan={value => this.updatePlan(value)}
          customerAddress={customerAddress}
        />

        <ConfirmAlert
          text={this.state.deleteAlertText}
          onClose={() => this.closeAlert()}
          onConfirm={() => this.removeItem()}
          showWithoutConfirmation={deleteAlert}
          error={error}
        />

        <Snackbar
          place="tr"
          color={alertColor}
          message={alertMessage}
          open={alertOpen}
        />
      </GridItem>
    )
  }
}

ScheduleDetail.defaultProps = {
  dates: {
    startDate: null,
    endDate: null,
  },
  onFilledSchedule: () => { },
  onNewPracticeModuleAdded: () => { },
  onDeletePracticeModule: () => { },
  disabled: false,
  appointmentId: null,
  checkInvalid: false,
  customer: {},
  action: '',
  items: [],
  value: [],
}

ScheduleDetail.propTypes = {
  disabled: PropTypes.bool,
  onFilledSchedule: PropTypes.func,
  onNewPracticeModuleAdded: PropTypes.func,
  onDeletePracticeModule: PropTypes.func,
  customer: PropTypes.object,
  action: PropTypes.string,
  items: PropTypes.array,
  value: PropTypes.array,
  appointmentId: PropTypes.string,
  checkInvalid: PropTypes.bool,
}

export default withTranslation()(ScheduleDetail);
