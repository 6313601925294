import './Wellcome.css'

import React from 'react'
import logo from '../../assets/img/login/logo-hmm.png'

const Wellcome = () => {
  return (
    <div>
      <div className="container">
        <h3>¡Te damos la bienvenida, {localStorage.getItem('userName')}!</h3>
        <p className="subtitle">Ya podes comenzar a operar.</p>
        <img className="image" src={logo} alt="" />
      </div>
    </div>
  )
}

export default Wellcome
