import '../../../DiabeticProgramStyles.css'

import React, { useEffect } from 'react'

import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import { useMemo } from 'react'

const OcularManifestations = (props) => {
  const { setData, editData, onlyView } = props
  const [visualAcuityDisorder, setVisualAcuityDisorder] = React.useState('no')
  const [glaucoma, setGlaucoma] = React.useState('no')
  const [waterfalls, setWaterfalls] = React.useState('no')
  const [retinopathy, setRetinopathy] = React.useState('no')

  const FormatData = () => {
    const data = {
      visualAcuityDisorder: visualAcuityDisorder === 'yes' ? true : false,
      glaucoma: glaucoma === 'yes' ? true : false,
      waterfalls: waterfalls === 'yes' ? true : false,
      retinopathy: retinopathy === 'yes' ? true : false,
    }
    setData(data)
  }

  const setDataEdit = (data) => {
    setVisualAcuityDisorder(data.visualAcuityDisorder === true ? 'yes' : 'no')
    setGlaucoma(data.glaucoma === true ? 'yes' : 'no')
    setWaterfalls(data.waterfalls === true ? 'yes' : 'no')
    setRetinopathy(data.retinopathy === true ? 'yes' : 'no')
  }

  const Edit = useMemo(() => {
    if (editData) {
      setDataEdit(editData)
    }
  }, [editData])

  useEffect(() => {
    FormatData()
    // eslint-disable-next-line no-unused-expressions
    Edit
  }, [visualAcuityDisorder, glaucoma, waterfalls, retinopathy])

  return (
    <>
      <GridItem md={12}>
        <h4>Manifestaciones Oculares</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">
            Trastorno de la agudeza visual:
          </p>
          <RadioInput
            value={visualAcuityDisorder}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setVisualAcuityDisorder(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Glaucoma:</p>
          <RadioInput
            value={glaucoma}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setGlaucoma(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Catarata:</p>
          <RadioInput
            value={waterfalls}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setWaterfalls(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Retinopatía:</p>
          <RadioInput
            value={retinopathy}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setRetinopathy(value)}
          />
        </div>
      </GridItem>
    </>
  )
}

export default OcularManifestations
