import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { Delete, InfoOutlined } from '@material-ui/icons';
import Table from '../../Table/Table';
import Button from '../../CustomButtons/Button';
import ConfirmAlert from '../../ConfirmAlert';
import PersonApiInvoker from '../../../api/PersonApiInvoker';

class EmployeeType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employeeType: [],
            personId: '',
            loading: true,
            deleteAlert: false,
            success: false,
            error: false,
            selectedType: '',
            selectedTypeName: '',
        }
    }

    componentWillReceiveProps(next) {        
        if (next.personId && next.refresh) {
            PersonApiInvoker.getEmployeeEmployeeType(next.personId, (data) => {
                const employeeType = this.formatType(data);
                this.setState({
                    employeeType: employeeType,
                    loading: false,
                });
            });
        }
    }

    infoTextData = (data) => {
        const { t } = this.props;
        return data.length === 1 ? t('employees.infoPractice', { practice: data })
            : t('employees.infoPractices', { practices: data.join(', ') });
    }

    formatType = (data) => {
        const { t } = this.props;
        const result = data.map((d) => {
            const practiceTypeGroupList = d.practiceTypeGroup && d.practiceTypeGroup ? d.practiceTypeGroup.map((p) => p.name).join(', ')  : '';
            const practiceTypeList = d.practiceType && d.practiceType.length ? d.practiceType.map((p) => p.name).join(', ') : '';
            const practicesTypeInactive = d.practiceType.filter((f) => f.active === false).map((p) => p.name);
            const infoText = this.infoTextData(practicesTypeInactive);
            var configuration = t('configuration.practice');
            const info = d.hasPracticeNotActive ? 
            <Tooltip title={<div><span>{infoText}</span><br/><span>{configuration}</span></div>} aria-label="info" placement="right-end"><InfoOutlined className="infoIcon" /></Tooltip> : <div/>
            return {
                info,
                employeeTypeId: d.employeeTypeId,
                employeeType: d.employeeTypeName,
                practiceTypeGroup: practiceTypeGroupList,
                praticeType: practiceTypeList,
                actions: <Button simple justIcon color="danger" onClick={() => this.onDeleteEmployeeType(d.employeeTypeId, d.employeeTypeName)}><Delete /></Button>
            }
        });
        return result;
    }

    EmployeeType(address) {
        this.props.onEmployeeType(address);
    }

    onDeleteEmployeeType(typeId, name) {
        this.setState({
            selectedTypeName: name,
            selectedType: typeId,
        });
        setTimeout(() => {
            this.setState({ deleteAlert: true });
        })
    }

    deleteItem() {
        PersonApiInvoker.deleteEmployeeType(this.props.personId, this.state.selectedType, data => {
            let employeeType = this.state.employeeType;
            employeeType = employeeType.filter((f) => f.employeeTypeId !== this.state.selectedType);

            this.props.onDeleteItem(this.state.selectedType);
            this.setState({
                success: true,
                employeeType,
            })
        }, () => {
            this.setState({ error: true });
        });
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
            selectedTypeName: '',
        });
    }

    onSuccess = () => {
        this.setState({
            deleteAlert: false,
            success: false,
            selectedTypeName: '',
        });
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: '', accessor: 'info', width: 50 },
            { Header: t('employees.practiceTypeGroup'), accessor: 'practiceTypeGroup' },
            { Header: t('employees.praticeType'), accessor: 'praticeType' },
        ];
        componenMode !== 'view' && tableHead.push({ Header: t('employee.new.actions'), accessor: 'actions', sortable: false, width: 150 });
        return tableHead;
    }

    render() {
        const { t } = this.props;
        const { deleteAlert, employeeType, loading, success, error, selectedTypeName } = this.state;
        return (
            <>
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={employeeType > 5 ? 20 : 5}
                    sortable={false}
                    tableHead={this.renderTableHead()}
                    tableData={employeeType}
                    colorsColls={["primary"]}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                />
                
                <ConfirmAlert
                    text={`${t('employee.type.confirm')} ${selectedTypeName}`}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    onSuccess={this.onSuccess}
                    show={deleteAlert}
                    success={success}
                    error={error}
                />
            </>
        )
    }
}

EmployeeType.defaultProps = {
    componenMode: '',
    personId: '',
    onEmployeeType: {},
    onDeleteItem: () => {},
}

EmployeeType.propTypes = {
    componenMode: PropTypes.string,
    personId: PropTypes.any,
    onEmployeeType: PropTypes.object,
    onDeleteItem: PropTypes.func,
}

export default withTranslation()(EmployeeType);
