export const PRACTICE_TYPE_DETAIL = 'PRACTICE_TYPE_DETAIL'
export const EDIT_PRACTICE_TYPE = 'EDIT_PRACTICE_TYPE';
export const SPECIALITIES_DETAIL = 'SPECIALITIES_DETAIL';
export const UNMOUNT_PRACTICE_TYPE = 'UNMOUNT_PRACTICE_TYPE';

export function getPracticeTypes(practices) {
    return {type: PRACTICE_TYPE_DETAIL, practices}
}

export function getSpecialities(specialities) {
    return {type: SPECIALITIES_DETAIL, specialities}
}

export function editPracticeTypes(practice) {
    return {type: EDIT_PRACTICE_TYPE, practice};
}

export function unmountPracticeTypes() {
    return {type: UNMOUNT_PRACTICE_TYPE};
}