import {  FINANCIERS, PROVINCIES } from "../actions/dashboardeconomictool";
import _ from 'lodash';

const dashboardeconomictool = (state = {}, action) => {
    switch(action.type) {
        case FINANCIERS:
            const financiers = action.financiers
            return {...state, financiers};

        case PROVINCIES:
            const provincies = action.provincies
            return {...state, provincies};
        
        default:
            return state;
    }
}

export default dashboardeconomictool;