import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Card';
import DialogTitle from '@material-ui/core/CardHeader';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import {Chip} from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import ApiInvoker from "../../../../api/ApiInvoker";
import PersonApiInvoker from "../../../../api/PersonApiInvoker";
import BusinessApiInvoker from "../../../../api/BusinessApiInvoker";
import RecordsApiInvoker from "../../../../api/RecordsApiInvoker";
import Authority from '../../../../util/Authority';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import SelectInput from '../../../SelectInput';
import AppointmentApiInvoker from '../../../../api/AppointmentApiInvoker';

class CustomInputChip extends Component {
    
    constructor(props) {
        super(props);
        const { t } = this.props
        var filters = [];
        filters = [
            {id: 'supplyCode', title: t('dashboardeconomicsupply.filter.supplyCode'), code: 'supplyCode', type: 'select', multiple: false},
            {id: 'supplyName', title: t('dashboardeconomicsupply.filter.supplyName'), code: 'supplyName', type: 'select', multiple: false},
            {id: 'practiceTypeName', title: t('dashboardeconomicsupply.filter.practiceTypeName'), code: 'practiceTypeName', type: 'select', multiple: false},
            {id: 'employeeName', title: t('dashboardeconomicsupply.filter.employeeName'), code: 'employeeName', type: 'select', multiple: false},
            {id: 'province', title: t('dashboardeconomicsupply.filter.province'), code: 'province', type: 'select', multiple: false},
            {id: 'location', title: t('dashboardeconomicsupply.filter.location'), code: 'location', type: 'select', multiple: false},
            {id: 'geographiczone', title: t('common.geographicZone'), code: 'geographicZone', type: 'select', multiple: false},
            {id: 'financierName', title: t('dashboardeconomicsupply.filter.financierName'), code: 'financierName', type: 'select', multiple: false},
        ] ;
        this.state = {
            show_items: false,
            items: [],
            locations: [],
            filters: filters
        }
    }

    onChipClick = () => {
       this.setState({show_items: !this.state.show_items})
    }

    handleEvent = (e) => {

        var v = this.state.filters[e.target.id];
        var source = [];
        if (v.code === 'practiceTypeName') {
            this.getEmployeeTypes();
        } else if (v.code === 'financierName') {
            this.getFinanciers();
        } else if (v.code === 'supplyCode') {
            this.getSupplyCatalog(true);
        } else if (v.code === 'supplyName') {
            this.getSupplyCatalog(false);
        } else if (v.code === 'province') {
            this.getProvincies();
        } else if (v.code === 'location') {
            source = this.state.locations;
        } else if (v.code === 'geographicZone') {
            source = this.state.zones;
        } else if (v.code === 'employeeName') {
            this.getEmployees();
        }
        this.setState({items: source, show_items: !this.state.show_items, selected_type: v})
    }

    getEmployeeTypes() {
        AppointmentApiInvoker.getGenericPracticeTypes( practices => {
            if (practices && practices.length) {
                this.setState({items: this.formatEmployeeTypes(practices)});
            }
        }, e => {
            console.error(e)
        });
    }

    formatEmployeeTypes(practices) {
        if(practices){
            let result = practices.map(e => {
                return {
                    id: e.practiceTypeId,
                    value: `${e.name}`
                }
            })
            return result
        }
    }

    findValue = (value) => {
        var r = null;
        this.state.items.map(d => {
            if (value === d.id) {
                r = d.value;
            }
        })
        return r;
    }

    formatEmployee(employeeTypes) {
        let result = []
        if(employeeTypes){
            employeeTypes.map(e => {
                result.push({
                    id: e.personId,
                    value: `${e.firstName} ${e.lastName}`
                })
            })
        }
        return result
    }

    getPatologies() {
        RecordsApiInvoker.getAllPatologies(pato => {
            if (pato && pato.length) {
                this.setState({items: this.formatPatologies(pato)});
            }
        }, e => {
            console.error(e)
        })
    }

    formatPatologies(patologies) {
        let result = []
        patologies.map(e => {
            result.push({
                id: e.id,
                value: `${e.name}`
            })
        })
        return result
    }

    getSupplyCatalog(code) {
        ApiInvoker.getSupplyCatalog(catalog => {
            if (catalog && catalog.length) {
                this.setState({items: this.formatCatalog(catalog, code)});
            }
        }, e => {
            console.error(e)
        })
    }

    formatCatalog(catalog, code) {
        let result = []
        catalog.map(e => {
            if (code) {
                result.push({
                    id: e.supplyCatalogId,
                    value: `${e.supplyCatalogCode}`
                })
            } else {
                result.push({
                    id: e.supplyCatalogId,
                    value: `${e.name}`
                })
            }
        })
        return result
    }

    formatFinaciers(financiers) {
        let result = []
        financiers.map(e => {
            result.push({
                id: e.financierId,
                value: `${e.fantasyName}`
            })
        })
        return result;
    }

    formatProvincies(provincies) {
        let result = []
        provincies.map(e => {
            result.push({
                id: e.provinceId,
                value: `${e.name}`
            })
        })
        return result
    }

    getEmployees() {
        var show = Authority.hasPermission("appointment_view");
        if (show) {
            PersonApiInvoker.getEmployees( employees => {
                if (employees && employees.length) {
                    this.setState({items: this.formatEmployee(employees)})
                }
            }, e => {
                console.error(e)
            })
        } else {
            this.setState({items: []});
        }
    } 

    getFinanciers() {
        BusinessApiInvoker.getFinanciers(finan => {
            if (finan && finan.length) {
                this.setState({items: this.formatFinaciers(finan)})
            }
        }, e => {
            console.error(e)
        })
    }


    getProvincies() {
        ApiInvoker.getAllProvinces(prov => {
            if (prov && prov.length) {
                this.setState({items: this.formatProvincies(prov)})
            }
        }, e => {

            console.error(e)
        })
    }

    findProfessional(personId) {
        return this.state.professionals.find(p => {
            return p.personId === personId
        })
    }

    getLocations(value) {
        ApiInvoker.getLocations(value, loc => {
            if (loc && loc.length) {
                this.setState({locations: this.formatLocations(loc)})
            }
        }, e => {
            console.error(e)
        })
    }
    
    getZones(value) {
        ApiInvoker.getGeographicZoneFromLocation(value, zones => {
            if (zones && zones.length) {
                this.setState({zones: this.formatZones(zones)})
            } else {
                this.setState({zones: []})
            }
        }, e => {
            console.error(e)
        })
    }

    formatZones(zones) {
        let result = []
        zones.map(e => {

            result.push({
                id: e.geographicZoneId,
                value: `${e.detail}`
            })
        })
        return result
    }

    formatLocations(loc) {
        let result = []
        loc.map(e => {
            result.push({
                id: e.locationId,
                value: `${e.name}`
            })
        })
        return result
    }

    getStatus = () => {
        return [
            {"id": "PENDING_APPROVAL", "value": this.props.t('PENDING_APPROVAL') },
            {"id": "REJECTED", "value": this.props.t('REJECTED') },
            {"id": "APPROVED", "value": this.props.t('APPROVED') },
            {"id": "CANCELLED", "value": this.props.t('CANCELLED') },
            {"id": "IN_PROGRESS", "value": this.props.t('IN_PROGRESS') },
            {"id": "DONE", "value": this.props.t('DONE') },
        ]
    }

    handleValue(value, state) {
        var item = this.state.selected_type;
        item.value = value;
        if (item.code == 'province') {
            this.getLocations(value);
        } else if (item.code == 'location') {
            this.getZones(value);
        }
        item.name = this.findValue(value);
        this.setState({selected_type: item});
    }

    handleCloseDialog = () => {
        this.setState({selected_type: null})
    }

    handleApply = () => {
        if (!this.state.selected_type.value) {
            return;
        }
        var s = this.state.selected_type;
        this.props.onApply(s);
        this.setState({selected_type: null})
    }

    renderDialogSelect = () => {
        const { t } = this.props      
        return (

            <Dialog
                open={true}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <GridContainer className="filters" justify="center">
                        <GridItem xs={12}>
                            <SelectInput
                            className="customInputChipSelect" 
                            elements={this.state.items} 
                            onSelectedValue={(value) => this.handleValue(value, 'item')}
                            label={this.state.selected_type.title}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleCloseDialog} color="primary">
                    {t('dashboardprofesional.filters.close')}
                </Button>
                <Button onClick={this.handleApply} color="primary">
                    {t('dashboardprofesional.filters.apply')}
                </Button>
                </DialogActions>
            </Dialog>
        );
    }

    has = (keyvalue) => {
        
        var p = false;
        this.state.filters.map((item, key) => {
            if (item.code === keyvalue && item.value) {
                p = true;
            }
        });
        return p;
    }


    hasCity = () => {
        return false;
    }

    findItemValue = (code) => {
        var ret = false;
        this.props.selecteditems.map((item, key) => {
            if (item.code === code) {
                ret = true;
            }

        });
        return ret;
    }

    render() {

        if (this.state.selected_type && this.state.selected_type.type === 'select') {
            return this.renderDialogSelect()
        }
        return (
            <div>
                <Chip  id={this.props.id} label={this.props.label} handleInteraction={this.onChipClick} />
                {this.state.show_items ? 
                    <div style={{position: 'absolute'}}>
                    <Paper>
                        <MenuList>
                            {this.state.filters.map((item, key) => {
                                var findc = this.findItemValue(item.code)
                                if (item.multiple) {
                                    findc = false;
                                }
                                if (findc || (item.code === 'location' && !this.has('province')) || (item.code === 'geographicZone' && !this.has('location'))) {
                                    return (<div/>);
                                }
                                return (
                                    <MenuItem id={key} onClick={this.handleEvent}>{item.title}</MenuItem>
                                )}
                            )}
                        </MenuList>
                    </Paper>
                    </div>
                : <div/>
                }
                
            </div>
            
        )
    }
}

CustomInputChip.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
}

export default withTranslation()(CustomInputChip);