import '../../../DiabeticProgramStyles.css'

import React, { useEffect } from 'react'

import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import { useMemo } from 'react'

const DentalPieces = (props) => {
  const { setData, editData, onlyView } = props
  const [dentalInfections, setDentalInfections] = React.useState('no')
  const [missingTeeth, setMissingTeeth] = React.useState('no')
  const [dentures, setDentures] = React.useState('no')

  const FormatData = () => {
    const data = {
      dentalInfections: dentalInfections === 'yes' ? true : false,
      missingTeeth: missingTeeth === 'yes' ? true : false,
      dentures: dentures === 'yes' ? true : false,
    }
    setData(data)
  }

  const setEditData = (data) => {
    setDentalInfections(data.dentalInfections === true ? 'yes' : 'no')
    setMissingTeeth(data.missingTeeth === true ? 'yes' : 'no')
    setDentures(data.dentures === true ? 'yes' : 'no')
  }

  const Edit = useMemo(() => {
    if (editData) {
      setEditData(editData)
    }
  }, [editData])

  useEffect(() => {
    FormatData()
    // eslint-disable-next-line no-unused-expressions
    Edit
  }, [dentalInfections, missingTeeth, dentures])

  return (
    <>
      <GridItem md={12}>
        <h4>Piezas dentarias</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Infecciones:</p>
          <RadioInput
            value={dentalInfections}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setDentalInfections(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Faltantes:</p>
          <RadioInput
            value={missingTeeth}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setMissingTeeth(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Dentadura postiza:</p>
          <RadioInput
            value={dentures}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setDentures(value)}
          />
        </div>
      </GridItem>
    </>
  )
}

export default DentalPieces
