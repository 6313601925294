import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Card';
import DialogTitle from '@material-ui/core/CardHeader';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import {Chip} from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import ApiInvoker from "../../../../api/ApiInvoker";
import BusinessApiInvoker from "../../../../api/BusinessApiInvoker";
import RecordsApiInvoker from "../../../../api/RecordsApiInvoker";
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import SelectInput from '../../../SelectInput';

class CustomInputChip extends Component {
    
    constructor(props) {
        super(props);
        const { t } = this.props
        let filters = [];
        filters = [
            {id: 'toolType', title: t('dashboardeconomictool.toolType'), code: 'toolType', type: 'select', multiple: false},
            {id: 'condition', title: t('dashboardeconomictool.condition'), code: 'condition', type: 'select', multiple: false},
            {id: 'province', title: t('dashboardeconomictool.province'), code: 'province', type: 'select', multiple: false},
            {id: 'location', title: t('dashboardeconomictool.location'), code: 'location', type: 'select', multiple: false},
            {id: 'geographicZone', title: t('dashboardeconomictool.geographicZone'), code: 'geographicZone', type: 'select', multiple: false},
            {id: 'financierName', title: t('dashboardeconomictool.financier'), code: 'financierName', type: 'select', multiple: false}
        ];

        this.state = {
            show_items: false,
            items: [],
            locations: [],
            filters: filters
        }
    }

    onChipClick = () => {
       this.setState({show_items: !this.state.show_items})
    }

    handleEvent = (e) => {
        var v = this.state.filters[e.target.id];
        var source = [];
        if (v.code === 'toolType') {
            this.getToolTypes()
        } else if (v.code === 'condition') {
            this.getConditions()
        } else if (v.code === 'province') {
            this.getProvincies();
        } else if (v.code === 'location') {
            source = this.state.locations;
        } else if (v.code === 'geographicZone') {
            source = this.state.zones;
        } else if (v.code === 'financierName') {
            this.getFinanciers();
        }
        this.setState({items: source, show_items: !this.state.show_items, selected_type: v})
    }

    findValue = (value) => {
        var r = null;
        this.state.items.map(d => {
            if (value === d.id) {
                r = d.value;
            }
        })
        return r;
    }

    formatToolTypes(toolType, code) {
        let result = toolType.map(e => {
            return {
                id: e.toolTypeId,
                value: `${e.name}`
            }
        })
        if (code) result = result.filter(f => f.name === code);
        return result;
    }

    getToolTypes(toolType) {
        ApiInvoker.getToolTypes(tt => {
            if (tt && tt.length) {
                this.setState({items: this.formatToolTypes(tt, toolType)});
            }
        }, err => {
            console.error(err);
        })
    }

    formatConditions(condition, code) {
        const { t } = this.props;
        let result = condition.map(c => {
            return {
                id: c.id,
                value: `${t(`dashboardeconomictool.condition.${c.name}`)}`
            }
        })
        if (code) result = result.filter(f => f.name === code);
        return result;
    }

    getConditions(condition) {
        RecordsApiInvoker.getToolsCondition(c => {
            if (c && c.length) {
                this.setState({items: this.formatConditions(c, condition)});
            }
        }, err => {
            console.error(err);
        })
    }

    formatFinaciers(financiers) {
        let result = financiers.map(e => {
            return {
                id: e.financierId,
                value: `${e.fantasyName}`
            }
        })
        return result;
    }

    formatProvincies(provincies) {
        let result = provincies.map(e => {
            return {
                id: e.provinceId,
                value: `${e.name}`
            }
        })
        return result;
    }

    getFinanciers() {
        BusinessApiInvoker.getFinanciers(finan => {
            if (finan && finan.length) {
                this.setState({items: this.formatFinaciers(finan)})
            }
        }, err => {
            console.error(err);
        })
    }

    getProvincies() {
        ApiInvoker.getAllProvinces(prov => {
            if (prov && prov.length) {
                this.setState({items: this.formatProvincies(prov)})
            }
        }, err => {
            console.error(err)
        })
    }

    getLocations(value) {
        ApiInvoker.getLocations(value, loc => {
            if (loc && loc.length) {
                this.setState({locations: this.formatLocations(loc)})
            }
        }, err => {
            console.error(err);
        })
    }

    getGepgraphicZones(value) {
        ApiInvoker.getGeographicZoneFromLocation(value, zones => {
            if (zones && zones.length) {
                this.setState({zones: this.formatZones(zones)})
            } else {
                this.setState({zones: []})
            }
        }, err => {
            console.error(err);
        })
    }

    formatZones(zones) {
        let result = zones.map(e => {
            return {
                id: e.geographicZoneId,
                value: `${e.detail}`
            }
        })
        return result;
    }

    formatLocations(loc) {
        let result = loc.map(e => {
            return {
                id: e.locationId,
                value: `${e.name}`
            }
        })
        return result;
    }

    handleValue(value, state) {
        var item = this.state.selected_type;
        item.value = value;
        if (item.code === 'province') {
            this.getLocations(value);
        } else if (item.code === 'location') {
            this.getGepgraphicZones(value);
        }
        item.name = this.findValue(value);
        this.setState({selected_type: item});
    }

    handleCloseDialog = () => {
        this.setState({selected_type: null})
    }

    handleApply = () => {
        if (!this.state.selected_type.value) {
            return;
        }
        var s = this.state.selected_type;
        this.props.onApply(s);
        this.setState({selected_type: null})
    }

    renderDialogSelect = () => {
        const { t } = this.props;
        return (
            <Dialog
                className="dialog-filters"
                open={true}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <GridContainer className="filters" justify="center">
                        <GridItem className="no-padding" xs={12}>
                            <SelectInput
                            className="customInputChipSelect" 
                            elements={this.state.items} 
                            onSelectedValue={(value) => this.handleValue(value, 'item')}
                            label={this.state.selected_type.title}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleCloseDialog} color="primary">
                    {t('dashboardprofesional.filters.close')}
                </Button>
                <Button onClick={this.handleApply} color="primary">
                    {t('dashboardprofesional.filters.apply')}
                </Button>
                </DialogActions>
            </Dialog>
        );
    }

    has = (keyvalue) => {
        var p = false;
        this.state.filters.map((item, key) => {
            if (item.code === keyvalue && item.value) {
                p = true;
            }
        });
        return p;
    }

    hasCity = () => {
        return false;
    }

    findItemValue = (code) => {
        var ret = false;
        this.props.selecteditems.map((item, key) => {
            if (item.code === code) {
                ret = true;
            }
        });
        return ret;
    }

    render() {
        if (this.state.selected_type && this.state.selected_type.type === 'select') {
            return this.renderDialogSelect()
        }
        return (
            <div>
                <Chip  id={this.props.id} label={this.props.label} handleInteraction={this.onChipClick} />
                {this.state.show_items ? 
                    <div style={{position: 'absolute'}}>
                    <Paper>
                        <MenuList>
                            {this.state.filters.map((item, key) => {
                                var findc = this.findItemValue(item.code)
                                if (item.multiple) {
                                    findc = false;
                                }
                                if (findc || (item.code === 'location' && !this.has('province')) || (item.code === 'geographicZone' && !this.has('location'))) {
                                    return (<div/>);
                                }
                                return (
                                    <MenuItem id={key} onClick={this.handleEvent}>{item.title}</MenuItem>
                                )}
                            )}
                        </MenuList>
                    </Paper>
                    </div>
                : <div/>
                }
            </div>
        )
    }
}

CustomInputChip.propTypes = {
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func
}

export default withTranslation()(CustomInputChip);