import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Icon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonSpinner from '../../ButtonSpinner';
import Button from '../../CustomButtons/Button';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import StatusIcon from '../StatusIcon';
import TimePickerGCalendar from '../../TimePickerGCalendar';
import ValidationInput from '../../ValidationInput';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import CustomDialog from '../../CustomDialog/index';
import CustomInput from '../../CustomInput/CustomInput';
import CustomSweetAlert from '../../CustomSweetAlert';

class ScheduleSystemFinished extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: null,
      professionals: [],
      errors: [],
      disabled: false,
      status: '',
      hasChanged: false,
      validation: false,
      validationResult: null,
      validating: false,
      busy: false,
      alertColor: 'info',
      startDate: "",
      endDate: "",
      endTime: "",
      startTime: "",
      alertMessage: '',
      alertOpen: false,
      showOpenCancel: false,
      cancel_motive: ""
    }
  }

  getDetail() {
    const { event } = this.props;
    if (event) {
      if (event.type === 'schedule') {
        AppointmentApiInvoker.getScheduleInCalendar(event.id, details => {
          this.setState(
            { current: details },
            () => this.getEmployees()
          )
        }, null);
      }
      else {
        AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
          this.setState(
            { current: details },
            () => this.getEmployees()
          )
        }, null);
      }
    }
  }

  componentDidMount() {
    //this.getDetail();
    this.setState({ current: this.props.current });
  }

  componentWillReceiveProps(next) {
    
  }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    });
    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 5000);
  }

  getBookingDetail() {
    const { event } = this.props;
    if (event) {
      AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
        details.employeeId = event.employeeId
        this.setState({ current: details });
      }, null);
    }
  }

  //mlgmlg
  getEmployees() {
    const { current } = this.state;
    let lender = 0;
    if (current.companyIdPartnership) {
      lender = current.companyIdPartnership.companyId;
    }
    if (current) {
      PersonApiInvoker.getEmployeesByPracticetype(lender, current.practiceTypeId, data => {
        this.setState({ professionals: this.formatEmployees(data) });
      }, null);
    }
  }

  formatEmployees(data) {
    let result = [];
    data.forEach(e => {
      result.push({
        id: e.personId,
        value: `${e.firstName} ${e.lastName}`,
      })
    })
    return result;
  }

  cancel = () => {
    this.setState({
      showOpenCancel: true
    })
  }

  updateBooking(checking) {
    const { event } = this.props;
    if (event) {
      const fetchUrl = event.type === 'schedule'
        ? 'schedules'
        : 'bookings';

      const body = {
        employeeId: this.state.current.employeeId,
        scheduleDateTime: `${moment(this.state.current.scheduleDate).format(
          'YYYY-MM-DD'
        )}T${this.state.current.startTime}`,
      };

      if (checking) {
        this.setState({
          'validationResult': null,
          'validation': false,
          'validating': true,
          busy: false,
        });

        SchedulerInvoker.postDynamicContentOnlyCheck(fetchUrl, event.id, body, data => {
          this.setState({
            'validationResult': data,
            'validation': data.status !== 'ERROR',
            'validating': false,
            busy: false,
          });
        }, (error) => {
          this.setState({
            'validating': false,
            'errors': [{
              statusError: 'ERROR',
              detail: error.message
            }]
          });
        });
      } else {
        this.setState({ busy: true });
        SchedulerInvoker.postDynamicContent(fetchUrl, event.id, body, data => {
          if (this.props.updateAppointment) {
            this.props.updateAppointment(data);
          }
          if (this.props.onUpdate) {
            this.props.onUpdate(data);
          }
        }, (error) => this.setState({ busy: false }));
      }
    }
  }

  handleChange = (value, state) => {
    let hasChanged;
    if (state === 'startTime') {
      hasChanged = (this.props.event.start && moment(this.props.event.start.toString().split('T')[1], 'HH:mm').format('HH:mm') !== value) ||
        moment(value, 'HH:mm').format('HH:mm') !== moment(this.state.current.startTime, 'HH:mm').format('HH:mm');
    } else {
      hasChanged = this.state.current[state] !== value;
    }

    let endTime = this.state.current.endTime;
    if (state === 'startTime') {
      endTime = moment()
        .set('hours', value.split(':')[0])
        .set('minutes', value.split(':')[1])
        .add(this.state.current.estimatedDuration, 'minutes')
        .format('HH:mm');
    }

    this.setState(prevState => ({
      current: {
        ...prevState.current,
        endTime,
        [state]: value
      },
      hasChanged,
      validation: !hasChanged,
      disabled: false,
      errors: []
    }))
  }
  ///mlgmlg

  cancelScheduleConfirm = () => {
    this.setState({
      showConfirmCancel: true,
      showOpenCancel: false
    })
  }

  confirmCancel = () => {
    var ddStart = moment(this.props.current.scheduleDateTime).format("YYYY-MM-DD");
    //var ddStart = moment(this.state.startDate).format('YYYY-MM-DD');
    var startDateTime  = moment(ddStart + ' ' + this.state.startTime);

    var ddEnd = moment(this.state.endDate).format('YYYY-MM-DD');
    var endDateTime  = moment(ddEnd + ' ' + this.state.endTime);

    this.props.cancelBySystem({
      "startDate": startDateTime,
      "endDate": endDateTime,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "temperature": this.state.temperature,
      "pulse": this.state.pulse,
      "arterialTensionMax": this.state.arterialTensionMax,
      "arterialTensionMin": this.state.arterialTensionMin,
      "breathing": this.state.breathing,
      "note": this.state.note
    })
  }



  cancelSchedule = () => {

    this.setState({ busy: true });
    const { event } = this.props;

    var body = {
      "scheduleIds": [event.id],
      "reasonCancel": this.state.cancel_motive
    }
    AppointmentApiInvoker.cancelSchedule(this.state.current.appointmentId, body, data => {
      this.getDetail();
      this.setState({
        showConfirm: false, showConfirmCancel: false
      })
    }, (error) => {
      const message = error.message ?? error;
      this.openAlert('danger', message);
    });
  }

  //mlgmlg
  handleValue = (value, state) => {
    this.setState({ [state]: value }, () => {
      this.evaluateFieldSig();
    });
  }

  evaluateFieldSig = () => {
    this.setState({
      errorTemperature: false,
      errorPulse: false,
      errorOxigen: false,
      errorArterialTensionMin: false,
      errorArterialTensionMax: false
    })
    if (this.state.temperature != '' && (this.state.temperature  < 34 || this.state.temperature > 45)) {
      this.setState({
        errorTemperature: true
      });
    }
    if (this.state.pulse != '' && (this.state.pulse  < 45 || this.state.pulse > 200)) {
      this.setState({
        errorPulse: true
      });
    }
    if (this.state.breathing != '' && (this.state.breathing  < 50 || this.state.breathing > 100)) {
      this.setState({
        errorOxigen: true
      });
    }
    if (this.state.arterialTensionMin != '' && (this.state.arterialTensionMin  < 45 || this.state.arterialTensionMin > 170)) {
      this.setState({
        errorArterialTensionMin: true
      });
    }
    if (this.state.arterialTensionMax != '' && (this.state.arterialTensionMax  < 80 || this.state.arterialTensionMax > 200)) {
      this.setState({
        errorArterialTensionMax: true
      });
    }

  }

  evaluateField = () => {
    if (
        this.state.endDate == '' ||
        this.state.startTime == '' ||
        this.state.endTime == '' ||
        this.state.errorTemperature ||
        this.state.errorPulse ||
        this.state.errorOxigen ||
        this.state.errorArterialTensionMin ||
        this.state.errorArterialTensionMax
        ) {
      return true;
    }
    return false;
  }
  render() {
    var { t, event } = this.props; // readOnly
    const { busy, current, errors, hasChanged, professionals, status, validating, validation, validationResult } = this.state;
    const disabledState = this.state.disabled;
    var disabled = this.evaluateField();

    var startDateTimeReal = moment(this.props.current.scheduleDateTime).format("YYYY-MM-DD");

    const minDate = startDateTimeReal;
    const maxDate = moment(this.props.current.scheduleDateTime).add(2, 'days').format("YYYY-MM-DD");
    return (
      <div className="booking-detail-form">
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <h4>{t('schedule.finish.system.dialog.subtitle1')}</h4>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.employee')}
              value={this.props.current.employeeFirstName + " " + this.props.current.employeeLastName}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.scheduleId')}
              value={this.props.scheduleId}
              disabled
            />
          </GridItem>
          
          <GridItem xs={12} sm={6}>
            <DateInput
              minDate={true}
              min={minDate}
              max={maxDate}
              maxDate={true}
              disabled={true}
              text={t('schedule.finish.system.dialog.startDate.real')}
              onChangeValue={(value) => this.handleValue(value, 'startDate')}
              value={startDateTimeReal}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <DateInput
              minDate={true}
              min={minDate}
              max={maxDate}
              maxDate={true}
              text={t('schedule.finish.system.dialog.endDate.real')}
              value={this.state.endDate}
              onChangeValue={(value) => this.handleValue(value, 'endDate')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <TimePickerGCalendar
              name="booking_finished_start_time"
              text={t('schedule.finish.system.dialog.startTime.real')}
              value={this.state.startTime}
              onSelectedValue={(value) => this.handleValue(value, 'startTime')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <TimePickerGCalendar
              name="booking_finished_end_time"
              text={t('schedule.finish.system.dialog.endTime.real')}
              value={this.state.endTime}
              onSelectedValue={(value) => this.handleValue(value, 'endTime')}

            />
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12}>
          <h4>{t('schedule.finish.system.dialog.subtitle2')}</h4>
        </GridItem>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              type="number"
              invalid={this.state.temperature < 1 || !this.state.temperature}
              value={this.state.temperature}
              text={t('schedule.finish.system.dialog.temperature')}
              errorText={this.state.errorTemperature ? t('schedule.finish.system.dialog.placeHolder.temperature') : null}
              placeHolder={t('schedule.finish.system.dialog.placeHolder.temperature')}
              onChangeValue={(value) => this.handleValue(value, 'temperature')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.pulse')}
              type="number"
              placeHolder={t('schedule.finish.system.dialog.placeHolder.pulse')}
              errorText={this.state.errorPulse ? t('schedule.finish.system.dialog.placeHolder.pulse') : null}
              value={this.state.pulse}
              invalid={this.state.errorPulse}

              onChangeValue={(value) => this.handleValue(value, 'pulse')}

            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.arterialTensionMax')}
              type="number"
              placeHolder={t('schedule.finish.system.dialog.placeHolder.arterialTensionMax')}
              errorText={this.state.errorArterialTensionMax ? t('schedule.finish.system.dialog.placeHolder.arterialTensionMax') : null}
              invalid={this.state.errorArterialTensionMax}
              value={this.state.arterialTensionMax}
              onChangeValue={(value) => this.handleValue(value, 'arterialTensionMax')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              type="number"
              value={this.state.breathing}
              placeHolder={t('schedule.finish.system.dialog.placeHolder.oxigen')}
              errorText={this.state.errorOxigen ? t('schedule.finish.system.dialog.placeHolder.oxigen') : null}
              text={t('schedule.finish.system.dialog.oxigen')}
              invalid={this.state.errorOxigen}
              onChangeValue={(value) => this.handleValue(value, 'breathing')}

            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.arterialTensionMin')}
              placeHolder={t('schedule.finish.system.dialog.placeHolder.arterialTensionMin')}
              errorText={this.state.errorArterialTensionMin ? t('schedule.finish.system.dialog.placeHolder.arterialTensionMin') : null}
              type="number"
              invalid={this.state.errorArterialTensionMin}

              error={true}
              value={this.state.arterialTensionMin}
              onChangeValue={(value) => this.handleValue(value, 'arterialTensionMin')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <ValidationInput
              text={t('schedule.finish.system.dialog.add.note.clinic')}
              value={this.state.note}
              onChangeValue={(value) => this.handleValue(value, 'note')}
            />
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} style={{textAlign:'right'}}>
          <ButtonSpinner
            onClick={() => this.confirmCancel()}
            label={t('schedule.finish.system.dialog.save')}
            typeButton="submit"
            disabled={disabled}
            color="success"
          />
        </GridItem>
      </div>

    )
  }
}

ScheduleSystemFinished.propTypes = {
  event: PropTypes.object,
  onCancel: PropTypes.func,
  updateAppointment: PropTypes.func,
  errors: PropTypes.array,
  status: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withTranslation()(ScheduleSystemFinished);
