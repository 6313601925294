import { PROFESSIONALS_ALERTS } from "../actions/alerts";

const alerts = (state = {}, action) => {
    switch(action.type) {
        case PROFESSIONALS_ALERTS:
            let professionals = action.professionals
            return {...state, professionals}
        default:
            return state
    }
}

export default alerts;
