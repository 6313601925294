import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import AccessibleIcon from '@material-ui/icons/Accessible';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import ApiInvoker from '../../api/ApiInvoker.js';
import './Tools.css';

class Tools extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: [],
            loading: false,
            personAddress: [],
            tools: [],
            tooltypes: [],
        }
    }

    componentWillMount() {
        if (this.props.tooltypes) {
            this.setState({ tooltypes: this.props.tooltypes },
                () => {
                this.props.tools && this.formatTools(this.props.tools);
            });
        }
        this.getTools();
    }

    componentWillReceiveProps(next) {
        if (next.tools) {
            this.formatTools(next.tools);
        }
        if (next.tooltypes) {
            this.setState({ tooltypes: next.tooltypes });
        }
    }

    getToolTypes() {
        ApiInvoker.getToolTypes(data => {
            this.props.onGetAddress(data);
            if (!this.props.tools) {
                this.getTools();
            }
        }, e => {
            console.error('** error getToolTypes', e);

        });
    }

    getTools() {
        this.setState({ loading: true });
        ApiInvoker.getTools(data => {
            this.setState({ loading: false });
            if (data && data.length) {
                this.props.onGetTools(data);
            }
        }, e => {
            this.setState({ loading: false });
        });
    }

    findToolType(toolTypeId) {
        let tooltypename = '';
        if (this.state.tooltypes && this.state.tooltypes.length) {
            this.state.filter(f => f.toolTypeId === toolTypeId).tooltypes.map(tt => {
                return tooltypename = tt.name
            });
        }
        return tooltypename;
    }

    formatConditionTable(condition) {
        const { t } = this.props;
        if(condition.name === 'own' || condition.name === 'rented' || condition.name === 'other'){
            return t(`tools.table.condition.${condition.name}`);
        } else {
            return t('tools.table.condition.not');
        }
    }

    addTool = () => browserHistory.push('nuevo-producto');

    viewAppointment = (id) => browserHistory.push({
        state: { mode: 'view'},
        pathname: `/solicitudes/${id}`
    });

    formatTools(tools) {
        const { t } = this.props;
        const data = tools.map(c => {
            return {
                serialNumber: c.serialNumber,
                detail: c.detail,
                toolId: c.toolId,
                toolType: c.toolType.name,
                toolTypeCode: c.toolType.toolTypeCode,
                condition: c.condition ? this.formatConditionTable(c.condition) : '',
                availableType: (c.availableType === 'NOT_AVAILABLE' ? t('common.notAvailable') : t('common.available')),
                appointmentLink: <span className="text-link" onClick={() => this.viewAppointment(c.appointmentId)}
                >
                    {c.appointmentId}
                </span>,
                appointmentId: c.appointmentId,
                actions: <div className="tools-actions">
                    <Tooltip title="Editar"><div><Button simple justIcon color="success" authority="tool_edit" onClick={() => browserHistory.push(`/editar-producto/${c.toolId}`)}><Edit /></Button></div></Tooltip>
                </div>
            }
        });
        this.setState({ tools: data });
    }

    exportToExcel = (tools) => {
        const { t } = this.props;
        let r = [];
        const fields = {
            'toolType': t('tools.table.toolType'),
            'toolTypeCode': t('tools.table.toolTypeCode'),
            'serialNumber': t('tools.table.serialNumber'),
            'condition': t('tools.table.condition'),
            'detail': t('tools.table.detail'),
            'availableType': t('tools.table.availableType'),
            'appointmentId': t('tools.table.appointmentId'),
        };
        r.push(fields);
        
        tools.forEach(t => {
            const tool = {
                toolType: t.toolType,
                toolTypeCode: t.toolTypeCode,
                serialNumber: t.serialNumber,
                condition: t.condition,
                detail: t.detail,
                availableType: t.availableType,
                appointmentId: t.appointmentId,
            }
            r = r.concat(tool);
        })

        const fileName = `tools-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(r, {skipHeader: 1});
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data2, fileName + fileExtension);
        return; 
    }

    render() {
        const { t } = this.props;
        const { loading, tools} = this.state;
        return (
            <GridContainer className="tools-container">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <AccessibleIcon />
                            </CardIcon>
                            <Button
                                className="add-content-button tools-button"
                                round
                                color="success"
                                authority="tool_new"
                                onClick={() => this.addTool()}
                            >
                                <Add className="tools-button-icon" /> {t('tool.create')}
                            </Button>
                        </CardHeader>
                        <CardBody className="filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t('tools.table.toolType'), accessor: 'toolType' },
                                    { Header: t('tools.table.toolTypeCode'), accessor: 'toolTypeCode' },
                                    { Header: t('tools.table.serialNumber'), accessor: 'serialNumber' },
                                    { Header: t('tools.table.condition'), accessor: 'condition' },
                                    { Header: t('tools.table.detail'), accessor: 'detail' },
                                    { Header: t('tools.table.availableType'), accessor: 'availableType' },
                                    { Header: t('tools.table.appointmentId'), accessor: 'appointmentLink', filterable: false },
                                    { Header: t('tools.table.actions'), accessor: 'actions', sortable: false, filterable: false }

                                ]}
                                tableData={tools}
                                colorsColls={['primary']}
                            />
                        </CardBody>
                        <CardFooter className="supplycatalog-submit">
                            <Button
                                className="button-export"
                                onClick={() => this.exportToExcel(tools)}
                                color="success"
                                disabled={tools.length < 1}
                            >
                                {t('common.exportExcel')}
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

Tools.propTypes = {
    onGetToolTypes: PropTypes.func.isRequired,
    tools: PropTypes.array,
}

export default withTranslation()(Tools);
