import { connect } from 'react-redux';
import DashboardOperative from '../components/DashboardsPB/DashboardFinancierIndex';

const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const DashboardPBFinancierService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardOperative);

export default DashboardPBFinancierService;