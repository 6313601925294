import '../../../DiabeticProgramStyles.css'

import React, { useEffect } from 'react'

import CustomInput from '../../../../CustomInput/CustomInput'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import { useMemo } from 'react'

const Digestive = (props) => {
  const { setData, editData, onlyView } = props

  const [reflux, setReflux] = React.useState('no')
  const [esophagitis, setEsophagitis] = React.useState('no')
  const [ulcers, setUlcers] = React.useState('no')
  const [irritableBowel, setIrritableBowel] = React.useState('no')
  const [malabsorptionSyndrome, setMalabsorptionSyndrome] = React.useState('no')
  const [colonCancer, setColonCancer] = React.useState('no')
  const [commentsDigestion, setCommentsDigestion] = React.useState(null)

  let commentsDigestionLenth = commentsDigestion ? commentsDigestion.length : 0

  const FormatData = () => {
    let data = {
      reflux: reflux === 'yes' ? true : false,
      esophagitis: esophagitis === 'yes' ? true : false,
      ulcers: ulcers === 'yes' ? true : false,
      irritableBowel: irritableBowel === 'yes' ? true : false,
      malabsorptionSyndrome: malabsorptionSyndrome === 'yes' ? true : false,
      colonCancer: colonCancer === 'yes' ? true : false,
      commentsDigestion: commentsDigestion,
    }
    setData(data)
  }

  const setEditDAta = (data) => {
    setReflux(data.reflux === true ? 'yes' : 'no')
    setEsophagitis(data.esophagitis === true ? 'yes' : 'no')
    setUlcers(data.ulcers === true ? 'yes' : 'no')
    setIrritableBowel(data.irritableBowel === true ? 'yes' : 'no')
    setMalabsorptionSyndrome(data.malabsorptionSyndrome === true ? 'yes' : 'no')
    setColonCancer(data.colonCancer === true ? 'yes' : 'no')
    setCommentsDigestion(data.commentsDigestion)
  }

  const Edit = useMemo(() => {
    if (editData) {
      setEditDAta(editData)
    }
  }, [editData])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    Edit
    FormatData()
  }, [
    reflux,
    esophagitis,
    ulcers,
    irritableBowel,
    malabsorptionSyndrome,
    colonCancer,
    commentsDigestion,
  ])

  return (
    <>
      <GridItem md={12}>
        <h4>Digestivo</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Reflujo:</p>
          <RadioInput
            value={reflux}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setReflux(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Esofagitis:</p>
          <RadioInput
            value={esophagitis}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setEsophagitis(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Úlceras:</p>
          <RadioInput
            value={ulcers}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setUlcers(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Colon irritable:</p>
          <RadioInput
            value={irritableBowel}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setIrritableBowel(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Síndrome de malabsorción:</p>
          <RadioInput
            value={malabsorptionSyndrome}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setMalabsorptionSyndrome(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Cáncer de colon:</p>
          <RadioInput
            value={colonCancer}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setColonCancer(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <CustomInput
          value={commentsDigestion ? commentsDigestion : ''}
          labelText="Comentarios:"
          multiline={true}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            maxlength: '500',
            disabled: onlyView,
          }}
          helpText={`Hasta 500 caracteres. Te quedan ${
            500 - commentsDigestionLenth
          } `}
          onChange={(e) => setCommentsDigestion(e.target.value)}
        />
      </GridItem>
    </>
  )
}

export default Digestive
