export const DOCUMENT_TYPESP = 'DOCUMENT_TYPESP';
export const EMPLOYEE_TYPES = 'EMPLOYEE_TYPES';
export const EMPLOYEE_GEOGRAPHICS = 'EMPLOYEE_GEOGRAPHICS';
export const GEOGRAPHICS = 'GEOGRAPHICS';
export const CHECK_TYPE = 'CHECK_TYPE';
export const CHECK_GEOGRAPHIC = 'CHECK_GEOGRAPHIC';
export const DELETE_ITEM = 'DELETE_ITEM';
export const CHECK_ALL_TYPE = 'CHECK_ALL_TYPE';
export const UNCHECK_ALL = 'UNCHECK_ALL';
export const UNCHECK_ALLGEOGRAPHIC = 'UNCHECK_ALLGEOGRAPHIC';
export const PUT_EMPLOYEETYPE = 'PUT_EMPLOYEETYPE';
export const PUT_EMPLOYEEGEOGRAPHIC = 'PUT_EMPLOYEEGEOGRAPHIC';
export const DELETE_ITEMGEOGRAPHIC = 'DELETE_ITEMGEOGRAPHIC';
export const CLEAN_EMPLOYEE = 'CLEAN_EMPLOYEE';



export function getDocumentTypes(documentTypes) {
    return {type: DOCUMENT_TYPESP, documentTypes}
}
export function getEmployeeTypes(Types) {
    return {type: EMPLOYEE_TYPES, Types}
}
export function cleanEmployees() {
    return {type: CLEAN_EMPLOYEE}
}

export function getEmployeeGeographics(geographics) {
    return {type: EMPLOYEE_GEOGRAPHICS, geographics }
}
export function getGeographics(geographics) {
    return {type: GEOGRAPHICS, geographics}
}

export function checkType(Type) {
    return {type: CHECK_TYPE, Type}
}
export function checkGeographic(geographic) {
    return {type: CHECK_GEOGRAPHIC, geographic}
}
export function deleteItem(itemId) {
    return {type: DELETE_ITEM, itemId}
}
export function deleteItemGeographic(geographicId) {
    return {type: DELETE_ITEMGEOGRAPHIC, geographicId}
}
export function uncheckAll() {
    return {type: UNCHECK_ALL, }
}
export function checkAllTypes(types) {
    return {type: CHECK_ALL_TYPE, types}
}
export function uncheckAllGeographic() {
    return {type: UNCHECK_ALLGEOGRAPHIC, }
}
export function putEmployeeType(employeType) {
    return {type: PUT_EMPLOYEETYPE, employeType}
}
export function putEmployeeGeographic(geographic) {
    return {type: PUT_EMPLOYEEGEOGRAPHIC, geographic}
}