import { PRACTICE_TYPE_DETAIL, SPECIALITIES_DETAIL, EDIT_PRACTICE_TYPE, UNMOUNT_PRACTICE_TYPE } from "../actions/practiceTypes";

const practiceTypes = (state = {}, action) => {
    switch(action.type) {
        case UNMOUNT_PRACTICE_TYPE:
            return {};
        case SPECIALITIES_DETAIL:
            let specialities = action.specialities;
            return {...state, specialities}
        case PRACTICE_TYPE_DETAIL:
            let practiceTypes = action.practiceTypes;
            return {...state, practiceTypes};
        case EDIT_PRACTICE_TYPE: 
            const updated = updatePracticeTypes(state.practiceTypes, action.practiceType);
            return {...state, practiceTypes: updated};
        default:
            return state;
    }
}

const updatePracticeTypes = (practiceTypes, practiceType) => {
    if (practiceTypes && practiceTypes.length) {
        practiceTypes.map((pt, i) => {
            if (pt.toolId === practiceType.toolId) {
                practiceTypes[i] = practiceType
            }
            return pt;
        })
    }
    return practiceTypes;
}

export default practiceTypes;
