import { FETCH_COMPANY_FINANCIERS } from '../actions/financiers';

const companyFinanciers = (state = {}, action) => {
    switch(action.type) {
        case FETCH_COMPANY_FINANCIERS:
            let companyFinanciers = action.companyFinanciers
            return {...state, companyFinanciers}
        default:
            return state
    }
}

export default companyFinanciers;