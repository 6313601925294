import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PeopleIcon from '@material-ui/icons/People';
import Add from '@material-ui/icons/Add';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import CustomInput from '../CustomInput/CustomInput';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import ButtonSpinner from '../ButtonSpinner';
import SelectInput from '../SelectInput';
import './Users.css';

class Users extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        const roles = [
            { id: 'ADM', value: t('common.role.adm') },
            { id: 'GTE', value: t('common.role.gte') },
            { id: 'COG', value: t('common.role.cog') },
            { id: 'COP', value: t('common.role.cop') },
            { id: 'COM', value: t('common.role.com') },
            { id: 'PROF', value: t('common.role.prof') }
        ];

        const status = [
            { id: 'confirm', value: t('active') },
            { id: 'pending', value: t('common.pending') }
        ];

        this.state = {
            loading: false,
            loadingSearch: false,
            users: [],
            filter: {},
            pageSize: 12,
            page: 0,
            roles,
            status,
            isShowMore: true,
        }
    }

    componentWillMount() {
        this.getUsersData();
    }

    formatUsers = (data) => {
        const users = this.state.users;
        const newUsers = data.map(c => {
            return {
                ...c,
                typeStatus: c.status,
            }
        });

        const isShowMore = (newUsers.length > 0) || (newUsers.length < this.pageSize *2) ? true : false;

        this.setState({ isShowMore });

        users.push.apply(users, newUsers);
        return users;
    };

    renderStatus = (type) => {
        const { t } = this.props;
        return type === 'Employee' ?
        <span className="status-tag active">{t('common.active.lowercase')}</span> :
        <span className="status-tag pending">{t('common.pending.lowercase')}</span>
    }

    getUsersData = () => {
        this.setState({ loading: true });
        const self = this;
        let params = Object.keys(this.state.filter).map((k) => {
            let value = self.state.filter[k];
            if (value !== '') {
                return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
            } else {
                return null;
            }
        }).join('&')
        if (params !== '') {
            params = `${params}&`;
        }
        params = `${params}page=${this.state.page}&pageSize=${this.state.pageSize}`;

        PersonApiInvoker.getUsers(params, data => {
            const users = this.formatUsers(data);
            this.setState({ users, loading: false, loadingSearch: false });
        }, error => {
            console.error('** error getUsers', error);
            this.setState({ loading: false, loadingSearch: false });
        });
    }

    showMore = () => {
        this.setState({
          page: this.state.page + 1,
          loading: true,
        }, () => {
          this.getUsersData();
        });
      }

    addUsers = () => browserHistory.push('new-user');

    cleanSearch = () => {
        this.setState({
            users: [],
            filter: {},
            page: 0,
            isShowMore: true,
            loadingSearch: false,
        }, () => {
            this.getUsersData();
        });
    }

    filtersSearch = () => {
        this.setState({
            users: [],
            page: 0,
            isShowMore: true,
            loadingSearch: true,
        }, () => {
            this.getUsersData();
        })
    }

    handleValue = (value, type) => {
        const f = this.state.filter;
        f[type] = value;
        this.setState({ filter: f });
    }

    render() {
        const { t } = this.props;
        const { loading, loadingSearch, filter, isShowMore, users, roles, status } = this.state;

        return (
            <GridContainer className="users-container">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PeopleIcon />
                            </CardIcon>
                            <Button
                                className="add-content-button"
                                round
                                color="success"
                                authority="tool_new"
                                onClick={() => this.addUsers()}
                            >
                                <Add className="user-button-icon" /> {t('users.create')}
                            </Button>

                        </CardHeader>

                        <CardBody className="padding-top-box">
                            <GridContainer>
                                <GridItem xs={12} sm={12} className="no-padding">
                                    <GridContainer>
                                        <GridItem xs={3}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('users.view.firstName')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={filter.firstName !== undefined ? filter.firstName : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'firstName')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('users.view.lastName')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={filter.lastName !== undefined ? filter.lastName : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'lastName')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('users.view.email')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={filter.mail !== undefined ? filter.mail : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'mail')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>
                                        <GridItem className="filters-actions" xs={2}>
                                            <div>
                                                <ComponentDisabledWithTooltip
                                                    tooltipText={t('reassignAppointments.buttonTooltip')}
                                                    disabled={false}
                                                    loading={false}
                                                    component={
                                                        <ButtonSpinner
                                                            onClick={() => this.filtersSearch()}
                                                            label={t('appointment.find.find')}
                                                            labelLoading={t('appointment.find.find')}
                                                            loading={loadingSearch}
                                                            disabled={loading}
                                                            typeButton="submit"
                                                            color="success"
                                                            block
                                                        />
                                                    }
                                                />
                                                <Button block onClick={() => this.cleanSearch()} color="danger">
                                                    {t('appointment.find.clean')}
                                                </Button>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridContainer>
                                    <GridItem xs={3}>
                                        <SelectInput
                                            className="no-padding"
                                            label={t('users.view.role')}
                                            elements={roles}
                                            value={filter.roleName}
                                            onSelectedValue={(value) => this.handleValue(value, 'rolName')}
                                        />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <SelectInput
                                            className="no-padding"
                                            label={t('status')}
                                            elements={status}
                                            value={filter.status}
                                            onSelectedValue={(value) => this.handleValue(value, 'status')}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridContainer>

                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t('users.view.firstName'), accessor: 'firstName' },
                                    { Header: t('users.view.lastName'), accessor: 'lastName' },
                                    { Header: t('users.view.email'), accessor: 'mail' },
                                    { Header: t('users.view.role'), accessor: 'rolName' },
                                    {
                                        Header: t('users.view.status'),
                                        id:'status',
                                        accessor: row => this.renderStatus(row.type),
                                        filterMethod: (filter, row) => {
                                            const value = filter.value.toLowerCase();
                                            return row.status.props.children.indexOf(value) >=0;
                                        }
                                    },
                                ]}
                                tableData={users}
                                defaultPageSize={users.length <= 12 ? 12 : users.length}
                                showPaginationTop={false}
                                showPaginationBottom={false}
                                colorsColls={['primary']}
                            />
                            { users &&
                                <GridItem className="bottom" xs={12} sm={12} md={12}>
                                    <ButtonSpinner
                                        onClick={() => this.showMore()}
                                        label={t('users.showmore')}
                                        labelLoading={t('users.showmore')}
                                        loading={false}
                                        block
                                        disabled={loading || !isShowMore}
                                        className="button-gray"
                                    />
                                </GridItem>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withTranslation()(Users);
