import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';
import Button from '../../CustomButtons/Button';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import '../AppointmentTools.css';
import styles from '../../../assets/components/customCheckboxRadioSwitch';

class AppointmentToolsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            open: false,
            type: '',
            checked: [],
            setChecked: [],
            component: null,
            current: null,
            action:'',
            tools_hold:[],
            removedItems:[],
        }
    }

    componentDidMount(){
        this.setState({ loading: true });
        if(this.props.componentAction === 'create'){
            this.getTools(this.props.dates.dateFrom, this.props.dates.dateTo, this.props.onGetAppointmentsTools)
        } else if(this.props.componentAction === 'edit'){
            const from = this.props.selectedAppointment && this.props.selectedAppointment.startDate;
            const to = this.props.selectedAppointment && this.props.selectedAppointment.endDate
            this.getTools(from, to, this.props.onGetAppointmentsTools)
        }
        const removedItems = this.props.removedItems.map(i => {
            return {
                ...i
            }
        });
        this.setState({ removedItems, tools_hold: this.props.holdIds });
    }

    getTools(from, to, cb) {
        this.setState({ loading: true });
        AppointmentApiInvoker.getAppointmentTool(from, to, data => {
            this.setState({ loading: false });
            // Create Mode
            if(!data.length){
                return cb([]);
            }
            else {
                return cb(data);
            }
        }, err => {
            this.setState({ loading: false });
        })
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open });
    }

    onCheckAppointmentToolsEdit(p) {
        this.props.onCheckAppointmentToolsEdit(p);
    } 

    cancel = () => {
        const list = [];
        this.props.appointmentsTools.map((i, index) => {
            if (this.state.tools_hold.indexOf(i.toolId) > -1) {
                list.push(i);
            }
        });
        this.props.onUndoSelectedTools(this.state.tools_hold, list);
        this.props.closeModal();
    }

    tableData = () => {
        const { classes } = this.props;
        let tableData;
        const availableTools = this.props.appointmentsTools.concat(this.state.removedItems);
        
        if(this.props.componentAction === 'create') {
            tableData = availableTools.map(p => {
                return {
                    ...p,
                    input: (
                        <Checkbox
                            tabIndex={-1}
                            onChange={() => this.props.onCheckAppointmentTools(p)}
                            checked={this.props.checkedToolsList.indexOf(p.toolId) !== -1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                        )
                    }
                })
                return tableData;
                
            } else if(this.props.componentAction === 'edit'){
                return tableData = availableTools.filter(p => {
                    return this.state.tools_hold.indexOf(p.toolId) < 0;
            }).map(p => {
                return {
                    ...p,
                    input:
                        <Checkbox
                            tabIndex={-1}
                            onChange={() => this.onCheckAppointmentToolsEdit(p)}
                            checked={this.props.checkedToolsList.indexOf(p.toolId) !== -1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                }
            )
        }
    }
    
    render() {
        const { t } = this.props;
        return (
            <GridContainer className="appointment-modal-tools check-input">
              <GridItem xs={12}>
                <Table
                  loading={this.state.loading}
                  tableHeaderColor="primary"
                  defaultPageSize={this.props.appointmentsTools.length > 5 ? 20 : 5}
                  tableHead={[
                      { Header: "#", accessor: 'toolId', sortable: false},
                      { Header: " ", accessor: 'input', sortable: false},
                      { Header: "Tipo", accessor: 'toolType.name', filterable: true, sortable: false},
                      { Header: "Código", accessor: 'toolType.toolTypeCode', filterable: true, sortable: false },
                      { Header: "Número de Serie", accessor: 'serialNumber', sortable: false },
                      { Header: "Detalle", accessor: 'detail', sortable: false }
                  ]}
                  sortable={false}
                  tableData={this.tableData()}
                  colorsColls={["primary"]}
                  showPaginationBottom={true}
                />
            </GridItem>
            <GridItem xs={12} className="buttons-bottom-modal text-center">
                <Button onClick={() => this.cancel()} className="buttonPlusFooter" color="danger">{t('appointment.new.cancel')}</Button>
                <Button onClick={() => this.props.closeModal()} className="buttonPlusFooter" color="success">{t('appointment.new.accept')}</Button>
            </GridItem> 
            </GridContainer>    
        )
    }
}

AppointmentToolsModal.defaultProps = {
    appointmentsTools: [],
    checkedToolsList: [],
    selectedAppointment: {},
    componentAction: 'create',
    onCheckAppointmentToolsEdit: () => {}
}

AppointmentToolsModal.propTypes = {
    open: PropTypes.bool,
    onCreatedModule: PropTypes.func,
    onCreatedPlan: PropTypes.func,
    onGetAppointmentsTools: PropTypes.func,
    onCheckAppointmentTools: PropTypes.func,
    onCheckAppointmentToolsEdit: PropTypes.func,
    appointmentsTools: PropTypes.array,
    checkedToolsList: PropTypes.array,
    current: PropTypes.object,
    componentAction: PropTypes.string,
    action: PropTypes.string,
    removedItems: PropTypes.array,
}

export default withStyles(styles)(withTranslation()(AppointmentToolsModal));
