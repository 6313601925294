import './NewSchedule.css'

import React, { Component } from 'react'

import AppointmentToolsService from '../../containers/AppointmentsToolsService'
import Button from '../CustomButtons/Button'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'
import CardIcon from '../Card/CardIcon'
import CustomSweetAlert from '../CustomSweetAlert'
import FullLoading from '../FullLoading'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { HealthBook } from '../../icons'
import PropTypes from 'prop-types'
import SchedulerInvoker from '../../api/SchedulerInvoker'
import SchedulerService from '../../containers/SchedulerService'
import Snackbar from '../../components/Snackbar/Snackbar'
import _ from 'lodash'
import { browserHistory } from 'react-router'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import { withTranslation } from 'react-i18next'

class NewSchedule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmOpen: false,
      customer: {},
      performAppointmentToolsSave: false,
      provider: {},
      diagnostic: '',
      schedule: [],
      loading: false,
      checkInvalid: false,
      alertOpen: false,
      alertStatus: '',
      bookingErrors: [],
      errorQuantity0: false,
      bookingsResult: null,
      financierId: '',
      affiliateId: '',
      metadata: {
        requestor: {},
        auditor: {},
        family: {},
        disability: {},
      },
      records: {},
      alertMessageOpen: false,
      alertMessageColor: '',
      alertMessageMsg: '',
    }
  }
  // Function to Detect Page Reload by MH-
  onUnload = (event) => {
    event.preventDefault()
    // Chrome requires returnValue to be set.
    event.returnValue = ''
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload, false)
    if (this.props.schedule) {
      this.setState({
        customer: this.props.schedule.customer,
        provider: this.props.schedule.provider,
        diagnostic: this.props.schedule.diagnostic,
        schedule: this.props.schedule.schedule,
        financierData: this.props.schedule.financierData,
        records: this.props.records,
      })
    }
  }

  componentWillUnmount() {
    this.props.onResetSchedule()
    this.props.onFilledBarthel({})
    this.props.onFilledComplexity({})
    this.props.onFilledPatology({})
    this.props.onFilledHospitalPatology({})
    window.removeEventListener('beforeunload', this.onUnload, false)
  }

  checkScheduleQuantity = () => {
    let found = false
    this.state.schedule.forEach((r, index) => {
      if (r.quantity === 0) {
        found = true
      }
    })
    return found
  }

  loadBookings() {
    const { t } = this.props
    if (this.checkScheduleQuantity()) {
      this.setState({ errorQuantity0: true })
      return
    }

    if (!this.validateFields()) {
      this.setState({ checkInvalid: true })
      this.openAlert('warning', t('appointment.new.requiredFields'))
      return
    }
    this.setState({ confirmOpen: true })
  }

  saveNewSchedule() {
    const {
      affiliateId,
      customer,
      diagnostic,
      financierData,
      financierId,
      metadata,
      observations,
      provider,
      schedule,
    } = this.state
    const { barthel, complexity, hospitalPatology, patology } = this.props
    this.setState({
      loading1: true,
      confirmOpen: false,
    })

    const body = {
      affiliateId: affiliateId,
      financierId: financierId,
      addressId: customer.address,
      customerId: customer.customer,
      creationEmployeeId: localStorage.getItem('user_id'),
      description: diagnostic,
      items: this.formatItems(schedule),
      startDate: provider.dateStart,
      endDate: provider.dateEnd,
      metadata: {
        // DO NOT DELETE
        sensibility: 1,
        hasTubes: 0,
        medicalData: {
          requestor: metadata.requestor,
          auditor: metadata.auditor,
          family: metadata.family,
        },
        diagnostic: {
          principalDiagnostic: {
            id: patology.recordId,
            description: patology.name,
            category:
              patology.parent &&
              patology.parent.parent &&
              patology.parent.parent.recordId,
            subcategory: patology.parent && patology.parent.recordId,
          },
          hospitalizationDiagnostic: {
            id: hospitalPatology.recordId,
            description: hospitalPatology.name,
            category:
              hospitalPatology.parent &&
              hospitalPatology.parent.parent &&
              hospitalPatology.parent.parent.recordId,
            subcategory:
              hospitalPatology.parent && hospitalPatology.parent.recordId,
          },
          complexity: {
            id: complexity && complexity.id,
            description: complexity && complexity.name,
          },
          barthelIndex: {
            id: barthel && barthel.id,
            description: barthel && barthel.name,
          },
          observations: observations,
        },
        disability: {
          disabledPatient: metadata.disability
            ? metadata.disability.disabledPatient
            : false,
          activeDisabilityCertificate: metadata.disability
            ? metadata.disability.activeDisabilityCertificate
            : false,
          disabilityCertificateCode: metadata.disability
            ? metadata.disability.disabilityCertificateCode
            : false,
        },
      },
    }

    SchedulerInvoker.postAppointment(
      body,
      (appointement) => {
        if (appointement.status === 'OK') {
          this.props.onSaveSchedule({
            customer: customer,
            provider: provider,
            diagnostic: diagnostic,
            schedule: schedule,
            financierData: financierData,
            appointmentId: appointement.appointmentId,
            customerId: appointement.customerId,
            startDate: appointement.startDate,
          })
          this.setState(
            {
              appointmentId: appointement.appointmentId,
            },
            () => {
              this.performSaveTools()
            }
          )
        } else {
          this.setState({
            alertOpen: true,
            confirmOpen: false,
            checkInvalid: false,
            bookingErrors: appointement.errors,
            alertStatus: appointement.status,
            bookingsResult: appointement,
            loading: false,
          })
        }
      },
      (error) => {
        this.setState({ loading: false })
        const message = error.message ?? error
        this.openAlert('danger', message + '')
      }
    )
  }

  performSaveTools() {
    this.setState({ performAppointmentToolsSave: true })
  }

  saveAppointmentTools() {
    browserHistory.push('/solicitudes')
  }

  cancel() {
    this.setState({
      alertOpen: false,
      bookingErrors: [],
      alertStatus: '',
      bookingsResult: null,
    })
  }

  continue() {
    this.saveAppointmentTools()
  }

  validateFields() {
    if (
      !this.state.customer.customer ||
      !this.state.customer.address ||
      !this.state.provider.provider ||
      !this.state.provider.dateStart ||
      !this.state.provider.dateEnd ||
      !this.state.financierId ||
      !this.state.affiliateId ||
      !this.props.patology.recordId ||
      !this.props.hospitalPatology.recordId ||
      !this.state.schedule.length
    ) {
      return false
    }
    return true
  }

  formatItems(schedules) {
    const result = schedules.map((s, i) => {
      let schedulePlan = s.plan
      let estimatedDuration = null
      if (schedulePlan != null) {
        if (schedulePlan.plan && schedulePlan.plan.estimatedDuration) {
          estimatedDuration = schedulePlan.plan.estimatedDuration
        }
        schedulePlan = {
          endType:
            s.plan.recurrence.finish === '2' ||
            s.plan.recurrence.endType === 'OCURRENCES'
              ? 'OCURRENCES'
              : s.plan.recurrence.finish === '1' ||
                s.plan.recurrence.endType === 'DATE'
              ? 'DATE'
              : null,
          every: s.plan.recurrence.every,
          monthDay:
            s.plan.recurrence.monthRecurrence || s.plan.recurrence.monthDay,
          ocurrences:
            s.plan.recurrence.finishOcurrences || s.plan.recurrence.ocurrences,
          recurrenceType: s.plan.recurrence.recurrenceType || null,
          weekDays: s.plan.recurrence.weekRecurrence
            ? s.plan.recurrence.weekRecurrence.join(',')
            : Array.isArray(s.plan.recurrence.weekDays)
            ? s.plan.recurrence.weekDays.join(',')
            : s.plan.recurrence.weekDays,
        }

        if (s.plan.recurrence.recurrenceType === 'INTRADAY') {
          schedulePlan.processingScheduleType = 'INTRADAY'
          schedulePlan.processingStartTimes = null
          schedulePlan.every = 1
          schedulePlan.processingDurationTime = s.plan.recurrence
            .processingDurationTime
            ? s.plan.recurrence.processingDurationTime
            : parseInt(s.plan.recurrence.quantityIntraday) *
              (estimatedDuration ? estimatedDuration : 1)
          schedulePlan.recurrenceType = 'WEEK'
        }
      }

      let itemRequestPackageDetails = []
      if (s.itemRequestPackageDetails) {
        itemRequestPackageDetails = []
        s.itemRequestPackageDetails.forEach((m) => {
          const row = {}
          row['practiceTypeId'] = m['practiceType']['practiceTypeId']
          row['recurrentType'] = m.schedulePlan['recurrenceType']
          row['every'] = m.schedulePlan['every']
          row['endType'] = m.schedulePlan['endType']
          row['processingScheduleType'] =
            m.schedulePlan['processingScheduleType']
          row['processingDurationTime'] =
            m.schedulePlan['processingDurationTime']
          row['weekDays'] = m.schedulePlan.weekRecurrence
            ? Array.isArray(m.schedulePlan.weekRecurrence)
              ? m.schedulePlan.weekRecurrence.join(',')
              : m.schedulePlan.weekRecurrence
            : Array.isArray(m.schedulePlan.weekDays)
            ? m.schedulePlan.weekDays.join(',')
            : m.schedulePlan.weekDays

          //row['weekDays'] = Array.isArray(m.schedulePlan['weekDays']) ? m.schedulePlan['weekDays'].join(',') : m.schedulePlan['weekDays']
          row['monthDay'] = m.schedulePlan.monthRecurrence
            ? m.schedulePlan.monthRecurrence
            : null
          row['ocurrences'] = m.schedulePlan['ocurrences']
          row['startTime'] = m['startTime'] ? m['startTime'] : '06:00'
          row['endTime'] = m['endTime'] ? m['endTime'] : '23:00'
          itemRequestPackageDetails.push(row)
        })
      }
      let companyIdPartnership = null
      if (s.companyIdPartnership) {
        companyIdPartnership = { companyId: s.companyIdPartnership }
      } else if (s.plan && s.plan.companyIdPartnership != null) {
        companyIdPartnership = { companyId: s.plan.companyIdPartnership }
      } else if (s.module && s.module.companyIdPartnership != null) {
        companyIdPartnership = { companyId: s.module.companyIdPartnership }
      }
      return {
        serviceProvider: {
          serviceProviderId: this.state.provider.provider,
        },
        order: i + 1,
        startDate: s.plan
          ? _.get(s, 'plan.startDate', s.startDate)
          : _.get(s, 'module.startDate', s.startDate),
        endTime: s.plan
          ? _.get(s, 'plan.endTime', s.endTime)
          : _.get(s, 'module.endTime', s.endTime),
        startTime: s.plan
          ? _.get(s, 'plan.startTime', s.startTime)
          : _.get(s, 'module.startTime', s.startTime),
        endDate: s.plan
          ? _.get(s, 'plan.endDate', s.endDate)
          : _.get(s, 'module.endDate', s.endDate),
        packageId:
          s.module || s.packageId
            ? _.get(s, 'module.module.packageId', s.packageId)
            : null,
        packageQuantity:
          s.module && s.module.module
            ? _.get(
                s,
                'module.module.packageQuantity',
                s.module.packageQuantity
              )
            : s.packageQuantity,
        practiceTypeId: s.plan
          ? _.get(s, 'plan.plan.id', _.get(s, 'plan.practiceTypeId'))
          : null,
        itemRequestDetails: s.plan
          ? _.get(s, 'plan.itemRequestDetails', _.get(s, 'itemRequestDetails'))
          : _.get(
              s,
              'module.itemRequestDetails',
              _.get(s, 'itemRequestDetails')
            ),
        schedulePlan: schedulePlan,
        companyIdPartnership: companyIdPartnership,
        appointmentItemRequestStatus: 'CREATED',
        itemRequestPackageDetails: itemRequestPackageDetails,
      }
    })
    return result
  }

  setProvider(value) {
    this.setState(
      {
        provider: value,
      },
      () => {
        this.props.onSaveDates({
          startDate: this.state.provider.dateStart,
          endDate: this.state.provider.dateEnd,
        })
      }
    )
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: value.financierId ? value.financierId : '',
      affiliateId: value.affiliateId ? value.affiliateId : '',
    })
  }

  setMetadata(value) {
    this.setState({ metadata: value })
  }

  setDisabilityMetadata(value) {
    const currMeta = this.state.metadata
    const {
      activeDisabilityCertificate,
      disabledPatient,
      disabilityCertificateCode,
    } = value
    const newMeta = {
      ...currMeta,
      disability: {
        disabledPatient: disabledPatient || false,
        activeDisabilityCertificate: activeDisabilityCertificate || false,
        disabilityCertificateCode: disabilityCertificateCode || null,
      },
    }
    this.setState({ metadata: newMeta })
  }

  setObservations(value) {
    this.setState({ observations: value })
  }

  openAlert = (color, message) => {
    this.setState({
      alertMessageColor: color,
      alertMessageMsg: message,
      alertMessageOpen: true,
    })

    setTimeout(() => {
      this.setState({ alertMessageOpen: false })
    }, 5000)
  }

  handleAppointmentToolsSaveError(error) {
    this.setState({
      loading: false,
      bookingErrors: error.errors,
      alertStatus: error.status,
      bookingsResult: error,
    })
  }

  setCompareItems = (items, compare) => {
    const itemsFilter = items.filter((f) => f[compare])
    const countItems = itemsFilter.length
    const uniqueItems = _.uniqBy(itemsFilter, compare)
    const countUniqueItems = uniqueItems.length
    return countItems === countUniqueItems
  }

  getEqualsItems = (items) => {
    const isUniquePractices = this.setCompareItems(items, 'specialty') // @TODO: ver si se puede cambiar por el id de practica
    const isUniqueModules = this.setCompareItems(items, 'packageId') // modules
    return isUniqueModules && isUniquePractices ? true : false
  }

  renderAlertOpen = (alertStatus, bookingErrors, t) => {
    if (alertStatus === 'ERROR') {
      return (
        <CustomSweetAlert
          type="error"
          title={t('appointment.new.appointmentReview')}
          onConfirm={() => this.cancel()}
          confirmBtnCssClass="btn danger"
          confirmBtnText={t('cancel')}
          showCancel={false}
          message={
            bookingErrors.length > 0 && (
              <ul className="schedule-errors-list">
                {bookingErrors.map((e) => (
                  <li className="schedule-errors-item">- {e.detail}</li>
                ))}
              </ul>
            )
          }
        />
      )
    } else if (alertStatus === 'WARNING') {
      return (
        <CustomSweetAlert
          type="warning"
          title={t('appointment.new.appointmentReview')}
          onConfirm={() => this.continue()}
          confirmBtnCssClass="btn success"
          confirmBtnText={t('accept')}
          showCancel={false}
          message={
            bookingErrors.length > 0 && (
              <ul className="schedule-errors-list">
                {bookingErrors.map((e) => (
                  <li className="schedule-errors-item">- {e.detail}</li>
                ))}
              </ul>
            )
          }
        />
      )
    }
  }

  render() {
    const {
      t,
      barthel,
      complexity,
      hospitalPatology,
      onFilledPatology,
      onFilledHospitalPatology,
      onFilledBarthel,
      onFilledComplexity,
      patology,
      schedule,
    } = this.props
    const {
      alertMessageColor,
      alertMessageMsg,
      alertMessageOpen,
      alertOpen,
      alertStatus,
      appointmentId,
      bookingErrors,
      checkInvalid,
      confirmOpen,
      customer,
      errorQuantity0,
      loading,
      financierData,
      performAppointmentToolsSave,
      provider,
    } = this.state

    const schedulesState = this.state.schedule

    return (
      <GridContainer>
        {loading && <FullLoading />}
        <Snackbar
          place="tr"
          color={alertMessageColor}
          message={alertMessageMsg}
          open={alertMessageOpen}
        />
        <GridItem className="schedule-form" xs={12} sm={12} md={6}>
          <SchedulerService.CustomerFormService
            onFilledCustomer={(value) => this.setState({ customer: value })}
            value={schedule ? schedule.customer : null}
            checkInvalid={checkInvalid}
            className="customer-form-container"
          />
        </GridItem>

        <SchedulerService.ProviderFormService
          onFilledProvider={(value) => this.setProvider(value)}
          onFilledFinancier={(value) => this.setFinancier(value)}
          value={schedule ? schedule.provider : null}
          financierData={financierData}
          customerId={customer}
          checkInvalid={checkInvalid}
        />

        <GridItem xs={12}>
          <Card className="authorizer-diagnostic-forms">
            <CardHeader
              color="primary"
              icon
              className={this.props.classes.cardHeader}
            >
              <CardIcon color="primary">
                <HealthBook />
              </CardIcon>
              <h3 className="card-icon-title">
                {t('appointment.clinicalData')}
              </h3>
            </CardHeader>
            <CardBody>
              <SchedulerService.AuthorizerFormService
                onFilledAuthorizer={(value) => this.setMetadata(value)}
                value={{ metadata: this.state.metadata }}
              />
              <SchedulerService.DisabilityFormService
                value={{
                  metadata:
                    this.state.metadata && this.state.metadata.disability,
                }}
                onFilledDisability={(value) =>
                  this.setDisabilityMetadata(value)
                }
              />
              <SchedulerService.DiagnosticFormService
                onFilledDiagnostic={(value) =>
                  this.setState({ diagnostic: value })
                }
                onFilledPatology={onFilledPatology}
                onFilledHospitalPatology={onFilledHospitalPatology}
                onFilledBarthel={onFilledBarthel}
                onFilledComplexity={onFilledComplexity}
                onFilledObservation={this.setObservations.bind(this)}
                barthel={barthel}
                complexity={complexity}
                patology={patology}
                hospitalPatology={hospitalPatology}
                checkInvalid={checkInvalid}
                accessor={'recordId'}
              />
            </CardBody>
          </Card>
        </GridItem>

        <SchedulerService.ScheduleDetailService
          action="create"
          onFilledSchedule={(value) => this.setState({ schedule: value })}
          checkInvalid={checkInvalid}
          dates={{
            startDate: provider && provider.dateStart,
            endDate: provider && provider.dateEnd,
          }}
          customer={customer}
          value={schedule ? schedule.schedule : null}
        />

        <AppointmentToolsService.Form
          hideControlButtons={true}
          appointmentId={appointmentId}
          afterAppointmentToolsSave={this.saveAppointmentTools.bind(this)}
          onAppointmentToolsSaveError={this.handleAppointmentToolsSaveError}
          performSave={performAppointmentToolsSave}
          startDate={moment(provider && provider.dateStart).format(
            'YYYY-MM-DD'
          )}
          endDate={moment(provider && provider.dateEnd).format('YYYY-MM-DD')}
        />

        <GridItem
          xs={12}
          className="schedule-grid-submit justify-space-between"
        >
          <Button onClick={() => browserHistory.push('/solicitudes')}>
            {t('appointment.new.cancel')}
          </Button>
          <Button color="info" onClick={() => this.loadBookings()}>
            {t('appointment.new.request')}
          </Button>
        </GridItem>

        {errorQuantity0 && (
          <CustomSweetAlert
            type="error"
            title={t('appointment.new.dialog.reviewappointment.title')}
            showConfirm={false}
            onCancel={() => this.setState({ errorQuantity0: false })}
            cancelBtnCssClass="btn danger"
            cancelBtnText={t(
              'appointment.new.dialog.confimation.button_cancel'
            )}
            showCancel
            message={
              <>
                <p>
                  {t('appointment.new.dialog.reviewappointment.description1')}
                </p>
                <p>
                  {t('appointment.new.dialog.reviewappointment.description2')}
                </p>
              </>
            }
          />
        )}

        {confirmOpen && (
          <CustomSweetAlert
            type="warning"
            title={t('appointment.new.dialog.confimation.title')}
            onConfirm={() => this.saveNewSchedule()}
            onCancel={() => this.setState({ confirmOpen: false })}
            confirmBtnCssClass="btn success"
            cancelBtnCssClass="btn danger"
            confirmBtnText={t(
              'appointment.new.dialog.confimation.button_confirm'
            )}
            cancelBtnText={t(
              'appointment.new.dialog.confimation.button_cancel'
            )}
            showCancel
            message={
              <>
                {!this.getEqualsItems(schedulesState) && (
                  <p>{t('appointment.practicesModules.sameSpeciality')}</p>
                )}
                <p>{t('appointment.new.dialog.confimation.description')}</p>
              </>
            }
          />
        )}

        {alertOpen && this.renderAlertOpen(alertStatus, bookingErrors, t)}
      </GridContainer>
    )
  }
}

NewSchedule.defaultProps = {
  patology: {},
  hospitalPatology: {},
}

NewSchedule.propTypes = {
  onGetBookings: PropTypes.func,
  onSaveSchedule: PropTypes.func,
  onSaveDates: PropTypes.func,
  schedule: PropTypes.object,
  onAppointment: PropTypes.object,
}

const styles = {
  cardHeader: {
    zIndex: '0 !important',
  },
}

export default withStyles(styles)(withTranslation()(NewSchedule))
