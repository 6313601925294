import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import FormLabel from "@material-ui/core/FormLabel";
import Close from "@material-ui/icons/Close";
import CustomInput from '../CustomInput/CustomInput';
import InputAdornment from "@material-ui/core/InputAdornment";
import './ValidationInput.css';

class ValidationInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredState: '',
            email: '',
            emailState: '',
            number: '',
            numberState: '',
            required: "",
            value: '',
        }
    }

    componentWillReceiveProps(next) {
        if (next.invalid) {
            this.setState({ [this.props.type || "required" + "State"]: "error" });
        }
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        if (this.props.disabled) {
            return
        }
        this.props.onChangeValue(event.target.value)
        switch (type) {
            case "email":
            if (this.verifyEmail(event.target.value)) {
                this.setState({ [this.props.type + "State"]: "success" });
            } else {
                this.setState({ [this.props.type + "State"]: "error" });
            }
            break;
            case "password":
            if (this.verifyLength(event.target.value, 1)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "equalTo":
            if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "checkbox":
            if (event.target.checked) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "number":
            if (this.verifyNumber(event.target.value)) {
                this.setState({ [this.props.type + "State"]: "success" });
            } else {
                this.setState({ [this.props.type + "State"]: "error" });
            }
            break;
            case "length":
            if (this.verifyLength(event.target.value, 1)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "max-length":
            if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "url":
            if (this.verifyUrl(event.target.value)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "min-value":
            if (
                this.verifyNumber(event.target.value) &&
                event.target.value >= stateNameEqualTo
            ) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "max-value":
            if (
                this.verifyNumber(event.target.value) &&
                event.target.value <= stateNameEqualTo
            ) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            case "range":
            if (
                this.verifyNumber(event.target.value) &&
                event.target.value >= stateNameEqualTo &&
                event.target.value <= maxValue
            ) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
            break;
            default:
            break;
        }
        switch (type) {
            case "checkbox":
            this.setState({ [stateName]: event.target.checked });
            break;
            default:
            this.setState({ [stateName]: event.target.value });
            break;
        }
    }

    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }

    verifyLength(value, length) {
        if (value.length > length) {
            return true;
        }
        return false;
    }

    verifyNumber(value) {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    }

    render () {
        return (
            <GridContainer>
            <GridItem xs={12} sm={7}>
            <CustomInput
                labelText={this.props.text}
                error={this.state[this.props.type || "required" + 'State'] === "error"}
                helpText={this.props.errorText}
                placeHolder={this.props.placeHolder}
                value={this.props.value}
                disabled={this.props.disabled}
                multiline={this.props.multiline}
                formControlProps={{
                    fullWidth: true,
                    disabled: this.props.disabled
                }}
                inputProps={{
                ...this.props.inputProps,
                onChange: event =>
                    this.change(event, this.props.type || "required", this.props.type || "length"),
                type: this.props.type || "text",
                }}
            />
            </GridItem>
            </GridContainer>
        )
    }
}

ValidationInput.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChangeValue: PropTypes.func,
    invalid: PropTypes.any,
    disabled: PropTypes.any,
    errorText: PropTypes.string,
    inputProps: PropTypes.object,
    multiline: PropTypes.bool,
    placeHolder: PropTypes.string,

}

export default ValidationInput;