import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Table from '../../Table/Table';
import { useStylesGralSteps } from '../useStylesReassignAppStepper';

const RequestChangesStep = ({
  isConfirmStep,
  values: {
    professionalsToReassign,
    schedulesSelectedData,
  },
  t,
}) => {
  const classes = useStylesGralSteps();

  const dataSchedulesSelected = schedulesSelectedData.map(s => {
    const scheduleDateTimeUpdate = s.scheduleDateTimeUpdate
      && moment(s.scheduleDateTimeUpdate).format('DD/MM/YYYY') !== moment(s.scheduleDateTime).format('DD/MM/YYYY') 
        ? moment(s.scheduleDateTimeUpdate).format('DD/MM/YYYY') : '-';
    const scheduleTimeUpdate = s.scheduleTimeUpdate && s.scheduleTimeUpdate !== s.scheduleTime ? s.scheduleTimeUpdate  : '-';
    return {
      ...s,
      scheduleDateTimeUpdate: <Typography style={{ fontWeight: 300 }} variant="body2" color="primary">{scheduleDateTimeUpdate}</Typography>,
      scheduleTimeUpdate: <Typography style={{ fontWeight: 300 }} variant="body2" color="primary">{scheduleTimeUpdate}</Typography>,
    }
  });
  const selectedProfessionalsData = professionalsToReassign.filter(f => f.checkedProfessional === true);

  return (
    <Grid container className={clsx(classes.formStepper, {[classes.readOnlyForm]: isConfirmStep})}>
      <Grid item xs={12}>
        <h4 className={classes.stepTitle}>{t('title.appointmentsToModify')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.scheduleId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.originalProfessional'), accessor: 'professional' },
            { Header: t('label.originalDate'), accessor: 'scheduleDate' },
            { Header: t('label.originalTime'), accessor: 'scheduleTime' },
            { Header: t('label.requestDate'), accessor: 'scheduleDateTimeUpdate' },
            { Header: t('label.requestTime'), accessor: 'scheduleTimeUpdate' },
          ]}
          tableData={dataSchedulesSelected}
          defaultPageSize={dataSchedulesSelected && dataSchedulesSelected.length > 5 ? dataSchedulesSelected.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>

      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.suggestedProfessionals')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable={true}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.profesional.firstName'), accessor: 'firstName' },
            { Header: t('reassignAppointments.table.profesional.lastName'), accessor: 'lastName' },
            { Header: t('reassignAppointments.table.profesional.employeeTypes'), accessor: 'employeeTypes' },
            { Header: t('reassignAppointments.table.profesional.geographicZone'), accessor: 'geographicZone' },
          ]}
          tableData={selectedProfessionalsData}
          showPaginationTop={false}
          showPaginationBottom={false}
          defaultPageSize={selectedProfessionalsData && selectedProfessionalsData.length > 5 ? selectedProfessionalsData.length : 5}
        />
      </Grid>
    </Grid>
  )
}

export default withTranslation()(RequestChangesStep);
