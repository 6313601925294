import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Cancel from '@material-ui/icons/Cancel';
import { ChipSet, Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import CustomInputChip from './CustomInputChip';

class ChipFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            professionals: [],
            employees: [],
            employeeTypes: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            elements: [],
        }
    }

    addFilter = (s) => {
        const chips = this.props.chips
        chips.push(JSON.parse(JSON.stringify(s)));
        this.props.onChangeChips(chips, this.props.chips);
    }

    removeChip = (keyremove) => {
        this.props.chips.map((item, key) => {
            if (item.code === keyremove) {
                this.props.chips.splice(key,1);
            }
        });
    }

    onChipRemove = (s) => {
        if (this.props.chips[s].code === 'location') {
            this.removeChip('geographicZone');
        }
        if (this.props.chips[s].code === 'province') {
            this.removeChip('location');
            this.removeChip('geographicZone');
        }
        this.props.chips.splice(s, 1);
        this.props.onChangeChips(s, this.props.chips);
    }

    renderChips = (chips) => {
        return chips.map((item, key) => {
            const label = `${item.title} '${item.name}'`;
            return (
                <Chip
                    filter={true}
                    selecteditems={chips}
                    trailingIcon={<Cancel/>}
                    shouldRemoveOnTrailingIconClick={false}
                    handleTrailingIconInteraction={this.onChipRemove}
                    id={key}
                    label={label}
                />
            );
        })
    }

    render() {
        const { chips, employees, employeesType, t } = this.props;
        return (    
            <ChipSet>
                <CustomInputChip
                    selecteditems={chips}
                    id="add_filter"
                    label={t('common.addFilter')}
                    employees={employees}
                    employeesType={employeesType}
                    onApply={this.addFilter}
                />
                {this.renderChips(chips)}
            </ChipSet>
        )
    }
}

ChipFilters.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
}

export default withTranslation()(ChipFilters);
