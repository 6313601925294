import { FETCH_BOOKINGS } from "../actions/bookings";

const bookings = (state = {}, action) => {
    switch(action.type) {
        case FETCH_BOOKINGS:
            let bookings = action.bookings
            return {...state, bookings}
        default:
            return state
    }
}

export default bookings;