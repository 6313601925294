import { Assignment } from '@material-ui/icons'
import Card from '../../../Card/Card'
import CardBody from '../../../Card/CardBody'
import CardHeader from '../../../Card/CardHeader'
import CardIcon from '../../../Card/CardIcon'
import Cardiovascular from './Sections/Cardiovascular'
import DentalPieces from './Sections/DentalPieces'
import Digestive from './Sections/Digestive'
import Endocrine from './Sections/Endocrine'
import Genitourinary from './Sections/Genitourinary'
import GridContainer from '../../../Grid/GridContainer'
import Neurological from './Sections/Neurological'
import OcularManifestations from './Sections/OcularManifestations'
import React from 'react'
import Respiratory from './Sections/Respiratory'
import SkinFaneras from './Sections/SkinFaneras'
import { useEffect } from 'react'

const FormPhysicalExam = (props) => {
  const {
    setDataPhysicalExam,
    save,
    setvalidatePhysicalExamForm,
    editData,
    onlyView,
  } = props

  const [dataSkinFareas, setDataSkinFareas] = React.useState(null)
  const [dataDentalPieces, setDataDentalPieces] = React.useState(null)
  const [dataOcularManifestations, setDataOcularManifestations] =
    React.useState(null)
  const [dataCardiovascular, setDataCardiovascular] = React.useState(null)
  const [dataRespiratory, setDataRespiratory] = React.useState(null)
  const [dataDigestive, setDataDigestive] = React.useState(null)
  const [dataGenitourinary, setDataGenitourinary] = React.useState(null)
  const [dataNeurological, setDataNeurological] = React.useState(null)
  const [dataEndocrine, setDataEndocrine] = React.useState(null)

  const [validateSkinSection, setValidateSkinSection] = React.useState(false)
  const [validateCardioSection, setValidateCardioSection] =
    React.useState(false)
  const [validateNeuroSection, setValidateNeuroSection] = React.useState(false)
  const [validateRespiratorySection, setValidateRespiratorySection] =
    React.useState(false)
  const [validateGenitourinarySection, setValidateRGenitourinarySection] =
    React.useState(false)
  const [validateEndocrineSection, setValidateEndocrineSection] =
    React.useState(false)

  const FormatData = () => {
    let data = {
      skinColorId: dataSkinFareas ? dataSkinFareas.skinColorId : null,
      skinInfections: dataSkinFareas ? dataSkinFareas.skinInfections : null,
      skinInfectionsDetail: dataSkinFareas
        ? dataSkinFareas.skinInfectionsDetail
        : null,
      skinLesions: dataSkinFareas ? dataSkinFareas.skinlesions : null,
      skinLesionsDetail: dataSkinFareas
        ? dataSkinFareas.skinlesionsDetail
        : null,
      skinUlcers: dataSkinFareas ? dataSkinFareas.skinUlcers : null,
      skinUlcersDetail: dataSkinFareas ? dataSkinFareas.skinUlcersDetail : null,
      dentalInfections: dataDentalPieces
        ? dataDentalPieces.dentalInfections
        : null,
      missingTeeth: dataDentalPieces ? dataDentalPieces.missingTeeth : null,
      dentures: dataDentalPieces ? dataDentalPieces.dentures : null,
      visualAcuityDisorder: dataOcularManifestations
        ? dataOcularManifestations.visualAcuityDisorder
        : null,
      glaucoma: dataOcularManifestations
        ? dataOcularManifestations.glaucoma
        : null,
      waterfalls: dataOcularManifestations
        ? dataOcularManifestations.waterfalls
        : null,
      retinopathy: dataOcularManifestations
        ? dataOcularManifestations.retinopathy
        : null,
      heartRate: dataCardiovascular ? dataCardiovascular.heartRate : null,
      bloodPressureA: dataCardiovascular
        ? dataCardiovascular.bloodPressureA
        : null,
      bloodPressureB: dataCardiovascular
        ? dataCardiovascular.bloodPressureB
        : null,
      peripheralPulses: dataCardiovascular
        ? dataCardiovascular.peripheralPulses
        : null,
      angina: dataCardiovascular ? dataCardiovascular.angina : null,
      anginaDate: dataCardiovascular ? dataCardiovascular.anginaDate : null,
      heartAttack: dataCardiovascular ? dataCardiovascular.heartAttack : null,
      heartAttackDate: dataCardiovascular
        ? dataCardiovascular.heartAttackDate
        : null,
      heartDisease: dataCardiovascular ? dataCardiovascular.heartDisease : null,
      heartDiseaseDate: dataCardiovascular
        ? dataCardiovascular.heartDiseaseDate
        : null,
      cardiovascularComments: dataCardiovascular
        ? dataCardiovascular.cardiovascularComments
        : null,
      paresis: dataNeurological ? dataNeurological.paresis : null,
      plegia: dataNeurological ? dataNeurological.plegia : null,
      atrophy: dataNeurological ? dataNeurological.atrophy : null,
      ataxia: dataNeurological ? dataNeurological.ataxia : null,
      amputation: dataNeurological ? dataNeurological.amputation : null,
      muscularStrength: dataNeurological
        ? dataNeurological.muscularStrength
        : null,
      hypesthesia: dataNeurological ? dataNeurological.hypesthesia : null,
      dementia: dataNeurological ? dataNeurological.dementia : null,
      apathy: dataNeurological ? dataNeurological.apathy : null,
      lessInterestOrPleasureId: dataNeurological
        ? dataNeurological.lessInterestOrPleasureId
        : null,
      feelingDepressedOrHopelessId: dataNeurological
        ? dataNeurological.feelingDepressedOrHopelessId
        : null,
      acv: dataNeurological ? dataNeurological.acv : null,
      acvDate: dataNeurological ? dataNeurological.acvDate : null,
      alzheimer: dataNeurological ? dataNeurological.alzheimer : null,
      alzheimerDate: dataNeurological ? dataNeurological.alzheimerDate : null,
      parkinson: dataNeurological ? dataNeurological.parkinson : null,
      parkinsonDate: dataNeurological ? dataNeurological.parkinsonDate : null,
      epilepsy: dataNeurological ? dataNeurological.epilepsy : null,
      epilepsyDate: dataNeurological ? dataNeurological.epilepsyDate : null,
      neurologyComments: dataNeurological
        ? dataNeurological.neurologicalComments
        : null,
      respiratoryRate: dataRespiratory ? dataRespiratory.respiratoryRate : null,
      hemoptysis: dataRespiratory ? dataRespiratory.hemoptysis : null,
      cough: dataRespiratory ? dataRespiratory.cough : null,
      smoking: dataRespiratory ? dataRespiratory.smoking : null,
      asthma: dataRespiratory ? dataRespiratory.asthma : null,
      asthmaDate: dataRespiratory ? dataRespiratory.asthmaDate : null,
      pneumonia: dataRespiratory ? dataRespiratory.pneumonia : null,
      pneumoniaDate: dataRespiratory ? dataRespiratory.pneumoniaDate : null,
      chronicBronchitis: dataRespiratory
        ? dataRespiratory.chronicBronchitis
        : null,
      chronicBronchitisDate: dataRespiratory
        ? dataRespiratory.chronicBronchitisDate
        : null,
      lungCancer: dataRespiratory ? dataRespiratory.lungCancer : null,
      lungCancerDate: dataRespiratory ? dataRespiratory.lungCancerDate : null,
      commentsBreathing: dataRespiratory
        ? dataRespiratory.commentsBreathing
        : null,
      reflux: dataDigestive ? dataDigestive.reflux : null,
      esophagitis: dataDigestive ? dataDigestive.esophagitis : null,
      ulcers: dataDigestive ? dataDigestive.ulcers : null,
      irritableBowel: dataDigestive ? dataDigestive.irritableBowel : null,
      malabsorptionSyndrome: dataDigestive
        ? dataDigestive.malabsorptionSyndrome
        : null,
      colonCancer: dataDigestive ? dataDigestive.colonCancer : null,
      commentsDigestion: dataDigestive ? dataDigestive.commentsDigestion : null,
      incontinence: dataGenitourinary ? dataGenitourinary.incontinence : null,
      neurogenicBladder: dataGenitourinary
        ? dataGenitourinary.neurogenicBladder
        : null,
      acuteRenalFailure: dataGenitourinary
        ? dataGenitourinary.acuteRenalFailure
        : null,
      acuteRenalFailureDate: dataGenitourinary
        ? dataGenitourinary.acuteRenalFailureDate
        : null,
      chronicRenalInsufficiency: dataGenitourinary
        ? dataGenitourinary.chronicRenalInsufficiency
        : null,
      chronicRenalInsufficiencyDate: dataGenitourinary
        ? dataGenitourinary.chronicRenalInsufficiencyDate
        : null,
      urinaryInfections: dataGenitourinary
        ? dataGenitourinary.urinaryInfections
        : null,
      urinaryInfectionsDate: dataGenitourinary
        ? dataGenitourinary.urinaryInfectionsDate
        : null,
      prostateCancer: dataGenitourinary
        ? dataGenitourinary.prostateCancer
        : null,
      prostateCancerDate: dataGenitourinary
        ? dataGenitourinary.prostateCancerDate
        : null,
      breastCancer: dataGenitourinary ? dataGenitourinary.breastCancer : null,
      breastCancerDate: dataGenitourinary
        ? dataGenitourinary.breastCancerDate
        : null,
      genitourinaryComments: dataGenitourinary
        ? dataGenitourinary.genitourinaryComments
        : null,
      hypothyroidism: dataEndocrine ? dataEndocrine.hypothyroidism : null,
      hypothyroidismDate: dataEndocrine
        ? dataEndocrine.hypothyroidismDate
        : null,
    }
    setDataPhysicalExam(data)
  }

  const validateSections = () => {
    if (
      validateSkinSection &&
      validateCardioSection &&
      validateNeuroSection &&
      validateRespiratorySection &&
      validateGenitourinarySection &&
      validateEndocrineSection
    ) {
      setvalidatePhysicalExamForm(true)
    } else {
      setvalidatePhysicalExamForm(false)
    }
  }

  useEffect(() => {
    FormatData()
    validateSections()
  }, [
    validateSkinSection,
    validateCardioSection,
    validateNeuroSection,
    validateRespiratorySection,
    validateGenitourinarySection,
    validateEndocrineSection,
    dataSkinFareas,
    dataDentalPieces,
    dataOcularManifestations,
    dataCardiovascular,
    dataNeurological,
    dataRespiratory,
    dataDigestive,
    dataGenitourinary,
    dataEndocrine,
    validateSkinSection,
    validateCardioSection,
    validateNeuroSection,
    validateRespiratorySection,
    validateGenitourinarySection,
    validateEndocrineSection,
  ])

  return (
    <Card>
      <CardHeader color="warning" icon>
        <CardIcon color="warning">
          <Assignment />
        </CardIcon>
        <h4>Examen físico</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <SkinFaneras
            setData={setDataSkinFareas}
            save={save}
            setValidateSkinSection={setValidateSkinSection}
            editData={editData}
            onlyView={onlyView}
          ></SkinFaneras>
          <DentalPieces
            setData={setDataDentalPieces}
            editData={editData}
            onlyView={onlyView}
          ></DentalPieces>
          <OcularManifestations
            setData={setDataOcularManifestations}
            editData={editData}
            onlyView={onlyView}
          ></OcularManifestations>
          <Cardiovascular
            setData={setDataCardiovascular}
            save={save}
            setValidateCardioSection={setValidateCardioSection}
            editData={editData}
            onlyView={onlyView}
          ></Cardiovascular>
          <Neurological
            setData={setDataNeurological}
            setValidateNeuroSection={setValidateNeuroSection}
            editData={editData}
            onlyView={onlyView}
            save={save}
          ></Neurological>
          <Respiratory
            setData={setDataRespiratory}
            setValidateRespiratorySection={setValidateRespiratorySection}
            editData={editData}
            onlyView={onlyView}
          ></Respiratory>
          <Digestive
            setData={setDataDigestive}
            editData={editData}
            onlyView={onlyView}
          ></Digestive>
          <Genitourinary
            setData={setDataGenitourinary}
            setValidateRGenitourinarySection={setValidateRGenitourinarySection}
            editData={editData}
            onlyView={onlyView}
          ></Genitourinary>
          <Endocrine
            setData={setDataEndocrine}
            setValidateEndocrineSection={setValidateEndocrineSection}
            editData={editData}
            onlyView={onlyView}
          ></Endocrine>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormPhysicalExam
