import { EMPLOYEES,EMPLOYEESTYPE, SUPPLIES, TOOLS } from "../actions/dashboard";
import _ from 'lodash';

const dashboard = (state = {}, action) => {
    switch(action.type) {
        case EMPLOYEES:
            const employees = action.employees
            return {...state, employees};
        case EMPLOYEESTYPE:
            const employeesType = action.employeesType
            return {...state, employeesType};
        case SUPPLIES:
            const supplies = groupSupplies(action.supplies)
            return {...state, supplies}
        case TOOLS:
            const tools = groupTools(action.tools)
            return {...state, tools}
        default:
            return state;
    }
}

const groupSupplies = (supplies) => {
    var result = _.chain(supplies)
    .groupBy('supplyCatalog.supplyCatalogId')
    .map((group) => ({ key: group[0].supplyCatalog.name, val : _.sumBy(group, 'quantity') }))
    .orderBy('val', 'desc')
    .value();

    return result;
}

const groupTools = (tools) => {
    var result = _.chain(tools)
    .groupBy('toolType.toolTypeId')
    .map((group) => ({ key: group[0].toolType.name, val : group.length }))
    .orderBy('val', 'desc')
    .value();
    return result;
}


export default dashboard;