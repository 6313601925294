import BaseInvoker from './BaseInvoker'
import { Component } from 'react'

const baseInvoker = new BaseInvoker()

export default class HozpitalizationApiInvoker extends Component {
  static getServicesCenter(callback, callerror) {
    baseInvoker.get('/api/service-center/viewAll', callback, callerror)
  }

  static getTreatingMedicals(callback, callerror) {
    baseInvoker.get('/api/treating-medical/viewAll', callback, callerror)
  }

  static addTreatingMedicals(body, callback, callerror) {
    baseInvoker.post('/api/treating-medical', body, callback, callerror)
  }

  static addDressingsType(body, callback, callerror) {
    baseInvoker.post('/api/dressings-type', body, callback, callerror)
  }

  static getDischargePlace(callback, callerror) {
    baseInvoker.get('/api/discharge-place/viewAll', callback, callerror)
  }

  static getDressingsType(callback, callerror) {
    baseInvoker.get('/api/dressings-type/viewAll', callback, callerror)
  }

  static getMedicinesType(callback, callerror) {
    baseInvoker.get('/api/medicines-type', callback, callerror)
  }

  static getFrequencyRanges(callback, callerror) {
    baseInvoker.get('/api/FrequencyRanges/viewAll', callback, callerror)
  }

  static getMedicinesUnitType(callback, callerror) {
    baseInvoker.get('/api/medicines-unit-type', callback, callerror)
  }

  static getMedicinesWayType(callback, callerror) {
    baseInvoker.get('/api/medicines-via-type', callback, callerror)
  }

  static getMedicinesPresentationType(callback, callerror) {
    baseInvoker.get('/api/medicines-presentation-type', callback, callerror)
  }

  static getAxaInternment(callback, callerror) {
    baseInvoker.get('/api/internment/viewAll', callback, callerror)
  }

  static getAxaInternmentById(id, callback, callerror) {
    baseInvoker.get(`/api/internment/${id}`, callback, callerror)
  }

  static postDischargeData(body, callback, callerror) {
    baseInvoker.post('/api/internment', body, callback, callerror)
  }

  static updateAxaInternment(body, callback, callerror) {
    baseInvoker.put('/api/internment/update', body, callback, callerror)
  }

  static filterListData(params, callback, callerror) {
    baseInvoker.get(`/api/internment-filter?${params}`, callback, callerror)
  }
}
