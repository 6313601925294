import './Customers.css'

import React, { Component } from 'react'

import Add from '@material-ui/icons/Add'
import Assignment from '@material-ui/icons/Assignment'
import Button from '../CustomButtons/Button'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'
import CardIcon from '../Card/CardIcon'
import CloudDownload from '@material-ui/icons/CloudDownload'
import Edit from '@material-ui/icons/Edit'
import FolderShared from '@material-ui/icons/FolderShared'
import GridContainer from '../Grid/GridContainer.jsx'
import GridItem from '../Grid/GridItem.jsx'
import HotelIcon from '@material-ui/icons/Hotel'
import PdfCustomer from '../PdfCustomer'
import PersonApiInvoker from '../../api/PersonApiInvoker'
import PropTypes from 'prop-types'
import Table from '../Table/Table'
import Tooltip from '@material-ui/core/Tooltip'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class Customers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customers: [],
      address: [],
      personAddress: [],
      loading: false,
    }
  }

  componentWillMount() {
    const company_partnership_id = localStorage.getItem(
      'company_partnership_id'
    )
    this.setState({ company_partnership_id })

    if (this.props.address) {
      this.setState(
        {
          address: this.props.address,
        },
        () => {
          this.props.customers && this.formatCustomers(this.props.customers)
        }
      )
    }

    this.getCustomers()
    if (this.props.customers) {
      this.formatCustomers(this.props.customers)
    } else {
      this.getCustomers()
    }
  }

  componentWillReceiveProps(next) {
    if (next.customers) {
      this.formatCustomers(next.customers)
    }

    if (next.address) {
      this.setState({ address: next.address })
    }
  }

  getAddress() {
    PersonApiInvoker.getAddress((data) => {
      this.props.onGetAddress(data)
      if (!this.props.customers) {
        this.getCustomers()
      }
    }, null)
  }

  getCustomers() {
    this.setState({ loading: true })
    PersonApiInvoker.getCustomers(
      (data) => {
        console.log(data)
        this.setState({ loading: false })
        if (data && data.length) {
          this.props.onGetCustomers(data)
        }
      },
      (e) => {
        this.setState({ loading: false })
      }
    )
  }

  exportPdfCustomer = (customer) => {
    // change this number to generate more or less rows of data
    PdfCustomer(customer)
  }

  formatCustomers = (customers) => {
    const { t } = this.props
    const data = customers.map((c) => {
      console.log(c)
      return {
        lastName: c.lastName,
        firstName: c.firstName,
        phone: c.phone && c.phone[0] ? c.phone[0].number : '',
        address: this.findPersonAddress(c.personId),
        id: c.id,
        email: c.email,
        locationName: c.locationName,
        provinceName: c.provinceName,
        generalPractitionerId: `${
          c.generalPractitionerId ? c.generalPractitionerId.name : ''
        } ${c.generalPractitionerId ? c.generalPractitionerId.lastName : ''}`,
        age: moment().diff(c.birthdate, 'years', false),
        actions: (
          <div className="customers-actions">
            <Tooltip title={t('medical_record.titleHistory')}>
              <div>
                <Button
                  simple
                  justIcon
                  color="violet"
                  authority="customers_view_mr"
                  onClick={() =>
                    browserHistory.push({
                      pathname: `/patient/${c.personId}/medical-record/full/`,
                    })
                  }
                >
                  <FolderShared />
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={t('medical_record.title')}>
              <div>
                <Button
                  simple
                  justIcon
                  color="blue"
                  authority="customers_view_mr"
                  onClick={() => this.historiaClinicaList(c.personId)}
                >
                  <FolderShared />
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={t('customers.tooltip.view')}>
              <div>
                <Button
                  simple
                  justIcon
                  color="info"
                  authority="customers_view,customers_view_related"
                  onClick={() =>
                    browserHistory.push({
                      state: { mode: 'view' },
                      pathname: `/ver-paciente/${c.personId}`,
                    })
                  }
                >
                  <Assignment />
                </Button>
              </div>
            </Tooltip>
            {this.state.company_partnership_id == null && (
              <Tooltip title={t('customers.tooltip.edit')}>
                <div>
                  <Button
                    simple
                    justIcon
                    color="success"
                    authority="customers_edit"
                    onClick={() =>
                      browserHistory.push(`/editar-paciente/${c.personId}`)
                    }
                  >
                    <Edit />
                  </Button>
                </div>
              </Tooltip>
            )}
            <Tooltip title={t('customers.tooltip.export')}>
              <div>
                <Button
                  simple
                  justIcon
                  color="warning"
                  authority="customers_export"
                  onClick={() => this.exportPdfCustomer(c)}
                >
                  <CloudDownload />
                </Button>
              </div>
            </Tooltip>
          </div>
        ),
      }
    })
    this.setState({ customers: data })
  }

  handleLastName = (e) => {
    const lastName = e.target.value

    const data = this.props.customers.filter((c) => {
      return c.lastName.toLowerCase().indexOf(lastName.toLowerCase()) !== -1
    })
    return this.formatCustomers(data)
  }

  handleFirstName = (e) => {
    const firstName = e.target.value

    const data = this.props.customers.filter((c) => {
      return c.firstName.toLowerCase().indexOf(firstName.toLowerCase()) !== -1
    })

    return this.formatCustomers(data)
  }

  handleDocument = (e) => {
    const doc = e.target.value

    const data = this.props.customers.filter((c) => {
      return c.id.toString().indexOf(doc) !== -1
    })

    return this.formatCustomers(data)
  }

  customerHistory(id) {
    browserHistory.push(`/historial/${id}`)
  }

  historiaClinicaList(id) {
    browserHistory.push(`/patient/${id}/medical-record`)
  }

  findPersonAddress(personId) {
    let address = ''
    if (this.state.personAddress && this.state.personAddress.length) {
      this.state.personAddress.map((pa) => {
        if (pa.personId === personId) {
          this.state.address.map((a) => {
            if (pa.addressId === a.addressId) {
              address = a.address
            }
          })
        }
      })
    }
    return address
  }

  render() {
    const { t } = this.props
    return (
      <div className="customers">
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="info">
                  <HotelIcon />
                </CardIcon>
                {this.state.company_partnership_id == null && (
                  <Button
                    className="add-content-button customers-button"
                    round
                    color="primary"
                    authority="customers_new"
                    onClick={() => browserHistory.push('nuevo-paciente')}
                  >
                    <Add className="customers-button-icon" />{' '}
                    {t('customer.create')}
                  </Button>
                )}
              </CardHeader>
              <CardBody className="not-scroll-table filtrable-table">
                <Table
                  striped
                  filterable
                  loading={this.state.loading}
                  tableHeaderColor="primary"
                  tableHead={[
                    {
                      Header: t('customers.table.first_name'),
                      accessor: 'firstName',
                    },
                    {
                      Header: t('customers.table.last_name'),
                      accessor: 'lastName',
                    },
                    { Header: t('customers.table.id'), accessor: 'id' },
                    {
                      Header: t('customers.table.locationName'),
                      accessor: 'locationName',
                    },
                    {
                      Header: t('customers.table.provinceName'),
                      accessor: 'provinceName',
                    },

                    { Header: t('customers.table.email'), accessor: 'email' },
                    {
                      Header: t('customers.table.doctor'),
                      accessor: 'generalPractitionerId',
                    },
                    {
                      Header: t('customers.table.actions'),
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  tableData={this.state.customers}
                  colorsColls={['primary']}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

Customers.propTypes = {
  onGetCustomers: PropTypes.func.isRequired,
  onGetAddress: PropTypes.func.isRequired,
  onGetPersonAddress: PropTypes.func.isRequired,
  customers: PropTypes.array,
  address: PropTypes.array,
  personAddress: PropTypes.array,
}

export default withTranslation()(Customers)
