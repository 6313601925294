import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Edit, Delete } from '@material-ui/icons';
import ConfirmAlert from '../ConfirmAlert';
import Snackbar from '../Snackbar/Snackbar';
import Button from '../CustomButtons/Button';
import Table from '../Table/Table';
import BusinessApiInvoker from '../../api/BusinessApiInvoker';

class Financiers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            financiers: [],
            loading: true,
            deleteAlert: false,
            selectedFinancier: '',
            success: false,
            error: false,
            snackbarColor: 'info',
            snackbarOpen: false,
            alertMessage: '',
        }
    }

    componentWillReceiveProps(next) {
        if (next.personId && next.refresh) {
            this.findFinanciers(next.personId);
        }
    }

    findFinanciers(personId) {
        BusinessApiInvoker.getCustomersFinanciers(personId, financiersData => {
            const financiers = this.formatFinanciers(financiersData);
            this.setState({
                financiers: financiers,
                loading: false,
            });
        }, null);
    }

    formatFinanciers(financiers) {
        const { t } = this.props;
        const result = financiers.map(f => {
            return {
                financierId: f.financierId,
                name: f.financier.businessName,
                affiliateId: f.affiliateId,
                active: f.financier.active ? t('active') : t('inactive'),
                actions: (this.props.componenMode === 'view') ? '' :
                    <div>
                        <Button
                            simple
                            justIcon
                            color="success"
                            onClick={() => this.editFinancier(f)}
                        >
                            <Edit />
                        </Button>
                        <Button
                            simple
                            justIcon
                            color="danger"
                            onClick={() => this.deleteFinancier(f.financierId)}
                        >
                            <Delete />
                        </Button>
                    </div>
            }
        })
        return result;
    }

    editFinancier(financier) {
        this.props.onEditFinancier(financier);
    }

    deleteFinancier(financierId) {
        this.setState({
            deleteAlert: true,
            selectedFinancier: financierId,
        });
    }

    /* delete item */
    deleteItem() {
        const { financiers, selectedFinancier } = this.state;
        BusinessApiInvoker.deleteCustomersFinanciers(this.props.personId, selectedFinancier, data => {
            const filterFinanciers = financiers.filter(f => f.financierId !== selectedFinancier);
            this.setState({
                success: true,
                financiers: filterFinanciers,
            });
        }, err => {
            console.error('** Error deleting financier:', err);
            this.setState({
                error: false,
                success: false,
                deleteAlert: false,
            });
            this.openAlert('danger', err.message);
        });
    }

    openAlert(color, message) {
        this.setState({
            alertMessage: message,
            snackbarColor: color,
            snackbarOpen: true,
        });

        setTimeout(() => {
            this.setState({
                alertMessage: '',
                snackbarOpen: false,
            });
        }, 6000);
    }

    closeAlert() {
        this.setState({
            alertMessage: '',
            deleteAlert: false,
            error: false,
            success: false,
        });
    }

    render() {
        const { t, componenMode } = this.props;
        const {
            deleteAlert, success, error, financiers, loading, snackbarColor, alertMessage, snackbarOpen,
        } = this.state;

        return (
            <div className="financers-table">
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={true}
                    tableHead={[
                        { Header: t('common.financier'), accessor: 'name' },
                        { Header: t('affiliate.number'), accessor: 'affiliateId' },
                        { Header: t('status'), accessor: 'active' },
                        { Header: (componenMode === 'view') ? '' : t('actions'), accessor: 'actions', sortable: false },
                    ]}
                    tableData={financiers}
                    colorsColls={['primary']}
                />
                <ConfirmAlert
                    text={t('common.financier')}
                    onClose={() => this.closeAlert()}
                    onSuccess={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    messageDeletedUnsuccess={alertMessage}
                    show={deleteAlert}
                    success={success}
                    error={error}
                />
                <Snackbar
                    place="tr"
                    color={snackbarColor}
                    message={alertMessage}
                    open={snackbarOpen}
                />
            </div>
        )
    }
}

Financiers.propTypes = {
    personId: PropTypes.any,
    onEditFinancier: PropTypes.func,
}

export default withTranslation()(Financiers);
