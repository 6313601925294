export const FETCH_MODULES = 'FETCH_MODULES'
export const FETCH_PRACTICE_TYPES = 'FETCH_PRACTICE_TYPES'
export const SAVE_SCHEDULE = 'SAVE_SCHEDULE'
export const SAVE_DATES = 'SAVE_DATES'
export const RESET_SCHEDULE = "RESET_SCHEDULE"
export const APPOINTMENT = "APPOINTMENT"

export function fetchModules(modules) {
    return {type: FETCH_MODULES, modules}
}

export function fetchPracticeTypes(practiceTypes) {
    return {type: FETCH_PRACTICE_TYPES, practiceTypes}
}


export function saveSchedule(schedule) {
    return {type: SAVE_SCHEDULE, schedule}
}

export function saveDates(dates) {
    return {type: SAVE_DATES, dates}
}

export function resetSchedule(dates) {
    return {type: RESET_SCHEDULE, dates}
}

export function setAppointment(appointment) {
    return {type: APPOINTMENT, appointment}
}