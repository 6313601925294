import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class VerificationApiInvoker extends Component {

	static getVerificationErrors(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/verification-errors`, callback, callerror);
	}

}