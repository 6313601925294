export const CUSTOMERS_DETAIL = 'CUSTOMERS_DETAIL'
export const CUSTOMERS_ADDRESS = 'CUSTOMER_ADDRESS'
export const CUSTOMERS_PERSON_ADDRESS = 'CUSTOMERS_PERSON_ADDRESS'
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const CLEAN_CUSTOMER = 'CLEAN_CUSTOMER';

export function getCustomers(customers) {
    return {type: CUSTOMERS_DETAIL, customers}
}

export function getAddress(address) {
    return {type: CUSTOMERS_ADDRESS, address}
}

export function getPersonAddress(personAddress) {
    return {type: CUSTOMERS_PERSON_ADDRESS, personAddress}
}

export function addCustomer(customer) {
    return {type: ADD_CUSTOMER, customer};
}

export function editCustomer(customer) {
    return {type: EDIT_CUSTOMER, customer};
}

export function cleanCustomer() {
    return {type: CLEAN_CUSTOMER};
}