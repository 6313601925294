import { connect } from 'react-redux';
import { getFinanciers, getProvincies } from "../actions/dashboardeconomictool";
import DashboardEconomicTool from '../components/DashboardEconomicTool/DashboardEconomicTool.js';
import Filters from '../components/DashboardEconomicTool/Filters';

const mapStateToProps = (state) => {
    return {
        financiers: state.dashboardeconomictool.financiers,
        provincies: state.dashboardeconomictool.provincies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetFinanciers: (financiers) => {
            dispatch(getFinanciers(financiers))
        },
        onGetProvincies: (provincies) => {
            dispatch(getProvincies(provincies))
        }
    }
}

const DashboardEconomicToolService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardEconomicTool);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters)

export {DashboardEconomicToolService, FiltersService};