import { MODULES, ADD_MODULE, EDIT_MODULE, UNMOUNT_MODULE } from "../actions/modules";

const modules = (state = {}, action, root) => {
    switch(action.type) {
        case UNMOUNT_MODULE:
            return {}
        case MODULES:
            let modules = action.modules
            return {...state, modules}
        case ADD_MODULE: 
                const result = addModule(state.modules, action.module)
                return {...state, modules: result}
        case EDIT_MODULE: 
                const updated = updateModule(state.modules, action.module)
                return {...state, modules: updated}
        default:
            return state;
    }
}

const addModule = (modules, mod) => {
    if (modules && modules.length) {
        modules.push(mod);
        return modules;
    }
    return;
}

const updateModule = (modules, mod) => {
    if (modules && modules.length) {
        modules.map((m, i) => {
            if (m.packageId === mod.packageId) {
                modules[i] = mod
                return;
            }
        })
    }
    return modules;
}

export default modules;