import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import RadioInput from '../../RadioInput';

const DisabilityForm = ({ disabled, onFilledDisability, t, value }) => {
  const [disability, setDisability] = useState({
    disabledPatient: false,
    activeDisabilityCertificate: false,
    disabilityCertificateCode: null,
  });

  useEffect(() => {
    if (value && value.metadata) {
      const { disabledPatient,  activeDisabilityCertificate } = value.metadata || {};
      setDisability({ disabledPatient, activeDisabilityCertificate });
    }
  }, [value]);

  const handleChange = (field, value) => {
    const valueRadio = value === '1' ? true : false
    if (field === 'disabledPatient' && value === '2') {
      const disabilityValue = {
        [field]: valueRadio,
        activeDisabilityCertificate: false,
        disabilityCertificateCode: null,
      }
      setDisability(disabilityValue);
      onFilledDisability(disabilityValue);
    } else {
      const disabilityValue2 = {
        ...disability,
        [field]: valueRadio,
      }
      setDisability(disabilityValue2);
      onFilledDisability(disabilityValue2);
    }
  };

  return (
    <div className="disability-form">
      <GridContainer className="margin-top disabled-patient">
        <GridItem xs={12}>
          <h4 className="margin-top">
            {t('appointment.disabledPatient.title')}
          </h4>
        </GridItem>
        <GridItem xs={12} sm={6} md={5} className="radio-input-inline">
          <p className="radio-legend">
            {t('appointment.disabledPatient')}
          </p>
          <RadioInput
            name="disabledPatient"
            value={disability.disabledPatient === true ? '1' : '2'}
            elements={[
              { id: '1', value: t('form.option.yes') },
              { id: '2', value: t('form.option.no') }
            ]}
            onChangeValue={(value) => handleChange('disabledPatient', value)}
            inputProps={{
              disabled: disabled,
            }}
          />
        </GridItem>
        {disability.disabledPatient === true && (
          <GridItem xs={12} sm={6} md={5} className="radio-technical-file radio-input-inline">
            <p className="radio-legend">
              {t('appointment.activeDisabilityCertificate')}
            </p>
            <RadioInput
              name="activeDisabilityCertificate"
              value={disability.activeDisabilityCertificate === true ? '1' : '2'}
              elements={[
                { id: '1', value: t('form.option.yes') },
                { id: '2', value: t('form.option.no') }
              ]}
              onChangeValue={(value) => handleChange('activeDisabilityCertificate', value)}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>)
        }
    </GridContainer>
  </div>
  )
}

DisabilityForm.propTypes = {
  onFilledDisability: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    metadata: {
      disabledPatient: PropTypes.bool,
      activeDisabilityCertificate: PropTypes.bool,
    },
  }),
}

export default withTranslation()(DisabilityForm);
