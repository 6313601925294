import '../../../DiabeticProgramStyles.css'

import DiabeticApiInvoker from '../../../../../api/DiabeticApiIvoker'
import GridItem from '../../../../Grid/GridItem'
import React from 'react'
import SelectInput from '../../../../SelectInput'
import { useEffect } from 'react'
import { useMemo } from 'react'

const Laboratory = (props) => {
  const { setData, editData, onlyView } = props
  const [glycemiaId, setGlycemiaId] = React.useState(null)
  const [glycosylatedHemoglobinId, setGlycosylatedHemoglobinId] =
    React.useState(null)
  const [cholesterolId, setCholesterolId] = React.useState(null)
  const [triglyceridesId, setTriglyceridesId] = React.useState(null)
  const [glucemiaOptions, setGlucemiaOptions] = React.useState([])
  const [colesterolOptions, setColesterolOptions] = React.useState([])
  const [hemoglobinaGlicolisadaOptions, setHemoglobinaGlicolisadaOptions] =
    React.useState([])
  const [triglicéridosOptions, setTriglicéridosOptions] = React.useState([])

  const FormatData = () => {
    let data = {
      glycemiaId: glycemiaId,
      glycosylatedHemoglobinId: glycosylatedHemoglobinId,
      cholesterolId: cholesterolId,
      triglyceridesId: triglyceridesId,
    }
    setData(data)
  }

  const formatOptions = (index) =>
    index.map((e) => ({
      id: e.id,
      value: e.label,
    }))

  const getLaboratoryOptions = () => {
    DiabeticApiInvoker.getAllGlucemiaOptions((data) => {
      let laboratoryOptions = formatOptions(data)
      setGlucemiaOptions(laboratoryOptions)
    })
    DiabeticApiInvoker.getAllColesterolOptions((data) => {
      let laboratoryOptions = formatOptions(data)
      setColesterolOptions(laboratoryOptions)
    })
    DiabeticApiInvoker.getAllHemoglobinaGlicolisadaOptions((data) => {
      let laboratoryOptions = formatOptions(data)
      setHemoglobinaGlicolisadaOptions(laboratoryOptions)
    })
    DiabeticApiInvoker.getAllTriglicéridosOptions((data) => {
      let laboratoryOptions = formatOptions(data)
      setTriglicéridosOptions(laboratoryOptions)
    })
  }

  const setEditData = (data) => {
    setGlycemiaId(data.glycemiaId)
    setGlycosylatedHemoglobinId(data.glycosylatedHemoglobinId)
    setCholesterolId(data.cholesterolId)
    setTriglyceridesId(data.triglyceridesId)
  }

  const GetOptions = useMemo(() => {
    getLaboratoryOptions()
    if (editData) {
      setEditData(editData)
    }
  }, [editData])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    GetOptions
    FormatData()
  }, [glycemiaId, glycosylatedHemoglobinId, cholesterolId, triglyceridesId])

  return (
    <>
      <GridItem md={12}>
        <h4>Laboratorio</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Glucemia:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setGlycemiaId(value)}
            elements={glucemiaOptions}
            value={glycemiaId}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Hemoglobina glicosilada:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setGlycosylatedHemoglobinId(value)}
            elements={hemoglobinaGlicolisadaOptions}
            value={glycosylatedHemoglobinId}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Colesterol:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setCholesterolId(value)}
            elements={colesterolOptions}
            value={cholesterolId}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Triglicéridos:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setTriglyceridesId(value)}
            elements={triglicéridosOptions}
            value={triglyceridesId}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
    </>
  )
}

export default Laboratory
