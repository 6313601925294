import { connect } from 'react-redux';
import NewToolType from '../components/NewToolType';
import {  editToolType, addToolType } from '../actions/tooltypes';
import EditToolType from '../components/EditToolType';

const mapStateToProps = (state) => {
    return {
        documentTypes: state.newCustomer.documentTypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        onAddToolType: (tooltype) => {
            dispatch(addToolType(tooltype))
        },
        onEditToolType: (tooltype) => {
            dispatch(editToolType(tooltype))
        }
    }
}

const NewToolTypeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewToolType);

const EditToolTypeService = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditToolType);

export default {NewToolTypeService, EditToolTypeService};