import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import Button from '../../../../CustomButtons/Button';
import CustomDialog from '../../../../CustomDialog';
import SchedulePlan from '../../../../SchedulePlan';
import TimePickerGCalendar from '../../../../TimePickerGCalendar';
import Snackbar from '../../../../Snackbar/Snackbar';

import './Modules.css';

class ModalConfigurePractices extends Component {

    constructor(props) {
        super(props);

        const { t } = this.props;
        const typesState = [{
            id: 1,
            value: t('Practice'),
        }, {
            id: 2,
            value: t('Package'),
        }];

        this.state = {
            open: false,
            types: typesState,
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            suggested: [],
            practices: [],
            shifts: [1, 2, 3],
            alertMessageColor: '',
            alertMessageMsg: '',
            alertMessageOpen: false

        }
    }

    componentDidMount() {
        this.fillSuggested();
    }

    componentWillReceiveProps(next) {
        if (next.practices) {
            this.setState({ practices: next.practices });
        }
        this.setState({ open: next.open });
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        })
        this.props.onCloseModal();
    }

    selectedType(value) {
        this.setState({
            type: value,
            current: null,
            component: value === 2 ? 'module' : 'plan',
        });
    }

    createModule(value) {
        this.closeModal();
        this.props.onCreatedModule(value);
    }

    createPlan(value) {
        this.closeModal();
        this.props.onCreatedPlan(value);
    }

    handleValue(index, value, state, shift, packageDetailId) {
        const practice = this.state.practices;
        if (state === 'recurrence') {
            practice[index]['schedulePlan']['weekRecurrence'] = value.weekRecurrence;
            practice[index]['schedulePlan']['monthRecurrence'] = value.monthRecurrence;
            practice[index]['schedulePlan']['monthDay'] = value.monthRecurrence;
        } else {
            practice[index][state] = value;
        }
        this.setState({ practices: practice });
    }

    fillSuggested = () => {
        if (this.state.suggested.length === 0) {
            let sa = [];
            this.props.module.packageDetail.map(m => {
                let shifts = this.fillArray(m);
                shifts.map(t => {
                    let row = {};
                    row['packageDetailId'] = m.packageDetailId;
                    row['practiceTypeId'] = m.practiceType.practiceTypeId;
                    let detail = this.findSuggestedFrontItems(m.practiceType.practiceTypeId, t, shifts.length !== 1);
                    // AQUI
                    if (detail) {
                        row['suggestedEmployeeId'] = detail.suggestedEmployeeId;
                        row['filerByZone'] = detail.filerByZone;
                    } else {
                        row['suggestedEmployeeId'] = null;
                        row['filerByZone'] = true;
                    }
                    row['order'] = t;
                    sa.push(row);
                });
            });
            // alert(JSON.stringify(sa));
            this.setState({ suggested: sa });
        }
    }

    findSuggestedFrontItems = (practice, shift, hasShift) => {
        let item;
        if (this.props.itemRequestDetails) {
            this.props.itemRequestDetails.map(m => {
                if (!hasShift) {
                    if (m.practiceTypeId === practice) {
                        item = m;
                    }
                } else {
                    if (m.practiceTypeId === practice && m.order === shift) {
                        item = m;
                    }
                }
            });
        }
        return item;
    }

    fillArray = (item) => {
        const estimatedDuration = item.practiceType.estimatedDuration;
        const processingScheduleType = item.schedulePlan.processingScheduleType;
        const processingDurationTime = item.schedulePlan.processingDurationTime;
        const tur = processingDurationTime / estimatedDuration;

        const ret = [];
        if (processingScheduleType === 'INTRADAY') {
            for (let idx = 0; idx < tur; idx++) {
                ret.push(idx + 1);
            }
        } else {
            ret.push(1);
        }
        return ret;
    }

    setInvalidPlan = (i, value) => {
    }

    isInvalidate = () => {
        let daysOk = false;
        if (this.state.practices.length == 0) {
            return false;
        }
        this.state.practices.forEach(m => {
            if (m.schedulePlan.weekRecurrence && m.schedulePlan.originaSelected) {
                const weekRecurrence = Array.isArray(m.schedulePlan.weekRecurrence) ?
                    m.schedulePlan.weekRecurrence : m.schedulePlan.weekRecurrence.split(',')
                if ((weekRecurrence.length != m.schedulePlan.originaSelected.split(',').length)) {
                    daysOk = true;
                }

            }
        });
        return daysOk;
    }

    handleChangeExpaned = (i) => {
        const practices = this.state.practices;
        practices[i].expanded = !practices[i].expanded;
        this.setState({ practices: practices });
    }


    saveModule = () => {
        const { t } = this.props;

        for (let idx = 0; idx < this.state.practices.length; idx++) {
            const m = this.state.practices[idx];
            if (m.startTime == '' || m.endTime == '' || 
                (m.schedulePlan.recurrenceType === 'MONTH' && !m.schedulePlan.monthRecurrence) ||
                (m.schedulePlan.recurrenceType === 'MONTH' && m.schedulePlan.monthRecurrence < 1) ||
                (m.schedulePlan.recurrenceType === 'MONTH' && m.schedulePlan.monthRecurrence > 31) 
                ) {
                this.openAlert('warning', t('appointment.new.requiredFields')); 
                return;
            }
        }
        this.props.onConfirmModal(this.state.practices);
    }

    openAlert = (color, message) => {
    
        this.setState({
          alertMessageColor: color,
          alertMessageMsg: message,
          alertMessageOpen: true
        });

        setTimeout(() => {
          this.setState({ alertMessageOpen: false });
        }, 5000);
      }

    render() {
        const { t } = this.props;
        let index = -1;
        return (
            <CustomDialog
                classTitle2="dialog-title-h3"
                maxWidth="md"
                title={t('module.configure.practices.title')}
                open={this.state.open}
                onClose={() => this.closeModal()}
            >
                <Snackbar
                    place="tr"
                    color={this.state.alertMessageColor}
                    message={this.state.alertMessageMsg}
                    open={this.state.alertMessageOpen}
                />
                <GridContainer className="ps-child modules-form assign-professionals-form">
                    <GridItem xs={12}>
                        <span className="practice-title-acordion">{this.props.module && this.props.module.name ? this.props.module.name : ""}</span>
                    </GridItem>
                    {this.state.practices.map((item, i) => {
                        let appointmentAction = '';
                        const recurrence = item.schedulePlan;
                        const selectedDuration = item.practiceType.estimatedDuration;

                        if (recurrence.processingScheduleType === 'INTRADAY') {
                            recurrence.recurrenceType = 'INTRADAY';
                            recurrence.quantityIntraday = recurrence.processingDurationTime / selectedDuration;
                        }
                        if (recurrence.processingScheduleType === 'INTRADAY' || recurrence.recurrenceType === 'WEEK') {
                            recurrence.originaSelected = item.schedulePlan.weekDays;
                            index = index + 1;
                        }
                        const dates = {}
                        const savePlan = false;
                        const startTime = item.startTime;
                        const endTime = item.endTime;
                        const endDate = '';
                        const practice_start_time_name = "practice_start_time_" + i;
                        const practice_end_time_name = "practice_end_time_" + i;
                        const estimatedDuration = moment.utc(moment.duration(parseInt(selectedDuration), 'minutes').asMilliseconds()).format('HH:mm') + t('estimated_minutes');
                        return (
                            <GridContainer className="ps-child modules-form assign-professionals-form">
                                <GridItem xs={12} sm={12} md={12}>
                                    <Accordion expanded={item.expanded} onChange={value => this.handleChangeExpaned(i)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography><span className="practice-title-acordion">{item.practiceType.name}</span></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <GridContainer>
                                                <GridItem xs={12} className="base-font availability-label">
                                                    <p className="padding-horizontal practice-time-estimated-gray">{t('estimated_label')}: <span className="practice-time-estimated-black">{estimatedDuration}</span></p>
                                                </GridItem>

                                                <GridItem xs={12} className="base-font availability-label">
                                                    <h4 className="padding-horizontal">{t('appointment.new.schedule.practice.availability')}</h4>
                                                </GridItem>

                                                <GridItem xs={6}>
                                                    <TimePickerGCalendar name={practice_start_time_name} text={t('appointment.new.schedule.practice.start_time')} value={startTime} onSelectedValue={(value) => this.handleValue(i, value, 'startTime')} defaultValue="00:00" invalid={!startTime} errorText={!startTime ? t('error.required') : ''} />
                                                </GridItem>

                                                <GridItem xs={6}>
                                                    <TimePickerGCalendar name={practice_end_time_name} text={t('appointment.new.schedule.practice.end_time')} value={endTime} onSelectedValue={(value) => this.handleValue(i, value, 'endTime')} defaultValue="23:30" invalid={!endTime} errorText={!endTime ? t('error.required') : ''} />
                                                </GridItem>

                                                <SchedulePlan
                                                    className="schedule-plan-component"
                                                    appointmentAction={appointmentAction}
                                                    practiceDuration={selectedDuration && selectedDuration.estimatedDuration}
                                                    current={recurrence}
                                                    endDate={endDate}
                                                    dates={dates}
                                                    indexArrayDays={index}
                                                    insideModule={true}
                                                    onCreatedRecurrence={(value) => this.handleValue(i, value, 'recurrence')}
                                                    savePlan={savePlan}
                                                    invalidPlan={(value) => this.setInvalidPlan(i, value)}
                                                />
                                            </GridContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </GridItem>
                            </GridContainer>
                        );
                    }
                    )}

                    <GridItem className="schedule-form-submit" xs={12}>
                        {this.props.isOwnLender &&
                            <Button color="info" onClick={() => this.props.onSuggestedProfesionals()}>
                                {t('suggested.assignProfessionals')}
                            </Button>
                        }
                        <Button color="success" disabled={this.isInvalidate()} onClick={() => this.saveModule()}>
                            {t('configure.practiceType.save')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CustomDialog>
        )
    }
}

ModalConfigurePractices.defaultProps = {
    onGetModuleTypes: () => [],
    onGetPracticeTypes: () => [],
    isOwnLender: false,
}

ModalConfigurePractices.propTypes = {
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onCreatedModule: PropTypes.func,
    onCreatedPlan: PropTypes.func,
    onGetModuleTypes: PropTypes.func,
    onGetPracticeTypes: PropTypes.func,
    current: PropTypes.object,
    action: PropTypes.string,
    isOwnLender: PropTypes.bool,
}

export default withTranslation()(ModalConfigurePractices);
