import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Address(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M12,1C8.686,1,6,3.686,6,7c0,4.286,6,11,6,11s6-6.714,6-11C18,3.686,15.314,1,12,1z M12,9.143c-1.183,0-2.143-0.959-2.143-2.143c0-1.184,0.959-2.143,2.143-2.143S14.143,5.816,14.143,7C14.143,8.183,13.183,9.143,12,9.143z"/><path d="M19.2,15h-2.388c-0.485,0.731-0.971,1.408-1.419,2h2.454l1.2,3H4.953l1.2-3h2.454c-0.448-0.592-0.934-1.269-1.419-2H4.8L2,22h20L19.2,15z" />
    </SvgIcon>
  );
}



