import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import ChartistGraph from 'react-chartist';
import CardBody from '../../Card/CardBody';
import CardIcon from '../../Card/CardIcon';
import DashboardApiInvoker from "../../../api/DashboardApiInvoker";
import '../Dashboard.css';
import CardFooter from '../../Card/CardFooter';
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import BarChart from '@material-ui/icons/BarChart'
import PieChart from '@material-ui/icons/PieChart'

class QuantityChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total: 0,
            series: {
                labels: [],
                series: [],
                colors: [],
            }
        }
    }

    componentWillReceiveProps(next) {
        if (next.data) {
            this.formatSeries(next.data, next.column);
        }

        if (next.clear) {
            this.setState({
                series: [],
            });
        }
    }

    formatSeries(data, column) {
        let labels = []
        let series = []
        var colors = []
        var json_data = {}
        var total = 0;
        if (data && data.length) {
            data.map(s => {
                var value = s[column];
                if (value) {
                    if (value.length > 30) {
                        value = value
                    }
                    if (json_data[value]) {
                        json_data[value] = json_data[value] + 1;
                    } else {
                        json_data[value] = 1;
                    }
                }
            })
        }
        var idx = 0;

        for(var i in json_data) {
            labels.push(i);
            colors.push("pie_color" + (idx+1));
            series.push({value: json_data[i], className: colors[idx]});
            total = total + json_data[i];
            idx++
        }
        this.setState({
            total: total,
            series: {
                labels: labels,
                series: series,
                colors: colors,
            }
        });
    }

    render() {
        const options = {
            // If high is specified then the axis will display values explicitly up to this value and the computed maximum from the data is ignored
            high: 100,
            // If low is specified then the axis will display values explicitly down to this value and the computed minimum from the data is ignored
            low: 0,
            // This option will be used when finding the right scale division settings. The amount of ticks on the scale will be determined so that as many ticks as possible will be displayed, while not violating this minimum required space (in pixel).
            scaleMinSpace: 20,
            // Can be set to true or false. If set to true, the scale will be generated with whole numbers only.
            onlyInteger: true,
            // The reference value can be used to make sure that this value will always be on the chart. This is especially useful on bipolar charts where the bipolar center always needs to be part of the chart.
            referenceValue: 5,
            height: '330px',
            chartPadding: 35,
            labelOffset: 90,
            labelInterpolationFnc: function(label, index) {
                if (self.state.series.series.length == 0) {
                    return 0;
                } 
                return Math.round(self.state.series.series[index].value / self.state.total * 100) + '%';
            }

        };
        
        var self = this;
        return (
            <Card className="quantity-chart">
                <CardHeader color="info" icon>
                    <CardIcon color="info">
                        {this.props.icon ? <PieChart/> : <BarChart />}
                    </CardIcon>
                    <h3 className="card-icon-title">
                        {this.props.title}
                    </h3>
                    
                </CardHeader>
                <CardBody>
                    <ChartistGraph
                        className="chat-style"
                        data={this.state.series}
                        options={options}
                        show={false}
                        ticks={['One', 'Two', 'Three']}
                        donut={true}
                        type="Pie"/>
                </CardBody>
                <CardFooter>
                    <GridContainer className="dashboard-charts">
                        <GridItem xs={12}>
                            <div style={{width: '100%', marginBottom:10, justifyContent: 'center', display: 'block', alignItems: 'center', justifyItems: 'center'}}>
                                <h6 className='legend'>Leyenda</h6>
                                {this.state.series.colors && this.state.series.colors.length >0 && this.state.series.labels.map(function(item, i){
                                    return(
                                        <span>
                                            <span style={{width: 10, height: 10, marginLeft: 10}} className={"dot "  + self.state.series.colors[i]}></span>
                                            <span className='labelLeyend'>{item !== 'undefined' ? item : ""}</span>
                                        </span>
                                    );
                                })}
                            </div>
                    
                    </GridItem>
                    </GridContainer>
                </CardFooter>
            </Card>
        )
    }
}

QuantityChart.propTypes = {
    options: PropTypes.object,
    employeeId: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    find: PropTypes.bool,
    clear: PropTypes.bool,
}

export default QuantityChart;