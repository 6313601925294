import { GEOGRAPHICZONES } from '../actions/geographicZones';

const geographicZones = (state = {}, action) => {
    switch(action.type) {
        case GEOGRAPHICZONES:
            let geographicZones = action.geographicZones
            return {...state, geographicZones}
        default:
            return state
    }
}

export default geographicZones;