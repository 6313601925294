import React, { useState, useEffect } from 'react'
import { Checkbox } from '@material-ui/core';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Check, Assignment } from '@material-ui/icons';
import ConfirmAlert from '../../ConfirmAlert';
import RadioInput from '../../RadioInput';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Card from '../../Card/Card';
import CardIcon from '../../Card/CardIcon';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';
import Button from '../../CustomButtons/Button';
import Table from '../../Table/Table';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import { DeleteDocument } from '../../../icons';
import stylesCheckbox from '../../../assets/components/customCheckboxRadioSwitch';

const useStyles = makeStyles(stylesCheckbox);

const styles = makeStyles({
  radioInputContainer: {
    display: "flex",
    flexDirection: "column !important",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
})

const AppointmentCancel = ({ t, appointments, params }) => {
  const [appointment, setAppointment] = useState({
    createdDateTime: new Date(),
  });
  const [ detailText, setDetailText ] = useState('');
  const [ appointmentCancelableDetail, setappointmentCancelableDetail ] = useState([]);
  const [ alreadyCancelled, setAlreadyCancelled ] = useState([])
  const [ confirmationModal, setConfirmationModal ] = useState(false);
  const [ successModal, setSuccessModal ] = useState(false);
  const [ errorModal, setErrorModal ] = useState(false);
  const [ cancellationReason, setCancelationReason ] = useState('');
  const [ cancellationType, setCancelationType ] = useState(1);
  const [ markedToBeCancelled, setMarkedToBeCancelled ] = useState([]);

  const changeCancelationType = (type) => {
    if(type === 1) {
      setMarkedToBeCancelled([]);
    }
    setCancelationType(type);
  }

  const markToBeCanceled = (checked, newOne) => {
    if(checked) {
      setMarkedToBeCancelled(markedToBeCancelled.concat([newOne]));
    } else {
      setMarkedToBeCancelled(markedToBeCancelled.filter(id => id !== newOne));
    }
  }

  const performCancelation = () => {
    const body = {
      "reason": cancellationReason,
      "appointmentDetailIds": markedToBeCancelled
    }
    AppointmentApiInvoker.cancelAppointment(params.id, body, () => {
      setConfirmationModal(false);
      setErrorModal(false);
      setSuccessModal(true);
    }, () => {
      setConfirmationModal(false);
      setSuccessModal(false);
      setErrorModal(true);
    });
  }

  const cancel = () => {
    browserHistory.push('/solicitudes');
  }

  useEffect(() => {
    AppointmentApiInvoker.getAppointmentCancellations(params.id, 
      (res) => {
        let canceled = res.filter(p => p.canceled)
        .map(p => p.appointmentDetailId);
        setAlreadyCancelled(canceled)
        setappointmentCancelableDetail(res)
      }, (e) => {
        console.error("Error getting cancelable practices: ", e)
      })
  }, [params.id])

  useEffect(() => {
    const selectedApp = appointments && appointments.find && appointments.find(app => app.appointmentId.toString() === params.id);
    setAppointment(selectedApp)
  }, [params.id])

  const isChecked = (practice) => { 
    const marketToBeCancelled = markedToBeCancelled.indexOf(practice.appointmentDetailId) > -1;
    const canceled = alreadyCancelled.indexOf(practice.appointmentDetailId) > -1;
    return marketToBeCancelled || canceled;
  }

  useEffect(() => {
    const detailText = cancellationType === 2 ? markedToBeCancelled.map((detailId) => {
        const detail = appointmentCancelableDetail.find(app => app.appointmentDetailId === detailId) || {};
        return `- ${t(detail.type)} ${detail.order}: ${detail.speciality}`
      })
      : ''
    setDetailText(detailText)
  }, [cancellationType, markedToBeCancelled, appointmentCancelableDetail])

  const classes = styles();
  const classesCheck = useStyles();

  return (
    <GridContainer className="cancellation-form">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer className={classes.spaceBetween}>
              <CustomInput
                id="appointment_id"
                labelText={t('appointment.input.appointment_id')}
                value={params.id}
                formControlProps={{ disabled: true}}
              />
              <CustomInput
                id="init_date"
                labelText={t('appointment.input.init_date')}
                value={
                  appointment ? moment(appointment.createdDateTime).format('DD/MM/YYYY')
                  : null
                }
                formControlProps={{
                  disabled: true,
                  fullWidth: true,
                }}
              />
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="danger">
              <DeleteDocument />
            </CardIcon>
            <h3 className="card-icon-title">{t('appointment.cancel.title')}</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem className="cancellation-content" xs={12}>
                <RadioInput
                  className={classes.radioInputContainer}
                  title={''}
                  value={cancellationType}
                  onChangeValue={(value) => { changeCancelationType(parseInt(value)) }}
                  elements={[
                    { id: 1, value: t('appointment.cancel.total_cancellation'), component: true, },
                    { id: 2, value: t('appointment.cancel.partial_cancellation'), component: false, }
                  ]}
                />
                {cancellationType === 2 && (
                  <Table 
                    loading={false}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={false}
                    tableHead={[
                      { Header: '', accessor: 'select' },
                      { Header: t('cancellations.order'), accessor: 'id' },
                      { Header: t('cancellations.type'), accessor: 'type' },
                      { Header: t('cancellations.name'), accessor: 'speciality' },
                      { Header: t('cancellations.date_init'), accessor: 'startDate' },
                      { Header: t('cancellations.quantity'), accessor: 'quantity' },
                    ]}
                    tableData={appointmentCancelableDetail.map((practice) => {
                      return {
                        'select': <Checkbox
                          tabIndex={-1}
                          onChange={(e) => markToBeCanceled(e.target.checked, practice.appointmentDetailId)}
                          disabled={alreadyCancelled.indexOf(practice.appointmentDetailId) > -1}
                          checked={isChecked(practice)}
                          checkedIcon={<Check className={classesCheck.checkedIcon} />}
                          icon={<Check className={classesCheck.uncheckedIcon} />}
                          classes={{
                              checked: classesCheck.checked,
                              root: classesCheck.checkRoot,
                          }}
                        />,
                        'id': practice.order,
                        'appointmentDetailId': practice.appointmentDetailId,
                        'type': t(practice.type),
                        'speciality': practice.speciality,
                        'startDate': moment(practice.startDate).format('DD/MM/YYYY'),
                        'quantity': practice.count,
                      }
                    })}
                    colorsColls={["primary"]}
                  />
                  )
                }
              </GridItem>
              <GridItem xs={6} sm={6}>
                <h4> {t('appointment.cancel.cancellation_reason')} </h4>
                <CustomInput
                  id="cancellation_reason"
                  labelText={t('appointment.cancel.cancellation_reason')}
                  value={cancellationReason}
                  onChange={(e) => setCancelationReason(e.target.value)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} className={classes.spaceBetween}>
        <Button onClick={cancel}> {t('appointment.cancel.cancel')} </Button>
        <Button disabled={cancellationType === 2 && !markedToBeCancelled.length} 
          onClick={()=> { 
            setConfirmationModal(true)
          }}
          color="success"> {t('appointment.cancel.save')} </Button>
      </GridItem>
      <ConfirmAlert
        closeOnClickOutside={false}
        confirmBtnText={t('appointment.cancel.modal.confirmBtnText')}
        cancelBtnText={t('appointment.cancel.modal.cancelBtnText')}
        confirmTitle={t('appointment.cancel.modal.confirmTitle')}
        confirmBodyText={(cancellationType === 2 ? t('appointment.cancel.modal.confirmBodyTextPartial') : t('appointment.cancel.modal.confirmBodyText', { appointmentId: params.id }))}
        confirmBodyTextSecondLine={detailText}
        successTitle={t('appointment.cancel.modal.successTitle')}
        successBodyText={cancellationType === 2 ? t('appointment.cancel.modal.successBodyTextPartial') : t('appointment.cancel.modal.successBodyText', { appointmentId: params.id })}
        successBodyTextSecondLine={detailText}
        successBodyTextThirdLine={cancellationType === 2 && t('appointment.cancel.modal.successBodyTextPartialSecondLine') ? t('appointment.cancel.modal.successBodyTextPartialSecondLine') : '' }
        errorBodyText={t('appointment.cancel.modal.errorBodyText', { appointmentId: params.id })}
        onConfirm={()=>{
          performCancelation();
        }}
        onSuccess={() => {
          browserHistory.push('/solicitudes')
        }}
        onClose={()=>{
          setConfirmationModal(false);
          setSuccessModal(false);
          setErrorModal(false);
        }}
        showConfirmWithText={confirmationModal}
        success={successModal}
        error={errorModal}
        useCustomStyles={cancellationType === 2}
      />
    </GridContainer>
  )
}

export default withTranslation()(AppointmentCancel);
