import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomSweetAlert from '../CustomSweetAlert';

class ConfirmAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
        }
    }

    componentWillReceiveProps(next) {
        if (next.show === false || next.showWithoutConfirmation === false || next.error === false || next.showConfirmWithText === false || next.success === false) {
            this.setState({ alert: null });
        }
        if (next.show) {
            this.confirmAlert();
        }
        if (next.showWithoutConfirmation) {
            this.confirmAlertWithoutConfirmation();
        }
        if (next.success) {
            this.successDelete();
        }
        if (next.error) {
            this.unsuccessDelete(next);
        }
        if (next.showConfirmWithText) {
            this.showConfirmWithText();
        }
    }

    delete = () => this.props.onConfirm();

    hideAlert = () => this.setState({ alert: null });

    unsuccessDelete = (next) => {
        const { t, closeOnClickOutside, errorTitle, errorBodyText, text } = this.props;
        const messageError = next.messageDeletedUnsuccess ? `${next.messageDeletedUnsuccess}` : `${t(`${errorBodyText}`)} ${text || ''}`;

        const alert = (
            <CustomSweetAlert
                closeOnClickOutside={closeOnClickOutside}
                type="danger"
                title={t(`${errorTitle}`)}
                onConfirm={() => {
                    this.hideAlert();
                    this.props.onClose();
                }}
                onCancel={() => {
                    this.hideAlert();
                    this.props.onClose();
                }}
                confirmBtnCssClass="success"
                message={messageError}
            />
        );
        this.setState({ alert });
    }

    successDelete = () => {
        const {
            t, cancelBtnText, closeOnClickOutside, contextText, customButtons, customClass, showCancelSuccess, showConfirm, successBodyText, successBodyTextSecondLine, successBodyTextThirdLine, successTitle, text,
        } = this.props;
        const alert = (
            <CustomSweetAlert
                type="success"
                customClass={customClass}
                closeOnClickOutside={closeOnClickOutside}
                title={t(`${successTitle}`)}
                onConfirm={() => {
                    this.props.onSuccess();
                    this.props.onClose();
                    this.hideAlert();
                }}
                onCancel={() => {
                    this.hideAlert();
                    this.props.onClose();
                }}
                confirmBtnCssClass="success"
                showCancel={showCancelSuccess}
                cancelBtnText={t(cancelBtnText)}
                showConfirm={showConfirm}
                message={
                    <>
                        <div className="successBodyTextContainer">
                            <div className="successBodyText">
                                {`${text || ''} ${t(`${successBodyText}`, { context: contextText })}` }
                            </div>
                            <div>
                                <div className="successBodyTextSecondLine"> {
                                    Array.isArray(successBodyTextSecondLine) ?
                                        successBodyTextSecondLine.map(text => {
                                            return text;
                                        })
                                    : successBodyTextSecondLine
                                }
                                </div>
                            </div>
                        </div>
                        <div className="successBodyTextThirdLine"> {successBodyTextThirdLine}</div>
                        {customButtons &&
                            <div className="customAlertButtons">
                                {customButtons}
                            </div>
                        }
                    </>
                }
            />
        );
        this.setState({ alert });
    }

    confirmAlert = () => {
        const {
            t, cancelBtnText, closeOnClickOutside, confirmBodyText, confirmBtnText, confirmBodyTextSecondLine, customButtons, customClass, showCancel, showConfirm, title, text,
        } = this.props;
        const alert = (
            <CustomSweetAlert
                type="warning"
                customClass={customClass}
                closeOnClickOutside={closeOnClickOutside}
                title={title}
                onConfirm={() => this.delete()}
                onCancel={() => {
                    this.hideAlert();
                    this.props.onClose();
                }}
                confirmBtnCssClass="success"
                cancelBtnCssClass="danger"
                confirmBtnText={t(confirmBtnText)}
                cancelBtnText={t(cancelBtnText)}
                customButtons={customButtons}
                showCancel={showCancel}
                showConfirm={showConfirm}
                message={
                    <>
                        <p>{`${t(`${confirmBodyText}`)} ${text || ''}.`}</p>
                        {confirmBodyTextSecondLine &&
                            (<><br /><p>{`${confirmBodyTextSecondLine}`}</p></>)
                        }
                        {customButtons &&
                            <div className="customAlertButtons">
                                {customButtons}
                            </div>
                        }
                    </>
                }
            />
        );
        this.setState({ alert });
    }

    showConfirmWithText = () => {
        const {
            t, cancelBtnText, confirmBodyText, confirmBodyTextSecondLine, confirmBtnText, closeOnClickOutside, confirmTitle, customClass, customButtons, message, showCancel, showConfirm, title,
        } = this.props;
        const alert = (
            <CustomSweetAlert
                type="warning"
                customClass={customClass}
                closeOnClickOutside={closeOnClickOutside}
                title={title || t(`${confirmTitle}`)}
                onConfirm={() => this.props.onConfirm()}
                onCancel={() => {
                    this.hideAlert();
                    this.props.onClose();
                }}
                confirmBtnCssClass="success"
                cancelBtnCssClass="danger"
                confirmBtnText={t(confirmBtnText)}
                cancelBtnText={t(cancelBtnText)}
                customButtons={customButtons}
                showCancel={showCancel}
                showConfirm={showConfirm}
                message={
                    <>
                        <div className="confirmBodyTextContainer">
                            <p className="confirmBodyText"> { message ? message : confirmBodyText}</p>
                            <ul className="confirmBodyTextSecondLine">  {
                                Array.isArray(confirmBodyTextSecondLine) ?
                                    confirmBodyTextSecondLine.map(text => {
                                        return <li>{text}</li>
                                    })
                                    : confirmBodyTextSecondLine
                                }
                            </ul>
                        </div>
                        {customButtons &&
                            <div className="customAlertButtons">
                                {customButtons}
                            </div>
                        }
                    </>
                }
            />
        );
        this.setState({ alert });
    }

    confirmAlertWithoutConfirmation = () => {
        const {
            t, cancelBtnText, closeOnClickOutside, confirmBodyText, confirmBtnText, customButtons, customClass, showCancel, showConfirm, text, title
        } = this.props;
        const alert = (
            <CustomSweetAlert
                type="warning"
                customClass={customClass}
                closeOnClickOutside={closeOnClickOutside}
                title={title}
                onConfirm={() => {
                    this.delete();
                    this.hideAlert();
                    this.props.onClose();
                }}
                onCancel={() => {
                    this.hideAlert();
                    this.props.onClose();
                }}
                confirmBtnCssClass="success"
                cancelBtnCssClass="danger"
                confirmBtnText={t(confirmBtnText)}
                cancelBtnText={t(cancelBtnText)}
                customButtons={customButtons}
                showCancel={showCancel}
                showConfirm={showConfirm}
                message={
                    <>
                        {`${t(`${confirmBodyText}`)} ${text}.`}
                        {customButtons &&
                            <div className="customAlertButtons">
                                {customButtons}
                            </div>
                        }
                    </>
                }
            />
        );
        this.setState({ alert });
    }

    
    render() {
        return this.state.alert;
    }
}

/**
 * Dont change this default props 
 * without changing the text props from where 
 * the confirm component was originally used
 */
 ConfirmAlert.defaultProps = {
    confirmBtnText: 'remove',
    cancelBtnText: 'common.cancel',
    confirmTitle: 'confirm.title',
    confirmBodyText: 'confirm.body.text',
    successTitle: 'success.title',
    successBodyText: 'success.body.text',
    contextText: 'male',
    successBodyTextSecondLine: '',
    successBodyTextThirdLine: '',
    errorTitle: 'error.title',
    confirmBodyTextSecondLine: '',
    messageDeletedUnsuccess: '',
    errorBodyText: 'error.body.text',
    closeOnClickOutside: true,
    customButtons: undefined,
    customClass: '',
    showCancel: true,
    showCancelSuccess: true,
    showConfirm: true,
};

ConfirmAlert.propTypes = {
    customIcon: PropTypes.string,
    confirmBodyText: PropTypes.string,
    confirmBodyTextSecondLine: PropTypes.string,
    successBodyText: PropTypes.string,
    contextText: PropTypes.string,
    successBodyTextSecondLine: PropTypes.string,
    successBodyTextThirdLine: PropTypes.string,
    messageDeletedUnsuccess: PropTypes.string,
    errorBodyText: PropTypes.string,
    confirmTitle: PropTypes.string,
    successTitle: PropTypes.string,
    errorTitle: PropTypes.string,
    confirmBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
    text: PropTypes.string,
    show: PropTypes.bool,
    showWithoutConfirmation: PropTypes.bool,
    success: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    customButtons: PropTypes.element,
    customClass: PropTypes.string,
    showCancel: PropTypes.bool,
    showCancelSuccess: PropTypes.bool,
    showConfirm: PropTypes.bool,
};

export default withTranslation()(ConfirmAlert);
