import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function DashboardEconomicProfessionalIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M3 17H6V21H3zM8 15H11V21H8zM18 3H21V21H18zM13 11H16V21H13zM12 8L12 3 7 3z"/>
      <path d="M2.361 7.043H11.553V9.043H2.361z" transform="rotate(-45.001 6.957 8.043)"/>
    </SvgIcon>
  );
}
