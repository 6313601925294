import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Close from '@material-ui/icons/Close'
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from '../CustomButtons/Button'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Table from '../Table/Table'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import './ScheduleDetail.css'

class ScheduleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            customer: '',
            schedule: {},                        
            scheduleParameters: [],
            supplyList:[]
        }
    
    }

    componentWillMount() {
        
        this.setState({
            open: this.props.open,
            schedule: this.props.schedule,
            customer: this.props.customer            
        })
    }

    componentWillReceiveProps(next) {
        
        if (next.open) {
            this.setState({
                open: next.open
            })
        }

        if (next.schedule) {
            
            this.setState({
                schedule: next.schedule,
                scheduleParameters: this.getParameters(next.schedule)            
            })            
        }

        if (next.customer) {
            this.setState({
                customer: next.customer
            })
        }

        if(next.supplyList)
        {
            this.setState({
                supplyList : next.supplyList
            });
        }
    }

    handleClose() {
        this.setState({
            open: false
        })
        this.props.onClose();
    }

    setParameters() {
        if (this.state.schedule.metadata && this.state.schedule.metadata.vital_signs) {
            this.setState({
                scheduleParameters: [
                    {parameter: 'Temperatura', value: this.state.schedule.metadata.vital_signs.temperature},
                    {parameter: 'Pulso', value: this.state.schedule.metadata.vital_signs.pulse},
                    {parameter: 'Respiración', value: this.state.schedule.metadata.vital_signs.breathing},
                    {parameter: 'Presión Máxima', value: this.state.schedule.metadata.vital_signs.pressure.max},
                    {parameter: 'Presión Mínima', value: this.state.schedule.metadata.vital_signs.pressure.min},
                ]
            })
        }
    }

    getParameters(schedule) {
        if (schedule.metadata && schedule.metadata.vital_signs) {
            return [
                    {parameter: 'Temperatura', value: schedule.metadata.vital_signs.temperature},
                    {parameter: 'Pulso', value: schedule.metadata.vital_signs.pulse},
                    {parameter: 'Respiración', value: schedule.metadata.vital_signs.breathing},
                    {parameter: 'Presión Máxima', value: schedule.metadata.vital_signs.pressure.max},
                    {parameter: 'Presión Mínima', value: schedule.metadata.vital_signs.pressure.min},
                ];
        }
        else
        return [];
    }

    render() {
        return (
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={this.state.open}
                keepMounted
                onClose={() => this.handleClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
                className="schedule-detail"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className="schedule-detail-title"
                >
                    <h4 className="schedule-detail-name">Detalle de Cita - {this.state.customer}</h4>
                    <Button
                        justIcon
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.handleClose()}
                    >
                        <Close />
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="classic-modal-slide-description"
                    className="schedule-detail-content"
                >
                    <GridContainer>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>Fecha/Hora: </b> {moment(this.state.schedule.scheduleDateTime).format('DD/MM/YYYY hh:mm')}
                        </GridItem>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>Profesional: </b> {`${this.state.schedule.employee ? this.state.schedule.employee.firstName : ''} ${this.state.schedule.employee ? this.state.schedule.employee.lastName : ''}`}
                        </GridItem>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>Estado: </b> {this.state.schedule.scheduleStatus ? t(`technicalfile.state.${this.state.schedule.scheduleStatus.name}`) : t(`technicalfile.state.PENDING_APPROVAL`)}
                        </GridItem>
                        <GridItem xs={6} className="schedule-detail-data">
                            <b>Tipo de cita: </b> {this.state.schedule && this.state.schedule.appointmentDetail && this.state.schedule.appointmentDetail.practiceType ? this.state.schedule.appointmentDetail.practiceType.name : ''}
                        </GridItem>
                    </GridContainer>
                    <GridContainer className="schedule-detail-clinic">
                        <GridItem md={4} className="schedule-detail-table">
                            <Table
                                sortable={false}
                                tableHeaderColor="primary"
                                tableHead={[{ Header: "Parámetro", accessor: "parameter" }, { Header: "Valor", accessor: "value" }]}
                                tableData={this.state.scheduleParameters}
                                colorsColls={["primary"]}
                            />
                        </GridItem>
                        <GridItem md={4} className="schedule-detail-table">
                            <Table
                                sortable={false}
                                tableHeaderColor="primary"
                                tableHead={[{ Header: "Insumo", accessor:"supplies" }, { Header: "Cantidad", accessor:"quantity" }]}
                                tableData={this.state.supplyList}
                                colorsColls={["primary"]}
                            />
                        </GridItem>
                        <GridItem md={4} className="schedule-detail-notes">
                            { (this.state.schedule.metadata && this.state.schedule.metadata.note) &&
                                <p>
                                    {this.state.schedule.metadata.note}
                                </p>
                            }
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleClose()}
                        color="danger"
                        simple
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ScheduleDetail.propTypes = {
    schedule: PropTypes.object,
    open: PropTypes.bool,
    customer: PropTypes.string,
    onClose: PropTypes.func,
}
export default withTranslation()(ScheduleDetail)