import { connect } from 'react-redux';
import { userLogin, fetchCompanies, selectCompany, userLogout } from '../actions/user';
import { cleanAppointments } from '../actions/appointments';
import { cleanCustomer } from '../actions/customers';
import { unmountEmployee } from '../actions/employees';
import { unmountTool } from '../actions/tools';
import { unmountToolType } from '../actions/tooltypes';
import { unmountModule } from '../actions/modules';
import { unmountBarthels } from '../actions/records';
import { unmountComplexty } from '../actions/records';

import Login from '../components/Login';
import AdminNavbarLinks from '../components/Navbars/AdminNavbarLinks';

const mapStateToProps = (state) => {
    return {
        user: state.user,
        companies: state.user.companies,
        selectedCompany: state.user.selectedCompany
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUserLogged: (user) => {
            dispatch(userLogin(user))
        },
        onGetCompanies: (companies) => {
            dispatch(fetchCompanies(companies))
        },
        onSelectedCompany: companyId => {
            dispatch(selectCompany(companyId))
        },
        onLogout: () => {
            dispatch(userLogout());
            dispatch(cleanAppointments());

        },
        onClearData: () => {
            dispatch(cleanAppointments());
            dispatch(cleanCustomer());
            dispatch(unmountEmployee());
            dispatch(unmountTool());
            dispatch(unmountToolType());
            dispatch(unmountModule());
            dispatch(unmountBarthels());
            dispatch(unmountComplexty());
        },
        onChangeCompany: () => {
            dispatch(cleanAppointments());
            dispatch(cleanCustomer());
            dispatch(unmountEmployee());
            dispatch(unmountTool());
            dispatch(unmountToolType());
            dispatch(unmountModule());
            dispatch(unmountBarthels());
            dispatch(unmountComplexty());
        }
    }
}

const UserLogged = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

const NavbarService = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminNavbarLinks);

export default {UserLogged, NavbarService};