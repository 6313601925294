import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TextField, FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import store from '../../../store';
import PatologiesApiInvoker from '../../../api/RecordsApiInvoker';

class DiagnosticForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      observation: '',
      complexity: '',
      barthel: '',
      patology: {
        [this.props.accessor]: null
      },
      hospitalPatology: {
        [this.props.accessor]: null
      }
    }
  }

  componentDidMount() {
    const newState = {};
    if(this.props.patology) {
      newState.patology = {
        id: this.props.patology[this.props.accessor]
      }
      this.setPatologyDetails(newState.patology)
    }
    if(this.props.hospitalPatology) {
      newState.hospitalPatology = {
        id: this.props.hospitalPatology[this.props.accessor],
      }
      this.setHospitalPatologyDetails(newState.hospitalPatology)
    }
    if(this.props.observation) {
      newState.observation = this.props.observation;
    }
    if (this.props.barthel) {
      newState.barthel = this.props.barthel;
    }
    if(this.props.complexity) {
      newState.complexity = this.props.complexity;
    }

    this.setState(newState);

    this.getPatologies();
    this.getComplexities();
    this.getBarthels();
  }

  componentWillReceiveProps(next) {
    const newState = {};

    if (next.patology && next.patology[this.props.accessor] !== this.props.patology[this.props.accessor]) {
      newState.patology = {
        id: next.patology[this.props.accessor],
      }
      this.setPatologyDetails(newState.patology);
    }
    if (next.hospitalPatology && next.hospitalPatology[this.props.accessor] !== this.props.hospitalPatology[this.props.accessor]) {
      newState.hospitalPatology = {
        id: next.hospitalPatology[this.props.accessor],
      }
      this.setHospitalPatologyDetails(newState.hospitalPatology);
    }

    if (next.observation !== this.props.observation) {
      newState.observation = next.observation;
    }
    if (next.barthel !== this.props.barthel) {
      newState.barthel = next.barthel;
    }
    if (next.complexity !== this.props.complexity) {
      newState.complexity = next.complexity;
    }

    this.setState(newState);
  }

  handleValue(value, state) {
    this.setState({ [state]: value });
    if (state === 'patology') {
      this.selectPatology(value);
    }
    if (state === 'hospitalPatology') {
      this.selectHospitalPatology(value);
    }
    if (state === 'complexity') {
      this.props.onFilledComplexity(value);
    }
    if (state === 'barthel') {
      this.props.onFilledBarthel(value);
    }
    if(state === 'observation') {
      this.props.onFilledObservation(value);
    }
  }

  selectPatology(value) {
    if(!value || !value.id) {
      return this.props.onFilledPatology({
        parent: {
          name: '',
          parent: {
            name: ''
          }
        }
      });
    }
    this.setState({
      patology: {
        id: value.id,
        name: value.name,
        description: value.description,
      }
    });
    this.setPatologyDetails(value);
  }

  setPatologyDetails(value) {
    if (!value || !value.id) {
      return;
    }
    PatologiesApiInvoker.getPatologyDetails(value.id, data => {
      this.props.onFilledPatology(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  selectHospitalPatology(value) {
    if(!value || !value.id) {
      return this.props.onFilledHospitalPatology({
        parent: {
          name: '',
          parent: {
            name: ''
          }
        }
      });
    }
    this.setState({
      hospitalPatology: {
        id: value.id,
        name: value.name,
        description: value.description,
      }
    });
    this.setHospitalPatologyDetails(value);
  }

  setHospitalPatologyDetails(value){
    if (!value || !value.id) {
      return;
    }
    PatologiesApiInvoker.getPatologyDetails(value.id, data => {
      this.props.onFilledHospitalPatology(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  getPatologies() {
    if (
      store.getState().newSchedule.patologies &&
      store.getState().newSchedule.patologies.length > 0
    ) {
      this.setState({
        patologies: store.getState().newSchedule.patologies,
      });
    } else {
      PatologiesApiInvoker.getAllPatologies(data => {
        this.props.onGetPatologies(data);
      }, e => {
        this.setState({ loading: false });
      })
    }
  }

  getComplexities() {
    if (
      store.getState().records.complexities &&
      store.getState().records.complexities.length > 0
    ) {
      this.setState({
        complexities: store.getState().records.complexities,
      });
    } else {
      PatologiesApiInvoker.getAllComplexities(data => {
        this.props.onGetComplexities(data);
      }, e => {
        this.setState({ loading: false });
      });
    }
  }

  getBarthels() {
    if (
      store.getState().records.barthels &&
      store.getState().records.barthels.length > 0
    ) {
      this.setState({
        barthels: store.getState().records.barthels,
      });
    } else {
      PatologiesApiInvoker.getAllBarthel(data => {
        this.props.onGetBarthels(data);
      }, e => {
        this.setState({ loading: false });
      });
    }
  }

  render() {
    const {
      t, accessor, barthel, barthels, checkInvalid, classes, complexities, complexity,
      disabled, errorText, hospitalPatology, invalid, patologies, patology
    } = this.props;
    const { observation } = this.state;

    return (
      <div className={`${classes.authorizerForm} diagnostic-form`}>
        <GridContainer>
          <GridItem xs={12}>
            <h4 className="margin-top">
              {t('appointment.new.diagnostic.main-diagnostic')}
            </h4>
          </GridItem>
          <GridItem sm={4}>
            <Autocomplete
              className="diagnostic-form-autocomplete"
              disabled={disabled}
              options={patologies}
              getOptionLabel={option => option.name || option.description}
              getOptionSelected={option => option.id}
              renderInput={params => ( 
                <TextField {...params} label={t('appointment.new.diagnostic.patology')} fullWidth />
              )}
              onChange={(event, value) => this.handleValue(value, 'patology')}
              value={patology}
            />
            {checkInvalid && !(patology && patology[accessor]) && (
              <FormHelperText className="helper-error">
                  {t('error.required')}
              </FormHelperText>
            )}
          </GridItem>
          <GridItem sm={4}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: !!(patology && patology.parent && patology.parent.name) }}
              className="diagnostic-form-text-field"
              label={t('appointment.new.diagnostic.category')}
              value={(patology && patology.parent && patology.parent.name)}
              disabled
            />
          </GridItem>
          <GridItem sm={4}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: !!(patology && patology.parent && patology.parent.parent && patology.parent.parent.name) }}
              className="diagnostic-form-text-field"
              label={t('appointment.new.diagnostic.subcategory')}
              value={(patology && patology.parent && patology.parent.parent && patology.parent.parent.name)}
              disabled
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <h4 className="margin-top">
              {t('appointment.new.diagnostic.internation-diagnostic')}
            </h4>
          </GridItem>
          <GridItem sm={4}>
            <Autocomplete
              className="diagnostic-form-autocomplete"
              disabled={disabled}
              options={patologies}
              getOptionLabel={option => option.name || option.description}
              getOptionSelected={(option, value) => option.id === value}
              renderInput={params => ( 
                <TextField {...params} label={t('appointment.new.diagnostic.patology')} fullWidth/>
              )}
              onChange={(event, value) => this.handleValue(value, 'hospitalPatology')}
              value={hospitalPatology}
            />
          {checkInvalid && !(hospitalPatology && hospitalPatology[accessor]) && (
            <FormHelperText className="helper-error">
              {t('error.required')}
            </FormHelperText>
          )}
          </GridItem>
          <GridItem sm={4}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: !!(hospitalPatology && hospitalPatology.parent && hospitalPatology && hospitalPatology.parent.name) }}
              className="diagnostic-form-text-field"
              label={t('appointment.new.diagnostic.category')}
              value={(hospitalPatology && hospitalPatology.parent && hospitalPatology.parent.name) }
              disabled
            />
          </GridItem>
          <GridItem sm={4}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: !!(hospitalPatology && hospitalPatology.parent && hospitalPatology.parent.parent && hospitalPatology.parent.parent.name) }}
              className="diagnostic-form-text-field"
              label={t('appointment.new.diagnostic.subcategory')}
              value={hospitalPatology && hospitalPatology.parent && hospitalPatology.parent.parent && hospitalPatology.parent.parent.name}
              disabled
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <h4 className="margin-top">
              {t('appointment.new.diagnostic.observations')}
            </h4>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <Autocomplete
              className="diagnostic-form-autocomplete"
              disabled={disabled}
              options={complexities}
              getOptionLabel={option => {
                return option && (option.name || option.description) && t(`${(option.name || option.description)}`)
              }}
              getOptionSelected={option => option.id}
              renderInput={params => ( 
                <TextField {...params} label={t('appointment.new.diagnostic.complexity')}  fullWidth/>
              )}
              onChange={(event, value) => this.handleValue(value, 'complexity')}
              value={complexity}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <Autocomplete
              className="diagnostic-form-autocomplete"
              disabled={disabled}
              options={barthels}
              getOptionLabel={option => option.name || option.description}
              getOptionSelected={option => option.id}
              renderInput={params => ( 
                <TextField {...params} label={t('appointment.new.diagnostic.barthelIndex')} fullWidth/>
              )}
              onChange={(event, value) => this.handleValue(value, 'barthel')}
              value={barthel}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <TextField
              className="diagnostic-form-text-field"
              fullWidth
              disabled={disabled}
              error={invalid}
              label={t('appointment.new.diagnostic.observations')}
              rowsMax="10"
              helperText={errorText}
              value={observation}
              onChange={value => this.handleValue(value.target.value, 'observation')}
              margin="normal"
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

DiagnosticForm.defaultProps = {
  onFilledObservation: () => {},
  onFilledPatology: () => {},
  onFilledHospitalPatology: () => {},
  onGetPatologies: () => {},
  onGetComplexities: () => {},
  onGetBarthels: () => {},
  complexities: [],
  patologies: [],
  barthels: [],
  complexity: {},
  barthel: {},
  patology: {},
  hospitalPatology: {},
  observation: '',
  disabled: false,
  accessor: "[this.props.accessor]"
}

DiagnosticForm.propTypes = {
  onFilledObservation: PropTypes.func,
  onFilledPatology: PropTypes.func,
  onFilledHospitalPatology: PropTypes.func,
  onGetPatologies: PropTypes.func,
  onGetComplexities: PropTypes.func,
  onGetBarthels: PropTypes.func,
  complexities: PropTypes.array,
  patologies: PropTypes.array,
  patology: PropTypes.object,
  hospitalPatology: PropTypes.object,
  value: PropTypes.string,
  accessor: PropTypes.string,
  checkInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withStyles({
  "authorizerForm": {
    "padding": "20px 0",
  }
})(withTranslation()(DiagnosticForm));
