import { connect } from 'react-redux';
import { getEmployeeTypes, getFinanciers, getProvincies } from "../actions/dashboardprofesional";
import DashboardEconomicSupply from '../components/DashboardEconomicSupply/DashboardEconomicSupply.js';
import Filters from '../components/DashboardEconomicSupply/Filters';

const mapStateToProps = (state) => {
    return {
        financiers: state.dashboardprofesional.financiers,
        provincies: state.dashboardprofesional.provincies,
        employeeTypes: state.dashboardprofesional.employeeTypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetFinanciers: (financiers) => {
            dispatch(getFinanciers(financiers))
        },
        onGetEmployeeTypes: (employeeTypes) => {
            dispatch(getEmployeeTypes(employeeTypes))
        },
        onGetProvincies: (provincies) => {
            dispatch(getProvincies(provincies))
        },
    }
}

const DashboardEconomicSupplyService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardEconomicSupply);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters)

export {DashboardEconomicSupplyService, FiltersService};