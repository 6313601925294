const config = {
    
    "api": {
        "intranet": "https://intranet.itlatingroup.cl:8443",
        "cloud": "https://cloud.itlatingroup.cl:8443"
    },
    "powerbi_client_id": 'fcc66094-e840-4603-8c89-bd6c54d0b2d6',
    "powerbi_scope": 'Tenant.Read.All',
    "powerbi_resource": 'https://analysis.windows.net/powerbi/api',
    "powerbi_urllogin": 'https://login.microsoftonline.com/common/oauth2/token',
    "powerbi_urlreport": 'https://api.powerbi.com/v1.0/myorg/GenerateToken',
}

export default config;