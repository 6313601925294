import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function ViewList(props) {
  return (
    <SvgIcon
      version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M80 760 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60
      60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z"/>
      <path d="M280 720 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z"/>
      <path d="M80 520 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60
      60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z"/>
      <path d="M280 480 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z"/>
      <path d="M80 280 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60
      60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z"/>
      <path d="M280 240 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z"/>
      </g>
    </SvgIcon>
  );
}
