import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Checkbox, Grid, makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Table from '../../../Table/Table.jsx';
import { useStylesGralSteps } from '../../useStylesReassignAppStepper';
import styles from '../../../../assets/components/customCheckboxRadioSwitch';
import Filters from './Filters';

const useStylesChecks = makeStyles(styles);

const AppointmentsStep = ({
  clearInformation,
  handleIsValidStep,
  isConfirmStep,
  softFilter,
  setFilters,
  onSelectAllSchedules,
  showGraphicsInfo,
  values: { chips, loadingSchedule, schedules, schedulesSelected, checkAllSchedules, startDate, endDate, practicesType },
  t,
}) => {
  const classes = useStylesGralSteps();
  const classesChecks = useStylesChecks();
  const isValid = schedulesSelected.length;

  useEffect(() => {
    handleIsValidStep(isValid);
  }, [handleIsValidStep, isValid]);

  return (
    <div className={clsx(classes.formStepper, {[classes.readOnlyForm]: isConfirmStep})}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Filters
            loading={loadingSchedule}
            chips={chips}
            onChangeChips={(filters) => softFilter(filters)}
            onChange={(filters) => setFilters(filters)}
            onClearFilters={clearInformation}
            filterData={{ startDate, endDate, practicesType }}
          />
        </Grid>
        <Grid item xs={12} className={classes.spacingTop4}>
          <Table
            loading={loadingSchedule}
            tableHeaderColor="primary"
            sortable={true}
            filterable
            tableHead={[
              { Header:
                <div className="tooltypes-actions">
                  <Checkbox
                    tabIndex={-1}
                    id="all_schedule"
                    checked={checkAllSchedules}
                    onClick={(e) => onSelectAllSchedules(e)}
                  />
                </div>, sortable: false, filterable: false, accessor: 'selector', width: 60,
              },
              { Header: t('reassignAppointments.table.scheduleId'), accessor: 'scheduleId' },
              { Header: t('status'), accessor: 'scheduleStatus' },
              { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
              { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
              { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
              { Header: t('label.professional'), accessor: 'professional' },
              { Header: t('reassignAppointments.table.scheduleDateTime'), accessor: 'scheduleDate' },
              { Header: t('label.hour'), accessor: 'scheduleTime' },
              { Header: t('reassignAppointments.table.province'), accessor: 'province' },
              { Header: t('reassignAppointments.table.location'), accessor: 'location' },
              { Header: t('reassignAppointments.table.geographicZone'), accessor: 'geographicZone' },
            ]}
            tableData={schedules}
            colorsColls={['primary']}
            onRowClick={(e, t, rowInfo) => showGraphicsInfo(rowInfo)}
            defaultPageSize={schedules && schedules.length > 5 ? schedules.length : 5}
            showPaginationTop={false}
            showPaginationBottom={false}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default withTranslation()(AppointmentsStep);
