import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import PinBlack from '@material-ui/icons/LocationOn';
import Table from '../../Table/Table';
import CustomDialog from '../../CustomDialog';
import DashboardApiInvoker from '../../../api/DashboardApiInvoker';


const PopupSchedules = (props) => {
  //t, open, dateFrom, dateTo, employeeId, openMap, closePopup

  const [dataTable, setDataTable] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const dateFormat = 'DD/MM/YYYY HH:mm';
  
  const sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if(a1<b1)
     return -1;
    else if(a1>b1)
        return 1;
    else
        return 0;                  
  }

  const getDataTable = async (employeeId) => {
      setDataLoading(true);
      setDataTable([]);
      DashboardApiInvoker.postEmployeeDashboardSchedule({
          dateFrom: props.dateFrom,
          dateTo: props.dateTo,
          employeeId: employeeId
      }, dataTable => {
          setDataLoading(false);
          formatDataTable(dataTable)
      }, err => {
          setDataLoading(false);
          console.error(err);
      })
  }

  const formatDataTable = (dataTable) => {
    let result = [];
    if (dataTable && dataTable.length>0) {
      const { t } = props;
      result = dataTable.map(s => {
          return {
              appoinmentId: s.appoinmentId,
              scheduleId: s.scheduleId,
              scheduleDateTime: moment(s.scheduleDateTime).format(dateFormat),
              customer: `${s.customer.firstName} ${s.customer.lastName}`,
              speciality: s.practiceTypeGroupName, // @TODO: agregar la especialidad
              practice: s.practiceTypeName,
              scheduleStatus: t(`schedule.booking.state.${s.scheduleStatus}`),
              duration: s.scheduleActualDuration,
              punctuality: s.puntuality,
              verificated: s.patientIdentityChecks ? 'S' : 'N',
              verifyLocation: (s.verifyLocation ? 'S' : 'N'),
              address:
                  <IconButton disabled={s.scheduleStatus !== 'DONE'} aria-label="pin" onClick={() => props.openMap(s)}>
                    <PinBlack style={{ color: "#e91e63" }} />
                </IconButton>
          }
      })
      setDataTable(result);
    }
  }

  const close = () => {
    props.closePopup('openPopupSchedule');
  }

  useEffect(() => {
    if (props.open) {
      getDataTable(props.employeeId)
    }
  }, [props.open])
  
  const { t, open } = props;
  return (
    <CustomDialog
      maxWidth="lg"
      open={open}
      title={t('dashboard.table.visits')}
      onClose={() => close()}
    >
      <Table
        loading={dataLoading}
        tableHeaderColor="primary"
        sortable
        tableHead={[
            { Header: t('dashboard.table.visits.appoinment_id'), accessor: 'appoinmentId' },
            { Header: t('dashboard.table.visits.schedule_id'), accessor: 'scheduleId' },
            { Header: t('dashboard.table.visits.schedule_date_time'), accessor: 'scheduleDateTime', sortMethod: (a, b) => sortDate(a, b) },
            { Header: t('dashboard.table.visits.customer'), accessor: 'customer' },
            { Header: t('dashboard.table.visits.speciality'), accessor: 'speciality' },
            { Header: t('dashboard.table.visits.practice'), accessor: 'practice' },
            { Header: t('dashboard.table.visits.schedule_status'), accessor: 'scheduleStatus' },
            { Header: t('dashboard.table.visits.duration'), accessor: 'duration' },
            { Header: t('dashboard.table.visits.puntuality'), accessor: 'punctuality' },
            { Header: t('dashboard.table.visits.verificated'), accessor: 'verificated' },
            { Header: t('dashboard.table.visits.addressverificated'), accessor: 'verifyLocation' },
            { Header: t('dashboard.table.visits.address'), accessor: 'address' },
        ]}
        tableData={dataTable}
        colorsColls={["primary"]}
    />
  </CustomDialog>
  );
};

export default PopupSchedules;