import { connect } from 'react-redux';
import TechnicalFile from '../components/MedicalRecordSignVitals/MedicalRecordSignVitals';
import { getTechnicalFile } from '../actions/technicalFile';

const mapStateToProps = (state) => {
    return {
        technicalfile: state.tools.tools,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetTechnicalFile: (tools) => {
            dispatch(getTechnicalFile (tools))
        },
    }
}

const MedicalRecordSignVitalsService = connect(
    mapStateToProps,
    mapDispatchToProps
)(TechnicalFile);

export default MedicalRecordSignVitalsService;