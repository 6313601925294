import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../assets/plugins/react-charts.css';
import './DashboardPB.css';

const dateFormat = 'DD/MM/YYYY';

class DashboardFinancier extends Component {

  constructor(props) {
    super(props);
    this.state = {
      companyId : 0,
      dashboards: [
        "https://app.powerbi.com/reportEmbed?reportId=7d7c9cb1-acc7-4872-ab1c-4d795744f7d6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22",
      ]
    }
  }

  componentDidMount() {
    this.setState({
      companyId: parseInt(localStorage.getItem('itlg_default_company_id'))
    })
  }

  render() {
    let DASHBOARD_LINK ='';    
    var companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    switch (companyId) {
      case 11:
        DASHBOARD_LINK = this.state.dashboards[0]
        break;
      default:
        DASHBOARD_LINK = "";
        break;
    }
    return (
      <iframe title="Tablero Financiador General" width="100%" height={window.innerHeight} 
      src={DASHBOARD_LINK}
      frameborder="0" allowFullScreen="true"></iframe>
    )
  }
}



export default withTranslation()(DashboardFinancier);
