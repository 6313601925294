import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Events, scroller } from 'react-scroll';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import HotelIcon from '@material-ui/icons/Hotel';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import { FiltersService } from '../../containers/DashboardEconomicProfesionalService';
import DashboardApiInvoker from '../../api/DashboardApiInvoker';
import Authority from '../../util/Authority';
import AssignmentTurnedIn from '../../icons/AssignmentTurnedIn';
import DashboardEconomicProfessionalIcon from '../../icons/DashboardEconomicProfessionalIcon';
import DateRange from '../../icons/DateRange';
import '../../assets/plugins/react-charts.css';
import './Dashboard.css';

class DashboardEconomicProfessional extends Component {
    constructor(props) {
        super(props);

        this.state = {
            originalquery: [],
            schedules: [],
            dateFrom: '',
            dateTo: '',
            employeeId: '',
            graphics: false,
            clear: false,
            financiers: [],
            chips: [],
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            setTimeout: 2000,
            percentaje: 0,
            scheduleall: 0,
            scheduledone: 0,
        }
    }

    componentWillMount() {
        if (this.props.employees && this.props.employees.length > 0) {
            this.setState({ professionals: this.props.employees });
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.setState({
                employees: next.employees,
                professionals: next.employees,
            });
        }
        if (next.financiers) {
            this.setState({
                financiers: next.financiers,
                ofinanciers: next.financiers,
            });
        }
    }

    componentDidMount() {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-charts', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                this.cleanFields();
            }
        }, 5000);
    }

    setFilters(filters) {
        if (filters.start_date && filters.end_date) {
            this.setState({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
                loadingEmployeesTable: true,
            });

            DashboardApiInvoker.getEmployeesDashboardEconomicProfessional({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
            }, data => {
                this.formatTableData(data);
            }, err => {
                this.openAlert('danger', err.message + '');
                this.setState({ loadingEmployeesTable: false });
                console.error(err);
            });
        }
    }

    exportToExcel = () => {
        const { t } = this.props;
        let r = [];
        const show = Authority.hasPermission('menu_reports_economics_schedules');
        const fields = {
            'financier': t('dashboardeconomicprofessional.table.financier'),
            'appointmentId': t('dashboardeconomicprofessional.table.appointmentId'),
            'scheduleId': t('dashboardeconomicprofessional.table.scheduleId'),
            'type': t('dashboardeconomicprofessional.table.type'),
            'module': t('dashboardeconomicprofessional.table.module'),
            'employeeTypes': t('dashboardeconomicprofessional.table.practice'),
            'scheduleStatusName': t('dashboardeconomicprofessional.table.scheduleStatusName'),
            'startDate': t('dashboardeconomicprofessional.table.startDate'),
            'estimatedTime': t('dashboardeconomicprofessional.table.estimatedTime'),
            'durationTime': t('dashboardeconomicprofessional.table.durationTime'),
            'employee': t('dashboardeconomicprofessional.table.employee'),
            'customer': t('dashboardeconomicprofessional.table.customer'),
            'mainDiagnostic': t('dashboardeconomicprofessional.table.mainDiagnostic'),
            'pathologyId': t('dashboardeconomicprofessional.table.pathologyId'),
            'disabledPatient': t('appointment.disabledPatient.title'),
            'activeDisabilityCertificate': t('appointment.activeDisabilityCertificate'),
            'province': t('dashboardeconomicprofessional.table.province'),
            'location': t('dashboardeconomicprofessional.table.location'),
            'geographicZone': t('dashboardeconomicprofessional.table.geographicZone'),
        }

        if (show) {
            fields['verifyLocation'] = t('dashboardeconomicprofessional.table.verifyLocation');
        }
        
        r.push(fields);

        this.state.schedules.map(sch => {
            delete sch['customerId'];
            delete sch['scheduleStatus'];
            delete sch['speciality'];
            if (!show) delete sch['verifyLocation'];
            const schedule = {
                ...sch,
                disabledPatient: sch.disabledPatient === '1' ? t('form.option.yes') : t('form.option.no'),
                activeDisabilityCertificate: sch.activeDisabilityCertificate === '1' ? t('form.option.yes') : t('form.option.no'),
            }
            return r.push(schedule);
        });

        const fileName = `dashboard-economic-professionals-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(r, { skipHeader: 1 });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data2, fileName + fileExtension);
        return;
    }

    getArrayOf = (filters, type) => {
        const ret = filters.chips.filter(f => f.code === type).map(e => e.name);
        return ret;
    }

    containsAny = (source, target) => {
        const result = source.filter(function (item) { return target.indexOf(item) > -1 });
        return (result.length > 0);
    }

    softFilter = (filters) => {
        let schedules = this.state.originalquery;
        let arra = this.getArrayOf(filters, 'employeeTypes')
        let self = this;
        filters.chips.map(e => {
            schedules = schedules.filter(function (item) {
                if (e.code === 'employeeTypes') {
                    return self.containsAny(item[e.code].split(','), arra);
                } else {
                    return item[e.code] === e.value || item[e.code] === e.name;
                }
            });
        })

        let quant1 = 0;
        let quant2 = 0;
        let percentaje = 0;
        let schedulesActives = schedules.filter(r => r.scheduleStatus === 'IN_PROGRESS' || r.scheduleStatus === 'DONE');
        let quantSchedulesActives = schedulesActives.length;
        let setQuantCustomers = [...new Set(schedulesActives.map(sche => sche.customerId))];
        quant1 = setQuantCustomers.length;
        quant2 = schedules.length;
        if (quant2 > 0) {
            let percentajeDecimal = ((quantSchedulesActives / quant2) * 100).toFixed(2);
            percentaje = percentajeDecimal === '0.00' ? 0 : percentajeDecimal === '100.00' ? 100 : percentajeDecimal;
        } else percentaje = 0;

        this.setState({ schedules, scheduledone: quant1, scheduleall: quant2, percentaje });
    }

    formatEmployeeType = (types) => {
        let typesv = '';
        types.map(e => {
            return typesv = typesv + (typesv === '' ? '' : ',') + e.name
        })
        return typesv;
    }

    formatTableData(data) {
        const { t } = this.props;
        if (data) {
            let result = data.map(d => {
                 return {
                    financier: d.financierBusinessName,
                    appointmentId: d.appointmentId,
                    scheduleId: d.scheduleId,
                    speciality: (d.practiceTypeGroupName ? d.practiceTypeGroupName : ''), // @TODO: agregar la especialidad
                    employeeTypes: (d.practiceTypeName ? d.practiceTypeName : ''),
                    scheduleStatus: d.scheduleStatus,
                    scheduleStatusName: t(d.scheduleStatus),
                    startDate: (d.scheduleDateTime ? moment(d.scheduleDateTime).format('DD/MM/YYYY') : ''),
                    employee: `${d.employeeFirstName} ${d.employeeLastName}`,
                    customer: `${d.customerFirstName} ${d.customerLastName}`,
                    customerId: d.customerId,
                    mainDiagnostic: (d.metadata && d.metadata.diagnostic && d.metadata.diagnostic.principalDiagnostic ? d.metadata.diagnostic.principalDiagnostic.description : ''),
                    pathologyId: (d.metadata && d.metadata.diagnostic && d.metadata.diagnostic.hospitalizationDiagnostic ? d.metadata.diagnostic.hospitalizationDiagnostic.description : ''),
                    province: d.province,
                    location: d.location,
                    geographicZone: d.geographicZone,
                    type: (d.module ? t('Package') : t('Practice')),
                    module: d.module,
                    estimatedTime: d.durationEstimated,
                    durationTime: d.durationReal,
                    verifyLocation: (d.verifyLocation ? t('verify.location.y') : t('verify.location.n')),
                    disabledPatient: d.metadata && d.metadata.disability && d.metadata.disability.disabledPatient && d.metadata.disability.disabledPatient === true ? '1' : '2',
                    activeDisabilityCertificate: d.metadata && d.metadata.disability && d.metadata.disability.activeDisabilityCertificate && d.metadata.disability.activeDisabilityCertificate === true ? '1' : '2',
                }
            })
            this.setState({
                schedules: result,
                originalquery: result,
                loadingEmployeesTable: false,
            });
            this.softFilter(this.state);
        }
    }

    clickOnRow(employeeId) {
        if (employeeId) {
            this.setState({ clear: false }, () => {
                this.getSchedules(this.state.employeeId);
            });
        }
    }

    getSchedules(employeeId) {
        DashboardApiInvoker.postEmployeeDashboardSchedule({
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            employeeId: employeeId,
        }, schedules => {
            this.setState({
                schedules: [],
                selectedRow: true,
                graphics: false,
            });
            this.formatSchedules(schedules);
        }, err => {
            console.error(err);
        });
    }

    clearInformation() {
        this.setState({
            employees: [],
            employeeId: '',
            dateFrom: '',
            dateTo: '',
            clear: true,
            chips: [],
            schedules: [],
            originalquery: [],
            scheduleall: 0,
            scheduledone: 0,
            percentaje: 0
        });
    }

    showGraphicsInfo(rowInfo) {
        if (rowInfo && rowInfo.original && rowInfo.original.employeeId) {
            this.setState({
                graphics: true,
                employeeId: rowInfo.original.employeeId,
            }, () => {
                this.setState({ graphics: false });
            });
        }
    }

    render() {
        const { t } = this.props;
        const {
            percentaje, scheduledone, scheduleall, chips, loadingEmployeesTable, 
            schedules, alertColor, alertMessage, alertOpen,
        } = this.state;

        let colorstate = '';
        if (percentaje === 100) {
            colorstate = 'green';
        } else if (percentaje < 100 && percentaje >= Number('95.00')) {
            colorstate = 'yellow';
        } else if (percentaje < Number('95.00')) {
            colorstate = 'red';
        }

        return (
            <div className="dashboard-economic-professional">
                <GridContainer className="cards-resume">
                        <GridItem className="card-resume" xs={12} sm={4} md={3}>
                            <Card>
                                <CardHeader color="warning" stats icon>
                                    <CardIcon color="primary" className='icon'>
                                        <HotelIcon />
                                    </CardIcon>
                                    <p className="cardTitle">{t('dashboardeconomicprofessional.resumen.scheduledone')}</p>
                                    <h3 className="cardValue">{scheduledone}</h3>
                                </CardHeader>
                                <CardFooter stats>
                                    <div />
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem className="card-resume" xs={12} sm={4} md={3}>
                            <Card>
                                <CardHeader color="warning" stats icon>
                                    <CardIcon color="primary" className='icon iconDashboard'>
                                        <DateRange className="svgIcon svgInCard no-margin" />
                                    </CardIcon>
                                    <p className="cardTitle">{t('dashboardeconomicprofessional.resumen.scheduleprogramadas')}</p>
                                    <h3 className="cardValue">{scheduleall}</h3>
                                </CardHeader>
                                <CardFooter stats>
                                    <div />
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem className="card-resume" xs={12} sm={4} md={3} lg={3}>
                            <Card>
                                <CardHeader stats icon>
                                    <CardIcon color={colorstate} className={`icon ${colorstate}-icon iconDashboard`}>
                                        <AssignmentTurnedIn className="svgIcon svgInCard no-margin" />
                                    </CardIcon>
                                    <p className="cardTitle">{t('dashboardeconomicprofessional.resumen.percentaje')}</p>
                                    <h3 className="cardValue">{percentaje} %</h3>
                                </CardHeader>
                                <CardFooter stats>
                                    <div />
                                </CardFooter>
                            </Card>
                        </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem className="dashboard-data-container" xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <DashboardEconomicProfessionalIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('dashboardeconomicprofessional.table.title')}</h3>
                                <FiltersService
                                    loading={loadingEmployeesTable}
                                    chips={chips} onChangeChips={(filters) => this.softFilter(filters)}
                                    onChange={(filters) => this.setFilters(filters)}
                                    onClearFilters={() => this.clearInformation()}
                                />
                            </CardHeader>
                            <CardBody className="dashboard-content-body">
                                <Table
                                    loading={loadingEmployeesTable}
                                    tableHeaderColor="primary"
                                    sortable={true}
                                    tableHead={[
                                        { Header: t('dashboardeconomicprofessional.table.financier'), accessor: 'financier' },
                                        { Header: t('dashboardeconomicprofessional.table.appointmentId'), accessor: 'appointmentId' },
                                        { Header: t('dashboardeconomicprofessional.table.scheduleId'), accessor: 'scheduleId' },
                                        { Header: t('dashboardeconomicprofessional.table.speciality'), accessor: 'speciality' },
                                        { Header: t('dashboardeconomicprofessional.table.practice'), accessor: 'employeeTypes' },
                                        { Header: t('dashboardeconomicprofessional.table.scheduleStatusName'), accessor: 'scheduleStatusName' },
                                        { Header: t('dashboardeconomicprofessional.table.startDate'), accessor: 'startDate' },
                                        { Header: t('dashboardeconomicprofessional.table.employee'), accessor: 'employee' },
                                        { Header: t('dashboardeconomicprofessional.table.customer'), accessor: 'customer' },
                                        { Header: t('dashboardeconomicprofessional.table.mainDiagnostic'), accessor: 'mainDiagnostic' },
                                        { Header: t('dashboardeconomicprofessional.table.pathologyId'), accessor: 'pathologyId' },
                                        { Header: t('dashboardeconomicprofessional.table.province'), accessor: 'province' },
                                        { Header: t('dashboardeconomicprofessional.table.location'), accessor: 'location' },
                                        // { Header: t('dashboardeconomicprofessional.table.geographicZone'), accessor: 'geographicZone' },
                                        { Header: '', accessor: 'disabledPatient', show: false },
                                        { Header: '', accessor: 'activeDisabilityCertificate', show: false },
                                    ]}
                                    tableData={schedules}
                                    defaultPageSize={schedules.length > 5 ? 20 : 5}
                                    colorsColls={['primary']}
                                    onRowClick={(e, t, rowInfo) => this.showGraphicsInfo(rowInfo)}
                                />
                            </CardBody>
                            <CardFooter className="footer-buttons buttons-right">
                                <Button className="buttonExcel" disabled={schedules.length < 1} onClick={() => this.exportToExcel()} color="success">
                                    {t('common.exportExcel')}
                                </Button>
                            </CardFooter>
                        </Card>
                        <Snackbar
                            place="tr"
                            color={alertColor}
                            message={alertMessage}
                            open={alertOpen}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

DashboardEconomicProfessional.propTypes = {
    schedules: PropTypes.array,
    supplies: PropTypes.array,
    tools: PropTypes.array,
    t: PropTypes.func,
}

export default withTranslation()(DashboardEconomicProfessional);
