import { connect } from 'react-redux';
import { fetchBookings } from '../actions/bookings';
import Bookings from '../components/Bookings';
import { saveSchedule } from '../actions/newSchedule';
import { saveNewSchedule } from '../actions/schedules';
import { fetchPatologyDetails, fetchHospitalPatologyDetails, setComplexity, setBarthel } from '../actions/records';

const mapStateToProps = (state) => {
    return {
        bookings: state.bookings.bookings,
        scheduleDates: state.newSchedule.dates
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFilledComplexity: (complexity) => {
            dispatch(setComplexity(complexity))
        },
        onFilledPatology: (patology) => {
            dispatch(fetchPatologyDetails(patology))
        },
        onFilledHospitalPatology: (patology) => {
            dispatch(fetchHospitalPatologyDetails(patology))
        },
        onFilledBarthel: (barthel) => {
            dispatch(setBarthel(barthel))
        },
        onGetBookings: (bookings) => {
            dispatch(fetchBookings(bookings))
        },
        onSaveBookings: (data) => {
            dispatch(saveSchedule(data))
        },
        addSchedule: (schedule) => {
            dispatch(saveNewSchedule(schedule))
        }
    }
}

const BookingsService = connect(
    mapStateToProps,
    mapDispatchToProps
)(Bookings);

export default BookingsService;