import { Add, Assignment, Edit } from '@material-ui/icons'
import { Grid, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'

import Button from '../CustomButtons/Button'
import ButtonSpinner from '../ButtonSpinner'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'
import CardIcon from '../Card/CardIcon'
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip'
import DateInput from '../DateInput'
import { DeleteDocument } from '../../icons'
import HozpitalizationApiInvoker from '../../api/HozpitalizationApiInvoker'
import SelectInput from '../SelectInput'
import Snackbar from '../Snackbar/Snackbar'
import Table from '../Table/Table'
import ValidationInput from '../ValidationInput'
import { browserHistory } from 'react-router'
import { useEffect } from 'react'

const HospitalizationList = (props) => {
  const [data, setData] = useState([])
  const [axaInternmentId, setAxaInternmentId] = useState(null)
  const [axaId, setAxaId] = useState(null)
  const [state, setState] = useState(null)
  const [admissionDate, setAdmissionDate] = useState(null)
  const [egressDate, setEgressDate] = useState(null)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [colorSnackBar, setColorSnackBar] = useState(null)
  const [messageSnackBar, setMessageSnackBar] = useState(null)
  const [loading, setLoading] = useState(false)
  const [filterable, setFilterable] = useState(true)

  const addInternment = () => browserHistory.push('/admission/new-form')

  let company = localStorage.getItem('itlg_default_company_name')

  const filterSearchClear = () => {
    setAxaInternmentId('')
    setAxaId('')
    setState('')
    setAdmissionDate('')
    setEgressDate('')
    setFilterable(false)
    getDataAxaInternment()
  }

  const filterSearch = () => {
    let internmentId
    let affiliateId
    let internmentStatus
    let egress_date
    let admission_date

    if (axaInternmentId) {
      internmentId = `internmentId=${axaInternmentId}`
    }
    if (axaId) {
      affiliateId = `&&affiliateId=${axaId}`
    }
    if (state) {
      internmentStatus = `&&internmentStatus=${state}`
    }
    if (egressDate) {
      egress_date = `&&egress_date=${egressDate}`
    }
    if (admissionDate) {
      admission_date = `&&admission_date=${admissionDate}`
    }
    let params = `${internmentId ? internmentId : ''}${
      affiliateId ? affiliateId : ''
    }${internmentStatus ? internmentStatus : ''}${
      egress_date ? egress_date : ''
    }${admission_date ? admission_date : ''}`

    HozpitalizationApiInvoker.filterListData(params, (response) => {
      setData(formatDataToTable(response))
    })
  }

  const optionState = [
    {
      id: 'CREATED',
      value: 'En Proceso',
    },
    {
      id: 'CANCELLED',
      value: 'Cancelada',
    },
    {
      id: 'ENDED',
      value: 'Finalizada',
    },
  ]

  const formatDataToTable = (response) => {
    let data = []
    response.map((internment) => {
      let dataInternment = {
        axa_internment_id: internment.axaInternmentId,
        axa_id: internment.affiliateId ? internment.affiliateId : '',
        admission_date: internment.admission_date,
        patient: `${internment.patientId.firstName} ${internment.patientId.lastName}`,
        egress_date: internment.egress_date,
        state:
          internment.internmentStatus == 'CREATED'
            ? 'En Proceso'
            : internment.internmentStatus == 'CANCELLED'
            ? 'Cancelada'
            : internment.internmentStatus == 'ENDED'
            ? 'Finalizada'
            : 'Sin Estado',
        actions: renderActionComponent(
          internment.axaInternmentId,
          internment.internmentStatus
        ),
      }
      data.push(dataInternment)
    })
    return data
  }

  const renderActionComponent = (id, state) => {
    return (
      <div className="appointments-actions">
        <Tooltip title={'Ver'}>
          <span>
            <Button
              simple
              justIcon
              color="info"
              authority="hospitalization_view"
              onClick={() => goToView(id)}
            >
              <Assignment />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={'Editar'}>
          <div>
            <Button
              simple
              justIcon
              disabled={state === 'CANCELLED' || state === 'ENDED'}
              color="success"
              authority="hospitalization_edit"
              onClick={() => goToEdit(id)}
            >
              <Edit />
            </Button>
          </div>
        </Tooltip>
        <Tooltip title={'Cancelar'}>
          <div>
            <Button
              simple
              justIcon
              disabled={state === 'CANCELLED' || state === 'ENDED'}
              color="danger"
              authority="hospitalization_cancel"
              onClick={() => goToCancel(id)}
            >
              <DeleteDocument className="svg-cancel-agenda" />
            </Button>
          </div>
        </Tooltip>
      </div>
    )
  }

  const goToEdit = (id) => {
    browserHistory.push(`/admission/edit-form/${id}`)
  }

  const goToView = (id) => {
    browserHistory.push(`/admission/view/${id}`)
  }

  const setDataToCancel = (internment) => {
    let dataToSend = {
      userId: window.localStorage.getItem('user_id'),
      axaInternmentId: internment.axaInternmentId,
      internmentStatus: 'CANCELLED',
      patientId: {
        personId: parseInt(internment.patientId.personId),
      },
      axaServicesCenterId: {
        axaServicesCenterId: internment.axaServicesCenterId
          ? internment.axaServicesCenterId.axaServicesCenterId
          : null,
      },
      room: internment.room ? internment.room : null,
      therapy: internment.therapy ? internment.therapy : null,
      admission_date: internment.admission_date
        ? internment.admission_date
        : null,
      egress_date: internment.egress_date ? internment.egress_date : null,
      treatingMedicalsId: {
        treatingMedicalsId: internment.treatingMedicalsId
          ? internment.treatingMedicalsId.treatingMedicalsId
          : null,
      },
      historyPathologyList: internment.historyPathologyList
        ? internment.historyPathologyList
        : null,
      admissionPathologyList: internment.admissionPathologyList
        ? internment.admissionPathologyList
        : null,
      dischargePathologyList: internment.dischargePathologyList
        ? internment.dischargePathologyList
        : null,
      barthelIndexId: internment.barthelIndexId
        ? internment.barthelIndexId.recordId
        : null,
      hospitalizationNewsList: internment.hospitalizationNewsList
        ? internment.hospitalizationNewsList
        : null,
      dischargePlaceId: internment.dischargePlaceId
        ? internment.dischargePlaceId.dischargePlaceId
        : null,
      nursing: internment.nursing ? internment.nursing : null,
      nursingFrequency: internment.nursingFrequency
        ? internment.nursingFrequency
        : null,
      kinesiology: internment.kinesiology ? internment.kinesiology : null,
      kinesiology_frequency: internment.kinesiology_frequency
        ? internment.kinesiology_frequency
        : null,
      speechTherapy: internment.speechTherapy ? internment.speechTherapy : null,
      speechTherapy_frequency: internment.speechTherapy_frequency
        ? internment.speechTherapy_frequency
        : null,
      occupationalTherapy: internment.occupationalTherapy
        ? internment.occupationalTherapy
        : null,
      occupationalTherapy_frequency: internment.occupationalTherapy
        ? internment.occupationalTherapy
        : null,
      healing: internment.healing ? internment.healing : null,
      healing_frequency: internment.healing_frequency
        ? internment.healing_frequency
        : null,
      dressingsTypeId: internment.dressingsTypeId
        ? internment.dressingsTypeId.dressingsTypeId
        : null,
      wheelchair: internment.wheelchair ? internment.wheelchair : null,
      orthopedic_bed: internment.orthopedic_bed
        ? internment.orthopedic_bed
        : null,
      walkerno: internment.walkerno ? internment.walkerno : null,
      oxygen_tube: internment.oxygen_tube ? internment.oxygen_tube : null,
      oxygen_concentrator: internment.oxygen_concentrator
        ? internment.oxygen_concentrator
        : null,
      medicineList: internment.medicineList ? internment.medicineList : null,
    }
    return dataToSend
  }

  const goToCancel = (id) => {
    HozpitalizationApiInvoker.getAxaInternmentById(id, (response) => {
      HozpitalizationApiInvoker.updateAxaInternment(
        setDataToCancel(response[0]),
        (response) => {
          setShowSnackBar(true)
          setColorSnackBar('success')
          setMessageSnackBar('La hospitalización se canceló exitosamente')
          setLoading(false)
          setTimeout(function () {
            setShowSnackBar(false)
            getDataAxaInternment()
          }, 2000)
        },
        (error) => {
          setShowSnackBar(true)
          setColorSnackBar('danger')
          setMessageSnackBar(
            'Hubo un error al intentar cancelar la internación.'
          )
          setLoading(false)
          setTimeout(function () {
            setShowSnackBar(false)
          }, 5000)
        }
      )
    })
  }

  const getDataAxaInternment = () => {
    HozpitalizationApiInvoker.getAxaInternment((response) => {
      setData(formatDataToTable(response))
      setFilterable(true)
    })
  }

  useEffect(() => {
    getDataAxaInternment()
    window.scrollTo(0, 0)
  }, [axaInternmentId, axaId, state, egressDate, admissionDate, filterable])
  return (
    <Grid container className="appointments appointments-list">
      <Snackbar
        color={colorSnackBar}
        message={messageSnackBar}
        open={showSnackBar}
        place="tc"
      />
      <Grid item xs={12}>
        <Card>
          <CardHeader
            icon
            className="filters-header"
            style={{ paddingBottom: 20 }}
          >
            <div className="header-internment-table">
              <div className="header-icon">
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </div>
              <div className="header-buttons">
                <Tooltip title="Nueva Hospitalización">
                  <span>
                    <Button
                      className="button-new-int"
                      round
                      color="primary"
                      authority="hospitalization_new"
                      onClick={addInternment}
                    >
                      <Add className="appointments-button-icon" />{' '}
                      {'Nueva Hospitalización'}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {
              <Grid container className="appointments-filters">
                <Grid
                  container
                  item
                  xs={12}
                  sm={9}
                  md={10}
                  className="appointments-filters-row"
                >
                  <Grid container item xs={12} className="filters-row">
                    <Grid container item xs={12} sm={3}>
                      <ValidationInput
                        className="filter-full no-padding"
                        text={'Nro. Hospitalización'}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="number"
                        value={axaInternmentId}
                        onChangeValue={(e) => setAxaInternmentId(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ValidationInput
                        className="filter-full no-padding"
                        text={'Nº de beneficiario'}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={axaId}
                        onChangeValue={(e) => setAxaId(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        label={'Estado'}
                        elements={optionState}
                        value={state}
                        onSelectedValue={(value) => setState(value)}
                      />
                    </Grid>

                    <Grid container item xs={12} sm={12}>
                      <Grid
                        container
                        item
                        xs={6}
                        className="date-container startDate"
                      >
                        <DateInput
                          text={'Fecha de ingreso'}
                          value={admissionDate}
                          onChangeValue={(e) => setAdmissionDate(e)}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        className="date-container endDate"
                      >
                        <DateInput
                          text={'Fecha de alta'}
                          value={egressDate}
                          onChangeValue={(e) => setEgressDate(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  className="filters-actions-appointments"
                >
                  <Grid
                    container
                    item
                    justify="flex-end"
                    className="filters-row"
                  >
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={'Buscar'}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            onClick={filterSearch}
                            label={'Buscar'}
                            labelLoading={'Buscar'}
                            typeButton="submit"
                            color="primary"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button block onClick={filterSearchClear} color="danger">
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </CardHeader>

          <CardBody className="appointments-content-body not-scroll-table filtrable-table">
            <Table
              filterable={filterable}
              striped
              loading={loading}
              tableHeaderColor="primary"
              sortable
              tableHead={[
                {
                  Header: 'Nro. de Hospitalización',
                  accessor: 'axa_internment_id',
                  width: 130,
                },
                {
                  Header: 'Nº de beneficiario',
                  accessor: 'axa_id',
                },
                {
                  Header: 'Fecha de Ingreso',
                  accessor: 'admission_date',
                },
                {
                  Header: 'Paciente',
                  accessor: 'patient',
                },
                { Header: 'Fecha de Alta', accessor: 'egress_date' },
                { Header: 'Estado', accessor: 'state' },
                {
                  Header: 'Acciones',
                  accessor: 'actions',
                  width: 200,
                  sortable: false,
                  filterable: false,
                },
              ]}
              tableData={data}
              colorsColls={['primary']}
              className="-striped -highlight filtrable sticky"
              defaultPageSize={data.length <= 25 ? 25 : data.length}
              showPaginationTop={false}
              showPaginationBottom={true}
            />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default HospitalizationList
