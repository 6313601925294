import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Navbar from '../Navbar';
import AdminNavbar from '../Navbars/AdminNavbar';
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import PropTypes from 'prop-types';
import routes from '../../routes';
import './Navigator.css';
import ChatBubble from '@material-ui/icons/ChatBubble';
import Chat from '../Chat/index.js';
import Button from '../CustomButtons/Button';

class Navigator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            miniActive: false,
            open: false,
            brandText: '',
            isChatVisible: false
        }
    }

    componentWillReceiveProps() {
        this.setBrandText()
    }

    componentDidMount() {
        this.setBrandText()
    }

    isTheCorrectPath (route, browserPath) {
        if (!route.path.includes(':')) {
            return browserPath.lastIndexOf("/") === browserPath.indexOf(route.path);
        } else {
            let routeWithoutId = route.path.substring(0, route.path.lastIndexOf("/"));
            let broswerPathWithoutId = browserPath.substring(0, browserPath.lastIndexOf("/"));

            return broswerPathWithoutId === routeWithoutId;
        }
    }

    setBrandText() {
        const pathname = browserHistory.getCurrentLocation().pathname;
        routes.forEach(route => {            
            if (route.path && this.isTheCorrectPath(route, pathname)) {
                this.setState({
                    brandText: route.name
                })
                return;
            } else {
                if (route.views) {
                    route.views.forEach(v => {
                        if (!v.views) {
                            // This is used to match routes like /patient/:ID/medical-record/full against /patient/5/medical-record/full
                            const routeMatcher = new RegExp(v.path.replace(/:[^\s/]+/g, '([\\w-]+)'));
                            if (pathname.match(routeMatcher)) {
                                this.setState({
                                    brandText: v.name
                                })
                                return
                            }
                        } else {
                            v.views.forEach(view => {
                                if (view.views) {
                                    view.views.forEach(view2 => {
                                        if (view2.path && browserHistory.getCurrentLocation().pathname == view2.path) {
                                            this.setState({
                                                brandText: (view2.titlewindow ? view2.titlewindow : view2.name)
                                            })
                                            
                                            return
                                        }
                                    }
                                    );
                                } else {
                                    if (view.path && browserHistory.getCurrentLocation().pathname.indexOf(view.path) !== -1) {
                                        this.setState({
                                            brandText: (view.titlewindow ? view.titlewindow : view.name)
                                        })
                                        
                                        return
                                    }

                                }
                            })
                        }
                    })
                }
            }
        })
    }

    sidebarMinimize() {
        this.setState({
            miniActive: !this.state.miniActive
        })
    }

    handleDrawerToggle() {
    
        this.setState({
            open: !this.state.open
        })
    }
      
    startChat = () => {
        this.setState((prev) => ({ ...prev, isChatVisible: !this.state.isChatVisible }));
    }

    render() {
        return (
            <div>
                <Navbar
                    miniActive={this.state.miniActive}
                    open={this.state.open}
                    handleDrawerToggle={() => this.handleDrawerToggle()}
                />
                <div>
                    <GridContainer>
                        <GridItem xs={12} className={this.state.miniActive ? "customers-content-header-mini" : "customers-content-header"}>
                            <AdminNavbar brandText={this.state.brandText}
                                sidebarMinimize={() => this.sidebarMinimize()}
                                miniActive={this.state.miniActive}
                                handleDrawerToggle={() => this.handleDrawerToggle()} />
                        </GridItem>
                    </GridContainer>
                    <div className={this.state.miniActive ? 'content-mini' : 'content'}>
                        {this.props.children}
                        <Button
                            id="button-signin"
                            onClick={this.startChat}
                            class="chat-floating">
                                <ChatBubble />
                        </Button>
                        <Chat login={false} isVisible={this.state.isChatVisible}/> 
                    </div>
                </div>
            </div>
        )
    }
}

Navigator.propTypes = {
    children: PropTypes.any
}

export default Navigator;