import { SUPPLYCATALOG_DETAIL, ADD_SUPPLYCATALOG,EDIT_SUPPLYCATALOG, UNMOUNT_SUPPLYCATALOG} from "../actions/supplycatalog";


const supplycatalog = (state = {}, action, root) => {
    
    switch(action.type) {
        case UNMOUNT_SUPPLYCATALOG:
            return {}
        case SUPPLYCATALOG_DETAIL:
            let supplycatalog = action.supplycatalog
            return {...state, supplycatalog}
        case ADD_SUPPLYCATALOG: 
                const result = addSupplyCatalog(state.supplycatalog, action.supplycatalog)
                return {...state, supplycatalog: result}
        case EDIT_SUPPLYCATALOG: 
                const updated = updateSupplyCatalog(state.supplycatalog, action.supplycatalog)
                return {...state, supplycatalog: updated}
        default:
            return state
    }
}

const addSupplyCatalog = (supplycatalogs, supplycatalog) => {
    if (supplycatalogs && supplycatalogs.length) {
        supplycatalogs.push(supplycatalog);
        return supplycatalogs;
    }
    return ;
}

const updateSupplyCatalog = (supplycatalogs, supplycatalog) => {
    if (supplycatalogs && supplycatalogs.length) {
        supplycatalogs.map((c, i) => {
            if (c.supplyCatalogId === supplycatalog.supplyCatalogId) {
                supplycatalogs[i] = supplycatalog
                return;
            }
        })
    }
    return supplycatalogs;
}



export default supplycatalog;
