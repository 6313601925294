import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import ApiInvoker from '../../api/ApiInvoker';
import { Syringe } from '../../icons';
import './SupplyCatalog.css';

class SupplyCatalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplycatalog: [],
            loading: false,
        }
    }

    componentWillMount() {
        if (this.props.supplycatalog) {
            this.formatCatalog(this.props.supplycatalog);
        } else {
            this.getSupplyCatalog();
        }
    }

    componentWillReceiveProps(next) {
        if (next.supplycatalog) {
            this.formatCatalog(next.supplycatalog);
        }
    }

    getSupplyCatalog() {
        this.setState({ loading: true });
        ApiInvoker.getSupplyCatalog(data => {
            this.setState({ loading: false });
            if (data && data.length) {
                this.props.onGetSupplyCatalog(data);
            }
        }, e => {
            this.setState({ loading: false });
            console.error('** error', e);
        });
    }

    addSupply = () => browserHistory.push('nuevo-insumo');

    editSupply = (id) => browserHistory.push(`/editar-insumo/${id}`);

    formatCatalog(supplycatalog) {
        const data = supplycatalog.map(c => {
            return {
                name: c.name,
                supplyCatalogCode: c.supplyCatalogCode,
                detail: c.detail,
                actions: <div className="supplycatalog-actions">
                    <Tooltip title={this.props.t('common.edit')}>
                        <span>
                            <Button
                                simple
                                justIcon
                                color="success"
                                authority="supply_catalog_edit"
                                onClick={() => this.editSupply(c.supplyCatalogId)}
                            ><Edit /></Button>
                        </span>
                    </Tooltip>
                </div>
            }
        })
        this.setState({ supplycatalog: data });
    }

    exportToExcel = (supplies) => {
        const { t } = this.props;
        let r = [];
        const fields = {
            'supplyCatalogCode': t('supplycatalog.table.supply_catalog_code'),
            'name': t('supplycatalog.table.name'),
            'detail': t('supplycatalog.table.detail'),
        };
        r.push(fields);
        
        supplies.forEach(s => {
            const supply = {
                supplyCatalogCode: s.supplyCatalogCode,
                name: s.name,
                detail: s.detail,
            }
            r = r.concat(supply);
        })

        const fileName = `supplies-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(r, {skipHeader: 1});
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data2, fileName + fileExtension);
        return; 
    }

    render() {
        const { t } = this.props;
        const { loading, supplycatalog } = this.state;
        return (
            <GridContainer className="supplies-catalog-container">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <Syringe />
                            </CardIcon>
                            <Button
                                className="add-content-button supplycatalog-button"
                                round
                                color="success"
                                authority="supply_catalog_new"
                                onClick={() => this.addSupply()}
                            >
                                <Add className="supplycatalog-button-icon" /> {t('supplycatalog.create')}
                            </Button>
                            
                        </CardHeader>
                        <CardBody className="filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t('supplycatalog.table.supply_catalog_code'), accessor: 'supplyCatalogCode' },
                                    { Header: t('supplycatalog.table.name'), accessor: 'name' },
                                    { Header: t('supplycatalog.table.detail'), accessor: 'detail' },
                                    { Header: t('supplycatalog.table.actions'), accessor: 'actions', sortable: false, filterable: false }
                                ]}
                                tableData={supplycatalog}
                                colorsColls={['primary']}
                            />
                        </CardBody>
                        <CardFooter className="supplycatalog-submit">
                            <Button
                                className="button-export"
                                onClick={() => this.exportToExcel(supplycatalog)}
                                color="success"
                                disabled={supplycatalog.length < 1}
                            >
                                {t('common.exportExcel')}
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}
 
SupplyCatalog.propTypes = {
    onGetSupplyCatalog: PropTypes.func.isRequired,
    supplycatalog: PropTypes.array,
}

export default withTranslation()(SupplyCatalog);
