import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardFooter from '../Card/CardFooter';
import CardIcon from '../Card/CardIcon';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import ApiInvoker from '../../api/ApiInvoker';
import './EditSupplyCatalog.css';

class EditSupplyCatalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTypes: [],
            documents: [],
            name: '',
            lastName: '',
            document: '',
            detail: '',
            id: '',
            documentType: '',
            email: '',
            supplyCatalogCode: '',
            birthDate: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            personId: '',
            openAddress: false,
            openPhone: false,
            openFinanciers: false,
            deleteAlert: false,
            address: null,
            phone: null,
            save: false,
            refresh: true,
            busy: false,
        }
    }

    componentWillMount() {
        this.findSupplyCatalog();
    }

    componentWillReceiveProps(next) {
    }
    
    cleanFields() {
        this.setState({
            name: '',
            supplyCatalogCode: '',
            detail: '',
            save: false,
            busy: false,
        });
    }
    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                browserHistory.push(`/catalogo-insumos/`);
                this.cleanFields();
            }
        }, 5000);
    }

    saveSupplyCatalog() {
        this.setState({ save: true, busy: true });
        if (this.state.name && this.state.name.trim() && this.state.supplyCatalogCode && this.state.supplyCatalogCode.trim()) {
            ApiInvoker.postSupplyCatalog({
                supplyCatalogId: this.state.id,
                name: this.state.name,
                supplyCatalogCode: this.state.supplyCatalogCode,
                detail: this.state.detail,
                companyId: this.state.companyId,
            }, data => {
                this.setState({ busy: false });
                this.props.onEditSupplyCatalog(data);
                this.openAlert('success', this.props.t('supplycatalog.successMessage'))
            }, error => {
                console.error(error);
                this.setState({ busy: false });
            })
        } else {
            this.openAlert('warning', this.props.t('supplycatalog.new.warning'));
            this.setState({ busy: false });
        }
    }

    handleValue(value, state) {
        this.setState({
            [state]: value,
            refresh: false,
        });
    }

    findSupplyCatalog() {
        ApiInvoker.getSupplySuppl(this.props.routeParams.id, supply => {
            this.setState({
                name: supply.name,
                detail: supply.detail,
                supplyCatalogCode: supply.supplyCatalogCode,
                id: supply.supplyCatalogId,
                companyId: supply.companyId,
            })
        }, null);
    }

    back() {        
        browserHistory.goBack();
    }

    render() {
        const { t } = this.props;

        return (
            <GridContainer className="edit-supplycatalog">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="success">
                                <EditIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('supplycatalog.title')}</h3>
                        </CardHeader>
                        <CardBody className="edit-supplycatalog-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('supplycatalog.edit.supplyCatalogCode')} value={this.state.supplyCatalogCode} onChangeValue={(value) => this.handleValue(value, 'supplyCatalogCode')} invalid={!(this.state.supplyCatalogCode && this.state.supplyCatalogCode.trim()) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('supplycatalog.edit.name')} value={this.state.name} onChangeValue={(value) => this.handleValue(value, 'name')} invalid={!(this.state.name && this.state.name.trim()) && this.state.save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ValidationInput text={t('supplycatalog.edit.detail')} value={this.state.detail} onChangeValue={(value) => this.handleValue(value, 'detail')} />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className="padding-horizontal">
                            <Button onClick={this.back}>
                                {t('supplycatalog.new.cancel')}
                            </Button>
                            <ButtonSpinner
                                onClick={() => this.saveSupplyCatalog()}
                                disabled={this.state.busy}
                                label={t('supplycatalog.new.save')}
                                labelLoading={t('supplycatalog.new.saving')}
                                loading={this.state.busy}
                                typeButton="submit"
                                color="info"
                            />
                        </CardFooter>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

EditSupplyCatalog.propTypes = {
    onEditSuppyCatalog: PropTypes.func.isRequired,
}

export default withTranslation()(EditSupplyCatalog);