import { connect } from 'react-redux';
import NewUser from '../components/NewUser';
import ConfirmUser from '../components/ConfirmUser';

import {getToolTypes, addTool, editTool } from '../actions/tools';

const mapStateToProps = (state) => {
    return {
        tools: state.tools.tools,
        tooltypes: state.tooltypes.tooltypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetToolTypes: (tooltypes) => {
            dispatch(getToolTypes(tooltypes))
        },
         onAddTool: (tool) => {
            dispatch(addTool(tool))
        },
        onEditTool: (tool) => {
            dispatch(editTool(tool))
        }
    }
}

const NewUserService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewUser);

const ConfirmUserService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmUser);

export default {NewUser, ConfirmUser};