import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CustomDialog from '../../CustomDialog';
import Table from '../../Table/Table';
import DashboardApiInvoker from '../../../api/DashboardApiInvoker';

const PopupSchedulesRejected = (props) => {
  //t, open, dateFrom, dateTo, employeeId, openMap, closePopup

  const [dataTable, setDataTable] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const dateFormat = 'DD/MM/YYYY HH:mm';
  
  const sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if(a1<b1)
     return -1;
    else if(a1>b1)
        return 1;
    else
        return 0;                  
  }

  const getDataTable = async (employeeId) => {
    setDataLoading(true);
    setDataTable([]);
    DashboardApiInvoker.postEmployeeDashboardScheduleRejected({
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
        employeeId: employeeId
    }, dataTable => {
        setDataLoading(false);
        formatDataTable(dataTable)
    }, err => {
        setDataLoading(false);
        console.error(err)
    });
  }

  const formatDataTable = (dataTable) => {
    let result = [];
    if (dataTable && dataTable.length>0) {
      const { t } = props
      result = dataTable.map(s => {
          return {
            appoinmentId: s.appointmentId ,
            scheduleId: s.scheduleId,
            scheduleDateTime: moment(s.scheduleDateTime).format(dateFormat),
            customer: `${s.customerFirstName} ${s.customerLastName}`,
            practice: s.practiceTypeName,
            scheduleStatus: t(`schedule.booking.state.${s.scheduleStatus}`),
            rejected_date_time: s.dateTimeReject,
            rejected_detail: s.reasonRejectedDetail
          }
      })
      setDataTable(result);
    }
  }

  const close = () => {
    props.closePopup('openPopupScheduleRejected');
  }

  useEffect(() => {
    if (props.open) {
      getDataTable(props.employeeId);
    }
  }, [props.open])
  
  const { t } = props;

  return (
      <CustomDialog
        maxWidth="lg"
        open={props.open}
        title={t('dashboard.modal.table.rejecteds.tittle')}
        onClose={() => close()}
      >
        <Table
          loading={dataLoading}
          tableHeaderColor="primary"
          sortable={true}
          tableHead={[
            { Header: t('dashboard.modal.table.rejecteds.appointmentId'), accessor: 'appoinmentId' },
            { Header: t('dashboard.modal.table.rejecteds.schedule_id'), accessor: 'scheduleId' },
            { Header: t('dashboard.modal.table.rejecteds.schedule_date_time'), accessor: 'scheduleDateTime', sortMethod: (a, b) => sortDate(a, b)  },
            { Header: t('dashboard.modal.table.rejecteds.customer'), accessor: 'customer' },
            { Header: t('dashboard.modal.table.rejecteds.practice'), accessor: 'practice' },
            { Header: t('dashboard.modal.table.rejecteds.schedule_status'), accessor: 'scheduleStatus' },
            { Header: t('dashboard.modal.table.rejecteds.rejected_date_time'), accessor: 'rejected_date_time', sortMethod: (a, b) => sortDate(a, b)},
            { Header: t('dashboard.modal.table.rejecteds.rejected_detail'), accessor: 'rejected_detail' },
          ]}
          tableData={dataTable}
          colorsColls={["primary"]}
        />
    </CustomDialog>
  );
};

export default PopupSchedulesRejected;