import { colors, makeStyles } from '@material-ui/core';

export const useStylesReassignAppStepper = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    margin: theme.spacing(2, 0),
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: `${colors.pink[400]} !important`,
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: colors.green[400],
    }
  },
  optionalCaption: {
    display: 'none',//'block',
  },
  loadingContent: {
    minHeight: 200,
    padding: theme.spacing(6, 0, 0),
    textAlign: 'center',
  },
  buttons: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 2),
  },
  buttonsStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonsEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    '& + $button': {
      marginLeft: theme.spacing(1),
    },
  },
  confirmStep0: {
    flexDirection: 'column',
  },
  titleSection: {
    fontSize: theme.typography.pxToRem(21),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
  },
  notConfig: {
    padding: theme.spacing(1, 2, 2),
    '& p': {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
  },
}));

export const useStylesGralSteps = makeStyles((theme) => ({
  formStepper: {
    width: '100%',
    alignItems: 'flex-start',
    '&:not($readOnlyForm)': {
      minHeight: 200,
    },
  },
  stepTitle: {
    fontWeight: 400,
  },
  spacingTop4: {
    marginTop: theme.spacing(4),
  },
  spacingTop7: {
    marginTop: theme.spacing(7),
  },
  readOnlyForm: {
    pointerEvents: 'none',
  },
  autocompleteField: {
    '& .MuiFormLabel-root': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  actionsButtonContent: {
    marginTop: theme.spacing(5),
  },
  buttonsStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonsEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    '& + $button': {
      marginLeft: theme.spacing(1),
    },
  },
  titleWidthIconEnd: {
    '& span': {
      display: 'inline-block',
      paddingRight: 8,
    },
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  actionButton: {
    marginTop: 8,
  },
}));

export const useStylesFrecuency = makeStyles((theme) => ({
  frecuencyPlan: {
    width: `calc(100% + ${theme.spacing(5)}px)`,
    margin: theme.spacing(-5, -2.4),
  },
  daysSelector: {
    '& .day-picker': {
      padding: '0 !important',
    },
  },
}));

export const useStylesDaysHours = makeStyles((theme) => ({
  daysHoursPlan: {
    width: `calc(100% + ${theme.spacing(5)}px)`,
    margin: theme.spacing(-5, -2.4, 0),//'-40px -20px 0',
    '& .MuiGrid-root .MuiInputBase-input.Mui-disabled': {
      color: '#808589 !important',
    },
  },
  settingscheck: {
    paddingBottom: '0 !important',
    '& .MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  practiceHours: {
    '& .date-input > .MuiGrid-item': {
      padding: '0 !important',
    },
  },
  availableTime: {
    paddingTop: theme.spacing(4),
    '& .MuiGrid-item:not(:last-child) .select-input > .MuiGrid-item': {
      paddingLeft: '0 !important',
    },
    '& .MuiGrid-item:last-child .select-input > .MuiGrid-item': {
      paddingRight: '0 !important',
    },
  },
  recurrenceTypeTitle: {
    paddingBottom: theme.spacing(1.6),
  },
  daysSelector: {
    marginTop: theme.spacing(3),
    '& .day-picker': {
      padding: '0 !important',
    },
  },
}));

export const useStylesProfessionals = makeStyles((theme) => ({
  professionalsPlan: {
    width: 'calc(100% + 40px)',
    margin: '-40px -20px 0',
    '& .MuiGrid-root .MuiInputBase-input.Mui-disabled': {
      color: '#808589 !important',
    },
  },
  settingscheck: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '0 !important',
    '& .MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  shiftsContainer: {
    '& .speciality ': {
      '&> .MuiGrid-root, .MuiGrid-root .padding-horizontal, .select-input-container': {
        padding: '0 !important',
      },
      '& .checkLabel.geographics': {
        marginLeft: '-11px',
      },
    },
    '& .speciality-group': {
      '&:nth-child(odd)': {
        paddingRight: theme.spacing(5),
      },
      '&:nth-child(even)': {
        paddingLeft: theme.spacing(5),
      },
      '&>.speciality-item>.title-item': {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
  },
}));

export const useStylesProviders = makeStyles(() => ({
  radioProviders: {
    '& .radio-input': {
      display: 'block',
      padding: '0 !important',
      width: '100%',
      '& .radio-input-group': {
        display:'block !important',
        width: '100%',
        '&+ .radio-input-group': {
          marginTop: 15,
        },
        '& .GridItem-grid-60': {
          width: '100%',
        },
        '&.disabled-radio': {
          opacity: .7,
        },
        '& .radio-component': {
          paddingLeft: '34px !important',
        },
      },
    },
  },
}));

export const useStylesDateTime = makeStyles(() => ({
  contentDateTimeInput: {
    '& .GridItem-grid-60': {
      padding: '0 !important',
    },
  },
  columnDateTime: {
    overflow: 'visible !important',
    '& .rdt input.form-control': {
      textAlign: 'center',
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
}));
