import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class HistoriaClinicaApi extends Component {

	static getHistoriaClinicaList(pacienteId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customer/${pacienteId}/appointment`, callback, callerror);
	}
	static getIntegralClinicalHistoryIntegral(customerId, dateFrom, dateTo, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/${customerId}/clinical-history?date-from=${dateFrom}&date-to=${dateTo}`, callback, callerror);
	}
}