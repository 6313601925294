import { LOCATIONS } from '../actions/address';

const address = (state = {}, action) => {
    switch(action.type) {
        case LOCATIONS:
            let locations = action.locations
            return {...state, locations}
        default:
            return state
    }
}

export default address;