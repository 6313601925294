import React from 'react';
import Chartist from 'chartist'
import ChartistGraph from 'react-chartist'
import CardHeader from '../../Card/CardHeader'
import CardBody from '../../Card/CardBody'
import GridItem from '../../Grid/GridItem'
import GridContainer from '../../Grid/GridContainer'
import { withTranslation } from 'react-i18next'

const IntegralVitalSignCharts = (props) => {
  const {
    temperature,
    pulse,
    oxigen,
    arterialTension,
    t
  } = props;

  const chartOptionsPulse = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',
    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
    low: 45,
    high: 200
  }
  const chartOptionsOxigen = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',

    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
    low: 50,
    high: 100
  }
  const chartOptionsTemperature = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',

    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }), low: 34,
    high: 45
  }
  const chartOptionsPressure = {
    chartPadding: {
      top: 15,
      right: 60,
      bottom: 5,
      left: 60
    },
    fullWidth: true,
    position: 'start',

    lineSmooth: Chartist.Interpolation.cardinal({
      fillHoles: true,
    }),
    low: 40,
    high: 250
  }

  return (
    <GridContainer className="vitalSignsView">
      <GridItem xs={12} className="rowItem chartistGraph first">
        <h3 className="chart-header headerVitalSigns"> {t('technicalfile.temperature')}</h3>
        <div className="chartistGraphBody">
          <ChartistGraph
            options={chartOptionsTemperature}
            data={temperature}
            type="Line"
          />
        </div>
      </GridItem>
      <GridItem xs={12} className="rowItem chartistGraph">
        <h3 className="chart-header headerVitalSigns"> {t('technicalfile.pulse')} </h3>
        <div className="chartistGraphBody">
          <ChartistGraph
            options={chartOptionsPulse}
            data={pulse}
            type="Line"
          />
        </div>
      </GridItem>
      <GridItem xs={12} className="rowItem chartistGraph">
        <h3 className="chart-header headerVitalSigns">{t('technicalfile.oxigen')}</h3>
        <div className="chartistGraphBody">
          <ChartistGraph
            options={chartOptionsOxigen}
            data={oxigen}
            type="Line"
          />
        </div>
      </GridItem>
      <GridItem xs={12} className="rowItem chartistGraph">
        <h3 className="chart-header headerVitalSigns">{t('technicalfile.arterialTension')}</h3>
        <div className="chartistGraphBody">
          <ChartistGraph
            options={chartOptionsPressure}
            data={arterialTension}
            type="Line"
          />
          <p className="chart-legend">
            <span className="chart-legend-blue">{t('technicalfile.pressureMax')}</span>
            <span className="chart-legend-red">{t('technicalfile.pressureMin')}</span>
          </p>
        </div>
      </GridItem>
    </GridContainer>
  )
}

export default withTranslation()(IntegralVitalSignCharts);