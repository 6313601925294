import './EditCustomer.css'

import { Address as AddressIcon, QrCode } from '../../icons'
import React, { Component } from 'react'

import Add from '@material-ui/icons/Add'
import Address from '../Address/address'
import AddressService from '../../containers/AddressService'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '../CustomButtons/Button'
import ButtonSpinner from '../ButtonSpinner'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardFooter from '../Card/CardFooter'
import CardHeader from '../Card/CardHeader'
import CardIcon from '../Card/CardIcon'
import CustomDialog from '../CustomDialog'
import DateInput from '../DateInput'
import Financiers from '../Financiers'
import FinanciersService from '../../containers/FinanciersService'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Person from '@material-ui/icons/Person'
import PersonApiInvoker from '../../api/PersonApiInvoker'
import Phone from '../Phone'
import PhoneIcon from '@material-ui/icons/Phone'
import PhoneService from '../../containers/PhoneService'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import SelectInput from '../SelectInput'
import Snackbar from '../Snackbar/Snackbar'
import { TextField } from '@material-ui/core'
import ValidationInput from '../ValidationInput'
import Work from '@material-ui/icons/Work'
import _ from 'lodash'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class EditCustomer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mode:
        (this.props &&
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.mode) ||
        '',
      documentTypes: [],
      documents: [],
      nationalities: [],
      genderTypes: [],
      name: '',
      lastName: '',
      document: '',
      gender: '',
      documentType: '',
      email: '',
      birthDate: '',
      nationality: '',
      alertColor: 'info',
      alertMessage: '',
      alertOpen: false,
      personId: '',
      openAddress: false,
      openPhone: false,
      openFinanciers: false,
      deleteAlert: false,
      address: null,
      phone: null,
      save: false,
      dataChanged: false,
      refresh: true,
      loading: false,
      firstPhone: false,
      phones: [],
    }
  }

  componentWillMount() {
    this.getGeneralPractitioner()
    this.getNationalities(this.findCustomer)
    this.getGenderTypes()

    if (this.props.documentTypes && this.props.documentTypes.length > 0) {
      const documentTypes = this.formatDocumentTypes(this.props.documentTypes)
      this.setState({
        documentTypes,
        documents: this.props.documentTypes,
      })
    } else {
      this.getDocumentTypes()
    }

    //if(this.state.phones.length<=0){this.setState({firstPhone: true});}
  }

  setRegistration(id) {
    const result = this.state.generalPractitioner.find(
      (doctor) => doctor.id === id
    )
    this.setState({ registration: result.registration })
  }

  getGeneralPractitioner() {
    this.setState({ loading: true })
    PersonApiInvoker.getGeneralPractitioner(
      (data) => {
        this.setState({ loading: false })
        if (data && data.length) {
          const result = this.formatGeneralPractitioner(data)
          this.setState({ generalPractitioner: result })
        }
      },
      (e) => {
        this.setState({ loading: false })
      }
    )
  }

  formatGeneralPractitioner(GeneralPractitioner) {
    let result = GeneralPractitioner.map((d) => {
      return {
        id: parseInt(d.generalPractitionerId),
        value: `${d.name} ${d.lastName}`,
        registration: d.medicalRegistrationNumber,
      }
    })
    return result
  }

  componentWillReceiveProps(next) {
    if (next.documentTypes) {
      const documentTypes = this.formatDocumentTypes(next.documentTypes)
      this.setState({
        documentTypes,
        documents: next.documentTypes,
      })
    }
  }

  formatDocumentTypes(documentTypes) {
    const result = documentTypes.map((d) => ({
      id: d.idTypeId,
      value: `${d.name} - ${d.type}`,
    }))
    return result
  }

  setGenderTypesState(genderTypes) {
    const { t } = this.props
    const genderTypesMap = {
      UNDEFINED: t('common.gender.undefined'),
      MALE: t('common.gender.male'),
      FEMALE: t('common.gender.female'),
    }
    const result = genderTypes.map((genderType) => ({
      id: genderType,
      value: genderTypesMap[genderType],
    }))

    this.setState({ genderTypes: result })
  }

  getDocumentTypes() {
    PersonApiInvoker.getIdTypes((data) => {
      this.props.onGetDocumentTypes(data)
    }, null)
  }

  getGenderTypes() {
    PersonApiInvoker.getGenderTypes((data) => {
      this.setGenderTypesState(data)
    }, null)
  }

  findDocumentType(id) {
    const type = this.state.documents.find((d) => d.idTypeId === id)
    return type
  }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    })

    setTimeout(() => {
      this.setState({ alertOpen: false })
    }, 5000)
  }

  validEmail(value) {
    if (value.length === 0) return true
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRex.test(value)
  }

  saveCustomer() {
    this.setState({ save: true, loading: true })
    const { t } = this.props
    const requiredFields =
      this.state.name &&
      this.state.lastName &&
      this.state.document &&
      this.state.documentType !== '0' &&
      this.state.birthDate &&
      this.state.nationality &&
      this.state.gender &&
      this.state.generalPractitioner

    if (requiredFields && this.validEmail(this.state.email)) {
      PersonApiInvoker.patchCustomer(
        {
          avatarUrl: '',
          id: this.state.document,
          birthdate: moment(this.state.birthDate).format('YYYY-MM-DD'),
          email: this.state.email,
          sex: this.state.gender,
          firstName: this.state.name,
          nationality: this.findNationality(),
          idType: this.findDocumentType(this.state.documentType),
          lastName: this.state.lastName,
          personId: this.state.personId,
          metadata: {},
          generalPractitionerId: {
            generalPractitionerId: this.state.generalPractitionerId,
          },
          verificationId: 0,
          qr: '',
        },
        (data) => {
          this.props.onEditCustomer(data)
          this.setState({ dataChanged: false, loading: false })
          this.openAlert('success', t('customers.new.success'))
        },
        (error) => {
          this.openAlert('danger', 'Error: ' + error.message + '')
        }
      )
    } else {
      const messageEmail = !this.validEmail(this.state.email)
        ? t('message.invalidEmail')
        : ''
      const messageRequired = !requiredFields
        ? t('appointment.new.requiredFields')
        : ''
      this.openAlert('warning', `${messageRequired} ${messageEmail}`)
      this.setState({ loading: false })
    }
  }

  handleValue(value, state) {
    this.setState({
      [state]: value,
      dataChanged: true,
      refresh: false,
    })
  }

  findCustomer() {
    const { routeParams, t } = this.props
    PersonApiInvoker.getCustomer(
      routeParams.id,
      (customer) => {
        this.setState({
          name: customer.firstName,
          lastName: customer.lastName,
          birthDate: customer.birthdate,
          nationality: this.loadNationality(customer),
          gender: customer.sex,
          email: customer.email,
          document: customer.id || '',
          documentType: customer.idType.idTypeId,
          personId: customer.personId,
          phones: customer.phone,
          generalPractitionerId: customer.generalPractitionerId
            ? customer.generalPractitionerId.generalPractitionerId
            : '',
          medicalRegistrationNumber: customer.generalPractitionerId
            ? customer.generalPractitionerId.medicalRegistrationNumber
            : '',
          //firstPhone: (customer.phone.length>0) ? false : true
        })
      },
      (e) => {
        this.openAlert('danger', e.message || t('customers.404.error'))
        setTimeout(() => {
          browserHistory.goBack()
        }, 5000)
      }
    )
  }

  getNationalities(findCustomer) {
    PersonApiInvoker.getNationalities((jsonResponse) => {
      this.setState(
        {
          nationalities: jsonResponse.map((nationalityItem) => {
            return {
              id: nationalityItem.nationalityId,
              name: nationalityItem.name,
            }
          }),
        },
        findCustomer
      )
    }, findCustomer)
  }

  findNationality() {
    const nationalityObj = _.find(
      this.state.nationalities,
      (nationalityItem) => {
        return nationalityItem.id === this.state.nationality.id
      }
    )

    return {
      name: nationalityObj.name,
      nationalityId: nationalityObj.id,
    }
  }

  loadNationality(customer) {
    const customerNationalityId = _.get(
      customer.nationality,
      'nationalityId',
      ''
    )
    let nationalityObj = _.find(this.state.nationalities, (nationalityItem) => {
      return nationalityItem.id === customerNationalityId
    })

    return nationalityObj
  }

  createAddress() {
    this.setState({
      openAddress: true,
      address: null,
    })
  }

  createPhone() {
    if (this.state.phones.length > 0) {
      this.setState({ firstPhone: false })
    } else {
      this.setState({ firstPhone: true })
    }
    this.setState({
      openPhone: true,
      phone: null,
    })
  }

  createFinancier() {
    this.setState({
      openFinanciers: true,
      financier: null,
    })
  }

  editAddress(address) {
    this.setState({
      openAddress: true,
      address,
    })
  }

  editPhone(phone) {
    this.setState({
      openPhone: true,
      phone,
    })
  }

  editFinancier(financier) {
    this.setState({
      openFinanciers: true,
      financier,
    })
  }

  goBack() {
    browserHistory.push('/patient')
  }

  render() {
    const { t } = this.props
    const {
      address,
      alertColor,
      alertMessage,
      alertOpen,
      dataChanged,
      document,
      documentType,
      documentTypes,
      email,
      gender,
      genderTypes,
      lastName,
      mode,
      name,
      nationalities,
      nationality,
      openAddress,
      openFinanciers,
      openPhone,
      personId,
      phone,
      save,
      refresh,
      financier,
      loading,
      firstPhone,
      generalPractitionerId,
      medicalRegistrationNumber,
    } = this.state

    const maxDate = moment().format('YYYY-MM-DD')
    const minDate = moment().subtract(120, 'years').format('YYYY-MM-DD')
    return (
      <div className="edit-customer">
        <GridContainer>
          <GridItem xs={12} sm={12} md={mode === 'view' ? 12 : 8}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Person />
                </CardIcon>
                <h3 className="card-icon-title">
                  {t('customers.new.title.profile')}
                </h3>
              </CardHeader>
              <CardBody className="edit-customer-form">
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ValidationInput
                        disabled={mode === 'view'}
                        text={t('customers.new.first_name')}
                        onChangeValue={(value) =>
                          this.handleValue(value, 'name')
                        }
                        value={name}
                        invalid={name === '' && save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ValidationInput
                        disabled={mode === 'view'}
                        text={t('customers.new.last_name')}
                        onChangeValue={(value) =>
                          this.handleValue(value, 'lastName')
                        }
                        value={lastName}
                        invalid={lastName === '' && save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Autocomplete
                        disabled={mode === 'view'}
                        className="nationality-autocomplete select-input"
                        options={nationalities}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ''
                        }
                        getOptionSelected={(option) => option.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('customers.new.nationality')}
                            fullWidth
                          />
                        )}
                        onChange={(event, value) =>
                          this.handleValue(value, 'nationality')
                        }
                        value={nationality}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <SelectInput
                        className="select-input"
                        disabled={mode === 'view'}
                        label={t('customers.new.id_type')}
                        elements={documentTypes}
                        onSelectedValue={(value) =>
                          this.handleValue(value, 'documentType')
                        }
                        value={documentType}
                        invalid={documentType === '' && save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ValidationInput
                        disabled={mode === 'view'}
                        text={t('customers.new.id')}
                        type="text"
                        onChangeValue={(value) =>
                          this.handleValue(value, 'document')
                        }
                        value={document}
                        invalid={document === '' && save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <SelectInput
                        className="select-input"
                        disabled={mode === 'view'}
                        label={t('customers.new.gender')}
                        elements={genderTypes}
                        onSelectedValue={(value) =>
                          this.handleValue(value, 'gender')
                        }
                        value={gender}
                        invalid={gender === '' && save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} className="calendarPicker">
                      <DateInput
                        text={t('customers.new.birth_date')}
                        disabled={mode === 'view'}
                        minDate
                        min={minDate}
                        max={maxDate}
                        onChangeValue={(value) =>
                          this.handleValue(value, 'birthDate')
                        }
                        value={this.state.birthDate}
                        invalid={
                          this.state.birthdate
                            ? this.state.birthdate <= maxDate
                            : !this.state.birthDate && save
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ValidationInput
                        disabled={mode === 'view'}
                        text={t('customers.new.email')}
                        type="email"
                        onChangeValue={(value) =>
                          this.handleValue(value, 'email')
                        }
                        value={email}
                        invalid={!this.validEmail(email)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <SelectInput
                        className="select-input"
                        label={'Médico de cabecera'}
                        elements={this.state.generalPractitioner}
                        onSelectedValue={(value) => {
                          this.handleValue(value, 'generalPractitionerId')
                          this.setRegistration(value)
                        }}
                        value={
                          this.state.generalPractitionerId
                            ? this.state.generalPractitionerId
                            : generalPractitionerId
                        }
                        invalid={
                          this.state.generalPractitionerId === '' &&
                          this.state.save
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <ValidationInput
                        text={'Registro médico'}
                        type="text"
                        onChangeValue={(value) =>
                          this.handleValue(value, 'registration')
                        }
                        value={
                          this.state.registration
                            ? this.state.registration
                            : medicalRegistrationNumber
                            ? medicalRegistrationNumber
                            : ''
                        }
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <p className="required-text">
                        <small>*</small> {t('customers.new.required')}
                      </p>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              {mode === 'view' ? (
                ''
              ) : (
                <CardFooter className="edit-customer-submit flex-end">
                  <ButtonSpinner
                    onClick={() => this.saveCustomer()}
                    disabled={!dataChanged || loading}
                    label={t('customers.new.save')}
                    labelLoading={t('customers.new.saving')}
                    loading={loading}
                    typeButton="submit"
                    color="info"
                  />
                </CardFooter>
              )}
            </Card>
          </GridItem>
          {mode !== 'view' && (
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <QrCode />
                  </CardIcon>
                  <h3 className="card-icon-title">
                    {t('customers.new.qr.code')}
                  </h3>
                </CardHeader>
                <CardBody className="edit-customer-qr text-center">
                  <QRCode
                    id={personId}
                    value={`{"personId":${personId}}`}
                    size={250}
                    level={'L'}
                    includeMargin={true}
                    renderAs={'canvas'} //svg
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <AddressIcon />
                </CardIcon>
                <h3 className="card-icon-title">
                  {t('customers.new.addresses')}
                </h3>
              </CardHeader>
              <CardBody>
                <Address
                  componenMode={mode === 'view' ? 'view' : ''}
                  personId={personId}
                  refresh={refresh}
                  onEditAddress={(address) => this.editAddress(address)}
                />
              </CardBody>
              {mode !== 'view' && (
                <CardFooter className="content-button-add flex-end">
                  <Button
                    className="edit-customer-create"
                    color="primary"
                    onClick={() => this.createAddress()}
                  >
                    <Add />
                    {t('customers.new.create.address')}
                  </Button>
                </CardFooter>
              )}
            </Card>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <PhoneIcon />
                </CardIcon>
                <h3 className="card-icon-title">{t('customers.new.phones')}</h3>
              </CardHeader>
              <CardBody>
                <Phone
                  componenMode={mode === 'view' ? 'view' : ''}
                  personId={personId}
                  refresh={refresh}
                  onEditPhone={(phone) => this.editPhone(phone)}
                  onDeletePhone={(newPhones) =>
                    this.setState({ phones: newPhones })
                  }
                />
              </CardBody>
              {mode === 'view' ? (
                ''
              ) : (
                <CardFooter className="content-button-add flex-end">
                  <Button
                    className="edit-customer-create"
                    color="primary"
                    onPhoneSubmited={(newPhone) =>
                      this.setState({ phones: [newPhone] })
                    }
                    onClick={(phone, firstPhone) =>
                      this.createPhone(phone, firstPhone)
                    }
                  >
                    <Add />
                    {t('customers.new.create.phone')}
                  </Button>
                </CardFooter>
              )}
            </Card>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Work />
                </CardIcon>
                <h3 className="card-icon-title">
                  {t('customers.new.financers')}
                </h3>
              </CardHeader>
              <CardBody>
                <Financiers
                  componenMode={mode === 'view' ? 'view' : ''}
                  personId={personId}
                  refresh={refresh}
                  onEditFinancier={(financier) => this.editFinancier(financier)}
                />
              </CardBody>
              {mode !== 'view' && (
                <CardFooter className="content-button-add flex-end">
                  <Button
                    className="edit-customer-create"
                    color="primary"
                    onClick={() => this.createFinancier()}
                  >
                    <Add />
                    {t('customers.new.create.financer')}
                  </Button>
                </CardFooter>
              )}
            </Card>
            <Snackbar
              place="tr"
              color={alertColor}
              message={alertMessage}
              open={alertOpen}
            />
            {personId && (
              <CustomDialog
                title={t('address.title')}
                open={openAddress}
                onClose={() =>
                  this.setState({ address: null, openAddress: false })
                }
                maxWidth="lg"
              >
                <AddressService
                  edit={address}
                  personId={personId}
                  onAddressSubmited={() =>
                    this.setState({
                      address: null,
                      openAddress: false,
                      refresh: true,
                    })
                  }
                />
              </CustomDialog>
            )}
            {personId && (
              <CustomDialog
                title={t('phone.title')}
                open={openPhone}
                onClose={() => this.setState({ phone: null, openPhone: false })}
              >
                <PhoneService
                  edit={phone}
                  firstPhone={firstPhone}
                  personId={personId}
                  onPhoneSubmited={(data) =>
                    this.setState({
                      phone: null,
                      openPhone: false,
                      refresh: true,
                      phones: [data],
                    })
                  }
                />
              </CustomDialog>
            )}
            {personId && (
              <CustomDialog
                title={t('financer.title')}
                open={openFinanciers}
                onClose={() =>
                  this.setState({ phone: null, openFinanciers: false })
                }
              >
                <FinanciersService
                  edit={financier}
                  personId={personId}
                  onFinancierSubmitted={() =>
                    this.setState({
                      financiers: null,
                      openFinanciers: false,
                      refresh: true,
                    })
                  }
                />
              </CustomDialog>
            )}
          </GridItem>
          <GridItem className="actions-buttons text-center">
            <Button onClick={this.goBack.bind(this)}>
              {t('appointment.new.back')}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

EditCustomer.propTypes = {
  onGetDocumentTypes: PropTypes.func.isRequired,
  onEditCustomer: PropTypes.func.isRequired,
}

export default withTranslation()(EditCustomer)
