import React from 'react';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'universal-cookie';
import map from 'lodash/map';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Badge, ClickAwayListener, FormControl, Grow, MenuItem, MenuList, Paper, Popper, Tooltip,
} from '@material-ui/core';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Help from '@material-ui/icons/Help';
//import NotificationsIcon from '@material-ui/icons/Notifications';
// core components
import LetterAvatar from '../Avatar/LetterAvatar';
import Button from '../CustomButtons/Button';
import CustomDialog from '../CustomDialog';
import ImageUpload from '../CustomUpload/ImageUpload';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import About from '../About';
import BaseInvoker from '../../api/BaseInvoker';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import adminNavbarLinksStyle from '../../assets/components/adminNavbarLinksStyle';
import logoHMM from '../../assets/img/hmm-logo-shadow.png';
import './Navbars.css';

const baseInvoker = new BaseInvoker();

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openProfile: false,
    openAbout: false,
    openNotifications: false,
    openAboutModal: false,
    alertsLength: 0,
    alertsIgnored: [],
    alertsFailed: [],
    multipleSelect: [],
    companies: [],
    user: {},
    email: '',
    companySelect: '',
    subdomain: '',
    changingCompany: false,
    checking: false,
    notRead: 0,
    notifications: [],
  };

  componentWillMount = () => {
    const { companies, onSelectedCompany } = this.props;

    if (companies && companies.length > 0) {

      const storedCompany = parseInt(localStorage.getItem('itlg_default_company_id'), 10);
      const companySelect = storedCompany && this.companyBelongsToUser(companies, storedCompany) ? storedCompany : companies[0].companyId;

      onSelectedCompany(companySelect);
      this.setState({
        companySelect,
        companies,
      })
    } else {
      this.getCompanies();
    }

    this.setState({
      user: localStorage.getItem('username'),
      email: localStorage.getItem('email'),
    })
  }

  componentWillReceiveProps(next) {
    if (next.companies) {
      const storedCompany = parseInt(localStorage.getItem('itlg_default_company_id'), 10);
      const companySelect = storedCompany && this.companyBelongsToUser(next.companies, storedCompany) ? storedCompany : next.companies[0].companyId;
      this.setState({
        companies: next.companies,
        companySelect,
      });
    }
  }

  handleProfileClick = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };

  handleProfileClose = () => {
    if (this.state.changingCompany) {
      this.setState({ changingCompany: false });
    } else {
      this.setState({ openProfile: false });
    }
  };

  
  handleClickNotifications = () => this.setState({ openNotifications: !this.state.openNotifications });
  
  handleCloseNotifications = () => this.setState({ openNotifications: false });
  
  handleClickAbout = () => this.setState({ openAbout: !this.state.openAbout });
  
  handleCloseAbout = () => this.setState({ openAbout: false });

  handleCompany = (event) => {
    const targetCompany = event.target.value;
    let company = this.state.companies.find(item => item.companyId === targetCompany);
    if (company) {
      fetch(`/api/setcompany?itlg_default_company_id=${targetCompany}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(profile => {
          if (profile) {
            this.setState({
              changeButtonProps: true,
              companySelect: targetCompany,
              openProfile: false,
            });
            this.props.onChangeCompany();
            localStorage.setItem('itlg_default_company_id', targetCompany);
            this.props.onSelectedCompany(targetCompany);
            localStorage.setItem('company', JSON.stringify(company));
            if (window.location.href.indexOf("/solicitudes") > -1) {
              window.location.reload();
            } else {
              browserHistory.push('/solicitudes');
            }
          }
        });
    }
  };

  handleAbout = () => this.setState({ openAboutModal: !this.state.openAboutModal });

  closeAboutModal = () => this.setState({ openAboutModal: false });

  companyBelongsToUser = (companies, companyId) => companies && companies.length > 0 && map(companies, 'companyId').includes(companyId);

  setCompanies(profile) {
    if (profile && profile.companies) {
      this.props.onGetCompanies(profile.companies);
      if (!localStorage.getItem('itlg_default_company_id') && profile.companies && profile.companies.length > 0) {
        localStorage.setItem('itlg_default_company_id', JSON.stringify(profile.companies[0].companyId));
      } else {
        //localStorage.removeItem('itlg_default_company_id')
      }
    }
  }

  getCompanies = () => {
    let url = '/api/profile';
    const company = localStorage.getItem('itlg_default_company_id');
    if (company) {
      url = `${url}?itlg_default_company_id=${company}`
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) => {
      if (response.status === 401) {

      } else {
        return response.text().then(function (text) {
          return text ? JSON.parse(text) : {}
        })
      }
    }).then(data => {
      if (data && !data.message && !data.error) {
        this.setState({ checking: false });
        this.setCompanies(data);
      } else {
        this.setState({ checking: true });
      }
    });
  }

  logoutUser = () => {
    const cookies = new Cookies();
    localStorage.removeItem('username');
    cookies.remove('sessionActive');
    this.props.onLogout();
    this.props.onClearData();
    browserHistory.push('/login');
    baseInvoker.post('/oauth/logout');
  }

  getCompanyName = () => {
    const { companies, selectedCompany } = this.props;
    let companyName = '';

    if (companies && companies.length > 0) {
      let companyIndex;
      companyIndex = companies.findIndex(item => item.companyId === selectedCompany);

      if (companyIndex !== -1) {
        companyName = companies[companyIndex].name
      }
    }
    return companyName;
  }

  componentDidMount() {
    const subdomain = window.location.host.split('.')[0];
    this.setState({ subdomain });
    this.getNotifications();
  }

  getNotifications = () => {
    PersonApiInvoker.getNotification(data => {
      let notRead = 0;
      data.forEach(noti => {
        if (!noti.readDateTime) {
          notRead++;
        }
      });
      this.setState({
        notifications: data,
        notificationsNotRead: notRead,
      })
    }, (error) => console.log('** error getNotification', error));
  }

  getFormattedDateTime = (datetime) => {
    const { t } = this.props;
    const messaged = moment(datetime).local();
    const now = moment.utc(new Date());
    const duration = moment.duration(now.diff(messaged));
    const hours = duration.asHours();
    if (hours > 24) {
      const numberDays = parseInt(hours/24);
      return t('notification.daysAgo', { days: numberDays });
    } else if (hours > 1) {
      const numberHours = parseInt(hours);
      return t('notification.hoursAgo', { hours: numberHours });
    } else {
      const minsHours = parseInt(hours*60);
      return t('notification.minutesAgo', { mins: minsHours });
    }
  }

  getNotificationByType = (data) => {
    if (data.notificationType === 'NEW_USER') {
      const role = this.props.t('common.role.prof');
      const fullName = `${data.firstName} ${data.lastName}`;
      return this.props.t('notification.newUser', {
        role,
        fullName,
        email: data.email,
      });
    }
  }

  setRead = (data) => {
    const mThis = this;
    PersonApiInvoker.setNotificationRead(data.notificationId, () => {
      mThis.getNotifications();
    }, (error) => {
      mThis.getNotifications();
      console.log('** error setNotificationRead', error);
    });
  }

  renderNotifications = (data, openNotifications) => {
    const { t, classes } = this.props;
    const classesPopper = classNames({
      [classes.popperClose]: !openNotifications,
      [classes.pooperResponsive]: true,
      [classes.pooperResponsiveAbout]: true,
      [classes.pooperNav]: true
    });

    return data.length > 0 &&
      <Popper
        open={openNotifications}
        anchorEl={this.anchorEl}
        transition
        disablePortal
        placement="left"
        className={classesPopper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="messages-list"
            style={{ transformOrigin: "0 0 0" }}
            >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={this.handleCloseNotifications}>
                <div className="notifications-container">
                  {data.map(notification => {
                    const dateTime = this.getFormattedDateTime(notification.createDateTime);
                    const notificationTitleTooltip = notification.readDateTime ? t('notifications.markAsUnread') : t('notifications.markAsRead');
                    const notificationClass = notification.readDateTime ? 'notification-container read' : 'notification-container';
                    return (
                      <GridContainer className={notificationClass}>
                        <GridItem xs={11}>
                          <p className="message" dangerouslySetInnerHTML={{__html: this.getNotificationByType(notification)}} />
                          <p className="datetime">{dateTime}</p>
                        </GridItem>
                        <GridItem xs={1} className="marked-row">
                          <Tooltip title={notificationTitleTooltip}>
                            <span className="marked" onClick={() => this.setRead(notification)}></span>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                  <div className="notifications-footer">
                    <img src={logoHMM} alt="HMM" />
                    <div>
                      <p>{t('notifications.lastDays', { days: 10 })}</p>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
  }

  render() {
    const { t, classes, rtlActive } = this.props;
    const {
      notifications, openProfile, openAbout, openAboutModal, user, email, companies, openNotifications,
    } = this.state;

    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    // mlgmlg
    return (
      <div id="rightMenuTopStyle" className={wrapper}>

        <div className={`${managerClasses} about-menu`}>
          <Button
            color="transparent"
            justIcon
            aria-label="About"
            aria-owns={openAbout ? "about-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickAbout}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Help
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
          </Button>
          <Popper
            open={openAbout}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="left"
            className={classNames({
              [classes.popperClose]: !openAbout,
              [classes.pooperResponsive]: true,
              [classes.pooperResponsiveAbout]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="about-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseAbout}>
                    <MenuList role="menu">
                      <MenuItem>
                        <a className="menu-item" rel="noopener noreferrer" href="https://hmmglobal.com/soporte/" target="_blank"> Soporte </a>
                      </MenuItem>
                      <MenuItem>
                        <a className="menu-item" rel="noopener noreferrer" href={`https://${this.state.subdomain}.hmmglobal.com/eula.html`} target="_blank"> Términos y condiciones de uso </a>
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleAbout}
                      >
                        <span className="menu-item" > {t("header.navbar.about")} </span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        {/* Profile button */}
        <div className={`${managerClasses} profile-menu`}>
          <Button
            color="transparent"
            justIcon
            aria-label="Profile User"
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleProfileClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ''
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            {/* <Hidden mdUp implementation="css">
              <span onClick={this.handleProfileClick} className={classes.linkText}>
                {rtlActive ? "إعلام" : "Profile"}
              </span>
            </Hidden> */}
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleProfileClose}>
                    <MenuList role="menu">
                      <MenuItem
                        className={dropdownItem}
                      >
                        <div className="current-user">

                          <div className="user-avatar">
                            <div className="avatar">
                              {
                                user && user.photo ? ( // FIXME: Once user profile is ready change this.
                                  <ImageUpload
                                    avatar
                                    photo={user.photo}
                                    addButtonProps={{
                                      color: "transparent",
                                    }}
                                    changeButtonProps={{
                                      color: "transparent",
                                    }}
                                    removeButtonProps={{
                                      color: "transparent",
                                    }}
                                  />
                                ) : (
                                  <div className="letter-avatar">
                                    <LetterAvatar user={user} />
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className="user-info">
                            <div className="data-name">
                              <strong>{user}</strong><br />
                              {email}<br />
                              <div>
                                {this.getCompanyName()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                      {companies && companies.length > 0 && (
                        <React.Fragment>
                          <hr className="divider-root divider-light" />
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >


                          </FormControl>
                        </React.Fragment>
                      )}
                      <hr className="divider-root divider-light" />
                      <MenuItem
                        className={`${dropdownItem} light-grey text-right`}
                      >
                        <Button
                          aria-label="logout"
                          onClick={() => this.logoutUser()}
                        >{t('header.navbar.logout')}</Button>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <CustomDialog
          maxWidth="sm"
          open={openAboutModal}
          onClose={this.closeAboutModal}
          title={t('header.navbar.about.title')}
        >
          <About />
        </CustomDialog>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  companies: PropTypes.any,
  onGetCompanies: PropTypes.func,
  onSelectedCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.number,
  setDefaultCompany: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
  onClearData: PropTypes.func.isRequired,
};

export default withStyles(adminNavbarLinksStyle)(withTranslation()(HeaderLinks));
