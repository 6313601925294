import '../../../DiabeticProgramStyles.css'

import React, { useEffect } from 'react'

import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import { useMemo } from 'react'

const PhysicalActivity = (props) => {
  const { setData, editData, onlyView } = props
  const [walkDaily, setWalkDaily] = React.useState('no')

  const FormatData = () => {
    let data = {
      walkDaily: walkDaily == 'yes' ? true : false,
    }
    setData(data)
  }

  const SetEdtData = (data) => {
    setWalkDaily(data.walkDaily === true ? 'yes' : 'no')
  }

  const Edit = useMemo(() => {
    if (editData) {
      SetEdtData(editData)
    }
  }, [editData])

  useEffect(() => {
    FormatData()
    // eslint-disable-next-line no-unused-expressions
    Edit
  }, [walkDaily])

  return (
    <>
      <GridItem md={12}>
        <h4>Actividad física</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-diabetic">
            ¿Camina al menos 30 min diarios?:
          </p>
          <RadioInput
            value={walkDaily}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => setWalkDaily(value)}
          />
        </div>
      </GridItem>
    </>
  )
}

export default PhysicalActivity
