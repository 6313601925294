import '../../../DiabeticProgramStyles.css'

import { Card, Modal } from '@material-ui/core'
import React, { useEffect } from 'react'

import { Add } from '@material-ui/icons'
import CardBody from '../../../../Card/CardBody'
import CardHeader from '../../../../Card/CardHeader'
import CardIcon from '../../../../Card/CardIcon'
import CustomInput from '../../../../CustomInput/CustomInput'
import DateInput from '../../../../DateInput'
import DiabeticApiInvoker from '../../../../../api/DiabeticApiIvoker'
import GridContainer from '../../../../Grid/GridContainer'
import GridItem from '../../../../Grid/GridItem'
import HozpitalizationApiInvoker from '../../../../../api/HozpitalizationApiInvoker'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import MedicationsForm from '../../../../HospitalizationForm/MedicationsForm'
import RadioInput from '../../../../RadioInput'
import RegularButton from '../../../../CustomButtons/Button.jsx'
import SelectInput from '../../../../SelectInput'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

const RenalFunction = (props) => {
  const { setData, setValidateRenalFunctionSection, editData, onlyView } = props

  const [ureaId, setUreaId] = React.useState(null)
  const [creatinineId, setCreatinineId] = React.useState(null)
  const [ureaOptions, setUreaOptions] = React.useState([])
  const [creatinineOptions, setCreatinineOptions] = React.useState([])
  const [xRayThorax, setXRayThorax] = React.useState('no')
  const [xRayThoraxDate, setxRayThoraxeDate] = React.useState(null)
  const [electrocardiogram, setElectrocardiogram] = React.useState('no')
  const [electrocardiogramDate, setElectrocardiogramDate] = React.useState(null)
  const [echocardiogram, setEchocardiogram] = React.useState('no')
  const [echocardiogramDate, setEchocardiogramDate] = React.useState(null)

  const [medicationsData, setMedicationsData] = React.useState([])
  const [medicineTypeOptions, setMedicineTypeOptions] = React.useState([])
  const [medicineUnitPresentationOptions, setMedicineUnitPresentationOptions] =
    React.useState([])
  const [medicineUnitTypeOptions, setMedicineUnitTypeOptions] = React.useState(
    []
  )
  const [medicineWayTypeOptions, setMedicineWayTypeOptions] = React.useState([])
  const [validateAddMedication, setValidateAddMedication] = React.useState(true)

  const [medication, setMedication] = React.useState(null)
  const [presentation, setPresentation] = React.useState(null)
  const [dose, setDose] = React.useState(null)
  const [unit, setUnit] = React.useState(null)
  const [way, setWay] = React.useState(null)

  const FormatData = () => {
    let data = {
      ureaId: ureaId,
      creatinineId: creatinineId,
      xRayThorax: xRayThorax === 'yes' ? true : false,
      xRayThoraxDate: xRayThoraxDate,
      electrocardiogram: electrocardiogram === 'yes' ? true : false,
      electrocardiogramDate: electrocardiogramDate,
      echocardiogram: echocardiogram === 'yes' ? true : false,
      echocardiogramDate: echocardiogramDate,
      medicineList: medicationsData,
    }
    setData(data)
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const resetValues = () => {
    setMedication(null)
    setPresentation(null)
    setDose(null)
    setUnit(null)
    setWay(null)
  }

  const addMedication = (data) => {
    setMedicationsData([...medicationsData, data])
    handleClose()
    resetValuesForMedication()
  }

  const resetValuesForMedication = () => {
    setMedication(null)
    setPresentation(null)
    setDose(null)
    setWay(null)
  }

  const formatOptions = (index) =>
    index.map((e) => ({
      id: e.id,
      value: e.label,
    }))

  const getLaboratoryOptions = () => {
    DiabeticApiInvoker.getAllTUreaOptions((data) => {
      let laboratoryOptions = formatOptions(data)
      setUreaOptions(laboratoryOptions)
    })
    DiabeticApiInvoker.getAllTCreatininaOptions((data) => {
      let laboratoryOptions = formatOptions(data)
      setCreatinineOptions(laboratoryOptions)
    })
  }

  const formatMedicineType = (index) =>
    index.map((e) => ({
      id: e.medicineTypeId,
      value: e.name,
    }))

  const SetMedicineType = () => {
    HozpitalizationApiInvoker.getMedicinesType((response) => {
      let medicinesType = formatMedicineType(response)
      setMedicineTypeOptions(medicinesType)
    })
  }

  const SetMedicineUnitType = () => {
    HozpitalizationApiInvoker.getMedicinesUnitType((response) => {
      let medicinesUnitType = formatMedicineUnitType(response)
      setMedicineUnitTypeOptions(medicinesUnitType)
    })
  }

  const formatMedicinePresentationType = (index) =>
    index.map((e) => ({
      id: e.medicinePresentationTypeId,
      value: e.name,
    }))

  const SetMedicinePresentationType = () => {
    HozpitalizationApiInvoker.getMedicinesPresentationType((response) => {
      let medicinesPresentationType = formatMedicinePresentationType(response)
      setMedicineUnitPresentationOptions(medicinesPresentationType)
    })
  }

  const SetValidateAddMedication = () => {
    if (!medication || !presentation || !dose || !unit || !way) {
      setValidateAddMedication(true)
    } else {
      setValidateAddMedication(false)
    }
  }

  const formatMedicineWayType = (index) =>
    index.map((e) => ({
      id: e.medicineViaTypeId,
      value: e.name,
    }))

  const SetMedicineWayType = () => {
    HozpitalizationApiInvoker.getMedicinesWayType((response) => {
      let medicinesWayType = formatMedicineWayType(response)
      setMedicineWayTypeOptions(medicinesWayType)
    })
  }

  const formatMedicineUnitType = (index) =>
    index.map((e) => ({
      id: e.medicineUnitTypeId,
      value: e.name,
    }))

  const removeMedication = (id) => {
    const newMedications = medicationsData.filter(
      (medication) =>
        medication.id !== id.id ||
        medication.diabeticsMedicineId !== id.diabeticsMedicineId
    )
    setMedicationsData(newMedications)
  }

  const validateSection = () => {
    if (
      (xRayThorax === 'yes' && !xRayThoraxDate) ||
      (electrocardiogram === 'yes' && !electrocardiogramDate) ||
      (echocardiogram === 'yes' && !echocardiogramDate)
    ) {
      setValidateRenalFunctionSection(false)
    } else {
      setValidateRenalFunctionSection(true)
    }
  }

  const setEditData = (data) => {
    setUreaId(data.ureaId)
    setCreatinineId(data.creatinineId)
    setXRayThorax(data.xRayThorax === true ? 'yes' : 'no')
    setxRayThoraxeDate(data.xRayThoraxDate)
    setElectrocardiogram(data.electrocardiogram === true ? 'yes' : 'no')
    setElectrocardiogramDate(data.electrocardiogramDate)
    setEchocardiogram(data.echocardiogram === true ? 'yes' : 'no')
    setEchocardiogramDate(data.echocardiogramDate)
    setMedicationsData(data.medicineList)
  }

  const GetOptions = useMemo(() => {
    SetMedicineType()
    SetMedicineUnitType()
    SetMedicineWayType()
    SetMedicinePresentationType()
    getLaboratoryOptions()
    if (editData) {
      setEditData(editData)
    }
  }, [editData])

  useEffect(() => {
    SetValidateAddMedication()
    FormatData()
    validateSection()
    // eslint-disable-next-line no-unused-expressions
    GetOptions
  }, [
    ureaId,
    creatinineId,
    xRayThorax,
    xRayThoraxDate,
    electrocardiogram,
    electrocardiogramDate,
    echocardiogram,
    echocardiogramDate,
    medication,
    presentation,
    dose,
    unit,
    way,
    medicationsData,
  ])

  return (
    <>
      <GridItem md={12}>
        <h5>Función renal:</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Urea:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setUreaId(value)}
            elements={ureaOptions}
            value={ureaId}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Creatinina:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setCreatinineId(value)}
            elements={creatinineOptions}
            value={creatinineId}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">RX de tórax:</p>
          <RadioInput
            value={xRayThorax}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setxRayThoraxeDate(null)
                setXRayThorax(value)
              } else {
                setXRayThorax(value)
              }
            }}
          />
          {xRayThorax === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setxRayThoraxeDate(value)}
              value={xRayThoraxDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              disabled={onlyView}
              error={xRayThorax === 'yes' && !xRayThoraxDate}
              errorText={
                xRayThorax === 'yes' && !xRayThoraxDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Electrocardiograma:</p>
          <RadioInput
            value={electrocardiogram}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setElectrocardiogramDate(null)
                setElectrocardiogram(value)
              } else {
                setElectrocardiogram(value)
              }
            }}
          />
          {electrocardiogram === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setElectrocardiogramDate(value)}
              value={electrocardiogramDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              disabled={onlyView}
              error={electrocardiogram === 'yes' && !electrocardiogramDate}
              errorText={
                echocardiogram === 'yes' && !echocardiogramDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Ecocardiograma:</p>
          <RadioInput
            value={echocardiogram}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setEchocardiogramDate(null)
                setEchocardiogram(value)
              } else {
                setEchocardiogram(value)
              }
            }}
          />
          {echocardiogram === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setEchocardiogramDate(value)}
              value={echocardiogramDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              disabled={onlyView}
              error={echocardiogram === 'yes' && !echocardiogramDate}
              errorText={
                echocardiogram === 'yes' && !echocardiogramDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <h4>Medicación</h4>
      </GridItem>
      {medicationsData && (
        <>
          {medicationsData.map((medication) => (
            <MedicationsForm
              dataM={medication}
              removeMedication={removeMedication}
              withoutRemove={false}
              onlyView={onlyView}
            ></MedicationsForm>
          ))}
        </>
      )}
      <GridItem md={12}>
        <div>
          <RegularButton
            onClick={() => {
              handleOpen()
              resetValues()
            }}
            size="sm"
            color="warning"
            disabled={onlyView}
          >
            <Add className="appointments-button-icon" /> Agregar Medicación
          </RegularButton>
        </div>
      </GridItem>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          <div className="modal-medication">
            <Card>
              <CardHeader color="primary" icon>
                <GridItem md={12}>
                  <CardIcon color="warning">
                    <LocalHospitalIcon />
                  </CardIcon>
                  <h4>Medicación</h4>
                </GridItem>
              </CardHeader>
              <CardBody>
                <form action="">
                  <GridContainer>
                    <GridItem md={4}>
                      <SelectInput
                        id="medication"
                        label="Medicación *"
                        onSelectedValue={(value) => setMedication(value)}
                        elements={medicineTypeOptions}
                        value={medication}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        id="presentacion"
                        label="Presentación *"
                        onSelectedValue={(value) => setPresentation(value)}
                        elements={medicineUnitPresentationOptions}
                        value={presentation}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <CustomInput
                        labelText="Dosis *"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'number',
                        }}
                        value={dose}
                        disabled={!presentation}
                        onChange={(e) => setDose(e.target.value)}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        id="unit"
                        label="Unidad *"
                        onSelectedValue={(value) => setUnit(value)}
                        elements={medicineUnitTypeOptions}
                        value={unit}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        id="via"
                        label="Vía *"
                        onSelectedValue={(value) => setWay(value)}
                        elements={medicineWayTypeOptions}
                        value={way}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}></GridItem>
                    <GridItem md={4}></GridItem>
                    <GridItem xs={12}>
                      <p className="required-text">
                        <small>*</small> {'Campos requeridos'}
                      </p>
                    </GridItem>

                    <GridItem md={12}>
                      <div className="modal-footer">
                        <RegularButton
                          onClick={handleClose}
                          size="sm"
                          color="default"
                        >
                          Cancelar
                        </RegularButton>
                        <RegularButton
                          onClick={() =>
                            addMedication({
                              id: uuidv4(),
                              medicineTypeId: {
                                medicineTypeId: medication,
                                name: document.getElementById('medication')
                                  ? document.getElementById('medication').value
                                  : '',
                              },
                              medicinePresentationType: {
                                medicinePresentationTypeId: presentation,
                                name: document.getElementById('presentacion')
                                  ? document.getElementById('presentacion')
                                      .value
                                  : '',
                              },
                              medicineDose: dose,
                              medicineViaTypeId: {
                                medicineViaTypeId: way,
                                name: document.getElementById('via')
                                  ? document.getElementById('via').value
                                  : '',
                              },
                              medicineUnitType: {
                                medicineUnitTypeId: unit,
                                name: document.getElementById('unit')
                                  ? document.getElementById('unit').value
                                  : '',
                              },
                            })
                          }
                          size="sm"
                          color="success"
                          disabled={validateAddMedication}
                        >
                          Agregar
                        </RegularButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </div>
        </>
      </Modal>
    </>
  )
}

export default RenalFunction
