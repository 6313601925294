import { connect } from 'react-redux';
import { getToolTypes,unmountToolType } from '../actions/tooltypes';
import ToolTypes from '../components/ToolTypes';

const mapStateToProps = (state) => {
    return {
        tooltypes: state.tooltypes.tooltypes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetToolTypes: (tooltypes) => {
            dispatch(getToolTypes(tooltypes))
        },
        unmountToolTypes: () => {
            dispatch(unmountToolType())
        },

    }
}

const ToolTypesService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ToolTypes);

export default ToolTypesService;