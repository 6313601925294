import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Stethoscope(props) {
  return (
    <SvgIcon width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M103 854 c-24 -25 -25 -28 -21 -137 3 -93 7 -120 26 -157 30 -56 67 -96 112 -121 19 -10 49 -32 66 -48 31 -28 32 -33 35 -118 4 -80 7 -93 33 -127 16 -21 44 -44 62 -52 45 -19 243 -19 287 0 43 18 85 68 93 112 4 23 20 48 43 69 32 30 36 39 36 84 0 44 -4 55 -33 83 -28 28 -39 33 -82 33 -43 0 -54 -5 -82 -33 -29 -28 -33 -39 -33 -83 0 -46 4 -54 38 -85 43 -40 46 -56 12 -89 -23 -24 -31 -25 -135 -25 -104 0 -112 1 -135 25 -22 21 -25 33 -25 100 0 74 1 76 36 108 20 18 48 38 63 46 43 22 81 63 109 116 22 43 27 65 30 162 4 109 4 112 -21 137 -22 22 -35 26 -82 26 l-55 0 0 -40 0 -40 41 0 41 0 -4 -93 c-4 -108 -25 -151 -91 -195 -34 -22 -52 -27 -106 -27 -79 0 -128 25 -169 87 -22 35 -27 53 -30 134 l-4 94 41 0 41 0 0 40 0 40 -55 0 c-47 0 -60 -4 -82 -26z" />
      </g>
    </SvgIcon>
  );
}
