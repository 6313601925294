import { Component } from 'react';
import BaseInvoker from './BaseInvoker';

const urlInvoke = '/api';
const baseInvoker = new BaseInvoker();

export default class ScheduleApi extends Component {

	static getScheduleModel(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule-model/${scheduleId}`, callback, callerror);
	}

	static getSchedulesReasign(startDate, endDate, employeeId, appointmentId, practiceTypeId, customerId, startTime, evenDay, dayOfWeek, callback, callerror) {
		//alert(`${urlInvoke}/schedules-reasign/?date-from=${startDate}&date-to=${endDate}${employeeId}${appointmentId}${practiceTypeId}${customerId}${startTime}${evenDay}${dayOfWeek}`);
		baseInvoker.get(
			`${urlInvoke}/schedules-reasign/?date-from=${startDate}&date-to=${endDate}${employeeId}${appointmentId}${practiceTypeId}${customerId}${startTime}${evenDay}${dayOfWeek}`,
			callback,
			callerror,
		);
	}

	static finishBySystem(scheduleId, body, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/schedule/${scheduleId}/finish-by-system`, body, callback, callerror);
	}

}