import { connect } from 'react-redux';
import { getEmployee, getCustomers, getProvincies } from '../actions/reasignappointments';
import Filters from '../components/ReassignAppointmentsStepper/components/AppointmentsStep/Filters';
import ReassignAppointmentsStepper from '../components/ReassignAppointmentsStepper';

const mapStateToProps = (state) => {
    return {
        profesionals: state.dashboardprofesional.employee,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCustomers: (customers) => {
            dispatch(getCustomers(customers))
        },
        onGetEmployeeTypes: (employees) => {
            dispatch(getEmployee(employees))
        },
        onGetProvincies: (provincies) => {
            dispatch(getProvincies(provincies))
        },
    }
}

const ReassignAppointmentsStepperService = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReassignAppointmentsStepper);

const FiltersService = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Filters);

export { ReassignAppointmentsStepperService, FiltersService };
