import React from 'react';
import PropTypes from 'prop-types';
import { colors, makeStyles, Snackbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  snackbarCont: {
    '&.warning': {
      backgroundColor: colors.orange[400],
    },
    '&.danger': {
      backgroundColor: colors.red[400],
    },
    '&.success': {
      backgroundColor: colors.green[400],
    },
  }
}));

const NotificationState = ({ notification }) => {
  const classes = useStyles();
  const { color, message, open } = notification;
  return (
    <Snackbar
      place="tr"
      open={open}
      message={message}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ContentProps={{
        className: `${classes.snackbarCont} ${color}`,
      }}
    />
  );
}

NotificationState.propTypes = {
  notification: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
};

export default NotificationState;
