import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import HotelIcon from '@material-ui/icons/Hotel';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import store from '../../../store';
import '../NewSchedule.css';

class CustomerForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            customer: '',
            customerId: '',
            addresses: [],
            address: '',
            zone: '',
            location: '',
            province: '',
        }
    }

    componentDidMount() {
        this.getCustomers();
        if (this.props.value) {
            this.handleValue(this.props.value.customer, 'customer');
            this.handleValue(this.props.value.address, 'address');
        }
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            const customers = this.formatCustomers(next.customers);
            this.setState({ customers });
        }
        
        if (this.props.value && this.props.value.customer && this.state.customers) {
            const customerIndex = this.state.customers.findIndex(item => item.id === this.props.value.customer);
            if (customerIndex !== -1) {
                const customerId = this.state.customers[customerIndex].customerId;
                this.setState({ customerId });
            }
        }
    }

    formatCustomers = (customers) => customers.map(c => ({
        id: c.personId,
        value: `${c.firstName} ${c.lastName}`,
        customerId: c.id,
    }));

    formatAddresses = (addresses) => addresses.map(a => ({
        id: a.addressId,
        value: `${a.street} ${a.number}`,
        zone: a.geographicZone.detail,
        geographicZoneId: a.geographicZone.geographicZoneId,
        location: a.location.name,
        province: a.provinceName,
    }));

    getCustomers() {
        if (store.getState().customers.customers &&
            store.getState().customers.customers.length > 0) {
            const customers = this.formatCustomers(store.getState().customers.customers);
            this.setState({ customers })
            return;
        }
        PersonApiInvoker.getCustomers(true, data => {
            this.props.onGetCustomers(data)
        }, _ => {
            this.setState({ loading: false })
        })
    }

    getAddresses(personId) {
        PersonApiInvoker.getPersonsAddress(personId,true,
            data => {
                if (data && data.length) {
                    this.setState({
                        addresses: this.formatAddresses(data),
                    }, () => {
                        let address = null;
                        if (this.state.addresses.length === 0) {
                            address = {
                                address: '',
                                zone: '',
                                location: '',
                                province: '',
                                geographicZoneId: '',
                            };
                        } else if (this.state.addresses.length === 1) {
                            address = {
                                address: this.state.addresses[0].id,
                                zone: this.state.addresses[0].zone,
                                location: this.state.addresses[0].location,
                                province: this.state.addresses[0].province,
                                geographicZoneId: this.state.addresses[0].geographicZoneId,
                            };
                        } else if (this.state.addresses.length > 1) {
                            const addressIndex = this.state.addresses.findIndex(item => item.id === this.state.address)
                            if (addressIndex !== -1) {
                                address = {
                                    address: this.state.addresses[addressIndex].id,
                                    zone: this.state.addresses[addressIndex].zone,
                                    location: this.state.addresses[addressIndex].location,
                                    province: this.state.addresses[addressIndex].province,
                                    geographicZoneId: this.state.addresses[addressIndex].geographicZoneId,
                                };
                            } else {
                                address = {
                                    address: '',
                                    zone: '',
                                    location: '',
                                    province: '',
                                    geographicZoneId: '',
                                };
                            }
                        }
                        if (address) {
                            this.setState({
                                address: address.address,
                                zone: address.zone,
                                location: address.location,
                                province: address.province,
                                geographicZoneId: address.geographicZoneId
                            }, () => {    
                                this.props.onFilledCustomer({
                                    customer: this.state.customer,
                                    customerId: this.state.customerId,
                                    address: this.state.address,
                                    zone: this.state.zone,
                                    geographicZoneId: this.state.geographicZoneId,
                                    location: this.state.location,
                                    province: this.state.province,
                                })
                            })
                        }
                    }
                )
            } else {
                this.setState({
                    addresses: [],
                    address: '',
                    zone: '',
                    location: '',
                    province: '',
                    geographicZoneId: '',
                }, () => {    
                    this.props.onFilledCustomer({
                        customer: this.state.customer,
                        customerId: this.state.customerId,
                        address: this.state.address,
                        zone: this.state.zone,
                        geographicZoneId: this.state.geographicZoneId,
                        location: this.state.location,
                        province: this.state.province,
                    })
                })
            }
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });

        if (state === 'customer') {
            this.getAddresses(value);
            const customerIndex = this.state.customers.findIndex(item => item.id === value);
            if (customerIndex !== -1) {
                this.setState({
                        customerId: this.state.customers[customerIndex].customerId,
                    }, () => {
                        this.props.onFilledCustomer({
                            customer: this.state.customer,
                            customerId: this.state.customerId,
                        })
                    }
                )
            }
        }
        if (state === 'address') {
            const addressIndex = this.state.addresses.findIndex(item => item.id === value)
            if (addressIndex !== -1) {
                this.setState({
                    address: this.state.addresses[addressIndex].id,
                    geographicZoneId: this.state.addresses[addressIndex].geographicZoneId,
                    zone: this.state.addresses[addressIndex].zone || '',
                    location: this.state.addresses[addressIndex].location || '',
                    province: this.state.addresses[addressIndex].province || '',
                }, () => {

                    this.props.onFilledCustomer({
                        customer: this.state.customer,
                        customerId: this.state.customer,
                        address: this.state.address,
                        geographicZoneId: this.state.geographicZoneId,
                        zone: this.state.zone,
                        location: this.state.location,
                        province: this.state.province,
                    })
                })
            }
        }
    }

    render() {
        const { customers, customer, customerId, addresses, address, zone, location, province } = this.state;
        const { disabled, checkInvalid, t } = this.props;
        return (
            <Card className="card-customer-form">
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <HotelIcon />
                    </CardIcon>
                    <h3 className="card-icon-title">{t('appointment.customer')}</h3>
                </CardHeader>
                <CardBody>
                    <GridContainer className="card-customer-container">
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <SelectInput
                                disabled={disabled}
                                label={t('appointment.new.customer.customer')}
                                elements={customers}
                                onSelectedValue={value => this.handleValue(value, 'customer')}
                                value={customer}
                                invalid={!customer && checkInvalid}
                                errorText={
                                    !customer && checkInvalid
                                        ? t('error.required')
                                        : ''
                                }
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.id')}
                                value={(customer && customerId) || ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <SelectInput
                                fullWidth
                                disabled={disabled}
                                label={t('appointment.new.customer.address')}
                                elements={addresses}
                                onSelectedValue={value => this.handleValue(value, 'address')}
                                value={customer && address}
                                invalid={!address && checkInvalid}
                                errorText={
                                    !address && checkInvalid
                                        ? t('error.required')
                                        : ''
                                }
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.zone')}
                                value={((customer && address) && zone) || ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.location')}
                                value={((customer && address) && location) || ''}
                            />
                        </GridItem>
                        <GridItem className="fullWidth-input" xs={12} sm={6}>
                            <TextField
                                disabled
                                fullWidth
                                label={t('appointment.new.customer.province')}
                                value={((customer && address) && province) || ''}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        )
    }
}

CustomerForm.propTypes = {
    onGetCustomers: PropTypes.func,
    onFilledCustomer: PropTypes.func,
    value: PropTypes.object,
    disabled: PropTypes.bool,
    checkInvalid: PropTypes.bool,
    t: PropTypes.func,
}

export default withTranslation()(CustomerForm);
