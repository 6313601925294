export const SCHEDULES = 'SCHEDULES'
export const SCHEDULES_STATUS = 'SCHEDULE_STATUS'
export const SCHEDULES_TYPES = 'SCHEDULES_TYPES'
export const SAVE_NEW_SCHEDULE = 'SAVE_NEW_SCHEDULE'

export function getSchedules(schedules) {
    return {type: SCHEDULES, schedules}
}

export function getStatus(status) {
    return {type: SCHEDULES_STATUS, status}
}

export function getScheduleTypes(types) {
    return {type: SCHEDULES_TYPES, types}
} 

export function saveNewSchedule(schedule) {
    return {type: SAVE_NEW_SCHEDULE, schedule}
}