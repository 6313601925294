import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Itinerary (props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path fill="none" stroke="#FFFFFF" d="M5 17v-2M9 17v-2M13 17v-2" />
      <path d="M19.5 12c-1.93 0-3.5 1.57-3.5 3.5 0 1.701 2.209 5.321 2.652 6.03l.848 1.357.848-1.357C20.791 20.821 23 17.201 23 15.5 23 13.57 21.43 12 19.5 12zM4.5 2C2.57 2 1 3.57 1 5.5c0 1.701 2.209 5.321 2.652 6.03L4.5 12.887l.848-1.357C5.791 10.821 8 7.201 8 5.5 8 3.57 6.43 2 4.5 2z" />
    </SvgIcon>
  );
}
