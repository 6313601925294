export const PATOLOGIES = 'PATOLOGIES'
export const COMPLEXITIES = 'COMPLEXITIES'
export const BARTHELS = 'BARTHELS'
export const BARTHEL = 'BARTHEL'
export const CLEAN_BARTHEL = 'CLEAN_BARTHEL'

export const COMPLEXITY = 'COMPLEXITY'
export const CLEAN_COMPLEXITY = 'CLEAN_COMPLEXITY'

export const PATOLOGY_DETAILS = 'PATOLOGY_DETAILS'
export const HOSPITAL_PATOLOGY_DETAILS = 'HOSPITAL_PATOLOGY_DETAILS'

export function fetchPatologies (patologies) {
  return { type: PATOLOGIES, patologies }
}

export function fetchComplexities (complexities) {
  return { type: COMPLEXITIES, complexities }
}

export function fetchBarthels (barthels) {
  return { type: BARTHELS, barthels }
}

export function setComplexity (complexity) {
  return { type: COMPLEXITY, complexity }
}

export function setBarthel (barthel) {
  return { type: BARTHEL, barthel }
}

export function fetchPatologyDetails (patology) {
  return { type: PATOLOGY_DETAILS, patology }
}

export function unmountBarthels () {
  return { type: CLEAN_BARTHEL }
}

export function unmountComplexty () {
  return { type: CLEAN_COMPLEXITY }
}



export function fetchHospitalPatologyDetails (patology) {
  return { type: HOSPITAL_PATOLOGY_DETAILS, patology }
}
