import React from 'react'
import './StatusIcon.css'

const setColor = value => {
  switch (value) {
    case 'OK':
      return 'green'
    case 'WARNING':
      return 'orange'
    case 'ERROR':
      return 'red'
    default:
      return 'green'
  }
}

const StatusIcon = ({ value, quote }) => {
  return (
    <div className={'status-container'}>
      <div className={`status-icon ${setColor(value)}`}>
        <span className={'child-1'}></span>
        <span className={'child-2'}></span>
      </div>
      <span className={'status-text'}>{quote || ''}</span>
    </div>
  )
}

export default StatusIcon;
