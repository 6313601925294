import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Assignment from '@material-ui/icons/Assignment';
import { TextField } from '@material-ui/core';
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import PatologiesApiInvoker from '../../../api/RecordsApiInvoker';

class DiagnosticForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patologyDetails: {},
      hospitalPatologyDetails: {}
    }
  }

  componentDidMount() {
    this.getPatologies();
    this.getComplexities();
    this.getBarthels();
  }

  getPatologies() {
    PatologiesApiInvoker.getAllPatologies(data => {
      this.props.onGetPatologies(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  getComplexities() {
    PatologiesApiInvoker.getAllComplexities(data => {
      this.props.onGetComplexities(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  getBarthels() {
    PatologiesApiInvoker.getAllBarthel(data => {
      this.props.onGetBarthels(data);
    }, e => {
      this.setState({ loading: false });
    })
  }

  componentWillReceiveProps(next) {
    if(next.patology && next.patology.id) {
      this.selectPatology(next.patology);
    }
    if(next.hospitalPatology && next.hospitalPatology.id) {
      this.selectHospitalPatology(next.hospitalPatology);
    }
  }

  selectPatology(value) {
    if(!value || !value.id) {
      return;
    }
    PatologiesApiInvoker.getPatologyDetails(value.id, data => {
      this.setState({ patologyDetails: data });
    }, e => {
      this.setState({ loading: false });
    })
  }

  selectHospitalPatology(value) {
    if(!value || !value.id) {
      return;
    }
    PatologiesApiInvoker.getPatologyDetails(value.id, data => {
      this.setState({ hospitalPatologyDetails: data });
    }, e => {
      this.setState({ loading: false });
    })
  }


  render() {
    const { t, barthel, complexity, hospitalPatology, observations, patology } = this.props;
    const { hospitalPatologyDetails, patologyDetails, } = this.state;
    return (
      <GridContainer className="diagnostic-form read-only">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <h4 className="margin-top">
                    {t('appointment.new.diagnostic.main-diagnostic')}
                  </h4>
                </GridItem>
                <GridItem sm={4}>
                  <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!(patology && patology.description)}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.patology')}
                    value={(patology && patology.description)}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!((patologyDetails && patologyDetails.parent && patologyDetails.parent.name))}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.category')}
                    value={((patologyDetails && patologyDetails.parent && patologyDetails.parent.name))}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!((patologyDetails && patologyDetails.parent && patologyDetails.parent.parent && patologyDetails.parent.parent.name))}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.subcategory')}
                    value={((patologyDetails && patologyDetails.parent && patologyDetails.parent.parent && patologyDetails.parent.parent.name))}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <h4 className="margin-top">{t('appointment.new.diagnostic.internation-diagnostic')}</h4>
                </GridItem>
                <GridItem sm={4}>
                <TextField
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: !!(hospitalPatology && hospitalPatology.description)}}
                  className="diagnostic-form-text-field"
                  label={t('appointment.new.diagnostic.patology')}
                  value={(hospitalPatology && hospitalPatology.description)}
                />
                </GridItem>
                <GridItem sm={4}>
                <TextField
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: !!(hospitalPatologyDetails && hospitalPatologyDetails.parent && hospitalPatologyDetails.parent.name)}}
                  className="diagnostic-form-text-field"
                  label={t('appointment.new.diagnostic.category')}
                  value={(hospitalPatologyDetails && hospitalPatologyDetails.parent && hospitalPatologyDetails.parent.name)}
                />
                </GridItem>
                <GridItem sm={4}>
                <TextField
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: !!(hospitalPatologyDetails && hospitalPatologyDetails.parent && hospitalPatologyDetails.parent.parent && hospitalPatologyDetails.parent.parent.name)}}
                  className="diagnostic-form-text-field"
                  label={t('appointment.new.diagnostic.subcategory')}
                  value={(hospitalPatologyDetails && hospitalPatologyDetails.parent && hospitalPatologyDetails.parent.parent && hospitalPatologyDetails.parent.parent.name)}
                />
                </GridItem>
              </GridContainer>
              <GridContainer className="diagnostic-form-grid-container">
                <GridItem xs={12}>
                  <h4 className="margin-top">{t('appointment.new.diagnostic.observations')} </h4>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <TextField
                    disabled
                    fullWidth
                    className="diagnostic-form-text-field"
                    InputLabelProps={{ shrink: !!(complexity && complexity.description) }}
                    label={t('appointment.new.diagnostic.complexity')}
                    value={complexity && complexity.description && t(t(`appointment.new.diagnostic.complexities.${complexity.description}`))}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: !!(barthel && barthel.description)}}
                    className="diagnostic-form-text-field"
                    label={t('appointment.new.diagnostic.barthelIndex')}
                    value={(barthel && barthel.description)}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('appointment.new.diagnostic.observations')}
                    value={observations}
                    disabled
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

DiagnosticForm.defaultProps = {
  onGetPatologies: () => {},
  onGetComplexities: () => {},
  onGetBarthels: () => {},
  complexities: [],
  patologies: [],
  barthels: [],
  complexity: {},
  barthel: {},
  patology: {},
  hospitalPatologyDetails: {},
  observations: '',
  disabled: false
}

DiagnosticForm.propTypes = {
  onGetPatologies: PropTypes.func,
  onGetComplexities: PropTypes.func,
  onGetBarthels: PropTypes.func,
  complexities: PropTypes.array,
  patologies: PropTypes.array,
  patology: PropTypes.object,
  observations: PropTypes.string,
  hospitalPatologyDetails: PropTypes.object,
  value: PropTypes.string,
  checkInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withTranslation()(DiagnosticForm);
