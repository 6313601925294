import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Icon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonSpinner from '../../ButtonSpinner';
import Button from '../../CustomButtons/Button';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import StatusIcon from '../StatusIcon';
import TimePickerGCalendar from '../../TimePickerGCalendar';
import ValidationInput from '../../ValidationInput';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import CustomDialog from '../../CustomDialog/index';
import CustomInput from '../../CustomInput/CustomInput';
import CustomSweetAlert from '../../CustomSweetAlert';
import ScheduleSystemFinished from '../ScheduleSystemFinished';
import ScheduleApi from '../../../api/ScheduleApi';

class BookingDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: null,
      professionals: [],
      errors: [],
      disabled: false,
      status: '',
      hasChanged: false,
      validation: false,
      validationResult: null,
      validating: false,
      busy: false,
      alertColor: 'info',
      alertMessage: '',
      alertOpen: false,
      showOpenCancel: false,
      openSystemFinished: false,
      cancel_motive: ""
    }
  }

  getDetail() {
    const { event } = this.props;
    if (event) {
      if (event.type === 'schedule') {
        AppointmentApiInvoker.getScheduleInCalendar(event.id, details => {
          this.setState(
            { current: details },
            () => this.getEmployees()
          )
        }, null);
      }
      else {
        AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
          this.setState(
            { current: details },
            () => this.getEmployees()
          )
        }, null);
      }
    }
  }

  componentDidMount() {
    this.getDetail();
  }

  componentWillReceiveProps(next) {
    if (next.errors) {
      let error = '';
      next.errors.map(e => {
        return error = error + '\n' + e.detail
      });
      const message = error.message ?? error;
      this.openAlert('danger', message);
      this.setState({
        disabled: false,
        status: next.status,
        validation: false,
        busy: false,
      });
    }
  }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    });
    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 5000);
  }

  getBookingDetail() {
    const { event } = this.props;
    if (event) {
      AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
        details.employeeId = event.employeeId
        this.setState({ current: details });
      }, null);
    }
  }

  getEmployees() {
    const { current } = this.state;
    let lender = 0;
    if (current.companyIdPartnership) {
      lender = current.companyIdPartnership.companyId;
    }

    if (current) {
      PersonApiInvoker.getEmployeesByPracticetype(lender, current.practiceTypeId, data => {
        this.setState({ professionals: this.formatEmployees(data) });
      }, null);
    }
  }

  formatEmployees(data) {
    let result = [];
    data.forEach(e => {
      result.push({
        id: e.personId,
        value: `${e.firstName} ${e.lastName}`,
      })
    })
    return result;
  }

  cancel = () => {
    this.setState({
      showOpenCancel: true
    })
  }

  updateBooking(checking) {
    const { event } = this.props;
    if (event) {
      const fetchUrl = event.type === 'schedule'
        ? 'schedules'
        : 'bookings';

      const body = {
        employeeId: this.state.current.employeeId,
        scheduleDateTime: `${moment(this.state.current.scheduleDate).format(
          'YYYY-MM-DD'
        )}T${this.state.current.startTime}`,
      };

      if (checking) {
        this.setState({
          'validationResult': null,
          'validation': false,
          'validating': true,
          busy: false,
        });

        SchedulerInvoker.postDynamicContentOnlyCheck(fetchUrl, event.id, body, data => {
          this.setState({
            'validationResult': data,
            'validation': data.status !== 'ERROR',
            'validating': false,
            busy: false,
          });
        }, (error) => {
          this.setState({
            'validating': false,
            'errors': [{
              statusError: 'ERROR',
              detail: error.message
            }]
          });
        });
      } else {
        this.setState({ busy: true });
        SchedulerInvoker.postDynamicContent(fetchUrl, event.id, body, data => {
          if (this.props.updateAppointment) {
            this.props.updateAppointment(data);
          }
          if (this.props.onUpdate) {
            this.props.onUpdate(data);
          }
        }, (error) => this.setState({ busy: false }));
      }
    }
  }

  systemFinished = () => {
    this.setState({
      openSystemFinished: true
    })
  }

  handleChange = (value, state) => {
    let hasChanged;
    if (state === 'startTime') {
      hasChanged = (this.props.event.start && moment(this.props.event.start.toString().split('T')[1], 'HH:mm').format('HH:mm') !== value) ||
        moment(value, 'HH:mm').format('HH:mm') !== moment(this.state.current.startTime, 'HH:mm').format('HH:mm');
    } else {
      hasChanged = this.state.current[state] !== value;
    }

    let endTime = this.state.current.endTime;
    if (state === 'startTime') {
      endTime = moment()
        .set('hours', value.split(':')[0])
        .set('minutes', value.split(':')[1])
        .add(this.state.current.estimatedDuration, 'minutes')
        .format('HH:mm');
    }

    this.setState(prevState => ({
      current: {
        ...prevState.current,
        endTime,
        [state]: value
      },
      hasChanged,
      validation: !hasChanged,
      disabled: false,
      errors: []
    }))
  }
  ///mlgmlg

  cancelScheduleConfirm = () => {
    this.setState({
      showConfirmCancel: true,
      showOpenCancel: false
    })
  }


  cancelSchedule = () => {

    this.setState({ busy: true });
    const { event } = this.props;

    var body = {
      "scheduleIds": [event.id],
      "reasonCancel": this.state.cancel_motive
    }
    AppointmentApiInvoker.cancelSchedule(this.state.current.appointmentId, body, data => {
      this.getDetail();
      this.setState({
        showConfirm: false, showConfirmCancel: false
      })
    }, (error) => {
      const message = error.message ?? error
      this.openAlert('danger', message);
    });
  }

  onChangeMotive = (value) => {
    if (value.length <= 255) {
      this.setState({ cancel_motive: value });
    }
  }

  cancelBySystem = (value) => {
    this.setState({openSystemFinished: false});
    const { event } = this.props;
    var startDateTime = moment(value['startDate']).utcOffset(0, true).format()
    var endDateTime = moment(value['endDate']).utcOffset(0, true).format()

    var presure = undefined;
    if (value['arterialTensionMax'] || value['arterialTensionMin']) {
      presure = {"max":value['arterialTensionMax'],"min":value['arterialTensionMin']};
    }
    var row = {
      "timeRegistry": moment().format("DD/MM/YYYY HH:mm:ss"),
      "temperature":value['temperature'],
      "pulse":value['pulse'],
      "breathing":value['breathing'],
      "pressure": presure
    }
    var rowData = [];

    if (
        !row["temperature"] &&
        !row["pulse"] &&
        !row["breathing"] &&
        !row["pressure"]
      ) {
        rowData = [];

      } else {
        rowData = [
          row
        ]
      }  

    var metadata = {
      "vitalSignsList": rowData,
        "note": (value['note'] ? value['note'] : ""),
        "start_geo_position":null,
        "end_geo_position":null

    }
    var body = {
      "startDateTime": startDateTime,
      "endDateTime": endDateTime,
      "metadata": metadata
    }
    ScheduleApi.finishBySystem(event.id, body, data => {
      this.getDetail();
      this.setState({
        showConfirm: false, showConfirmCancel: false
      })
    }, (error) => {
      const message = error.message ?? error;
      this.openAlert('danger', message);
    });

  }

  render() {
    var user = localStorage.getItem('username')
    var { t, disabled, event } = this.props; // readOnly
    const { busy, current, errors, hasChanged, professionals, status, validating, validation, validationResult } = this.state;
    const disabledState = this.state.disabled;
    if (this.state.current && this.state.current.scheduleStatusName == 'CANCELLED') {
      disabled = true;
    }
    var validateDateTime = true;
    if (current != null) {
      var now = moment();
      var startDate = moment(current.scheduleDateTime);
      if (startDate.isAfter(now)) {
        validateDateTime = false;
      }

      if (validateDateTime && (this.state.current.scheduleStatusName == 'WITHOUT_ATTENTION' || this.state.current.scheduleStatusName == 'NOT_ATTENDED')) {
        if (moment().isAfter(current.limitFinishedDateTime)) {
          validateDateTime = false;
        }
      }
    }
    //
    var company = localStorage.getItem("company_partnership_id");
    if (company == null) {
      company = localStorage.getItem("itlg_default_company_id");
    }
    let edit = true;
    if (this.state.current && 
        this.state.current.companyIdPartnership && 
        this.state.current.companyIdPartnership.companyId != company) {
        edit = false;
        disabled = true;
    }

    return (
      <div className="booking-detail-form">
        {current && (
          <GridContainer>
            <GridItem xs={12} sm={event && event.id ? 6 : 12}>
              <ValidationInput
                text={t('common.patient')}
                value={`${current.customerFirstName} ${current.customerLastName}`}
                disabled
              />
            </GridItem>
            {event && event.id &&
              <GridItem xs={12} sm={6}>
                <ValidationInput
                  text={t('common.scheduleNumber')}
                  value={event.id}
                  disabled
                />
              </GridItem>
            }
            <GridItem xs={12}>
              <SelectInput
                label={`${t('common.professional')} *`}
                elements={professionals}
                value={current.employeeId}
                disabled={disabled}
                onSelectedValue={value => this.handleChange(value, 'employeeId')}
                invalid={!current.employeeId}
              />
            </GridItem>
            <GridItem xs={12}>
              <ValidationInput
                text={t('common.speciality')}
                value={current.practiceTypeName}
                disabled
              />
            </GridItem>
            <GridItem xs={12}>
              {(current.practiceTypeName) ?
                <ValidationInput
                  text={t('estimated_label')}
                  value={`${moment.utc(moment.duration(parseInt(current.estimatedDuration), 'minutes').asMilliseconds()).format('HH:mm')}${t('estimated_minutes')}`}
                  disabled
                /> : ''
              }
            </GridItem>
            <GridItem xs={12}>
              <ValidationInput
                text={t('schedule.booking.detail.statusname')}
                value={
                  (current.scheduleStatus)
                    ? t(`schedule.booking.state.${current.scheduleStatus}`)
                    : t(`schedule.booking.state.${current.scheduleBookingStatus}`)}
                disabled
              />
            </GridItem>
            <GridItem xs={12}>
              {(current.scheduleStatus === 'REJECTED') ?
                <ValidationInput
                  text={t('schedule.booking.detail.reasonRejected')}
                  value={current.reasonRejected}
                  disabled
                /> : ''
              }
            </GridItem>
            <GridItem xs={12}>
              <DateInput
                text={t('common.date')}
                disabled={disabled}
                value={current.scheduleDate}
                onChangeValue={value => this.handleChange(value, 'scheduleDate')}
              />
            </GridItem>
            <GridItem xs={6}>
              <TimePickerGCalendar
                text={`${t('common.startTime')} *`}
                value={current.startTime}
                onSelectedValue={value => this.handleChange(value, 'startTime')}
                disabled={disabled}
                name="booking_detail_start_time"
                invalid={!current.startTime}
                errorText={!current.startTime ? t('error.required') : ''}
              />

            </GridItem>
            <GridItem xs={6}>
              <TimePickerGCalendar
                name="booking_detail_end_time"
                text={t('common.endTime')}
                value={current.endTime}
                onSelectedValue={current.endTime}
                invalid={!current.endTime}
                disabled
                errorText={!current.endTime ? t("error.required") : ''}
              />
            </GridItem>
            <GridItem xs={12}>
              <ValidationInput
                text={t('address.title')}
                value={`${current.addressStreet} ${current.addressNumber}`}
                disabled
              />
            </GridItem>

            {errors && errors.length > 0 &&
              errors.map(e => {
                return (
                  <GridItem xs={12} className={e.statusError === 'ERROR' ? 'error' : 'warning'}>
                    <Icon color={e.statusError === 'ERROR' ? 'error' : 'warning'}>
                      {
                        e.statusError === 'ERROR' ? 'error' : 'warning'
                      }
                    </Icon>{' '}
                    <span>{e.detail}</span>
                  </GridItem>
                )
              })
            }
            <GridItem xs={12}>
              <div>
                {validating ?
                  <div style={{
                    display: 'flex',
                    height: '200px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <CircularProgress />
                  </div> : ''}
              </div>
              {validationResult && (
                <StatusIcon
                  value={
                    validationResult.status
                  }
                />
              )}
            </GridItem>
            {this.state.current && this.state.current.scheduleStatusName == 'CANCELLED' &&
              <GridItem xs={12}>
                <div>
                  <ValidationInput
                    text={t('common.reasonCancel')}
                    value={this.state.current.reasonCancel}
                    disabled
                  />
                </div>
                <div>
                  <ValidationInput
                    value={t('common.cancelEmployeeName') + ": " + this.state.current.cancelEmployeeName}
                    disabled
                  />
                </div>
              </GridItem>
            }

            {validationResult &&
              validationResult.status !== 'OK' ?
              validationResult.errors.map(e => {
                return (<GridItem xs={12}>
                  <GridContainer><GridItem xs={12} sm={7} className={e.statusError === 'ERROR' ? 'error' : 'warning'}>
                    {' '}
                    <span>{t(e.detail)}</span>
                  </GridItem></GridContainer>
                </GridItem>)
              })
              : ''
            }

        <GridItem xs={3} className="finished-button">
            {this.props.action == 'finished_system' &&
                this.state.current &&
                validateDateTime &&
                (this.state.current.scheduleStatusName == 'PENDING_APPROVAL' ||
                  this.state.current.scheduleStatusName == 'APPROVED' ||
                  this.state.current.scheduleStatusName == 'IN_PROGRESS' ||
                  this.state.current.scheduleStatusName == 'NOT_ATTENDED' ||
                  this.state.current.scheduleStatusName == 'WITHOUT_ATTENTION') &&
                <Button authority="end_schedule_by_system" color="info" onClick={() => this.systemFinished()}>
                  {event.type === 'booking' || !event.action ? t('schedule.detail.system.finished') : t('common.close')}
                </Button>
              }
          </GridItem>
          {edit &&
          <GridItem xs={8} className="booking-detail-actions">
              {!this.props.disabled &&
                this.state.current &&
                (this.state.current.scheduleStatusName == 'PENDING_APPROVAL' ||
                  this.state.current.scheduleStatusName == 'APPROVED' ||
                  this.state.current.scheduleStatusName == 'REJECTED' ||
                  this.state.current.scheduleStatusName == 'NOT_ATTENDED') &&
                <Button authority="cancel_schedule" color="danger" onClick={() => this.cancel()}>
                  {event.type === 'booking' || !event.action ? t('common.schedule.cancel') : t('common.close')}
                </Button>
              }
                {this.state.current && this.state.current.scheduleStatusName != 'CANCELLED' && (
                  <>
                    <Button
                      color="info"
                      onClick={() => this.updateBooking(true)}
                      disabled={!hasChanged || validating}
                    >
                      {t('common.check')}
                    </Button>

                    <ButtonSpinner
                      onClick={() => this.updateBooking()}
                      disabled={
                        busy || !hasChanged ||
                        ((disabledState && status === 'ERROR') || !validation)
                      }
                      label={t('common.accept')}
                      labelLoading={t('common.saving')}
                      loading={busy}
                      typeButton="submit"
                      color="success"
                    />
                  </>
                )}
                
              </GridItem>
          }
          </GridContainer>
        )}
        <CustomDialog
          title={t('schedule.cancel.title')}
          maxWidth="sm"
          open={this.state.showOpenCancel}
          onClose={() => this.setState({ showOpenCancel: false })}
        >
          <div>
            <div>
              <ValidationInput
                text={t('schedule.cancel.motive')}
                onChangeValue={(value) => this.onChangeMotive(value)}
                value={this.state.cancel_motive}
              />
            </div>
            <p className="style-user">
              Usuario: {user}
            </p>

          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.cancelScheduleConfirm()}
              disabled={this.state.cancel_motive == ''}

              label={t('schedule.cancel.acept')}
              typeButton="submit"
              color="success"
            />
          </div>
        </CustomDialog>
        {this.state.showConfirmCancel &&
          <CustomSweetAlert
            type="warning"
            title={t('schedule.cancel.title')}
            onConfirm={() => this.cancelSchedule()}
            onCancel={() => this.setState({ showOpenCancel: true, showConfirmCancel: false })}
            showCancel={true}
            showConfirm={true}
            confirmBtnCssClass="success"
            cancelBtnCssClass="danger"
            cancelBtnText={t('common.cancel')}
            confirmBtnText={t('common.confirm')}
            message={
              <div className="contentMessage" dangerouslySetInnerHTML={
                { __html: t('schedule.cancel.confirm.body', { practiceType: this.state.current.practiceTypeName }) }} />
            }
          />
        }
        <CustomDialog
          title={t('appointment.scheduleDetail')}
          maxWidth="md"
          open={this.state.openSystemFinished}
          onClose={() => this.setState({ openSystemFinished: false })}
        >
          <ScheduleSystemFinished
            current={this.state.current}
            scheduleId={this.props.event.id}
            cancelBySystem={this.cancelBySystem}
            onCancel={() => this.setState({ openSystemFinished: false })}
          />
        </CustomDialog>
      </div>

    )
  }
}

BookingDetail.propTypes = {
  event: PropTypes.object,
  onCancel: PropTypes.func,
  updateAppointment: PropTypes.func,
  errors: PropTypes.array,
  status: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withTranslation()(BookingDetail);
