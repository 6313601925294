import {
  ItineraryOutlined,
  MedicalDoctorOutlined,
  SyringeOutlined,
} from '../src/icons'

import Alerts from './components/Alerts'
import AppointmentDetail from './components/Appointments/AppointmentDetail'
import AppointmentTools from './components/AppointmentTools'
import Appointments from './components/Appointments'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import Bookings from './components/Bookings'
import Customers from './components/Customers'
import Dashboard from './components/Dashboard'
import DashboardCustomer from './components/DashboardCustomer'
import DashboardEconomicProfessional from './components/DashboardEconomicProfessional/DashboardEconomicProfessional'
import DashboardEconomicSupply from './components/DashboardEconomicSupply/DashboardEconomicSupply'
import DashboardEconomicTool from './components/DashboardEconomicTool/DashboardEconomicTool'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import DashboardProfesional from './components/DashboardProfesional'
import DiabeticProgram from './components/DiabeticProgram/DiabeticProgram'
import DiabeticProgramForm from './components/DiabeticProgram/DiabeticProgramForm'
import DiabeticProgramFormView from './components/DiabeticProgram/DiabeticProgramFormView'
import EditCustomer from './components/EditCustomer/edit-customer'
import EditEmployee from './components/EditEmployee/EditEmployee'
import EditPracticeTypes from './components/EditPracticeTypes'
import EditSupplyCatalog from './components/EditSupplyCatalog'
import EditTool from './components/EditTool'
import Employees from './components/Employees'
import HospitalizationForm from './components/HospitalizationForm/HospitalizationForm'
import HospitalizationFormView from './components/HospitalizationForm/HospitalizationFormView'
import HospitalizationList from './components/HospitalizationForm/HospitalizationList'
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined'
import IntegralTechnicalFile from './components/IntegralTechnicalFile/IntegralTechnicalFile'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import MedicalRecordList from './components/MedicalRecord/MedicalRecordList'
import { Module } from 'module'
import NewCustomer from './components/NewCustomer/new-customer'
import NewEmployee from './components/NewEmployee/new-employee'
import NewSchedule from './components/NewSchedule'
import NewSupplyCatalog from './components/NewSupplyCatalog'
import NewTool from './components/NewTool'
import NewUser from './components/NewUser'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import PracticeTypes from './components/PracticeTypes'
import ReassignAppointmentsStepper from './components/ReassignAppointmentsStepper'
import Routes from './components/Routes'
import ScheduleDetail from './components/MedicalRecord/ScheduleDetail'
import Schedules from './components/Schedules'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import SupplyCatalog from './components/SupplyCatalog'
import TechnicalFile from './components/TechnicalFile/TechnicalFile'
import ToolTypes from './components/ToolTypes'
import Tools from './components/Tools'
import Users from './components/Users'
import Wellcome from './components/Wellcome'

// icons

const routes = [
  // {
  //   collapse: true,
  //   name: 'routes.appointments',
  //   rtlName: '',
  //   mini: '',
  //   icon: AssignmentOutlinedIcon,
  //   state: 'appointmentsCollapse',
  //   views: [
  //     // {
  //     //   path: '/solicitudes',
  //     //   name: 'routes.appointments',
  //     //   mini: 'routes.appointments.mini',
  //     //   rtlName: '',
  //     //   inset: 10,
  //     //   component: Appointments,
  //     //   layout: '',
  //     //   enabled: true,
  //     //   authority:'menu_appointment'
  //     // },
  //     // {
  //     //   path: '/reasign-appointments',
  //     //   name: 'routes.reassignment',
  //     //   mini: 'routes.reassignment.mini',
  //     //   inset: 10,
  //     //   rtlName: '',
  //     //   component: ReassignAppointmentsStepper,
  //     //   layout: '',
  //     //   enabled: true,
  //     //   authority:'menu_reassignment'
  //     // },
  //     {
  //       path: '/solicitudes/:id',
  //       name: 'routes.appointments',
  //       mini: 'routes.appointments.mini',
  //       inset: 20,
  //       rtlName: '',
  //       component: AppointmentDetail,
  //       layout: '',
  //       enabled: true,
  //       hidden: true
  //     },
  //   ],
  // },
  // {
  //   path: '/appointments/:id/edit/agenda',
  //   name: 'routes.appointments',
  //   mini: 'routes.appointments.mini',
  //   rtlName: '',
  //   component: AppointmentDetail,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/appointments/:id/renew/agenda',
  //   name: 'routes.renewAppointment',
  //   mini: 'routes.renewAppointment.mini',
  //   rtlName: '',
  //   component: AppointmentDetail,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/alertas',
  //   name: 'routes.alerts',
  //   mini: 'routes.alerts.mini',
  //   rtlName: '',
  //   component: Alerts,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/medical-record',
  //   name: 'routes.clinicalHistoryRequest',
  //   mini: 'routes.clinicalHistoryRequest.mini',
  //   rtlName: '',
  //   component: MedicalRecordList,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/schedule',
  //   name: 'routes.medicalVisit',
  //   mini: 'routes.medicalVisit.mini',
  //   rtlName: '',
  //   component: ScheduleDetail,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/historial',
  //   name: 'routes.record',
  //   mini: 'routes.record.mini',
  //   rtlName: '',
  //   component: Schedules,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/nueva-solicitud',
  //   name: 'routes.newRequest',
  //   mini: 'routes.newRequest.mini',
  //   rtlName: '',
  //   component: NewSchedule,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  {
    path: '/patient',
    name: 'routes.patients',
    mini: 'routes.patients.mini',
    icon: HotelOutlinedIcon,
    rtlName: '',
    component: Customers,
    layout: '',
    enabled: true,
    authority: 'menu_customers',
    views: [
      {
        path: '/patient/:id/medical-record/full/',
        name: 'routes.integralMedicalHistory',
        titlewindow: 'routes.integralMedicalHistory',
        mini: 'routes.integralMedicalHistory.mini',
        rtlName: '',
        component: IntegralTechnicalFile,
        layout: '',
        hidden: true,
        enabled: true,
      },
    ],
  },
  {
    path: '/nuevo-paciente',
    name: 'routes.newPatient',
    mini: 'routes.newPatient.mini',
    rtlName: '',
    component: NewCustomer,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/editar-paciente/:id',
    name: 'routes.editPatient',
    mini: 'routes.editPatient.mini',
    rtlName: '',
    component: EditCustomer,
    layout: '',
    enabled: true,
    hidden: true,
    authority: 'customers_edit',
  },
  {
    path: '/ver-paciente/:id',
    name: 'routes.viewPatient',
    mini: 'routes.viewPatient.mini',
    inset: 10,
    rtlName: '',
    component: EditCustomer,
    layout: '',
    enabled: true,
    hidden: true,
    authority: 'customers_view,customers_view_related',
  },
  {
    path: '/admission',
    name: 'routes.admission',
    mini: 'routes.admission.mini',
    rtlName: '',
    inset: 10,
    icon: LocalHospitalIcon,
    component: HospitalizationList,
    layout: '',
    enabled: true,
    authority: 'admission_module',
  },
  {
    path: '/admission/new-form',
    inset: 10,
    rtlName: '',
    component: HospitalizationForm,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/admission/edit-form/:id',
    inset: 10,
    rtlName: '',
    component: HospitalizationForm,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/admission/view/:id',
    inset: 10,
    rtlName: '',
    component: HospitalizationFormView,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/diabetic-program',
    name: 'routes.diabetic',
    mini: 'routes.diabetic.mini',
    inset: 10,
    rtlName: '',
    icon: HotelOutlinedIcon,
    component: DiabeticProgram,
    layout: '',
    enabled: true,
    authority: 'diabetic_module',
  },
  {
    path: '/diabetic-program/form',
    inset: 10,
    rtlName: '',
    component: DiabeticProgramForm,
    layout: '',
    enabled: true,
    hidden: true,
  },

  {
    path: '/diabetic-program/edit/:id',
    inset: 10,
    rtlName: '',
    component: DiabeticProgramForm,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/diabetic-program/view/:id',
    inset: 10,
    rtlName: '',
    component: DiabeticProgramFormView,
    layout: '',
    enabled: true,
    hidden: true,
  },
  // {
  //   path: '/turnos',
  //   name: 'routes.shifts',
  //   mini: 'routes.shifts.mini',
  //   rtlName: '',
  //   component: Bookings,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/hojas-ruta',
  //   name: 'routes.itinerary',
  //   mini: 'routes.itinerary.mini',
  //   icon: ItineraryOutlined,
  //   rtlName: '',
  //   component: Routes,
  //   layout: '',
  //   enabled: true,
  //   authority:'menu_roadmap'
  // },
  // {
  //   path: '/profesionales',
  //   name: 'routes.professionals',
  //   mini: 'routes.professionals.mini',
  //   icon: MedicalDoctorOutlined,
  //   rtlName: '',
  //   component: Employees,
  //   layout: '',
  //   enabled: true,
  //   authority:'menu_employees'
  // },
  // {
  //   path: '/detail',
  //   name: 'routes.technicalSheet' ,
  //   mini: 'routes.technicalSheet.mini',
  //   rtlName: '',
  //   component: TechnicalFile,
  //   layout: '',
  //   hidden: true,
  //   enabled: true
  // },
  // {
  //   path: '/nuevo-paciente',
  //   name: 'routes.newPatient',
  //   mini: 'routes.newPatient.mini',
  //   rtlName: '',
  //   component: NewCustomer,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/herramientas-turno',
  //   name: 'routes.addMedicalProducts',
  //   mini: 'routes.addMedicalProducts.mini',
  //   rtlName: '',
  //   component: AppointmentTools.Form,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/nuevo-profesional',
  //   name: 'routes.newProfessional',
  //   mini: 'routes.newProfessional.mini',
  //   rtlName: '',
  //   component: NewEmployee,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/editar-profesional/:id',
  //   name: 'routes.editProfessional',
  //   mini: 'routes.editProfessional.mini',
  //   rtlName: '',
  //   component: EditEmployee,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/ver-profesional',
  //   name: 'routes.viewProfessional',
  //   mini: 'routes.viewProfessional.mini',
  //   rtlName: '',
  //   component: EditEmployee,
  //   layout: '',
  //   enabled: true,
  //   hidden: true
  // },
  // {
  //   path: '/modulos',
  //   name: 'Package',
  //   mini: 'routes.package.mini',
  //   rtlName: '',
  //   component: Module,
  //   layout: '',
  //   enabled: true,
  //   authority:'menu_package'
  // },
  // {
  //   collapse: true,
  //   name: 'routes.suppliesGroup',
  //   rtlName: '',
  //   mini: 'routes.suppliesGroup.mini',
  //   icon: SyringeOutlined,
  //   state: 'medicalProductsCollapse',
  //   authority:'menu_supplies_and_tools',
  //   views: [
  //     {
  //       path: '/catalogo-insumos',
  //       name: 'routes.supplies' ,
  //       mini: 'routes.supplies.mini',
  //       rtlName: '',
  //       inset: 10,
  //       component: SupplyCatalog,
  //       layout: '',
  //       enabled: true,
  //       authority:'menu_supplies_and_tools_supply_catalog'
  //     },
  //     {
  //       path: '/tool-types',
  //       name: 'routes.productType' ,
  //       mini: 'routes.productType.mini',
  //       rtlName: '',
  //       component: ToolTypes,
  //       layout: '',
  //       inset: 10,
  //       enabled: true,
  //       authority:'menu_supplies_and_tools_tool_type'
  //     },
  //     {
  //       path: '/tools',
  //       name: 'routes.tools' ,
  //       mini: 'routes.tools.mini',
  //       rtlName: '',
  //       component: Tools,
  //       layout: '',
  //       inset: 10,
  //       enabled: true,
  //       authority:'menu_supplies_and_tools_tool'
  //     },
  //     {
  //       path: '/nuevo-insumo',
  //       name: 'routes.newSupply',
  //       mini: 'routes.newSupply.mini',
  //       rtlName: '',
  //       component: NewSupplyCatalog,
  //       layout: '',
  //       inset: 10,
  //       enabled: true,
  //       hidden: true
  //     },
  //     {
  //       path: '/editar-insumo/:id',
  //       name: 'routes.supplyEdit',
  //       mini: '',
  //       rtlName: '',
  //       component: EditSupplyCatalog,
  //       layout: '',
  //       inset: 10,
  //       enabled: true,
  //       hidden: true
  //     },
  //     {
  //       path: '/nuevo-producto',
  //       name: 'routes.newProduct',
  //       mini: 'routes.newProduct.mini',
  //       rtlName: '',
  //       component: NewTool,
  //       layout: '',
  //       inset: 10,
  //       enabled: true,
  //       hidden: true
  //     },
  //     {
  //       path: '/editar-producto/:id',
  //       name: 'routes.productEdit',
  //       mini: 'routes.productEdit.mini',
  //       inset: 10,
  //       rtlName: '',
  //       component: EditTool,
  //       layout: '',
  //       enabled: true,
  //       hidden: true
  //     },
  //     {
  //       path: '/ver-profesional',
  //       name: 'routes.viewProfessional',
  //       mini: 'routes.viewProfessional.mini',
  //       rtlName: '',
  //       component: EditEmployee,
  //       layout: '',
  //       enabled: true,
  //       hidden: true
  //     },
  //     {
  //       path: '/editar-profesional/:id',
  //       name: 'routes.editProfessional',
  //       mini: 'routes.editProfessional.mini',
  //       rtlName: '',
  //       component: EditEmployee,
  //       layout: '',
  //       enabled: true,
  //       hidden: true
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'routes.settings',
  //   rtlName: '',
  //   mini: 'CO',
  //   icon: SettingsOutlinedIcon,
  //   state: 'mantenimientoCollapse',
  //   authority:'menu_maintenance',
  //   views: [
  //     {
  //       path: '/practice-types',
  //       name: 'routes.practiceTypes',
  //       mini: 'routes.practiceTypes.mini',
  //       rtlName: '',
  //       inset: 10,
  //       component: PracticeTypes,
  //       layout: '',
  //       enabled: true,
  //       authority:'menu_maintenance_practices'
  //     },

  //     {
  //       state: 'mantenimientoCollapse',
  //       path: '/users',
  //       name: 'routes.users',
  //       mini: 'routes.users.mini',
  //       titlewindow: "routes.Users",
  //       inset: 10,
  //       rtlName: '',
  //       component: Users,
  //       layout: '',
  //       enabled: true
  //     },

  //     {
  //       path: 'practice-types/:id/edit',
  //       name: 'routes.editPracticeTypes',
  //       mini: 'routes.editPracticeTypes.mini',
  //       inset: '',
  //       rtlName: '',
  //       component: EditPracticeTypes,
  //       layout: '',
  //       enabled: true,
  //       hidden: true,
  //     },
  //     {
  //       path: '/module-settings',
  //       name: 'routes.moduleSettings',
  //       mini: 'routes.moduleSettings.mini',
  //       rtlName: '',
  //       inset: 10,
  //       component: Dashboard,
  //       layout: '',
  //       enabled: false,
  //       hidden: true,
  //     },
  //     {
  //       path: '/appointments-settings',
  //       name: 'routes.appointmentSettings',
  //       mini: 'routes.appointmentSettings.mini',
  //       rtlName: '',
  //       inset: 10,
  //       component: Dashboard,
  //       layout: '',
  //       enabled: false,
  //       hidden: true,
  //     },
  //     {
  //       path: '/config-personal',
  //       name: 'routes.personalSettings',
  //       mini: 'routes.personalSettings.mini',
  //       inset: 10,
  //       rtlName: '',
  //       component: Dashboard,
  //       layout: '',
  //       enabled: false,
  //       hidden: true,
  //     }
  //   ]
  // },

  // {
  //   path: '/new-user',
  //   name: 'routes.newUser',
  //   mini: 'routes.newUser.mini',
  //   rtlName: '',
  //   component: NewUser,
  //   layout: '',
  //   inset: 10,
  //   enabled: true,
  //   hidden: true
  // },
  /*
  {
    collapse: true,
    name: 'routes.statistics',
    rtlName: '',
    path: '',
    mini: 'routes.statistics.mini',
    icon: PollOutlinedIcon,
    state: 'statisticsCollapse',
    authority:'menu_reports',
    views: [
      {
        collapse: true,
        name: 'routes.operational',
        mini: 'routes.operational.mini',
        rtlName: '',
        inset: 10,
        state: 'operativesCollapse',
        authority:'menu_reports_operationals',
        views: [
          {
            state: 'mantenimientoCollapse',
            path: '/dashboard-paciente',
            name: 'routes.patients',
            mini: 'routes.patients.mini',
            titlewindow: "routes.dashboardCustomers",
            inset: 20,
            rtlName: '',
            component: DashboardCustomer,
            layout: '',
            enabled: true
          },
          {
            collapse: true,
            state: 'operativesProfesionalesCollapse',
            name: 'routes.professionals',
            rtlName: '',
            mini: 'routes.professionals.mini',
            inset: 20,
            views: [
              {
                state: 'mantenimientoprofesionalCollapse',
                path: '/dashboard-professionals',
                name: 'routes.professionals',
                titlewindow: "routes.DashboardProfessional",
                mini: 'routes.DashboardProfessional.mini',
                rtlName: '',
                inset: 30,
                component: DashboardProfesional,
                layout: '',
                enabled: true
              },
              {
                state: 'mantenimientoCollapse',
                path: '/dashboard',
                name: 'routes.professionalPerformance',
                mini: 'routes.professionalPerformance.mini',
                inset: 30,
                rtlName: '',
                component: Dashboard,
                layout: '',
                enabled: true
              }
            ]
          }
        ]
      },
      {
        name: 'routes.economic',
        mini: 'routes.economic.mini',
        inset: 10,
        rtlName: '',
        component: Dashboard,
        layout: '',
        enabled: true,
        collapse: true,
        views: [
            {
            state: 'dashboardeconomicprofesionals',
            path: '/dashboard-economic-professionals',
            name: 'routes.professionalVisits',
            titlewindow: "routes.professionalVisits",
            mini: 'routes.professionalVisits.mini',
            rtlName: '',
            inset: 20,
            component: DashboardEconomicProfessional,
            layout: '',
            authority:'menu_reports_economics_schedules,menu_reports_economics_schedules_related',
            enabled: true
          },
          {
            state: 'dashboardeconomicsupply',
            path: '/dashboard-economic-supplies',
            name: 'dashboardeconomicsupply.table.title',
            titlewindow: "dashboardeconomicsupply.table.title",
            mini: 'routes.dashboardeconomicsupply.mini',
            rtlName: '',
            inset: 20,
            component: DashboardEconomicSupply,
            layout: '',
            authority:'menu_reports_supplies',
            enabled: true,
          },
          {
            state: 'dashboardeconomictool',
            path: '/dashboard-economic-tools',
            name: 'appointment.tools.tittle',
            titlewindow: "appointment.tools.tittle",
            mini: 'routes.DashboardEconomicTool.mini',
            rtlName: '',
            inset: 20,
            component: DashboardEconomicTool,
            layout: '',
            authority:'menu_reports_tools',
            enabled: true,
          }
        ]
      },
     
    ]

  },
  */
  {
    name: 'routes.powerbi',
    mini: 'routes.powerbi.mini',
    inset: 10,
    rtlName: '',
    layout: '',
    enabled: true,
    icon: DashboardIcon,
    collapse: true,
    authority: 'menu_pbi_reports',
    state: 'dashboardpowerbi',
    views: [
      {
        path: '/dashboard-hospitalizations',
        name: 'routes.dashboardHospitalizations',
        titlewindow: 'routes.dashboardHospitalizations',
        mini: 'routes.dashboardHospitalizations.mini',
        rtlName: '',
        inset: 20,
        layout: '',
        authority: 'admission_module',
        enabled: true,
      },
      {
        path: '/dashboard-diabetic',
        name: 'routes.dashboardDiabetic',
        titlewindow: 'routes.dashboardDiabetic',
        mini: 'routes.dashboardDiabetic.mini',
        rtlName: '',
        inset: 20,
        layout: '',
        authority: 'diabetic_module',
        enabled: true,
      },
    ],
  },
]

export default routes
