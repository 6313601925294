import { connect } from 'react-redux';
import DashboardPBManagementControl from '../components/DashboardsPB/DashboardManagemenControltindex';

const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const DashboardPBManagementControlService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPBManagementControl);

export default DashboardPBManagementControlService;