import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '../../../../CustomButtons/Button';
import CustomDialog from '../../../../CustomDialog';
import SuggestedProfessional from '../../../../SuggestedProfessional';
import './ModalSuggestedProfessionals.css';

class ModalSuggestedProfessionals extends Component {
    constructor(props) {
        super(props);
        
        const { t } = this.props;
        const typesState = [{
            id: 1,
            value: t('Practice'),
        }, {
            id: 2,
            value: t('Package'),
        }];

        this.state = {
            open: false,
            types: typesState,
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            suggested: [],
            shifts: [1, 2, 3],
        }
    }

    componentDidMount() {
        this.fillSuggested();
    }
    componentWillReceiveProps(next) {
        this.fillSuggested();
        this.setState({ open: next.open })
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        })
        this.props.onCloseModal();
    }

    selectedType(value) {
        this.setState({
            type: value,
            current: null,
            component: value === 2 ? 'module' : 'plan',
        })

    }

    createModule(value) {
        this.closeModal();
        this.props.onCreatedModule(value);
    }

    createPlan(value) {
        this.closeModal();
        this.props.onCreatedPlan(value);
    }

    handleValue(value, state, index, shift, packageDetailId) {
        let suggestedEmployee = this.state.suggested;
        let idx = 0;
        let idxselected = 0;

        suggestedEmployee.map(m => {
            if (m.packageDetailId === packageDetailId && m.order === shift) {
                idxselected = idx;
            }
            return idx++;
        });
        const element = this.state.suggested[idxselected];
        element['filerByZone'] = value.filerByZone;
        element['practiceTypeId'] = value.practiceTypeId;
        element['suggestedEmployeeId'] = value.suggestedEmployeeId;

        this.setState({ suggested: suggestedEmployee });
    }

    fillSuggested = () => {
        if (this.state.suggested.length === 0) {
            let sa = [];
            this.props.module.packageDetail.map(m => {
                let shifts = this.fillArray(m);
                shifts.map(t => {
                    let row = {}
                    row['packageDetailId'] = m.packageDetailId;
                    row['practiceTypeId'] = m.practiceType.practiceTypeId;
                    let detail = this.findSuggestedFrontItems(m.practiceType.practiceTypeId, t, shifts.length !== 1);
                    // AQUI
                    if (detail) {
                        row['suggestedEmployeeId'] = detail.suggestedEmployeeId;
                        row['filerByZone'] = detail.filerByZone;
                    } else {
                        row['suggestedEmployeeId'] = null;
                        row['filerByZone'] = true;
                    }
                    row['order'] = t;
                    sa.push(row);
                });
            });
            // alert(JSON.stringify(sa));
            this.setState({ suggested: sa });
        }
    }

    findSuggestedFrontItems = (practice, shift, hasShift) => {
    
        let item;
        if (this.props.itemRequestDetails) {
            this.props.itemRequestDetails.map(m => {
                if (!hasShift) {
                    if (m.practiceTypeId === practice) {
                        item = m;
                    }
                } else {
                    if (m.practiceTypeId === practice && m.order === shift) {
                        item = m;
                    }
                }
            });
        }
        return  item;
    }

    fillArray = (item) => {
        const estimatedDuration = item.practiceType.estimatedDuration;
        const processingScheduleType = item.schedulePlan.processingScheduleType;
        const processingDurationTime = item.schedulePlan.processingDurationTime;
        const tur = processingDurationTime/estimatedDuration;

        const ret = [];
        if (processingScheduleType === 'INTRADAY') {
            for (let idx = 0; idx < tur; idx++){
                ret.push(idx+1);
            }
        } else {
            ret.push(1);
        }
        return ret;
    }

    render() {
        const { t } = this.props;
        const self = this;
        return (
            <CustomDialog
                maxWidth="sm"
                title={t('suggested.professionals.title')}
                open={this.state.open}
                onClose={() => this.closeModal()}
            >
                <GridContainer className='ps-child modules-form assign-professionals-form'>
                    <GridItem xs={12}>
                        <InputLabel className="title-item title-module">
                            <small>{t('suggested.module.module')}</small>
                            {this.props.module.name}
                        </InputLabel>
                        {this.props.module.packageDetail.map((item, i) => {
                            const packageEmployees = self.state.suggested.filter(f => f.packageDetailId === item.packageDetailId);
                            const shifts = self.fillArray(item).map(item => {
                                const empl = packageEmployees.find(pe => pe.order === item);
                                return empl ? empl : item;
                            });
                            return (
                                <div className="speciality-group">
                                    {shifts.map((shiftItem, idx) => {
                                        let hasShift = shifts.length > 1;
                                        let ttext = hasShift ? `: ${t('suggested.shift')} ${(idx+1)}` : '';
                                        return (
                                        <div className="speciality-item speciality-item-padding">
                                            <InputLabel className="title-item title-practice">
                                                <small>{`${t('suggested.module.speciality')} ${i + 1}${ttext}`}</small>
                                                {`${item.practiceType.name}${ttext}`}
                                            </InputLabel>
                                            <SuggestedProfessional
                                                customer={self.props.customer}
                                                hasShift={hasShift}
                                                order={idx+1}
                                                inline={true}
                                                lender={this.props.lender}
                                                itemRequestDetails={shiftItem}
                                                packageDetailId={item.packageDetailId}
                                                practiceType={item.practiceType.practiceTypeId}
                                                onChangeValue={(value, state) => self.handleValue(value, state, i, idx+1, item.packageDetailId)}
                                            />
                                        </div>)
                                    })}
                                </div> 
                            )
                        }
                    )}
                    </GridItem>
                    <GridItem className="schedule-form-submit" xs={12}>
                        <Button color="info" onClick={() => this.props.onConfirmModal(this.state.suggested)}>
                            {t('suggestedprofessionals.save')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CustomDialog>
        )
    }
}

ModalSuggestedProfessionals.defaultProps = {
    onGetModuleTypes: () => [],
    onGetPracticeTypes: () => []
}

ModalSuggestedProfessionals.propTypes = {
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onCreatedModule: PropTypes.func,
    onCreatedPlan: PropTypes.func,
    onGetModuleTypes: PropTypes.func,
    onGetPracticeTypes: PropTypes.func,
    current: PropTypes.object,
    action: PropTypes.string
}

export default withTranslation()(ModalSuggestedProfessionals);
