import { connect } from 'react-redux';
import {getDocumentTypes} from '../actions/newCustomer';
import NewCustomer from '../components/NewCustomer/new-customer';
import { addCustomer, editCustomer } from '../actions/customers';
import EditCustomer from '../components/EditCustomer/edit-customer';

const mapStateToProps = (state) => {
    return {
        documentTypes: state.newCustomer.documentTypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetDocumentTypes: (documentTypes) => {
            dispatch(getDocumentTypes(documentTypes))
        },
        onAddCustomer: (customer) => {
            dispatch(addCustomer(customer))
        },
        onEditCustomer: (customer) => {
            dispatch(editCustomer(customer))
        }
    }
}

const NewCustomerService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCustomer);

const EditCustomerService = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCustomer);

export default {NewCustomerService, EditCustomerService};