import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Card';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import SelectInput from '../../../SelectInput';
import ApiInvoker from '../../../../api/ApiInvoker';
import PersonApiInvoker from '../../../../api/PersonApiInvoker';
import BusinessApiInvoker from '../../../../api/BusinessApiInvoker';
import RecordsApiInvoker from '../../../../api/RecordsApiInvoker';
import AppointmentApiInvoker from '../../../../api/AppointmentApiInvoker';
import Authority from '../../../../util/Authority';
import RadioInput from '../../../RadioInput';

class CustomInputChip extends Component {
    constructor(props) {
        super(props);

        const { t } = this.props;
        const show = Authority.hasPermission('menu_reports_economics_schedules');
        
        const filters = [
            { id: 'financier', title: t('dashboardeconomicprofessional.filter.financier'), code: 'financier', type: 'select', multiple: false },
            { id: 'practice', title: t('dashboardeconomicprofessional.filter.practice'), code: 'employeeTypes', type: 'select', multiple: false },
            { id: 'statusName', title: t('dashboardeconomicprofessional.filter.statusName'), code: 'scheduleStatusName', type: 'select', multiple: false },
            { id: 'mainDiagnostic', title: t('dashboardcustomer.filter.mainDiagnostic'), code: 'mainDiagnostic', type: 'select', multiple: false },
            { id: 'patologicid', title: t('dashboardcustomer.filter.patologicid'), code: 'pathologyId', type: 'select', multiple: false },
            { id: 'province', title: t('dashboardprofesional.filter.province'), code: 'province', type: 'select', multiple: false },
            { id: 'location', title: t('dashboardprofesional.filter.location'), code: 'location', type: 'select', multiple: false },
            { id: 'geographiczone', title: t('dashboardeconomicprofessional.filter.geographiczone'), code: 'geographicZone', type: 'select', multiple: false },
            { id: 'disabledPatient', title: t('appointment.disabledPatient.title'), code: 'disabledPatient', type: 'radio' },
            { id: 'activeDisabilityCertificate', title: t('appointment.activeDisabilityCertificate'), code: 'activeDisabilityCertificate', type: 'radio' },
        ];

        if (show) {
            filters.splice(2, 0, { id: 'employee', title: t('dashboardeconomicprofessional.filter.employee'), code: 'employee', type: 'select', multiple: false})
        }

        this.state = {
            show_items: false,
            items: [],
            locations: [],
            filters,
        }
    }

    onChipClick = () => this.setState({ show_items: !this.state.show_items });

    handleEvent = (e) => {
        const v = this.state.filters[e.target.id];
        let source = [];
        const optionsRadio = [
            { id: '1', value: this.props.t('form.option.yes') },
            { id: '2', value: this.props.t('form.option.no') }
        ];
        if (v.code === 'employeeTypes') {
            this.getEmployeeTypes();
        } else if (v.code === 'financier') {
            this.getFinanciers();
        } else if (v.code === 'mainDiagnostic' || v.code === 'pathologyId') {
            this.getPathologies();
        } else if (v.code === 'province') {
            this.getProvincies();
        } else if (v.code === 'location') {
            source = this.state.locations;
        } else if (v.code === 'geographicZone') {
            source = this.state.zones;
        } else if (v.code === 'employee') {
            this.getEmployees();
        } else if (v.code === 'scheduleStatusName') {
            source = this.getStatus();
        } else if (v.code === 'disabledPatient' || v.code === 'activeDisabilityCertificate') {
            source = optionsRadio;
            v.value = '2';
            v.name = ['NO'];
        } else {
            console.error('WARNING PASSED A FILTER THAT IS NOT ON THE LIST OF ACCEPTED PROPS!!')
        }
        
        this.setState({ items: source, show_items: !this.state.show_items, selected_type: v });
    }
    
    findValue = (value) => {
        let r = null;
        this.state.items.map(d => {
            if (value === d.id) {
                r = d.value;
            }
        });
        return r;
    }

    formatEmployee = (employeeTypes) => employeeTypes.map(e => {
        return {
            id: e.personId,
            value: `${e.firstName} ${e.lastName}`,
        }
    });

    getEmployees() {
        const show = Authority.hasPermission('appointment_view');
        if (show) {
            PersonApiInvoker.getEmployees( employees => {
                if (employees && employees.length) {
                    this.setState({ items: this.formatEmployee(employees) });
                }
            }, err => console.error('** error getEmployees', err));
        } else {
            this.setState({ items: [] });
        }
    }

    formatEmployeeTypes = (practices) => practices.map(e => {
        return {
            id: e.practiceTypeId,
            value: e.name,
        }
    });

    //mlgmlgmlg
    getEmployeeTypes() {
        AppointmentApiInvoker.getOnlyCompanyPracticeTypesAll(practices => {
            if (practices && practices.length) {
                this.setState({ items: this.formatEmployeeTypes(practices) });
            }
        }, err => console.error('** error getGenericPracticeTypes', err));
    }

    formatFinaciers = (financiers) => financiers.map(e => {
        return {
            id: e.financierId,
            value: e.fantasyName,
        }
    });

    getFinanciers() {
        BusinessApiInvoker.getFinanciers(finan => {
            if (finan && finan.length) {
                this.setState({ items: this.formatFinaciers(finan) });
            }
        }, err => console.error('** error getFinanciers', err));
    }

    formatPatologies = (pathologies) => pathologies.map(e => {
        return {
            id: e.id,
            value: e.name,
        }
    });

    getPathologies() {
        RecordsApiInvoker.getAllPatologies(patho => {
            if (patho && patho.length) {
                this.setState({ items: this.formatPatologies(patho) });
            }
        }, err => console.error('** error getAllPatologies', err));
    }

    formatProvincies = (provincies) => provincies.map(e => {
        return {
            id: e.provinceId,
            value: e.name,
        }
    });

    getProvincies() {
        ApiInvoker.getAllProvinces(prov => {
            if (prov && prov.length) {
                this.setState({ items: this.formatProvincies(prov) });
            }
        }, err => console.error('** error getAllProvinces', err));
    }

    formatLocations = (loc) => loc.map(l => {
        return {
            id: l.locationId,
            value: l.name,
        }
    });

    getLocations(value) {
        ApiInvoker.getLocations(value, loc => {
            if (loc && loc.length) {
                this.setState({ locations: this.formatLocations(loc) });
            }
        }, err => console.error('** error getLocations', err));
    }

    formatZones = (zones) => zones.map(z => {
        return {
            id: z.geographicZoneId,
            value: z.detail,
        }
    });
    
    getZones(value) {
        ApiInvoker.getGeographicZoneFromLocation(value, zones => {
            if (zones && zones.length) {
                this.setState({ zones: this.formatZones(zones) });
            } else {
                this.setState({ zones: [] });
            }
        }, err => console.error('** error getGeographicZoneFromLocation', err));
    }

    //MLGMLG
    getStatus = () => {
        const { t } = this.props;
        return [
            { 'id': 'PENDING_APPROVAL', 'value': t('PENDING_APPROVAL') },
            { 'id': 'REJECTED', 'value': t('REJECTED') },
            { 'id': 'APPROVED', 'value': t('APPROVED') },
            { 'id': 'CANCELLED', 'value': t('CANCELLED') },
            { 'id': 'IN_PROGRESS', 'value': t('IN_PROGRESS') },
            { 'id': 'DONE', 'value': t('DONE') },
            { 'id': 'NOT_ATTENDED', 'value': t('NOT_ATTENDED') },
            { 'id': 'WITHOUT_ATTENTION', 'value': t('WITHOUT_ATTENTION') },
            { 'id': 'FINISHED_SYSTEM', 'value': t('FINISHED_SYSTEM') }
        ];
    }
    handleValue(value, state) {
        const item = this.state.selected_type;
        item.value = value;
        if (item.code === 'province') {
            this.getLocations(value);
        } else if (item.code === 'location') {
            this.getZones(value);
        }
        item.name = this.findValue(value);
        this.setState({ selected_type: item });
    }

    handleCloseDialog = () => this.setState({ selected_type: null });

    handleApply = () => {
        if (!this.state.selected_type.value) {
            return;
        }
        const s = this.state.selected_type;
        this.props.onApply(s);
        this.setState({ selected_type: null });
    }

    has = (keyvalue) => {
        let p = false;
        this.state.filters.map((item, key) => {
            if (item.code === keyvalue && item.value) {
                p = true;
            }
        });
        return p;
    }

    hasSelectedRadio = (keyvalue) => {
        let p = false;
        this.state.filters.map((item, key) => {
            if (item.code === keyvalue && item.value === '1') {
                p = true;
            }
        });
        return p;
    }

    hasCity = () => false;

    findItemValue = (code) => {
        let ret = false;
        this.props.selecteditems.map((item, key) => {
            if (item.code === code) {
                ret = true;
            }
        });
        return ret;
    }

    renderCardDialogItem = (selected_type) => {
        const { items } = this.state;
        switch (true) {
            case selected_type.type === 'select':
                return  (
                    <SelectInput
                        className="customInputChipSelect" 
                        elements={items} 
                        onSelectedValue={(value) => this.handleValue(value, 'item')}
                        label={selected_type.title}
                    />
                );
            case selected_type.type === 'radio':
                return (
                    <>
                        <p className="radio-legend">
                            {selected_type.title}
                        </p>
                        <RadioInput
                            elements={items}
                            value={selected_type.value}
                            onChangeValue={value => this.handleValue(value)}
                            inputProps={{
                                disabled: false,
                            }}
                        />
                    </>
                )
            default:
                break;
        }
    }

    renderDialogSelect = () => {
        const { t } = this.props;
        const { selected_type } = this.state;

        return (
            <Dialog
                open={true}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <GridContainer className="filters" justify="center">
                        <GridItem xs={12}>
                            {this.renderCardDialogItem(selected_type)}
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleCloseDialog} color="primary">
                    {t('dashboardprofesional.filters.close')}
                </Button>
                <Button onClick={this.handleApply} color="primary">
                    {t('dashboardprofesional.filters.apply')}
                </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { id, label } = this.props;
        const { filters, selected_type, show_items } = this.state;

        if (selected_type) return this.renderDialogSelect();
        
        return (
            <div>
                <Chip
                    id={id}
                    label={label}
                    handleInteraction={this.onChipClick}
                />
                {show_items ? 
                    <div style={{position: 'absolute'}}>
                    <Paper>
                        <MenuList>
                            {filters.map((item, key) => {
                                let findc = this.findItemValue(item.code);
                                if (item.multiple) {
                                    findc = false;
                                }
                                if (findc || (item.code === 'location' && !this.has('province')) || (item.code === 'geographicZone' && !this.has('location')) || (item.code === 'activeDisabilityCertificate' && !this.hasSelectedRadio('disabledPatient'))) {
                                    return <div />;
                                }
                                return (
                                    <MenuItem
                                        id={key}
                                        key={item.code}
                                        onClick={this.handleEvent}
                                    >
                                        {item.title}
                                    </MenuItem>
                                )}
                            )}
                        </MenuList>
                    </Paper>
                    </div>
                : <div/>}
            </div>
        )
    }
}

CustomInputChip.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
}

export default withTranslation()(CustomInputChip);
