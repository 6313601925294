import './HospitalizationStyles.css'

import React, { useEffect, useState } from 'react'

import FormDischarge from './Forms/FormDischarge'
import FormFinancial from './Forms/FormFinancial'
import FormHospitalization from './Forms/FormHospitalization'
import FormPatients from './Forms/FormPatients'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import HozpitalizationApiInvoker from '../../api/HozpitalizationApiInvoker'
import RegularButton from '../CustomButtons/Button.jsx'
import Snackbar from '../Snackbar/Snackbar'
import { browserHistory } from 'react-router'
import { useMemo } from 'react'

const HospitalizationFormView = (props) => {
  const [dataPatient, setDataPatient] = useState('')
  const [dataPatientEdit, setDataPatientEdit] = useState(null)
  const [dataHospitalizationEdit, setDataHospitalizationEdit] = useState(null)
  const [dataDischargeEdit, setDataDischargeEdit] = useState(null)
  const [personId, setPersonId] = useState(null)
  const [dataFinance, setDataFinance] = useState('')
  const [dataHospitalization, setDataHospitalization] = useState('')
  const [dataDischarge, setDataDischarge] = useState('')
  const [showDischargeForm, setShowDischargeForm] = useState('')
  const [isValidatedPatientForm, setIsValidatedPatientForm] = useState(false)
  const [isValidatedFinanceForm, setIsValidatedFinanceForm] = useState(false)
  const [isValidatedDischargeForm, setIsValidatedDischargeForm] =
    useState(false)
  const [isValidatedHospitalizationForm, setIsValidatedHospitalizationForm] =
    useState(false)
  const [isValidatedAllForm, setIsValidatedAllForm] = useState(false)
  const [axaInternmentId, setAxaInternmentId] = useState(null)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [colorSnackBar, setColorSnackBar] = useState(null)
  const [messageSnackBar, setMessageSnackBar] = useState(null)
  const [axaInternmentStatus, setAxaInternmentStatus] = useState(null)
  const [validateFrequencyError, setValidateFrequencyError] = useState(false)

  const [save, setSave] = useState(false)

  let id = props.params.id

  const onSave = () => {
    let dataForm = SetData('CREATED')
    if (id) {
      setLoading(true)
      setSave(false)
      HozpitalizationApiInvoker.updateAxaInternment(
        dataForm,
        (response) => {
          if (response) {
            setShowSnackBar(true)
            setColorSnackBar('success')
            setMessageSnackBar('Los cambios se guardaron exitosamente')
            setLoading(false)
            setTimeout(function () {
              setShowSnackBar(false)
              goToAdmissionList()
            }, 2000)
          }
        },
        (error) => {
          setShowSnackBar(true)
          setColorSnackBar('danger')
          setMessageSnackBar('Hubo un error al intentar guardar los cambios.')
          setLoading(false)
          setTimeout(function () {
            setShowSnackBar(false)
          }, 5000)
        }
      )
    } else {
      setLoading(true)
      setSave(false)
      HozpitalizationApiInvoker.postDischargeData(
        dataForm,
        (response) => {
          if (response) {
            setShowSnackBar(true)
            setColorSnackBar('success')
            setMessageSnackBar('Los cambios se guardaron exitosamente')
            setLoading(false)
            setTimeout(function () {
              setShowSnackBar(false)
              goToAdmissionList()
            }, 2000)
          }
        },
        (error) => {
          setShowSnackBar(true)
          setColorSnackBar('danger')
          setMessageSnackBar('Hubo un error al intentar guardar los cambios.')
          setLoading(false)
          setTimeout(function () {
            setShowSnackBar(false)
          }, 5000)
        }
      )
    }
  }

  const goToAdmissionList = () => browserHistory.push('/admission')

  const SetData = () => {
    let data = {
      userId: window.localStorage.getItem('user_id'),
      userName: window.localStorage.getItem('username'),
      internmentStatus: axaInternmentStatus,
      axaInternmentId: id ? axaInternmentId : '',
      patientId: {
        personId: parseInt(dataPatient.patient),
      },
      axaServicesCenterId: {
        axaServicesCenterId: dataHospitalization.service_center,
      },
      room: dataHospitalization.room,
      therapy: dataHospitalization.therapy,
      admission_date: dataHospitalization.date_admission,
      egress_date: dataHospitalization.egress_date,
      treatingMedicalsId: dataHospitalization.treating_doctors
        ? {
            treatingMedicalsId: dataHospitalization.treating_doctors,
          }
        : null,
      historyPathologyList: dataHospitalization.history_of_pathology,
      admissionPathologyList: dataHospitalization.admission_diagnosis,
      dischargePathologyList: dataHospitalization.discharge_diagnosis,
      internmentKatz: dataDischarge.internmentKatz,
      barthelIndexId: dataDischarge.barthel_index,
      hospitalizationNewsList: dataHospitalization.hospitalizationNewsList,
      dischargePlaceId: dataHospitalization
        ? dataHospitalization.dischargePlace
        : null,
      medicalRecord: dataDischarge.clinical_history_summary,
      nursing: dataDischarge ? dataDischarge.nursing : false,
      nursingFrequency: dataDischarge ? dataDischarge.nursing_frequency : null,
      kinesiology: dataDischarge ? dataDischarge.kinesiology : false,
      kinesiology_frequency: dataDischarge
        ? dataDischarge.kinesiology_frequency
        : null,
      speechTherapy: dataDischarge ? dataDischarge.speechTherapy : false,
      speechTherapy_frequency: dataDischarge
        ? dataDischarge.speechTherapy_frequency
        : null,
      occupationalTherapy: dataDischarge
        ? dataDischarge.occupationalTherapy
        : false,
      occupationalTherapy_frequency: dataDischarge
        ? dataDischarge.occupationalTherapy_frequency
        : null,
      healing: dataDischarge ? dataDischarge.curations : false,
      healing_frequency: dataDischarge
        ? dataDischarge.curations_frequency
        : null,
      dressingsTypeId: dataDischarge ? dataDischarge.dressings_type : null,
      wheelchair: dataDischarge ? dataDischarge.wheelchair : false,
      orthopedic_bed: dataDischarge ? dataDischarge.orthopedic_bed : false,
      walkerno: dataDischarge ? dataDischarge.walker : false,
      oxygenConcentrator5Liter: dataDischarge
        ? dataDischarge.oxygenConcentrator5Liter
        : false,
      oxygenConcentrator10Liter: dataDischarge
        ? dataDischarge.oxygenConcentrator10Liter
        : false,
      medicineList: dataDischarge ? dataDischarge.medicineList : [],
    }
    return data
  }

  const validateForm = () => {
    setSave(true)
    if (
      isValidatedPatientForm &&
      isValidatedFinanceForm &&
      isValidatedHospitalizationForm &&
      showDischargeForm &&
      isValidatedDischargeForm &&
      validateFrequencyError
    ) {
      onSave()
    } else if (
      isValidatedPatientForm &&
      isValidatedFinanceForm &&
      isValidatedHospitalizationForm &&
      !showDischargeForm
    ) {
      onSave()
    } else if (
      isValidatedPatientForm &&
      isValidatedFinanceForm &&
      isValidatedHospitalizationForm &&
      showDischargeForm &&
      !isValidatedDischargeForm
    ) {
      setShowSnackBar(true)
      setColorSnackBar('warning')
      setMessageSnackBar(
        'Hay campos requeridos sin información. Complete todos los datos requeridos.'
      )
      setLoading(false)
      setTimeout(function () {
        setShowSnackBar(false)
      }, 3000)
    } else if (
      isValidatedPatientForm &&
      isValidatedFinanceForm &&
      isValidatedHospitalizationForm &&
      showDischargeForm &&
      !validateFrequencyError
    ) {
      setShowSnackBar(true)
      setColorSnackBar('warning')
      setLoading(false)
      setTimeout(function () {
        setShowSnackBar(false)
      }, 3000)
      setMessageSnackBar(
        'Falta escoger una frecuencia semanal para aquella indicaciones afirmativas.'
      )
    } else {
      setShowSnackBar(true)
      setColorSnackBar('warning')
      setMessageSnackBar(
        'Hay campos requeridos sin información. Complete todos los datos requeridos.'
      )
      setLoading(false)
      setTimeout(function () {
        setShowSnackBar(false)
      }, 3000)
    }
  }

  const SetDataPatientForm = (data) => {
    setDataPatient(data)
  }

  const SetDataFinanceForm = (data) => {
    setDataFinance(data)
  }

  const SetDataHospitalizationtForm = (data) => {
    setDataHospitalization(data)
  }

  const SetDataDischargeForm = (data) => {
    setDataDischarge(data)
  }

  const validationShowDischargeForm = (value) => {
    setShowDischargeForm(value)
  }

  const SetEditData = (id) => {
    HozpitalizationApiInvoker.getAxaInternmentById(id, (response) => {
      setAxaInternmentId(response[0].axaInternmentId)
      setAxaInternmentStatus(response[0].internmentStatus)
      setDataPatientEdit({
        patient: response[0].patientId.personId,
        identification: response[0].patientId.id,
        direction: response[0].patientId.address.length
          ? response[0].patientId.address[0].addressId
          : null,
        zone: response[0].patientId.address.length
          ? response[0].patientId.address[0].geographicZone.detail
          : null,
        location: response[0].patientId.address.length
          ? response[0].patientId.address[0].location.name
          : null,
        province: response[0].patientId.address.length
          ? response[0].patientId.address[0].geographicZone.location.name
          : null,
        doctor: response[0].patientId.generalPractitionerId
          ? response[0].patientId.generalPractitionerId.generalPractitionerId
          : '',
        medicalRecord: response[0].patientId.generalPractitionerId
          ? response[0].patientId.generalPractitionerId
              .medicalRegistrationNumber
          : '',
      })
      setDataHospitalizationEdit({
        serviceCenter: response[0].axaServicesCenterId.axaServicesCenterId,
        therapy: response[0].therapy,
        room: response[0].room,
        dateAdmission: response[0].admission_date,
        egressDate: response[0].egress_date,
        treatingMedicalsId: response[0].treatingMedicalsId.treatingMedicalsId,
        historyPathology: response[0].historyPathologyList,
        admissionPathology: response[0].admissionPathologyList,
        dischargePathology: response[0].dischargePathologyList,
        hospitalizationNewsList: response[0].hospitalizationNewsList,
        dischargePlaceId: response[0].dischargePlaceId
          ? response[0].dischargePlaceId.dischargePlaceId
          : response[0].dischargePlaceId,
      })
      setDataDischargeEdit({
        medicalRecord: response[0].medicalRecord,
        nursing: response[0].nursing,
        nursing_frequency: response[0].nursingFrequency
          ? response[0].nursingFrequency.frequencyRangesId
          : null,
        kinesiology: response[0].kinesiology,
        kinesiology_frequency: response[0].kinesiology_frequency
          ? response[0].kinesiology_frequency.frequencyRangesId
          : null,
        speechTherapy: response[0].speechTherapy,
        speechTherapy_frequency: response[0].speechTherapy_frequency
          ? response[0].speechTherapy_frequency.frequencyRangesId
          : null,
        occupationalTherapy: response[0].occupationalTherapy,
        occupationalTherapy_frequency: response[0].occupationalTherapy_frequency
          ? response[0].occupationalTherapy_frequency.frequencyRangesId
          : null,
        psychology: response[0].psychology,
        psychology_frequency: response[0].psychology_frequency
          ? response[0].psychology_frequency.frequencyRangesId
          : null,
        homeAssistant: response[0].homeAssistant,
        homeAssistant_frequency: response[0].homeAssistant_frequency
          ? response[0].homeAssistant_frequency.frequencyRangesId
          : null,
        healing: response[0].healing,
        healing_frequency: response[0].healing_frequency
          ? response[0].healing_frequency.frequencyRangesId
          : null,
        dressingsTypeId: response[0].dressingsTypeId
          ? response[0].dressingsTypeId.dressingsTypeId
          : response[0].dressingsTypeId,
        wheelchair: response[0].wheelchair,
        orthopedic_bed: response[0].orthopedic_bed,
        walkerno: response[0].walkerno,
        oxygenConcentrator5Liter: response[0].oxygenConcentrator5Liter,
        oxygenConcentrator10Liter: response[0].oxygenConcentrator10Liter,
        medicineList: response[0].medicineList,
        internmentKatz: response[0].internmentKatz,
        barthelIndexId: response[0].barthelIndexId.recordId,
      })
    })
  }

  const Init = useMemo(() => {
    if (id) {
      SetEditData(id)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    Init
  }, [
    isValidatedPatientForm,
    isValidatedFinanceForm,
    isValidatedHospitalizationForm,
    dataFinance,
  ])

  return (
    <form>
      <Snackbar
        color={colorSnackBar}
        message={messageSnackBar}
        open={showSnackBar}
        place="tc"
      />
      <GridContainer>
        <GridItem md={6}>
          <FormPatients
            setDataPatientForm={SetDataPatientForm}
            setIsValidatedPatientForm={setIsValidatedPatientForm}
            setPersonId={setPersonId}
            editData={dataPatientEdit}
            onlyView={true}
            id={id}
          ></FormPatients>
        </GridItem>
        <GridItem md={6}>
          <FormFinancial
            setDataFinanceForm={SetDataFinanceForm}
            setIsValidatedFinanceForm={setIsValidatedFinanceForm}
            personId={personId}
            onlyView={true}
          ></FormFinancial>
        </GridItem>
        <GridItem md={12}>
          <FormHospitalization
            validationShowDischargeForm={validationShowDischargeForm}
            setDataHospitalizationForm={SetDataHospitalizationtForm}
            setIsValidatedHospitalizationForm={
              setIsValidatedHospitalizationForm
            }
            editData={dataHospitalizationEdit}
            onlyView={true}
          ></FormHospitalization>
        </GridItem>
        {showDischargeForm && (
          <GridItem md={12}>
            <FormDischarge
              setIsValidatedDischargeForm={setIsValidatedDischargeForm}
              SetDataDischargeForm={SetDataDischargeForm}
              setFrequencyError={setValidateFrequencyError}
              editData={dataDischargeEdit}
              onlyView={true}
            ></FormDischarge>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer md={12}>
        <div className="buttons-container">
          <div>
            <RegularButton onClick={goToAdmissionList}>Volver</RegularButton>
          </div>
          <div className="button-submit-container">
      
            <RegularButton
              authority="hospitalization_new"
              disabled={
                dataHospitalization &&
                !dataHospitalization.hospitalizationNewsList
              }
              onClick={validateForm}
              color="info"
            >
              GUARDAR CAMBIOS
            </RegularButton>
          </div>
        </div>
      </GridContainer>
    </form>
  )
}

export default HospitalizationFormView
