import { connect } from 'react-redux';
import { getLocations } from '../actions/address';
import CreateAddress from '../components/CreateAddress/create-address';

const mapStateToProps = (state) => {
    return {
        locations: state.address.locations
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetLocations: (locations) => {
            dispatch(getLocations(locations))
        }
    }
}

const AddressService = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateAddress);

export default AddressService;