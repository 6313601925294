import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@material-ui/core';
import ButtonSpinner from '../../ButtonSpinner';
import Button from '../../CustomButtons/Button';
import CustomSweetAlert from '../../CustomSweetAlert';
import useProfessionalsStep from './ProfessionalsStep/useProfessionalsStep';
import { useStylesReassignAppStepper } from '../useStylesReassignAppStepper';
import { initialAlertWarning } from '../data';
import useCalendarStep from './CalendarStep/useCalendarStep';
import useDateStep from './DateStep/useDateStep';
import useTimeStep from './TimeStep/useTimeStep';

export const ButtonsSteps = ({
  clearInformation,
  handleBack,
  handleChange,
  handleNext,
  handleSkipStep,
  showSnackbarNotification,
  removeCompletedStep,
  values,
  steps,
  t,
}) => {
  const classes = useStylesReassignAppStepper();

  const [alertWarning, setAlertWarning] = useState(initialAlertWarning);
  const { activeStep, refStepsValues} = values;

  const {
    getProfessionals,
    resetAllProfessionals,
  } = useProfessionalsStep(handleChange, showSnackbarNotification, refStepsValues, values);

  const {
    calculateReassignment,
    confirmReassignment,
    handleDeleteBookings,
  } = useCalendarStep(clearInformation, handleChange, handleNext, showSnackbarNotification, values, t);

  const {
    handleClearDates,
  } = useDateStep(handleChange, values);
  const {
    handleClearTimes,
  } = useTimeStep(handleChange, values);

  const {
    bookings, bookingsError, busyButton,
    dateSchedulesHasChanged,
    loadingevents,
    professionalsSelected, professionalsToReassign,
    schedules, schedulesSelected,
    timeSchedulesHasChanged,
  } = values;

  const firstStep = activeStep === 0;
  const secondStep = activeStep === 1;
  const thirdStep = activeStep === 2;
  const fourthStep = activeStep === 3;
  const fifthStep = activeStep === 4;
  const sixthStep = activeStep === 5;

  const isConfirmStep = activeStep === steps.length - 1;

  const bookingsToDelete = [...bookings, ...bookingsError];

  const backAction = () => {
    handleBack();
    if (sixthStep) {
      handleDeleteBookings(bookingsToDelete);
      removeCompletedStep(5);
    }
  }

  const firstStepNextAction = () => {
    const schedulesCkecked = schedules.filter(f => f.checkedSchedule === true);
    handleChange('schedulesSelectedData', schedulesCkecked);
    professionalsToReassign.length === 0 && getProfessionals();
  };

  const nextAction = () => {
    handleNext(true);
    firstStep && firstStepNextAction();
  }

  const disabledNextButton = () => {
    switch (true) {
      case firstStep:
        return firstStep && schedulesSelected.length < 1;
      case secondStep:
        return secondStep && professionalsSelected.length < 1;
      case thirdStep:
        return thirdStep && !dateSchedulesHasChanged;
      case fourthStep:
        return fourthStep && !timeSchedulesHasChanged;
      default:
        break;
    }
  }

  const isVisibleNextButton = !fifthStep && !sixthStep;

  const skipAction = () => {
    handleSkipStep();
    
    switch (true) {
      case secondStep:
        return resetAllProfessionals();
      case thirdStep:
        return handleClearDates();
      case fourthStep:
        return handleClearTimes();
      default:
        break;
    }
  }

  const aletCancelAction = () => {
    handleDeleteBookings(bookingsToDelete);
    clearInformation();
  }

  const alertWarningAction = (type) => {
    switch (type) {
      case 'cancel':
        return aletCancelAction();
      case 'confirm':
        return confirmReassignment();
      default:
        break;
    }
  }

  const alertCancel = {
    open: true,
    message:
      <>
        <p>{t('message.cancellationReassignment')}</p>
        <p>{t('message.cancellationReassignmentConfirm')}</p>
      </>,
    confirmBtnText: t('common.accept'),
    cancelBtnText: t('common.cancel'),
    title: t('title.cancellationReassignment'),
    type: 'cancel',
  }

  return (
    <>
      <Grid container className={classes.buttons}>
        <Grid item xs={6} className={classes.buttonsStart}>
          {activeStep !== 0 &&
            <>
              <Button
                className={classes.button}
                onClick={() => setAlertWarning(alertCancel)}
              >
                {t('common.cancel')}
              </Button>
              <Button
                className={classes.button}
                color="rose"
                onClick={backAction}
                disabled={activeStep === 0}
              >
                {t('common.prev')}
              </Button>
            </>
          }
        </Grid>
        <Grid item xs={6} className={classes.buttonsEnd}>
          {steps[activeStep].isOpcional &&
            <Button
              variant="contained"
              color="rose"
              onClick={() => skipAction()}
              className={classes.button}
              disabled={activeStep === 0 && schedulesSelected.length < 1}
            >
              {t('label.skip')}
            </Button>
          }
          {(isVisibleNextButton) &&
            <>
              <Button
                variant="contained"
                color="rose"
                onClick={() => nextAction()}
                className={classes.button}
                disabled={disabledNextButton()}
              >
                {t('common.next')}
              </Button>
            </>
          }

          {fifthStep && (
            <Tooltip title={t('tooltipInfo.stepReassingConfirm')}>
              <span className={classes.button}>
                <ButtonSpinner
                  className={classes.button}
                  onClick={() => calculateReassignment(true)}
                  disabled={loadingevents}
                  label={t('label.calculateReassignment')}
                  labelLoading={t('label.calculateReassignment')}
                  loading={loadingevents}
                  typeButton="submit"
                  color="info"
                />
              </span>
            </Tooltip>
          )}

          {isConfirmStep
            && <ButtonSpinner
              className={classes.button}
              color="info"
              disabled={busyButton || bookings.length === 0}
              label={t('label.confirmReassignment')}
              labelLoading={t('label.confirmReassignment')}
              loading={busyButton}
              onClick={() => setAlertWarning({
                open: true,
                message: <p>{t('message.confirmReassignment')}</p>,
                confirmBtnText: t('common.save'),
                cancelBtnText: t('common.cancel'),
                title: t('label.confirmReassignment'),
                type: 'confirm',
              })}
              typeButton="submit"
            />
          }
        </Grid>
      </Grid>

      {alertWarning.open &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => {
            setAlertWarning(initialAlertWarning);
            alertWarningAction(alertWarning.type)
          }}
          onCancel={() => setAlertWarning(initialAlertWarning)}
          confirmBtnCssClass="success"
          confirmBtnText={alertWarning.confirmBtnText}
          cancelBtnCssClass="danger"
          cancelBtnText={alertWarning.cancelBtnText}
          title={alertWarning.title}
          message={alertWarning.message}
      />}
    </>
  )
}

export default withTranslation()(ButtonsSteps);
