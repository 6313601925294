import { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import SchedulerInvoker from '../../../../api/SchedulerInvoker';
import { getColor, initialAlertWarning } from '../../data';

const useCalendarStep = (clearInformation, handleChange, handleNext, showSnackbarNotification, values, t) => {
  const {
    bookings, bookingsError, oevents, practicesType, professionalsSelected, schedulesSelectedData,
    events,
    bookingChanged,
    currentEvent,
  } = values;

  const [openDetail, setOpenDetail] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(null);
  const [status, setStatus]  = useState(null);

  const formatPracticeName = (practiceTypeId, genericName) => {
    const result = practicesType.filter(pt => pt.practiceTypeId === practiceTypeId);
    if (result.length > 0) {
      return result[0]['name'];
    } else {
      return genericName;
    }
  }

  const addBooking = (book, id) => !book.includes(id) && book.push(id);

  const reduceBookingsOk = (dt, book) => dt.reduce((prev, parent) => {
    const bookingData = parent.bookings.filter(f => f.status !== 'ERROR').map((e) => {
      addBooking(book, parent.appointmentBookingId);
      return {
        id: e.scheduleBookingId,
        title: `${moment(e.scheduleDateTime).format('HH:mm')} - ${e.label}`,
        allDay: false,
        time_display: `${moment(e.scheduleDateTime).format('HH:mm')} - ${moment(e.scheduleEndDateTime).format('HH:mm')}`,
        time_start: moment(e.scheduleDateTime).format('HH:mm'),
        start: new Date(e.scheduleDateTime),
        end: new Date(e.scheduleEndDateTime),
        color: getColor(e),
        employeeId: e.employeeId,
        practiceTypeId: 1,
        status: !e.status ? 'OK' : e.status,
        appointmentBookingId: parent.appointmentBookingId,
        scheduleDateTime: e.scheduleDateTime,
      };
    });
    return prev.concat(bookingData);
  }, []);

  const reduceBookingsOkList = (dt, book) => dt.reduce((prev, parent) => {
    const bookingData = parent.bookings.filter(f => f.status != 'ERROR').map((e) => {
      addBooking(book, parent.appointmentBookingId);
      return {
        customer: `${parent.customerFirstName} ${parent.customerLastName}`,
        appointmentId: parent.appointmentId,
        scheduleId: e.originalSchedulerId,
        scheduleDateTime: moment(e.scheduleDateTime).format('DD/MM/YYYY HH:mm'),
        location: e.locationName,
        geographicZone: e.geographicZoneName,
        practiceTypeName: (e.practiceTypeId ? formatPracticeName(e.practiceTypeId, e.practiceTypeName) : ''),
      };
    });
    return prev.concat(bookingData);
  }, []);



  const reduceBookingsError = (dt, book) => dt.reduce((prev, parent) => {
    const bookingData = parent.bookings.filter(f => f.status === 'ERROR').map((e) => {
      addBooking(book, parent.appointmentBookingId);
      return {
        customer: `${parent.customerFirstName} ${parent.customerLastName}`,
        appointmentId: parent.appointmentId,
        scheduleId: e.originalSchedulerId,
        scheduleDateTime: moment(e.scheduleDateTime).format('DD/MM/YYYY HH:mm'),
        location: e.locationName,
        geographicZone: e.geographicZoneName,
        practiceTypeName: (e.practiceTypeId ? formatPracticeName(e.practiceTypeId, e.practiceTypeName) : ''),
      };
    });
    return prev.concat(bookingData);
  }, []);

  const formatTableDataCalendar = (data) => {
    let eventsData = [];
    let notAssignedData = [];
    let AssignedDataOk = [];

    let bookingsOk = [];
    let bookingsErr = [];

    if (data && data.length) {
      eventsData = reduceBookingsOk(data, bookingsOk);
      notAssignedData = reduceBookingsError(data, bookingsErr);
      AssignedDataOk = reduceBookingsOkList(data, bookingsOk);

    }

    const oresultData = data.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);

    handleChange('events', eventsData);
    handleChange('oevents', oresultData);
    handleChange('bookings', bookingsOk);
    handleChange('bookingsError', bookingsErr);
    handleChange('schedulesNotAssigned', notAssignedData);
    handleChange('schedulesAssigned', AssignedDataOk);

    handleChange('loadingTable', false);
  }

  const calculateReassignment = (prevStep = false) => {
    const bodySchedules = schedulesSelectedData.map((d) => {
      const scheduleDate = d.scheduleDateTimeUpdate ? d.scheduleDateTimeUpdate : d.scheduleDateTime;
      const scheduleTime = d.scheduleTimeUpdate ? d.scheduleTimeUpdate : d.scheduleTime;
      const scheduleDateTime = `${moment(scheduleDate).format('YYYY-MM-DD')}T${scheduleTime}:00`;
      return {
        scheduleId: d.scheduleId,
        scheduleDateTime,
      }
    });
    const body = { scheduleIds: bodySchedules, personIds: professionalsSelected };
    handleChange('loadingevents', true);

    SchedulerInvoker.scheduleReasign(
      body,
      data => {
        formatTableDataCalendar(data);
        handleChange('data_saved', false);
        handleChange('loadingevents', false);
        prevStep && handleNext(true);
      }, (err) => {
        const { message }  = err;
        showSnackbarNotification(message, 'danger');
        handleChange('data_saved', false);
        handleChange('loadingevents', false);
      }
    );
  }

  const handleDeleteBookings = (bookingsDel) => {
    if (bookingsDel && bookingsDel.length > 0) {
      SchedulerInvoker.deleteBookingsByArray(
        bookingsDel,
        () => {
          handleChange('events', []);
          handleChange('bookings', []);
          handleChange('bookingChanged', []);
          handleChange('showConfirmSave', false);
        }, (err) => {
          handleChange('showConfirmSave', false);
          const { message }  = err;
          showSnackbarNotification(message, 'danger');
        }
      );
      handleChange('hasChangedSchedules', false);
      handleChange('events', oevents);
      handleChange('showConfirmUndo', false);
    }
  }

  const handleUndoChanges = (bookingsDel) => {
    handleDeleteBookings(bookingsDel);
    calculateReassignment();
    showSnackbarNotification(t('common.undoMessageConfirm'), 'success');
  }

  const updateAppointment = (appointment) => {
    let result = [...events];
    setUpdateErrors(null);
    setStatus(null);

    if (appointment && appointment.status) {
      if (appointment.status !== 'ERROR') {
        if (appointment.appointmentBookingId) {
          const bchanged = [...bookingChanged];
          if (!bchanged.includes(appointment.appointmentBookingId)) {
            bchanged.push(appointment.appointmentBookingId);
            handleChange('bookingChanged', bchanged);
          }
        }

        result.forEach((b, i) => {
          if (b.id === currentEvent.id) {
            let item = appointment.bookings[0];
            result[i] = {
              id: item.scheduleBookingId,
              title: `${moment(item.scheduleDateTime).format('HH:mm')} - ${item.label}`,
              allDay: false,
              time_display: `${moment(item.scheduleDateTime).format('HH:mm')} - ${moment(item.scheduleEndDateTime).format('HH:mm')}`,
              time_start: moment(item.scheduleDateTime).format('HH:mm'),
              start: new Date(item.scheduleDateTime),
              end: new Date(item.scheduleEndDateTime),
              color: getColor(item.group),
              employeeId: item.employeeId,
              status: item.status,
              errorDetail: item.status === 'ERROR' || item.status === 'WARNING' 
                ? item.errorDetail : '',
              type: item.booking || item.scheduleBookingId
                ? 'booking' : 'schedule',
              scheduleDateTime: item.scheduleDateTime,
            }
          }
        });

        setOpenDetail(false);
        handleChange('events', _.orderBy(result, ['start'], ['asc']));
        handleChange('hasChangedSchedules', true);
      } else {
        setUpdateErrors(appointment.errors);
        setStatus(appointment.status);
      }
    } else {
      // Handling Unexpected errors
      setUpdateErrors([{
        detail: appointment.message,
        statusError: 'ERROR',
      }]);
      setStatus('ERROR');
    }
  }

  const handleOnSelectedEvent = (event) => {
    setOpenDetail(true);
    setUpdateErrors(null);
    handleChange('currentEvent', event);
  }

  const eventColors = (event) => {
    let backgroundColor = 'event-';
    backgroundColor = event.color
      ? `${backgroundColor}${event.color}`
      : `${backgroundColor}default`;
    return {
      className: backgroundColor,
    }
  }

  const confirmReassignment = () => {
    handleChange('busyButton', true);
    bookings.forEach(bookingId => {
      return SchedulerInvoker.postSaveBookings(
        bookingId,
        () => {
          const index = bookings.indexOf(bookingId);
          if (index !== -1) bookings.splice(index, 1);

          if (bookings.length === 0) {
            clearInformation();
            showSnackbarNotification(t('reassignAppointments.saveSuccess'), 'success');
          }
        }, (err) => {
          const { message }  = err;
          handleChange('busyButton', false);
          handleChange('alertWarning', initialAlertWarning);
          showSnackbarNotification(message, 'danger');
        }
      );
    });
    handleDeleteBookings(bookingsError);
  }

  return {
    calculateReassignment,
    confirmReassignment,
    eventColors,
    handleDeleteBookings,
    handleOnSelectedEvent,
    handleUndoChanges,
    updateAppointment,
    openDetail,
    setOpenDetail,
    updateErrors,
    status,
  }
}

export default useCalendarStep;
