import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Grid, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import Table from '../../../Table/Table';
import Button from '../../../CustomButtons/Button';
import TimePickerGCalendar from '../../../TimePickerGCalendar';
import { useStylesGralSteps, useStylesDateTime } from '../../useStylesReassignAppStepper';
import useTimeStep from './useTimeStep';

const TimeStep = ({
  handleChange,
  handleIsValidStep,
  setRefSchedules,
  values,
  t,
}) => {
  const classes = useStylesGralSteps();
  const classesTime = useStylesDateTime();

  const {
    timeSchedules,
    timeSchedulesHasChanged,
  } = values;

  const {
    handleClearTimes,
    handleValueTimes,
    isValid,
    schedulesData,
  } = useTimeStep(handleChange, values);

  useEffect(() => {
    handleIsValidStep(isValid);
  }, [handleIsValidStep, isValid]);

  return (
    <Grid
      container
      className={classes.formStepper}
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.titleWidthIconEnd)}>
          <span>{t('title.desiredStartHour')}</span>
          <Tooltip placement="right" title={t('tooltipInfo.desiredStartHour')}>
            <InfoOutlined />
          </Tooltip>
        </h4>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classesTime.contentDateTimeInput}>
        <TimePickerGCalendar
          text={`${t('common.startTime')} *`}
          value={timeSchedules}
          onSelectedValue={(value) => handleValueTimes(value)}
          name="startTimeAll"
        />
      </Grid>
       
      <Grid item xs={12}>
      <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.appointmentsToModify')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable
          setRef={setRefSchedules}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.scheduleId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.professional'), accessor: 'professional' },
            { Header: t('reassignAppointments.table.scheduleDateTime'), accessor: 'scheduleDate' },
            { Header: t('label.hour'), accessor: 'scheduleTimeInputUpdate', className: classesTime.columnDateTime },
          ]}
          tableData={schedulesData}
          defaultPageSize={schedulesData && schedulesData.length > 5 ? schedulesData.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>
      <Grid item xs={12} className={classes.actionButton}>
        <Button
          onClick={() => handleClearTimes()}
          className={classes.button}
          disabled={!timeSchedulesHasChanged}
        >
          {t('label.undo')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default withTranslation()(TimeStep);
