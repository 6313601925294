import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ChipSet, Chip } from '@material/react-chips';
import '@material/react-chips/dist/chips.css';
import Cancel from '@material-ui/icons/Cancel';
import CustomInputChip from './CustomInputChip';

class ChipFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            professionals: [],
            employees: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            elements: [],
        }
    }

    addFilter = (s) => {
        var c = this.props.chips
        c.push(JSON.parse(JSON.stringify(s)));
        this.props.onChangeChips(c,this.props.chips);
    }

    removeChip = (keyremove) => {
        this.props.chips.map((item, key) => {
            if (item.code === keyremove) {
                this.props.chips.splice(key,1);
            }
        });

    }

    onChipRemove = (s) => {
        this.props.chips.map((item, key) => {
            const label = `${item.title} '${item.name}'`;
            if (label === s) {
                this.props.chips.splice(key, 1);
                this.props.onChangeChips(s, this.props.chips);
            }
        });
    }

    render() {
        return (
            <ChipSet>
                <CustomInputChip selecteditems={this.props.chips} id='add_filter' label={this.props.t('common.addFilter')} financiers={this.props.financiers} employeeTypes={this.props.employeeTypes} onApply={this.addFilter} provincies={this.props.provincies} />
                {this.props.chips.map((item, key) => {
                    const label = `${item.title} '${item.name}'`;
                    return (
                        <Chip
                            className="ChipsInFilter"
                            filter={true}
                            selecteditems={this.props.chips}
                            trailingIcon={<Cancel />}
                            shouldRemoveOnTrailingIconClick={false}
                            handleTrailingIconInteraction={this.onChipRemove}
                            id={label}
                            label={label}
                        />
                    );
                })}
            </ChipSet>
        )
    }
}

ChipFilters.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
}

export default withTranslation()(ChipFilters);
