import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function HealthBook(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M18,2H6C4.897,2,4,2.897,4,4v16c0,1.103,0.897,2,2,2h12c1.103,0,2-0.897,2-2V4C20,2.897,19.103,2,18,2z M15,13h-2v2h-2v-2H9v-2h2V9h2v2h2V13z" />
      <path d="M5 7H3C2.448 7 2 6.552 2 6l0 0c0-.552.448-1 1-1h2V7zM5 11H3c-.552 0-1-.448-1-1l0 0c0-.552.448-1 1-1h2V11zM5 15H3c-.552 0-1-.448-1-1l0 0c0-.552.448-1 1-1h2V15zM5 19H3c-.552 0-1-.448-1-1l0 0c0-.552.448-1 1-1h2V19z" />
    </SvgIcon>
  );
}