import classnames from 'classnames';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { TextField } from '@material-ui/core'

class TextareaInput extends Component {
  handleChange(e) {
    this.props.onChangeValue(e.target.value)
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={7}>
          <TextField
            multiline
            className={classnames('', this.props.className)}
            fullWidth
            disabled={this.props.disabled}
            error={this.props.invalid}
            label={this.props.text}
            rowsMax="10"
            helperText={this.props.errorText}
            value={this.props.value}
            onChange={e => this.handleChange(e)}
            margin="normal"
            variant="outlined"
          />
        </GridItem>
      </GridContainer>
    )
  }
}

TextareaInput.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChangeValue: PropTypes.func,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TextareaInput
