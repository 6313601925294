import React, { useEffect } from 'react'

import Card from '../../../Card/Card'
import CardBody from '../../../Card/CardBody'
import CardHeader from '../../../Card/CardHeader'
import CardIcon from '../../../Card/CardIcon'
import GridContainer from '../../../Grid/GridContainer'
import HealthAndSafetyIcon from '../../../../icons/HealthAndSafetyIcon'
import Laboratory from './Sections/Laboratory'
import RenalFunction from './Sections/RenalFunction'

const FormComplementaryStudies = (props) => {
  const {
    setDataComplementaryStudies,
    save,
    setValidateComplementaryStudiesForm,
    editData,
    onlyView,
  } = props
  const [dataLaboratory, setDataLaboratory] = React.useState(null)
  const [dataRenalFunction, setDataRenalFunction] = React.useState(null)
  const [validateRenalFunctionSection, setValidateRenalFunctionSection] =
    React.useState(false)

  const FormatData = () => {
    let data = {
      glycemiaId: dataLaboratory ? dataLaboratory.glycemiaId : null,
      glycosylatedHemoglobinId: dataLaboratory
        ? dataLaboratory.glycosylatedHemoglobinId
        : null,
      cholesterolId: dataLaboratory ? dataLaboratory.cholesterolId : null,
      triglyceridesId: dataLaboratory ? dataLaboratory.triglyceridesId : null,
      ureaId: dataRenalFunction ? dataRenalFunction.ureaId : null,
      creatinineId: dataRenalFunction ? dataRenalFunction.creatinineId : null,
      xRayThorax: dataRenalFunction ? dataRenalFunction.xRayThorax : null,
      xRayThoraxDate: dataRenalFunction
        ? dataRenalFunction.xRayThoraxDate
        : null,
      electrocardiogram: dataRenalFunction
        ? dataRenalFunction.electrocardiogram
        : null,
      electrocardiogramDate: dataRenalFunction
        ? dataRenalFunction.electrocardiogramDate
        : null,
      echocardiogram: dataRenalFunction
        ? dataRenalFunction.echocardiogram
        : null,
      echocardiogramDate: dataRenalFunction
        ? dataRenalFunction.echocardiogramDate
        : null,
      medicineList: dataRenalFunction ? dataRenalFunction.medicineList : null,
    }
    setDataComplementaryStudies(data)
  }

  const validateSections = () => {
    if (validateRenalFunctionSection) {
      setValidateComplementaryStudiesForm(true)
    } else {
      setValidateComplementaryStudiesForm(false)
    }
  }

  useEffect(() => {
    FormatData()
    validateSections()
  }, [dataLaboratory, dataRenalFunction, validateRenalFunctionSection])

  return (
    <>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <HealthAndSafetyIcon />
          </CardIcon>
          <h4>Estudios complementarios</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <Laboratory
              setData={setDataLaboratory}
              save={save}
              editData={editData}
              onlyView={onlyView}
            ></Laboratory>
            <RenalFunction
              setData={setDataRenalFunction}
              save={save}
              setValidateRenalFunctionSection={setValidateRenalFunctionSection}
              editData={editData}
              onlyView={onlyView}
            ></RenalFunction>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  )
}

export default FormComplementaryStudies
