export const EMPLOYEES = 'EMPLOYEES';
export const EMPLOYEETYPES = 'EMPLOYEETYPES';
export const FINANCIERS = 'FINANCIERS';
export const PRACTICETYPES = 'PRACTICETYPES';
export const PROVINCIES = 'PROVINCIES';

export function getEmployees(employees) {
  return { type: EMPLOYEES, employees }
}

export function getEmployeeTypes(employeeTypes) {
  return { type: EMPLOYEETYPES, employeeTypes }
}

export function getFinanciers(financiers) {
  return { type: FINANCIERS, financiers }
}

export function getPracticeTypes(practices) {
  return { type: PRACTICETYPES, practices }
}

export function getProvincies(provincies) {
  return { type: PROVINCIES, provincies }
}