import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Events, scroller } from 'react-scroll';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import moment from 'moment';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import Snackbar from '../Snackbar/Snackbar';
import DashboardApiInvoker from '../../api/DashboardApiInvoker';
import { FiltersService } from '../../containers/DashboardEconomicSupplyService';
import DashboardEconomic from '../../icons/DashboardEconomicIcon/index';
import '../../assets/plugins/react-charts.css';
import './Dashboard.css';


class DashboardEconomicSupply extends Component {
    constructor(props) {
        super(props);

        this.state = {
            originalquery: [],
            schedules: [],
            dateFrom: '',
            dateTo: '',
            employeeId: '',
            graphics: false,
            clear: false,
            financiers: [],
            chips: [],
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            percentaje: 0,
            scheduleall: 0,
            scheduledone: 0
        }
    }

    componentWillMount() {
        if (this.props.employees && this.props.employees.length > 0) {
            this.setState({ professionals: this.props.employees });
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.setState({
                employees: next.employees,
                professionals: next.employees,
            });
        }
        if (next.financiers) {
            this.setState({
                financiers: next.financiers,
                ofinanciers: next.financiers,
            });
        }
    }

    componentDidMount() {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-charts', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }


    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                this.cleanFields();
            }
        }, 5000);
    }

    setFilters(filters) {
        if (filters.start_date && filters.end_date) {
            this.setState({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
                loadingEmployeesTable: true,
            });

            DashboardApiInvoker.getDashboardEconomicSupply({
                start_date: moment(filters.start_date).format('YYYY-MM-DD'),
                end_date: moment(filters.end_date).format('YYYY-MM-DD'),
            }, data => {
                this.formatTableData(data)
            }, err => {
                const message = err.message ?? err;
                this.openAlert('danger', message + '');
                this.setState({ loadingEmployeesTable: false });
                console.error(err);
            });
        }
    }

    exportToExcel = () => {
        const { t } = this.props
        let r = [];
        let fields = [];
        fields = {
            'supplyCode': t('dashboardeconomicsupply.table.supplyCode'),
            'supplyName': t('dashboardeconomicsupply.table.supplyName'),
            'supplyQuantity': t('dashboardeconomicsupply.table.supplyQuantity'),
            'appointmentId': t('dashboardeconomicsupply.table.appointmentId'),
            'scheduleId': t('dashboardeconomicsupply.table.scheduleId'),
            'scheduleDateTime': t('dashboardeconomicsupply.table.scheduleDateTime'),
            'speciality': t('dashboardeconomicsupply.table.speciality'),
            'practiceTypeName': t('dashboardeconomicsupply.table.practiceTypeName'),
            'employeeName': t('dashboardeconomicsupply.table.employeeName'),
            'customerName': t('dashboardeconomicsupply.table.customerName'),
            'province': t('dashboardeconomicsupply.table.province'),
            'location': t('dashboardeconomicsupply.table.location'),
            'geographicZone': t('dashboardeconomicsupply.table.geographicZone'),
            'financierName': t('dashboardeconomicsupply.table.financierName'),
        };

        r.push(fields);
        this.state.schedules.map(e => {
            return r.push(e);
        })

        const fileName = 'insumosmedicos';
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(r, { skipHeader: 1 });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data2, fileName + fileExtension);
        return;
    }

    getArrayOf = (filters, type) => {
        const ret = [];
        filters.chips.map(e => {
            if (e.code === type) {
                ret.push(e.name);
            }
        });
        return ret;
    }

    containsAny = (source, target) => {
        const result = source.filter(function (item) { return target.indexOf(item) > -1 });
        return (result.length > 0);
    }

    softFilter = (filters) => {
        let result = this.state.originalquery;
        const arra = this.getArrayOf(filters, 'employeeTypes')
        const self = this;
        filters.chips.map(e => {
            result = result.filter(function (item) {
                if (e.code === 'employeeTypes') {
                    return self.containsAny(item[e.code].split(','), arra);
                } else {
                    return item[e.code] === e.name;
                }
            });
        });

        let quant1 = 0;
        let quant2 = 0;
        let percentaje = 0;
        result.map(e => {
            if (e.scheduleStatus === 'IN_PROGRESS' || e.scheduleStatus === 'DONE') {
                quant1++;
            }
            if (e.scheduleStatus === 'PENDING_APPROVAL' || e.scheduleStatus === 'APPROVED' || e.scheduleStatus === 'IN_PROGRESS' || e.scheduleStatus === 'DONE'  || e.scheduleStatus === 'REJECTED') {
                quant2++
            }
        })
        if (quant2 > 0) {
            percentaje = ((quant1 * 100) / quant2);
        }
        this.setState({ schedules: result, scheduledone: quant1, scheduleall: quant2, percentaje: percentaje });
    }

    formatEmployeeType(types) {
        let typesv = '';

        types.map(e => {
            typesv = typesv + (typesv === '' ? '' : ',') + e.name
        });
        return typesv;
    }

    formatTableData(data) {
        let result = []
        if (data && data.length) {
            result = data.map(d => {
                return {
                    supplyCode: d.supplyCode,
                    supplyName: d.supplyName,
                    supplyQuantity: d.supplyQuantity,
                    appointmentId: d.appointmentId,
                    scheduleId: d.scheduleId,
                    scheduleDateTime: (d.scheduleDateTime ? moment(d.scheduleDateTime).format('DD/MM/YYYY') : ''),
                    speciality: d.practiceTypeGroupName, // @TODO: agregar especialidad
                    practiceTypeName: d.practiceTypeName,
                    employeeName: d.employeeName,
                    customerName: d.customerName,
                    province: d.province,
                    location: d.location,
                    geographicZone: d.geographicZone,
                    financierName: d.financierName,
                }
            });
        }

        this.setState({
            schedules: result,
            originalquery: result,
            loadingEmployeesTable: false,
        });

        this.softFilter(this.state);
    }

    clickOnRow(employeeId) {
        if (employeeId) {
            this.setState({
                clear: false,
            }, () => {
                this.getSchedules(this.state.employeeId);
            });
        }
    }

    getSchedules(employeeId) {
        DashboardApiInvoker.postEmployeeDashboardSchedule({
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            employeeId: employeeId
        }, schedules => {
            this.setState({
                schedules: [],
                selectedRow: true,
                graphics: false,
            })
            this.formatSchedules(schedules)
        }, err => {
            console.error(err);
        })
    }

    clearInformation() {
        this.setState({
            employees: [],
            employeeId: '',
            dateFrom: '',
            dateTo: '',
            clear: true,
            chips: [],
            schedules: [],
            originalquery: [],
            scheduleall: 0,
            scheduledone: 0,
            percentaje: 0,
        });
    }

    showGraphicsInfo(rowInfo) {
        if (rowInfo && rowInfo.original && rowInfo.original.employeeId) {
            this.setState({
                graphics: true,
                employeeId: rowInfo.original.employeeId,
            }, () => {
                this.setState({ graphics: false });
            });
        }
    }

    render() {
        const { t } = this.props;
        const {
            chips, loadingEmployeesTable, schedules, alertColor, alertMessage, alertOpen,
        } = this.state;
        
        return (
            <div className="dashboard dashboard-economic-supply">
                <GridContainer>
                    <GridItem className="table-container" xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <DashboardEconomic />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('dashboardeconomicsupply.table.title')}</h3>
                                <FiltersService
                                    loading={loadingEmployeesTable}
                                    chips={chips}
                                    onChangeChips={(filters) => this.softFilter(filters)}
                                    onChange={(filters) => this.setFilters(filters)}
                                    onClearFilters={() => this.clearInformation()}
                                />
                            </CardHeader>
                            <CardBody>
                                <Table
                                    loading={loadingEmployeesTable}
                                    tableHeaderColor="primary"
                                    sortable
                                    tableHead={[
                                        { Header: t('dashboardeconomicsupply.table.supplyCode'), accessor: 'supplyCode' },
                                        { Header: t('dashboardeconomicsupply.table.supplyName'), accessor: 'supplyName' },
                                        { Header: t('dashboardeconomicsupply.table.supplyQuantity'), accessor: 'supplyQuantity' },                                       
                                        { Header: t('dashboardeconomicsupply.table.appointmentId'), accessor: 'appointmentId' },
                                        { Header: t('dashboardeconomicsupply.table.scheduleId'), accessor: 'scheduleId' },
                                        { Header: t('dashboardeconomicsupply.table.scheduleDateTime'), accessor: 'scheduleDateTime' },
                                        { Header: t('dashboardeconomicsupply.table.speciality'), accessor: 'speciality' },
                                        { Header: t('dashboardeconomicsupply.table.practiceTypeName'), accessor: 'practiceTypeName' },
                                        { Header: t('dashboardeconomicsupply.table.employeeName'), accessor: 'employeeName' },
                                        { Header: t('dashboardeconomicsupply.table.customerName'), accessor: 'customerName' },
                                        { Header: t('dashboardeconomicsupply.table.province'), accessor: 'province' },
                                        { Header: t('dashboardeconomicsupply.table.location'), accessor: 'location' },
                                        // { Header: t('dashboardeconomicsupply.table.geographicZone'), accessor: 'geographicZone' },
                                        { Header: t('dashboardeconomicsupply.table.financierName'), accessor: 'financierName' },
                                    ]}
                                    tableData={schedules}
                                    defaultPageSize={schedules.length > 5 ? 20 : 5}
                                    colorsColls={['primary']}
                                    onRowClick={(e, t, rowInfo) => this.showGraphicsInfo(rowInfo)}
                                />
                            </CardBody>
                            <CardFooter className="footer-buttons buttons-right">
                                <Button className="button-export" onClick={() => this.exportToExcel()} color="success" disabled={schedules.length < 1}>
                                    {t('common.exportExcel')}
                                </Button>
                            </CardFooter>
                        </Card>
                        <Snackbar
                            place="tr"
                            color={alertColor}
                            message={alertMessage}
                            open={alertOpen}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

DashboardEconomicSupply.propTypes = {
    schedules: PropTypes.array,
    supplies: PropTypes.array,
    tools: PropTypes.array,
    t: PropTypes.func
}

export default withTranslation()(DashboardEconomicSupply);
