import React from 'react';
import { withTranslation } from 'react-i18next';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '../../../CustomButtons/Button';

const ScheduleItemActions = (props) => {
  const { hideRemove, t } = props

  return <div className="schedule-action-buttons">
    <Tooltip title={t('common.edit')}>
      <div>
        <Button
          simple
          justIcon
          disabled={props.disabled ? props.disabled : false}
          color="success"
          onClick={props.onEdit}
        >
          <Edit />
        </Button>
      </div>
    </Tooltip>
    {!hideRemove && 
      <Tooltip title={t('remove')}>
        <div>
          <Button
            simple
            justIcon
            disabled={props.disabled ? props.disabled : false}
            color="danger"
            onClick={props.onDelete}
          >
            <Delete />
          </Button>
        </div>
      </Tooltip>
    }
  </div>
}

export default withTranslation()(ScheduleItemActions);
