import './CreateAddress.css'

import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import React, { Component } from 'react'
import { compose, withProps } from 'recompose'

import ApiInvoker from '../../api/ApiInvoker'
import Button from '../CustomButtons/Button'
import ButtonSpinner from '../ButtonSpinner'
import CheckInput from '../CheckInput'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import PersonApiInvoker from '../../api/PersonApiInvoker'
import PropTypes from 'prop-types'
import SelectInput from '../SelectInput'
import Snackbar from '../Snackbar/Snackbar'
import ThirdPartiesInvoker from '../../api/ThirdPartiesInvoker'
import ValidationInput from '../ValidationInput'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

const MapComponent = compose(
  withProps({
    googleMapURL: '/googlemap',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div style={{ position: `absolute`, width: `100%`, height: `100%` }} />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={16}
    center={{
      lat: Number(props.lat) || -31.41070438150723,
      lng: Number(props.lng) || -64.19620923199496,
    }}
  >
    <Marker position={{ lat: Number(props.lat), lng: Number(props.lng) }} />
  </GoogleMap>
))

class CreateAddress extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addressType: '',
      addressTypes: [],
      addressTypesForPosting: [],
      locations: [],
      cities: [],
      countries: [],
      country: '',
      provinces: [],
      province: '',
      geographicZones: [],
      geographicZone: '',
      city: '',
      street: '',
      number: '',
      floor: '',
      apartment: '',
      neighborhood: '',
      zipcode: '',
      latitude: '',
      longitude: '',
      addressDefault: false,
      alertOpen: false,
      save: false,
      addressChecked:
        _.get(props.edit, 'latitude', false) &&
        _.get(props.edit, 'longitude', false),
      geolocationUpdated: false,
      loading: false,
      changeManualGeo: false,
      addressVerify: false,
      manualGeoVerify: true,
      errorGeolocation: false,
    }
  }

  componentWillMount() {
    this.getCountries()
    this.getAddressTypes()
    if (this.props.edit) {
      const {
        editable,
        addressType,
        addressDefault,
        countryId,
        department,
        floor,
        geographicZone,
        latitude,
        longitude,
        location,
        neighborhood,
        number,
        save,
        provinceId,
        street,
        zipcode,
      } = this.props.edit

      this.setState({
        addressType: addressType && addressType.addressTypeId,
        number: number,
        neighborhood: neighborhood,
        street: street,
        zipcode: zipcode,
        city: location.locationId,
        apartment: department,
        floor: floor,
        editable: editable,
        province: provinceId !== 0 ? provinceId : '',
        country: countryId !== 0 ? countryId : '',
        geographicZone:
          geographicZone && geographicZone.geographicZoneId
            ? geographicZone.geographicZoneId
            : '',
        latitude: latitude ? latitude : '',
        longitude: longitude ? longitude : '',
        addressDefault: addressDefault,
        addressVerify: true,
        save: save,
      })

      if (countryId && countryId !== 0) {
        this.getProvinces(countryId)
      }

      if (provinceId && provinceId !== 0) {
        this.getCities(provinceId)
      }

      if (location.locationId && location.locationId !== 0) {
        this.getGeographicZones(location.locationId)
      }
    }
  }

  componentDidUpdate() {
    if (this.shouldSearchForLocationInGoogle()) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords
          ThirdPartiesInvoker.getGoogleLocationBasedOnLatLong(
            coords.latitude,
            coords.longitude,
            this.handleLocationFromGeoposition.bind(this)
          )
        },
        null,
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
      )
    }
  }

  shouldSearchForLocationInGoogle() {
    const { geolocationUpdated, countries } = this.state
    return (
      !this.props.edit &&
      !geolocationUpdated &&
      navigator &&
      navigator.geolocation &&
      !_.isEmpty(countries)
    )
  }

  handleLocationFromGeoposition(responseJson) {
    if (responseJson.status === 'OK') {
      const addressComponents = responseJson.results[0].address_components
      let city, region, country, locality

      for (let i = 0; i < addressComponents.length; i++) {
        if (addressComponents[i].types[0] === 'locality') {
          locality = addressComponents[i].long_name
        }
        if (addressComponents[i].types[0] === 'administrative_area_level_2') {
          city = addressComponents[i].long_name
        }
        if (addressComponents[i].types[0] === 'administrative_area_level_1') {
          region = addressComponents[i].long_name
        }
        if (addressComponents[i].types[0] === 'country') {
          country = addressComponents[i].long_name
        }
      }

      this.openAlert('info', this.props.t('address.gmap.getLocation'), {
        geolocationUpdated: true,
      })

      // this should be concatenated to wait for the services calls based on selected values
      this.getProvinces(
        this.getLocationFromStateByName('country', country),
        () => {
          this.getCities(
            this.getLocationFromStateByName('province', region),
            () => {
              this.getGeographicZones(
                this.getLocationFromStateByName('city', [locality, city])
              )
            }
          )
        }
      )
    }
  }

  getLocationFromStateByName(type, googleItem) {
    const state = this.state
    const stateMap = {
      country: state.countries,
      province: state.provinces,
      city: state.cities,
    }
    const locationSelected =
      _.find(stateMap[type], (item) => {
        let value = _.get(item, 'value', '')
        value = this.normalizeSpecialChars(value)

        if (_.isArray(googleItem)) {
          return _.some(googleItem, (item) => {
            let normalizedGoogleItem = this.normalizeSpecialChars(item)
            return value && normalizedGoogleItem
              ? value.toLowerCase() === normalizedGoogleItem.toLowerCase()
              : false
          })
        } else {
          const normalizedGoogleItem = this.normalizeSpecialChars(googleItem)
          return value && normalizedGoogleItem
            ? value.toLowerCase() === normalizedGoogleItem.toLowerCase()
            : false
        }
      }) || {}

    return locationSelected.id
  }

  normalizeSpecialChars(value) {
    if (value) {
      return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    } else {
      return value
    }
  }

  handleValue(value, state) {
    if (state === 'latitude' || state === 'longitude') {
      this.setState({ manualGeoVerify: false })
    }
    let addressChecked = this.state.addressChecked
    if (
      state !== 'addressType' &&
      state !== 'geographicZone' &&
      state !== 'addressDefault'
    ) {
      addressChecked = false
    }
    this.setState({
      [state]: value,
      addressChecked,
    })
  }

  handleValueLatLng(value, state) {
    if (value.indexOf('e') > -1) return

    const rgx_lat =
      /^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{6,20})?))$/
    const rgx_lng =
      /^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{6,20})?))$/
    let errorGeolocation = null
    if (state === 'latitude') {
      if (!value.match(rgx_lat)) {
        errorGeolocation = this.props.t('address.change.lat.error')
      }
    }
    if (state === 'longitude') {
      if (!value.match(rgx_lng)) {
        errorGeolocation = this.props.t('address.change.lng.error')
      }
    }
    this.setState({
      [state]: value,
      manualGeoVerify: false,
      errorGeolocation,
    })
  }

  handleCountry(country) {
    this.setState(
      {
        addressChecked: false,
        country,
        city: null,
        geographicZone: null,
      },
      () => {
        this.getProvinces(country)
      }
    )
  }

  handleProvince(value) {
    this.setState(
      {
        province: value,
        addressChecked: false,
        geographicZone: null,
      },
      () => {
        this.getCities(value)
      }
    )
  }

  handleCities(city) {
    this.setState(
      {
        city,
        addressChecked: false,
        geographicZone: null,
      },
      () => {
        this.getGeographicZones(city)
      }
    )
  }

  getCountries() {
    ApiInvoker.getCountries((data) => {
      this.formatCountries(data)
    }, null)
  }

  getProvinces(country, setStateCallBack) {
    ApiInvoker.getProvinces(
      country,
      (data) => {
        this.formatProvinces(data, setStateCallBack)
        this.setState({ country })
      },
      null
    )
  }

  getCities(province, setStateCallBack) {
    ApiInvoker.getLocations(
      province,
      (cities) => {
        this.formatCities(cities, setStateCallBack)
        this.setState({ province })
      },
      null
    )
  }

  getAddressTypes() {
    ApiInvoker.getAddressTypes((addressTypes) => {
      this.setState({
        addressTypes: _.map(addressTypes, this.getAddressType),
        addressTypesForPosting: addressTypes,
      })
    })
  }

  getAddressType(addressType) {
    return {
      id: addressType.addressTypeId,
      value: addressType.name,
    }
  }

  getGeographicZones(city) {
    ApiInvoker.getGeographicZoneFromLocation(
      city,
      (geographicZones) => {
        this.formatGeographicZones(geographicZones)
        this.setState({ city })
      },
      null
    )
  }

  checkGeocode() {
    const { t } = this.props
    const {
      cities,
      city,
      countries,
      country,
      number,
      province,
      provinces,
      street,
    } = this.state
    const cityComplete = _.find(cities, (item) => item.id === city)
    const provinceComplete = _.find(provinces, (item) => item.id === province)
    const countryComplete = _.find(countries, (item) => item.id === country)
    this.setState({
      changeManualGeo: false,
    })
    if (
      street &&
      number &&
      cityComplete &&
      provinceComplete &&
      countryComplete
    ) {
      const completeAddress = [
        street,
        number,
        cityComplete.value,
        provinceComplete.value,
        countryComplete.value,
      ].join(' ')

      ThirdPartiesInvoker.getGoogleGeolocation(
        completeAddress,
        (responseJson) => {
          if (responseJson.status === 'OK') {
            this.setState(
              {
                latitude: responseJson.results[0].geometry.location.lat,
                longitude: responseJson.results[0].geometry.location.lng,
                addressChecked: true,
              },
              () => {
                this.setState({
                  addressVerify: true,
                  errorGeolocation: null,
                  manualGeoVerify: true,
                })
              }
            )
          } else {
            this.openAlert('danger', t('address.gmap.errorCoordinates'))
          }
        },
        () => {
          this.openAlert('danger', t('address.gmap.errorCoordinates'))
        }
      )
    } else {
      this.openAlert('warning', t('common.messageWarning.fieldsComplete'))
    }
  }

  formatProvinces(provinces, setStateCallBack) {
    let result = []
    result = provinces.map((p) => ({
      id: p.provinceId,
      value: p.name,
    }))

    this.setState({ provinces: result }, setStateCallBack)
  }

  formatCountries(countries) {
    let result = []
    result = countries.map((c) => ({
      id: c.countryId,
      value: c.name,
    }))

    this.setState({ countries: result })
  }

  formatCities(cities, setStateCallBack) {
    let result = []
    result = cities.map((c) => ({
      id: c.locationId,
      value: c.name,
    }))

    this.setState(
      {
        locations: cities,
        cities: result,
      },
      setStateCallBack
    )
  }

  formatGeographicZones(zones) {
    let geographicZones = []
    geographicZones = zones.map((gz) => ({
      id: gz.geographicZoneId,
      value: gz.detail,
    }))

    this.setState({ geographicZones })
  }

  saveAddress() {
    if (this.props.edit) {
      this.props.edit.save = true
      this.patchAddress()
    } else {
      if (this.state.addressChecked) {
        this.setState({ save: true })
        this.postAddress()
      } else {
        this.openAlert('danger', this.props.t('address.messageWngCheckAddress'))
      }
    }
  }

  dataPost = (edit) => {
    const { personId } = this.props
    const {
      addressDefault,
      apartment,
      floor,
      latitude,
      longitude,
      neighborhood,
      number,
      street,
      zipcode,
    } = this.state
    const data = {
      personId,
      location: this.findLocation(),
      latitude: latitude ? latitude.toString().substring(0, 25) : latitude,
      longitude: longitude ? longitude.toString().substring(0, 25) : longitude,
      department: apartment,
      floor,
      addressType: this.getAddressTypeForPosting(),
      number,
      neighborhood,
      street,
      zipCode: zipcode,
      addressDefault,
      geographicZone: this.findGeographicZone(),
    }
    if (edit) {
      data.addressId = edit.addressId
      data.address = edit.address
    } else data.address = ''
    return data
  }

  patchAddress() {
    this.setState({ loading: true })
    const { edit, personId, t } = this.props
    const {
      addressType,
      city,
      geographicZone,
      neighborhood,
      number,
      street,
      zipcode,
    } = this.state

    if (
      addressType &&
      number &&
      neighborhood &&
      street &&
      zipcode &&
      city &&
      geographicZone
    ) {
      PersonApiInvoker.postPersonsAddress(
        personId,
        edit.addressId,
        this.dataPost(edit),
        (data) => {
          this.setState({ loading: false })
          if (!data.message) {
            this.openAlert('success', t('address.messageUpdateSuccess'))
            this.props.onAddressSubmited(data)
          } else this.openAlert('danger', data.message)
        },
        (error) => {
          this.setState({ loading: false })
          const msg = error.message ?? t('customers.addresses.404.error')
          this.openAlert('danger', msg)
          console.error('** error de Fetch', error.message)
        }
      )
    } else {
      this.openAlert('warning', t('common.messageWarning.fieldsComplete'))
      this.setState({ loading: false })
    }
  }

  postAddress() {
    this.setState({ loading: true })
    const { personId, t } = this.props
    const {
      addressType,
      city,
      geographicZone,
      neighborhood,
      number,
      street,
      zipcode,
    } = this.state
    if (
      addressType &&
      number &&
      neighborhood &&
      street &&
      zipcode &&
      city &&
      geographicZone
    ) {
      PersonApiInvoker.postPersonsNewAddress(
        personId,
        this.dataPost(null),
        (data) => {
          this.setState({ loading: false })
          if (!data.message) {
            this.openAlert('success', t('address.messageSaveSuccess'))
            this.props.onAddressSubmited(data)
          } else this.openAlert('danger', data.message)
        },
        (error) => {
          this.setState({ loading: false })
          const msg = error.message ?? t('customers.addresses.404.error')
          this.openAlert('danger', msg)
          console.error('** error de Fetch', error.message)
        }
      )
    } else {
      this.openAlert('warning', t('common.messageWarning.fieldsComplete'))
      this.setState({ loading: false })
    }
  }

  findLocation() {
    let currentLocation = {}
    currentLocation = this.state.locations.find(
      (l) => l.locationId === this.state.city
    )
    return currentLocation
  }

  findGeographicZone = () => {
    let currentGeographicZone = null
    currentGeographicZone = this.state.geographicZones
      .map((gz) => {
        return {
          companyId: localStorage.getItem('itlg_default_company_id'),
          detail: gz.value,
          geographicZoneId: gz.id,
          locationId: this.findLocation().locationId,
          metadata: null,
        }
      })
      .find((gz) => gz.geographicZoneId === this.state.geographicZone)
    return currentGeographicZone
  }

  openAlert(color, message, extraState) {
    this.setState(
      _.assign(
        {
          alertColor: color,
          alertMessage: message,
          alertOpen: true,
        },
        extraState
      )
    )

    setTimeout(() => {
      this.setState({ alertOpen: false })
    }, 5000)
  }

  getAddressTypeForPosting() {
    const { addressType } = this.state
    return _.find(
      this.state.addressTypesForPosting,
      (item) => item.addressTypeId === addressType
    )
  }

  verifyGeo = () => {
    let error = null
    const { t } = this.props
    if (this.state.latitude > 90.0 || this.state.latitude < -90.0) {
      error = t('address.change.lat.error')
    }
    if (this.state.longitude > 180.0 || this.state.longitude < -180.0) {
      error = t('address.change.lng.error')
    }
    const lat = this.state.latitude + ''
    const lng = this.state.longitude + ''

    const decimalLat = lat.substring(lat.indexOf('.') + 1)
    const decimalLong = lng.substring(lng.indexOf('.') + 1)

    if (decimalLat.length < 6 || decimalLat.length > 25) {
      error = t('address.change.lat.error')
    }
    if (decimalLong.length < 6 || decimalLong.length > 25) {
      error = t('address.change.lng.error')
    }

    if (error != null) {
      this.setState({ errorGeolocation: error })
    } else {
      this.setState({
        errorGeolocation: null,
        manualGeoVerify: true,
        addressChecked: true,
      })
    }
  }

  disableAddressCheckButton() {
    const { edit, editable } = this.props
    let isDisabled = false
    if (edit) {
      if (editable === false && edit.latitude !== '' && edit.longitude !== '') {
        return (isDisabled = true)
      }
    }
    return isDisabled
  }

  renderGoogleMap = () => (
    <MapComponent
      key="map"
      lat={this.state.latitude}
      lng={this.state.longitude}
    />
  )

  render() {
    const {
      addressDefault,
      addressType,
      addressTypes,
      addressVerify,
      alertColor,
      alertMessage,
      alertOpen,
      apartment,
      editable,
      cities,
      city,
      changeManualGeo,
      countries,
      country,
      errorGeolocation,
      floor,
      geographicZone,
      geographicZones,
      latitude,
      loading,
      longitude,
      manualGeoVerify,
      neighborhood,
      number,
      province,
      provinces,
      save,
      street,
      zipcode,
    } = this.state
    const { t, edit } = this.props

    // let localEditable = true
    // if (edit && !editable) {
    //   localEditable = false
    // }

    return (
      <>
        <GridContainer className="create-address">
          <GridItem xs={12} sm={12} md={6} className="no-padding">
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <SelectInput
                  label={`${t('address.country')} *`}
                  elements={countries}
                  value={country}
                  onSelectedValue={(value) => this.handleCountry(value)}
                  invalid={country === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <SelectInput
                  label={`${t('address.province')} *`}
                  elements={provinces}
                  value={province}
                  onSelectedValue={(value) => this.handleProvince(value)}
                  invalid={province === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <SelectInput
                  label={`${t('address.location')} *`}
                  elements={cities}
                  value={city}
                  onSelectedValue={(value) => this.handleCities(value)}
                  invalid={city === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="item-input">
                <ValidationInput
                  text={`${t('address.zipCode')} *`}
                  value={zipcode}
                  onChangeValue={(value) => this.handleValue(value, 'zipcode')}
                  invalid={zipcode === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="item-input">
                <ValidationInput
                  text={`${t('address.neighborhood')} *`}
                  value={neighborhood}
                  onChangeValue={(value) =>
                    this.handleValue(value, 'neighborhood')
                  }
                  invalid={neighborhood === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="item-input">
                <ValidationInput
                  text={`${t('address.street')} *`}
                  value={street}
                  onChangeValue={(value) => this.handleValue(value, 'street')}
                  invalid={street === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="item-input">
                <ValidationInput
                  text={`${t('address.number')} *`}
                  type="number"
                  value={number}
                  onChangeValue={(value) => this.handleValue(value, 'number')}
                  invalid={number === '' && save}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={4} className="item-input">
                <ValidationInput
                  text={t('address.floor')}
                  type="number"
                  value={floor}
                  onChangeValue={(value) => this.handleValue(value, 'floor')}
                  invalid={false}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={4} className="item-input">
                <ValidationInput
                  text={t('address.apartment')}
                  type="text"
                  value={apartment}
                  onChangeValue={(value) =>
                    this.handleValue(value, 'apartment')
                  }
                  invalid={false}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <SelectInput
                  label={`${t('address.addressType')} *`}
                  elements={addressTypes}
                  value={addressType}
                  onSelectedValue={(value) =>
                    this.handleValue(value, 'addressType')
                  }
                  invalid={addressType === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <SelectInput
                  label={`${t('address.geographicZone')} *`}
                  elements={geographicZones}
                  value={geographicZone}
                  onSelectedValue={(value) =>
                    this.handleValue(value, 'geographicZone')
                  }
                  invalid={geographicZone === '' && save}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CheckInput
                  onChangeValue={() =>
                    this.handleValue(!addressDefault, 'addressDefault')
                  }
                  checked={addressDefault}
                  label={t('address.mainAddress')}
                  labelPlacement="start"
                />
              </GridItem>
              <GridContainer>
                <GridItem md={12} className="address-check-button text-right">
                  <Button
                    color="rose"
                    onClick={() => this.checkGeocode()}
                    disabled={this.disableAddressCheckButton()}
                  >
                    {t('address.checkAddress')}
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={12}>
                  <CheckInput
                    onChangeValue={() =>
                      this.handleValue(!changeManualGeo, 'changeManualGeo')
                    }
                    checked={changeManualGeo}
                    disabled={
                      !addressVerify || this.disableAddressCheckButton()
                    }
                    label={t('address.change.manual.geo')}
                  />
                </GridItem>
              </GridContainer>

              {changeManualGeo && (
                <GridContainer>
                  <GridItem xs={6} sm={4} md={4} className="item-input">
                    <ValidationInput
                      text={t('address.latitude')}
                      type="number"
                      value={latitude}
                      onChangeValue={(value) =>
                        this.handleValueLatLng(value, 'latitude')
                      }
                      disabled={!changeManualGeo}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={4} className="item-input">
                    <ValidationInput
                      text={t('address.longitude')}
                      type="number"
                      value={longitude}
                      onChangeValue={(value) =>
                        this.handleValueLatLng(value, 'longitude')
                      }
                      disabled={!changeManualGeo}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <ButtonSpinner
                      className="create-address-submit"
                      onClick={() => this.verifyGeo()}
                      disabled={!addressVerify || !changeManualGeo}
                      label={t('address.change.check.geo')}
                      fullWidth={true}
                      color="rose"
                    />
                  </GridItem>
                </GridContainer>
              )}

              {errorGeolocation && (
                <GridItem md={8}>
                  <p>
                    <span className="error">{errorGeolocation}</span>
                  </p>
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className="map-container">
            {this.renderGoogleMap()}
          </GridItem>
          <GridItem xs={12} className="item-submit">
            <ButtonSpinner
              className="create-address-submit"
              onClick={() => this.saveAddress()}
              disabled={!manualGeoVerify}
              label={t('address.save')}
              labelLoading={t('address.saving')}
              loading={loading}
              typeButton="submit"
              color="info"
            />
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tr"
          color={alertColor}
          message={alertMessage}
          open={alertOpen}
        />
      </>
    )
  }
}

CreateAddress.propTypes = {
  personId: PropTypes.number,
  onGetLocations: PropTypes.func,
  onAddressSubmited: PropTypes.func,
  edit: PropTypes.object,
  invalid: PropTypes.bool,
}

export default withTranslation()(CreateAddress)
