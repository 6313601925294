import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../../Card/Card'
import CardHeader from '../../Card/CardHeader'
import CardIcon from '../../Card/CardIcon'
import HowToReg from '@material-ui/icons/HowToReg'
import CardBody from '../../Card/CardBody'
import SelectInput from '../../SelectInput'
import '../NewSchedule.css'
import { withTranslation } from 'react-i18next'
import PersonApiInvoker from "../../../api/PersonApiInvoker";

const ResponsableForm = props => {
  const [responsable, setResponsable] = useState({})
  const [responsables, setResponsables] = useState([])
  const getResponsables = () => {
    PersonApiInvoker.getEmployeesCoordinators((_responsables)=>{
      setResponsables(_responsables)
    }, () => {})
  }

  useEffect(() => {
    getResponsables()
  }, [])

  const changeSelectedValue = (value) => {
    setResponsable(value);
    props.onSelectedValue(value);
  }

  useEffect(() => {
    props.value && setResponsable(props.value)
  }, [props.value])

  const t = props.t;
  return (
    <Card>
      <CardHeader color="success" icon>
        <CardIcon color="success">
          <HowToReg />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <SelectInput
          disabled={props.disabled}
          onGetOptionLabel={(option) => option && (`${option.firstName} ${option.lastName}`)}
          label={t('appointment.new.responsable.responsable')}
          elements={responsables}
          valueAccessor={'personId'}
          onSelectedValue={changeSelectedValue}
          value={responsable}
          invalid={!responsable && props.checkInvalid}
          errorText={
            !responsable && props.checkInvalid
              ? t('error.required')
              : ''
          }
        />
      </CardBody>
    </Card>
  )
}

ResponsableForm.propTypes = {
  onSelectedValue: PropTypes.func,
  onFilledOptions: PropTypes.func,
  onInvalid: PropTypes.func,
  value: PropTypes.object,
  options: PropTypes.bool,
  checkInvalid: PropTypes.bool,
}

export default withTranslation()(ResponsableForm)
