import './Sidebar.css'

import Authority from '../../util/Authority'
import Button from '../CustomButtons/Button'
import CloseIcon from '@material-ui/icons/Close'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Icon from '@material-ui/core/Icon'
import { Link } from 'react-router'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import PerfectScrollbar from 'perfect-scrollbar'
import PropTypes from 'prop-types'
import React from 'react'
import UserService from '../../containers/UserService'
import avatar from '../../assets/img/avatar.png'
import { connect } from 'react-redux'
import cx from 'classnames'
import hmmLogo from '../../assets/img/hmm-white.png'
import logohmm from '../../assets/img/logo-hmm-white.png'
import pwdBy from '../../assets/img/glin-white.png'
import sidebarStyle from '../../assets/components/sidebarStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import { withTranslation } from 'react-i18next'

// javascript plugin used to create scrollbars on windows

// @material-ui/core components

let ps

const SidebarFooter = (props) => {
  const { logos } = props

  return (
    <div className="logos">
      {logos.length > 0 &&
        logos.map(({ title, linkTo, img }, index) => (
          <a
            key={`sidebarfooter-link-${index}`}
            href={linkTo}
            target={linkTo !== '#' ? '_blank' : ''}
            rel="noopener noreferrer"
          >
            <img
              className={title ? title.toLowerCase() : ''}
              src={img}
              title={title}
              alt={title}
            />
          </a>
        ))}
    </div>
  )
}
// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
  }
  render() {
    const { className, headerLinks, links, miniActive } = this.props
    const logos = [
      { img: pwdBy, title: 'Glin', linkTo: 'http://glin-technologies.com/' },
    ]

    return (
      <div className={className} ref="sidebarWrapper">
        {headerLinks}
        {links}
        {!miniActive && <SidebarFooter logos={logos} />}
      </div>
    )
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    }
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    if (this.props.location) {
      return this.props.location.pathname.indexOf(routeName) > -1
        ? 'active'
        : ''
    }
  }
  openCollapse(collapse) {
    const st = {}
    st[collapse] = !this.state[collapse]
    this.setState(st)
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    routes = routes.filter((item) => Authority.hasPermission(item.authority))

    const { classes, color, rtlActive } = this.props
    return routes.map((prop, key) => {
      if (!prop.hidden) {
        if (prop.redirect) {
          return null
        }
        if (prop.collapse) {
          const st = {}
          st[prop['state']] = !this.state[prop.state]
          const navLinkClasses =
            classes.itemLink +
            ' ' +
            cx({
              [' ' + classes.collapseActive]: this.getCollapseInitialState(
                prop.views
              ),
            })
          const itemText =
            classes.itemText +
            ' ' +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive,
            })
          const collapseItemText =
            classes.collapseItemText +
            ' ' +
            cx({
              [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextRTL]: rtlActive,
            })
          const itemIcon =
            classes.itemIcon +
            ' ' +
            cx({
              [classes.itemIconRTL]: rtlActive,
            })
          const caret =
            classes.caret +
            ' ' +
            cx({
              [classes.caretRTL]: rtlActive,
            })
          const collapseItemMini =
            classes.collapseItemMini +
            ' ' +
            cx({
              [classes.collapseItemMiniRTL]: rtlActive,
            })

          return (
            <ListItem
              key={key}
              className={cx(
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined }
              )}
            >
              <Link
                to={'#'}
                className={navLinkClasses}
                activeClassName={`${classes[color]} active`}
                onClick={(e) => {
                  e.preventDefault()
                  this.setState(st)
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === 'string' ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span
                    className={collapseItemMini}
                    style={{ paddingLeft: `${prop.inset}px` }}
                  >
                    {rtlActive
                      ? this.props.t(prop.rtlMini)
                      : this.props.t(prop.mini)}
                  </span>
                )}
                <ListItemText
                  primary={
                    rtlActive
                      ? this.props.t(prop.rtlName)
                      : this.props.t(prop.name)
                  }
                  style={{
                    paddingLeft: prop.mini !== '' ? 0 : `${prop.inset}px`,
                  }}
                  secondary={
                    <b
                      className={
                        caret +
                        ' ' +
                        (this.state[prop.state] ? classes.caretActive : '')
                      }
                    />
                  }
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </Link>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={classes.list + ' ' + classes.collapseList}>
                  {this.createLinks(prop.views)}
                </List>
              </Collapse>
            </ListItem>
          )
        }
        const innerNavLinkClasses =
          classes.collapseItemLink +
          ' ' +
          cx({
            [' ' + classes[color]]: this.activeRoute(prop.path),
          })
        const collapseItemMini =
          classes.collapseItemMini +
          ' ' +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          })
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes[color]]: this.activeRoute(prop.path),
          })
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          })
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          })
        const itemIcon =
          classes.itemIcon +
          ' ' +
          cx({
            [classes.itemIconRTL]: rtlActive,
          })
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <Link
              to={prop.enabled ? prop.layout + prop.path : ''}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined },
                { [classes.disabled]: !prop.enabled }
              )}
              activeClassName={`${classes[color]} active`}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span
                  className={collapseItemMini}
                  style={{ paddingLeft: `${prop.inset}px` }}
                >
                  {rtlActive
                    ? this.props.t(prop.rtlMini)
                    : this.props.t(prop.mini)}
                </span>
              )}
              <ListItemText
                primary={
                  rtlActive
                    ? this.props.t(prop.rtlName)
                    : this.props.t(prop.name)
                }
                disableTypography={true}
                style={{
                  paddingLeft: prop.mini !== '' ? 0 : `${prop.inset}px`,
                }}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </Link>
          </ListItem>
        )
      }
    })
  }

  getCompanyName = () => {
    const { companies } = this.props
    const selectedCompany = parseInt(
      localStorage.getItem('itlg_default_company_id')
    )
    let companyName = ''
    if (selectedCompany && companies && companies.length) {
      const companyNm = companies.find((f) => f.companyId === selectedCompany)
      companyName = companyNm && companyNm.name
    }
    return companyName
  }

  render() {
    const { classes, logo, image, logoText, routes, bgColor, rtlActive } =
      this.props
    const itemText =
      classes.itemText +
      ' ' +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      })
    const collapseItemText =
      classes.collapseItemText +
      ' ' +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      })
    const userWrapperClass =
      classes.user +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      })
    const caret =
      classes.caret +
      ' ' +
      cx({
        [classes.caretRTL]: rtlActive,
      })
    const collapseItemMini =
      classes.collapseItemMini +
      ' ' +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      })
    const photo =
      classes.photo +
      ' ' +
      cx({
        [classes.photoRTL]: rtlActive,
      })
    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <Link
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton}
              activeClassName="active"
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={this.props.user}
                disableTypography={true}
                className={itemText + ' ' + classes.userItemText}
              />
            </Link>
          </ListItem>
        </List>
      </div>
    )
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    )

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      })
    const logoMini =
      classes.logoMini +
      ' ' +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      })
    const logoClasses =
      classes.logo +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      })
    const brand = (
      <div
        className={`${logoClasses}logo-sidebar ${!logo ? 'companyName' : ''}`}
      >
        {logo ? (
          <a href="#" className={logoMini}>
            <img
              src={logo}
              alt={this.getCompanyName()}
              className={classes.img}
            />
          </a>
        ) : (
          <p>
            <a href="#">{this.getCompanyName()}</a>
          </p>
        )}
      </div>
    )

    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      })
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      })
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Button
              className={`${classes.appResponsive} closeModalTopFixButton`}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={() => this.props.handleDrawerToggle()}
            >
              <CloseIcon />
            </Button>
            {brand}
            <SidebarWrapper
              className={`${this.props.miniActive && this.state.miniActive} ps`}
              user={user}
              miniActive={this.state.miniActive}
              headerLinks={<UserService.NavbarService rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={`${classes.background} backgroundTablet`}
                style={{ backgroundColor: `#5dc4b9` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
          >
            <img className="logo-hmm" src={logohmm} alt="" />
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              miniActive={this.props.miniActive && this.state.miniActive}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundColor: `#5dc4b9` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.defaultProps = {
  bgColor: 'black',
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'gray',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.string,
  enabled: PropTypes.bool,
  companies: PropTypes.array,
  selectedCompany: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.user.selectedCompany,
  }
}
export default connect(
  mapStateToProps,
  null
)(withStyles(sidebarStyle)(withTranslation()(Sidebar)))
