import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Checkbox, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import ViewList from '@material-ui/icons/ViewList';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import CardHeader from '../Card/CardHeader';
import Button from '../CustomButtons/Button';
import Table from '../Table/Table';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import './PracticeTypes.css';

class PracticeTypes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            practices: [],
            specialties: [],
        };
    }

    componentWillMount() {
        if (this.props.specialties) {
            this.setState({
                specialties: this.props.specialties,
            }, () => {
                this.props.practices && this.formatPractices(this.props.practices);
            });
        }
        this.getPractices();
    };

    componentWillReceiveProps(next) {
        if (next.practices) {
            this.formatPractices(next.practices);
        }
    };

    getPractices = () => {
        this.setState({ loading: true });
        AppointmentApiInvoker.getCompanyPracticeTypes(data => {
            this.setState({ loading: false });
            this.formatPractices(data);
        }, (e) => {
            this.setState({ loading: false });
        });
    };
    
    formatPractices(practices) {
        const { classes } = this.props;
        const data = practices.map(p => {
            const checkActive = <Checkbox
                tabIndex={-1}
                style={{ alignContent: 'center', textAlign: 'center' }}
                checked={p.active ? p.active : false}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                checked: classes.checked,
                root: classes.checkRoot,
                }}
                disabled
            />;
            const duration = moment.utc().startOf('day').add({ minutes: p.estimatedDuration }).format('HH:mm');
            return {
                active:  checkActive,
                specility: p.practiceTypeGroupName,
                id: p.practiceType,
                genericName: p.practiceName,
                duration: `${duration} hs.`,
                code: p.practiceTypeCode,
                customName: p.practiceTypeName,
                actions: <div className="practices-actions">
                    <Tooltip title={this.props.t('common.edit')} arial-label="edit">
                        <Button simple justIcon color="success" onClick={() => browserHistory.push(`/practice-types/${p.practiceType}/edit`)}>
                            <Edit />
                        </Button>
                    </Tooltip>
                </div>
            }
        });
        this.setState({ practices: data });
    }

    render() {
        const { t } = this.props;
        const { loading, practices } = this.state;

        return (
            <div className="practice-types">
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="rose">
                                    <ViewList />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('practices.tableTitle')}</h3>
                            </CardHeader>
                            <CardBody className="filtrable-table">
                                <Table
                                    striped
                                    filterable
                                    sortable
                                    loading={loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        { Header: t('practices.active'), accessor: 'active', sortable: false, filterable: false  },
                                        { Header: t('practices.specility'), accessor: 'specility' },
                                        { Header: t('practices.id'), accessor: 'id' },
                                        { Header: t('practices.genericName'), accessor: 'genericName' },
                                        { Header: t('practices.duration'), accessor: 'duration' },
                                        { Header: t('practices.code'), accessor: 'code' },
                                        { Header: t('practices.customName'), accessor: 'customName' },
                                        { Header: t('practices.actions'), accessor: 'actions', sortable: false, filterable: false }
                                    ]}
                                    tableData={practices}
                                    colorsColls={["primary"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

PracticeTypes.defaultProps = {
    practices: [],
    specialties: [],
}

PracticeTypes.propTypes = {
    practices: PropTypes.array,
    specialties: PropTypes.array,
}

export default withStyles(styles)(withTranslation()(PracticeTypes));
