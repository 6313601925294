import { PHONE_TYPES } from '../actions/phone';

const phone = (state = {}, action) => {
    switch(action.type) {
        case PHONE_TYPES:
            let phoneTypes = action.phoneTypes
            return {...state, phoneTypes}
        default:
            return state
    }
}

export default phone;